import React from 'react';
import { SensorGroup, Sensor } from 'models/ships';
import { useIntl } from 'react-intl';
import { styled } from '@mui/material';
import colors from 'resources/colors';
import constants from 'resources/constants';
import { roundValue, roundValueToString } from 'utils/misc';
import { MultiLineTypography } from 'components/MultiLineTypography';

const RootDiv = styled('div')({
  position: 'absolute',
  fontSize: 0,
  paddingTop: '3px',
});

const TitleDiv = styled('div')({
  display: 'inline-block',
  textAlign: 'center',
  color: 'white',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  paddingLeft: '10px',
  position: 'relative',
});

interface RexLoInfoComponenProps {
  value: number;
  top: number;
  left: number;
  sensor: Sensor;
  group: SensorGroup;
  title?: string;
  unit?: string;
  rateV?: number;
  rateH?: number;
}

export function RexLoInfoComponent(props: RexLoInfoComponenProps): JSX.Element {
  const { value, sensor } = props;
  const intl = useIntl();
  const sensorTitle = props.title
    ? props.title
    : intl.formatMessage({ id: constants.labelPrefix.mimic + sensor.sensorName });
  const sensorUnit = props.unit ? props.unit : props.sensor.displayUnit;
  const rateH = props.rateH ? props.rateH : 1;
  const rateV = props.rateV ? props.rateV : 1;
  const scaleValueFontrate = rateH < rateV ? rateH : rateV;
  const range = props.sensor.displayUpperLimit - props.sensor.displayLowerLimit;
  let valueParsent = ((props.value - props.sensor.displayLowerLimit) / range) * 100;
  valueParsent = valueParsent > 100 ? 100 : valueParsent < 0 ? 0 : valueParsent;
  const hederLabelFontSize = 10;
  const hederLabelHeight = hederLabelFontSize + 4;
  const scaleFontSize = 6 * rateV;
  const gageFontSize = 14;
  const basePanelHeight = 150;
  const basePanelWidth = 80;
  const gagePanelHeight = basePanelHeight - hederLabelHeight * 3;
  const gageNormalColor = colors.chart.mimic.border;
  const gageHightlColor = colors.chart.mimic.gageDanger;

  const roundedvalue =
    isNaN(value) || value === null ? undefined : roundValue(value, sensor.displayRoundingPosition);
  const isDanger =
    sensor.warningUpperLimit != null && roundedvalue != null
      ? roundedvalue >= sensor.warningUpperLimit
      : false;
  const gagelColor = isDanger ? gageHightlColor : gageNormalColor;
  const valueText = roundValueToString(roundedvalue, sensor.displayRoundingPosition, true);

  const styleUnit: React.CSSProperties = {
    height: hederLabelHeight * rateV,
    fontSize: hederLabelFontSize * rateV,
    width: basePanelWidth * rateH,
    display: 'inline-block',
    textAlign: 'center',
    color: 'white',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    paddingLeft: basePanelWidth * rateH * 0.12,
  };

  const styleGagePanel: React.CSSProperties = {
    height: gagePanelHeight * rateV,
    width: '100%',
    display: 'inline-block',
    color: 'white',
  };

  const mainusMarginTop =
    (gagePanelHeight / 5) * rateV - (gagePanelHeight / 5) * rateV * (10 / scaleFontSize);
  const styleScale: React.CSSProperties = {
    height: gagePanelHeight * rateV,
    width: '25%',
    display: 'inline-block',
    color: 'white',
    paddingTop: -1 * mainusMarginTop + scaleFontSize,
  };

  const styleScaleValue: React.CSSProperties = {
    height: (gagePanelHeight / 5) * rateV * (10 / scaleFontSize) + 2,
    width: `${100 * (10 / scaleFontSize)}%`,
    fontSize: scaleFontSize * scaleValueFontrate,
    display: 'inline-block',
    color: 'white',
    textAlign: 'right',
    float: 'left',
    transform: `scale(${scaleFontSize / 10})`,
    transformOrigin: '0 0',
    verticalAlign: 'middle',
    marginTop: mainusMarginTop,
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  };

  const styleGageBase: React.CSSProperties = {
    height: gagePanelHeight * rateV - 6,
    width: 'calc(75% - 6px)',
    display: 'inline-block',
    color: 'white',
    border: `1px solid ${gagelColor}`,
    borderRadius: 2,
    margin: 2,
    position: 'relative',
  };

  const styleGageValue: React.CSSProperties = {
    height: (gageFontSize + 7) * rateV,
    fontSize: gageFontSize * rateV,
    width: '100%',
    display: 'inline-block',
    color: 'white',
    position: 'absolute',
    bottom: 0,
    textAlign: 'center',
    paddingBottom: 5,
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    userSelect: 'none',
  };

  const styleGageImage: React.CSSProperties = {
    height: `${valueParsent}%`,
    fontSize: gageFontSize * rateV,
    width: 'calc(100% + 2px)',
    display: 'inline-block',
    position: 'absolute',
    bottom: 0,
    marginLeft: -1,
    background: gagelColor,
  };

  const unit = !(sensorUnit === undefined || sensorUnit === null || sensorUnit.length === 0)
    ? '[' + sensorUnit + ']'
    : '';

  // AppLogger.info(
  //   '[*RexLoInfo] ' +
  //     sensor.sensorName +
  //     ': value=' +
  //     value +
  //     ', isDanger=' +
  //     isDanger +
  //     ', valueText=' +
  //     valueText +
  //     ', warningUpperLimit=' +
  //     sensor.warningUpperLimit
  // );

  return (
    <RootDiv
      sx={{
        top: props.top * rateV,
        left: props.left * rateH,
        width: basePanelWidth * rateH,
        height: basePanelHeight * rateV,
      }}
    >
      <TitleDiv
        sx={{
          height: hederLabelHeight * 2 * rateV,
          fontSize: hederLabelFontSize * rateV,
          width: basePanelWidth * rateH,
        }}
      >
        <MultiLineTypography
          sx={{ position: 'absolute', bottom: 0, width: 'calc(100% - 10px)', textAlign: 'center' }}
          text={sensorTitle}
          variant="body2"
          fontSize="10px"
          align="center"
        />
      </TitleDiv>
      <div style={styleUnit}>{unit}</div>
      <div style={styleGagePanel}>
        <div style={styleScale}>
          <div style={styleScaleValue}>{props.sensor.displayUpperLimit}</div>
          <div style={styleScaleValue}>{props.sensor.displayUpperLimit - range / 4}</div>
          <div style={styleScaleValue}>{props.sensor.displayUpperLimit - (range / 4) * 2}</div>
          <div style={styleScaleValue}>{props.sensor.displayUpperLimit - (range / 4) * 3}</div>
          <div style={styleScaleValue}>{props.sensor.displayLowerLimit}</div>
        </div>
        <div style={styleGageBase}>
          <div style={styleGageImage}></div>
          <div style={styleGageValue}>{valueText}</div>
        </div>
      </div>
    </RootDiv>
  );
}
