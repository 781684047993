import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import dayjs from 'dayjs';
import colors from 'resources/colors';
import config from 'resources/config';
import msgId from 'resources/intl';
import { CustomScrollBars } from 'components/CustomScrollBars';
import { SearchShipList } from 'components/search/SearchShipList';
import { ReportProgressPanel } from './ReportProgressPanel';
import { ReportItem } from 'models/reports';
import { DialogResult } from 'components/AlertDialog';
import { FadeLoading } from 'components/FadeLoading';
import { generateUUID } from 'utils/misc';
import AppLogger from 'utils/AppLogger';
import { useCommonShips } from 'app/commonSlice';
import {
  cancelReport,
  fetchReport,
  setAlarmReportCheck,
  setEndDate,
  setFilterShipName,
  setOperationReportCheck,
  setReportFetchError,
  setReportItems,
  setReportProgressOpen,
  setShipCheck,
  setShips,
  setStartDate,
  useReportAlarmReportCheck,
  useReportCanceling,
  useReportCanSearch,
  useReportCheckedMachines,
  useReportEndDate,
  useReportFetchErrorResult,
  useReportFilteredShips,
  useReportFilterShipName,
  useReportInvalidEndDate,
  useReportInvalidRange,
  useReportInvalidStartDate,
  useReportItems,
  useReportOperationReportCheck,
  useReportProgressOpen,
  useReportStartDate,
} from './reportSlice';
import { Box, Checkbox, Divider, FormControl, Grid, styled, Typography } from '@mui/material';
import { ApiErrorDialog } from 'components/ApiErrorDialog';
import { DatePicker } from 'components/DatePicker';
import constants from 'resources/constants';
import { theme } from 'resources/theme';
import { SaveAlt } from '@mui/icons-material';
import { NormalButton } from 'components/NormalButton';
import dimens from 'resources/dimens';

const RootDiv = styled('div')({
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'flex-start',
  background: colors.subDrawer.background,
});

const ConditionGrid = styled(Grid)({
  paddingTop: theme.spacing(5),
});

const DateRow = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  width: '100%',
  background: theme.palette.background.default,
});

const ErrorRow = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  width: '100%',
  background: theme.palette.background.default,
  paddingLeft: theme.spacing(0.5),
  paddingRight: theme.spacing(0.5),
  paddingTop: 0,
  paddingBottom: theme.spacing(1),
});

const FooterDiv = styled('div')({
  width: '100%',
  padding: theme.spacing(1),
  textAlign: 'left',
});

interface ReportPanelProps {
  width: number;
  height: number;
}

export function ReportPanel(props: ReportPanelProps): JSX.Element {
  const { width, height } = props;
  AppLogger.debug('  CALL: ReportPanel');
  const intl = useIntl();
  const dispatch = useDispatch();
  const commonShips = useCommonShips();
  const reportProgressOpen = useReportProgressOpen();
  const fetchError = useReportFetchErrorResult();
  const reportCanceling = useReportCanceling();
  const filteredShips = useReportFilteredShips();
  const filterShipName = useReportFilterShipName();
  const checkedMachines = useReportCheckedMachines();
  const alarmReportCheck = useReportAlarmReportCheck();
  const operationReportCheck = useReportOperationReportCheck();
  const startDate = useReportStartDate();
  const endDate = useReportEndDate();
  const invalidStartDate = useReportInvalidStartDate();
  const invalidEndDate = useReportInvalidEndDate();
  const invalidRange = useReportInvalidRange();
  const canSearch = useReportCanSearch();
  const reportItems = useReportItems();

  /**
   * 検索入力変更
   * @param input 入力値
   */
  const handleSeachInputChange = (input: string) => {
    dispatch(setFilterShipName(input));
  };

  /**
   * 船舶リストのチェック状態変更
   * @param shipId 船舶ID
   * @param machineId 機械ID
   * @param checked チェック状態
   */
  const handleCheckChange = (shipId: string, machineId: number, checked: boolean) => {
    dispatch(setShipCheck({ shipId, machineId, checked }));
  };

  const handleProgressDialogClose = (result: DialogResult) => {
    dispatch(setReportProgressOpen(false));
    if (result === 'ok') {
      dispatch(setReportItems(undefined));
    } else {
      if (reportItems) {
        dispatch(cancelReport(reportItems));
      }
    }
  };

  /**
   * データ取得エラー
   */
  const handleReportFetchError = () => {
    dispatch(setReportFetchError(undefined));
  };

  /**
   * 開始日付の変更
   * @param date 日付ピッカー
   * @param value 日付
   */
  const handleStartDateChange = (value: string | undefined) => {
    if (value != null) {
      try {
        dispatch(setStartDate(dayjs(value).utc().format(constants.dateFormat.iso8601)));
      } catch (e) {
        dispatch(setStartDate(dayjs().utc().add(-1, 'day').format(constants.dateFormat.iso8601)));
      }
    }
  };

  /**
   * 終了日付の変更
   * @param date 日付ピッカー
   * @param value 日付
   */
  const handleEndDateChange = (value: string | undefined) => {
    if (value != null) {
      try {
        dispatch(setEndDate(dayjs(value).utc().format(constants.dateFormat.iso8601)));
      } catch (e) {
        dispatch(
          setEndDate(
            dayjs()
              .utc()
              .add(+1, 'day')
              .format(constants.dateFormat.iso8601)
          )
        );
      }
    }
  };

  const onDownloadClick = () => {
    if (commonShips != null) {
      const reportItems: ReportItem[] = [];
      commonShips.forEach((ship) => {
        const machines = ship.machines.filter((machine) =>
          checkedMachines.some((x) => x.machineId === machine.machineId)
        );
        if (machines.length > 0) {
          if (alarmReportCheck) {
            reportItems.push({
              reportId: generateUUID(),
              ship: ship,
              machines: machines,
              progress: 0,
              reportResult: 'Generating',
              reportType: 'Alarm',
            });
          }
          if (operationReportCheck) {
            reportItems.push({
              reportId: generateUUID(),
              ship: ship,
              machines: machines,
              progress: 0,
              reportResult: 'Generating',
              reportType: 'Operation',
            });
          }
        }
      });

      dispatch(fetchReport(reportItems, startDate, endDate));
    }
  };

  useEffect(() => {
    if (commonShips != null) {
      dispatch(setShips(commonShips));
    }
  }, [dispatch, commonShips]);

  return (
    <RootDiv
      sx={{
        width: width,
        height: height,
      }}
    >
      <Grid sx={{ height: height }} container wrap="nowrap">
        {/* 船舶選択 */}
        <SearchShipList
          ships={filteredShips}
          checkedMachines={checkedMachines}
          filterShipName={filterShipName}
          titleMsgId={msgId.homeReportShipTitle}
          onSearchInputChange={handleSeachInputChange}
          onCheckChange={handleCheckChange}
        />
        <Divider orientation="vertical" flexItem />
        <ConditionGrid item sx={{ height: height - 5, width: width - 300 }}>
          <CustomScrollBars autoHide={false}>
            {/* 出力範囲設定 */}
            <DateRow
              sx={{
                paddingTop: theme.spacing(1),
                paddingBottom: invalidRange ? 0 : theme.spacing(1),
              }}
            >
              <Typography
                variant="body2"
                noWrap
                sx={{ paddingLeft: theme.spacing(1), color: colors.accent }}
              >
                {intl.formatMessage({ id: msgId.homeReportDateLabel })}
              </Typography>
              <DatePicker
                value={startDate}
                type="datetime"
                displayFormat={constants.dateFormat.YYYYMMDDHHmm}
                onValueChanged={handleStartDateChange}
                error={invalidStartDate || invalidRange}
              />
              <Typography variant="body2" sx={{ paddingTop: '2px' }}>
                -
              </Typography>
              <DatePicker
                value={endDate}
                type="datetime"
                displayFormat={constants.dateFormat.YYYYMMDDHHmm}
                onValueChanged={handleEndDateChange}
                error={invalidEndDate || invalidRange}
              />
            </DateRow>
            {invalidRange && (
              <ErrorRow>
                <Typography
                  variant="body2"
                  noWrap
                  color="error"
                  sx={{ marginTop: theme.spacing(1), marginLeft: theme.spacing(0.5) }}
                >
                  {intl.formatMessage(
                    { id: msgId.homeReportPeriodRangeError },
                    { days: config.reportMaxDays }
                  )}
                </Typography>
              </ErrorRow>
            )}
            {/* 出力内容 */}
            <Box>
              <Typography
                variant="body2"
                noWrap
                sx={{
                  paddingTop: theme.spacing(1),
                  paddingLeft: theme.spacing(1),
                }}
              >
                {intl.formatMessage({ id: msgId.homeReportNoticeMessage })}
              </Typography>
              <FormControl
                sx={{
                  width: '100%',
                  paddingTop: '10px',
                }}
              >
                <Grid
                  container
                  wrap="nowrap"
                  alignItems="center"
                  sx={{
                    paddingLeft: theme.spacing(0.5),
                    paddingRight: theme.spacing(0.5),
                    minHeight: 24,
                  }}
                >
                  <Grid item>
                    <Checkbox
                      sx={{ width: 24, height: 24 }}
                      size="small"
                      color="primary"
                      value={alarmReportCheck}
                      disabled={false}
                      checked={alarmReportCheck}
                      onChange={(event) => dispatch(setAlarmReportCheck(event.target.checked))}
                    />
                  </Grid>
                  <Grid item>
                    <Typography variant="body2" noWrap sx={{ paddingLeft: theme.spacing(0.5) }}>
                      {intl.formatMessage({ id: msgId.homeReportAlarmLabel })}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  container
                  wrap="nowrap"
                  alignItems="center"
                  sx={{
                    paddingLeft: theme.spacing(0.5),
                    paddingRight: theme.spacing(0.5),
                    minHeight: 24,
                  }}
                >
                  <Grid item>
                    <Checkbox
                      sx={{ width: 24, height: 24 }}
                      size="small"
                      color="primary"
                      value={operationReportCheck}
                      disabled={false}
                      checked={operationReportCheck}
                      onChange={(event) => dispatch(setOperationReportCheck(event.target.checked))}
                    />
                  </Grid>
                  <Grid item>
                    <Typography variant="body2" noWrap sx={{ paddingLeft: theme.spacing(0.5) }}>
                      {intl.formatMessage({ id: msgId.homeReportOperationLabel })}
                    </Typography>
                  </Grid>
                </Grid>
              </FormControl>
            </Box>
            {/* downloadボタン */}
            <FooterDiv>
              <NormalButton
                sx={{ width: dimens.button.width }}
                label={intl.formatMessage({ id: msgId.homeReportButtonDownload })}
                color="primary"
                startIcon={<SaveAlt />}
                disabled={
                  checkedMachines.length === 0 ||
                  !(alarmReportCheck || operationReportCheck) ||
                  !canSearch
                }
                onClick={() => onDownloadClick()}
              />
            </FooterDiv>
          </CustomScrollBars>
        </ConditionGrid>
      </Grid>
      <FadeLoading loading={reportCanceling} />
      <ReportProgressPanel
        open={reportProgressOpen}
        onClose={handleProgressDialogClose}
        containerHeight={height}
      />
      {fetchError != null && <ApiErrorDialog error={fetchError} onClose={handleReportFetchError} />}
    </RootDiv>
  );
}
