import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import colors from 'resources/colors';
import { useIntl } from 'react-intl';
import msgId from 'resources/intl';
import {
  useAdminSettingsEditMode,
  useAdminSettingsEditShip,
  registrationShips,
  updateShips,
  deleteShips,
  registrationMachine,
  updateMachine,
  deleteMachine,
  setShipEditing,
  setEditShipError,
  useAdminSettingssEditShipError,
  useAdminSettingsEditShipResult as useAdminSettingsUpdateResult,
  setEditShipResult as setUpdateResult,
  useAdminSettingsFetching,
} from '../adminSettingsSlice';
import {
  EditDialog,
  EditDialogContents,
  EditDialogInputFieldGrid,
  EditDialogMiniButton,
  EditDialogMiniDeleteButton,
  EditDialogRootGrid,
  EditDialogTitleGrid,
} from 'components/EditDialog';
import { AdminShip, AdminMachine } from 'models/admin';
import ClearIcon from '@mui/icons-material/Clear';
import constants from 'resources/constants';
import { Button, Grid, MenuItem, styled, SxProps, Typography, FormHelperText } from '@mui/material';
import { SmallTextField } from 'components/SmallTextField';
import { CustomScrollBars } from 'components/CustomScrollBars';
import { theme } from 'resources/theme';
import { AddCircle } from '@mui/icons-material';
import { SelectTextField } from 'components/SelectTextField';
import { ErrorInfo, ErrorMessages, FieldLabels, getErrorMessage } from 'models/error';
import { AlertDialog } from 'components/AlertDialog';
import dimens from 'resources/dimens';
import { FadeLoading } from 'components/FadeLoading';

const RowRightGrid = styled(Grid)({
  width: 380 + 32 + 32,
  justifyContent: 'end',
  display: 'flex',
});

const RowLeftGrid = styled(Grid)({
  width: 380 + 32 + 32,
  justifyContent: 'start',
  display: 'flex',
  alignItems: 'flex-start',
});

const ShipBlockGrid = styled(Grid)({
  width: 380 + 32 + 32 + 100,
  paddingLeft: 50,
  paddingRight: 25,
  marginTop: 5,
});

const MachineBlockGrid = styled(Grid)({
  width: 380 + 32 + 32 + 100,
  paddingLeft: 50,
  paddingRight: 25,
  marginTop: 5,
  background: colors.subDrawer.background,
});

const ButtonGrid = styled(Grid)({
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
});

const ClearButtonGrid = styled(Grid)({
  paddingTop: theme.spacing(2),
  paddingLeft: theme.spacing(1),
  width: 32,
});

const AddButtonGrid = styled(Grid)({
  paddingTop: theme.spacing(2),
  paddingLeft: theme.spacing(1),
  width: 32,
});

const textFieldSxProps: SxProps = {
  backgroundColor: theme.palette.background.default,
  borderRadius: '4px',
  width: '100%',
  marginBottom: theme.spacing(1),
};

const textFieldReadOnlySxProps: SxProps = {
  backgroundColor: theme.palette.background.default,
  borderRadius: '4px',
  width: '100%',
  marginTop: '5px',
  marginBottom: theme.spacing(1),
};

const formControlSxProps: SxProps = {
  backgroundColor: theme.palette.background.default,
  borderRadius: '4px',
  width: '100%',
  marginBottom: theme.spacing(1),
};

const formControlReadOnlySxProps: SxProps = {
  backgroundColor: theme.palette.background.default,
  borderRadius: '4px',
  width: '100%',
  marginTop: '5px',
  marginBottom: theme.spacing(1),
};

const FormHelperTextProps: SxProps = {
  backgroundColor: colors.subDrawer,
  paddingLeft: '14px',
  paddingRight: '14px',
  marginTop: '-5px',
  color: colors.helperText.error,
};

type EditType = 'ship' | 'machine';
type EditProc = 'new' | 'update';
interface EditItem {
  editType: EditType;
  machineIndex: number | undefined;
  editProc: EditProc;
}

interface ShipEditDialogProps {
  contentHeight: number;
}

export function ShipEditDialog(props: ShipEditDialogProps): JSX.Element {
  const { contentHeight } = props;
  const intl = useIntl();
  const dispatch = useDispatch();
  const editMode = useAdminSettingsEditMode();
  const editShip = useAdminSettingsEditShip();
  const updateResult = useAdminSettingsUpdateResult();
  const editShipError = useAdminSettingssEditShipError();
  const inProgress = useAdminSettingsFetching();
  const [state, setState] = useState<AdminShip>({
    shipId: '',
    shipName: '',
    machines: [],
    propulsionSerialNumbers: undefined,
  });
  const [editMachine, setEditMachine] = useState<AdminMachine>({
    machineId: '',
    name: '',
    dataFormatId: 400,
    serialNumber: undefined,
  });
  const [editItem, setEditItem] = useState<EditItem | undefined>(undefined);
  const [deleteParam, setDeleteParam] = useState<
    { type: EditType; machineIndex: number | undefined } | undefined
  >(undefined);
  const [confirmedDeleteParam, setConfirmedDeleteParam] = useState<
    { type: EditType; machineIndex: number | undefined } | undefined
  >(undefined);
  const [isBack, setIsBack] = useState<boolean>(false);

  // if (updateResult != null) {
  //   if (updateResult.isSuccess) {
  //     if (editItem != null) {
  //       setEditItem(undefined);
  //     }
  //   }
  // }

  let errorMsg: ErrorMessages = {};
  let errors: ErrorInfo[] = [];
  const labels: FieldLabels = {
    PARAMETER_LENGTH_ERROR: {
      fallback: intl.formatMessage({ id: msgId.errorMessageLengthError100 }),
      shipId: intl.formatMessage({ id: msgId.errorMessageLengthError50 }),
      machineId: intl.formatMessage({ id: msgId.errorMessageLengthError15 }),
    },
    PARAMETER_FORMAT_ERROR: {
      fallback: intl.formatMessage({ id: msgId.errorMessageParameterRequirements }),
    },
    PARAMETER_REQUIREMENTS_ERROR: {
      fallback: intl.formatMessage({ id: msgId.errorMessageParameterRequirements }),
    },
    PARAMETER_DUPLICATION_ERROR: {
      fallback: intl.formatMessage({ id: msgId.errorMessageParameterRequirements }),
    },
    PARAMETER_REFERENCE_ERROR: {
      fallback: undefined,
    },
    fallback: { fallback: intl.formatMessage({ id: msgId.errorMessageOther }) },
  };

  if (editShipError) {
    if (Array.isArray(editShipError.response)) {
      errors = editShipError.response;
    } else {
      errors = [editShipError.response];
    }
  }
  errorMsg = getErrorMessage(errors, labels);

  const propulsionSerialNumbersMsg: {
    [key: number]: string;
  } = [];
  const serialNumbersMsg: {
    [key: number]: string;
  } = [];
  Object.keys(errorMsg).forEach((key) => {
    let index = 0;
    if (key.includes('propulsionSerialNumbers')) {
      if (key.includes('[')) {
        let vals = key.split('[');
        vals = vals[1].split(']');
        index = parseInt(vals[0], 10);
      }
      if (propulsionSerialNumbersMsg[index] === undefined && errorMsg[key]) {
        propulsionSerialNumbersMsg[index] = errorMsg[key].replace(
          key,
          intl.formatMessage({ id: msgId.propulsionSerialNumber })
        );
      }
    } else if (key.includes('serialNumbers')) {
      if (key.includes('[')) {
        let vals = key.split('[');
        vals = vals[1].split(']');
        index = parseInt(vals[0], 10);
      }
      if (serialNumbersMsg[index] === undefined && errorMsg[key]) {
        serialNumbersMsg[index] = errorMsg[key].replace(
          key,
          intl.formatMessage({ id: msgId.plcSerialNumber })
        );
      }
    }
  });

  // shipIdの必須チェック
  if (!state.shipId && !errorMsg['shipId']) {
    errorMsg['shipId'] = intl.formatMessage({ id: msgId.messageInputRequired });
  }

  // shipNameの必須チェック
  if (!state.shipName && !errorMsg['shipName']) {
    errorMsg['shipName'] = intl.formatMessage({ id: msgId.messageInputRequired });
  }

  // 登録・更新ボタンの必須チェック
  const positiveDisableShip =
    !state.shipId ||
    !state.shipName ||
    !state.propulsionSerialNumbers ||
    state.propulsionSerialNumbers.some((x: string) => !x);
  const positiveDisableMachine =
    isNaN(parseInt(editMachine.machineId + '', 10)) ||
    !editMachine.name ||
    !editMachine.serialNumber ||
    editMachine.serialNumber.split(',').some((x: string) => !x);

  // propulsionSerialNumbersの必須チェック
  (state.propulsionSerialNumbers ? state.propulsionSerialNumbers : ['']).forEach((val, index) => {
    if (!propulsionSerialNumbersMsg[index] && val.length === 0) {
      propulsionSerialNumbersMsg[index] = intl.formatMessage({ id: msgId.messageInputRequired });
    }
  });

  // machineIdの必須チェック
  if (
    !editMachine.machineId &&
    !errorMsg['machineId'] &&
    !(confirmedDeleteParam && confirmedDeleteParam.type === 'machine')
  ) {
    errorMsg['machineId'] = intl.formatMessage({ id: msgId.messageInputRequired });
  }

  // machineNameの必須チェック
  if (
    !editMachine.name &&
    !errorMsg['machineName'] &&
    !(confirmedDeleteParam && confirmedDeleteParam.type === 'machine')
  ) {
    errorMsg['machineName'] = intl.formatMessage({ id: msgId.messageInputRequired });
  }

  // dataFormatIdの必須チェック
  if (
    !editMachine.dataFormatId &&
    !errorMsg['dataFormatId'] &&
    !(confirmedDeleteParam && confirmedDeleteParam.type === 'machine')
  ) {
    errorMsg['dataFormatId'] = intl.formatMessage({ id: msgId.messageInputRequired });
  }

  // serialNumberの必須チェック
  (editMachine.serialNumber ? editMachine.serialNumber : '').split(',').forEach((val, index) => {
    if (
      !serialNumbersMsg[index] &&
      val.length === 0 &&
      !(confirmedDeleteParam && confirmedDeleteParam.type === 'machine')
    ) {
      serialNumbersMsg[index] = intl.formatMessage({ id: msgId.messageInputRequired });
    }
  });

  /**
   * バックボタン押下
   */
  const handleBackClick = () => {
    dispatch(setShipEditing({ editing: false, ship: undefined }));
    dispatch(setEditShipError(undefined));
    setState({
      shipId: '',
      shipName: '',
      machines: [],
      propulsionSerialNumbers: undefined,
    });
    setEditMachine({
      machineId: undefined,
      name: '',
      dataFormatId: 400,
      serialNumber: undefined,
    });
    setDeleteParam(undefined);
    setConfirmedDeleteParam(undefined);
  };

  /**
   * 削除ボタン押下時に呼ばれる。
   */
  const handleDeleteClick = (type: EditType, index?: number) => {
    setDeleteParam({ type: type, machineIndex: index });
  };

  /**
   * 登録ボタン押下時に呼ばれる。
   */
  const handleRegistrationClick = () => {
    dispatch(setEditShipError(undefined));
    if (editItem) {
      if (editItem.editType === 'ship') {
        if (editItem.editProc === 'new') {
          dispatch(registrationShips(state));
        } else {
          dispatch(updateShips(state.shipId, state));
        }
      } else {
        if (editItem.editProc === 'new') {
          if (editMachine.machineId != null) {
            dispatch(registrationMachine(state.shipId, editMachine));
          }
        } else {
          dispatch(updateMachine(state.shipId, String(editMachine.machineId), editMachine));
        }
      }
      setDeleteParam(undefined);
      setConfirmedDeleteParam(undefined);
    }
  };

  /**
   * 船舶情報変更
   */
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    let val = event.target.value;
    if (event.target.id === 'shipId') {
      val = event.target.value.replace(/[^0-9a-zA-Z]/g, '');
    }
    setState({ ...state, [event.target.id]: val });
  };

  /**
   * 推進機製造番号値変更
   */
  const handlePropulsionSerialNumberChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    if (state.propulsionSerialNumbers) {
      const values = state.propulsionSerialNumbers.concat();
      values[index] = event.target.value;
      setState({ ...state, [event.target.id]: values });
    } else {
      const values = [];
      values[index] = event.target.value;
      setState({ ...state, [event.target.id]: values });
    }
  };

  /**
   * 推進機製造番号削除
   */
  const handlePropulsionSerialNumbersClear = (index: number) => {
    if (state.propulsionSerialNumbers) {
      const values = state.propulsionSerialNumbers.filter((val, idx) => idx !== index);
      setState({ ...state, ['propulsionSerialNumbers']: values.length === 0 ? undefined : values });
    }
  };

  /**
   * 推進機製造番号追加
   */
  const handlePropulsionSerialNumbersAdd = () => {
    if (state.propulsionSerialNumbers) {
      const values = state.propulsionSerialNumbers.concat();
      values.push('');
      setState({ ...state, ['propulsionSerialNumbers']: values });
    } else {
      setState({ ...state, ['propulsionSerialNumbers']: ['', ''] });
    }
  };

  /**
   * 編集状態の設定
   */
  const handleEditMode = (edit: EditItem) => {
    setEditItem(edit);
    if (edit.editProc === 'update') {
      if (edit.editType === 'machine') {
        if (state.machines) {
          setEditMachine({
            machineId: state.machines[edit.machineIndex as number].machineId,
            name: state.machines[edit.machineIndex as number].name,
            dataFormatId: state.machines[edit.machineIndex as number].dataFormatId,
            serialNumber: state.machines[edit.machineIndex as number].serialNumber,
          });
        }
      }
    }
    dispatch(setEditShipError(undefined));
  };

  const handleCancelClick = () => {
    if (editItem?.editProc === 'new' && editItem?.editType === 'machine' && state.machines) {
      const machines = state.machines.map((x) => x);
      machines.pop();
      setState({ ...state, ['machines']: machines });
    }
    if (editItem?.editType === 'machine') {
      setEditMachine({
        machineId: undefined,
        name: '',
        dataFormatId: 400,
        serialNumber: undefined,
      });
    } else if (editItem?.editType === 'ship') {
      setState({
        shipId: editShip ? editShip.shipId : '',
        shipName: editShip ? editShip.name : '',
        machines: editShip
          ? editShip.machines.map((x) => {
              return {
                dataFormatId: x.dataFormatId,
                machineId: x.machineId,
                name: x.name,
                serialNumber: x.serialNumbers.join(','),
              };
            })
          : [],
        propulsionSerialNumbers: editShip
          ? editShip.propulsionSerialNumbers.map((x) => x)
          : undefined,
      });
    }
    setEditItem(undefined);
    dispatch(setEditShipError(undefined));
  };

  /**
   * 機械情報追加
   */
  const handleMachineAdd = () => {
    if (state.machines) {
      const machines = state.machines.map((x) => x);
      machines.push(editMachine);
      setState({ ...state, ['machines']: machines });
      setEditItem({ editType: 'machine', machineIndex: machines.length - 1, editProc: 'new' });
    } else {
      const machines: AdminMachine[] = [];
      machines.push(editMachine);
      setState({ ...state, ['machines']: machines });
      setEditItem({ editType: 'machine', machineIndex: machines.length - 1, editProc: 'new' });
    }
    setEditMachine({
      machineId: undefined,
      name: '',
      dataFormatId: 400,
      serialNumber: '',
    });
    setDeleteParam(undefined);
    setConfirmedDeleteParam(undefined);
    dispatch(setEditShipError(undefined));
  };

  /**
   * シリアルナンバークリア
   */
  const handleSerialNumbersClear = (index: number) => {
    if (editMachine.serialNumber !== undefined) {
      if (index === 0 && editMachine.serialNumber.split(',').length === 0) {
        setEditMachine({ ...editMachine, ['serialNumber']: undefined });
      } else {
        const serialNumbers = editMachine.serialNumber.split(',');
        serialNumbers.splice(index, 1);
        const serialNumber = serialNumbers.join(',');
        setEditMachine({ ...editMachine, ['serialNumber']: serialNumber });
      }
    } else {
      setEditMachine({ ...editMachine, ['serialNumber']: '' });
    }
  };

  /**
   * 機械値変更
   */
  const handleMachineChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let val: string | number = event.target.value;
    if (event.target.id === 'machineId') {
      val = event.target.value.replace(/[^0-9]/g, '');
    }
    setEditMachine({ ...editMachine, [event.target.id]: val });
  };

  const handleMachineBlur = () => {
    if (editMachine.machineId != null && typeof editMachine.machineId === 'string') {
      const m = editMachine.machineId.match(/^([0]*)(.*)$/);
      if (m != null && m[2] != null) {
        setEditMachine({ ...editMachine, machineId: m[2] });
      }
    }
  };

  const handleMachineDataFormatIdChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setEditMachine({ ...editMachine, dataFormatId: parseInt(event.target.value as string, 10) });
  };

  /**
   * PLC製造番号値変更
   */
  const handleSerialNumberChange = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
    let serialNumbers = [''];
    if (editMachine.serialNumber !== undefined) {
      serialNumbers = editMachine.serialNumber.split(',');
      serialNumbers[index] = event.target.value;
      const serialNumber = serialNumbers.join(',');
      setEditMachine({ ...editMachine, [event.target.id]: serialNumber });
    } else {
      serialNumbers[index] = event.target.value;
      serialNumbers.join(',');
      const serialNumber = serialNumbers.join(',');
      setEditMachine({ ...editMachine, [event.target.id]: serialNumber });
    }
  };

  /**
   * シリアルナンバー追加
   */
  const handleSerialNumbersAdd = () => {
    if (editMachine.serialNumber === undefined) {
      setEditMachine({ ...editMachine, serialNumber: ',' });
    } else {
      setEditMachine({ ...editMachine, serialNumber: editMachine.serialNumber + ',' });
    }
  };

  /**
   * 結果クリア
   */
  const handleResultClear = (isSuccess: boolean) => {
    dispatch(setUpdateResult(undefined));
    if (isSuccess) {
      if (isBack) {
        dispatch(setShipEditing({ editing: false, ship: undefined }));
        setIsBack(false);
        setState({
          shipId: '',
          shipName: '',
          machines: [],
          propulsionSerialNumbers: undefined,
        });
        setEditMachine({
          machineId: undefined,
          name: '',
          dataFormatId: 400,
          serialNumber: undefined,
        });
      }
      dispatch(setEditShipError(undefined));
      setConfirmedDeleteParam(undefined);
      setDeleteParam(undefined);
    }
  };

  /**
   * 削除実行時に呼ばれる。
   */
  const handleDeleteClickOk = (result: string) => {
    if (result === 'ok') {
      setConfirmedDeleteParam(deleteParam);
      dispatch(setEditShipError(undefined));
      if (deleteParam?.type === 'ship' && editShip) {
        dispatch(deleteShips(editShip.shipId));
        setIsBack(true);
      } else if (editShip && deleteParam && deleteParam.machineIndex !== undefined) {
        dispatch(
          deleteMachine(
            editShip.shipId,
            String(editShip.machines[deleteParam.machineIndex].machineId)
          )
        );
      }
    }
    setDeleteParam(undefined);
  };

  /**
   * 初期化
   */
  useEffect(() => {
    setState({
      shipId: editShip ? editShip.shipId : '',
      shipName: editShip ? editShip.name : '',
      machines: editShip
        ? editShip.machines.map((x) => {
            return {
              machineId: x.machineId,
              name: x.name,
              dataFormatId: x.dataFormatId,
              serialNumber: x.serialNumbers.join(','),
            };
          })
        : [],
      propulsionSerialNumbers: editShip
        ? editShip.propulsionSerialNumbers.map((x) => x)
        : undefined,
    });
    if (!editShip) {
      setEditItem({ editType: 'ship', machineIndex: undefined, editProc: 'new' });
    } else {
      setEditItem(undefined);
    }
  }, [dispatch, editShip]);

  useEffect(() => {
    if (updateResult && updateResult.isSuccess && isBack) {
      if (isBack) {
        dispatch(setShipEditing({ editing: false, ship: undefined }));
        setIsBack(false);
        setState({
          shipId: '',
          shipName: '',
          machines: [],
          propulsionSerialNumbers: undefined,
        });
        setEditMachine({
          machineId: undefined,
          name: '',
          dataFormatId: 400,
          serialNumber: undefined,
        });
      }
      dispatch(setUpdateResult(undefined));
      dispatch(setEditShipError(undefined));
      setConfirmedDeleteParam(undefined);
    }
    if (updateResult) {
      if (updateResult.isSuccess && updateResult.isDeleteRequest) {
        dispatch(setUpdateResult(undefined));
      }
    }
  }, [dispatch, updateResult, isBack]);

  return (
    <EditDialog
      id="shipEditDialog"
      open={editMode === 'ship'}
      onBackClick={handleBackClick}
      title={intl.formatMessage({
        id: editShip ? msgId.shipInformation : msgId.shipNewRegistration,
      })}
    >
      <EditDialogContents>
        <CustomScrollBars
          thumbColor={colors.scrollBar.thumb.light}
          height={contentHeight}
          width={dimens.dialogContents.width}
          autoHide={false}
        >
          <EditDialogRootGrid>
            <EditDialogTitleGrid>
              <Typography variant="h5">
                {intl.formatMessage({ id: msgId.guideMessageEditShip })}
              </Typography>
            </EditDialogTitleGrid>
            <ShipBlockGrid
              container
              wrap="nowrap"
              direction="column"
              justifyContent="center"
              alignItems="center"
            >
              <RowRightGrid>
                <ButtonGrid item>
                  {!(editItem?.editType === 'ship' && editItem.editProc === 'update') &&
                    editShip && (
                      <EditDialogMiniDeleteButton
                        color="inherit"
                        onClick={() => handleDeleteClick('ship')}
                        disabled={!!editItem}
                      >
                        {intl.formatMessage({ id: msgId.delete })}
                      </EditDialogMiniDeleteButton>
                    )}
                  {editItem?.editType === 'ship' && editItem.editProc === 'update' && (
                    <EditDialogMiniButton color="primary" onClick={() => handleCancelClick()}>
                      {intl.formatMessage({ id: msgId.cancel })}
                    </EditDialogMiniButton>
                  )}
                  {editItem?.editType === 'ship' ? (
                    <EditDialogMiniButton
                      color="primary"
                      onClick={() => handleRegistrationClick()}
                      disabled={positiveDisableShip}
                    >
                      {editShip
                        ? intl.formatMessage({ id: msgId.trendButtonUpdate })
                        : intl.formatMessage({ id: msgId.registration })}
                    </EditDialogMiniButton>
                  ) : (
                    <EditDialogMiniButton
                      color="primary"
                      disabled={!!editItem}
                      onClick={() =>
                        handleEditMode({
                          editType: 'ship',
                          machineIndex: undefined,
                          editProc: 'update',
                        })
                      }
                    >
                      {intl.formatMessage({ id: msgId.labelEdit })}
                    </EditDialogMiniButton>
                  )}
                </ButtonGrid>
              </RowRightGrid>
              <RowLeftGrid>
                <EditDialogInputFieldGrid>
                  <SmallTextField
                    id="shipId"
                    variant={
                      editItem
                        ? editItem.editType === 'ship' && !editShip
                          ? 'outlined'
                          : 'standard'
                        : 'standard'
                    }
                    label={intl.formatMessage({ id: msgId.shipId })}
                    sx={
                      !(editItem?.editType === 'ship' && editItem.editProc === 'new')
                        ? textFieldSxProps
                        : textFieldReadOnlySxProps
                    }
                    value={state.shipId}
                    required={true}
                    disabled={!(editItem?.editType === 'ship' && editItem.editProc === 'new')}
                    onChange={handleChange}
                    error={!!errorMsg['shipId']}
                    helperText={errorMsg['shipId']}
                    autoComplete="off"
                  />
                </EditDialogInputFieldGrid>
              </RowLeftGrid>
              <RowLeftGrid>
                <EditDialogInputFieldGrid>
                  <SmallTextField
                    id="shipName"
                    variant={
                      editItem
                        ? editItem.editType === 'ship'
                          ? 'outlined'
                          : 'standard'
                        : 'standard'
                    }
                    label={intl.formatMessage({ id: msgId.shipName })}
                    sx={
                      !(editItem?.editType === 'ship') ? textFieldSxProps : textFieldReadOnlySxProps
                    }
                    value={state.shipName}
                    required={true}
                    InputLabelProps={{
                      focused: false,
                    }}
                    disabled={!(editItem ? editItem.editType === 'ship' : false)}
                    onChange={handleChange}
                    error={!!errorMsg['shipName']}
                    helperText={errorMsg['shipName']}
                    autoComplete="off"
                  />
                </EditDialogInputFieldGrid>
              </RowLeftGrid>
              {(state.propulsionSerialNumbers === undefined
                ? ['']
                : state.propulsionSerialNumbers
              ).map((val, index) => (
                <RowLeftGrid
                  key={'propulsionSerialNumbers' + index}
                  direction="row"
                  container
                  alignItems="center"
                >
                  <EditDialogInputFieldGrid>
                    <SmallTextField
                      id="propulsionSerialNumbers"
                      variant={
                        editItem
                          ? editItem.editType === 'ship'
                            ? 'outlined'
                            : 'standard'
                          : 'standard'
                      }
                      fullWidth
                      label={intl.formatMessage({ id: msgId.propulsionSerialNumber })}
                      sx={
                        !(editItem?.editType === 'ship')
                          ? textFieldSxProps
                          : textFieldReadOnlySxProps
                      }
                      value={val}
                      required={true}
                      disabled={!(editItem ? editItem.editType === 'ship' : false)}
                      onChange={(event) => handlePropulsionSerialNumberChange(event, index)}
                      error={!!propulsionSerialNumbersMsg[index]}
                      helperText={
                        propulsionSerialNumbersMsg[index] ? propulsionSerialNumbersMsg[index] : ''
                      }
                      autoComplete="off"
                    />
                  </EditDialogInputFieldGrid>
                  <ClearButtonGrid item>
                    {editItem?.editType === 'ship' && (
                      <ClearIcon
                        htmlColor={colors.border}
                        onClick={() => handlePropulsionSerialNumbersClear(index)}
                        sx={{ cursor: 'pointer' }}
                      />
                    )}
                  </ClearButtonGrid>
                  <AddButtonGrid item>
                    {editItem?.editType === 'ship' &&
                      (state.propulsionSerialNumbers
                        ? state.propulsionSerialNumbers.length - 1 === index
                        : true) && (
                        <AddCircle
                          color="primary"
                          onClick={() => handlePropulsionSerialNumbersAdd()}
                          sx={{ cursor: 'pointer' }}
                        />
                      )}
                  </AddButtonGrid>
                </RowLeftGrid>
              ))}
            </ShipBlockGrid>

            {/* マシン設定*/}
            {state.machines &&
              state.machines.map((machine, index) => (
                <MachineBlockGrid
                  container
                  wrap="nowrap"
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                  key={'ship-machine' + index}
                >
                  <RowRightGrid>
                    <ButtonGrid item>
                      {!(editItem?.editType === 'machine' && editItem?.machineIndex === index) && (
                        <EditDialogMiniDeleteButton
                          variant="outlined"
                          disableElevation
                          color="inherit"
                          disabled={!!editItem}
                          onClick={() => handleDeleteClick('machine', index)}
                        >
                          {intl.formatMessage({ id: msgId.delete })}
                        </EditDialogMiniDeleteButton>
                      )}
                      {editItem?.editType === 'machine' && editItem?.machineIndex === index && (
                        <EditDialogMiniButton
                          variant="outlined"
                          disableElevation
                          color="primary"
                          onClick={() => handleCancelClick()}
                        >
                          {intl.formatMessage({ id: msgId.cancel })}
                        </EditDialogMiniButton>
                      )}
                      {editItem?.editType === 'machine' && editItem.machineIndex === index ? (
                        <EditDialogMiniButton
                          variant="outlined"
                          disableElevation
                          color="primary"
                          onClick={() => handleRegistrationClick()}
                          disabled={positiveDisableMachine}
                        >
                          {editItem.editType === 'machine' && editItem.editProc === 'update'
                            ? intl.formatMessage({ id: msgId.trendButtonUpdate })
                            : intl.formatMessage({ id: msgId.registration })}
                        </EditDialogMiniButton>
                      ) : (
                        <EditDialogMiniButton
                          color="primary"
                          disabled={!!editItem}
                          onClick={() =>
                            handleEditMode({
                              editType: 'machine',
                              machineIndex: index,
                              editProc: 'update',
                            })
                          }
                        >
                          {intl.formatMessage({ id: msgId.labelEdit })}
                        </EditDialogMiniButton>
                      )}
                    </ButtonGrid>
                  </RowRightGrid>
                  <RowLeftGrid>
                    <EditDialogInputFieldGrid>
                      <SmallTextField
                        id="machineId"
                        variant={
                          editItem
                            ? /** 編集状態になる条件:machineのindexが編集中か */
                              editItem.editType === 'machine' &&
                              editItem.machineIndex === index &&
                              editItem.editProc === 'new'
                              ? 'outlined'
                              : 'standard'
                            : 'standard'
                        }
                        label={intl.formatMessage({ id: msgId.machineId })}
                        sx={
                          !(
                            editItem?.editType === 'machine' &&
                            editItem?.machineIndex === index &&
                            editItem.editProc === 'new'
                          )
                            ? textFieldSxProps
                            : textFieldReadOnlySxProps
                        }
                        value={
                          editItem &&
                          editItem.editType === 'machine' &&
                          editItem.machineIndex === index &&
                          editItem.editProc === 'new'
                            ? editMachine.machineId
                            : machine.machineId
                        }
                        required={true}
                        disabled={
                          !machine ||
                          (editItem
                            ? /** 編集状態になる条件:machineのindexが編集中か */
                              editItem.editType === 'machine' && editItem.machineIndex === index
                              ? editItem.editProc === 'update'
                              : true
                            : true)
                        }
                        onChange={handleMachineChange}
                        onBlur={handleMachineBlur}
                        error={
                          ((editItem?.editType === 'machine' && editItem.machineIndex === index) ||
                            (confirmedDeleteParam?.type === 'machine' &&
                              confirmedDeleteParam.machineIndex === index)) &&
                          !!errorMsg['machineId']
                        }
                        autoComplete="off"
                      />
                      {((editItem?.editType === 'machine' && editItem.machineIndex === index) ||
                        (confirmedDeleteParam?.type === 'machine' &&
                          confirmedDeleteParam.machineIndex === index)) &&
                        !!errorMsg['machineId'] && (
                          <FormHelperText id="machineId" sx={FormHelperTextProps}>
                            {errorMsg['machineId']}
                          </FormHelperText>
                        )}
                    </EditDialogInputFieldGrid>
                  </RowLeftGrid>
                  <RowLeftGrid>
                    <EditDialogInputFieldGrid>
                      <SmallTextField
                        id="name"
                        variant={
                          editItem
                            ? /** 編集状態になる条件:machineのindexが編集中か */
                              editItem.editType === 'machine' && editItem.machineIndex === index
                              ? 'outlined'
                              : 'standard'
                            : 'standard'
                        }
                        label={intl.formatMessage({ id: msgId.machineName })}
                        sx={
                          !(editItem?.editType === 'machine' && editItem?.machineIndex === index)
                            ? textFieldSxProps
                            : textFieldReadOnlySxProps
                        }
                        value={
                          editItem &&
                          editItem.editType === 'machine' &&
                          editItem.machineIndex === index
                            ? editMachine.name
                            : machine.name
                        }
                        required={true}
                        disabled={
                          editItem
                            ? /** 編集状態になる条件:machineのindexが編集中か */
                              editItem.editType === 'machine' && editItem.machineIndex === index
                              ? false
                              : true
                            : true
                        }
                        onChange={handleMachineChange}
                        error={
                          ((editItem?.editType === 'machine' && editItem.machineIndex === index) ||
                            (confirmedDeleteParam?.type === 'machine' &&
                              confirmedDeleteParam.machineIndex === index)) &&
                          !!errorMsg['machineName']
                        }
                        autoComplete="off"
                      />
                      {((editItem?.editType === 'machine' && editItem.machineIndex === index) ||
                        (confirmedDeleteParam?.type === 'machine' &&
                          confirmedDeleteParam.machineIndex === index)) &&
                        !!errorMsg['machineName'] && (
                          <FormHelperText id="name" sx={FormHelperTextProps}>
                            {errorMsg['machineName']}
                          </FormHelperText>
                        )}
                    </EditDialogInputFieldGrid>
                  </RowLeftGrid>
                  <RowLeftGrid>
                    <EditDialogInputFieldGrid>
                      <SelectTextField
                        id="dataFormatId"
                        label={intl.formatMessage({ id: msgId.dataFormatId })}
                        sx={
                          !(editItem?.editType === 'machine' && editItem?.machineIndex === index)
                            ? formControlSxProps
                            : formControlReadOnlySxProps
                        }
                        disabled={
                          !(
                            editItem &&
                            editItem.editType === 'machine' &&
                            editItem.machineIndex === index
                          )
                        }
                        value={
                          editItem &&
                          editItem.editType === 'machine' &&
                          editItem.machineIndex === index
                            ? editMachine.dataFormatId
                            : machine.dataFormatId
                        }
                        required={true}
                        onChange={handleMachineDataFormatIdChange}
                        variant={
                          editItem
                            ? /** 編集状態になる条件:machineのindexが編集中か */
                              editItem.editType === 'machine' && editItem.machineIndex === index
                              ? 'outlined'
                              : 'standard'
                            : 'standard'
                        }
                        error={
                          ((editItem?.editType === 'machine' && editItem.machineIndex === index) ||
                            (confirmedDeleteParam?.type === 'machine' &&
                              confirmedDeleteParam.machineIndex === index)) &&
                          !!errorMsg['dataFormatId']
                        }
                      >
                        {constants.dataFormatIds.map((dataFormatI) => {
                          return (
                            <MenuItem value={dataFormatI} key={dataFormatI}>
                              {dataFormatI}
                            </MenuItem>
                          );
                        })}
                      </SelectTextField>
                      {((editItem?.editType === 'machine' && editItem.machineIndex === index) ||
                        (confirmedDeleteParam?.type === 'machine' &&
                          confirmedDeleteParam.machineIndex === index)) &&
                        !!errorMsg['dataFormatId'] && (
                          <FormHelperText id="machineId" sx={FormHelperTextProps}>
                            {errorMsg['dataFormatId']}
                          </FormHelperText>
                        )}
                    </EditDialogInputFieldGrid>
                  </RowLeftGrid>
                  {(editItem && editItem.editType === 'machine' && editItem.machineIndex === index
                    ? editMachine.serialNumber
                      ? editMachine.serialNumber
                      : ''
                    : machine.serialNumber
                    ? machine.serialNumber
                    : ''
                  )
                    .split(',')
                    .map((val, serialIndex) => (
                      <RowLeftGrid
                        key={'plcSerialNumber' + index + '_' + serialIndex}
                        direction="row"
                        container
                        alignItems="center"
                      >
                        <EditDialogInputFieldGrid>
                          <SmallTextField
                            id="serialNumber"
                            variant={
                              editItem
                                ? /** 編集状態になる条件:machineのindexが編集中か */
                                  editItem.editType === 'machine' && editItem.machineIndex === index
                                  ? 'outlined'
                                  : 'standard'
                                : 'standard'
                            }
                            label={intl.formatMessage({ id: msgId.plcSerialNumber })}
                            sx={
                              !(
                                editItem?.editType === 'machine' && editItem?.machineIndex === index
                              )
                                ? textFieldSxProps
                                : textFieldReadOnlySxProps
                            }
                            value={val}
                            required={true}
                            disabled={
                              editItem
                                ? /** 編集状態になる条件:machineのindexが編集中か */
                                  editItem.editType === 'machine' && editItem.machineIndex === index
                                  ? false
                                  : true
                                : true
                            }
                            onChange={(event) => handleSerialNumberChange(event, serialIndex)}
                            error={
                              ((editItem?.editType === 'machine' &&
                                editItem.machineIndex === index) ||
                                (confirmedDeleteParam?.type === 'machine' &&
                                  confirmedDeleteParam.machineIndex === index)) &&
                              !!serialNumbersMsg[serialIndex]
                            }
                            autoComplete="off"
                          />
                          {((editItem?.editType === 'machine' && editItem.machineIndex === index) ||
                            (confirmedDeleteParam?.type === 'machine' &&
                              confirmedDeleteParam.machineIndex === index)) &&
                            !!serialNumbersMsg[serialIndex] && (
                              <FormHelperText
                                id={'serialNumbers' + index + '_' + serialIndex}
                                sx={FormHelperTextProps}
                              >
                                {serialNumbersMsg[serialIndex] ? serialNumbersMsg[serialIndex] : ''}
                              </FormHelperText>
                            )}
                        </EditDialogInputFieldGrid>
                        <ClearButtonGrid item>
                          {editItem &&
                            editItem.editType === 'machine' &&
                            editItem.machineIndex === index && (
                              <ClearIcon
                                htmlColor={colors.border}
                                onClick={() => handleSerialNumbersClear(serialIndex)}
                                sx={{ cursor: 'pointer' }}
                              />
                            )}
                        </ClearButtonGrid>
                        <AddButtonGrid item>
                          {editItem &&
                            editItem.editType === 'machine' &&
                            editItem.machineIndex === index &&
                            (editMachine.serialNumber
                              ? editMachine.serialNumber.split(',').length - 1 === serialIndex
                              : true) && (
                              <AddCircle
                                color="primary"
                                onClick={() => handleSerialNumbersAdd()}
                                sx={{ cursor: 'pointer' }}
                              />
                            )}
                        </AddButtonGrid>
                      </RowLeftGrid>
                    ))}
                </MachineBlockGrid>
              ))}

            <RowLeftGrid direction="row" container alignItems="center">
              <AddButtonGrid item sx={{ width: '100%' }}>
                <Button
                  variant="text"
                  size="large"
                  onClick={() => handleMachineAdd()}
                  disabled={editItem != null}
                  startIcon={
                    <AddCircle
                      htmlColor={editItem != null ? colors.text.disabled : colors.primary}
                      fontSize="large"
                    />
                  }
                >
                  {intl.formatMessage({ id: msgId.addMachine })}
                </Button>
              </AddButtonGrid>
            </RowLeftGrid>
          </EditDialogRootGrid>
        </CustomScrollBars>
        {updateResult != null && updateResult.showDialog && editMode === 'ship' && !deleteParam && (
          <AlertDialog
            title={intl.formatMessage({ id: updateResult.isSuccess ? msgId.confirm : msgId.error })}
            message={intl.formatMessage({ id: updateResult.msgId })}
            onClose={() => handleResultClear(updateResult.isSuccess)}
          />
        )}
        {deleteParam && editMode === 'ship' && (
          <AlertDialog
            title={intl.formatMessage({ id: msgId.delete })}
            message={intl.formatMessage({
              id:
                deleteParam?.type === 'ship'
                  ? msgId.confirmMessageShipDelete
                  : msgId.confirmMessageDelete,
            })}
            onClose={(result) => handleDeleteClickOk(result)}
            negativeButton={intl.formatMessage({ id: msgId.cancel })}
            positiveButton={intl.formatMessage({ id: msgId.ok })}
          />
        )}
      </EditDialogContents>
      <FadeLoading loading={inProgress} />
    </EditDialog>
  );
}
