import React from 'react';
import { useIntl } from 'react-intl';
import colors from 'resources/colors';
import { AnalysisData, AnalysisGearAnomalyDetectionValue } from 'models/analysis';
import { ChartHeaderPanel } from 'components/chart/ChartHeaderPanel';
import { LegendGroup, ChartLegendList, Legend } from '../common/ChartLegendList';
import { RexGearAnomalyDetectionChart } from './RexGearAnomalyDetectionChart';
import { RexGearAnomalyDetectionFooterChart } from './RexGearAnomalyDetectionFooterChart';
import { ChartContainer } from '../common/ChartContainer';
import msgId from 'resources/intl';
import { useDispatch } from 'react-redux';
import {
  setRexGearAnomalyDetectionExpanded,
  useAnalysisRexGearAnomalyDetectionExpanded,
} from 'features/contents/analysis/analysisSlice';
import { ConfirmedSearchResultItem } from 'models/search';
import { styled } from '@mui/material';
import dimens from 'resources/dimens';
import config from 'resources/config';
import { dumpAnalysisRexGearAnomalyDetection } from 'utils/dumpData';

const RootDiv = styled('div')({
  width: '100%',
});

const ContainerDiv = styled('div')({
  height: '100%',
  width: '100%',
});

const ContentsDiv = styled('div')({
  width: '100%',
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'flex-start',
  background: colors.chart.legend.background,
  '&:last-child': {
    borderBottom: `0px solid ${colors.chart.divider}`,
  },
  zIndex: 2,
  overflow: 'hidden',
});

interface RexGearAnomalyDetectionContainerProps {
  chartId: string;
  confirmedSearchResultItem: ConfirmedSearchResultItem;
  analysisData: AnalysisData;
}
interface ChartProps {
  titleId: string;
  port: AnalysisGearAnomalyDetectionValue;
  starboard: AnalysisGearAnomalyDetectionValue;
}

export function RexGearAnomalyDetectionContainer(
  props: RexGearAnomalyDetectionContainerProps
): JSX.Element {
  const { chartId, confirmedSearchResultItem, analysisData } = props;
  const intl = useIntl();
  const dispatch = useDispatch();
  const expanded = useAnalysisRexGearAnomalyDetectionExpanded();
  const contents = analysisData.data.rexGearAnomalyDetection;

  if (contents == null) {
    return <div></div>;
  }

  const analysisName =
    intl.formatMessage({
      id: 'AnalysisSection' + analysisData.data.rexGearAnomalyDetection?.analysisSectionName,
    }) +
    ' : ' +
    intl.formatMessage({
      id: 'AnalysisMenu' + analysisData.data.rexGearAnomalyDetection?.analysisMenuName,
    });

  const graphTypes: ChartProps[] = [
    {
      titleId: msgId.rexGearAnomalyDetectionUpperMain,
      port: contents.UpperBevelMainShaftP,
      starboard: contents.UpperBevelMainShaftS,
    },
    {
      titleId: msgId.rexGearAnomalyDetectionUpperVertical,
      port: contents.UpperBevelVerticalShaftP,
      starboard: contents.UpperBevelVerticalShaftS,
    },
    {
      titleId: msgId.rexGearAnomalyDetectionLowerVertical,
      port: contents.LowerBevelVerticalShaftP,
      starboard: contents.LowerBevelVerticalShaftS,
    },
    {
      titleId: msgId.rexGearAnomalyDetectionLowerPropeller,
      port: contents.LowerBevelPropellerShaftP,
      starboard: contents.LowerBevelPropellerShaftS,
    },
  ];
  const legendIds = [
    {
      msgId: msgId.rexGearAnomalyDetectionDown,
      htmlColor: colors.chart.analysis.rexGearAnomalyDetection.incDegColors[2],
    },
    {
      msgId: msgId.rexGearAnomalyDetectionEqual,
      htmlColor: colors.chart.analysis.rexGearAnomalyDetection.incDegColors[0],
    },
    {
      msgId: msgId.rexGearAnomalyDetectionUp,
      htmlColor: colors.chart.analysis.rexGearAnomalyDetection.incDegColors[1],
    },
  ];

  if (config.valueInspectionLog) {
    dumpAnalysisRexGearAnomalyDetection(analysisData);
  }

  /**
   * CSV出力
   */
  const handleCsvOutput = () => {
    // noop
  };

  /**
   * 開閉状態変更
   * @param expanded 開閉状態
   */
  const handleExpandChanged = (expanded: boolean) => {
    dispatch(setRexGearAnomalyDetectionExpanded(expanded));
  };

  /**
   * 凡例リスト
   */
  const legendGroups: LegendGroup = {
    groupName: analysisData.shipName + ' - ' + analysisData.machineName,
    legends: legendIds.map((legendId) => {
      return {
        title: intl.formatMessage({ id: legendId.msgId }),
        color: legendId.htmlColor,
        iconType: 'circle',
      } as Legend;
    }),
  };

  return (
    <RootDiv>
      <ChartContainer
        chartId={chartId}
        shipName={analysisData.shipName}
        machineName={analysisData.machineName}
        analysisName={analysisName}
        confirmedSearchResultItem={confirmedSearchResultItem}
        title={analysisName}
        expanded={expanded}
        onExpandChanged={handleExpandChanged}
      >
        <ContainerDiv>
          <ChartHeaderPanel
            chartId={chartId}
            sx={{ paddingRight: `${dimens.chart.legend.width}px` }}
            title={analysisName}
            titleEditable={false}
            csvOutputEnabled={false}
            csvOutputLocked={true}
          />
          {graphTypes.map((type, index) => {
            return (
              <ContentsDiv key={index}>
                <RexGearAnomalyDetectionChart
                  titleId={type.titleId}
                  dates={contents.date}
                  sx={{
                    width: `calc(100% - ${dimens.chart.legend.width}px - 1px)`,
                    borderRight: `1px solid ${colors.chart.divider}`,
                  }}
                  ShaftPort={type.port}
                  ShaftStarboard={type.starboard}
                />
                {/* <ChartDivider orientation="vertical" flexItem /> */}
                {index === 0 && <ChartLegendList legendGroups={[legendGroups]} />}
              </ContentsDiv>
            );
          })}
          <ContentsDiv>
            <RexGearAnomalyDetectionFooterChart
              titleId={'Footer'}
              sx={{
                width: `calc(100% - ${dimens.chart.legend.width}px - 1px)`,
                borderRight: `1px solid ${colors.chart.divider}`,
              }}
              dates={contents.date}
            />
            <ChartLegendList legendGroups={[]} />
          </ContentsDiv>
        </ContainerDiv>
      </ChartContainer>
    </RootDiv>
  );
}
