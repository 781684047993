const colors = {
  primary: '#003070',
  accent: '#3B86FF',
  border: '#9D9D9D',
  white: '#FFFFFF',
  login: {
    background: 'linear-gradient(0deg, #729AD8, #09B3C4)',
  },
  text: {
    disabled: '#808495',
  },
  drawerItem: {
    normal: '#FFFFFF',
    hover: 'rgba(160, 200, 230, 0.6)',
    disabled: '#103C6E',
    selected: '#AAFFBE',
  },
  subDrawer: {
    text: '#43425D',
    background: '#F5F6FA',
  },
  listItem: {
    normal: '#FFFFFF',
    hover: '#EAEBEF',
    selected: '#D4D5D9',
    header: '#D7E8FD',
    subHeader: '#E8F2FE',
    boader: '#CECFD0',
    disabled: '#EEEEEE',
  },
  appBar: {
    background: 'linear-gradient(90deg, #003070, #08E8E0)',
  },
  loading: {
    background: 'rgba(0, 0, 0, 0.3)',
  },
  icon: {
    maintenance: '#E8D738',
    caution: '#FFA200',
    warning: '#FF3400',
    fault: '#FF3400',
  },
  accordion: {
    header: '#E8F2FE',
    border: '#A5C9FF',
  },
  calendar: {
    background: '#EDF0F5',
    activeTile: '#CBF0D9',
    currentTile: '#006edc',
  },
  scrollBar: {
    thumb: {
      light: 'rgba(0,0,0,.2)',
      dark: 'rgba(255,255,255,.2)',
      login: 'rgba(255,255,255,.5)',
    },
  },
  timeSlider: {
    border: '#CECFD0',
  },
  button: {
    clear: {
      color: '#141414',
      background: '#E9E9F0',
      hover: '#D5D5D5',
    },
  },
  chart: {
    text: '#FFFFFF',
    divider: '#4D4F5C',
    lines: [
      '#BB6EFF',
      '#00DDFF',
      '#FFFF0C',
      '#6FFF00',
      '#FF0091',
      '#FFA60C',
      '#8700FF',
      '#FF5CB9',
      '#0022FF',
    ],
    labels: [
      '#FFFFFF',
      '#FFFFFF',
      '#FFFFFF',
      '#FFFFFF',
      '#FFFFFF',
      '#FFFFFF',
      '#FFFFFF',
      '#FFFFFF',
      '#FFFFFF',
    ],
    alert: {
      color: '#FFFF0C',
    },
    megHpLevels: ['#FF0091', '#FFA60C', '#FFFF0C', '#6FFF00', '#00DDFF', '#FFFFFF'],
    header: {
      color: '#B4D4FF',
      background: '#262E38',
      border: '#3D3D3D',
    },
    mimic: {
      value: '#00DDFF',
      border: '#3D98FF',
      danger: '#FCA000',
      gageDanger: 'orange',
      background: '#405468',
    },
    button: {
      apply: '#3D98FF',
      footer: '#EEF7FD',
    },
    legend: {
      border: '#2B2B2B',
      background: '#101010',
      listItem: {
        border: '#000000',
        selected: '#3E3E3E',
        hover: '#444444',
      },
    },
    plotarea: {
      background: '#101010',
      grid: '#444',
      gridLine: '#2B2B2B',
    },
    chartContainer: {
      background: '#262E38',
    },
    analysis: {
      vesShipSpeed: {
        testDataLineColor: '#8700FF',
        axisFontColor: '#CECFD0',
        gridColor: '#2b2b2b',
        y1TickColor: '#00DDFF',
        y2TickColor: '#6FFF00',
        y1TickFontColor: '#FFFFFF',
        y2TickFontColor: '#FFFFFF',
      },
      rexPropulsionOperation: {
        axisFontColor: '#CECFD0',
        gridColor: '#2b2b2b',
        y1TickColor: '#00DDFF',
        y2TickColor: '#6FFF00',
        y1TickFontColor: '#FFFFFF',
        y2TickFontColor: '#FFFFFF',
      },
      megTemp: {
        outTempColor: '#8700FF',
        axisFontColor: '#CECFD0',
        gridColor: '#2b2b2b',
        y1TickColor: '#00DDFF',
        y2TickColor: '#BB6EFF',
        y1TickFontColor: '#FFFFFF',
        y2TickFontColor: '#FFFFFF',
      },
      megLoadBand: {
        axisFontColor: '#CECFD0',
        gridColor: '#2b2b2b',
        y1TickColor: '#00DDFF',
        y1TickFontColor: '#FFFFFF',
      },
      megLoad: {
        testDataLineColor: '#8700FF',
        axisFontColor: '#CECFD0',
        gridColor: '#2b2b2b',
        y1TickColor: '#00DDFF',
        y1TickFontColor: '#FFFFFF',
      },
      rexGearAnomalyDetection: {
        axisFontColor: '#CECFD0',
        gridColor: '#2b2b2b',
        y1TickColor: '#BB6EFF',
        y2TickColor: '#00DDFF',
        y1TickFontColor: '#FFFFFF',
        y2TickFontColor: '#FFFFFF',
        auxiliaryLineColor: '#FF0000',
        incDegColors: ['#6FFF00', '#FFA60C', '#00DDFF'],
      },
      rexBearingAnomalyDetection: {
        axisFontColor: '#CECFD0',
        gridColor: '#2b2b2b',
        y1TickColor: '#BB6EFF',
        y2TickColor: '#00DDFF',
        y1TickFontColor: '#FFFFFF',
        y2TickFontColor: '#FFFFFF',
        auxiliaryLineColor: '#FF0000',
        dotColor: '#BB6EFF',
      },
      rexPropellerInvolvement: {
        axisFontColor: '#CECFD0',
        gridColor: '#2b2b2b',
        y1TickColor: '#BB6EFF',
        y2TickColor: '#00DDFF',
        y1TickFontColor: '#FFFFFF',
        y2TickFontColor: '#FFFFFF',
        auxiliaryLineColor: '#FF0000',
      },
    },
  },
  map: {
    shipSpeed: [
      'rgb(106,58,240)',
      'rgb(58,58,240)',
      'rgb(58,88,240)',
      'rgb(58,123,240)',
      'rgb(58,153,240)',
      'rgb(26,172,238)',
      'rgb(17,197,221)',
      'rgb(15,203,199)',
      'rgb(14,194,155)',
      'rgb(14,194,125)',
      'rgb(14,190,89)',
      'rgb(15,193,15)',
      'rgb(15,185,15)',
      'rgb(29,179,13)',
      'rgb(53,168,12)',
      'rgb(98,151,11)',
      'rgb(125,152,12)',
      'rgb(167,171,13)',
      'rgb(199,190,15)',
      'rgb(222,193,16)',
      'rgb(229,179,17)',
    ],
  },
  aisData: {
    status: {
      active: {
        label: '#141414',
        background: '#78ECFF',
      },
      notActive: {
        label: '#141414',
        background: '#FFFFFF',
      },
      abnormal: {
        label: '#FFFFFF',
        background: '#E53030',
      },
      restricted: {
        label: '#141414',
        background: '#FFFF78',
      },
      fishing: {
        label: '#141414',
        background: '#B3FF78',
      },
      unknown: {
        label: '#141414',
        background: '#E4E4E4',
      },
      undefined: {
        label: '#141414',
        background: '#FFFFFF',
      },
    },
  },
  helperText: {
    error: '#CD0000',
  },
  marineWeather: {
    windSpeed: [
      'rgb(0,0,255)', // 0
      'rgb(0,170,255)', // 3
      'rgb(0,255,255)', // 6
      'rgb(0,255,170)', // 9
      'rgb(0,255,0)', // 12
      'rgb(170,255,0)', // 15
      'rgb(255,255,0)', // 18
      'rgb(255,170,0)', // 21
      'rgb(255,85,0)', // 24
      'rgb(255,0,0)', // 27
      'rgb(210,0,0)', // 30
    ],
    sigHeight: [
      'rgb(0,0,255)', // 0
      'rgb(0,170,255)', // 1
      'rgb(0,255,255)', // 2
      'rgb(0,255,170)', // 3
      'rgb(0,255,0)', // 4
      'rgb(170,255,0)', // 5
      'rgb(255,255,0)', // 6
      'rgb(255,170,0)', // 7
      'rgb(255,85,0)', // 8
      'rgb(255,0,0)', // 9
      'rgb(210,0,0)', // 10
    ],
    swellHeight: [
      'rgb(0,0,255)', // 0
      'rgb(0,170,255)', // 1
      'rgb(0,255,255)', // 2
      'rgb(0,255,170)', // 3
      'rgb(0,255,0)', // 4
      'rgb(170,255,0)', // 5
      'rgb(255,255,0)', // 6
      'rgb(255,170,0)', // 7
      'rgb(255,85,0)', // 8
      'rgb(255,0,0)', // 9
      'rgb(210,0,0)', // 10
    ],
    swellPeriod: [
      'rgb(0,0,255)', // 0
      'rgb(0,170,255)', // 2
      'rgb(0,255,255)', // 4
      'rgb(0,255,170)', // 6
      'rgb(0,255,0)', // 8
      'rgb(170,255,0)', // 10
      'rgb(255,255,0)', // 12
      'rgb(255,170,0)', // 14
      'rgb(255,85,0)', // 16
      'rgb(255,0,0)', // 18
      'rgb(210,0,0)', // 20
    ],
  },
};

export default colors;
