import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'app/rootReducer';
import { useSelector } from 'react-redux';

/**
 * MapState インターフェース
 */
export interface MapState {
  /** センター座標 */
  center: google.maps.LatLngLiteral | undefined;
  /** リセット */
  reset: boolean;
}

/**
 * Map の初期状態
 */
const initialState: MapState = {
  center: undefined,
  reset: false,
};

export const map = createSlice({
  name: 'shipStatePastDataMap',
  initialState,
  reducers: {
    /**
     * センターを設定する。
     */
    setCenter: (state, action: PayloadAction<google.maps.LatLngLiteral | undefined>) => {
      state.center = action.payload;
    },
    resetMap: (state, action: PayloadAction<boolean>) => {
      state.reset = action.payload;
    },
    /**
     * shipStatePastDataMapをクリアする。
     */
    clearShipStatePastDataMap: (state) => {
      state.center = initialState.center;
    },
  },
});

export const { setCenter, resetMap, clearShipStatePastDataMap } = map.actions;

const pastDataMapState = (state: RootState) => state.pastDataMap;
const selectCenter = createSelector(pastDataMapState, (x) => x.center);
const selectReset = createSelector(pastDataMapState, (x) => x.reset);

export const usePastDataMapCenter = () => useSelector(selectCenter);
export const usePastDataMapReset = () => useSelector(selectReset);

export default map.reducer;
