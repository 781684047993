import React from 'react';
import { useIntl, IntlShape } from 'react-intl';
import msgId from 'resources/intl';
import Plotly, { Layout, LayoutAxis, Shape, PlotMouseEvent, Annotations } from 'plotly.js';
import createPlotlyComponent from 'react-plotly.js/factory';
import colors from 'resources/colors';
import { AnalysisData, DisplayInfo } from 'models/analysis';
import { Box, styled, SxProps } from '@mui/material';
import { roundValueToString } from 'utils/misc';

const Plot = createPlotlyComponent(Plotly);
const axisFontColor = colors.chart.analysis.rexPropellerInvolvement.axisFontColor;
const gridColor = colors.chart.analysis.rexPropellerInvolvement.gridColor;
const yTickColor = [
  colors.chart.analysis.rexPropellerInvolvement.y1TickColor,
  colors.chart.analysis.rexPropellerInvolvement.y2TickColor,
];
const yTickFontColor = [
  colors.chart.analysis.rexPropellerInvolvement.y1TickFontColor,
  colors.chart.analysis.rexPropellerInvolvement.y2TickFontColor,
];
const graphHeight = 200;
const graphHeightFooter = 50;

const PlotPanelDiv = styled('div')({
  position: 'relative',
  width: '50%',
  padding: 0,
  float: 'left',
  borderLeft: `1px solid ${colors.chart.legend.border}`,
});

const HoverDiv = styled('div')({
  position: 'absolute',
  color: 'white',
  background: 'black',
  zIndex: 2,
  fontFamily: 'Arial, sans-serif',
  fontSize: 13,
  whiteSpace: 'nowrap',
});

const plotLStyle: React.CSSProperties = {
  width: '100%',
  height: graphHeight,
  background: colors.chart.legend.background,
};

const plotRStyle: React.CSSProperties = {
  width: '100%',
  height: graphHeight,
  background: colors.chart.legend.background,
};

const plotLFooterStyle: React.CSSProperties = {
  width: '100%',
  height: graphHeightFooter,
  background: colors.chart.legend.background,
};

const plotRFooterStyle: React.CSSProperties = {
  width: '100%',
  height: graphHeightFooter,
  background: colors.chart.legend.background,
};

export type PropellerGraphType =
  | 'analysis_propeller_change_score'
  | 'MimicMegFuelRackLvP1'
  | 'MimicMegTCSpdP1'
  | 'RexLtStrPressB'
  | 'RexRtStrPressB'
  | 'MimicRexCPPBladeAngOrderP1'
  | 'MimicRexCPPBladeAngFBP1'
  | 'MimicRexStrAngOrderP1'
  | 'MimicRexStrAngFBP1'
  | 'DateTimeTick';

export function hoverInfoText(
  intl: IntlShape,
  date: string,
  title: string,
  text: string,
  unit: string
): string {
  return (
    intl.formatMessage({ id: msgId.AnalysisHoverInfoLabelDate }) +
    ':' +
    date +
    '<br>' +
    title +
    ':' +
    text +
    unit
  );
}

export function getYaxis(
  index: number,
  info: Partial<DisplayInfo>,
  title: string,
  graphType: PropellerGraphType,
  dtick?: Plotly.DTickValue | undefined
): Partial<LayoutAxis> {
  const yaxis = {
    title: {
      text: title,
      font: { color: axisFontColor, size: 11 },
    },
    gridcolor: gridColor,
    ticklabelposition: 'inside',
    ticks: 'inside',
    tick0: 0,
    tickfont: { color: yTickFontColor[index] },
    tickcolor: yTickColor[index],
    range: info ? [info.rangeMin, info.rangeMax] : [0, 1],
  } as Partial<LayoutAxis>;
  let tick = {};
  if (dtick) {
    tick = {
      dtick: dtick,
    };
  }
  if (graphType === 'DateTimeTick') {
    yaxis.showgrid = false;
    yaxis.zeroline = false;
  }
  if (index > 0) {
    yaxis.showgrid = false;
    yaxis.overlaying = 'y';
    yaxis.side = 'right';
  }

  return Object.assign(tick, yaxis);
}
interface RexPropellerInvolvementChartProps {
  sx?: SxProps;
  graphType: PropellerGraphType;
  analysisData: AnalysisData;
  onHover: (data: Readonly<PlotMouseEvent>, graphType: PropellerGraphType) => void;
  onRelayout: (
    elementId: string,
    event: Readonly<Plotly.PlotRelayoutEvent>,
    side: 'left' | 'right'
  ) => void;
  onUnhover: (data: Readonly<PlotMouseEvent>) => void;
  onClick?: (data: Readonly<PlotMouseEvent>, graphType: PropellerGraphType) => void;
  onDoubleClick: (elementId: string) => void;
}

export function RexPropellerInvolvementChart(
  props: RexPropellerInvolvementChartProps
): JSX.Element {
  const intl = useIntl();
  const { sx, analysisData, graphType, onHover, onRelayout, onUnhover, onClick, onDoubleClick } =
    props;
  const contents = analysisData.data.rexPropellerForeignMatterEntrainment;
  if (contents == null) {
    return <Box sx={sx}></Box>;
  }
  const displayInfoL: DisplayInfo[] = [];
  const displayInfoR: DisplayInfo[] = [];
  const yDataL: number[][] = [];
  const yDataR: number[][] = [];
  const datetimeOffset = new Date().getTimezoneOffset();
  const xData: number[] = contents.sensorData.date.map((x) => x + datetimeOffset * 60000);
  const plotDataListL: Plotly.Data[] = [];
  const plotDataListR: Plotly.Data[] = [];
  const label: string[] = [];
  const title: string[] = [];
  const sharpL = [];
  const sharpR = [];
  const dTickL: number[] = [];
  const dTickR: number[] = [];

  graphType !== 'DateTimeTick' ? label.push(intl.formatMessage({ id: graphType })) : label.push('');
  switch (graphType) {
    case 'analysis_propeller_change_score':
      if (analysisData.dataFormatId !== 402) {
        yDataL.push(contents.sensorData.MegSpdP1);
        yDataL.push(contents.sensorData.MahalanobisP1);
        yDataL.push(contents.sensorData.MahalanobisAlertP1);
        yDataR.push(contents.sensorData.MegSpdS1);
        yDataR.push(contents.sensorData.MahalanobisS1);
        yDataR.push(contents.sensorData.MahalanobisAlertS1);
        displayInfoL.push(contents.displayInformations.MegSpd);
        displayInfoL.push(contents.displayInformations.Mahalanobis);
        displayInfoL.push(contents.displayInformations.Mahalanobis);
        displayInfoR.push(contents.displayInformations.MegSpd);
        displayInfoR.push(contents.displayInformations.Mahalanobis);
        displayInfoR.push(contents.displayInformations.Mahalanobis);
        label.push(intl.formatMessage({ id: msgId.MimicMegSpdS1 }));
        title.push(intl.formatMessage({ id: msgId.analysisPropellerMegEngineSpeed }));
        title.push(intl.formatMessage({ id: msgId.analysisPropellerChangeScore }));
      } else {
        yDataL.push(contents.sensorData.EngSpdP);
        yDataL.push(contents.sensorData.MahalanobisP1);
        yDataL.push(contents.sensorData.MahalanobisAlertP1);
        yDataR.push(contents.sensorData.EngSpdS);
        yDataR.push(contents.sensorData.MahalanobisS1);
        yDataR.push(contents.sensorData.MahalanobisAlertS1);
        displayInfoL.push(contents.displayInformations.EngSpd);
        displayInfoL.push(contents.displayInformations.Mahalanobis);
        displayInfoL.push(contents.displayInformations.Mahalanobis);
        displayInfoR.push(contents.displayInformations.EngSpd);
        displayInfoR.push(contents.displayInformations.Mahalanobis);
        displayInfoR.push(contents.displayInformations.Mahalanobis);
        label.push(intl.formatMessage({ id: msgId.MimicEngSpdS }));
        title.push(intl.formatMessage({ id: msgId.analysisPropellerMegEngineSpeed }));
        title.push(intl.formatMessage({ id: msgId.analysisPropellerChangeScore }));
      }
      break;
    case 'MimicMegFuelRackLvP1':
      if (analysisData.dataFormatId !== 402) {
        yDataL.push(contents.sensorData.MegFuelRackLvP1);
        yDataR.push(contents.sensorData.MegFuelRackLvS1);
        displayInfoL.push(contents.displayInformations.MegFuelRackLv);
        displayInfoR.push(contents.displayInformations.MegFuelRackLv);
        title.push(intl.formatMessage({ id: msgId.analysisPropellerPumpLack }));
      } else {
        yDataL.push(contents.sensorData.EngFuelRackLvP);
        yDataR.push(contents.sensorData.EngFuelRackLvS);
        displayInfoL.push(contents.displayInformations.EngFuelRackLv);
        displayInfoR.push(contents.displayInformations.EngFuelRackLv);
        title.push(intl.formatMessage({ id: msgId.analysisPropellerPumpLack }));
      }
      break;
    case 'MimicMegTCSpdP1':
      if (analysisData.dataFormatId !== 402) {
        yDataL.push(contents.sensorData.MegTCSpdP1);
        yDataR.push(contents.sensorData.MegTCSpdS1);
        displayInfoL.push(contents.displayInformations.MegTCSpd);
        displayInfoR.push(contents.displayInformations.MegTCSpd);
        title.push(intl.formatMessage({ id: msgId.analysisPropellerTurbocharger }));
      } else {
        yDataL.push(contents.sensorData.EngTcSpdP);
        yDataR.push(contents.sensorData.EngTcSpdS);
        displayInfoL.push(contents.displayInformations.EngTcSpd);
        displayInfoR.push(contents.displayInformations.EngTcSpd);
        title.push(intl.formatMessage({ id: msgId.analysisPropellerTurbocharger }));
      }
      break;
    case 'RexLtStrPressB':
      if (analysisData.dataFormatId !== 402) {
        yDataL.push(contents.sensorData.RexLtStrPressP1RIO);
        yDataR.push(contents.sensorData.RexLtStrPressS1RIO);
        displayInfoL.push(contents.displayInformations.RexLtStrPressRIO);
        displayInfoR.push(contents.displayInformations.RexLtStrPressRIO);
        title.push(intl.formatMessage({ id: msgId.analysisPropellerLeftPress }));
      } else {
        yDataL.push(contents.sensorData.RexLtStrPressP);
        yDataR.push(contents.sensorData.RexLtStrPressS);
        displayInfoL.push(contents.displayInformations.RexLtStrPress);
        displayInfoR.push(contents.displayInformations.RexLtStrPress);
        title.push(intl.formatMessage({ id: msgId.analysisPropellerLeftPress }));
      }
      break;
    case 'RexRtStrPressB':
      if (analysisData.dataFormatId !== 402) {
        yDataL.push(contents.sensorData.RexRtStrPressP1RIO);
        yDataR.push(contents.sensorData.RexRtStrPressS1RIO);
        displayInfoL.push(contents.displayInformations.RexRtStrPressRIO);
        displayInfoR.push(contents.displayInformations.RexRtStrPressRIO);
        title.push(intl.formatMessage({ id: msgId.analysisPropellerRightPress }));
      } else {
        yDataL.push(contents.sensorData.RexRtStrPressP);
        yDataR.push(contents.sensorData.RexRtStrPressS);
        displayInfoL.push(contents.displayInformations.RexRtStrPress);
        displayInfoR.push(contents.displayInformations.RexRtStrPress);
        title.push(intl.formatMessage({ id: msgId.analysisPropellerRightPress }));
      }
      break;
    case 'MimicRexCPPBladeAngOrderP1':
      yDataL.push(contents.sensorData.RexCPPBladeAngOrderP1);
      yDataR.push(contents.sensorData.RexCPPBladeAngOrderS1);
      displayInfoL.push(contents.displayInformations.RexCPPBladeAngOrder);
      displayInfoR.push(contents.displayInformations.RexCPPBladeAngOrder);
      title.push(intl.formatMessage({ id: msgId.analysisPropellerCppOrder }));
      break;
    case 'MimicRexCPPBladeAngFBP1':
      yDataL.push(contents.sensorData.RexCPPBladeAngFBP1);
      yDataR.push(contents.sensorData.RexCPPBladeAngFBS1);
      displayInfoL.push(contents.displayInformations.RexCPPBladeAngFB);
      displayInfoR.push(contents.displayInformations.RexCPPBladeAngFB);
      title.push(intl.formatMessage({ id: msgId.analysisPropellerCppFeedback }));
      break;
    case 'MimicRexStrAngOrderP1':
      if (analysisData.dataFormatId !== 402) {
        yDataL.push(contents.sensorData.RexStrAngOrderP1);
        yDataR.push(contents.sensorData.RexStrAngOrderS1);
        displayInfoL.push(contents.displayInformations.RexStrAngOrder);
        displayInfoR.push(contents.displayInformations.RexStrAngOrder);
        title.push(intl.formatMessage({ id: msgId.analysisPropellerTurnOrder }));
      } else {
        yDataL.push(contents.sensorData.RexStrAngOrderP);
        yDataR.push(contents.sensorData.RexStrAngOrderS);
        displayInfoL.push(contents.displayInformations.RexStrAngOrder);
        displayInfoR.push(contents.displayInformations.RexStrAngOrder);
        title.push(intl.formatMessage({ id: msgId.analysisPropellerTurnOrder }));
      }
      break;
    case 'MimicRexStrAngFBP1':
      if (analysisData.dataFormatId !== 402) {
        yDataL.push(contents.sensorData.RexStrAngFBP1);
        yDataR.push(contents.sensorData.RexStrAngFBS1);
        displayInfoL.push(contents.displayInformations.RexStrAngFB);
        displayInfoR.push(contents.displayInformations.RexStrAngFB);
        title.push(intl.formatMessage({ id: msgId.analysisPropellerTurnFeedback }));
      } else {
        yDataL.push(contents.sensorData.RexStrAngFbP);
        yDataR.push(contents.sensorData.RexStrAngFbS);
        displayInfoL.push(contents.displayInformations.RexStrAngFb);
        displayInfoR.push(contents.displayInformations.RexStrAngFb);
        title.push(intl.formatMessage({ id: msgId.analysisPropellerTurnFeedback }));
      }
      break;
    case 'DateTimeTick':
      title[0] = '';
      break;
  }
  const yAnnotationL: (Partial<Annotations> | undefined)[] = displayInfoL.map((info, index) => {
    if (info.thresholdValue) {
      const shape: Partial<Shape> = {
        type: 'line',
        xref: 'paper',
        yref: ('y' + (index === 0 ? '' : '2')) as Plotly.YAxisName,
        x0: 0,
        y0: info.thresholdValue,
        x1: 1,
        y1: info.thresholdValue,
        line: {
          color: colors.chart.analysis.rexPropellerInvolvement.auxiliaryLineColor,
          width: 1,
          dash: 'dot',
        },
      };
      sharpL.push(shape);

      return undefined;
    } else {
      return undefined;
    }
  });

  const yAnnotationR: (Partial<Annotations> | undefined)[] = displayInfoR.map((info, index) => {
    if (info.thresholdValue) {
      const shape: Partial<Shape> = {
        type: 'line',
        xref: 'paper',
        yref: ('y' + (index === 0 ? '' : '2')) as Plotly.YAxisName,
        x0: 0,
        y0: info.thresholdValue,
        x1: 1,
        y1: info.thresholdValue,
        line: {
          color: colors.chart.analysis.rexPropellerInvolvement.auxiliaryLineColor,
          width: 1,
          dash: 'dot',
        },
      };
      sharpR.push(shape);

      return undefined;
    } else {
      return undefined;
    }
  });

  yDataL.forEach((data, index) => {
    if (graphType === 'analysis_propeller_change_score' && index >= 1) {
      const plotData = {
        x: xData,
        y: data,
        type: 'scatter',
        hoverinfo: 'none',
        mode: 'markers',
        yaxis: 'y2',
        marker: {
          color: index === 1 ? colors.chart.lines[index] : colors.chart.alert.color,
          size: 8,
        },
      } as Partial<Plotly.PlotData>;
      plotDataListL.push(plotData);
    } else {
      const plotData = {
        x: xData,
        y: data,
        type: 'scatter',
        hoverinfo: 'none',
        mode: 'lines',
        yaxis: 'y',
        line: { color: colors.chart.lines[0], width: 1 },
      } as Partial<Plotly.PlotData>;
      plotDataListL.push(plotData);
    }
  });
  yDataR.forEach((data, index) => {
    if (graphType === 'analysis_propeller_change_score' && index >= 1) {
      const plotData = {
        x: xData,
        y: data,
        type: 'scatter',
        hoverinfo: 'none',
        mode: 'markers',
        yaxis: 'y2',
        marker: {
          color: index === 1 ? colors.chart.lines[index] : colors.chart.alert.color,
          size: 8,
        },
      } as Partial<Plotly.PlotData>;
      plotDataListR.push(plotData);
    } else {
      const plotData = {
        x: xData,
        y: data,
        type: 'scatter',
        hoverinfo: 'none',
        mode: 'lines',
        yaxis: 'y',
        line: { color: colors.chart.lines[0], width: 1 },
      } as Partial<Plotly.PlotData>;
      plotDataListR.push(plotData);
    }
  });

  const shapeLeftLine: Partial<Shape> = {
    type: 'line',
    xref: 'paper',
    yref: 'paper',
    x0: 0,
    y0: 0,
    x1: 0,
    y1: 1,
    line: {
      width: 1,
    },
  };
  if (displayInfoL.length > 0 && shapeLeftLine && shapeLeftLine.line) {
    shapeLeftLine.line.color = colors.chart.analysis.rexPropellerInvolvement.y1TickColor;
    sharpL.push(shapeLeftLine);
  }
  if (displayInfoR.length > 0 && shapeLeftLine && shapeLeftLine.line) {
    shapeLeftLine.line.color = colors.chart.analysis.rexPropellerInvolvement.y1TickColor;
    sharpR.push(shapeLeftLine);
  }
  sharpL.push(shapeLeftLine);
  sharpR.push(shapeLeftLine);
  if (displayInfoL.length > 1) {
    const shapeRightLine: Partial<Shape> = {
      type: 'line',
      xref: 'paper',
      yref: 'paper',
      x0: 1,
      y0: 0,
      x1: 1,
      y1: 1,
      line: {
        width: 1,
        color: colors.chart.analysis.rexPropellerInvolvement.y2TickColor,
      },
    };
    sharpL.push(shapeRightLine);
    sharpR.push(shapeRightLine);
  }

  const d = new Date(xData.length > 0 ? xData[0] : 0);
  const dateString = d.toISOString().replace('T', ' ').split('-').join('/').split(':');
  dateString.pop();
  const xaxis = {
    title: {
      text: '',
      font: { color: axisFontColor },
    },
    type: 'date',
    gridcolor: gridColor,
    // tickfont: { color: y1TickColor, size: 13 },
    range: [xData[0], xData[xData.length - 1]],
    showticklabels: false,
    side: 'top',
    autorange: false,
    tickmode: 'auto',
    tickangle: 0,
    nticks: 3,
  } as Partial<LayoutAxis>;
  if (graphType === 'DateTimeTick') {
    xaxis.side = 'bottom';
    xaxis.showticklabels = true;
    xaxis.title = '';
    xaxis.tickfont = { color: 'white', size: 10 };
    xaxis.tickformatstops = [
      { dtickrange: [null, 1000], value: '%H:%M:%S.%Lms' },
      { dtickrange: [1000, 60000], value: '%H:%M:%Ss' },
      { dtickrange: [60000, 3600000], value: '%H:%Mm' },
      { dtickrange: [3600000, 86400000], value: '%H:%Mh' },
      { dtickrange: [86400000, 604800000], value: '%m/%d' },
      { dtickrange: [604800000, 'M1'], value: '%Y/%m/%d' },
      { dtickrange: ['M1', 'M12'], value: '%Y/%m/%d' },
      { dtickrange: ['M12', null], value: '%Y/%m' },
    ];
    xaxis.showgrid = false;
    xaxis.zeroline = false;
  }

  const yaxisL = getYaxis(0, displayInfoL[0], title[0], graphType, dTickL[0]);
  const yaxisR = getYaxis(0, displayInfoR[0], title[0], graphType, dTickR[0]);
  const layoutL = {
    title: {
      text: yDataL.length >= 2 ? intl.formatMessage({ id: msgId.trendLabelPort }) : '',
      color: axisFontColor,
      x: 0.05,
      y: 0.98,
      font: { color: axisFontColor },
    },
    height: graphHeight,
    autosize: true,
    xaxis: xaxis,
    yaxis: yaxisL,
    paper_bgcolor: colors.chart.plotarea.background,
    plot_bgcolor: colors.chart.plotarea.background,
    margin: {
      t: 25,
      b: 5,
      l: 35,
      r: 30,
      pad: 2,
    },
    showlegend: false,
    shapes: sharpL,
    annotations: yAnnotationL,
    hovermode: 'closest',
    hoverlabel: {
      bgcolor: 'black',
      bordercolor: 'black',
      font: {
        color: 'white',
      },
      align: 'left',
    },
    barmode: 'stack',
  } as Partial<Layout>;
  if (yAnnotationL && layoutL) {
    layoutL.annotations = yAnnotationL.filter(
      (anno) => anno !== undefined
    ) as Partial<Plotly.Annotations>[];
  }
  if (graphType === 'DateTimeTick') {
    layoutL.margin = {
      t: 0,
      b: 70,
      l: 35,
      r: 30,
      pad: 2,
    };
    layoutL.height = graphHeightFooter;
    layoutL.title = '';
  }

  const layoutR = Object.assign({}, layoutL);
  Object.assign(layoutR, {
    title: {
      text: yDataR.length >= 2 ? intl.formatMessage({ id: msgId.trendLabelStbd }) : '',
      color: axisFontColor,
      x: 0.05,
      y: 0.98,
      font: { color: axisFontColor },
    },
    shapes: sharpR,
    annotations: yAnnotationR,
    yaxis: yaxisR,
  });
  if (yAnnotationR && layoutR) {
    layoutR.annotations = yAnnotationR.filter(
      (anno) => anno !== undefined
    ) as Partial<Plotly.Annotations>[];
  }
  if (graphType === 'DateTimeTick') {
    layoutR.title = '';
  }

  if (displayInfoL.length > 1) {
    layoutL.yaxis2 = getYaxis(1, displayInfoL[1], title[1], graphType, dTickL[1]);
  }
  if (displayInfoR.length > 1) {
    layoutR.yaxis2 = getYaxis(1, displayInfoR[1], title[1], graphType, dTickR[1]);
  }

  const plotConfig = {
    displayModeBar: false,
    showTips: false,
    doubleClick: false,
  } as Partial<Plotly.Config>;

  const panel = 'RexPropellerInvolvement';
  const PlotL = 'PlotL' + panel + graphType;
  const PlotR = 'PlotR' + panel + graphType;
  const hoverIdL = PlotL + 'hover';
  const hoverIdR = PlotR + 'hover';

  return (
    <Box
      sx={{
        zIndex: graphType === 'DateTimeTick' ? 1 : 0,
        ...sx,
      }}
    >
      <PlotPanelDiv>
        <HoverDiv id={hoverIdL} />
        <HoverDiv id={hoverIdL + '1'} />
        <Plot
          divId={PlotL}
          onRelayout={(event) => onRelayout(PlotL, event, 'left')}
          style={graphType != 'DateTimeTick' ? plotLStyle : plotLFooterStyle}
          data={plotDataListL}
          useResizeHandler
          layout={layoutL}
          onHover={(event) => onHover(event, graphType)}
          onUnhover={onUnhover}
          config={plotConfig}
          onClick={(e) => onClick && onClick(e, graphType)}
          onDoubleClick={() => onDoubleClick(PlotL)}
        />
      </PlotPanelDiv>
      <PlotPanelDiv>
        <HoverDiv id={hoverIdR} />
        <HoverDiv id={hoverIdR + '1'} />
        <Plot
          divId={PlotR}
          onRelayout={(event) => onRelayout(PlotR, event, 'right')}
          style={graphType != 'DateTimeTick' ? plotRStyle : plotRFooterStyle}
          data={plotDataListR}
          useResizeHandler
          layout={layoutR}
          onHover={(event) => onHover(event, graphType)}
          onUnhover={onUnhover}
          config={plotConfig}
          onClick={(e) => onClick && onClick(e, graphType)}
          onDoubleClick={() => onDoubleClick(PlotR)}
        />
      </PlotPanelDiv>
    </Box>
  );
}
