import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'app/rootReducer';
import { AppThunk } from 'app/store';
import { Account, UpdateAccount } from 'models/accounts';
import { UpdateResult } from 'models/admin';
import { ErrorResult, getErrorMsgIdFromErrorResult, getErrorResult } from 'models/error';
import { useSelector } from 'react-redux';
import msgId from 'resources/intl';
import { getAccountsAsync, updateAccountAsync } from 'api/maricoApiAccounts';
export type EditMode = 'account';

interface GeneralSettingsState {
  /** アカウント情報リスト */
  accounts: Account[] | undefined;
  /** データ取得中状態 */
  fetching: boolean;
  /** データ取得エラー */
  fetchError: ErrorResult | undefined;
  /** 編集モード */
  editMode: EditMode | undefined;
  /** 編集アカウントAPI結果 */
  editAccountResult: UpdateResult | undefined;
  /** 編集対象アカウント */
  editAccount: Account | undefined;
  /** 編集対象アカウントエラー */
  editAccountError: ErrorResult | undefined;
}

const initialState: GeneralSettingsState = {
  accounts: undefined,
  fetching: false,
  fetchError: undefined,
  editMode: undefined,
  editAccountResult: undefined,
  editAccount: undefined,
  editAccountError: undefined,
};

const generalSettings = createSlice({
  name: 'generalSettings',
  initialState,
  reducers: {
    /**
     * 取得の開始を設定する。
     */
    startFetch: (state, { payload }: PayloadAction<boolean>) => {
      state.fetching = { payload }.payload;
    },
    /**
     * 取得エラーを設定する。
     */
    setFetchError: (state, { payload }: PayloadAction<ErrorResult | undefined>) => {
      state.fetchError = payload;
      state.fetching = false;
    },
    /**
     * アカウントリストを設定する。
     */
    setAllList: (state, { payload }: PayloadAction<{ accounts: Account[] }>) => {
      state.accounts = payload.accounts;
      state.fetching = false;
    },
    /**
     * アカウントリストを設定する。
     */
    setAccounts: (state, { payload }: PayloadAction<Account[]>) => {
      state.accounts = payload;
      state.fetching = false;
    },
    /**
     * 編集対象アカウントエラーを設定する。
     */
    setEditAccountError: (state, { payload }: PayloadAction<ErrorResult | undefined>) => {
      state.editAccountError = payload;
      state.fetching = false;
    },
    /**
     * アカウント編集結果を設定する。
     */
    setEditAccountResult: (state, { payload }: PayloadAction<UpdateResult | undefined>) => {
      state.editAccountResult = payload;
      state.fetching = false;
    },
    /**
     * アカウント編集状態を設定する。
     */
    setAccountEditing: (
      state,
      { payload }: PayloadAction<{ editing: boolean; account: Account | undefined }>
    ) => {
      state.editAccount = payload.account;
      state.editMode = payload.editing ? 'account' : undefined;
    },
    /**
     * クリアする。
     */
    clear: (state) => {
      state.accounts = undefined;
    },
    /**
     * GeneralSettingsを設定する。
     */
    clearGeneralSettings: (state) => {
      state.accounts = initialState.accounts;
      state.fetching = initialState.fetching;
      state.fetchError = initialState.fetchError;
      state.editMode = initialState.editMode;
      state.editAccount = initialState.editAccount;
      state.editAccountResult = initialState.editAccountResult;
      state.editAccountError = initialState.editAccountError;
    },
  },
});

export const {
  setFetchError,
  setAccountEditing,
  clear,
  setEditAccountError,
  setEditAccountResult,
  clearGeneralSettings,
} = generalSettings.actions;

/**
 * アカウント情報リストを取得する。
 */
export const fetchAllList = (): AppThunk => async (dispatch) => {
  dispatch(generalSettings.actions.startFetch(true));
  try {
    const accounts = await getAccountsAsync();
    dispatch(generalSettings.actions.setAllList({ accounts }));
  } catch (error) {
    dispatch(generalSettings.actions.setFetchError(getErrorResult(error)));
  }
};

/**
 * アカウント情報リストを取得する。
 */
export const fetchAccounts = (): AppThunk => async (dispatch) => {
  dispatch(generalSettings.actions.startFetch(true));
  try {
    const accounts = await getAccountsAsync();
    dispatch(generalSettings.actions.setAccounts(accounts));
  } catch (error) {
    dispatch(generalSettings.actions.setFetchError(getErrorResult(error)));
  }
};

/**
 * アカウント情報リストを取得する。(fetchingなし)
 */
export const fetchAccountsSilent = (): AppThunk => async (dispatch) => {
  try {
    const accounts = await getAccountsAsync();
    dispatch(generalSettings.actions.setAccounts(accounts));
  } catch (error) {
    dispatch(generalSettings.actions.setFetchError(getErrorResult(error)));
  }
};

/**
 * アカウント情報を更新する。
 */
export const updateAccount =
  (accountId: number, updateAccount: UpdateAccount): AppThunk =>
  async (dispatch) => {
    dispatch(generalSettings.actions.startFetch(true));
    try {
      await updateAccountAsync(accountId, updateAccount);
      dispatch(
        generalSettings.actions.setEditAccountResult({
          isSuccess: true,
          showDialog: true,
          msgId: msgId.resultCompleted,
          isDeleteRequest: false,
        })
      );
    } catch (error) {
      const errorResult = getErrorResult(error);
      dispatch(generalSettings.actions.setEditAccountError(errorResult));
      dispatch(
        generalSettings.actions.setEditAccountResult({
          isSuccess: false,
          showDialog: errorResult.status !== 400,
          msgId: getErrorMsgIdFromErrorResult(errorResult),
          isDeleteRequest: false,
        })
      );
    }
  };

const generalSettingsState = (state: RootState) => state.generalSettings;
const selectAccounts = createSelector(generalSettingsState, (x) => x.accounts);
const selectFetchFetching = createSelector(generalSettingsState, (x) => x.fetching);
const selectFetchError = createSelector(generalSettingsState, (x) => x.fetchError);
const selectEditMode = createSelector(generalSettingsState, (x) => x.editMode);
const selectEditAccount = createSelector(generalSettingsState, (x) => x.editAccount);
const selectEditAccountError = createSelector(generalSettingsState, (x) => x.editAccountError);
const selectEditAccountResult = createSelector(generalSettingsState, (x) => x.editAccountResult);

export const useGeneralSettingsAccounts = () => useSelector(selectAccounts);
export const useGeneralSettingsFetching = () => useSelector(selectFetchFetching);
export const useGeneralSettingsFetchError = () => useSelector(selectFetchError);
export const useGeneralSettingsEditMode = () => useSelector(selectEditMode);
export const useGeneralSettingsEditAccount = () => useSelector(selectEditAccount);
export const useGeneralSettingssEditAccountError = () => useSelector(selectEditAccountError);
export const useGeneralSettingsEditAccountResult = () => useSelector(selectEditAccountResult);

export default generalSettings.reducer;
