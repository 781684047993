import { API } from 'aws-amplify';
import { AisData } from 'models/data';
import { getErrorResult } from 'models/error';
import AppLogger from 'utils/AppLogger';
import { API_NAME } from './maricoApi';

/**
 * リアルタイムAISデータリストを取得する。
 * @param shipIds 船舶IDリスト
 */
export async function getRealtimeAisDataListAsync(shipIds: string[]): Promise<AisData[]> {
  try {
    const params = {
      headers: {
        'Content-Type': 'application/json',
      },
      body: {
        shipIds: shipIds,
      },
      response: true,
      timeout: 30000,
    };
    const result = await API.post(API_NAME, '/v1/ais/realtime', params);

    return result.data;
  } catch (error) {
    AppLogger.error('error getRealtimeAisDataList: ', error);
    throw getErrorResult(error);
  }
}
