import React from 'react';
import { useIntl } from 'react-intl';
import { TempInfoComponent } from './TempInfoComponent';
import { SensorValue } from './MimicContainer';
import msgId from 'resources/intl';
import { Box, styled, SxProps } from '@mui/material';
import colors from 'resources/colors';

const Tc02_400L = 'MegNo1EXTGASTmpP1COM';
const Tc02_400R = 'MegNo1EXTGASTmpS1COM';
const Tc03_400L = 'MegNo2EXTGASTmpP1COM';
const Tc03_400R = 'MegNo2EXTGASTmpS1COM';
const Tc04_400L = 'MegNo3EXTGASTmpP1COM';
const Tc04_400R = 'MegNo3EXTGASTmpS1COM';
const Tc05_400L = 'MegNo4EXTGASTmpP1COM';
const Tc05_400R = 'MegNo4EXTGASTmpS1COM';
const Tc06_400L = 'MegNo5EXTGASTmpP1COM';
const Tc06_400R = 'MegNo5EXTGASTmpS1COM';
const Tc07_400L = 'MegNo6EXTGASTmpP1COM';
const Tc07_400R = 'MegNo6EXTGASTmpS1COM';
const Tc08_400L = 'MegNo7EXTGASTmpP1COM';
const Tc08_400R = 'MegNo7EXTGASTmpS1COM';
const Tc09_400L = 'MegNo8EXTGASTmpP1COM';
const Tc09_400R = 'MegNo8EXTGASTmpS1COM';
const Tc10_400L = 'MegNo9EXTGASTmpP1COM';
const Tc10_400R = 'MegNo9EXTGASTmpS1COM';

const Tc01_401L = 'MegInletAirTmpP1';
const Tc01_401R = 'MegInletAirTmpS1';
const Tc02_401L = 'MegNo1EXTGASTmpP1RIO';
const Tc02_401R = 'MegNo1EXTGASTmpS1RIO';
const Tc03_401L = 'MegNo2EXTGASTmpP1RIO';
const Tc03_401R = 'MegNo2EXTGASTmpS1RIO';
const Tc04_401L = 'MegNo3EXTGASTmpP1RIO';
const Tc04_401R = 'MegNo3EXTGASTmpS1RIO';
const Tc05_401L = 'MegNo4EXTGASTmpP1RIO';
const Tc05_401R = 'MegNo4EXTGASTmpS1RIO';
const Tc06_401L = 'MegNo5EXTGASTmpP1RIO';
const Tc06_401R = 'MegNo5EXTGASTmpS1RIO';
const Tc07_401L = 'MegNo6EXTGASTmpP1RIO';
const Tc07_401R = 'MegNo6EXTGASTmpS1RIO';
const Tc08_401L = 'MegNo7EXTGASTmpP1RIO';
const Tc08_401R = 'MegNo7EXTGASTmpS1RIO';
const Tc09_401L = 'MegNo8EXTGASTmpP1RIO';
const Tc09_401R = 'MegNo8EXTGASTmpS1RIO';
const Tc10_401L = 'MegNo9EXTGASTmpP1RIO';
const Tc10_401R = 'MegNo9EXTGASTmpS1RIO';

const Tc01_402L = 'EngChargedAirTempP';
const Tc01_402R = 'EngChargedAirTempS';
const Tc02_402L = 'EngNo1ExtGasTempP';
const Tc02_402R = 'EngNo1ExtGasTempS';
const Tc03_402L = 'EngNo2ExtGasTempP';
const Tc03_402R = 'EngNo2ExtGasTempS';
const Tc04_402L = 'EngNo3ExtGasTempP';
const Tc04_402R = 'EngNo3ExtGasTempS';
const Tc05_402L = 'EngNo4ExtGasTempP';
const Tc05_402R = 'EngNo4ExtGasTempS';
const Tc06_402L = 'EngNo5ExtGasTempP';
const Tc06_402R = 'EngNo5ExtGasTempS';
const Tc07_402L = 'EngNo6ExtGasTempP';
const Tc07_402R = 'EngNo6ExtGasTempS';
const Tc08_402L = 'EngTcInTemp145P';
const Tc08_402R = 'EngTcInTemp145S';
const Tc09_402L = 'EngTcInTemp236P';
const Tc09_402R = 'EngTcInTemp236S';
const Tc10_402L = 'EngTcOutTempP';
const Tc10_402R = 'EngTcOutTempS';

const RootDiv = styled('div')({
  border: `1px solid ${colors.chart.mimic.border}`,
  position: 'absolute',
  fontSize: 0,
  padding: '3px',
});

const BackgroundDiv = styled('div')({
  width: '100%',
  height: '100%',
  position: 'relative',
  padding: '3px',
  background: 'black',
});

const TitleDiv = styled('div')({
  position: 'relative',
  color: 'white',
  textAlign: 'center',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  userSelect: 'none',
});

interface TcInfoPanelProps {
  sx?: SxProps;
  values: SensorValue[];
  top: number;
  left: number;
  title?: string;
  unit?: string;
  rateV?: number;
  rateH?: number;
}

export function TcInfoPanel(props: TcInfoPanelProps): JSX.Element {
  const { sx, values } = props;
  const intl = useIntl();
  const sensorTitle = props.title
    ? props.title
    : intl.formatMessage({ id: msgId.MimicTcInfoTitle });
  const sensorUnit = props.unit ? props.unit : intl.formatMessage({ id: msgId.MimicTcInfoUnit });
  const rateV = props.rateV ? props.rateV : 1;
  const rateH = props.rateH ? props.rateH : 1;
  const rowSize = 22 * rateV;
  const basePanelHeight = 256 * rateV;
  const basePanelWidth = 165 * rateH;

  const rootProps: SxProps = {
    top: props.top * rateV,
    left: props.left * rateH,
    width: basePanelWidth,
    height: basePanelHeight,
    ...sx,
  };

  const titleProps: SxProps = {
    width: basePanelWidth - 6,
    height: rowSize,
    fontSize: rowSize - 10,
    lineHeight: `${rowSize}px`,
  };

  const styleTempBasePanel: SxProps = {
    width: basePanelWidth - 6,
    height: rowSize * 11,
    position: 'relative',
    marginTop: '3px',
  };

  if (values.length === 0) {
    return <RootDiv sx={rootProps} />;
  }

  return (
    <RootDiv sx={rootProps}>
      <BackgroundDiv>
        <TitleDiv sx={titleProps}>{sensorTitle + '[' + sensorUnit + ']'}</TitleDiv>
        <Box sx={styleTempBasePanel}>
          {values.map(({ sensor, group, value }) => {
            const key = sensor.sensorName + '_' + value;
            switch (sensor.sensorName) {
              case Tc01_401L:
              case Tc01_401R:
              case Tc01_402L:
              case Tc01_402R:
                return (
                  <TempInfoComponent
                    key={key}
                    top={rowSize * 0}
                    left={0}
                    sensor={sensor}
                    group={group}
                    value={value}
                    rateH={rateH}
                    rateV={rateV}
                  />
                );
              case Tc02_400L:
              case Tc02_400R:
              case Tc02_401L:
              case Tc02_401R:
              case Tc02_402L:
              case Tc02_402R:
                return (
                  <TempInfoComponent
                    key={key}
                    top={rowSize * 1}
                    left={0}
                    sensor={sensor}
                    group={group}
                    value={value}
                    rateH={rateH}
                    rateV={rateV}
                  />
                );
              case Tc03_400L:
              case Tc03_400R:
              case Tc03_401L:
              case Tc03_401R:
              case Tc03_402L:
              case Tc03_402R:
                return (
                  <TempInfoComponent
                    key={key}
                    top={rowSize * 2}
                    left={0}
                    sensor={sensor}
                    group={group}
                    value={value}
                    rateH={rateH}
                    rateV={rateV}
                  />
                );
              case Tc04_400L:
              case Tc04_400R:
              case Tc04_401L:
              case Tc04_401R:
              case Tc04_402L:
              case Tc04_402R:
                return (
                  <TempInfoComponent
                    key={key}
                    top={rowSize * 3}
                    left={0}
                    sensor={sensor}
                    group={group}
                    value={value}
                    rateH={rateH}
                    rateV={rateV}
                  />
                );
              case Tc05_400L:
              case Tc05_400R:
              case Tc05_401L:
              case Tc05_401R:
              case Tc05_402L:
              case Tc05_402R:
                return (
                  <TempInfoComponent
                    key={key}
                    top={rowSize * 4}
                    left={0}
                    sensor={sensor}
                    group={group}
                    value={value}
                    rateH={rateH}
                    rateV={rateV}
                  />
                );
              case Tc06_400L:
              case Tc06_400R:
              case Tc06_401L:
              case Tc06_401R:
              case Tc06_402L:
              case Tc06_402R:
                return (
                  <TempInfoComponent
                    key={key}
                    top={rowSize * 5}
                    left={0}
                    sensor={sensor}
                    group={group}
                    value={value}
                    rateH={rateH}
                    rateV={rateV}
                  />
                );
              case Tc07_400L:
              case Tc07_400R:
              case Tc07_401L:
              case Tc07_401R:
              case Tc07_402L:
              case Tc07_402R:
                return (
                  <TempInfoComponent
                    key={key}
                    top={rowSize * 6}
                    left={0}
                    sensor={sensor}
                    group={group}
                    value={value}
                    rateH={rateH}
                    rateV={rateV}
                  />
                );
              case Tc08_400L:
              case Tc08_400R:
              case Tc08_401L:
              case Tc08_401R:
              case Tc08_402L:
              case Tc08_402R:
                return (
                  <TempInfoComponent
                    key={key}
                    top={rowSize * 7}
                    left={0}
                    sensor={sensor}
                    group={group}
                    value={value}
                    rateH={rateH}
                    rateV={rateV}
                  />
                );
              case Tc09_400L:
              case Tc09_400R:
              case Tc09_401L:
              case Tc09_401R:
              case Tc09_402L:
              case Tc09_402R:
                return (
                  <TempInfoComponent
                    key={key}
                    top={rowSize * 8}
                    left={0}
                    sensor={sensor}
                    group={group}
                    value={value}
                    rateH={rateH}
                    rateV={rateV}
                  />
                );
              case Tc10_400L:
              case Tc10_400R:
              case Tc10_401L:
              case Tc10_401R:
              case Tc10_402L:
              case Tc10_402R:
                return (
                  <TempInfoComponent
                    key={key}
                    top={rowSize * 9}
                    left={0}
                    sensor={sensor}
                    group={group}
                    value={value}
                    rateH={rateH}
                    rateV={rateV}
                  />
                );
              default:
                return <React.Fragment key={key} />;
            }
          })}
        </Box>
      </BackgroundDiv>
    </RootDiv>
  );
}
