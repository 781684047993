import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import msgId from 'resources/intl';
import {
  changeMailAddress,
  confirmChangeMailAddress,
  setMailAddressChangeOpen,
  setMailAddressChangeResult,
  setMailAddressChangeError,
  useAccountCognitoUserInfo,
  useAccountInProgress,
  useAccountMailAddressChangeOpen,
  useAccountMailAddressChangeResult,
  useAccountMailAddressChangeError,
  checkAccounts,
} from './accountSlice';
import {
  EditDialog,
  EditDialogContents,
  EditDialogFooterButtonGrid,
  EditDialogInputFieldGrid,
  EditDialogRootGrid,
  EditDialogTitleGrid,
} from 'components/EditDialog';
import { fetchAccountsSilent } from 'features/contents/adminSettings/adminSettingsSlice';
import { MultiLineTypography } from 'components/MultiLineTypography';
import { Grid, Typography, SxProps } from '@mui/material';
import { SmallTextField } from 'components/SmallTextField';
import { NormalButton } from 'components/NormalButton';
import { FadeLoading } from 'components/FadeLoading';
import { AlertDialog } from 'components/AlertDialog';
import { ErrorInfo, ErrorMessages, FieldLabels, getErrorMessage } from 'models/error';
import { theme } from 'resources/theme';
import { CustomScrollBars } from 'components/CustomScrollBars';
import colors from 'resources/colors';
import dimens from 'resources/dimens';

const textFieldSxProps: SxProps = {
  paddingTop: theme.spacing(1),
};

export function AccountMailAddressChangeDialog(): JSX.Element {
  const intl = useIntl();
  const dispatch = useDispatch();
  const open = useAccountMailAddressChangeOpen();
  const result = useAccountMailAddressChangeResult();
  const mailAddressChangeError = useAccountMailAddressChangeError();
  const inProgress = useAccountInProgress();
  const cognitoUserInfo = useAccountCognitoUserInfo();
  const [state, setState] = useState({
    newMailAddress: '',
    confirmNewMailAddress: '',
    authCode: '',
  });
  const [step, setStep] = useState(1);

  let errorMsg: ErrorMessages = {};
  let errors: ErrorInfo[] = [];
  const labels: FieldLabels = {
    PARAMETER_LENGTH_ERROR: {
      fallback: intl.formatMessage({ id: msgId.errorMessageLengthError100 }),
    },
    PARAMETER_FORMAT_ERROR: { fallback: intl.formatMessage({ id: msgId.errorMailAddressFormat }) },
    CODE_EXPAIRED_ERROR: { fallback: intl.formatMessage({ id: msgId.errorAuthCode }) },
    CODE_MISMATCH_ERROR: { fallback: intl.formatMessage({ id: msgId.errorAuthCode }) },
    NOT_AUTHORIZED_ERROR: { fallback: intl.formatMessage({ id: msgId.errorLoginIdNotAuthorized }) },
    fallback: { fallback: intl.formatMessage({ id: msgId.errorMessageOther }) },
  };

  if (mailAddressChangeError) {
    if (Array.isArray(mailAddressChangeError.response)) {
      errors = mailAddressChangeError.response;
    } else {
      errors = [mailAddressChangeError.response];
    }
  }
  errorMsg = getErrorMessage(errors, labels);

  if (!state.newMailAddress) {
    errorMsg['mailAddress'] = intl.formatMessage({ id: msgId.errorMessageLengthError100 });
  } else if (state.newMailAddress !== state.confirmNewMailAddress) {
    errorMsg['mailAddress'] = intl.formatMessage({ id: msgId.errorMailAddressMismatch });
  }
  if (!state.confirmNewMailAddress) {
    errorMsg['confirmNewMailAddress'] = intl.formatMessage({
      id: msgId.errorMessageLengthError100,
    });
  }
  if (state.authCode === '') {
    errorMsg['authCode'] = intl.formatMessage({ id: msgId.messageInputRequired });
  }

  /**
   * バックボタン押下
   */
  const handleBackClick = () => {
    dispatch(setMailAddressChangeOpen(false));
    dispatch(setMailAddressChangeError(undefined));
  };

  const handleSendMailAddress = () => {
    if (cognitoUserInfo && state.newMailAddress === state.confirmNewMailAddress) {
      dispatch(changeMailAddress(cognitoUserInfo, state.newMailAddress));
    }
  };

  const handleSendAuthCode = () => {
    if (cognitoUserInfo) {
      dispatch(confirmChangeMailAddress(cognitoUserInfo, state.authCode));
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setState({ ...state, [event.target.name]: event.target.value });
  };

  const handleResultClear = (isSuccess: boolean) => {
    if (isSuccess && step === 2) {
      dispatch(setMailAddressChangeOpen(false));
      dispatch(setMailAddressChangeError(undefined));
    }
    dispatch(setMailAddressChangeResult(undefined));
  };

  useEffect(() => {
    if (open) {
      setState({
        newMailAddress: '',
        confirmNewMailAddress: '',
        authCode: '',
      });
      setStep(1);
    }
  }, [open]);

  useEffect(() => {
    if (result != null && result.isSuccess) {
      if (step === 1) {
        setStep(2);
        dispatch(setMailAddressChangeResult(undefined));
      }
    }
    if (step === 2 && result && result.isSuccess) {
      dispatch(checkAccounts());
      dispatch(fetchAccountsSilent());
    }
  }, [dispatch, result, step]);

  return (
    <EditDialog
      id="accountMailAddressChangeDialog"
      open={open}
      onBackClick={handleBackClick}
      title={intl.formatMessage({
        id: step === 1 ? msgId.accountInformationMailTitle1 : msgId.accountInformationMailTitle2,
      })}
    >
      {step === 1 && (
        <EditDialogContents>
          <CustomScrollBars
            thumbColor={colors.scrollBar.thumb.light}
            width={dimens.dialogContents.width}
            autoHide={false}
          >
            <EditDialogRootGrid>
              <EditDialogTitleGrid sx={{ marginBottom: theme.spacing(2) }}>
                <Typography variant="h5">
                  {intl.formatMessage({
                    id: msgId.guideMessageEditMailAddress1,
                  })}
                </Typography>
              </EditDialogTitleGrid>
              <EditDialogTitleGrid>
                <MultiLineTypography
                  variant="body1"
                  align="center"
                  text={intl.formatMessage({ id: msgId.guideMessageEditMailAddress2 })}
                />
              </EditDialogTitleGrid>
              <EditDialogInputFieldGrid sx={textFieldSxProps}>
                <SmallTextField
                  id="newMailAddress"
                  fullWidth={true}
                  label={intl.formatMessage({ id: msgId.mailAddress })}
                  autoComplete="email"
                  value={state.newMailAddress}
                  required={true}
                  onChange={handleChange}
                  error={errorMsg['mailAddress'].length > 0}
                  helperText={errorMsg['mailAddress']}
                />
              </EditDialogInputFieldGrid>
              <EditDialogInputFieldGrid sx={textFieldSxProps}>
                <SmallTextField
                  id="confirmNewMailAddress"
                  fullWidth={true}
                  label={intl.formatMessage({ id: msgId.mailAddressConfirm })}
                  autoComplete="email"
                  value={state.confirmNewMailAddress}
                  required={true}
                  onChange={handleChange}
                  error={errorMsg['confirmNewMailAddress'].length > 0}
                  helperText={errorMsg['confirmNewMailAddress']}
                />
              </EditDialogInputFieldGrid>
              <EditDialogFooterButtonGrid>
                <NormalButton
                  label={intl.formatMessage({ id: msgId.send })}
                  color="primary"
                  onClick={() => handleSendMailAddress()}
                  disabled={
                    state.newMailAddress !== state.confirmNewMailAddress ||
                    state.newMailAddress.length === 0
                  }
                />
              </EditDialogFooterButtonGrid>
            </EditDialogRootGrid>
          </CustomScrollBars>
        </EditDialogContents>
      )}
      {step === 2 && (
        <EditDialogContents>
          <CustomScrollBars
            thumbColor={colors.scrollBar.thumb.light}
            width={dimens.dialogContents.width}
            autoHide={false}
          >
            <EditDialogRootGrid>
              <Grid item>
                <Typography variant="h5" color="error">
                  {intl.formatMessage({
                    id: msgId.guideMessageSendAuthCode1,
                  })}
                </Typography>
              </Grid>
              <EditDialogTitleGrid>
                <MultiLineTypography
                  variant="body1"
                  align="center"
                  text={intl.formatMessage({ id: msgId.guideMessageSendAuthCode2 })}
                />
              </EditDialogTitleGrid>
              <EditDialogInputFieldGrid sx={textFieldSxProps}>
                <SmallTextField
                  id="authCode"
                  fullWidth={true}
                  label={intl.formatMessage({ id: msgId.authCode })}
                  required={true}
                  autoComplete="off"
                  value={state.authCode}
                  onChange={handleChange}
                  error={errorMsg['authCode'].length > 0}
                  helperText={errorMsg['authCode']}
                />
              </EditDialogInputFieldGrid>
              <EditDialogFooterButtonGrid>
                <NormalButton
                  label={intl.formatMessage({ id: msgId.authentication })}
                  color="primary"
                  onClick={() => handleSendAuthCode()}
                  disabled={state.authCode.length === 0}
                />
              </EditDialogFooterButtonGrid>
            </EditDialogRootGrid>
          </CustomScrollBars>
        </EditDialogContents>
      )}
      <FadeLoading loading={inProgress} />
      {result != null && result.showDialog && result.msgId && (
        <AlertDialog
          title={intl.formatMessage({ id: result.isSuccess ? msgId.confirm : msgId.error })}
          message={intl.formatMessage({
            id: result.msgId,
          })}
          onClose={() => handleResultClear(result.isSuccess)}
        />
      )}
    </EditDialog>
  );
}
