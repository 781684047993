import React from 'react';
import { useIntl } from 'react-intl';
import colors from 'resources/colors';
import msgId from 'resources/intl';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { SearchResult, SearchResultItem } from 'models/search';
import { Divider, Grid, Radio, styled, Typography } from '@mui/material';
import { ContentsHeader } from 'components/ContentsHeader';
import { theme } from 'resources/theme';
import { NormalButton } from 'components/NormalButton';
import { CheckCircle } from '@mui/icons-material';
import { CustomScrollBars } from 'components/CustomScrollBars';

dayjs.extend(utc);

const RootGrid = styled(Grid)({
  minWidth: 400,
  width: '100%',
  '& > div': {
    width: '100%',
  },
});

const GridItem = styled(Grid)({
  width: '100%',
  '&:last-child': {
    height: '100%',
  },
});

const GridContents = styled(Grid)({
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1),
  paddingTop: theme.spacing(0.5),
  paddingBottom: theme.spacing(0.5),
  minHeight: 32,
  background: colors.listItem.header,
});

const SearchResultDiv = styled('div')({
  width: '100%',
  '& > div': {
    overflow: 'hidden',
  },
});

const FooterDiv = styled('div')({
  width: '100%',
  padding: theme.spacing(1),
  textAlign: 'center',
});

interface SearchResultListProps {
  searchResults: SearchResult[] | undefined;
  selectedItem: SearchResultItem | undefined;
  dateFormat: string;
  dateOnly: boolean;
  onChange: (result: SearchResultItem) => void;
  onOkClick: () => void;
}

export function SearchResultList(props: SearchResultListProps): JSX.Element {
  const { searchResults, selectedItem, dateFormat, dateOnly, onChange, onOkClick } = props;
  const intl = useIntl();

  return (
    <RootGrid container item direction="column" wrap="nowrap">
      <GridItem item>
        <ContentsHeader title={intl.formatMessage({ id: msgId.resultSelection })} />
        {searchResults != null && (
          <SearchResultDiv>
            <GridContents container wrap="nowrap" alignItems="center">
              <Grid item sx={{ width: 40, minWidth: 40 }}></Grid>
              <Grid item sx={{ minWidth: 180 }}>
                <Typography variant="body2" noWrap>
                  {intl.formatMessage({ id: msgId.startDate })}
                </Typography>
              </Grid>
              <Grid item sx={{ minWidth: 180 }}>
                <Typography variant="body2" noWrap>
                  {intl.formatMessage({ id: msgId.endDate })}
                </Typography>
              </Grid>
            </GridContents>
          </SearchResultDiv>
        )}
      </GridItem>
      <GridItem item>
        {searchResults && (
          <CustomScrollBars autoHide={false} width={'calc(100% - 3px)'}>
            <SearchResultDiv>
              {searchResults.length > 0 &&
                searchResults.map((searchResult) => {
                  return (
                    <React.Fragment key={searchResult.shipId}>
                      <GridContents
                        container
                        wrap="nowrap"
                        alignItems="center"
                        sx={{ background: colors.listItem.subHeader }}
                      >
                        <Grid item sx={{ width: '100%' }}>
                          <Typography variant="body2" noWrap>
                            {searchResult.shipName} : {searchResult.machineName}
                          </Typography>
                        </Grid>
                      </GridContents>
                      {searchResult.results.map((item, index) => {
                        const imtekey = String(searchResult.machineId) + '_' + index;
                        const startDate = dateOnly
                          ? dayjs(item.startDate).format(dateFormat)
                          : dayjs(item.startDate).utc().format(dateFormat).substring(0, 21);
                        const endDate = dateOnly
                          ? dayjs(item.endDate).format(dateFormat)
                          : dayjs(item.endDate).utc().format(dateFormat).substring(0, 21);

                        return (
                          <React.Fragment key={index}>
                            <GridContents
                              container
                              wrap="nowrap"
                              alignItems="center"
                              sx={{ background: colors.listItem.normal }}
                            >
                              <Grid item sx={{ width: 40, minWidth: 40 }}>
                                <Typography variant="body2" noWrap>
                                  <Radio
                                    sx={{ width: 24, height: 24 }}
                                    size="small"
                                    color="primary"
                                    checked={
                                      selectedItem?.machineId === item.machineId &&
                                      selectedItem?.startDate === item.startDate &&
                                      selectedItem?.endDate === item.endDate
                                    }
                                    onChange={() => onChange(item)}
                                    value={imtekey}
                                  />
                                </Typography>
                              </Grid>
                              <Grid item sx={{ minWidth: 180 }}>
                                <Typography variant="body2" noWrap>
                                  {startDate}
                                </Typography>
                              </Grid>
                              <Grid item sx={{ minWidth: 180 }}>
                                <Typography variant="body2" noWrap>
                                  {endDate}
                                </Typography>
                              </Grid>
                            </GridContents>
                            <Divider />
                          </React.Fragment>
                        );
                      })}
                    </React.Fragment>
                  );
                })}
              {searchResults.length === 0 && (
                <React.Fragment key="empty">
                  <GridContents
                    container
                    wrap="nowrap"
                    alignItems="center"
                    sx={{ background: colors.listItem.normal }}
                  >
                    <Grid item>
                      <Typography variant="body2" noWrap>
                        {intl.formatMessage({ id: msgId.noResults })}
                      </Typography>
                    </Grid>
                  </GridContents>
                  <Divider />
                </React.Fragment>
              )}
            </SearchResultDiv>
            <FooterDiv>
              <NormalButton
                label={intl.formatMessage({ id: msgId.decision })}
                color="primary"
                startIcon={<CheckCircle />}
                disabled={
                  selectedItem == null || searchResults == null || searchResults.length === 0
                }
                onClick={() => onOkClick()}
              />
            </FooterDiv>
          </CustomScrollBars>
        )}
      </GridItem>
    </RootGrid>
  );
}
