import React from 'react';
import { useIntl } from 'react-intl';
import { DialogResult } from 'components/AlertDialog';
import colors from 'resources/colors';
import msgId from 'resources/intl';
import { CustomScrollBars } from 'components/CustomScrollBars';
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Fade,
  Grid,
  LinearProgress,
  linearProgressClasses,
  LinearProgressProps,
  Paper,
  styled,
  Typography,
} from '@mui/material';
import { theme } from 'resources/theme';
import { useReportFetching, useReportItems } from './reportSlice';
import constants from 'resources/constants';

const StyledLinearProgress = styled(LinearProgress)({
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: colors.border,
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: colors.accent,
  },
});

function LinearProgressWithLabel(props: LinearProgressProps & { value: number }) {
  return (
    <Box display="flex" alignItems="center">
      <Box width={85} mr={1}>
        <StyledLinearProgress variant="determinate" {...props} />
      </Box>
      <Box width={35}>
        <Typography variant="body2" color="textSecondary">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

const RootDiv = styled('div')({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  background: colors.loading.background,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  overflow: 'hidden',
});

interface ReportProgressPanelProps {
  open: boolean;
  onClose: (result: DialogResult) => void;
  containerHeight: number;
}

export function ReportProgressPanel(props: ReportProgressPanelProps): JSX.Element {
  const { open, onClose, containerHeight } = props;
  const intl = useIntl();
  const reportFetching = useReportFetching();
  const reportItems = useReportItems();

  return (
    <Fade
      in={open}
      style={{
        transitionDelay: open ? '500ms' : '0ms',
        zIndex: constants.zIndex.splitter - 1,
      }}
      unmountOnExit
    >
      <RootDiv>
        <Paper sx={{ width: '96%' }}>
          <DialogTitle>{intl.formatMessage({ id: msgId.download })}</DialogTitle>
          <DialogContent>
            <CustomScrollBars autoHeight={false} height={containerHeight - 200} autoHide={false}>
              {reportItems?.map((item) => {
                return (
                  <React.Fragment key={item.ship.shipId + item.reportType}>
                    <Grid
                      container
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="center"
                      wrap="nowrap"
                      sx={{ marginTop: theme.spacing(0.5) }}
                    >
                      <Grid
                        item
                        sx={{
                          minWidth: 120,
                          maxWidth: 120,
                          marginRight: theme.spacing(1),
                        }}
                      >
                        <Typography variant="body2" noWrap>
                          {item.ship.name}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        sx={{
                          minWidth: 120,
                          maxWidth: 120,
                          marginRight: theme.spacing(1),
                        }}
                      >
                        <Typography variant="body2" noWrap>
                          {intl.formatMessage({ id: 'ReportType' + item.reportType })}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        sx={{
                          minWidth: 120,
                          maxWidth: 120,
                          marginRight: theme.spacing(1),
                        }}
                      >
                        <LinearProgressWithLabel value={item.progress} />
                      </Grid>
                      <Grid item>
                        {item.reportResult !== 'Generating' && item.reportResult !== 'Canceled' && (
                          <Typography
                            variant="body2"
                            noWrap
                            color={item.reportResult === 'Completed' ? 'textPrimary' : 'error'}
                          >
                            {intl.formatMessage({ id: 'ReportResult' + item.reportResult })}
                          </Typography>
                        )}
                      </Grid>
                    </Grid>
                    <Divider />
                  </React.Fragment>
                );
              })}
            </CustomScrollBars>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => onClose('abort')}
              color="primary"
              sx={{ minWidth: 100 }}
              disabled={!reportFetching}
            >
              {intl.formatMessage({ id: msgId.abort })}
            </Button>
            <Button
              onClick={() => onClose('ok')}
              color="primary"
              sx={{ minWidth: 100 }}
              disabled={reportFetching}
            >
              {intl.formatMessage({ id: msgId.ok })}
            </Button>
          </DialogActions>
        </Paper>
      </RootDiv>
    </Fade>
  );
}
