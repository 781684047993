import React from 'react';
import { useIntl, IntlShape } from 'react-intl';
import msgId from 'resources/intl';
import Plotly, { Layout, LayoutAxis, Shape, PlotMouseEvent, Annotations } from 'plotly.js';
import createPlotlyComponent from 'react-plotly.js/factory';
import colors from 'resources/colors';
import { AnalysisData } from 'models/analysis';
import { Box, styled, SxProps } from '@mui/material';
import { roundValueToString } from 'utils/misc';

const Plot = createPlotlyComponent(Plotly);
const axisFontColor = colors.chart.analysis.rexPropulsionOperation.axisFontColor;
const gridColor = colors.chart.analysis.rexPropulsionOperation.gridColor;
const y1TickColor = colors.chart.analysis.rexPropulsionOperation.y1TickColor;
const y1TickFontColor = colors.chart.analysis.rexPropulsionOperation.y1TickFontColor;
const y2TickFontColor = colors.chart.analysis.rexPropulsionOperation.y2TickFontColor;

const HoverDiv = styled('div')({
  position: 'absolute',
  color: 'white',
  background: 'black',
  zIndex: 2,
  fontFamily: 'Arial, sans-serif',
  pointerEvents: 'none',
  fontSize: 13,
});

const plotLStyle: React.CSSProperties = {
  width: '100%',
  height: 500,
  background: colors.chart.legend.background,
};

const plotRStyle: React.CSSProperties = {
  width: '100%',
  height: 500,
  background: colors.chart.legend.background,
};

function hoverInfoText(
  intl: IntlShape,
  date: string,
  ang: string,
  cpp: string | undefined,
  totalH: string,
  unit: string,
  unitH: string
): string {
  if (cpp !== undefined) {
    return (
      intl.formatMessage({ id: msgId.AnalysisHoverInfoLabelDate }) +
      ':' +
      date +
      '<br>' +
      intl.formatMessage({ id: msgId.AnalysisRexStrAngFB }) +
      ':' +
      ang +
      unit +
      '<br>' +
      intl.formatMessage({ id: msgId.AnalysisRexCPPBladeAngFB }) +
      ':' +
      cpp +
      unit +
      '<br>' +
      intl.formatMessage({ id: msgId.AnalysisTotalDrivingTimeLabel }) +
      ':' +
      totalH +
      unitH
    );
  } else {
    return (
      intl.formatMessage({ id: msgId.AnalysisHoverInfoLabelDate }) +
      ':' +
      date +
      '<br>' +
      intl.formatMessage({ id: msgId.AnalysisRexStrAngFB }) +
      ':' +
      ang +
      unit +
      '<br>' +
      intl.formatMessage({ id: msgId.AnalysisTotalDrivingTimeLabel }) +
      ':' +
      totalH +
      unitH
    );
  }
}

interface RexPropulsionOperationChartProps {
  sx?: SxProps;
  analysisData: AnalysisData;
}

export function RexPropulsionOperationChart(props: RexPropulsionOperationChartProps): JSX.Element {
  const intl = useIntl();
  const { sx, analysisData } = props;
  const contents = analysisData.data.rexPropulsionOperation;
  if (contents == null || contents.date.length === 0) {
    return <Box sx={sx}></Box>;
  }
  const unit = contents.displayInformations.RexStrCPP.unit;
  const round = contents.displayInformations.RexStrCPP.roundingPosition;
  const yDataL: number[][] = [[], [], [], [], [], []];
  const yDataR: number[][] = [[], [], [], [], [], []];
  const yTextL: string[] = [];
  const yTextR: string[] = [];
  const yAnnotationL: { val: string; pos: number }[] = [];
  const yAnnotationR: { val: string; pos: number }[] = [];
  const yAnnotationListL: Partial<Annotations>[] = [];
  const yAnnotationListR: Partial<Annotations>[] = [];
  const xData: number[] = [];
  const plotDataListL: Plotly.Data[] = [];
  const plotDataListR: Plotly.Data[] = [];

  for (let i = 1; i <= 31; i++) {
    xData.push(i);
    yTextL.push('');
    yTextR.push('');
    for (let j = 0; j < 6; j++) {
      yDataL[0].push(0);
      yDataR[0].push(0);
    }
  }
  contents.date.forEach((date, index) => {
    const d = new Date(date);
    const dateStrings = d.toISOString().replace('T', ' ').split('-').join('/').split(':');
    dateStrings.pop();
    const dateString = dateStrings.join(':');
    const dayIndex = d.getUTCDate() - 1;

    if (analysisData.dataFormatId !== 402) {
      yDataL[0][dayIndex] = contents.RexStrAngFBP1[index];
      yDataR[0][dayIndex] = contents.RexStrAngFBS1[index];
      yDataL[1][dayIndex] = contents.RexCPPBladeAngFBP1[index];
      yDataR[1][dayIndex] = contents.RexCPPBladeAngFBS1[index];
      let text = hoverInfoText(
        intl,
        dateString + '',
        roundValueToString(contents.RexStrAngFBP1[index], round),
        roundValueToString(contents.RexCPPBladeAngFBP1[index], round),
        roundValueToString(
          contents.DrivingTimeP1[index] ? contents.DrivingTimeP1[index] : 0,
          contents.displayInformations.DrivingTime.roundingPosition
        ),
        unit + '',
        contents.displayInformations.DrivingTime.unit + ''
      );
      yTextL[dayIndex] = text;
      text = hoverInfoText(
        intl,
        dateString + '',
        roundValueToString(contents.RexStrAngFBS1[index], round),
        roundValueToString(contents.RexCPPBladeAngFBS1[index], round),
        roundValueToString(contents.DrivingTimeP1[index] ? contents.DrivingTimeP1[index] : 0, round),
        unit + '',
        contents.displayInformations.DrivingTime.unit + ''
      );
      yTextR[dayIndex] = text;
      if (contents.DrivingTimeP1[index]) {
        contents.DrivingTimeP1[index] &&
          (yAnnotationL[dayIndex] = {
            val: roundValueToString(contents.DrivingTimeP1[index], round),
            pos:
              contents.RexStrAngFBP1[index] > contents.RexCPPBladeAngFBP1[index]
                ? contents.RexStrAngFBP1[index]
                : contents.RexCPPBladeAngFBP1[index],
          });
      }
      if (contents.DrivingTimeS1[index]) {
        contents.DrivingTimeS1[index] &&
          (yAnnotationR[dayIndex] = {
            val: roundValueToString(contents.DrivingTimeS1[index], round),
            pos:
              contents.RexStrAngFBS1[index] > contents.RexCPPBladeAngFBS1[index]
                ? contents.RexStrAngFBS1[index]
                : contents.RexCPPBladeAngFBS1[index],
          });
      }
    } else {
      yDataL[0][dayIndex] = contents.RexStrAngFbP[index];
      yDataR[0][dayIndex] = contents.RexStrAngFbS[index];
      let text = hoverInfoText(
        intl,
        dateString + '',
        roundValueToString(contents.RexStrAngFbP[index], round),
        undefined,
        roundValueToString(
          contents.DrivingTimeP[index] ? contents.DrivingTimeP[index] : 0,
          contents.displayInformations.DrivingTime.roundingPosition
        ),
        unit + '',
        contents.displayInformations.DrivingTime.unit + ''
      );
      yTextL[dayIndex] = text;
      text = hoverInfoText(
        intl,
        dateString + '',
        roundValueToString(contents.RexStrAngFbS[index], round),
        undefined,
        roundValueToString(contents.DrivingTimeS[index] ? contents.DrivingTimeS[index] : 0, round),
        unit + '',
        contents.displayInformations.DrivingTime.unit + ''
      );
      yTextR[dayIndex] = text;
      if (contents.DrivingTimeP[index]) {
        contents.DrivingTimeP[index] &&
          (yAnnotationL[dayIndex] = {
            val: roundValueToString(contents.DrivingTimeP[index], round),
            pos: contents.RexStrAngFbP[index],
          });
      }
      if (contents.DrivingTimeS[index]) {
        contents.DrivingTimeS[index] &&
          (yAnnotationR[dayIndex] = {
            val: roundValueToString(contents.DrivingTimeS[index], round),
            pos: contents.RexStrAngFbS[index],
          });
      }
    }
  });

  let outCnt: number;
  if (analysisData.dataFormatId !== 402) {
    outCnt = 2;
  } else {
    outCnt = 1;
  }

  for (let i = 0; i < outCnt; i++) {
    const OutDataL = {
      x: yDataL[i],
      y: xData,
      orientation: 'h',
      marker: {
        color: colors.chart.lines[i],
      },
      type: 'bar',
      text: yTextL,
      textposition: 'none',
      hoverinfo: 'none',
    } as Plotly.Data;
    const OutDataR = {
      x: yDataR[i],
      y: xData,
      orientation: 'h',
      marker: {
        color: colors.chart.lines[i],
      },
      type: 'bar',
      text: yTextR,
      textposition: 'none',
      hoverinfo: 'none',
    } as Plotly.Data;
    plotDataListL.push(OutDataL);
    plotDataListR.push(OutDataR);
  }
  yAnnotationL.forEach((value, index) => {
    if (value.pos !== 0) {
      const annotation: Partial<Annotations> = {
        xref: 'x',
        yref: 'y',
        x: value.pos,
        xanchor: 'left',
        y: index + 1,
        yanchor: 'middle',
        text: '' + value.val,
        xshift: 10,
        showarrow: false,
        font: {
          size: 13,
          color: y2TickFontColor,
        },
      };
      yAnnotationListL.push(annotation);
    }
  });
  yAnnotationR.forEach((value, index) => {
    if (value.pos !== 0) {
      const annotation: Partial<Annotations> = {
        xref: 'x',
        yref: 'y',
        x: value.pos,
        xanchor: 'left',
        y: index + 1,
        yanchor: 'middle',
        text: '' + value.val,
        xshift: 10,
        showarrow: false,
        font: {
          size: 13,
          color: y2TickFontColor,
        },
      };
      yAnnotationListR.push(annotation);
    }
  });
  const shapeLine: Partial<Shape> = {
    type: 'line',
    xref: 'paper',
    yref: 'paper',
    x0: 0,
    y0: 1,
    x1: 1,
    y1: 1,
    line: {
      color: y1TickColor,
      width: 1,
    },
  };
  const shapeBottomLine: Partial<Shape> = {
    type: 'line',
    xref: 'paper',
    yref: 'paper',
    x0: 0,
    y0: 0,
    x1: 1,
    y1: 0,
    line: {
      color: gridColor,
      width: 1,
    },
  };

  const xaxis = {
    title: {
      text: intl.formatMessage({ id: msgId.AnalysisRexAngXTitle }) + '[' + unit + ']',
      font: { color: axisFontColor },
    },
    // range: [
    //   contents.displayInformations.RexStrCPP.rangeMin,
    //   contents.displayInformations.RexStrCPP.rangeMax,
    // ],
    showgrid: true,
    gridcolor: gridColor,
    tickfont: { color: y1TickFontColor, size: 13 },
    side: 'top',
    tickmode: 'auto',
    tickangle: 0,
    nticks: 5,
  } as Partial<LayoutAxis>;

  const yaxis = {
    title: {
      text: intl.formatMessage({ id: msgId.AnalysisMenuLoadBandAnalysisXTitle }),
      font: { color: axisFontColor },
    },
    tickvals: xData,
    tickfont: { color: axisFontColor, size: 13 },
    range: [0.5, 31.5],
    showgrid: false,
    gridcolor: gridColor,
    autorange: 'reversed',
  };

  const layout = {
    title: {
      text: intl.formatMessage({ id: msgId.trendLabelPort }),
      color: axisFontColor,
      x: 0.05,
      y: 0.98,
      font: { color: axisFontColor },
    },
    height: 500,
    autosize: true,
    xaxis: xaxis,
    yaxis: yaxis,
    paper_bgcolor: colors.chart.legend.background,
    plot_bgcolor: colors.chart.legend.background,
    margin: {
      t: 45,
      b: 5,
      l: 60,
      r: 60,
      pad: 2,
    },
    showlegend: false,
    shapes: [shapeLine, shapeBottomLine],
    annotations: yAnnotationListL,
    hovermode: 'closest',
    hoverlabel: {
      bgcolor: 'black',
      bordercolor: 'black',
      font: {
        color: 'white',
      },
      align: 'left',
    },
  } as Partial<Layout>;
  const layoutR = Object.assign({}, layout);
  Object.assign(layoutR, {
    title: {
      text: intl.formatMessage({ id: msgId.trendLabelStbd }),
      color: axisFontColor,
      x: 0.05,
      y: 0.98,
      font: { color: axisFontColor },
    },
    annotations: yAnnotationListR,
  });
  const plotConfig = { displayModeBar: false, showTips: false };

  const panel = 'RexPropulsionOperation';
  const PlotL = 'PlotL' + panel;
  const PlotR = 'PlotR' + panel;
  let relayouting = false;
  function onRelayout(event: Readonly<Plotly.PlotRelayoutEvent>, side: 'left' | 'right') {
    if (!relayouting) {
      let divId: string;
      relayouting = true;
      setTimeout(() => {
        relayouting = false;
      }, 500);
      if (side === 'left') {
        divId = PlotR;
      } else {
        divId = PlotL;
      }
      Plotly.relayout(document.getElementById(divId) as HTMLElement, event);
    }
  }

  const hoverIdL = PlotL + 'hover';
  const hoverIdR = PlotR + 'hover';
  const dataL = plotDataListL;
  const dataR = plotDataListR;

  function onHover(data: Readonly<PlotMouseEvent>, side: 'left' | 'right') {
    const point = data.points[0];
    let showHoverInfo = false;
    for (const [key] of Object.entries(point)) {
      if (key === 'text') {
        showHoverInfo = true;
        break;
      }
    }
    if (showHoverInfo === false) return;
    let hoverTexts;
    let eId: string;
    let offset = 0;
    if (side === 'left') {
      hoverTexts = dataR[point.curveNumber].text as string[];
      eId = hoverIdR;
      offset = 500;
    } else {
      hoverTexts = dataL[point.curveNumber].text as string[];
      eId = hoverIdL;
    }
    if (!hoverTexts) return;
    const hoverText = hoverTexts[point.pointIndex];
    const div = document.getElementById(eId);
    if (div) {
      div.innerHTML = hoverText;
      div.style.left = data.event.offsetX + 'px';
      div.style.top = data.event.offsetY + offset + 'px';
      div.style.padding = '3px';
    }

    if (side === 'left') {
      hoverTexts = dataL[point.curveNumber].text as string[];
      const hoverText = hoverTexts[point.pointIndex];
      const div = document.getElementById(hoverIdL);
      if (div) {
        div.innerHTML = hoverText;
        div.style.left = data.event.offsetX + 'px';
        div.style.top = data.event.offsetY + 'px';
        div.style.padding = '3px';
      }
    } else {
      hoverTexts = dataR[point.curveNumber].text as string[];
      const hoverText = hoverTexts[point.pointIndex];
      const div = document.getElementById(hoverIdR);
      if (div) {
        div.innerHTML = hoverText;
        div.style.left = data.event.offsetX + 'px';
        div.style.top = data.event.offsetY + 500 + 'px';
        div.style.padding = '3px';
      }
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  function onUnhover(event: Readonly<Plotly.PlotMouseEvent>) {
    let e = document.getElementById(hoverIdL);
    if (e) {
      e.innerHTML = '';
      e.style.padding = '0px';
    }
    e = document.getElementById(hoverIdR);
    if (e) {
      e.innerHTML = '';
      e.style.padding = '0px';
    }
  }

  return (
    <Box sx={sx}>
      <HoverDiv id={hoverIdL} />
      <Plot
        divId={PlotL}
        onRelayout={(event) => onRelayout(event, 'left')}
        style={plotLStyle}
        data={plotDataListL}
        useResizeHandler
        layout={layout}
        onHover={(event) => onHover(event, 'left')}
        onUnhover={onUnhover}
        config={plotConfig}
      />
      <HoverDiv id={hoverIdR} />
      <Plot
        divId={PlotR}
        onRelayout={(event) => onRelayout(event, 'right')}
        style={plotRStyle}
        data={plotDataListR}
        useResizeHandler
        layout={layoutR}
        onHover={(event) => onHover(event, 'right')}
        onUnhover={onUnhover}
        config={plotConfig}
      />
    </Box>
  );
}
