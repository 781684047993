import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import msgId from 'resources/intl';
import {
  EditDialog,
  EditDialogContents,
  EditDialogFooterButtonGrid,
  EditDialogInputFieldGrid,
  EditDialogRootGrid,
  EditDialogTitleGrid,
  EditDialogWideInputFieldGrid,
} from 'components/EditDialog';
import {
  Box,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  styled,
  Typography,
} from '@mui/material';
import { SmallTextField } from 'components/SmallTextField';
import { NormalButton } from 'components/NormalButton';
import { theme } from 'resources/theme';
import { ErrorInfo, ErrorMessages, FieldLabels, getErrorMessage } from 'models/error';
import { AlertDialog } from 'components/AlertDialog';
import dimens from 'resources/dimens';
import { CustomScrollBars } from 'components/CustomScrollBars';
import colors from 'resources/colors';
import { FadeLoading } from 'components/FadeLoading';
import {
  deleteMaintenance,
  registrationMaintenanceData,
  setEditMaintenanceData,
  setEditMaintenanceDataError,
  setEditMaintenanceDataResult,
  updateMaintenanceData,
  useEditMaintenanceDataResult,
  useMaintenanceDataEditError,
  useMaintenanceDataEditing,
  useMaintenanceEditMaintenanceData,
} from './maintenanceSlice';
import { DatePicker } from 'components/DatePicker';
import constants from 'resources/constants';
import dayjs from 'dayjs';
import { useCommonShips } from 'app/commonSlice';
import config from 'resources/config';
import AppLogger from 'utils/AppLogger';

const DateRow = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  width: '100%',
  background: theme.palette.background.default,
});

interface AccountEditDialogProps {
  contentHeight: number;
}

export function MaintenanceEditDialog(props: AccountEditDialogProps): JSX.Element {
  const { contentHeight } = props;
  const intl = useIntl();
  const dispatch = useDispatch();
  const ships = useCommonShips();
  const editMaintenanceData = useMaintenanceEditMaintenanceData();
  const updateResult = useEditMaintenanceDataResult();
  const editError = useMaintenanceDataEditError();
  const inProgress = useMaintenanceDataEditing();
  const [state, setState] = useState<{
    shipId: string;
    startDate: string;
    endDate: string;
    memo: string;
    startDateError: boolean;
    endDateError: boolean;
    rangeError: boolean;
  }>({
    shipId: '',
    startDate: '',
    endDate: '',
    memo: '',
    startDateError: false,
    endDateError: false,
    rangeError: false,
  });
  const [deleteDialog, setDeleteDialog] = useState<boolean>(false);

  let errorMsg: ErrorMessages = {};
  const labels: FieldLabels = {
    PARAMETER_LENGTH_ERROR: {
      fallback: intl.formatMessage({ id: msgId.errorMessageLengthError100 }),
      password: intl.formatMessage({ id: msgId.errorPasswordRequirement }),
    },
    PARAMETER_FORMAT_ERROR: {
      fallback: intl.formatMessage({ id: msgId.errorLoginIdInvalidCharacter }),
      mailAddress: intl.formatMessage({ id: msgId.errorMailAddressFormat }),
      passsword: intl.formatMessage({ id: msgId.errorPasswordFormat }),
    },
    PARAMETER_REQUIREMENTS_ERROR: {
      fallback: intl.formatMessage({ id: msgId.errorPasswordRequirement }),
    },
    PASSWORD_MISMATCH_ERROR: {
      fallback: intl.formatMessage({ id: msgId.errorCurrentPasswordMismatch }),
    },
    PARAMETER_INVALID_CHARACTER_ERROR: {
      fallback: intl.formatMessage({ id: msgId.errorPasswordFormat }),
    },
    PARAMETER_DUPLICATION_ERROR: {
      fallback: intl.formatMessage({ id: msgId.errorMessageParameterRequirements }),
    },
    fallback: { fallback: intl.formatMessage({ id: msgId.errorMessageOther }) },
  };
  let errors: ErrorInfo[] = [];
  if (editError) {
    if (Array.isArray(editError.response)) {
      errors = editError.response;
    } else {
      errors = [editError.response];
    }
  }
  errorMsg = getErrorMessage(errors, labels);

  const isOverlapError =
    errors.length > 0 && errors[0].errorId === 'PARAMETER_ALERT_RANGE_OVERLAP_ERROR';

  /**
   * バックボタン押下
   */
  const handleBackClick = () => {
    dispatch(setEditMaintenanceData(undefined));
    dispatch(setEditMaintenanceDataError(undefined));
    setState({
      shipId: '',
      startDate: '',
      endDate: '',
      memo: '',
      startDateError: false,
      endDateError: false,
      rangeError: false,
    });
  };

  /**
   * 削除ボタン押下時に呼ばれる。
   */
  const handleDeleteClick = () => {
    setDeleteDialog(true);
  };

  /**
   * 削除実行時に呼ばれる。
   */
  const handleDeleteClickOk = (result: string) => {
    if (editMaintenanceData && result === 'ok') {
      dispatch(deleteMaintenance(editMaintenanceData.maintenanceId));
    }
    setDeleteDialog(false);
  };

  /**
   * 登録ボタン押下時に呼ばれる。
   */
  const handleRegistrationClick = () => {
    if (editMaintenanceData != null) {
      if (editMaintenanceData.maintenanceId !== 0) {
        dispatch(
          updateMaintenanceData(editMaintenanceData.maintenanceId, {
            shipId: state.shipId,
            startDate: state.startDate,
            endDate: state.endDate,
            memo: state.memo,
            checkAlertOverlap: true,
          })
        );
      } else {
        dispatch(
          registrationMaintenanceData({
            shipId: state.shipId,
            startDate: state.startDate,
            endDate: state.endDate,
            memo: state.memo,
            checkAlertOverlap: true,
          })
        );
      }
    }
  };

  const handleNoCheckRegistration = (result: string) => {
    if (editMaintenanceData && result === 'ok') {
      if (editMaintenanceData.maintenanceId !== 0) {
        dispatch(
          updateMaintenanceData(editMaintenanceData.maintenanceId, {
            shipId: state.shipId,
            startDate: state.startDate,
            endDate: state.endDate,
            memo: state.memo,
            checkAlertOverlap: false,
          })
        );
      } else {
        dispatch(
          registrationMaintenanceData({
            shipId: state.shipId,
            startDate: state.startDate,
            endDate: state.endDate,
            memo: state.memo,
            checkAlertOverlap: false,
          })
        );
      }
    }
    dispatch(setEditMaintenanceDataError(undefined));
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setState({ ...state, [event.target.name]: event.target.value });
  };

  /**
   * 開始日付の変更
   * @param date 日付ピッカー
   * @param value 日付
   */
  const handleStartDateChange = (value: string | undefined) => {
    if (value != null) {
      let startDate;
      let startDateError = false;
      let endDateError = false;

      try {
        startDate = dayjs(value).utc().format(constants.dateFormat.iso8601WithoutSeconds);
      } catch (e) {
        return;
      }

      const start = dayjs(startDate);
      const end = dayjs(state.endDate);
      if (start > end) {
        startDateError = true;
        endDateError = true;
      }
      if (start < end) {
        const diff = end.diff(start);
        if (diff > config.dataRecalcRangeLimit) {
          startDateError = true;
          endDateError = true;
        }
      }

      setState({
        ...state,
        startDate: startDate,
        startDateError: startDateError,
        endDateError: endDateError,
      });
    }
  };

  /**
   * 終了日付の変更
   * @param date 日付ピッカー
   * @param value 日付
   */
  const handleEndDateChange = (value: string | undefined) => {
    if (value != null) {
      let endDate;
      let startDateError = false;
      let endDateError = false;

      try {
        endDate = dayjs(value).utc().format(constants.dateFormat.iso8601WithoutSeconds);
      } catch (e) {
        return;
      }

      const start = dayjs(state.startDate);
      const end = dayjs(endDate);
      if (start > end) {
        startDateError = true;
        endDateError = true;
      }

      setState({
        ...state,
        endDate: endDate,
        startDateError: startDateError,
        endDateError: endDateError,
      });
    }
  };

  const handleSelectChange = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    if (event.target.name) {
      switch (event.target.name) {
        case 'ship-id-group':
          setState({ ...state, ['shipId']: event.target.value as string });
          break;
      }
    }
  };

  const handleResultClear = (isSuccess: boolean) => {
    if (isSuccess) {
      dispatch(setEditMaintenanceData(undefined));
      dispatch(setEditMaintenanceDataError(undefined));
      setDeleteDialog(false);
      setState({
        shipId: '',
        startDate: '',
        endDate: '',
        memo: '',
        startDateError: false,
        endDateError: false,
        rangeError: false,
      });
    }
    dispatch(setEditMaintenanceDataResult(undefined));
  };

  useEffect(() => {
    if (editMaintenanceData != null) {
      setState({
        shipId: editMaintenanceData.shipId,
        startDate: editMaintenanceData.startDate,
        endDate: editMaintenanceData.endDate,
        memo: editMaintenanceData.memo,
        startDateError: false,
        endDateError: false,
        rangeError: false,
      });
    } else {
      setState({
        shipId: '',
        startDate: '',
        endDate: '',
        memo: '',
        startDateError: false,
        endDateError: false,
        rangeError: false,
      });
    }
  }, [dispatch, editMaintenanceData]);

  useEffect(() => {
    if (updateResult && updateResult.isSuccess && updateResult.isDeleteRequest) {
      dispatch(setEditMaintenanceData(undefined));
      dispatch(setEditMaintenanceDataError(undefined));
      setState({
        shipId: '',
        startDate: '',
        endDate: '',
        memo: '',
        startDateError: false,
        endDateError: false,
        rangeError: false,
      });
      dispatch(setEditMaintenanceDataResult(undefined));
    }
  }, [dispatch, updateResult]);

  // AppLogger.debug('start: ' + state.startDate);
  // AppLogger.debug('end: ' + state.endDate);

  return (
    <EditDialog
      id="maintenanceEditDialog"
      open={editMaintenanceData != null}
      onBackClick={handleBackClick}
      title={intl.formatMessage({
        id:
          editMaintenanceData?.maintenanceId !== 0
            ? msgId.maintenanceInformation
            : msgId.maintenanceNewRegistration,
      })}
    >
      <EditDialogContents>
        <CustomScrollBars
          thumbColor={colors.scrollBar.thumb.light}
          height={contentHeight}
          width={dimens.dialogContents.width}
          autoHide={false}
        >
          <EditDialogRootGrid>
            <EditDialogTitleGrid>
              {intl
                .formatMessage({ id: msgId.guideMessageEditMaintenance })
                .split('\n')
                .map((t, i) => {
                  return (
                    <Typography key={i} variant="h5" align="center">
                      {t}
                    </Typography>
                  );
                })}
            </EditDialogTitleGrid>
            <EditDialogWideInputFieldGrid
              sx={{
                margin: theme.spacing(0),
              }}
            >
              <DateRow>
                <Typography
                  variant="body2"
                  noWrap
                  sx={{ paddingLeft: theme.spacing(1), color: colors.accent }}
                >
                  {intl.formatMessage({ id: msgId.maintenanceDateLabel })}
                </Typography>
                {state.startDate !== '' && (
                  <DatePicker
                    value={state.startDate}
                    type="datetime"
                    unsetMax={true}
                    displayFormat={constants.dateFormat.YYYYMMDDHHmm}
                    onValueChanged={handleStartDateChange}
                    error={state.startDateError || state.rangeError}
                  />
                )}
                <Typography variant="body2" sx={{ paddingTop: '2px' }}>
                  -
                </Typography>
                {state.endDate !== '' && (
                  <DatePicker
                    value={state.endDate}
                    type="datetime"
                    unsetMax={true}
                    displayFormat={constants.dateFormat.YYYYMMDDHHmm}
                    onValueChanged={handleEndDateChange}
                    error={state.endDateError || state.rangeError}
                  />
                )}
              </DateRow>
            </EditDialogWideInputFieldGrid>
            <EditDialogWideInputFieldGrid>
              {state.rangeError && (
                <Box>
                  {intl
                    .formatMessage({ id: msgId.errorMessageDataRecalcRange })
                    .split('\n')
                    .map((t, i) => {
                      return (
                        <Typography key={i} variant="body2" align="left" color="error">
                          {t}
                        </Typography>
                      );
                    })}
                </Box>
              )}
            </EditDialogWideInputFieldGrid>
            <EditDialogInputFieldGrid
              sx={{
                background: colors.subDrawer.background,
                paddingLeft: theme.spacing(2),
                paddingRight: theme.spacing(2),
                paddingTop: theme.spacing(0.5),
                paddingBottom: theme.spacing(0.5),
              }}
            >
              <FormControl>
                <RadioGroup name="ship-id-group" value={state.shipId} onChange={handleSelectChange}>
                  {ships &&
                    ships.map((ship, index) => {
                      return (
                        <FormControlLabel
                          key={'ship_' + index}
                          value={ship.shipId}
                          control={<Radio size="small" sx={{ padding: theme.spacing(0.5) }} />}
                          label={
                            <Typography
                              variant="body2"
                              width={dimens.dialogContents.labelMaxWidth}
                              noWrap
                            >
                              {ship.name}
                            </Typography>
                          }
                        />
                      );
                    })}
                </RadioGroup>
              </FormControl>
            </EditDialogInputFieldGrid>
            <EditDialogInputFieldGrid>
              <SmallTextField
                id="memo"
                fullWidth={true}
                label={intl.formatMessage({ id: msgId.remarks })}
                autoComplete="off"
                value={state.memo}
                multiline={true}
                onChange={handleChange}
                error={errorMsg['memo'].length > 0}
                helperText={
                  errorMsg['memo'].length > 0
                    ? intl.formatMessage({ id: msgId.errorMessageLengthError0To100 })
                    : errorMsg['memo']
                }
              />
            </EditDialogInputFieldGrid>
            <EditDialogFooterButtonGrid>
              {editMaintenanceData?.maintenanceId !== 0 && (
                <NormalButton
                  label={intl.formatMessage({ id: msgId.delete })}
                  color="error"
                  onClick={() => handleDeleteClick()}
                  sx={{ marginRight: theme.spacing(2), width: dimens.button.footerWidth }}
                />
              )}
              <NormalButton
                sx={{ width: dimens.button.footerWidth }}
                label={intl.formatMessage({ id: msgId.registration })}
                color="primary"
                onClick={() => handleRegistrationClick()}
                disabled={
                  state.shipId.length === 0 ||
                  state.startDateError ||
                  state.endDateError ||
                  state.rangeError
                }
              />
            </EditDialogFooterButtonGrid>
          </EditDialogRootGrid>
          {isOverlapError === true && (
            <AlertDialog
              title={intl.formatMessage({ id: msgId.confirm })}
              message={intl.formatMessage({ id: msgId.questionMessagePartialMaintenance })}
              onClose={(result) => handleNoCheckRegistration(result)}
              negativeButton={intl.formatMessage({ id: msgId.cancel })}
              positiveButton={intl.formatMessage({ id: msgId.ok })}
            />
          )}
          {updateResult != null && updateResult.showDialog && editMaintenanceData != null && (
            <AlertDialog
              title={intl.formatMessage({
                id: updateResult.isSuccess ? msgId.confirm : msgId.error,
              })}
              message={intl.formatMessage({ id: updateResult.msgId })}
              onClose={() => handleResultClear(updateResult.isSuccess)}
            />
          )}
          {deleteDialog && editMaintenanceData != null && (
            <AlertDialog
              title={intl.formatMessage({ id: msgId.delete })}
              message={intl.formatMessage({ id: msgId.confirmMessageDelete })}
              onClose={(result) => handleDeleteClickOk(result)}
              negativeButton={intl.formatMessage({ id: msgId.cancel })}
              positiveButton={intl.formatMessage({ id: msgId.ok })}
            />
          )}
        </CustomScrollBars>
      </EditDialogContents>
      <FadeLoading loading={inProgress} />
    </EditDialog>
  );
}
