import React from 'react';
import { SensorGroup, Sensor } from 'models/ships';
import { useIntl } from 'react-intl';
import { styled } from '@mui/material';
import constants from 'resources/constants';
import colors from 'resources/colors';
import { roundValue, roundValueToString } from 'utils/misc';

const prefix = constants.labelPrefix.mimic;
const gageNormalColor = colors.chart.mimic.border;

const RootDiv = styled('div')({
  width: 336,
  height: 118,
});

interface TempInfoComponentProps {
  value: number;
  top: number;
  left: number;
  sensor: Sensor;
  group: SensorGroup;
  width?: number;
  title?: string;
  rateV?: number;
  rateH?: number;
}

export function TempInfoComponent(props: TempInfoComponentProps): JSX.Element {
  const { value, sensor, width } = props;
  const intl = useIntl();
  const sensorTitle = props.title
    ? props.title
    : intl.formatMessage({ id: prefix + props.sensor.sensorName });
  const rateH = props.rateH ? props.rateH : 1;
  const rateV = props.rateV ? props.rateV : 1;
  const range = props.sensor.displayUpperLimit - props.sensor.displayLowerLimit;
  const basePanelHeight = 20 * rateV;
  const basePanelWidth = (width ? width : 150) * rateH;
  const gageFontSize = 14 * rateV;
  const val = isNaN(value) ? props.sensor.displayLowerLimit : value;
  let valueParsent = ((val - props.sensor.displayLowerLimit) / range) * 100;
  valueParsent = valueParsent > 100 ? 100 : valueParsent < 0 ? 0 : valueParsent;

  const gageHightlColor = colors.chart.mimic.gageDanger;
  const radius = basePanelHeight / 2;

  const roundedvalue =
    isNaN(value) || value === null ? undefined : roundValue(value, sensor.displayRoundingPosition);
  const isDanger =
    sensor.warningUpperLimit != null && roundedvalue != null
      ? roundedvalue >= sensor.warningUpperLimit
      : false;
  const gagelColor = isDanger ? gageHightlColor : gageNormalColor;
  const valueText = roundValueToString(roundedvalue, sensor.displayRoundingPosition, true);

  const styleBasePanel: React.CSSProperties = {
    top: props.top * rateV,
    left: props.left * rateH,
    width: basePanelWidth,
    height: basePanelHeight,
    position: 'absolute',
    fontSize: 0,
  };

  const gageWidth = 150 * 0.45;
  const styleTitle: React.CSSProperties = {
    width: `calc(100% - ${gageWidth}px)`,
    height: basePanelHeight,
    fontSize: gageFontSize - 2,
    color: 'white',
    float: 'left',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    lineHeight: `${basePanelHeight}px`,
    userSelect: 'none',
  };

  const gageHeight = basePanelHeight - 2;
  const styleGageBase: React.CSSProperties = {
    width: gageWidth,
    height: gageHeight,
    float: 'left',
    position: 'relative',
    overflow: 'hidden',
  };

  const styleGageFrame: React.CSSProperties = {
    width: gageWidth,
    height: gageHeight,
    top: 0,
    left: 0,
    position: 'absolute',
    borderRadius: radius,
    border: `2px solid ${gagelColor}`,
    zIndex: 4,
    opacity: 0.8,
  };

  const shadow = 5 * rateV;
  const styleGageBackground: React.CSSProperties = {
    width: gageWidth + shadow * 2 - 2,
    height: gageHeight + shadow * 2 - 2,
    color: 'white',
    borderRadius: gageHeight / 2 + shadow,
    border: `${shadow}px solid black`,
    position: 'absolute',
    top: shadow * -1 + 1,
    left: shadow * -1 + 1,
    zIndex: 3,
  };

  const styleGageValue: React.CSSProperties = {
    height: gageHeight - 2,
    fontSize: gageFontSize,
    width: '100%',
    display: 'inline-block',
    color: 'white',
    position: 'absolute',
    top: 0,
    left: 0,
    textAlign: 'center',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    verticalAlign: 'middle',
    lineHeight: `${gageHeight + 2}px`,
    userSelect: 'none',
  };

  const imageWidth = gageWidth - 3;
  const styleGageImage: React.CSSProperties = {
    width: (imageWidth * valueParsent) / 100,
    height: gageHeight - 2,
    background: gagelColor,
    position: 'absolute',
    top: 1,
    left: 1,
  };

  // AppLogger.info(
  //   '[*TempInfo] ' +
  //     sensor.sensorName +
  //     ': value=' +
  //     value +
  //     ', isDanger=' +
  //     isDanger +
  //     ', valueText=' +
  //     valueText +
  //     ', warningUpperLimit=' +
  //     sensor.warningUpperLimit
  // );

  return (
    <RootDiv style={styleBasePanel}>
      <div style={styleTitle}>{sensorTitle}</div>
      <div style={styleGageBase}>
        <div style={styleGageBackground}></div>
        <div style={styleGageFrame}></div>
        <div style={styleGageImage}></div>
        <div style={styleGageValue}>{valueText}</div>
      </div>
    </RootDiv>
  );
}
