import JSZip from 'jszip';

export const API_NAME = 'maricoapiv1';

/**
 * BLOBを読み込み文字列を返す。
 * @param file BLOB形式のファイル
 */
export function readFileAsText(file: Blob): Promise<string> {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.onerror = () => reject(reader.error);
    reader.onload = () => resolve((reader.result as string) || '');
    reader.readAsText(file);
  });
}

/**
 * BLOBを読み込み文字列を返す。
 * @param fileName ファイル名
 * @param data BLOBデータ
 */
export async function zipBlobToStringAsync(fileName: string, data: Blob): Promise<string> {
  const jszip = await JSZip.loadAsync(data);

  return jszip.files[fileName].async('text');
}
