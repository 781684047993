import { API, Storage } from 'aws-amplify';
import { API_NAME } from './maricoApi';
import {
  ReportRequest,
  ReportRequestResult,
  ReportRequestKey,
  ReportItem,
  ReportResult,
} from 'models/reports';
import { sleep } from 'utils/misc';
import { getErrorResult } from 'models/error';
import AppLogger from 'utils/AppLogger';

/**
 * レポート生成結果を取得する。
 * @param dataRequest レポート生成要求
 */
async function reportResultAsync(reportRequest: ReportRequest): Promise<ReportRequestResult> {
  try {
    const params = {
      headers: {
        'Content-Type': 'application/json',
      },
      response: true,
    };
    const results = await API.get(API_NAME, '/v1/reports/' + reportRequest.reportRequestId, params);

    return results.data as ReportRequestResult;
  } catch (error) {
    AppLogger.error('error dataResult: ', error);
    throw getErrorResult(error);
  }
}

/**
 * データ生成結果を取得Keyを返却する。
 * @param request データ生成要求
 * @param reportItem レポートアイテム
 * @param onProgress 進捗イベントハンドラ
 */
async function getReportResultKeyAsync(
  request: ReportRequest,
  reportItem: ReportItem,
  onProgress: (reportItem: ReportItem, progress: number) => void
): Promise<ReportRequestKey | undefined> {
  // 5秒間隔で15分間ポーリング
  let retry = 60 * 15 * 1000;
  AppLogger.debug('reportRequestId:' + request.reportRequestId);
  try {
    let sleepMs = 1000;
    while (retry > 0) {
      await sleep(sleepMs);
      if (reportItem.reportResult === 'Canceled') {
        return undefined;
      }
      const reportRequestResult = await reportResultAsync(request);
      AppLogger.debug('status:' + reportRequestResult.status + ' retry:' + retry);
      AppLogger.info('reportItem:' + reportItem.reportResult);
      if (reportRequestResult.status === 'generating') {
        reportItem.progress = reportRequestResult.progress;
        onProgress(reportItem, reportRequestResult.progress);
        retry -= sleepMs;
        sleepMs = 3000;
      } else if (reportRequestResult.status === 'completed') {
        if (reportRequestResult.result) {
          reportItem.progress = reportRequestResult.progress;
          onProgress(reportItem, reportRequestResult.progress);
        }

        return reportRequestResult.result;
      } else {
        throw getErrorResult(new Error('pdf data is failed.'));
      }
    }
    throw getErrorResult(new Error('pdf data is failed.'));
  } catch (error) {
    throw getErrorResult(error);
  }
}

/**
 * レポートデータの生成を要求する。
 * @param reportItem レポートアイテム
 * @param startDate 開始日時
 * @param endDate 終了日時
 * @param onAccepted 受け付けイベントハンドラ
 * @param onProgress 進捗イベントハンドラ
 * @param onCompeted 完了イベントハンドラ
 */
export async function postReportAsync(
  reportItem: ReportItem,
  startDate: string,
  endDate: string,
  onAccepted: (reportItem: ReportItem, reportRequestId: number) => void,
  onProgress: (reportItem: ReportItem, progress: number) => void,
  onCompeted: (reportItem: ReportItem, reportResult: ReportResult, blob: Blob | undefined) => void
): Promise<boolean> {
  const copyReportItem: ReportItem = {
    ship: reportItem.ship,
    machines: reportItem.machines.map((x) => x),
    progress: 0,
    reportId: reportItem.reportId,
    reportResult: 'Generating',
    reportType: reportItem.reportType,
  };
  try {
    const params = {
      headers: {
        'Content-Type': 'application/json',
      },
      body: {
        reportType: copyReportItem.reportType,
        machineIds: copyReportItem.machines.map((machine) => machine.machineId),
        startDate: startDate,
        endDate: endDate,
      },
      response: true,
    };
    const result = await API.post(API_NAME, '/v1/reports', params);
    const reportRequest: ReportRequest = result.data;
    copyReportItem.reportRequestId = reportRequest.reportRequestId;
    onAccepted(copyReportItem, reportRequest.reportRequestId);

    const reportRequestKey = await getReportResultKeyAsync(
      reportRequest,
      copyReportItem,
      onProgress
    );
    if (reportRequestKey) {
      const data = (await Storage.get(reportRequestKey.key, {
        level: reportRequestKey.level,
        identityId: reportRequestKey.identityId,
        download: true,
      })) as { Body: Blob };

      copyReportItem.reportResult = 'Completed';
      onCompeted(copyReportItem, 'Completed', data.Body);
    } else {
      copyReportItem.reportResult = 'NoData';
      onCompeted(copyReportItem, 'NoData', undefined);
    }

    return true;
  } catch (error) {
    copyReportItem.reportResult = 'Error';
    onCompeted(copyReportItem, 'Error', undefined);
  }

  return false;
}

/**
 * レポートデータの生成をキャンセルする。
 * @param reportItem レポートアイテム
 * @param onCanceled キャンセルイベントハンドラ
 */
export async function cancelReportAsync(
  reportItem: ReportItem,
  onCanceled: (reportItem: ReportItem) => void
): Promise<boolean> {
  try {
    const params = {
      headers: {},
      response: true,
    };

    await API.put(API_NAME, '/v1/reports/' + reportItem.reportRequestId + '/cancel', params);
  } catch (error) {
    AppLogger.info('cancel not accept. requestId=' + reportItem.reportRequestId);
  }

  onCanceled(reportItem);

  return true;
}
