import React, { useEffect, useRef, useState } from 'react';
import dimens from 'resources/dimens';
import { useDispatch } from 'react-redux';
import { ShipDrawer } from 'components/shipDrawer/ShipDrawer';
import { FadeLoading } from 'components/FadeLoading';
import {
  setShips,
  setContractedShipId,
  setFilterShipName,
  fetchRealtimeAisDataList,
  setFetchError,
  useRealtimeAisDataFilteredShips,
  useRealtimeAisDataContractedShipIds,
  useRealtimeAisDataFetching,
  useRealtimeAisDataIsFirsttime,
  useRealtimeAisDataList,
  useRealtimeAisDataFetchError,
  fetchMarineWeatherBundle,
} from './realtimeAisDataSlice';
import { RootContainer } from 'components/RootContainer';
import { RealtimeMapContainer } from './map/MapContainer';
import { ApiErrorDialog } from 'components/ApiErrorDialog';
import { useCommonShips } from 'app/commonSlice';
import { useAppBarConfirmedReorderShips } from 'features/appBar/mainAppBarSlice';
import AppLogger from 'utils/AppLogger';
import { setCenter } from './map/mapSlice';
import dayjs from 'dayjs';
import config from 'resources/config';
import { styled } from '@mui/material';

const MapDiv = styled('div')({
  width: '100%',
  height: '100%',
});

interface RealtimeAisDataFragmentProps {
  hidden: boolean;
}

export function RealtimeAisDataFragment(props: RealtimeAisDataFragmentProps): JSX.Element {
  AppLogger.debug('CALL: RealtimeAisDataFragment');
  const { hidden } = props;
  const dispatch = useDispatch();
  const [shipDrawerOpen, setShipDrawerOpen] = useState(true);
  const [contentWidth, setContentWidth] = useState<number>(0);
  const reorderShips = useAppBarConfirmedReorderShips();
  const ships = useCommonShips();
  const filteredShips = useRealtimeAisDataFilteredShips();
  const contractedShipIds = useRealtimeAisDataContractedShipIds();
  const aisDataFetching = useRealtimeAisDataFetching();
  const isFirstTime = useRealtimeAisDataIsFirsttime();
  const aisDataList = useRealtimeAisDataList();
  const fetchError = useRealtimeAisDataFetchError();
  const containerRef = useRef<HTMLDivElement>(null);
  const timerId = useRef<NodeJS.Timer | null>(null);

  /**
   * 検索入力変更
   * @param event イベント
   */
  const handleSeachInputChange = (input: string) => {
    dispatch(setFilterShipName(input));
  };

  /**
   * 船舶ドロワー開閉状態変更
   * @param open 開閉状態
   */
  const handleShipDrawerChangeOpen = (open: boolean) => {
    setShipDrawerOpen(open);
  };

  /**
   *  船舶ドロワーの船舶開閉状態変更
   * @param shipId 船舶ID
   * @param expanded 開閉状態
   */
  const handleShipDrawerExpandedChange = (shipId: string, expanded: boolean) => {
    dispatch(setContractedShipId({ shipId, expanded }));
  };

  /**
   *  船舶ドロワーの船舶位置移動
   * @param shipId 船舶ID
   * @param expanded 開閉状態
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleShipLocationLocation = (shipId: string) => {
    if (aisDataList != null) {
      const aisData = aisDataList.find((x) => x.shipId === shipId);
      if (aisData) {
        dispatch(
          setCenter({
            lat: aisData.latitude,
            lng: aisData.longitude,
          })
        );
      } else {
        dispatch(
          setCenter({
            lat: 0,
            lng: 0,
          })
        );
      }
    }
  };

  /**
   * データ取得エラー
   */
  const handleFetchError = () => {
    dispatch(setFetchError(undefined));
  };

  useEffect(() => {
    if (reorderShips != null && reorderShips.length > 0 && !hidden) {
      dispatch(setShips(reorderShips));
      dispatch(fetchRealtimeAisDataList(reorderShips));
      dispatch(fetchMarineWeatherBundle());
    }
  }, [dispatch, reorderShips, hidden]);

  useEffect(() => {
    if (aisDataList != null && aisDataList.length > 0 && isFirstTime) {
      dispatch(
        setCenter({
          lat: 0,
          lng: 0,
        })
      );
    }
  }, [dispatch, isFirstTime, aisDataList]);

  useEffect(() => {
    if (aisDataList != null && aisDataList.length > 0 && !hidden) {
      // 自動更新タイマー
      if (timerId.current != null) {
        clearInterval(timerId.current);
      }
      const interval = (config.aisDataRefreshIntervalMinutes - dayjs().minute()) * 60 * 1000;
      AppLogger.debug('refresh timer start. interval=' + interval);
      timerId.current = setInterval(() => {
        dispatch(fetchRealtimeAisDataList(reorderShips));
        dispatch(fetchMarineWeatherBundle());
      }, interval);
    } else {
      if (timerId.current != null) {
        AppLogger.debug('refresh timer stop');
        clearInterval(timerId.current);
        timerId.current = null;
      }
    }
  }, [dispatch, reorderShips, aisDataList, hidden]);

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      const width = entries[0].contentRect.width;
      setContentWidth(width);
    });
    containerRef.current && resizeObserver.observe(containerRef.current);

    return (): void => {
      resizeObserver.disconnect();
    };
  }, []);

  return (
    <RootContainer hidden={hidden}>
      <ShipDrawer
        ships={filteredShips}
        contractedShipIds={contractedShipIds}
        aisDataList={aisDataList}
        open={shipDrawerOpen}
        onChangeOpen={handleShipDrawerChangeOpen}
        onExpandedChange={handleShipDrawerExpandedChange}
        onLocation={handleShipLocationLocation}
        onSearchInputChange={handleSeachInputChange}
        scrollvbarMargin={dimens.shipState.realtimeAisScrollbar.margin}
      />
      {ships != null && ships.length > 0 && (
        <MapDiv ref={containerRef}>
          <RealtimeMapContainer width={contentWidth} />
        </MapDiv>
      )}
      <FadeLoading loading={aisDataFetching} />
      {fetchError != null && <ApiErrorDialog error={fetchError} onClose={handleFetchError} />}
    </RootContainer>
  );
}
