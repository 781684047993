import React, { useEffect, useRef, useState } from 'react';
import { RootContainer } from 'components/RootContainer';
import { MenuDrawer } from 'components/menuDrawer/MenuDrawer';
import { useIntl } from 'react-intl';
import msgId from 'resources/intl';
import {
  fetchAllList,
  setFetchError,
  useGeneralSettingsAccounts,
  useGeneralSettingsFetching,
  useGeneralSettingsFetchError,
} from './generalSettingsSlice';
import { useDispatch } from 'react-redux';
import dimens from 'resources/dimens';
import { styled } from '@mui/material';
import { AccountManagement } from './accountAuthority/AccountAuthorityManagement';
import { useAccount } from 'features/login/accountSlice';
import { ApiErrorDialog } from 'components/ApiErrorDialog';
import { FadeLoading } from 'components/FadeLoading';
import { AuthorityAdministrators, AuthorityAdminUsers, AuthorityDevelopers } from 'models/accounts';

const ContntsContainer = styled('div')({
  position: 'absolute',
  overflow: 'hidden',
  top: 0,
  height: '100%',
});

interface GeneralSettingsFragmentProps {
  hidden: boolean;
}

export function GeneralSettingsFragment(props: GeneralSettingsFragmentProps): JSX.Element {
  const { hidden } = props;
  const intl = useIntl();
  const dispatch = useDispatch();
  const account = useAccount();
  const accounts = useGeneralSettingsAccounts();
  const fetchError = useGeneralSettingsFetchError();
  const fetching = useGeneralSettingsFetching();
  const [menuDrawerOpen, setMenuDrawerOpen] = useState(true);
  const [menuIndex, setMenuIndex] = useState(0);
  const menuTitles: string[] = [intl.formatMessage({ id: msgId.accountAuthorityManagement })];
  const containerRef = useRef<HTMLDivElement>(null);
  const [containerHeight, setContainerHeight] = useState<number>(0);

  /**
   * メニュー選択変更
   * @param index 開閉状態
   */
  const handleSelectMenu = (index: number) => {
    setMenuIndex(index);
  };

  /**
   * メニュードロワー開閉状態変更
   * @param open 開閉状態
   */
  const handleMenuDrawerChangeOpen = (open: boolean) => {
    setMenuDrawerOpen(open);
  };

  /**
   * データ取得エラー
   */
  const handleFetchError = () => {
    dispatch(setFetchError(undefined));
  };

  useEffect(() => {
    if (
      account &&
      (account.authorityId === AuthorityAdministrators ||
        account.authorityId === AuthorityDevelopers ||
        account.authorityId === AuthorityAdminUsers)
    ) {
      if (accounts == null) {
        dispatch(fetchAllList());
      }
    }
  }, [dispatch, account, accounts]);

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      const height = entries[0].contentRect.height;
      setContainerHeight(height);
    });
    containerRef.current && resizeObserver.observe(containerRef.current);

    return (): void => {
      resizeObserver.disconnect();
    };
  }, [containerRef]);

  return (
    <RootContainer hidden={hidden} ref={containerRef}>
      <MenuDrawer
        menus={menuTitles}
        open={menuDrawerOpen}
        selectedMenu={menuIndex}
        onSelectMenu={handleSelectMenu}
        onChangeOpen={handleMenuDrawerChangeOpen}
      />
      <ContntsContainer
        sx={{
          width: menuDrawerOpen
            ? `calc(100% - ${dimens.subDrawer.width}px)`
            : `calc(100% - ${dimens.subDrawer.minWidth}px)`,
          left: menuDrawerOpen ? dimens.subDrawer.width : dimens.subDrawer.minWidth,
        }}
      >
        <AccountManagement open={!hidden && menuIndex === 0} height={containerHeight} />
      </ContntsContainer>
      <FadeLoading loading={fetching} />
      {fetchError != null && <ApiErrorDialog error={fetchError} onClose={handleFetchError} />}
    </RootContainer>
  );
}
