import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'app/rootReducer';
import { useSelector } from 'react-redux';

/**
 * RealtimeMapState インターフェース
 */
export interface RealtimeMapState {
  /** センター座標 */
  center: google.maps.LatLngLiteral | undefined;
}

/**
 * Map の初期状態
 */
const initialState: RealtimeMapState = {
  center: undefined,
};

export const map = createSlice({
  name: 'shipStateRealtimeAisDataMap',
  initialState,
  reducers: {
    /**
     * センターを設定する。
     */
    setCenter: (state, action: PayloadAction<google.maps.LatLngLiteral | undefined>) => {
      state.center = action.payload;
    },
    /**
     * shipStateRealtimeAisDataMapをクリアする。
     */
    clearShipStateRealtimeAisDataMap: (state) => {
      state.center = initialState.center;
    },
  },
});

export const { setCenter, clearShipStateRealtimeAisDataMap } = map.actions;

const realtimeAisDataMapState = (state: RootState) => state.realtimeAisDataMap;
const selectCenter = createSelector(realtimeAisDataMapState, (x) => x.center);

export const useRealtimeAisDataMapCenter = () => useSelector(selectCenter);

export default map.reducer;
