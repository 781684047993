import React from 'react';
import { useIntl, IntlShape } from 'react-intl';
import msgId from 'resources/intl';
import Plotly, { Layout, LayoutAxis, Shape, PlotMouseEvent, Annotations } from 'plotly.js';
import createPlotlyComponent from 'react-plotly.js/factory';
import colors from 'resources/colors';
import { AnalysisData } from 'models/analysis';
import { Box, styled, SxProps } from '@mui/material';
import { roundValueToString } from 'utils/misc';

const Plot = createPlotlyComponent(Plotly);
const axisFontColor = colors.chart.analysis.megLoadBand.axisFontColor;
const gridColor = colors.chart.analysis.megLoadBand.gridColor;
const y1TickColor = colors.chart.analysis.megLoadBand.y1TickColor;
const y1TickFontColor = colors.chart.analysis.megLoadBand.y1TickFontColor;

const HoverDiv = styled('div')({
  position: 'absolute',
  color: 'white',
  background: 'black',
  zIndex: 2,
  fontFamily: 'Arial, sans-serif',
  pointerEvents: 'none',
  fontSize: 13,
});

const plotLStyle: React.CSSProperties = {
  width: '100%',
  height: 500,
  background: colors.chart.legend.background,
};

const plotRStyle: React.CSSProperties = {
  width: '100%',
  height: 500,
  background: colors.chart.legend.background,
};

function hoverInfoText(
  intl: IntlShape,
  date: string,
  level1: string,
  level2: string,
  level3: string,
  level4: string,
  level5: string,
  level6: string,
  unit: string
): string {
  return (
    intl.formatMessage({ id: msgId.AnalysisHoverInfoLabelDate }) +
    ':' +
    date +
    '<br>' +
    intl.formatMessage({ id: msgId.AnalysisMegHpLevel6 }) +
    ':' +
    level6 +
    unit +
    '<br>' +
    intl.formatMessage({ id: msgId.AnalysisMegHpLevel5 }) +
    ':' +
    level5 +
    unit +
    '<br>' +
    intl.formatMessage({ id: msgId.AnalysisMegHpLevel4 }) +
    ':' +
    level4 +
    unit +
    '<br>' +
    intl.formatMessage({ id: msgId.AnalysisMegHpLevel3 }) +
    ':' +
    level3 +
    unit +
    '<br>' +
    intl.formatMessage({ id: msgId.AnalysisMegHpLevel2 }) +
    ':' +
    level2 +
    unit +
    '<br>' +
    intl.formatMessage({ id: msgId.AnalysisMegHpLevel1 }) +
    ':' +
    level1 +
    unit
  );
}
function sum(
  level1: number,
  level2: number,
  level3: number,
  level4: number,
  level5: number,
  level6: number
): number {
  return level1 + level2 + level3 + level4 + level5 + level6;
}

interface MegLoadBandChartProps {
  sx?: SxProps;
  analysisData: AnalysisData;
}

export function MegLoadBandChart(props: MegLoadBandChartProps): JSX.Element {
  const intl = useIntl();
  const { sx, analysisData } = props;
  const contents = analysisData.data.megLoadBand;
  if (contents == null || contents.date.length === 0) {
    return <Box sx={sx} />;
  }
  const unit = contents.displayInformations.DrivingTime.unit;
  const yDataL: number[][] = [[], [], [], [], [], []];
  const yDataR: number[][] = [[], [], [], [], [], []];
  const yTextL: string[] = [];
  const yTextR: string[] = [];
  const yAnnotationL: string[] = [];
  const yAnnotationR: string[] = [];
  const yAnnotationListL: Partial<Annotations>[] = [];
  const yAnnotationListR: Partial<Annotations>[] = [];
  const xData: number[] = [];
  const plotDataListL: Plotly.Data[] = [];
  const plotDataListR: Plotly.Data[] = [];

  for (let i = 1; i <= 31; i++) {
    xData.push(i);
    yTextL.push('');
    yTextR.push('');
    yAnnotationL.push('');
    yAnnotationR.push('');
    for (let j = 0; j < 6; j++) {
      yDataL[0].push(0);
      yDataR[0].push(0);
    }
  }
  contents.date.forEach((date, index) => {
    const d = new Date(date);
    const dateStrings = d.toISOString().replace('T', ' ').split('-').join('/').split(':');
    dateStrings.pop();
    const dateString = dateStrings.join(':');
    const dayIndex = d.getUTCDate() - 1;
    if (analysisData.dataFormatId !== 402) {
      yDataL[0][dayIndex] = contents.MegHpLevel1P1[index];
      yDataR[0][dayIndex] = contents.MegHpLevel1S1[index];
      yDataL[1][dayIndex] = contents.MegHpLevel2P1[index];
      yDataR[1][dayIndex] = contents.MegHpLevel2S1[index];
      yDataL[2][dayIndex] = contents.MegHpLevel3P1[index];
      yDataR[2][dayIndex] = contents.MegHpLevel3S1[index];
      yDataL[3][dayIndex] = contents.MegHpLevel4P1[index];
      yDataR[3][dayIndex] = contents.MegHpLevel4S1[index];
      yDataL[4][dayIndex] = contents.MegHpLevel5P1[index];
      yDataR[4][dayIndex] = contents.MegHpLevel5S1[index];
      yDataL[5][dayIndex] = contents.MegHpLevel6P1[index];
      yDataR[5][dayIndex] = contents.MegHpLevel6S1[index];
      const round = contents.displayInformations.DrivingTime.roundingPosition;
      let text = hoverInfoText(
        intl,
        dateString + '',
        roundValueToString(contents.MegHpLevel1P1[index], round),
        roundValueToString(contents.MegHpLevel2P1[index], round),
        roundValueToString(contents.MegHpLevel3P1[index], round),
        roundValueToString(contents.MegHpLevel4P1[index], round),
        roundValueToString(contents.MegHpLevel5P1[index], round),
        roundValueToString(contents.MegHpLevel6P1[index], round),
        unit + ''
      );
      yTextL[dayIndex] = text;
      text = hoverInfoText(
        intl,
        dateString + '',
        roundValueToString(contents.MegHpLevel1S1[index], round),
        roundValueToString(contents.MegHpLevel2S1[index], round),
        roundValueToString(contents.MegHpLevel3S1[index], round),
        roundValueToString(contents.MegHpLevel4S1[index], round),
        roundValueToString(contents.MegHpLevel5S1[index], round),
        roundValueToString(contents.MegHpLevel6S1[index], round),
        unit + ''
      );
      yTextR[dayIndex] = text;
      if (contents.MegHpLevel1P1.length === contents.DrivingTimeP1.length) {
        contents.DrivingTimeP1[index] &&
          (yAnnotationL[dayIndex] = roundValueToString(contents.DrivingTimeP1[index], round));
        contents.DrivingTimeS1[index] &&
          (yAnnotationR[dayIndex] = roundValueToString(contents.DrivingTimeS1[index], round));
      } else {
        contents.MegHpLevel1P1[index] &&
          (yAnnotationL[dayIndex] = roundValueToString(
            sum(
              contents.MegHpLevel1P1[index],
              contents.MegHpLevel2P1[index],
              contents.MegHpLevel3P1[index],
              contents.MegHpLevel4P1[index],
              contents.MegHpLevel5P1[index],
              contents.MegHpLevel6P1[index]
            ),
            round
          ));
        contents.MegHpLevel1S1[index] &&
          (yAnnotationR[dayIndex] = roundValueToString(
            sum(
              contents.MegHpLevel1S1[index],
              contents.MegHpLevel2S1[index],
              contents.MegHpLevel3S1[index],
              contents.MegHpLevel4S1[index],
              contents.MegHpLevel5S1[index],
              contents.MegHpLevel6S1[index]
            ),
            round
          ));
      }
    } else {
      yDataL[0][dayIndex] = contents.EngSftHpLevel1P[index];
      yDataR[0][dayIndex] = contents.EngSftHpLevel1S[index];
      yDataL[1][dayIndex] = contents.EngSftHpLevel2P[index];
      yDataR[1][dayIndex] = contents.EngSftHpLevel2S[index];
      yDataL[2][dayIndex] = contents.EngSftHpLevel3P[index];
      yDataR[2][dayIndex] = contents.EngSftHpLevel3S[index];
      yDataL[3][dayIndex] = contents.EngSftHpLevel4P[index];
      yDataR[3][dayIndex] = contents.EngSftHpLevel4S[index];
      yDataL[4][dayIndex] = contents.EngSftHpLevel5P[index];
      yDataR[4][dayIndex] = contents.EngSftHpLevel5S[index];
      yDataL[5][dayIndex] = contents.EngSftHpLevel6P[index];
      yDataR[5][dayIndex] = contents.EngSftHpLevel6S[index];
      const round = contents.displayInformations.DrivingTime.roundingPosition;
      let text = hoverInfoText(
        intl,
        dateString + '',
        roundValueToString(contents.EngSftHpLevel1P[index], round),
        roundValueToString(contents.EngSftHpLevel2P[index], round),
        roundValueToString(contents.EngSftHpLevel3P[index], round),
        roundValueToString(contents.EngSftHpLevel4P[index], round),
        roundValueToString(contents.EngSftHpLevel5P[index], round),
        roundValueToString(contents.EngSftHpLevel6P[index], round),
        unit + ''
      );
      yTextL[dayIndex] = text;
      text = hoverInfoText(
        intl,
        dateString + '',
        roundValueToString(contents.EngSftHpLevel1S[index], round),
        roundValueToString(contents.EngSftHpLevel2S[index], round),
        roundValueToString(contents.EngSftHpLevel3S[index], round),
        roundValueToString(contents.EngSftHpLevel4S[index], round),
        roundValueToString(contents.EngSftHpLevel5S[index], round),
        roundValueToString(contents.EngSftHpLevel6S[index], round),
        unit + ''
      );
      yTextR[dayIndex] = text;
      if (contents.EngSftHpLevel1P.length === contents.DrivingTimeP.length) {
        contents.DrivingTimeP[index] &&
          (yAnnotationL[dayIndex] = roundValueToString(contents.DrivingTimeP[index], round));
        contents.DrivingTimeS[index] &&
          (yAnnotationR[dayIndex] = roundValueToString(contents.DrivingTimeS[index], round));
      } else {
        contents.EngSftHpLevel1P[index] &&
          (yAnnotationL[dayIndex] = roundValueToString(
            sum(
              contents.EngSftHpLevel1P[index],
              contents.EngSftHpLevel2P[index],
              contents.EngSftHpLevel3P[index],
              contents.EngSftHpLevel4P[index],
              contents.EngSftHpLevel5P[index],
              contents.EngSftHpLevel6P[index]
            ),
            round
          ));
        contents.EngSftHpLevel1S[index] &&
          (yAnnotationR[dayIndex] = roundValueToString(
            sum(
              contents.EngSftHpLevel1S[index],
              contents.EngSftHpLevel2S[index],
              contents.EngSftHpLevel3S[index],
              contents.EngSftHpLevel4S[index],
              contents.EngSftHpLevel5S[index],
              contents.EngSftHpLevel6S[index]
            ),
            round
          ));
      }
    }
  });

  for (let i = 0; i < 6; i++) {
    const OutDataL = {
      x: yDataL[i],
      y: xData,
      orientation: 'h',
      marker: {
        color: colors.chart.megHpLevels[5 - i],
      },
      type: 'bar',
      text: yTextL,
      textposition: 'none',
      hoverinfo: 'none',
    } as Plotly.Data;
    const OutDataR = {
      x: yDataR[i],
      y: xData,
      orientation: 'h',
      marker: {
        color: colors.chart.megHpLevels[5 - i],
      },
      type: 'bar',
      text: yTextR,
      textposition: 'none',
      hoverinfo: 'none',
    } as Plotly.Data;
    plotDataListL.push(OutDataL);
    plotDataListR.push(OutDataR);
  }
  yAnnotationL.forEach((value, index) => {
    if (value !== '') {
      const annotation: Partial<Annotations> = {
        xref: 'x',
        yref: 'y',
        x: value,
        xanchor: 'left',
        y: index + 1,
        yanchor: 'middle',
        text: '' + value,
        xshift: 10,
        showarrow: false,
        font: {
          size: 13,
          color: y1TickFontColor,
        },
      };
      yAnnotationListL.push(annotation);
    }
  });
  yAnnotationR.forEach((value, index) => {
    if (value !== '') {
      const annotation: Partial<Annotations> = {
        xref: 'x',
        yref: 'y',
        x: value,
        xanchor: 'left',
        y: index + 1,
        yanchor: 'middle',
        text: '' + value,
        xshift: 10,
        showarrow: false,
        font: {
          size: 13,
          color: y1TickFontColor,
        },
      };
      yAnnotationListR.push(annotation);
    }
  });
  const shapeLine: Partial<Shape> = {
    type: 'line',
    xref: 'paper',
    yref: 'paper',
    x0: 0,
    y0: 1,
    x1: 1,
    y1: 1,
    line: {
      color: y1TickColor,
      width: 1,
    },
  };
  const shapeBottomLine: Partial<Shape> = {
    type: 'line',
    xref: 'paper',
    yref: 'paper',
    x0: 0,
    y0: 0,
    x1: 1,
    y1: 0,
    line: {
      color: gridColor,
      width: 1,
    },
  };

  const xaxis = {
    title: {
      text:
        intl.formatMessage({ id: msgId.AnalysisMenuLoadBandAnalysisYTitle }) +
        '[' +
        contents.displayInformations.DrivingTime.unit +
        ']',
      font: { color: axisFontColor },
    },
    showgrid: true,
    gridcolor: gridColor,
    tickfont: { color: y1TickFontColor, size: 13 },
    side: 'top',
    tickmode: 'auto',
    tickangle: 0,
    nticks: 5,
  } as Partial<LayoutAxis>;

  const yaxis = {
    title: {
      text: intl.formatMessage({ id: msgId.AnalysisMenuLoadBandAnalysisXTitle }),
      font: { color: axisFontColor },
    },
    tickvals: xData,
    tickfont: { color: axisFontColor, size: 13 },
    range: [0.5, 31.5],
    showgrid: false,
    gridcolor: gridColor,
    autorange: 'reversed',
  };

  const layout = {
    title: {
      text: intl.formatMessage({ id: msgId.trendLabelPort }),
      color: axisFontColor,
      x: 0.05,
      y: 0.98,
      font: { color: axisFontColor },
    },
    height: 500,
    autosize: true,
    xaxis: xaxis,
    yaxis: yaxis,
    paper_bgcolor: colors.chart.legend.background,
    plot_bgcolor: colors.chart.legend.background,
    margin: {
      t: 45,
      b: 5,
      l: 60,
      r: 60,
      pad: 2,
    },
    showlegend: false,
    shapes: [shapeLine, shapeBottomLine],
    annotations: yAnnotationListL,
    hovermode: 'closest',
    hoverlabel: {
      bgcolor: 'black',
      bordercolor: 'black',
      font: {
        color: 'white',
      },
      align: 'left',
    },
    barmode: 'stack',
  } as Partial<Layout>;

  const layoutR = Object.assign({}, layout);
  Object.assign(layoutR, {
    title: {
      text: intl.formatMessage({ id: msgId.trendLabelStbd }),
      color: axisFontColor,
      x: 0.05,
      y: 0.98,
      font: { color: axisFontColor },
    },
    annotations: yAnnotationListR,
  });

  const plotConfig = { displayModeBar: false, showTips: false };

  const panel = 'MegLoadBand';
  const PlotL = 'PlotL' + panel;
  const PlotR = 'PlotR' + panel;
  let relayouting = false;
  function onRelayout(event: Readonly<Plotly.PlotRelayoutEvent>, side: 'left' | 'right') {
    if (!relayouting) {
      let divId: string;
      relayouting = true;
      setTimeout(() => {
        relayouting = false;
      }, 500);
      if (side === 'left') {
        divId = PlotR;
      } else {
        divId = PlotL;
      }
      Plotly.relayout(document.getElementById(divId) as HTMLElement, event);
    }
  }

  const hoverIdL = PlotL + 'hover';
  const hoverIdR = PlotR + 'hover';
  const dataL = plotDataListL;
  const dataR = plotDataListR;

  function onHover(data: Readonly<PlotMouseEvent>, side: 'left' | 'right') {
    const point = data.points[0];
    let showHoverInfo = false;
    for (const [key] of Object.entries(point)) {
      if (key === 'text') {
        showHoverInfo = true;
        break;
      }
    }
    if (showHoverInfo === false) return;
    let hoverTexts;
    let eId: string;
    let offset = 0;
    if (side === 'left') {
      hoverTexts = dataR[point.curveNumber].text as string[];
      eId = hoverIdR;
      offset = 500;
    } else {
      hoverTexts = dataL[point.curveNumber].text as string[];
      eId = hoverIdL;
    }
    if (!hoverTexts) return;
    const hoverText = hoverTexts[point.pointIndex];
    const div = document.getElementById(eId);
    if (div) {
      div.innerHTML = hoverText;
      div.style.left = data.event.offsetX + 'px';
      div.style.top = data.event.offsetY + offset + 'px';
      div.style.padding = '3px';
    }

    if (side === 'left') {
      hoverTexts = dataL[point.curveNumber].text as string[];
      const hoverText = hoverTexts[point.pointIndex];
      const div = document.getElementById(hoverIdL);
      if (div) {
        div.innerHTML = hoverText;
        div.style.left = data.event.offsetX + 'px';
        div.style.top = data.event.offsetY + 'px';
        div.style.padding = '3px';
      }
    } else {
      hoverTexts = dataR[point.curveNumber].text as string[];
      const hoverText = hoverTexts[point.pointIndex];
      const div = document.getElementById(hoverIdR);
      if (div) {
        div.innerHTML = hoverText;
        div.style.left = data.event.offsetX + 'px';
        div.style.top = data.event.offsetY + 500 + 'px';
        div.style.padding = '3px';
      }
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  function onUnhover(event: Readonly<Plotly.PlotMouseEvent>) {
    let e = document.getElementById(hoverIdL);
    if (e) {
      e.innerHTML = '';
      e.style.padding = '0px';
    }
    e = document.getElementById(hoverIdR);
    if (e) {
      e.innerHTML = '';
      e.style.padding = '0px';
    }
  }

  return (
    <Box sx={sx}>
      <HoverDiv id={hoverIdL} />
      <Plot
        divId={PlotL}
        onRelayout={(event) => onRelayout(event, 'left')}
        style={plotLStyle}
        data={plotDataListL}
        useResizeHandler
        layout={layout}
        onHover={(event) => onHover(event, 'left')}
        onUnhover={onUnhover}
        config={plotConfig}
      />
      <HoverDiv id={hoverIdR} />
      <Plot
        divId={PlotR}
        onRelayout={(event) => onRelayout(event, 'right')}
        style={plotRStyle}
        data={plotDataListR}
        useResizeHandler
        layout={layoutR}
        onHover={(event) => onHover(event, 'right')}
        onUnhover={onUnhover}
        config={plotConfig}
      />
    </Box>
  );
}
