import { Box, Grid, Typography } from '@mui/material';
import React from 'react';
import colors from 'resources/colors';
import dimens from 'resources/dimens';
import { theme } from 'resources/theme';

interface MarineWeatherLegendProps {
  legendColors: string[];
  legendLabels: string[];
  unit: string;
}

export function MarineWeatherLegend(props: MarineWeatherLegendProps): JSX.Element {
  const { legendColors, legendLabels, unit } = props;

  return (
    <Box
      sx={{
        background: theme.palette.primary.main,
        color: colors.white,
        width: 50,
        position: 'absolute',
        overflow: 'hidden',
        bottom: dimens.marineWeather.legend.bottom,
        right: dimens.marineWeather.legend.right,
        padding: theme.spacing(1),
        pointerEvents: 'none',
      }}
    >
      <Grid container spacing={0} direction="column">
        <Grid
          container
          item
          spacing={0}
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item>
            <Typography variant="body2" sx={{ marginBottom: '8px' }}>
              {unit}
            </Typography>
          </Grid>
        </Grid>
        {legendColors.map((c, i) => {
          return (
            <Grid
              key={'windSpeedLegend' + i}
              container
              item
              spacing={0}
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Grid item>
                <div style={{ width: 18, height: 18, background: c }} />
              </Grid>
              <Grid key={'windSpeedLegendT' + i} item>
                <Typography
                  variant="body2"
                  sx={{
                    marginLeft: '4px',
                    textAlign: 'end',
                    width: '12px',
                    marginTop: '-1px',
                  }}
                >
                  {legendLabels[i]}
                </Typography>
              </Grid>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
}
