import React, { ReactNode } from 'react';
import { styled, Box, Slide } from '@mui/material';

export const StyledBox = styled(Box)({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  display: 'flex',
  overflow: 'hidden',
});

interface SlideFragmentContainerProps {
  open: boolean;
  children: ReactNode;
}

export function SlideFragmentContainer(props: SlideFragmentContainerProps): JSX.Element {
  const { open, children } = props;

  return (
    <Slide direction="up" in={open} mountOnEnter unmountOnExit>
      <StyledBox>{children}</StyledBox>
    </Slide>
  );
}
