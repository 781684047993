import React from 'react';
import { theme } from 'resources/theme';
import dimens from 'resources/dimens';
import colors from 'resources/colors';
import { styled } from '@mui/material';
import Button, { ButtonProps } from '@mui/material/Button';
import { ArrowBack } from '@mui/icons-material';

export const StyledButton = styled((props: ButtonProps) => (
  <Button variant="contained" color="primary" disableElevation {...props} />
))({
  borderRadius: 0,
  padding: theme.spacing(1),
  minWidth: dimens.toolbar.height,
  minHeight: dimens.toolbar.height,
});

const ArrowBackIcon = styled(ArrowBack)({
  color: colors.drawerItem.selected,
});

interface ToolbarBackButtonProps {
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

export function ToolbarBackButton(props: ToolbarBackButtonProps): JSX.Element {
  const { onClick } = props;

  return (
    <StyledButton onClick={onClick}>
      <ArrowBackIcon />
    </StyledButton>
  );
}
