import React from 'react';
import { useIntl } from 'react-intl';
import msgId from 'resources/intl';
import { ErrorResult } from 'models/error';
import { AlertDialog, DialogResult } from './AlertDialog';

interface ApiErrorDialogProps {
  error: ErrorResult;
  positiveButton?: string;
  negativeButton?: string;
  onClose: (result: DialogResult) => void;
}

/**
 * APIエラーダイアログ
 */
export function ApiErrorDialog(props: ApiErrorDialogProps): JSX.Element {
  const { error, positiveButton, negativeButton, onClose } = props;
  const intl = useIntl();
  let title = intl.formatMessage({ id: msgId.error });
  let message = intl.formatMessage({ id: msgId.errorMessageOther });
  if ('response' in error) {
    if (error.status === 401) {
      title = intl.formatMessage({ id: msgId.error });
      message = intl.formatMessage({ id: msgId.errorMessageAuth });
    } else if (error.status === 403) {
      title = intl.formatMessage({ id: msgId.error });
      message = intl.formatMessage({ id: msgId.errorMessageOther });
    } else if (error.status === 504) {
      title = intl.formatMessage({ id: msgId.error });
      message = intl.formatMessage({ id: msgId.errorMessageOther });
    } else if (Math.floor(error.status / 100) === 5) {
      title = intl.formatMessage({ id: msgId.error });
      message = intl.formatMessage({ id: msgId.errorMessageServer });
    }
  }

  return (
    <AlertDialog
      title={title}
      message={message}
      onClose={onClose}
      positiveButton={positiveButton}
      negativeButton={negativeButton}
    />
  );
}
