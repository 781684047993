import React, { useEffect, useRef, useState, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import AppLogger from 'utils/AppLogger';
import {
  setContractedShipId,
  fetchAnalysisData,
  setFetchError,
  setFilterShipName,
  setShips,
  setTabIndex,
  useAnalysisFilteredShips,
  useAnalysisContractedShipIds,
  useAnalysisFetching,
  useAnalysisData,
  useAnalysisFetchError,
  useAnalysisTabIndex,
  useIsExpandUpdated,
  setExpandUpdated,
} from './analysisSlice';
import { ShipDrawer } from 'components/shipDrawer/ShipDrawer';

import {
  setOpen as setSeachOpen,
  useAnalysisConfirmedSearchConditions,
  useAnalysisSearchConfirmedSearchResultItem,
  useAnalysisSearchOpen,
} from './search/searchSlice';
import { SearchConditionsBar } from 'components/SearchConditionsBar';
import { CustomScrollBars } from 'components/CustomScrollBars';
import colors from 'resources/colors';
import dimens from 'resources/dimens';
import { SearchFragment } from './search/SearchFragment';
import { ApiErrorDialog } from 'components/ApiErrorDialog';
import { FadeLoading } from 'components/FadeLoading';
import { VesShipSpeedContainer } from './chart/vesShipSpeed/VesShipSpeedContainer';
import { MegLoadContainer } from './chart/megLoad/MegLoadContainer';
import { MegTempContainer } from './chart/megTemp/MegTempContainer';
import { MegLoadBandContainer } from './chart/megLoadBand/MegLoadBandContainer';
import { RexPropulsionOperationContainer } from './chart/rexPropulsionOperation/RexPropulsionOperationContainer';
import { RexGearAnomalyDetectionContainer } from './chart/RexGearAnomalyDetection/RexGearAnomalyDetectionContainer';
import { RexBearingAnomalyDetectionContainer } from './chart/RexBearingAnomalyDetection/RexBearingAnomalyDetectionContainer';
import { RexPropellerInvolvementContainer } from './chart/RexPropellerInvolvement/RexPropellerInvolvementContainer';
import dayjs from 'dayjs';
import { setBusyProgress, useCommonShips } from 'app/commonSlice';
import { Box, styled } from '@mui/material';
import { RootContainer } from 'components/RootContainer';
import { SlideFragmentContainer } from 'components/SlideFragmentContainer';
import constants from 'resources/constants';

export const ContentsDiv = styled('div')({
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'flex-start',
  background: colors.subDrawer.background,
});

interface AnalysisFragmentProps {
  hidden: boolean;
}

export function AnalysisFragment(props: AnalysisFragmentProps): JSX.Element {
  AppLogger.debug('CALL: AnalysisFragment');
  const { hidden } = props;
  const dispatch = useDispatch();
  const [shipDrawerOpen, setShipDrawerOpen] = useState(true);
  const ships = useCommonShips();
  const filteredShips = useAnalysisFilteredShips();
  const contractedShipIds = useAnalysisContractedShipIds();
  const searchOpen = useAnalysisSearchOpen();
  const searchConditions = useAnalysisConfirmedSearchConditions();
  const confirmedSearchResultItem = useAnalysisSearchConfirmedSearchResultItem();
  const fetching = useAnalysisFetching();
  const analysisData = useAnalysisData();
  const fetchError = useAnalysisFetchError();
  const tabIndex = useAnalysisTabIndex();
  const isExpandUpdated = useIsExpandUpdated();
  const containerRef = useRef<HTMLDivElement>(null);
  const [contentWidth, setContentWidth] = useState<number>(-1);
  const [visibleChart, setVisibleChart] = useState<boolean>(false);

  /**
   * 検索入力変更
   * @param event イベント
   */
  const handleSeachInputChange = (input: string) => {
    dispatch(setFilterShipName(input));
  };

  /**
   * 検索条件変更クリック
   */
  const handleSeachClick = () => {
    dispatch(setSeachOpen(true));
  };

  /**
   * 船舶ドロワー開閉状態変更
   * @param open 開閉状態
   */
  const handleShipDrawerChangeOpen = (open: boolean) => {
    setShipDrawerOpen(open);
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 400);
  };

  /**
   *  船舶ドロワーの船舶開閉状態変更
   * @param shipId 船舶ID
   * @param expanded 開閉状態
   */
  const handleShipDrawerExpandedChange = (shipId: string, expanded: boolean) => {
    dispatch(setContractedShipId({ shipId, expanded }));
  };

  /**
   * データ取得エラー
   */
  const handleFetchError = () => {
    dispatch(setFetchError(undefined));
  };

  /**
   * データ取得エラー
   */
  const handleSetTabIndex = (tabIndex: number) => {
    dispatch(setTabIndex(tabIndex));
  };

  const handleDateFormat = (date: string) => {
    return dayjs(date).utc().format(constants.dateFormat.YYYYMMDD);
  };

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      const width = entries[0].contentRect.width;
      setContentWidth(width);
    });
    containerRef.current && resizeObserver.observe(containerRef.current);

    return (): void => {
      resizeObserver.disconnect();
    };
  }, [dispatch]);

  useEffect(() => {
    if (ships != null && searchConditions != null && confirmedSearchResultItem != null) {
      dispatch(setShips({ ships, confirmedSearchResultItem }));
      dispatch(fetchAnalysisData(searchConditions, confirmedSearchResultItem));
    }
  }, [dispatch, ships, searchConditions, confirmedSearchResultItem]);

  useEffect(() => {
    if (analysisData && !hidden && !visibleChart) {
      dispatch(setBusyProgress(true));
      setTimeout(() => {
        setVisibleChart(true);
      }, 500);
    } else {
      if (hidden && visibleChart) {
        setVisibleChart(false);
      }
    }

    if (visibleChart) {
      dispatch(setBusyProgress(false));
    }
  }, [dispatch, analysisData, hidden, visibleChart]);

  useEffect(() => {
    if (isExpandUpdated) {
      setTimeout(() => {
        dispatch(setExpandUpdated(false));
      }, 800);
    }
  }, [dispatch, isExpandUpdated]);

  const rexPropellerInvolvementContainer = useMemo(
    () => (
      <RexPropellerInvolvementContainer
        chartId={'rexPropellerForeignMatterEntrainment_0'}
        hidden={hidden || !visibleChart}
      />
    ),
    [hidden, visibleChart]
  );

  return (
    <RootContainer sx={{ display: 'block' }} hidden={hidden}>
      <SearchConditionsBar
        handleSeachClick={handleSeachClick}
        analysisSearchConditions={searchConditions}
        onDateFormat={handleDateFormat}
        tabIndex={tabIndex}
        onTabClick={handleSetTabIndex}
      />
      <ContentsDiv>
        <ShipDrawer
          ships={filteredShips}
          contractedShipIds={contractedShipIds}
          open={shipDrawerOpen}
          onChangeOpen={handleShipDrawerChangeOpen}
          onExpandedChange={handleShipDrawerExpandedChange}
          onSearchInputChange={handleSeachInputChange}
        />
        <Box
          sx={
            shipDrawerOpen
              ? {
                  width: `calc(100% - ${dimens.subDrawer.width}px)`,
                  height: `calc(100% - ${dimens.searchBar.height}px)`,
                }
              : {
                  width: `calc(100% - ${dimens.subDrawer.minWidth}px)`,
                  height: `calc(100% - ${dimens.searchBar.height}px)`,
                }
          }
          ref={containerRef}
        >
          {analysisData != null &&
            confirmedSearchResultItem != null &&
            ships != null &&
            ships.length > 0 &&
            tabIndex === 0 && (
              <CustomScrollBars thumbColor={colors.scrollBar.thumb.dark} autoHide={false}>
                <Box
                  sx={
                    contentWidth > dimens.chart.analysis.width * 2
                      ? {
                          display: 'flex',
                          overflow: 'hidden',
                        }
                      : {}
                  }
                >
                  {/* 船体 : 船速分析 */}
                  {analysisData.data.vesShipSpeed && (
                    <VesShipSpeedContainer
                      chartId={analysisData.data.vesShipSpeed.analysisMenuName + '_0'}
                      confirmedSearchResultItem={confirmedSearchResultItem}
                      analysisData={analysisData}
                      chartHeight={contentWidth > dimens.chart.analysis.width * 2 ? 500 : 300}
                    />
                  )}
                  {/* 主機 : 負荷分析 */}
                  {analysisData.data.megLoad && (
                    <MegLoadContainer
                      chartId={analysisData.data.megLoad.analysisMenuName + '_0'}
                      confirmedSearchResultItem={confirmedSearchResultItem}
                      analysisData={analysisData}
                      chartHeight={contentWidth > dimens.chart.analysis.width * 2 ? 500 : 300}
                    />
                  )}
                </Box>
                {/* 主機 : 排気温度分析 */}
                {analysisData.data.megTemp && (
                  <MegTempContainer
                    chartId={analysisData.data.megTemp.analysisMenuName + '_0'}
                    confirmedSearchResultItem={confirmedSearchResultItem}
                    analysisData={analysisData}
                    width={contentWidth}
                  />
                )}
                {/* 主機 : 日別負荷割合分析 */}
                {analysisData.data.megLoadBand && (
                  <MegLoadBandContainer
                    chartId={analysisData.data.megLoadBand.analysisMenuName + '_0'}
                    confirmedSearchResultItem={confirmedSearchResultItem}
                    analysisData={analysisData}
                  />
                )}
                {/* レックスペラ : アクチェータ操作量分析(旋回、CPP) */}
                {analysisData.data.rexPropulsionOperation && (
                  <RexPropulsionOperationContainer
                    chartId={analysisData.data.rexPropulsionOperation.analysisMenuName + '_0'}
                    confirmedSearchResultItem={confirmedSearchResultItem}
                    analysisData={analysisData}
                  />
                )}
                {/* レックスペラ : ベベルギア診断 */}
                {analysisData.data.rexGearAnomalyDetection && (
                  <RexGearAnomalyDetectionContainer
                    chartId={analysisData.data.rexGearAnomalyDetection.analysisMenuName + '_0'}
                    confirmedSearchResultItem={confirmedSearchResultItem}
                    analysisData={analysisData}
                  />
                )}
                {/* レックスペラ : 軸受診断 */}
                {analysisData.data.rexBearingAnomalyDetection && (
                  <RexBearingAnomalyDetectionContainer
                    chartId={analysisData.data.rexBearingAnomalyDetection.analysisMenuName + '_0'}
                    confirmedSearchResultItem={confirmedSearchResultItem}
                    analysisData={analysisData}
                  />
                )}
              </CustomScrollBars>
            )}
          {/* レックスペラ : プロペラ異物巻き込み分析(日別) */}
          {ships != null && ships.length > 0 && rexPropellerInvolvementContainer}
        </Box>
      </ContentsDiv>
      <FadeLoading loading={fetching} />
      {fetchError != null && <ApiErrorDialog error={fetchError} onClose={handleFetchError} />}
      <SlideFragmentContainer open={searchOpen}>
        <SearchFragment />
      </SlideFragmentContainer>
    </RootContainer>
  );
}
