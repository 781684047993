import React, { useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';
import { useIntl } from 'react-intl';
import msgId from 'resources/intl';
import { theme } from 'resources/theme';
import { Account } from 'models/accounts';
import {
  Button,
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Typography,
} from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { AlertDialog, DialogResult } from 'components/AlertDialog';

const StyledPopper = styled(Popper)({
  zIndex: theme.zIndex.drawer + 101,
});

const Arrow = styled('span')({
  position: 'absolute',
  width: 0,
  height: 0,
  borderLeft: '1em solid transparent',
  borderRight: '1em solid transparent',
  borderBottom: '1em solid #FFF',
  marginTop: '-0.9em',
  '&:before': {
    borderWidth: '0 1em 1em 1em',
    borderColor: 'transparent transparent white transparent',
  },
});

const MenuHeader = styled('div')({
  color: theme.palette.text.disabled,
  padding: theme.spacing(1, 2),
  minHeight: '36px',
  textAlign: 'left',
  alignItems: 'center',
  outline: 'none',
});

const StyledMenuItem = styled(MenuItem)({
  borderTop: `1px solid ${theme.palette.divider}`,
  color: theme.palette.primary.main,
});

interface AccountButtonProps {
  account: Account;
  onClickAccountInformation?(): void;
  onClickSignOut?(): void;
}

export function AccountButton(props: AccountButtonProps): JSX.Element {
  const { account, onClickAccountInformation, onClickSignOut } = props;
  const intl = useIntl();
  const [open, setOpen] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const anchorRef = useRef<HTMLButtonElement>(null);
  const [arrowRef, setArrowRef] = useState<HTMLSpanElement>();
  const prevOpen = useRef(open);

  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current?.focus();
    }
    prevOpen.current = open;
  }, [open]);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClickAway = (event: MouseEvent | TouchEvent) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    setOpen(false);
  };

  const handleListKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  };

  const handleArrowRef = (node: HTMLElement) => {
    setArrowRef(node);
  };

  const handleAccountInformation = (event: React.MouseEvent<EventTarget>) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }
    setOpen(false);
    onClickAccountInformation && onClickAccountInformation();
  };

  const handleSignOutRequest = (event: React.MouseEvent<EventTarget>) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }
    setConfirm(true);
  };

  const handleSignOut = (result: DialogResult) => {
    setConfirm(false);
    if (result === 'ok') {
      setOpen(false);
      onClickSignOut && onClickSignOut();
    }
  };

  return (
    <React.Fragment>
      <Button
        color="inherit"
        ref={anchorRef}
        aria-controls={open ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
      >
        <AccountCircleIcon />
        <ExpandMoreIcon />
      </Button>
      <StyledPopper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        placement="bottom-end"
        modifiers={[
          {
            name: 'offset',
            options: {
              offset: [-4, 12],
            },
          },
          {
            name: 'arrow',
            enabled: true,
            options: {
              element: arrowRef,
            },
          },
        ]}
      >
        {({ TransitionProps }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: 'center top',
            }}
          >
            <Paper>
              <Arrow ref={handleArrowRef} />
              <ClickAwayListener onClickAway={handleClickAway}>
                <MenuList
                  sx={{ padding: 0, minWidth: 200, outline: 'none' }}
                  autoFocusItem={open}
                  id="menu-list-grow"
                  onKeyDown={handleListKeyDown}
                >
                  <MenuHeader>
                    <Typography variant="body1" noWrap>
                      {account?.cognitoAccountId}
                    </Typography>
                    <Typography variant="body2" noWrap>
                      {account?.mailAddress}
                    </Typography>
                  </MenuHeader>
                  <StyledMenuItem
                    onClick={handleAccountInformation}
                    disabled={onClickAccountInformation === undefined}
                  >
                    {intl.formatMessage({ id: msgId.accountInformation })}
                  </StyledMenuItem>
                  <StyledMenuItem onClick={handleSignOutRequest}>
                    {intl.formatMessage({ id: msgId.logout })}
                  </StyledMenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </StyledPopper>
      {confirm && (
        <AlertDialog
          title={intl.formatMessage({ id: msgId.confirm })}
          message={intl.formatMessage({ id: msgId.logoutConfirmMessage })}
          negativeButton={intl.formatMessage({ id: msgId.cancel })}
          onClose={handleSignOut}
        />
      )}
    </React.Fragment>
  );
}
