import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'app/rootReducer';
import { useSelector } from 'react-redux';
import AppLogger from 'utils/AppLogger';

let sliderTime = 0;
export function SliderTime(): number {
  return sliderTime;
}

let timeSliderCurrentIndex = 0;
export function getTimeSliderCurrentIndex(): number {
  return timeSliderCurrentIndex;
}

export interface TimeSliderRange {
  /** 開始位置 */
  startIndex: number;
  /** 終了位置 */
  endIndex: number;
  /** 開始時間 */
  startTime: number;
  /** 終了位置 */
  endTime: number;
}

/**
 * カレンダー画面設定保持用 インターフェース
 */
interface SaveRange {
  range: TimeSliderRange;
  currentIndex: number;
  timeSliderCurrentIndex: number;
  sliderTime: number;
}

/**
 * TimeSliderState インターフェース
 */
interface TimeSliderState {
  /** 再生中かどうか */
  playing: boolean;
  /** 範囲 */
  range: TimeSliderRange;
  /** 現在位置 */
  currentIndex: number;
  /** 再生速度倍率 */
  speedMagnification: number;
  /** 1サンプルあたりのミリ秒 */
  samplePerMilliSeconds: number;
  /** カレンダー範囲 */
  saveRange: SaveRange | undefined;
  /** カレンダー範囲をリストアするか */
  restoreRange: boolean;
  /** 前の1サンプルあたりのミリ秒 */
  beforeSamplePerMilliSeconds: number;
}

/**
 * TimeSlider の初期状態
 */
const initialState: TimeSliderState = {
  playing: false,
  range: { startIndex: 0, endIndex: 0, startTime: 0, endTime: 0 },
  currentIndex: 0,
  speedMagnification: 1.0,
  samplePerMilliSeconds: 1000,
  saveRange: undefined,
  restoreRange: false,
  beforeSamplePerMilliSeconds: 1000,
};

export const timeSliderSlice = createSlice({
  name: 'shipStatePastDataTimeSlider',
  initialState,
  reducers: {
    /**
     * 再生を実行する。
     */
    play: (state) => {
      state.playing = true;
    },
    /**
     * 一時停止を実行する。
     */
    pause: (state) => {
      state.playing = false;
    },
    /**
     * 再生速度倍率を設定する。
     */
    setSpeedMagnification: (state, action: PayloadAction<number>) => {
      state.speedMagnification = action.payload;
    },
    /**
     * 1サンプルあたりのミリ秒を設定する。
     */
    setSamplePerMilliSeconds: (state, action: PayloadAction<number>) => {
      state.beforeSamplePerMilliSeconds = state.samplePerMilliSeconds;
      state.samplePerMilliSeconds = action.payload;
    },
    /**
     * 範囲を設定する。
     */
    setRange: (state, action: PayloadAction<TimeSliderRange>) => {
      const { startIndex, endIndex, startTime, endTime } = action.payload;
      if (
        state.range.startTime === startTime &&
        state.range.endTime === endTime &&
        state.range.startIndex === startIndex &&
        state.range.endIndex === endIndex
      ) {
        // 同じ範囲なので何もしない
      } else {
        // sampleが変更になった場合を考慮
        const currentTime =
          state.range.startTime +
          state.currentIndex *
            (state.samplePerMilliSeconds === state.beforeSamplePerMilliSeconds
              ? state.samplePerMilliSeconds
              : state.beforeSamplePerMilliSeconds);
        state.range = action.payload;
        if (
          currentTime < startTime ||
          currentTime > startTime + endIndex * state.samplePerMilliSeconds
        ) {
          state.currentIndex = 0;
          timeSliderCurrentIndex = 0;
          sliderTime = 0;
        } else {
          const startIndex = (currentTime - startTime) / state.samplePerMilliSeconds;
          state.currentIndex = startIndex;
          timeSliderCurrentIndex = startIndex;
          sliderTime = state.currentIndex * state.samplePerMilliSeconds;
        }
      }
      state.playing = false;

      // カレンダー設定の設定復元
      if (state.restoreRange && state.saveRange) {
        state.range = { ...state.saveRange.range };
        state.currentIndex = state.saveRange.currentIndex;
        timeSliderCurrentIndex = state.saveRange.timeSliderCurrentIndex;
        sliderTime = state.saveRange.sliderTime;
        state.saveRange = undefined;
      }
      state.restoreRange = false;
    },
    /**
     * 現在位置を設定する。
     */
    setCurrentIndex: (state, action: PayloadAction<number>) => {
      state.currentIndex = action.payload;
      // let index = state.currentIndex + state.speedMagnification;
      let index = state.currentIndex;
      if (index < state.range.startIndex) {
        index = state.range.startIndex;
        state.playing = false;
      } else if (index > state.range.endIndex) {
        index = state.range.endIndex;
        state.playing = false;
      }
      state.currentIndex = index;
      timeSliderCurrentIndex = state.currentIndex;
      sliderTime = state.currentIndex * state.samplePerMilliSeconds;
    },
    /**
     * 位置をインクリメントする。
     */
    incrementIndex: (state) => {
      AppLogger.debug('incrementIndex');
      let index = state.currentIndex + state.speedMagnification;
      if (index > state.range.endIndex) {
        index = state.range.endIndex;
        state.playing = false;
      }
      state.currentIndex = index;
      timeSliderCurrentIndex = state.currentIndex;
      sliderTime = state.currentIndex * state.samplePerMilliSeconds;
    },
    /**
     * 位置をデクリメントする。
     */
    decrementIndex: (state) => {
      let index = state.currentIndex - state.speedMagnification;
      if (index < state.range.startIndex) {
        index = state.range.startIndex;
        state.playing = false;
      }
      state.currentIndex = index;
      timeSliderCurrentIndex = state.currentIndex;
      sliderTime = state.currentIndex * state.samplePerMilliSeconds;
    },
    /**
     * shipStatePastDataTimeSliderをクリアする。
     */
    clearShipStatePastDataTimeSlider: (state) => {
      state.playing = initialState.playing;
      state.range = { ...initialState.range };
      state.currentIndex = initialState.currentIndex;
      state.speedMagnification = initialState.speedMagnification;
      state.samplePerMilliSeconds = initialState.samplePerMilliSeconds;
      state.saveRange = undefined;
      state.restoreRange = false;
      sliderTime = 0;
      timeSliderCurrentIndex = 0;
    },
    /**
     * 値を退避する。上位では意識せず保存を実行
     */
    saveRange: (state) => {
      // 初回登録のみ保持
      if (!state.saveRange && state.range.startTime !== 0 && state.range.endTime !== 0) {
        state.saveRange = {
          range: { ...state.range },
          currentIndex: state.currentIndex,
          sliderTime: sliderTime,
          timeSliderCurrentIndex: timeSliderCurrentIndex,
        };
      }
    },
    /**
     * 値を戻す。
     */
    restoreRange: (state, action: PayloadAction<boolean>) => {
      state.restoreRange = action.payload;
      if (!state.restoreRange) {
        state.saveRange = undefined;
      }
    },
  },
});

export const {
  play,
  pause,
  setSpeedMagnification,
  setSamplePerMilliSeconds,
  setRange,
  setCurrentIndex,
  incrementIndex,
  decrementIndex,
  clearShipStatePastDataTimeSlider,
  saveRange,
  restoreRange,
} = timeSliderSlice.actions;

const pastDataTimeSliderState = (state: RootState) => state.pastDataTimeSlider;
const selectPlaying = createSelector(pastDataTimeSliderState, (x) => x.playing);
const selectRange = createSelector(pastDataTimeSliderState, (x) => x.range);
const selectCurrentIndex = createSelector(pastDataTimeSliderState, (x) => x.currentIndex);
const selectSpeedMagnification = createSelector(
  pastDataTimeSliderState,
  (x) => x.speedMagnification
);
const selectSamplePerMilliSeconds = createSelector(
  pastDataTimeSliderState,
  (x) => x.samplePerMilliSeconds
);

export const usePastDataTimeSliderPlaying = () => useSelector(selectPlaying);
export const usePastDataTimeSliderRange = () => useSelector(selectRange);
export const usePastDataTimeSliderCurrentIndex = () => useSelector(selectCurrentIndex);
export const usePastDataTimeSliderSpeedMagnification = () => useSelector(selectSpeedMagnification);
export const usePastDataTimeSliderSamplePerMilliSeconds = () =>
  useSelector(selectSamplePerMilliSeconds);

export default timeSliderSlice.reducer;
