import { API, Storage } from 'aws-amplify';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { API_NAME, zipBlobToStringAsync } from './maricoApi';
import {
  AnalysisData,
  AnalysisSearchConditions,
  AnalysisSection,
  RexPropellerForeignMatterEntrainmentAnalysis,
  RexPropellerForeignMatterEntrainmentSensorData,
  RexGearAnomalyDetectionAnalysis,
  RexBearingAnomalyDetectionAnalysis,
} from 'models/analysis';
import { parse, ParseConfig } from 'papaparse';
import { KeyObject } from 'models/common';
import AppLogger from 'utils/AppLogger';
import { ConfirmedSearchResultItem, SearchResult, SearchFuelResult } from 'models/search';
import { getErrorResult } from 'models/error';
import colors from 'resources/colors';
import constants from 'resources/constants';

dayjs.extend(utc);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function toGearAnomalyDetectionData(
  gearAnomalyDetectionData: any
): RexGearAnomalyDetectionAnalysis {
  const rexGearAnomalyDetection: RexGearAnomalyDetectionAnalysis = {
    analysisSectionId: gearAnomalyDetectionData.analysisSectionId,
    analysisSectionName: gearAnomalyDetectionData.analysisSectionName,
    analysisMenuId: gearAnomalyDetectionData.analysisMenuId,
    analysisMenuName: gearAnomalyDetectionData.analysisMenuName,
    date: gearAnomalyDetectionData.date.map((x: string) => dayjs(x).valueOf()),
    UpperBevelMainShaftP: {
      values: gearAnomalyDetectionData.UpperBevelMainShaftP.values,
      colors: gearAnomalyDetectionData.UpperBevelMainShaftP.colors,
      htmlColors: gearAnomalyDetectionData.UpperBevelMainShaftP.colors.map(
        (x: number) => colors.chart.analysis.rexGearAnomalyDetection.incDegColors[x]
      ),
      details: gearAnomalyDetectionData.UpperBevelMainShaftP.details,
    },
    UpperBevelMainShaftS: {
      values: gearAnomalyDetectionData.UpperBevelMainShaftS.values,
      colors: gearAnomalyDetectionData.UpperBevelMainShaftS.colors,
      htmlColors: gearAnomalyDetectionData.UpperBevelMainShaftS.colors.map(
        (x: number) => colors.chart.analysis.rexGearAnomalyDetection.incDegColors[x]
      ),
      details: gearAnomalyDetectionData.UpperBevelMainShaftS.details,
    },
    UpperBevelVerticalShaftP: {
      values: gearAnomalyDetectionData.UpperBevelVerticalShaftP.values,
      colors: gearAnomalyDetectionData.UpperBevelVerticalShaftP.colors,
      htmlColors: gearAnomalyDetectionData.UpperBevelVerticalShaftP.colors.map(
        (x: number) => colors.chart.analysis.rexGearAnomalyDetection.incDegColors[x]
      ),
      details: gearAnomalyDetectionData.UpperBevelVerticalShaftP.details,
    },
    UpperBevelVerticalShaftS: {
      values: gearAnomalyDetectionData.UpperBevelVerticalShaftS.values,
      colors: gearAnomalyDetectionData.UpperBevelVerticalShaftS.colors,
      htmlColors: gearAnomalyDetectionData.UpperBevelVerticalShaftS.colors.map(
        (x: number) => colors.chart.analysis.rexGearAnomalyDetection.incDegColors[x]
      ),
      details: gearAnomalyDetectionData.UpperBevelVerticalShaftS.details,
    },
    LowerBevelPropellerShaftP: {
      values: gearAnomalyDetectionData.LowerBevelPropellerShaftP.values,
      colors: gearAnomalyDetectionData.LowerBevelPropellerShaftP.colors,
      htmlColors: gearAnomalyDetectionData.LowerBevelPropellerShaftP.colors.map(
        (x: number) => colors.chart.analysis.rexGearAnomalyDetection.incDegColors[x]
      ),
      details: gearAnomalyDetectionData.LowerBevelPropellerShaftP.details,
    },
    LowerBevelPropellerShaftS: {
      values: gearAnomalyDetectionData.LowerBevelPropellerShaftS.values,
      colors: gearAnomalyDetectionData.LowerBevelPropellerShaftS.colors,
      htmlColors: gearAnomalyDetectionData.LowerBevelPropellerShaftS.colors.map(
        (x: number) => colors.chart.analysis.rexGearAnomalyDetection.incDegColors[x]
      ),
      details: gearAnomalyDetectionData.LowerBevelPropellerShaftS.details,
    },
    LowerBevelVerticalShaftP: {
      values: gearAnomalyDetectionData.LowerBevelVerticalShaftP.values,
      colors: gearAnomalyDetectionData.LowerBevelVerticalShaftP.colors,
      htmlColors: gearAnomalyDetectionData.LowerBevelVerticalShaftP.colors.map(
        (x: number) => colors.chart.analysis.rexGearAnomalyDetection.incDegColors[x]
      ),
      details: gearAnomalyDetectionData.LowerBevelVerticalShaftP.details,
    },
    LowerBevelVerticalShaftS: {
      values: gearAnomalyDetectionData.LowerBevelVerticalShaftS.values,
      colors: gearAnomalyDetectionData.LowerBevelVerticalShaftS.colors,
      htmlColors: gearAnomalyDetectionData.LowerBevelVerticalShaftS.colors.map(
        (x: number) => colors.chart.analysis.rexGearAnomalyDetection.incDegColors[x]
      ),
      details: gearAnomalyDetectionData.LowerBevelVerticalShaftS.details,
    },
  };

  return rexGearAnomalyDetection;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function toBearingAnomalyDetectionData(
  bearingAnomalyDetectionData: any
): RexBearingAnomalyDetectionAnalysis {
  const rexBearingAnomalyDetection: RexBearingAnomalyDetectionAnalysis = {
    analysisSectionId: bearingAnomalyDetectionData.analysisSectionId,
    analysisSectionName: bearingAnomalyDetectionData.analysisSectionName,
    analysisMenuId: bearingAnomalyDetectionData.analysisMenuId,
    analysisMenuName: bearingAnomalyDetectionData.analysisMenuName,
    date: bearingAnomalyDetectionData.date.map((x: string) => dayjs(x).valueOf()),
    Bearing7P: {
      values: bearingAnomalyDetectionData.Bearing7P.values,
      details: bearingAnomalyDetectionData.Bearing7P.details,
    },
    Bearing7S: {
      values: bearingAnomalyDetectionData.Bearing7S.values,
      details: bearingAnomalyDetectionData.Bearing7S.details,
    },
    Bearing8P: {
      values: bearingAnomalyDetectionData.Bearing8P.values,
      details: bearingAnomalyDetectionData.Bearing8P.details,
    },
    Bearing8S: {
      values: bearingAnomalyDetectionData.Bearing8S.values,
      details: bearingAnomalyDetectionData.Bearing8S.details,
    },
  };

  return rexBearingAnomalyDetection;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
async function toPropellerForeignMatterEntrainmentSensorData(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  resultData: any
): Promise<RexPropellerForeignMatterEntrainmentAnalysis> {
  const { key, level, identityId, fileName } = resultData.sensorData;
  const data = (await Storage.get(key, {
    level,
    identityId,
    download: true,
  })) as { Body: Blob };
  const csv = await zipBlobToStringAsync(fileName, data.Body);

  return new Promise<RexPropellerForeignMatterEntrainmentAnalysis>((resolve, reject) => {
    let count = 0;
    const sensorData: RexPropellerForeignMatterEntrainmentSensorData = {
      date: [],
      MegSpdP1: [],
      MegFuelRackLvP1: [],
      MegTCSpdP1: [],
      RexLtStrPressP1RIO: [],
      RexRtStrPressP1RIO: [],
      RexCPPBladeAngOrderP1: [],
      RexCPPBladeAngFBP1: [],
      RexStrAngOrderP1: [],
      RexStrAngFBP1: [],
      MegSpdS1: [],
      MegFuelRackLvS1: [],
      MegTCSpdS1: [],
      RexLtStrPressS1RIO: [],
      RexRtStrPressS1RIO: [],
      RexCPPBladeAngOrderS1: [],
      RexCPPBladeAngFBS1: [],
      RexStrAngOrderS1: [],
      RexStrAngFBS1: [],
      VesLatC1: [],
      VesLonC1: [],
      VesSpdSOGC1: [],
      VesCourseTrueDirC1: [],
      VesWindDirRelC1: [],
      VesWindSpdRelC1: [],
      MahalanobisP1: [],
      MahalanobisS1: [],
      MahalanobisAlertP1: [],
      MahalanobisAlertS1: [],
      EngSpdP: [],
      EngFuelRackLvP: [],
      EngTcSpdP: [],
      RexLtStrPressP: [],
      RexRtStrPressP: [],
      RexStrAngOrderP: [],
      RexStrAngFbP: [],
      EngSpdS: [],
      EngFuelRackLvS: [],
      EngTcSpdS: [],
      RexLtStrPressS: [],
      RexRtStrPressS: [],
      RexStrAngOrderS: [],
      RexStrAngFbS: [],
    };
    const sensorNames: string[] = [];

    const mahDate = resultData.mahalanobis.date.map((x: string) => dayjs(x).valueOf());
    let mahIndex = 0;
    let currentTimestamp = 0;

    parse(csv, {
      header: true,
      fastMode: false,
      worker: true,
      skipEmptyLines: true,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      error: function (error: any) {
        reject(error);
      },
      step: function (row) {
        const data = row.data as KeyObject;
        if (sensorNames.length === 0) {
          for (const key in data) {
            if (key !== 'date') {
              sensorNames.push(key);
            }
          }
          sensorNames.push('MahalanobisP1');
          sensorNames.push('MahalanobisS1');
          sensorNames.push('MahalanobisAlertP1');
          sensorNames.push('MahalanobisAlertS1');
          currentTimestamp = dayjs(data['date'] + 'Z').valueOf();
        }

        const dateValue = dayjs(data['date'] + 'Z').valueOf();
        // 抜けている区間を埋める
        while (currentTimestamp < dateValue) {
          sensorData.date.push(currentTimestamp);
          sensorNames.forEach((x) => {
            if (x === 'VesLatC1MCOM' || x === 'VesLatC1GCOM' || x === 'VesLat') {
              sensorData['VesLatC1'].push(NaN);
            } else if (x === 'VesLonC1MCOM' || x === 'VesLonC1GCOM' || x === 'VesLon') {
              sensorData['VesLonC1'].push(NaN);
            } else if (x === 'VesSpdSOGC1MCOM' || x === 'VesSpdSogC1GCOM' || x === 'VesSog') {
              sensorData['VesSpdSOGC1'].push(NaN);
            } else if (
              x === 'VesCourseTrueDirC1MCOM' ||
              x === 'VesCourseTrueDirC1GCOM' ||
              x === 'VesHeading'
            ) {
              sensorData['VesCourseTrueDirC1'].push(NaN);
            } else if (
              x === 'VesWindDirRelC1' ||
              x === 'VesWindDirmagnitudeindgreeC1' ||
              x === 'VesWindDirMagnitudMagDeg'
            ) {
              sensorData['VesWindDirRelC1'].push(NaN);
            } else if (
              x === 'VesWindSpdRelC1MCOM' ||
              x === 'VesWindSpdRelC1GCOM' ||
              x === 'VesWindSpd'
            ) {
              sensorData['VesWindSpdRelC1'].push(NaN);
            } else if (
              !(
                x === 'MahalanobisP1' ||
                x === 'MahalanobisS1' ||
                x === 'MahalanobisAlertP1' ||
                x === 'MahalanobisAlertS1'
              )
            ) {
              sensorData[x].push(NaN);
            }
          });

          if (
            mahDate.length > mahIndex &&
            Math.floor(currentTimestamp / 1000) === Math.floor(mahDate[mahIndex] / 1000)
          ) {
            if (resultData.mahalanobis.AlertFlags[mahIndex] != 0) {
              sensorData['MahalanobisP1'].push(NaN);
              sensorData['MahalanobisS1'].push(NaN);
              sensorData['MahalanobisAlertP1'].push(resultData.mahalanobis.MahalanobisP1[mahIndex]);
              sensorData['MahalanobisAlertS1'].push(resultData.mahalanobis.MahalanobisS1[mahIndex]);
            } else {
              sensorData['MahalanobisP1'].push(resultData.mahalanobis.MahalanobisP1[mahIndex]);
              sensorData['MahalanobisS1'].push(resultData.mahalanobis.MahalanobisS1[mahIndex]);
              sensorData['MahalanobisAlertP1'].push(NaN);
              sensorData['MahalanobisAlertS1'].push(NaN);
            }
            mahIndex += 1;
          } else {
            sensorData['MahalanobisP1'].push(NaN);
            sensorData['MahalanobisS1'].push(NaN);
            sensorData['MahalanobisAlertP1'].push(NaN);
            sensorData['MahalanobisAlertS1'].push(NaN);
          }

          currentTimestamp += 1000;
        }

        sensorData.date.push(dateValue);
        sensorNames.forEach((x) => {
          if (x === 'VesLatC1MCOM' || x === 'VesLatC1GCOM' || x === 'VesLat') {
            sensorData['VesLatC1'].push(data[x]);
          } else if (x === 'VesLonC1MCOM' || x === 'VesLonC1GCOM' || x === 'VesLon') {
            sensorData['VesLonC1'].push(data[x]);
          } else if (x === 'VesSpdSOGC1MCOM' || x === 'VesSpdSogC1GCOM' || x === 'VesSog') {
            sensorData['VesSpdSOGC1'].push(data[x]);
          } else if (
            x === 'VesCourseTrueDirC1MCOM' ||
            x === 'VesCourseTrueDirC1GCOM' ||
            x === 'VesHeading'
          ) {
            sensorData['VesCourseTrueDirC1'].push(data[x]);
          } else if (
            x === 'VesWindDirRelC1' ||
            x === 'VesWindDirmagnitudeindgreeC1' ||
            x === 'VesWindDirMagnitudMagDeg'
          ) {
            sensorData['VesWindDirRelC1'].push(data[x]);
          } else if (
            x === 'VesWindSpdRelC1MCOM' ||
            x === 'VesWindSpdRelC1GCOM' ||
            x === 'VesWindSpd'
          ) {
            sensorData['VesWindSpdRelC1'].push(data[x]);
          } else if (
            x === 'RexStrAngOrderP1' ||
            x === 'RexStrAngOrderS1' ||
            x === 'RexStrAngFBP1' ||
            x === 'RexStrAngFBS1' ||
            x === 'RexStrAngFbP' ||
            x === 'RexStrAngFbS' ||
            x === ''
          ) {
            const val = data[x];
            sensorData[x].push(isNaN(val) ? NaN : val > 180 ? val - 360 : val);
          } else if (
            !(
              x === 'MahalanobisP1' ||
              x === 'MahalanobisS1' ||
              x === 'MahalanobisAlertP1' ||
              x === 'MahalanobisAlertS1'
            )
          ) {
            sensorData[x].push(data[x]);
          }
        });

        if (
          mahDate.length > mahIndex &&
          Math.floor(dateValue / 1000) === Math.floor(mahDate[mahIndex] / 1000)
        ) {
          if (resultData.mahalanobis.AlertFlags[mahIndex] != 0) {
            sensorData['MahalanobisP1'].push(NaN);
            sensorData['MahalanobisS1'].push(NaN);
            sensorData['MahalanobisAlertP1'].push(resultData.mahalanobis.MahalanobisP1[mahIndex]);
            sensorData['MahalanobisAlertS1'].push(resultData.mahalanobis.MahalanobisS1[mahIndex]);
          } else {
            sensorData['MahalanobisP1'].push(resultData.mahalanobis.MahalanobisP1[mahIndex]);
            sensorData['MahalanobisS1'].push(resultData.mahalanobis.MahalanobisS1[mahIndex]);
            sensorData['MahalanobisAlertP1'].push(NaN);
            sensorData['MahalanobisAlertS1'].push(NaN);
          }
          mahIndex += 1;
        } else {
          sensorData['MahalanobisP1'].push(NaN);
          sensorData['MahalanobisS1'].push(NaN);
          sensorData['MahalanobisAlertP1'].push(NaN);
          sensorData['MahalanobisAlertS1'].push(NaN);
        }

        currentTimestamp = dateValue + 1000;
        count += 1;
      },
      complete: function () {
        AppLogger.debug('All done! count=' + count);

        resolve({
          analysisMenuId: resultData.analysisMenuId,
          analysisMenuName: resultData.analysisMenuName,
          analysisSectionId: resultData.analysisSectionId,
          analysisSectionName: resultData.analysisSectionName,
          mahalanobis: resultData.mahalanobis,
          sensorData: sensorData,
          displayInformations: resultData.displayInformations,
        });
      },
    } as ParseConfig);
  });
}

/**
 * 分析データを取得する。
 * @param machineIds 機械IDリスト
 * @param sensorNames センサー名リスト
 * @param startDate 開始日付
 * @param endDate 終了日付
 */
export async function getAnalysisDataAsync(
  searchConditions: AnalysisSearchConditions,
  confirmedSearchResultItem: ConfirmedSearchResultItem
): Promise<AnalysisData> {
  try {
    const sd = confirmedSearchResultItem.searchResultItem.startDate + 'T00:00:00+00:00';
    const ed = confirmedSearchResultItem.searchResultItem.endDate + 'T00:00:00+00:00';
    const params = {
      headers: {
        'Content-Type': 'application/json',
      },
      body: {
        machineId: confirmedSearchResultItem.searchResultItem.machineId,
        startDate: sd,
        endDate: ed,
        analysisPeriod: searchConditions.periodUnit,
        analysisMenuIds: searchConditions.analysisMenuIds,
      },
      response: true,
      timeout: 60000,
    };

    const result = await API.post(API_NAME, '/v1/analysis', params);
    if (searchConditions.periodUnit == 'daily') {
      const rexPropellerForeignMatterEntrainment =
        await toPropellerForeignMatterEntrainmentSensorData(
          result.data.data.rexPropellerForeignMatterEntrainment
        );

      return {
        shipId: result.data.shipId,
        machineId: result.data.machineId,
        shipName: result.data.shipName,
        machineName: result.data.machineName,
        dataFormatId: result.data.dataFormatId,
        data: {
          megLoad: undefined,
          megLoadBand: undefined,
          megTemp: undefined,
          vesShipSpeed: undefined,
          rexPropulsionOperation: undefined,
          rexGearAnomalyDetection: undefined,
          rexBearingAnomalyDetection: undefined,
          rexPropellerForeignMatterEntrainment: rexPropellerForeignMatterEntrainment,
        },
      };
    } else {
      let rexGearAnomalyDetection: RexGearAnomalyDetectionAnalysis | undefined = undefined;
      if (result.data.data.rexGearAnomalyDetection != null) {
        rexGearAnomalyDetection = toGearAnomalyDetectionData(
          result.data.data.rexGearAnomalyDetection
        );
      }
      let rexBearingAnomalyDetection: RexBearingAnomalyDetectionAnalysis | undefined = undefined;
      if (result.data.data.rexBearingAnomalyDetection != null) {
        rexBearingAnomalyDetection = toBearingAnomalyDetectionData(
          result.data.data.rexBearingAnomalyDetection
        );
      }

      return {
        shipId: result.data.shipId,
        machineId: result.data.machineId,
        shipName: result.data.shipName,
        machineName: result.data.machineName,
        dataFormatId: result.data.dataFormatId,
        data: {
          megLoad: result.data.data.megLoad,
          megLoadBand: result.data.data.megLoadBand,
          megTemp: result.data.data.megTemp,
          vesShipSpeed: result.data.data.vesShipSpeed,
          rexPropulsionOperation: result.data.data.rexPropulsionOperation,
          rexGearAnomalyDetection: rexGearAnomalyDetection,
          rexBearingAnomalyDetection: rexBearingAnomalyDetection,
          rexPropellerForeignMatterEntrainment: undefined,
        },
      };
    }
  } catch (error) {
    AppLogger.error('error getAnalysisDataAsync: ', error);
    throw getErrorResult(error);
  }
}

/**
 * 分析データ検索結果を取得する。
 * @param machineIds 機械IDリスト
 * @param searchConditions 分析検索条件
 */
export async function searchAnalysisDataAsync(
  machineIds: number[],
  searchConditions: AnalysisSearchConditions
): Promise<SearchResult[]> {
  try {
    let d = dayjs(searchConditions.startDate).utc().format(constants.dateFormat.iso8601);
    const sd = d.split('T')[0] + 'T00:00:00Z';
    d = dayjs(searchConditions.endDate).utc().format(constants.dateFormat.iso8601);
    const ed = d.split('T')[0] + 'T00:00:00Z';
    const params = {
      headers: {
        'Content-Type': 'application/json',
      },
      body: {
        machineIds: machineIds,
        startDate: sd,
        endDate: ed,
        analysisPeriod: searchConditions.periodUnit,
        analysisMenuIds: searchConditions.analysisMenuIds,
      },
      response: true,
      timeout: 30000,
    };
    const results = await API.post(API_NAME, '/v1/analysis/search', params);
    const searchResults: SearchResult[] = results.data;
    searchResults.forEach((x) => {
      x.results.forEach((y) => {
        y.machineId = x.machineId;
      });
    });

    return searchResults.sort((a, b) => {
      if (a.shipId > b.shipId) {
        return 1;
      } else if (a.shipId < b.shipId) {
        return -1;
      } else {
        return 0;
      }
    });
  } catch (error) {
    AppLogger.error('error searchAnalysisDataAsync: ', error);
    throw getErrorResult(error);
  }
}

/**
 * 分析データ検索結果を取得する。
 * @param machineIds 機械IDリスト
 * @param searchConditions 分析検索条件
 */
export async function searchAnalysisFuelOilConsumDetectionSearchAsync(
  machineId: number,
  searchConditions: AnalysisSearchConditions
): Promise<SearchFuelResult> {
  try {
    const sd = dayjs(searchConditions.startDate).utc().format(constants.dateFormat.iso8601);
    const ed = dayjs(searchConditions.endDate)
      .add(1, 'minute')
      .add(-100, 'millisecond')
      .utc()
      .format(constants.dateFormat.iso8601WithSSS);
    const params = {
      headers: {
        'Content-Type': 'application/json',
      },
      body: {
        machineId: machineId,
        startDate: sd,
        endDate: ed,
        conditions: searchConditions.conditions.map((condition) => {
          return {
            lowerValue: condition.lowerValue,
            upperValue: condition.upperValue,
            sensorName: condition.id,
          };
        }), // 速力、回転数の順
      },
      response: true,
      timeout: 30000,
    };
    const results = await API.post(
      API_NAME,
      '/v1/analysis/fuel-oil-consum-detection-search',
      params
    );
    Object.keys(results.data.summary).forEach((key) => {
      results.data.summary[key] =
        results.data.summary[key] == null ? constants.invalid.value : results.data.summary[key];
    });

    return results.data;
  } catch (error) {
    AppLogger.error('error searchAnalysisDataAsync: ', error);
    throw getErrorResult(error);
  }
}

/**
 * 分析要素区分リストを取得する。
 */
export async function getAnalysisSectionsAsync(): Promise<AnalysisSection[]> {
  try {
    const params = {
      headers: {},
      response: true,
    };
    const result = await API.get(API_NAME, '/v1/analysis/menu', params);
    const analysisSections: AnalysisSection[] = result.data;
    const monthIds: number[] = [1, 3, 4, 5, 9, 11, 14];
    const weekIds: number[] = [];
    const dayIds: number[] = [13];
    const otherIds: number[] = [15];
    analysisSections.forEach((x) => {
      x.analysisMenus.forEach((y) => {
        y.locked =
          y.analysisMenuId === 2 ||
          y.analysisMenuId === 6 ||
          y.analysisMenuId === 7 ||
          y.analysisMenuId === 8 ||
          y.analysisMenuId === 10 ||
          y.analysisMenuId === 12;
        y.checked = !y.locked;
        y['daily'] = dayIds.includes(y.analysisMenuId);
        y['weekly'] = weekIds.includes(y.analysisMenuId);
        y['monthly'] = monthIds.includes(y.analysisMenuId);
        y['other'] = otherIds.includes(y.analysisMenuId);
      });
    });

    return analysisSections;
  } catch (error) {
    AppLogger.error('error getAnalysisSectionsAsync: ', error);
    throw getErrorResult(error);
  }
}
