import React, { useRef, useState } from 'react';
import dayjs from 'dayjs';
import { useIntl } from 'react-intl';
import { DiagnosisStatus } from 'models/diagnosis';
import {
  ClickAwayListener,
  Divider,
  Grow,
  IconButton,
  MenuList,
  Paper,
  Popper,
  styled,
  Typography,
} from '@mui/material';
import { theme } from 'resources/theme';
import { CautionIcon, FaultIcon, MaintenanceIcon, WarningIcon } from './ShipItem';
import constants from 'resources/constants';
import { CustomScrollBars } from 'components/CustomScrollBars';

const StyledPopper = styled(Popper)({
  zIndex: theme.zIndex.drawer + 101,
});

const StyledMenuList = styled(MenuList)({
  padding: 0,
  minWidth: 200,
  outline: 'none',
});

const MenuHeader = styled('div')({
  color: theme.palette.text.secondary,
  padding: theme.spacing(1, 2),
  minHeight: '36px',
  textAlign: 'left',
  alignItems: 'center',
  outline: 'none',
});

const IconRow = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'flex-start',
});

const IconFrame = styled('span')({
  paddingTop: 3,
  paddingRight: 4,
});

export type PopupChangeEvent = 'open' | 'close' | 'awayClick';

export interface ShipStatus {
  operation: boolean;
  maintenance: boolean;
  diagnosisStatus?: DiagnosisStatus;
  sensorName: string;
  startDate: string;
}

// const useStyles = makeStyles((theme: Theme) =>
//   createStyles({
//     paper: {
//       border: '1px solid',
//       backgroundColor: theme.palette.background.paper,
//     },
//     popdiv: {
//       padding: theme.spacing(1),
//     },
//     triangle: {
//       position: 'absolute',
//       bottom: '100%',
//       left: '50%',
//       transform: 'translateX(-50%)',
//       width: 0,
//       height: 0,
//       borderBottom: `10px solid #FFF`,
//       borderRight: '10px solid transparent',
//       borderLeft: '10px solid transparent',
//     },
//     triangleUnder: {
//       position: 'absolute',
//       top: '100%',
//       left: '50%',
//       transform: 'translateX(-50%)',
//       width: 0,
//       height: 0,
//       borderTop: `10px solid #FFF`,
//       borderRight: '10px solid transparent',
//       borderLeft: '10px solid transparent',
//     },
//   })
// );

interface ShipStatusPopupProps {
  shipStatus: ShipStatus[];
  onClick?: (event: React.MouseEvent<EventTarget>) => void;
  onPopupChange: (event: PopupChangeEvent) => void;
}

export function ShipStatusPopup(props: ShipStatusPopupProps): JSX.Element {
  const { shipStatus, onClick, onPopupChange } = props;
  const intl = useIntl();
  const anchorRef = useRef<HTMLButtonElement>(null);
  const [open, setOpen] = useState(false);

  const handleToggle = (event: React.MouseEvent<EventTarget>) => {
    onPopupChange(!open ? 'open' : 'close');
    setOpen((prevOpen) => !prevOpen);
    if (onClick) {
      onClick(event);
    }
  };

  const handleClickAway = (event: MouseEvent | TouchEvent) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    onPopupChange('awayClick');
    setOpen(false);
  };

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === 'Tab') {
      event.preventDefault();
      onPopupChange('awayClick');
      setOpen(false);
    }
  }

  return (
    <React.Fragment>
      <IconButton onClick={handleToggle} ref={anchorRef}>
        {shipStatus.some((x) => x.maintenance) && <MaintenanceIcon />}
        {shipStatus.some((x) => x.diagnosisStatus === 'Caution') && <CautionIcon />}
        {shipStatus.some((x) => x.diagnosisStatus === 'Warning') && <WarningIcon />}
        {shipStatus.some((x) => x.diagnosisStatus === 'Fault') && <FaultIcon />}
      </IconButton>
      <StyledPopper
        open={open}
        anchorEl={anchorRef.current}
        transition
        placement="auto"
        modifiers={[
          {
            name: 'offset',
            options: {
              offset: [-4, 12],
            },
          },
        ]}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClickAway}>
                <div>
                  <CustomScrollBars autoHide={false} autoHeight={true} autoHeightMax={500}>
                    <StyledMenuList
                      autoFocusItem={open}
                      id="menu-list-grow"
                      onKeyDown={handleListKeyDown}
                    >
                      {shipStatus.map((status, index) => {
                        return (
                          <div key={index}>
                            <MenuHeader>
                              <IconRow>
                                <IconFrame>
                                  {status.diagnosisStatus === 'Caution' && <CautionIcon />}
                                  {status.diagnosisStatus === 'Warning' && <WarningIcon />}
                                  {status.diagnosisStatus === 'Fault' && <FaultIcon />}
                                </IconFrame>
                                <span style={{ verticalAlign: 'middle' }}>
                                  {intl.formatMessage({
                                    id: 'diagnosis_status_' + status.diagnosisStatus,
                                  })}
                                </span>
                              </IconRow>
                              <Typography variant="body2" noWrap>
                                {dayjs(status.startDate)
                                  .utc()
                                  .format(constants.dateFormat.YYYYMMDDHHmmssSSS)
                                  .substring(0, 21)}
                              </Typography>
                              <Typography variant="body2" noWrap>
                                {intl.formatMessage({ id: status.sensorName })}
                              </Typography>
                            </MenuHeader>
                            {shipStatus.length - 1 !== index && <Divider />}
                          </div>
                        );
                      })}
                    </StyledMenuList>
                  </CustomScrollBars>
                </div>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </StyledPopper>
    </React.Fragment>
  );
}
