import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { FadeLoading } from 'components/FadeLoading';
import {
  clearDiagnosis,
  fetchDiagnosisData,
  setChartLegendExpandUpdated,
  setChartSeriesRange,
  setDataFetchError,
  useDiagnosisChartLegendExpandUpdated,
  useDiagnosisChartSeriesList,
  useDiagnosisCurrentDiagnosis,
  useDiagnosisDataFetchError,
  useDiagnosisDataFetching,
  useDiagnosisListItems,
  useDiagnosisSelectedDiagnosis,
} from './diagnosisSlice';
import msgId from 'resources/intl';
import { ChartSeries } from 'models/chart';
import AppLogger from 'utils/AppLogger';
import { styled } from '@mui/material';
import colors from 'resources/colors';
import { ApiErrorDialog } from 'components/ApiErrorDialog';
import { ChartHeaderPanel } from 'components/chart/ChartHeaderPanel';
import { ChartLegendPanel } from 'components/chart/ChartLegendPanel';
import dimens from 'resources/dimens';
import { useCommonShips } from 'app/commonSlice';
import DiagnosisGraph from './DiagnosisGraph';
import { CustomScrollBars } from 'components/CustomScrollBars';
import config from 'resources/config';
import { dumpDiagnosisGraphData, dumpDiagnosisGraphSensors } from 'utils/dumpData';
import { Ship } from 'models/ships';

const RootDiv = styled('div')({
  width: '100%',
  height: '100%',
  display: 'flex',
  background: colors.chart.header.background,
});

const EmptyDiv = styled('div')({
  width: '100%',
  height: '100%',
  display: 'flex',
  background: colors.subDrawer.background,
});

const ContainerDiv = styled('div')({
  width: '100%',
  height: 'calc(100% - 32px)',
  display: 'flex',
  background: colors.chart.header.background,
});

const GraphDiv = styled('div')({});

export interface DiagnosisChartContainerProps {
  width: number;
  height: number;
}

export function DiagnosisChartContainer(props: DiagnosisChartContainerProps): JSX.Element {
  const { width, height } = props;
  AppLogger.debug('  CALL: DiagnosisChartContainer');
  const intl = useIntl();
  const dispatch = useDispatch();
  const ships = useCommonShips();
  const dataFetching = useDiagnosisDataFetching();
  const dataFetchError = useDiagnosisDataFetchError();
  const selectedDiagnosis = useDiagnosisSelectedDiagnosis();
  const currentDiagnosis = useDiagnosisCurrentDiagnosis();
  const chartSeriesList = useDiagnosisChartSeriesList();
  const chartLegendExpandUpdated = useDiagnosisChartLegendExpandUpdated();
  const diagnosisListItems = useDiagnosisListItems();

  let dataFormatId = 0;
  if (ships !== undefined && currentDiagnosis !== undefined) {
    for (let i = 0; i < ships.length; i++) {
      if (currentDiagnosis.shipId === ships[i].shipId) {
        for (let j = 0; j < ships[i].machines.length; j++) {
          if (currentDiagnosis.machineId === ships[i].machines[j].machineId) {
            dataFormatId = ships[i].machines[j].dataFormatId;
            break;
          }
        }
        if (dataFormatId !== 0) {
          break;
        }
      }
    }
  }

  const handleChartSeriesRangeChange = (
    chartId: string,
    chartSeries: ChartSeries,
    min: number,
    max: number
  ) => {
    dispatch(setChartSeriesRange({ chartSeries, min, max }));
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleExpandChanged = (chartId: string, sensorGroupName: string, expanded: boolean) => {
    dispatch(setChartLegendExpandUpdated(true));
  };

  /**
   * データ取得エラー
   */
  const handleDataFetchError = () => {
    if (dataFetchError != null) {
      if (dataFetchError.status === 404) {
        dispatch(clearDiagnosis());
      }
    }
    dispatch(setDataFetchError(undefined));
  };

  useEffect(() => {
    if (ships != null && selectedDiagnosis != null) {
      if (selectedDiagnosis.diagnosisId !== currentDiagnosis?.diagnosisId) {
        dispatch(fetchDiagnosisData(ships, selectedDiagnosis));
      }
    }
  }, [dispatch, ships, selectedDiagnosis, currentDiagnosis]);

  useEffect(() => {
    if (
      config.valueInspectionLog &&
      chartSeriesList != null &&
      ships != null &&
      currentDiagnosis != null
    ) {
      const ship = ships.find((x) => x.shipId === currentDiagnosis.shipId);
      dumpDiagnosisGraphSensors(chartSeriesList, ship);
      dumpDiagnosisGraphData(chartSeriesList, ship);
    }
  }, [chartSeriesList, ships, currentDiagnosis]);

  useEffect(() => {
    if (chartLegendExpandUpdated) {
      setTimeout(() => {
        dispatch(setChartLegendExpandUpdated(false));
      }, 800);
    }
  }, [dispatch, chartLegendExpandUpdated]);

  if (ships == null || currentDiagnosis == null) {
    return (
      <EmptyDiv>
        {dataFetchError != null && (
          <ApiErrorDialog error={dataFetchError} onClose={handleDataFetchError} />
        )}
      </EmptyDiv>
    );
  }

  const ship = ships.find((x) => x.shipId === currentDiagnosis.shipId);
  let baseShip: Ship | undefined = undefined;
  if (ship != null) {
    baseShip = {
      ...ship,
      machines: ship.machines.filter((x) => x.machineId === currentDiagnosis.machineId),
    };
  }

  const graphWidth =
    width < dimens.diagnosis.graphMinWidth
      ? dimens.diagnosis.graphMinWidth - dimens.diagnosis.legendMinWidth
      : width - dimens.chart.legend.width;
  const graphHeight =
    height < dimens.diagnosis.graphMinHeight ? dimens.diagnosis.graphMinHeight - 32 : height - 32;

  return (
    <RootDiv>
      <CustomScrollBars thumbColor={colors.scrollBar.thumb.dark}>
        {baseShip != null && chartSeriesList != null && diagnosisListItems != null && (
          <ContainerDiv
            sx={{
              width:
                width < dimens.diagnosis.graphMinWidth ? dimens.diagnosis.graphMinWidth : width,
              height: graphHeight,
            }}
          >
            <GraphDiv sx={{ width: graphWidth }}>
              <ChartHeaderPanel
                width={graphWidth}
                chartId={'9999'}
                title={intl.formatMessage({ id: msgId.details })}
                titleEditable={false}
              />
              <DiagnosisGraph
                chartId={'9999'}
                machineId={currentDiagnosis.machineId}
                dataFormatId={dataFormatId}
                chartSeriesList={chartSeriesList}
                diagnosisListItems={diagnosisListItems}
                width={graphWidth}
                height={graphHeight}
              />
            </GraphDiv>
            <ChartLegendPanel
              chartId={'9999'}
              height={graphHeight}
              width={dimens.diagnosis.legendMinWidth}
              baseShip={baseShip}
              chartSeriesList={chartSeriesList}
              chartSeriesChanged={false}
              canRangeInput={false}
              onChangeRange={handleChartSeriesRangeChange}
              onExpandChanged={handleExpandChanged}
            />
          </ContainerDiv>
        )}
        <FadeLoading loading={dataFetching} />
        {dataFetchError != null && (
          <ApiErrorDialog error={dataFetchError} onClose={handleDataFetchError} />
        )}
      </CustomScrollBars>
    </RootDiv>
  );
}
