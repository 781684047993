import React from 'react';
import { Polyline } from '@react-google-maps/api';
import colors from 'resources/colors';
import { SensorData, CommonSensorData, SensorThinnedHeaderData } from 'models/data';
import { usePastDataMapReset } from './mapSlice';
import config from 'resources/config';

interface PathInfo {
  option: google.maps.PolylineOptions;
  path: google.maps.LatLngLiteral[];
}

interface VesSensorData {
  vesLatC1: SensorData;
  vesLonC1: SensorData;
  vesSpdSOGC1: SensorData | undefined;
}

interface ShipWavePathProps {
  commonSensorData: CommonSensorData | undefined;
  sensorThinnedHeaderDataList: SensorThinnedHeaderData[] | undefined;
  isThinned: boolean;
}

export function ShipWavePath(props: ShipWavePathProps): JSX.Element {
  const { commonSensorData, sensorThinnedHeaderDataList, isThinned } = props;
  const reset = usePastDataMapReset();
  const pathInfoList: PathInfo[] = [];
  const vesSensorDataList: VesSensorData[] = [];

  if (isThinned) {
    sensorThinnedHeaderDataList?.forEach((thinnedHeaderData) => {
      if (
        thinnedHeaderData != null &&
        thinnedHeaderData.dataFormatId !== 402 &&
        thinnedHeaderData.sensors.vesLatC1 != null &&
        thinnedHeaderData.sensors.vesLonC1 != null
      ) {
        vesSensorDataList.push({
          vesLatC1: thinnedHeaderData.sensors.vesLatC1,
          vesLonC1: thinnedHeaderData.sensors.vesLonC1,
          vesSpdSOGC1: thinnedHeaderData.sensors.vesSpdSOGC1,
        });
      } else if (
        thinnedHeaderData != null &&
        thinnedHeaderData.dataFormatId === 402 &&
        thinnedHeaderData.sensors.vesLat != null &&
        thinnedHeaderData.sensors.vesLon != null
      ) {
        vesSensorDataList.push({
          vesLatC1: thinnedHeaderData.sensors.vesLat,
          vesLonC1: thinnedHeaderData.sensors.vesLon,
          vesSpdSOGC1: thinnedHeaderData.sensors.vesSog,
        });
      }
    });
  } else {
    if (
      commonSensorData != null &&
      commonSensorData.dataFormatId !== 402 &&
      commonSensorData.sensors.vesLatC1 != null &&
      commonSensorData.sensors.vesLonC1 != null
    ) {
      vesSensorDataList.push({
        vesLatC1: commonSensorData.sensors.vesLatC1,
        vesLonC1: commonSensorData.sensors.vesLonC1,
        vesSpdSOGC1: commonSensorData.sensors.vesSpdSOGC1,
      });
    } else if (
      commonSensorData != null &&
      commonSensorData.dataFormatId === 402 &&
      commonSensorData.sensors.vesLat != null &&
      commonSensorData.sensors.vesLon != null
    ) {
      vesSensorDataList.push({
        vesLatC1: commonSensorData.sensors.vesLat,
        vesLonC1: commonSensorData.sensors.vesLon,
        vesSpdSOGC1: commonSensorData.sensors.vesSog,
      });
    }
  }

  vesSensorDataList.forEach((vesSensorData) => {
    const vesLatC1 = vesSensorData.vesLatC1;
    const vesLonC1 = vesSensorData.vesLonC1;
    const vesSpdSOGC1 = vesSensorData.vesSpdSOGC1;

    let array: google.maps.LatLngLiteral[] = [];
    let prevLat = NaN;
    let prevLon = NaN;
    let prevSpeed = NaN;
    for (let i = 0; i < vesLatC1.data.length; i++) {
      // 描画点が多いため、100個に1個だけ描画する
      // if (i % 100 !== 0) {
      //   continue;
      // }
      if (prevLat !== vesLatC1.data[i] || vesLonC1.data[i] !== prevLon) {
        if (isNaN(vesLatC1.data[i]) && isNaN(vesLonC1.data[i])) {
          // 一旦ここまでを追加してリセット
          if (array.length > 0) {
            pathInfoList.push({
              path: array,
              option: {
                strokeColor: colors.map.shipSpeed[prevSpeed],
                strokeOpacity: 1,
                strokeWeight: 3,
              },
            });
          }
          array = [];
          prevLat = NaN;
          prevLon = NaN;
          prevSpeed = NaN;
          continue;
        }
        if (!isNaN(vesLatC1.data[i]) && !isNaN(vesLonC1.data[i])) {
          const adbLat = Math.abs(vesLatC1.data[i]);
          const absLon = Math.abs(vesLonC1.data[i]);
          if (adbLat < 1 && absLon < 1) {
            continue;
          }
        }

        let speed = 10;
        if (vesSpdSOGC1 != null) {
          speed = Math.round(vesSpdSOGC1.data[i]);
          if (speed > 20) {
            speed = 20;
          } else if (speed < 0) {
            speed = 0;
          }
        }
        array.push({ lat: vesLatC1.data[i], lng: vesLonC1.data[i] });

        if (isNaN(prevSpeed)) {
          prevSpeed = speed;
        } else if (prevSpeed !== speed) {
          if (array.length > 0) {
            pathInfoList.push({
              path: array,
              option: {
                strokeColor: colors.map.shipSpeed[prevSpeed],
                strokeOpacity: 1,
                strokeWeight: 5,
              },
            });
          }

          prevSpeed = speed;
          array = [];
          array.push({ lat: vesLatC1.data[i], lng: vesLonC1.data[i] });
        }

        prevLat = vesLatC1.data[i];
        prevLon = vesLonC1.data[i];
      }
    }

    if (array.length > 0) {
      pathInfoList.push({
        path: array,
        option: {
          strokeColor: colors.map.shipSpeed[prevSpeed],
          strokeOpacity: 1,
          strokeWeight: 3,
        },
      });
    }
  });

  if (!reset && pathInfoList.length > 0) {
    return (
      <React.Fragment>
        {pathInfoList.map((pathInfo, index) => (
          <Polyline key={index} path={pathInfo.path} options={pathInfo.option} />
        ))}
      </React.Fragment>
    );
  } else {
    return <React.Fragment />;
  }
}
