import React, { useState } from 'react';
import { InputAdornment, styled, SxProps, TextField } from '@mui/material';
import colors from 'resources/colors';
import { VisibilityIconButton } from './VisibilityIconButton';
import { useIntl } from 'react-intl';
import msgId from 'resources/intl';

const StyledTextField = styled(TextField)({
  '&.MuiOutlinedInput-notchedOutline': {
    borderColor: colors.accent,
    '&:hover': {
      borderColor: colors.accent,
    },
  },
});

interface PasswordTextFieldProps {
  id: string;
  label: string;
  value: string;
  fullWidth: boolean;
  sx?: SxProps;
  autoComplete?: string;
  error?: boolean;
  helperText?: string;
  placeholder?: string;
  required?: boolean;
  shrink?: boolean | undefined;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyPress?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
}

export function PasswordTextField(props: PasswordTextFieldProps): JSX.Element {
  const {
    id,
    label,
    value,
    fullWidth,
    sx,
    autoComplete,
    error,
    helperText,
    placeholder,
    required,
    shrink,
    onChange,
    onKeyPress,
  } = props;
  const [showPassword, setShowPassword] = useState(false);
  const intl = useIntl();

  let _label;
  if (required === true) {
    _label = label + ' [' + intl.formatMessage({ id: msgId.required }) + ']';
  } else {
    _label = label;
  }

  return (
    <StyledTextField
      label={_label}
      id={id}
      name={id}
      value={value}
      fullWidth={fullWidth}
      sx={sx}
      size="small"
      type={showPassword ? 'text' : 'password'}
      autoComplete={autoComplete}
      error={error}
      helperText={helperText}
      placeholder={placeholder}
      onChange={onChange}
      onKeyPress={onKeyPress}
      InputLabelProps={{
        shrink: shrink,
      }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end" sx={{ padding: 0 }}>
            <VisibilityIconButton
              visibility={showPassword}
              onClick={() => setShowPassword(!showPassword)}
            />
          </InputAdornment>
        ),
      }}
    />
  );
}

PasswordTextField.defaultProps = {
  fullWidth: false,
};
