import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import colors from 'resources/colors';
import { AnalysisData } from 'models/analysis';
import { ChartHeaderPanel } from 'components/chart/ChartHeaderPanel';
import { LegendGroup, ChartLegendList } from '../common/ChartLegendList';
import { RexPropulsionOperationChart } from './RexPropulsionOperationChart';
import { ChartContainer, ChartContentsDiv, ChartDivider } from '../common/ChartContainer';
import msgId from 'resources/intl';
import dimens from 'resources/dimens';
import { useDispatch } from 'react-redux';
import { ConfirmedSearchResultItem } from 'models/search';
import {
  setRexPropulsionOperationExpanded,
  useAnalysisRexPropulsionOperationExpanded,
} from '../../analysisSlice';

interface RexPropulsionOperationContainerProps {
  chartId: string;
  confirmedSearchResultItem: ConfirmedSearchResultItem;
  analysisData: AnalysisData;
}

export function RexPropulsionOperationContainer(
  props: RexPropulsionOperationContainerProps
): JSX.Element {
  const { chartId, confirmedSearchResultItem, analysisData } = props;
  const intl = useIntl();
  const dispatch = useDispatch();
  const expanded = useAnalysisRexPropulsionOperationExpanded();

  /**
   * CSV出力
   */
  const handleCsvOutput = () => {
    // noop
  };

  /**
   * 開閉状態変更
   * @param expanded 開閉状態
   */
  const handleExpandChanged = (expanded: boolean) => {
    dispatch(setRexPropulsionOperationExpanded(expanded));
  };

  /**
   * 凡例リスト
   */
  let legendGroups: LegendGroup[];
  if (analysisData.dataFormatId !== 402) {
    legendGroups = [
      {
        groupName: analysisData.shipName + ' - ' + analysisData.machineName,
        legends: [
          {
            title: intl.formatMessage({ id: msgId.AnalysisRexStrAngFB }),
            color: colors.chart.lines[0],
            iconType: 'square',
          },
          {
            title: intl.formatMessage({ id: msgId.AnalysisRexCPPBladeAngFB }),
            color: colors.chart.lines[1],
            iconType: 'square',
          },
        ],
      },
    ];
  } else {
    legendGroups = [
      {
        groupName: analysisData.shipName + ' - ' + analysisData.machineName,
        legends: [
          {
            title: intl.formatMessage({ id: msgId.AnalysisRexStrAngFB }),
            color: colors.chart.lines[0],
            iconType: 'square',
          },
        ],
      },
    ];
  }

  const analysisName =
    intl.formatMessage({
      id: 'AnalysisSection' + analysisData.data.rexPropulsionOperation?.analysisSectionName,
    }) +
    ' : ' +
    intl.formatMessage({
      id: 'AnalysisMenu' + analysisData.data.rexPropulsionOperation?.analysisMenuName,
    });

  const chart = useMemo(
    () => (
      <RexPropulsionOperationChart
        sx={{ width: `calc(100% - ${dimens.chart.legend.width}px - 1px)` }}
        analysisData={analysisData}
      />
    ),
    [analysisData]
  );

  return (
    <ChartContainer
      chartId={chartId}
      shipName={analysisData.shipName}
      machineName={analysisData.machineName}
      analysisName={analysisName}
      confirmedSearchResultItem={confirmedSearchResultItem}
      title={analysisName}
      expanded={expanded}
      onExpandChanged={handleExpandChanged}
    >
      <ChartHeaderPanel
        chartId={chartId}
        sx={{ paddingRight: `${dimens.chart.legend.width}px` }}
        title={analysisName}
        titleEditable={false}
        csvOutputEnabled={false}
        csvOutputLocked={true}
      />
      <ChartContentsDiv>
        {chart}
        <ChartDivider orientation="vertical" flexItem />
        <ChartLegendList legendGroups={legendGroups} />
      </ChartContentsDiv>
    </ChartContainer>
  );
}
