import React from 'react';
import { theme } from 'resources/theme';
import colors from 'resources/colors';
import { Box, Input, styled, SxProps } from '@mui/material';
import { Search } from '@mui/icons-material';

const SearchBox = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  border: `2px solid ${colors.border}`,
  borderRadius: '100px',
  width: '100%',
  height: 28,
  background: theme.palette.background.default,
});

const SearchIcon = styled(Search)({
  paddingLeft: theme.spacing(0.5),
  width: '24px',
  height: '24px',
  color: theme.palette.text.disabled,
});

const StyledInput = styled(Input)({
  paddingRight: theme.spacing(1),
  paddingLeft: theme.spacing(0.5),
  width: '100%',
});

const inputProps = {
  fontSize: '0.8rem',
};

interface SearchBarProps {
  sx?: SxProps;
  placeholder?: string;
  defaultValue?: string;
  value?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
}

export function SearchBar(props: SearchBarProps): JSX.Element {
  const { sx, placeholder, defaultValue, value, onChange } = props;

  return (
    <Box sx={sx}>
      <SearchBox>
        <SearchIcon />
        <StyledInput
          type="search"
          placeholder={placeholder}
          disableUnderline
          defaultValue={defaultValue}
          value={value}
          inputProps={inputProps}
          onChange={onChange}
        />
      </SearchBox>
    </Box>
  );
}
