import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getCommonSensorDataAsync, getShipWaveDataAsync } from 'api/maricoApiData';
import { getMarineWeatherBundleAsync } from 'api/maricoApiMarineWeather';
import {
  getCommonSensorThinnedDataListAsync,
  getThinnedDataDatesListAsync,
  getThinnedHeaderDataAsync,
} from 'api/maricoApiThinnedData';
import { RootState } from 'app/rootReducer';
import { AppThunk } from 'app/store';
import {
  CommonSensorData,
  SensorThinnedDataDates,
  SensorThinnedHeaderData,
  ShipWaveData,
} from 'models/data';
import { ErrorResult, getErrorResult } from 'models/error';
import { MarineWeatherBundle2 } from 'models/marineWeather';
import { ConfirmedSearchResultItem, ThinnedSearchConditions } from 'models/search';
import { Machine, Ship } from 'models/ships';
import config from 'resources/config';
import {
  dumpPastDataDaySelectlMapData,
  dumpPastDataDaySelectMimicData,
  dumpPastDataSearchConditionMapData,
  dumpPastDataSearchConditionMimicData,
} from 'utils/dumpData';
import { useSelector } from 'react-redux';
import AppLogger from 'utils/AppLogger';
import { doFilterShips } from 'utils/misc';

/**
 * PastDataState インターフェース
 */
interface PastDataState {
  /** 船舶リスト */
  ships: Ship[] | undefined;
  /** 船舶リスト */
  thinnedShips: Ship[] | undefined;
  /** フィルター済み船舶リスト */
  filteredShips: Ship[] | undefined;
  /** フィルター済み船舶リスト */
  thinnedFilteredShips: Ship[] | undefined;
  /** フィルター船舶名 */
  filterShipName: string;
  /** 船舶開閉状態リスト */
  contractedShipIds: string[];
  /** データ取得中状態 */
  fetching: boolean;
  /** 間引きセンサーデータが存在する日付リスト取得中状態 */
  thinnedDataDatesListFetching: boolean;
  /** センサーヘッダー間引きデータ取得中状態 */
  thinnedHeaderDataFetching: boolean;
  /** データ取得エラー */
  fetchError: ErrorResult | undefined;
  /** 共通センサーデータ */
  commonSensorData: CommonSensorData | undefined;
  /** 船舶航跡データ */
  shipWaveData: ShipWaveData | undefined;
  /** 間引きセンサーデータが存在する日付リスト */
  sensorThinnedDataDatesList: SensorThinnedDataDates[] | undefined;
  /** センサーヘッダー間引きデータリスト */
  sensorThinnedHeaderData: SensorThinnedHeaderData | undefined;
  /** 共通センサー間引きデータリスト(初期表示用) */
  commonSensorThinnedDataList: CommonSensorData[] | undefined;
  /** 共通センサー間引きデータ(日付選択間引き表示用) */
  currentThinnedConditions: ThinnedSearchConditions | undefined;
  /** 気象海象データバンドル */
  marineWeatherBundles: { [key: string]: MarineWeatherBundle2 };
  /** トレンドグラフ表示のみかどうか */
  trendOnly: boolean;
}

/**
 * PastDataState の初期状態
 */
const initialState: PastDataState = {
  ships: undefined,
  thinnedShips: undefined,
  filteredShips: undefined,
  thinnedFilteredShips: undefined,
  filterShipName: '',
  contractedShipIds: [],
  fetching: false,
  thinnedDataDatesListFetching: false,
  thinnedHeaderDataFetching: false,
  fetchError: undefined,
  commonSensorData: undefined,
  shipWaveData: undefined,
  sensorThinnedDataDatesList: undefined,
  sensorThinnedHeaderData: undefined,
  commonSensorThinnedDataList: undefined,
  currentThinnedConditions: undefined,
  marineWeatherBundles: {},
  trendOnly: false,
};

export const pastData = createSlice({
  name: 'shipStatePastData',
  initialState,
  reducers: {
    /**
     * 選択済み機械から船舶リストを設定する。
     */
    setShips: (
      state,
      {
        payload,
      }: PayloadAction<{
        commonShips: Ship[];
        confirmedSearchResultItem: ConfirmedSearchResultItem;
      }>
    ) => {
      const commonShips = payload.commonShips;
      const confirmedSearchResultItem = payload.confirmedSearchResultItem;
      const ships: Ship[] = [];
      commonShips.forEach((commonShip) => {
        const machines = commonShip.machines.filter(
          (x) => x.machineId === confirmedSearchResultItem.machine.machineId
        );
        if (machines.length > 0) {
          ships.push({
            shipId: commonShip.shipId,
            name: commonShip.name,
            propulsionSerialNumbers: commonShip.propulsionSerialNumbers,
            machines: machines.map((commonMachine) => {
              return {
                machineId: commonMachine.machineId,
                name: commonMachine.name,
                serialNumbers: commonMachine.serialNumbers,
                dataFormatId: commonMachine.dataFormatId,
                sensorGroups: commonMachine.sensorGroups.filter((x) =>
                  x.sensors.find(
                    (y) => y.displayLowerLimit != null && y.displayUpperLimit != null)
                ),
                checked: false,
              };
            }),
            createdAt: commonShip.createdAt,
            updatedAt: commonShip.updatedAt,
            checked: false,
            propulsionSerialNumbersText: commonShip.propulsionSerialNumbers.join(', '),
            machineNamesText: commonShip.machines.map((x) => x.name).join(', '),
          });
        }
      });

      state.ships = ships;
      state.filteredShips = doFilterShips(state.ships, state.filterShipName);
      state.contractedShipIds = [];
    },
    /**
     * 選択済み機械から船舶リストを設定する。
     */
    setThinnedShips: (state, { payload }: PayloadAction<Ship[]>) => {
      const commonShips = payload;
      const ships: Ship[] = [];
      commonShips.forEach((commonShip) => {
        const machines = commonShip.machines;
        if (machines.length > 0) {
          ships.push({
            shipId: commonShip.shipId,
            name: commonShip.name,
            propulsionSerialNumbers: commonShip.propulsionSerialNumbers,
            machines: machines.map((commonMachine) => {
              return {
                machineId: commonMachine.machineId,
                name: commonMachine.name,
                serialNumbers: commonMachine.serialNumbers,
                dataFormatId: commonMachine.dataFormatId,
                sensorGroups: commonMachine.sensorGroups.filter((x) =>
                  x.sensors.find(
                    (y) => y.displayLowerLimit != null && y.displayUpperLimit != null)
                ),
                checked: false,
              };
            }),
            createdAt: commonShip.createdAt,
            updatedAt: commonShip.updatedAt,
            checked: false,
            propulsionSerialNumbersText: commonShip.propulsionSerialNumbers.join(', '),
            machineNamesText: commonShip.machines.map((x) => x.name).join(', '),
          });
        }
      });

      state.thinnedShips = ships;
      state.thinnedFilteredShips = doFilterShips(state.thinnedShips, state.filterShipName);
      state.contractedShipIds = [];
      if (state.currentThinnedConditions === undefined && state.thinnedShips.length > 0) {
        state.currentThinnedConditions = {
          ship: state.thinnedShips[0],
          machineId: state.thinnedShips[0].machines[0].machineId,
          startDate: '',
          endDate: '',
        };
      }
      let fined = false;
      state.thinnedShips.forEach((ship) => {
        if (
          state.currentThinnedConditions &&
          ship.shipId === state.currentThinnedConditions.ship.shipId
        ) {
          fined = true;
        }
      });
      if (!fined && state.thinnedShips.length > 0 && state.currentThinnedConditions) {
        state.currentThinnedConditions.ship = state.thinnedShips[0];
        state.currentThinnedConditions.machineId = state.thinnedShips[0].machines[0].machineId;
        state.currentThinnedConditions.startDate = '';
        state.currentThinnedConditions.endDate = '';
      }
    },
    setCurrentThinnedConditions: (
      state,
      { payload }: PayloadAction<ThinnedSearchConditions | undefined>
    ) => {
      state.currentThinnedConditions = payload;
    },
    /**
     * フィルター船舶名を設定する。
     */
    setFilterShipName: (state, { payload }: PayloadAction<string>) => {
      state.filterShipName = payload;
      if (state.ships != null) {
        state.filteredShips = doFilterShips(state.ships, state.filterShipName);
      }
      if (state.thinnedShips != null) {
        state.thinnedFilteredShips = doFilterShips(state.thinnedShips, state.filterShipName);
      }
    },
    /**
     * 船舶リストをクリアする。
     */
    clearShips: (state) => {
      state.ships = undefined;
      state.filterShipName = '';
      state.filteredShips = undefined;
      state.contractedShipIds = [];
    },
    /**
     * 船舶リストの指定した船舶を開閉状態を変更する。
     */
    setContractedShipId: (
      state,
      { payload }: PayloadAction<{ shipId: string; expanded: boolean }>
    ) => {
      if (payload.expanded) {
        state.contractedShipIds = state.contractedShipIds.filter((x) => x !== payload.shipId);
      } else {
        state.contractedShipIds.push(payload.shipId);
      }
    },
    /**
     * 取得の開始を設定する。
     */
    startFetch: (state, { payload }: PayloadAction<boolean>) => {
      state.fetching = payload;
    },
    /**
     * 間引きセンサーデータが存在する日付リスト取得の開始を設定する。
     */
    startThinnedDataDatesListFetch: (state, { payload }: PayloadAction<boolean>) => {
      state.thinnedDataDatesListFetching = payload;
    },
    /**
     * センサーヘッダー間引きデータ取得の開始を設定する。
     */
    startThinnedHeaderDataFetch: (state, { payload }: PayloadAction<boolean>) => {
      state.thinnedHeaderDataFetching = payload;
    },
    /**
     * 共通センサーデータを設定する。
     */
    setCommonSensorData: (state, { payload }: PayloadAction<CommonSensorData | undefined>) => {
      state.commonSensorData = payload;
    },
    /**
     * 間引きセンサーデータが存在する日付リストを設定する。
     */
    setSensorThinnedDataDatesList: (
      state,
      { payload }: PayloadAction<SensorThinnedDataDates[]>
    ) => {
      state.sensorThinnedDataDatesList = payload;
      if (
        state.currentThinnedConditions === undefined &&
        state.sensorThinnedDataDatesList.length > 0 &&
        state.thinnedShips &&
        state.thinnedShips.length > 0
      ) {
        const ship = state.thinnedShips[0];
        state.currentThinnedConditions = {
          ship: ship,
          machineId: state.sensorThinnedDataDatesList.filter(
            (list) => list.machineId === ship.machines[0].machineId
          )[0].machineId,
          startDate: '',
          endDate: '',
        };
      } else if (state.currentThinnedConditions && state.sensorThinnedDataDatesList) {
        const machineId = state.currentThinnedConditions.machineId;
        if (
          state.sensorThinnedDataDatesList.filter((list) => list.machineId === machineId).length ===
          0
        ) {
          if (state.thinnedShips && state.thinnedShips.length > 0) {
            const ship = state.thinnedShips[0];
            const machines = ship.machines;
            if (state.sensorThinnedDataDatesList.length > 0) {
              state.currentThinnedConditions = {
                ship: ship,
                machineId: state.sensorThinnedDataDatesList.filter(
                  (list) => list.machineId === machines[0].machineId
                )[0].machineId,
                startDate: '',
                endDate: '',
              };
            } else {
              state.currentThinnedConditions = {
                ship: ship,
                machineId: machines[0].machineId,
                startDate: '',
                endDate: '',
              };
            }
          }
        }
      }
    },
    /**
     * センサーヘッダー間引きデータを設定する。
     */
    setSensorThinnedHeaderData: (
      state,
      { payload }: PayloadAction<SensorThinnedHeaderData | undefined>
    ) => {
      state.sensorThinnedHeaderData = payload;
    },
    /**
     * 共通センサー間引きデータを設定する。
     */
    setCommonSensorThinnedDataList: (
      state,
      { payload }: PayloadAction<CommonSensorData[] | undefined>
    ) => {
      state.commonSensorThinnedDataList = payload;
    },
    /**
     * 気象海象データとカラーマップを設定する。
     */
    setMarineWeatherBundle: (
      state,
      { payload }: PayloadAction<{ key: string; value: MarineWeatherBundle2 }>
    ) => {
      const bundles = { ...state.marineWeatherBundles };
      bundles[payload.key] = payload.value;
      const keys = Object.keys(bundles);
      if (keys.length > 50) {
        AppLogger.debug('len=' + keys.length);
        delete bundles[keys[0]];
      }
      state.marineWeatherBundles = bundles;
    },
    /**
     * 取得エラーを設定する。
     */
    setFetchError: (state, { payload }: PayloadAction<ErrorResult | undefined>) => {
      state.fetchError = payload;
      state.fetching = false;
      state.thinnedDataDatesListFetching = false;
      state.thinnedHeaderDataFetching = false;
    },
    /**
     * 共通センサーデータを設定する。
     */
    setShipWaveData: (state, { payload }: PayloadAction<ShipWaveData | undefined>) => {
      state.shipWaveData = payload;
    },
    /**
     * 間引きデータの船(機械ID)条件を設定する。
     */
    setCurrentConditionMachineId: (state, { payload }: PayloadAction<number>) => {
      if (state.thinnedShips && state.currentThinnedConditions) {
        const ship = state.thinnedShips.filter(
          (ship) => ship.machines.filter((machine) => machine.machineId === payload).length > 0
        )[0];
        state.currentThinnedConditions = {
          ship: ship,
          machineId: payload,
          startDate: state.currentThinnedConditions.startDate,
          endDate: state.currentThinnedConditions.endDate,
        };
      }
    },
    setTrendOnly: (state, { payload }: PayloadAction<boolean>) => {
      state.trendOnly = payload;
    },
    clearMarineWeatherBundles: (state) => {
      state.marineWeatherBundles = {};
    },
    /**
     * shipStatePastDataをクリアする。
     */
    clearShipStatePastData: (state) => {
      state.ships = initialState.ships;
      state.thinnedShips = initialState.thinnedShips;
      state.filteredShips = initialState.filteredShips;
      state.thinnedFilteredShips = initialState.thinnedFilteredShips;
      state.filterShipName = initialState.filterShipName;
      state.contractedShipIds = initialState.contractedShipIds;
      state.fetching = initialState.fetching;
      state.trendOnly = initialState.trendOnly;
      state.thinnedDataDatesListFetching = initialState.thinnedDataDatesListFetching;
      state.thinnedHeaderDataFetching = initialState.thinnedHeaderDataFetching;
      state.fetchError = initialState.fetchError;
      state.commonSensorData = initialState.commonSensorData;
      state.shipWaveData = initialState.shipWaveData;
      state.sensorThinnedDataDatesList = initialState.sensorThinnedDataDatesList;
      state.sensorThinnedHeaderData = initialState.sensorThinnedHeaderData;
      state.commonSensorThinnedDataList = initialState.commonSensorThinnedDataList;
      state.currentThinnedConditions = initialState.currentThinnedConditions;
      state.marineWeatherBundles = {};
    },
  },
});

export const {
  setShips,
  setFilterShipName,
  clearShips,
  setShipWaveData,
  setContractedShipId,
  setFetchError,
  setThinnedShips,
  setCommonSensorData,
  setSensorThinnedHeaderData,
  setCurrentThinnedConditions,
  setCurrentConditionMachineId,
  setCommonSensorThinnedDataList,
  setMarineWeatherBundle,
  setTrendOnly,
  clearMarineWeatherBundles,
  clearShipStatePastData,
} = pastData.actions;

/**
 * 共通センサーデータを取得する。
 * @param confirmedSearchResultItem 確定検索結果アイテム
 */
export const fetchCommonSensorData =
  (confirmedSearchResultItem: ConfirmedSearchResultItem): AppThunk =>
    async (dispatch) => {
      dispatch(pastData.actions.startFetch(true));
      try {
        const commonSensorData = await getCommonSensorDataAsync(confirmedSearchResultItem);
        dispatch(pastData.actions.setCommonSensorData(commonSensorData));
        const shipWaveData = await getShipWaveDataAsync(confirmedSearchResultItem);
        dispatch(pastData.actions.setShipWaveData(shipWaveData));
        dispatch(pastData.actions.startFetch(false));

        if (config.valueInspectionLog) {
          dumpPastDataSearchConditionMapData(commonSensorData);
          dumpPastDataSearchConditionMimicData(commonSensorData, confirmedSearchResultItem.machine);
        }
      } catch (error) {
        dispatch(pastData.actions.setFetchError(getErrorResult(error)));
      }
    };

/**
 * 間引きセンサーデータが存在する日付リストを取得する。
 * @param ships 船舶リスト
 */
export const fetchSensorThinnedDataDatesList =
  (ships: Ship[]): AppThunk =>
    async (dispatch) => {
      dispatch(pastData.actions.startThinnedDataDatesListFetch(true));
      try {
        const machineIds: number[] = [];
        ships.forEach((x) => {
          x.machines.forEach((y) => {
            machineIds.push(y.machineId);
          });
        });
        const sensorThinnedDataDatesList = await getThinnedDataDatesListAsync(machineIds);
        dispatch(pastData.actions.setSensorThinnedDataDatesList(sensorThinnedDataDatesList));
        dispatch(pastData.actions.startThinnedDataDatesListFetch(false));
      } catch (error) {
        dispatch(pastData.actions.setFetchError(getErrorResult(error)));
      }
    };

/**
 * 指定した機械と日付リストのセンサー間引きヘッダーデータリストを取得する。
 * @param machines 機械リスト
 * @param date 日付リスト
 */
export const fetchSensorThinnedHeaderData =
  (machine: Machine, date: string[]): AppThunk =>
    async (dispatch) => {
      dispatch(pastData.actions.startThinnedHeaderDataFetch(true));
      try {
        const thinnedHeaderData = await getThinnedHeaderDataAsync(machine, date);
        dispatch(pastData.actions.setSensorThinnedHeaderData(thinnedHeaderData));
        dispatch(pastData.actions.startThinnedHeaderDataFetch(false));
      } catch (error) {
        dispatch(pastData.actions.setFetchError(getErrorResult(error)));
      }
    };

/**
 * 共通センサー間引きデータを取得する。
 * メインの機械IDのみ共通センサーデータが含まれる。
 * メイン以外の機械は地図表示用データのみ含まれる。
 * @param machines 機械リスト
 * @param mainMachineId メインの機械ID
 * @param date 日付
 */
export const fetchCommonSensorThinnedDataList =
  (machines: Machine[], mainMachineId: number, date: string): AppThunk =>
    async (dispatch) => {
      dispatch(pastData.actions.startFetch(true));
      try {
        const commonSensorThinnedDataList = await getCommonSensorThinnedDataListAsync(
          machines,
          mainMachineId,
          date
        );
        dispatch(pastData.actions.setCommonSensorThinnedDataList(commonSensorThinnedDataList));
        dispatch(pastData.actions.startFetch(false));

        if (config.valueInspectionLog) {
          dumpPastDataDaySelectlMapData(commonSensorThinnedDataList, mainMachineId);
          dumpPastDataDaySelectMimicData(commonSensorThinnedDataList, machines, mainMachineId);
        }
      } catch (error) {
        dispatch(pastData.actions.setFetchError(getErrorResult(error)));
      }
    };

/**
 * 気象海象データとカラーマップを取得する。
 * @param date 日付
 * @param hour 時
 */
export const fetchMarineWeatherBundle =
  (keys: string[]): AppThunk =>
    async (dispatch) => {
      const results = await Promise.all(
        keys.map(async (key) => {
          try {
            return await getMarineWeatherBundleAsync(key);
          } catch {
            return undefined;
          }
        })
      );

      for (let i = 0; i < results.length; i++) {
        const result = results[i];
        if (result != null) {
          dispatch(pastData.actions.setMarineWeatherBundle({ key: keys[i], value: result }));
        }
      }
    };

const pastDataState = (state: RootState) => state.pastData;
const selectFilteredShips = createSelector(pastDataState, (x) => x.filteredShips);
const selectThinnedFilteredShips = createSelector(pastDataState, (x) => x.thinnedFilteredShips);
const selectFilterShipName = createSelector(pastDataState, (x) => x.filterShipName);
const selectContractedShipIds = createSelector(pastDataState, (x) => x.contractedShipIds);
const selectFetching = createSelector(pastDataState, (x) => x.fetching);
const selectThinnedDataDatesListFetching = createSelector(
  pastDataState,
  (x) => x.thinnedDataDatesListFetching
);
const selectThinnedHeaderDataFetching = createSelector(
  pastDataState,
  (x) => x.thinnedHeaderDataFetching
);
const selectFetchError = createSelector(pastDataState, (x) => x.fetchError);
const selectCommonSensorData = createSelector(pastDataState, (x) => x.commonSensorData);
const selectShipWaveData = createSelector(pastDataState, (x) => x.shipWaveData);
const selectSensorThinnedDataDatesList = createSelector(
  pastDataState,
  (x) => x.sensorThinnedDataDatesList
);
const selectSensorThinnedHeaderData = createSelector(
  pastDataState,
  (x) => x.sensorThinnedHeaderData
);
const selectCommonSensorThinnedDataList = createSelector(
  pastDataState,
  (x) => x.commonSensorThinnedDataList
);
const selectCurrentThinnedConditions = createSelector(
  pastDataState,
  (x) => x.currentThinnedConditions
);
const selectShips = createSelector(pastDataState, (x) => x.ships);
const selectThinnedShips = createSelector(pastDataState, (x) => x.thinnedShips);
const selectMarineWeatherBundles = createSelector(pastDataState, (x) => x.marineWeatherBundles);
const selectTrendOnly = createSelector(pastDataState, (x) => x.trendOnly);

export const usePastDataFilteredShips = () => useSelector(selectFilteredShips);
export const usePastDataThinnedFilteredShips = () => useSelector(selectThinnedFilteredShips);
export const usePastDataFilterShipName = () => useSelector(selectFilterShipName);
export const usePastDataContractedShipIds = () => useSelector(selectContractedShipIds);
export const usePastDataFetching = () => useSelector(selectFetching);
export const usePastThinnedDataDatesListFetching = () =>
  useSelector(selectThinnedDataDatesListFetching);
export const usePastThinnedHeaderDataFetching = () => useSelector(selectThinnedHeaderDataFetching);
export const usePastDataFetchError = () => useSelector(selectFetchError);
export const usePastDataCommonSensorData = () => useSelector(selectCommonSensorData);
export const usePastDataShipWaveData = () => useSelector(selectShipWaveData);
export const usePastDataSensorThinnedDataDatesList = () =>
  useSelector(selectSensorThinnedDataDatesList);
export const usePastDataSensorThinnedHeaderData = () => useSelector(selectSensorThinnedHeaderData);
export const usePastDataCommonSensorThinnedDataList = () =>
  useSelector(selectCommonSensorThinnedDataList);
export const usePastDataCurrentThinnedConditions = () =>
  useSelector(selectCurrentThinnedConditions);
export const usePastDataShips = () => useSelector(selectShips);
export const usePastDataThinnedShips = () => useSelector(selectThinnedShips);
export const usePastDataMarineWeatherBundles = () => useSelector(selectMarineWeatherBundles);
export const usePastDataTrendOnly = () => useSelector(selectTrendOnly);

export default pastData.reducer;
