import React from 'react';
import { useIntl } from 'react-intl';
import msgId from 'resources/intl';
import Plotly, {
  Layout,
  LayoutAxis,
  PlotType,
  Shape,
  PlotMouseEvent,
  Annotations,
} from 'plotly.js';
import createPlotlyComponent from 'react-plotly.js/factory';
import colors from 'resources/colors';
import { AnalysisData, StatisticalValueType, AnalysisStatisticalValue } from 'models/analysis';
import { Box, SxProps } from '@mui/material';
import { roundValueToString } from 'utils/misc';

const Plot = createPlotlyComponent(Plotly);
const testDataLineColor = colors.chart.analysis.megLoad.testDataLineColor;
const axisFontColor = colors.chart.analysis.megLoad.axisFontColor;
const gridColor = colors.chart.analysis.megLoad.gridColor;
const y1TickColor = colors.chart.analysis.megLoad.y1TickColor;
const y1TickFontColor = colors.chart.analysis.megLoad.y1TickFontColor;

const plotProps: React.CSSProperties = {
  width: '100%',
  height: '100%',
  background: colors.chart.plotarea.background,
};

interface MegLoadChartProps {
  sx?: SxProps;
  analysisData: AnalysisData;
  statisticalValueType: StatisticalValueType;
  height: number;
}

export function MegLoadChart(props: MegLoadChartProps): JSX.Element {
  const intl = useIntl();
  const { sx, analysisData, statisticalValueType, height } = props;
  const contents = analysisData.data.megLoad;
  const analysisState = statisticalValueType;
  if (contents == null || contents.date.length === 0) {
    return <Box sx={sx} />;
  }

  let xAnalysisDataL: number[];
  let xAnalysisDataR: number[];
  let yMegBhpDataL: number[];
  let yMegBhpDataR: number[];

  const yMegBhpTextL: string[] = [];
  const yMegBhpTextR: string[] = [];

  function getHoverInfoText(
    index: number,
    data: AnalysisStatisticalValue,
    unit: string,
    round: number
  ): string {
    let ret = '';
    const max = roundValueToString(data.max[index], round);
    const min = roundValueToString(data.min[index], round);
    const average = roundValueToString(data.average[index], round);
    const median = roundValueToString(data.median[index], round);
    const variance = roundValueToString(data.variance[index], round);
    const mode = roundValueToString(data.mode[index], round);
    ret = intl.formatMessage({ id: msgId.statisticalValueMax }) + ':' + max + unit + '<br>';
    ret += intl.formatMessage({ id: msgId.statisticalValueMin }) + ':' + min + unit + '<br>';
    ret +=
      intl.formatMessage({ id: msgId.statisticalValueAverage }) + ':' + average + unit + '<br>';
    ret += intl.formatMessage({ id: msgId.statisticalValueMedian }) + ':' + median + unit + '<br>';
    ret += intl.formatMessage({ id: msgId.statisticalValueVariance }) + ':' + variance + '<br>';
    ret += intl.formatMessage({ id: msgId.statisticalValueMode }) + ':' + mode + unit + '';

    return ret;
  }
  const nitiji = intl.formatMessage({ id: msgId.AnalysisHoverInfoLabelDate });
  contents.date.forEach((date, index) => {
    const d = new Date(date);
    const dateStrings = d.toISOString().replace('T', ' ').split('-').join('/').split(':');
    dateStrings.pop();
    const dateString = dateStrings.join(':');

    if (analysisData.dataFormatId !== 402) {
      yMegBhpTextL.push(
        nitiji +
        ':' +
        dateString +
        '<br>' +
        getHoverInfoText(
          index,
          contents.MegBhpP1,
          contents.displayInformations.MegBhp.unit + '',
          contents.displayInformations.MegBhp.roundingPosition
        )
      );
      yMegBhpTextR.push(
        nitiji +
        ':' +
        dateString +
        '<br>' +
        getHoverInfoText(
          index,
          contents.MegBhpS1,
          contents.displayInformations.MegBhp.unit + '',
          contents.displayInformations.MegBhp.roundingPosition
        )
      );
    } else {
      yMegBhpTextL.push(
        nitiji +
        ':' +
        dateString +
        '<br>' +
        getHoverInfoText(
          index,
          contents.EngBhpP,
          contents.displayInformations.EngBhp.unit + '',
          contents.displayInformations.EngBhp.roundingPosition
        )
      );
      yMegBhpTextR.push(
        nitiji +
        ':' +
        dateString +
        '<br>' +
        getHoverInfoText(
          index,
          contents.EngBhpS,
          contents.displayInformations.EngBhp.unit + '',
          contents.displayInformations.EngBhp.roundingPosition
        )
      );
    }
  });

  let MegBhpTestDataL: Plotly.Data;
  let MegBhpTestDataR: Plotly.Data;
  if (analysisData.dataFormatId !== 402) {
    if (analysisState === 'mode') {
      xAnalysisDataL = contents.MegSpdP1.mode.map((value) => (value < 0 ? NaN : value));
      xAnalysisDataR = contents.MegSpdS1.mode.map((value) => (value < 0 ? NaN : value));
      yMegBhpDataL = contents.MegBhpP1.mode.map((value) => (value < 0 ? NaN : value));
      yMegBhpDataR = contents.MegBhpS1.mode.map((value) => (value < 0 ? NaN : value));
    } else {
      xAnalysisDataL = contents.MegSpdP1.median.map((value) => (value < 0 ? NaN : value));
      xAnalysisDataR = contents.MegSpdS1.median.map((value) => (value < 0 ? NaN : value));
      yMegBhpDataL = contents.MegBhpP1.median.map((value) => (value < 0 ? NaN : value));
      yMegBhpDataR = contents.MegBhpS1.median.map((value) => (value < 0 ? NaN : value));
    }
    MegBhpTestDataL = {
      x: contents.cubicCurveDataP1.MegSpd,
      y: contents.cubicCurveDataP1.MegBhp,
      type: 'scatter' as PlotType,
      hoverinfo: 'none',
      yaxis: 'y',
      mode: 'lines',
      line: { color: testDataLineColor },
    } as Plotly.Data;

    MegBhpTestDataR = {
      x: contents.cubicCurveDataS1.MegSpd,
      y: contents.cubicCurveDataS1.MegBhp,
      type: 'scatter' as PlotType,
      hoverinfo: 'none',
      yaxis: 'y',
      mode: 'lines',
      line: { color: testDataLineColor },
    } as Plotly.Data;
  } else {
    if (analysisState === 'mode') {
      xAnalysisDataL = contents.EngSpdP.mode.map((value) => (value < 0 ? NaN : value));
      xAnalysisDataR = contents.EngSpdS.mode.map((value) => (value < 0 ? NaN : value));
      yMegBhpDataL = contents.EngBhpP.mode.map((value) => (value < 0 ? NaN : value));
      yMegBhpDataR = contents.EngBhpS.mode.map((value) => (value < 0 ? NaN : value));
    } else {
      xAnalysisDataL = contents.EngSpdP.median.map((value) => (value < 0 ? NaN : value));
      xAnalysisDataR = contents.EngSpdS.median.map((value) => (value < 0 ? NaN : value));
      yMegBhpDataL = contents.EngBhpP.median.map((value) => (value < 0 ? NaN : value));
      yMegBhpDataR = contents.EngBhpS.median.map((value) => (value < 0 ? NaN : value));
    }
    MegBhpTestDataL = {
      x: contents.cubicCurveDataP1.EngSpd,
      y: contents.cubicCurveDataP1.EngBhp,
      type: 'scatter' as PlotType,
      hoverinfo: 'none',
      yaxis: 'y',
      mode: 'lines',
      line: { color: testDataLineColor },
    } as Plotly.Data;

    MegBhpTestDataR = {
      x: contents.cubicCurveDataS1.EngSpd,
      y: contents.cubicCurveDataS1.EngBhp,
      type: 'scatter' as PlotType,
      hoverinfo: 'none',
      yaxis: 'y',
      mode: 'lines',
      line: { color: testDataLineColor },
    } as Plotly.Data;
  }

  const MegBhpAnalysisDataL = {
    x: xAnalysisDataL,
    y: yMegBhpDataL,
    type: 'scatter' as PlotType,
    yaxis: 'y',
    mode: 'markers',
    line: { color: colors.chart.lines[0] },
    text: yMegBhpTextL,
    hovertemplate: '%{text}<extra></extra>',
    hoverinfo: 'text',
  } as Plotly.Data;

  const MegBhpAnalysisDataR = {
    x: xAnalysisDataR,
    y: yMegBhpDataR,
    type: 'scatter' as PlotType,
    yaxis: 'y',
    mode: 'markers',
    line: { color: colors.chart.lines[1] },
    text: yMegBhpTextR,
    hovertemplate: '%{text}<extra></extra>',
    hoverinfo: 'text',
  } as Plotly.Data;

  const xtickVals: number[] = [];
  const xtickText: string[] = [];

  let megSpdRangeMax: number;
  let megSpdRangeMin: number;
  let megSpdPerformanceMax: number | undefined;
  let megSpdUnit: number;
  let megSpdRoundingPosition: number;
  let megBhpRangeMax: number;
  let megBhpRangeMin: number;
  let megBhpPerformanceMax: number | undefined;
  let megBhpUnit: number;
  let megBhpRoundingPosition: number;
  if (analysisData.dataFormatId !== 402) {
    megSpdRangeMax = contents.displayInformations.MegSpd.rangeMax;
    megSpdRangeMin = contents.displayInformations.MegSpd.rangeMin;
    megSpdPerformanceMax = contents.displayInformations.MegSpd.performanceMax;
    megSpdUnit = contents.displayInformations.MegSpd.unit;
    megSpdRoundingPosition = contents.displayInformations.MegSpd.roundingPosition;
    megBhpRangeMax = contents.displayInformations.MegBhp.rangeMax;
    megBhpRangeMin = contents.displayInformations.MegBhp.rangeMin;
    megBhpPerformanceMax = contents.displayInformations.MegBhp.performanceMax;
    megBhpUnit = contents.displayInformations.MegBhp.unit;
    megBhpRoundingPosition = contents.displayInformations.MegBhp.roundingPosition;
  } else {
    megSpdRangeMax = contents.displayInformations.EngSpd.rangeMax;
    megSpdRangeMin = contents.displayInformations.EngSpd.rangeMin;
    megSpdPerformanceMax = contents.displayInformations.EngSpd.performanceMax;
    megSpdUnit = contents.displayInformations.EngSpd.unit;
    megSpdRoundingPosition = contents.displayInformations.EngSpd.roundingPosition;
    megBhpRangeMax = contents.displayInformations.EngBhp.rangeMax;
    megBhpRangeMin = contents.displayInformations.EngBhp.rangeMin;
    megBhpPerformanceMax = contents.displayInformations.EngBhp.performanceMax;
    megBhpUnit = contents.displayInformations.EngBhp.unit;
    megBhpRoundingPosition = contents.displayInformations.EngBhp.roundingPosition;
  }

  for (
    let i = megSpdRangeMin;
    i <= megSpdRangeMax;
    i += 100
  ) {
    xtickVals.push(i);
    xtickText.push(i + '');
  }

  const xaxis = {
    title: {
      text:
        intl.formatMessage({ id: msgId.megRotationSpeed }) +
        '[' +
        megSpdUnit +
        ']',
      font: { color: axisFontColor },
    },
    tickvals: xtickVals,
    ticktext: xtickText,
    range: [
      megSpdRangeMin,
      megSpdRangeMax,
    ],
    showgrid: true,
    gridcolor: gridColor,
    tickfont: { color: axisFontColor, size: 13 },
  } as Partial<LayoutAxis>;

  const bhpDTick = megBhpRangeMax / 5;
  const yaxis = {
    title: {
      text:
        intl.formatMessage({ id: msgId.AnalysisMegBhpYTitle }) +
        '[' +
        megBhpUnit +
        ']',
      font: { color: axisFontColor },
    },
    tickmode: 'array',
    tickvals: [bhpDTick * 0, bhpDTick * 1, bhpDTick * 2, bhpDTick * 3, bhpDTick * 4, bhpDTick * 5],
    ticktext: [
      bhpDTick * 0 + '',
      bhpDTick * 1 + '',
      bhpDTick * 2 + '',
      bhpDTick * 3 + '',
      bhpDTick * 4 + '',
      bhpDTick * 5 + '',
    ],
    tickfont: { color: y1TickFontColor, size: 13 },
    range: [
      megBhpRangeMin,
      megBhpRangeMax,
    ],
    showgrid: true,
    gridcolor: gridColor,
  };

  const shapeBhp: Partial<Shape> = {
    type: 'line',
    xref: 'paper',
    yref: 'y',
    x0: 0,
    y0: megBhpPerformanceMax,
    x1: 1,
    y1: megBhpPerformanceMax,
    line: {
      color: 'red',
      width: 1,
      dash: 'dot',
    },
  };

  const shapeBhpLine: Partial<Shape> = {
    type: 'line',
    xref: 'paper',
    yref: 'y',
    x0: 0,
    y0: 0,
    x1: 0,
    y1: megBhpRangeMax,
    line: {
      color: y1TickColor,
      width: 2,
    },
  };

  const shapeSpd: Partial<Shape> = {
    type: 'line',
    xref: 'x',
    yref: 'paper',
    x0: megSpdPerformanceMax,
    y0: 0,
    x1: megSpdPerformanceMax,
    y1: 1,
    line: {
      color: 'red',
      width: 1,
      dash: 'dot',
    },
  };

  const annotationY1: Partial<Annotations> = {
    xref: 'paper',
    yref: 'y',
    x: 0,
    xanchor: 'left',
    y: megBhpPerformanceMax,
    yanchor: 'bottom',
    text:
      roundValueToString(
        megBhpPerformanceMax,
        megBhpRoundingPosition
      ) + '(100%)',
    showarrow: false,
    font: {
      size: 13,
      color: 'red',
    },
  };

  const annotationX: Partial<Annotations> = {
    xref: 'x',
    yref: 'paper',
    x: megSpdPerformanceMax,
    xanchor: 'left',
    y: 0,
    yanchor: 'bottom',
    text:
      roundValueToString(
        megSpdPerformanceMax,
        megSpdRoundingPosition
      ) + '(100%)',
    showarrow: false,
    font: {
      size: 13,
      color: 'red',
    },
  };

  const layout = {
    height: height,
    autosize: true,
    xaxis: xaxis,
    yaxis: yaxis,
    paper_bgcolor: colors.chart.plotarea.background,
    plot_bgcolor: colors.chart.plotarea.background,
    margin: {
      t: 10,
      b: 40,
      l: 90,
      r: 60,
      pad: 2,
    },
    showlegend: false,
    shapes: [shapeBhp, shapeBhpLine, shapeSpd],
    annotations: [annotationY1, annotationX],
    hovermode: 'closest',
    hoverlabel: {
      bgcolor: 'black',
      bordercolor: 'black',
      font: {
        color: 'white',
      },
      align: 'left',
    },
  } as Partial<Layout>;

  const plotConfig = {
    displayModeBar: false,
    showTips: false,
    doubleClick: 'reset',
  } as Partial<Plotly.Config>;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  function onHover(data: Readonly<PlotMouseEvent>) {
    // noop
  }

  return (
    <Box sx={sx}>
      <Plot
        style={plotProps}
        data={[MegBhpTestDataL, MegBhpTestDataR, MegBhpAnalysisDataL, MegBhpAnalysisDataR]}
        useResizeHandler
        layout={layout}
        onHover={(event) => onHover(event)}
        config={plotConfig}
      />
    </Box>
  );
}
