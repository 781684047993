import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import dimens from 'resources/dimens';
import { AnalysisData, AnalysisStatisticalValue, StatisticalValueType } from 'models/analysis';
import { ChartHeaderPanel } from 'components/chart/ChartHeaderPanel';
import { LegendGroup, MegTempLegendList, LegendIndex } from './MegTempLegendList';
import { MegTempChart } from './MegTempChart';
import { ChartContainer, ChartContentsDiv, ChartDivider } from '../common/ChartContainer';
import dayjs from 'dayjs';
import {
  setMegTempExpanded,
  setMegTempSelectedLegends,
  setMegTempStatisticalValueType,
  useAnalysisMegTempExpanded,
  useAnalysisMegTempSelectedLegends,
  useAnalysisMegTempStatisticalValueType,
} from 'features/contents/analysis/analysisSlice';
import { ConfirmedSearchResultItem } from 'models/search';
import { useDispatch } from 'react-redux';

/**
 * 統計値から選択した統計値タイプの値を取得する。
 * @param value 統計値
 * @param index インデックス
 * @param statisticalValueType 統計値タイプ
 */
function getStatisticalValue(
  value: AnalysisStatisticalValue,
  index: number,
  statisticalValueType: StatisticalValueType
): number {
  if (statisticalValueType === 'max') {
    return value.max[index];
  } else if (statisticalValueType === 'min') {
    return value.min[index];
  } else if (statisticalValueType === 'average') {
    return value.average[index];
  } else if (statisticalValueType === 'median') {
    return value.median[index];
  } else if (statisticalValueType === 'mode') {
    return value.mode[index];
  } else if (statisticalValueType === 'variance') {
    return value.variance[index];
  } else {
    return Number.NaN;
  }
}

interface MegTempContainerProps {
  chartId: string;
  confirmedSearchResultItem: ConfirmedSearchResultItem;
  analysisData: AnalysisData;
  width: number;
}

export function MegTempContainer(props: MegTempContainerProps): JSX.Element {
  const { chartId, confirmedSearchResultItem, analysisData, width } = props;
  const intl = useIntl();
  const dispatch = useDispatch();
  const megTemp = analysisData.data.megTemp;
  const expanded = useAnalysisMegTempExpanded();
  const statisticalValueType = useAnalysisMegTempStatisticalValueType();
  const selectedLegends = useAnalysisMegTempSelectedLegends();

  /**
   * CSV出力
   */
  const handleCsvOutput = () => {
    // noop
  };

  /**
   * 開閉状態変更
   * @param expanded 開閉状態
   */
  const handleExpandChanged = (expanded: boolean) => {
    dispatch(setMegTempExpanded(expanded));
  };

  /**
   * 分析値タイプ変更
   * @param statisticalValueType 分析値タイプ
   */
  const handleChangeStatisticalValueType = (statisticalValueType: StatisticalValueType) => {
    dispatch(setMegTempStatisticalValueType(statisticalValueType));
  };

  /**
   * 凡例選択変更
   * @param index 凡例インデックス
   */
  const handleCheckChange = (index: LegendIndex) => {
    dispatch(setMegTempSelectedLegends(index));
  };

  /**
   * 凡例リスト
   */
  const legendGroups: LegendGroup[] = [
    {
      groupName: analysisData.shipName + ' - ' + analysisData.machineName,
      legends: [],
    },
  ];

  const chart = useMemo(
    () => (
      <MegTempChart
        sx={{ width: `calc(100% - ${dimens.chart.legend.width}px - 1px)` }}
        analysisData={analysisData}
        selectedLegends={selectedLegends}
        statisticalValueType={statisticalValueType}
        width={width - dimens.chart.legend.width}
      />
    ),
    [analysisData, selectedLegends, statisticalValueType, width]
  );

  if (megTemp == null) {
    return <React.Fragment />;
  }

  const analysisName =
    intl.formatMessage({ id: 'AnalysisSection' + analysisData.data.megTemp?.analysisSectionName }) +
    ' : ' +
    intl.formatMessage({ id: 'AnalysisMenu' + analysisData.data.megTemp?.analysisMenuName });

  if (analysisData.dataFormatId !== 402) {
    for (let i = 0; i < megTemp.date.length; i++) {
      legendGroups[0].legends.push({
        date: dayjs(megTemp.date[i]).format('YYYY/MM/DD').toString(),
        megHpP1: Math.round(getStatisticalValue(megTemp.MegHpP1, i, statisticalValueType)),
        megHpS1: Math.round(getStatisticalValue(megTemp.MegHpS1, i, statisticalValueType)),
      });
    }
  } else {
    for (let i = 0; i < megTemp.date.length; i++) {
      legendGroups[0].legends.push({
        date: dayjs(megTemp.date[i]).format('YYYY/MM/DD').toString(),
        megHpP1: Math.round(getStatisticalValue(megTemp.EngSftHpP, i, statisticalValueType)),
        megHpS1: Math.round(getStatisticalValue(megTemp.EngSftHpS, i, statisticalValueType)),
      });
    }
  }

  return (
    <ChartContainer
      chartId={chartId}
      shipName={analysisData.shipName}
      machineName={analysisData.machineName}
      analysisName={analysisName}
      confirmedSearchResultItem={confirmedSearchResultItem}
      title={analysisName}
      expanded={expanded}
      onExpandChanged={handleExpandChanged}
    >
      <ChartHeaderPanel
        chartId={chartId}
        sx={{ paddingRight: `${dimens.chart.legend.width}px` }}
        title={analysisName}
        titleEditable={false}
        csvOutputEnabled={false}
        csvOutputLocked={true}
      />
      <ChartContentsDiv>
        {chart}
        <ChartDivider orientation="vertical" flexItem />
        <MegTempLegendList
          legendGroups={legendGroups}
          selectedLegends={selectedLegends}
          statisticalValueType={statisticalValueType}
          onChangeStatisticalValueType={handleChangeStatisticalValueType}
          onCheckChange={handleCheckChange}
        />
      </ChartContentsDiv>
    </ChartContainer>
  );
}
