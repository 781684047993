import React, { useEffect, useRef, useState } from 'react';
import {
  Button,
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Typography,
} from '@mui/material';
import { theme } from 'resources/theme';
import styled from '@emotion/styled';

const StyledPopper = styled(Popper)({
  zIndex: theme.zIndex.drawer + 101,
});

const StyledMenuItem = styled(MenuItem)({
  borderTop: `1px solid ${theme.palette.divider}`,
});

interface SpeedMagnificationPopupProps {
  speedMagnification: number;
  samplePerMilliSeconds: number;
  onClickMagnification(event: React.MouseEvent<EventTarget>, magnification: number): void;
}

export function SpeedMagnificationPopup(props: SpeedMagnificationPopupProps): JSX.Element {
  const { speedMagnification, samplePerMilliSeconds, onClickMagnification } = props;
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLButtonElement>(null);
  const prevOpen = useRef(open);

  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current?.focus();
    }
    prevOpen.current = open;
  }, [open]);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClickAway = (event: MouseEvent | TouchEvent) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    setOpen(false);
  };

  const handleListKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  };

  const handleSelectMenu = (event: React.MouseEvent<EventTarget>, magnification: number) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    setOpen(false);
    onClickMagnification(event, magnification);
  };

  const magnifications = samplePerMilliSeconds === 100 ? [1, 10, 100, 600] : [1, 10, 60, 600];

  return (
    <React.Fragment>
      <Button
        sx={{
          width: 50,
          padding: 0,
          margin: theme.spacing(0.5),
          textTransform: 'none',
        }}
        variant="contained"
        color="secondary"
        size="small"
        ref={anchorRef}
        aria-controls={open ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
      >
        <Typography variant="body1" noWrap>
          x{speedMagnification}
        </Typography>
      </Button>
      <StyledPopper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        placement="auto"
      >
        {({ TransitionProps }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: 'center bottom',
            }}
          >
            <Paper>
              <React.Fragment>
                <ClickAwayListener onClickAway={handleClickAway}>
                  <MenuList
                    sx={{ padding: 0, minWidth: 200 }}
                    autoFocusItem={open}
                    id="menu-list-grow"
                    onKeyDown={handleListKeyDown}
                  >
                    {magnifications.map((mag) => {
                      return (
                        <StyledMenuItem key={mag} onClick={(x) => handleSelectMenu(x, mag)}>
                          x{mag}
                        </StyledMenuItem>
                      );
                    })}
                  </MenuList>
                </ClickAwayListener>
              </React.Fragment>
            </Paper>
          </Grow>
        )}
      </StyledPopper>
    </React.Fragment>
  );
}
