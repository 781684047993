import React, { ReactNode } from 'react';
import { styled } from '@mui/material/styles';
import { ListItemButton, ListItemIcon, ListItemText, Tooltip } from '@mui/material';
import dimens from 'resources/dimens';
import colors from 'resources/colors';

const StyledListItem = styled(ListItemButton)({
  padding: '0px 0px 0px 8px',
  height: dimens.toolbar.height,
  '&:hover': {
    background: colors.drawerItem.hover,
  },
  '&.Mui-selected': {
    background: colors.drawerItem.disabled,
  },
  '&.Mui-disabled': {
    opacity: 1.0,
  },
  borderLeft: '5px solid transparent',
});

interface DrawerItemProps {
  className?: string;
  children?: ReactNode;
  index: number;
  selected: boolean;
  title: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  icon: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  lockIcon?: any;
  buttonKey: string;
  enabled: boolean;
  padding?: string;
  onItemClicked: (index: number) => void;
}

export function DrawerItem(props: DrawerItemProps): JSX.Element {
  const { children, index, selected, title, icon, lockIcon, buttonKey, enabled, onItemClicked } =
    props;
  const MenuIcon = icon;
  const LockIcon = lockIcon;

  const handleItemClick = (index: number) => {
    if (onItemClicked != null) {
      onItemClicked(index);
    }
  };

  return (
    <StyledListItem
      key={buttonKey}
      selected={selected}
      onClick={() => handleItemClick(index)}
      disabled={selected || !enabled}
      sx={{
        opacity: selected ? '1.0' : '',
        borderLeft: '5px solid ' + (selected ? colors.drawerItem.selected : 'transparent'),
      }}
    >
      <Tooltip title={title} sx={{ minWidth: '38px' }}>
        <ListItemIcon>
          {MenuIcon !== null && (
            <MenuIcon
              sx={{ color: selected ? colors.drawerItem.selected : colors.drawerItem.normal }}
            />
          )}
        </ListItemIcon>
      </Tooltip>
      <ListItemText
        primaryTypographyProps={{ variant: 'h4' }}
        primary={title}
        sx={{
          color: selected ? colors.drawerItem.selected : colors.drawerItem.normal,
        }}
      />
      {children}
      <ListItemIcon sx={{ minWidth: dimens.default.rowHeight }}>
        {LockIcon !== null && (
          <LockIcon style={{ color: selected ? colors.drawerItem.selected : colors.white }} />
        )}
      </ListItemIcon>
    </StyledListItem>
  );
}

DrawerItem.defaultProps = {
  lockIcon: null,
  selected: false,
};
