import React, { useState } from 'react';
import { styled } from '@mui/material';
import { RootContainer } from 'components/RootContainer';
import { MenuDrawer } from 'components/menuDrawer/MenuDrawer';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import msgId from 'resources/intl';
import dimens from 'resources/dimens';
import { SensorDiagnosisThresholdsManagement } from './sensorDiagnosisThresholds/SensorDiagnosisThresholdsManagement';
import { TestDataManagement } from './testData/TestDataManagement';
import { FadeLoading } from 'components/FadeLoading';
import { AlertDialog } from 'components/AlertDialog';
import {
  setUpdateResult,
  useSystemSettingsUpdateResult,
  useSystemSettingsUpdating,
} from './systemSettingsSlice';
import { allClearAndSignOut } from 'utils/common';
import { useNavigate } from 'react-router-dom';
import { useAccount } from 'features/login/accountSlice';

const ContntsContainer = styled('div')({
  position: 'absolute',
  overflow: 'hidden',
  top: 0,
  height: '100%',
});

interface SystemSettingsFragmentProps {
  hidden: boolean;
}

export function SystemSettingsFragment(props: SystemSettingsFragmentProps): JSX.Element {
  const { hidden } = props;
  const intl = useIntl();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const account = useAccount();
  const updating = useSystemSettingsUpdating();
  const updateResult = useSystemSettingsUpdateResult();
  const [menuDrawerOpen, setMenuDrawerOpen] = useState(true);
  const [menuIndex, setMenuIndex] = useState(0);
  const menuTitles: string[] = [
    intl.formatMessage({ id: msgId.sensorDiagnosisThresholdsManagement }),
    intl.formatMessage({ id: msgId.testDataManagement }),
  ];

  /**
   * メニュー選択変更
   * @param index 開閉状態
   */
  const handleSelectMenu = (index: number) => {
    setMenuIndex(index);
  };

  /**
   * メニュードロワー開閉状態変更
   * @param open 開閉状態
   */
  const handleMenuDrawerChangeOpen = (open: boolean) => {
    dispatch(setMenuDrawerOpen(open));
  };

  const handleResultClear = () => {
    if (updateResult?.isSuccess) {
      allClearAndSignOut(dispatch, account?.homePath, navigate);
    } else {
      dispatch(setUpdateResult(undefined));
    }
  };

  return (
    <RootContainer hidden={hidden}>
      <MenuDrawer
        menus={menuTitles}
        open={menuDrawerOpen}
        selectedMenu={menuIndex}
        onSelectMenu={handleSelectMenu}
        onChangeOpen={handleMenuDrawerChangeOpen}
      />
      <ContntsContainer
        sx={{
          width: menuDrawerOpen
            ? `calc(100% - ${dimens.subDrawer.width}px)`
            : `calc(100% - ${dimens.subDrawer.minWidth}px)`,
          left: menuDrawerOpen ? dimens.subDrawer.width : dimens.subDrawer.minWidth,
        }}
      >
        <SensorDiagnosisThresholdsManagement open={!hidden && menuIndex === 0} />
        <TestDataManagement open={!hidden && menuIndex === 1} />
      </ContntsContainer>
      <FadeLoading loading={updating} />
      {updateResult != null && (
        <AlertDialog
          title={intl.formatMessage({ id: updateResult.isSuccess ? msgId.confirm : msgId.error })}
          message={intl.formatMessage({ id: updateResult.msgId })}
          onClose={() => handleResultClear()}
        />
      )}
    </RootContainer>
  );
}
