import { API } from 'aws-amplify';
import { API_NAME } from './maricoApi';
import AppLogger from 'utils/AppLogger';
import { getErrorResult } from 'models/error';
import { Ship } from 'models/ships';

/**
 * 船舶リスト取得
 */
export async function getShipsAsync(): Promise<Ship[]> {
  try {
    const params = {
      headers: {},
      response: true,
    };
    const result = await API.get(API_NAME, '/v1/ships', params);

    return result.data;
  } catch (error) {
    AppLogger.error('error getShipsAsync: ', error);
    throw getErrorResult(error);
  }
}
