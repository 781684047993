import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import msgId from 'resources/intl';
import {
  forgetPassword,
  setPasswordResetResult,
  setPasswordResetOpen,
  sendAuthCode,
  useAccountPasswordResetOpen,
  useAccountPasswordResetResult,
  useAccountInProgress,
  useAccountPasswordResetError,
  setPasswordResetError,
  useAccountCognitoUserInfo,
} from './accountSlice';
import { FadeLoading } from 'components/FadeLoading';
import { AlertDialog } from 'components/AlertDialog';
import { NormalButton } from 'components/NormalButton';
import { PasswordTextField } from 'components/PasswordTextField';
import { SmallTextField } from 'components/SmallTextField';
import { MultiLineTypography } from 'components/MultiLineTypography';
import {
  EditDialog,
  EditDialogContents,
  EditDialogFooterButtonGrid,
  EditDialogInputFieldGrid,
  EditDialogRootGrid,
  EditDialogTitleGrid,
} from 'components/EditDialog';
import { Typography } from '@mui/material';
import { theme } from 'resources/theme';
import { ErrorInfo, ErrorMessages, FieldLabels, getErrorMessage } from 'models/error';
import { CustomScrollBars } from 'components/CustomScrollBars';
import colors from 'resources/colors';
import dimens from 'resources/dimens';

export function AccountPasswordResetDialog(): JSX.Element {
  const intl = useIntl();
  const dispatch = useDispatch();
  const open = useAccountPasswordResetOpen();
  const passwordResetResult = useAccountPasswordResetResult();
  const passwordResetError = useAccountPasswordResetError();
  const cognitoUserInfo = useAccountCognitoUserInfo();
  const inProgress = useAccountInProgress();
  const [state, setState] = useState({
    cognitoAccountId: '',
    authCode: '',
    newPassword: '',
    confirmNewPassword: '',
  });
  const [step, setStep] = useState(1);

  let errorMsg: ErrorMessages = {};
  let errors: ErrorInfo[] = [];
  const labels: FieldLabels = {
    PARAMETER_LENGTH_ERROR: {
      fallback: intl.formatMessage({ id: msgId.errorPasswordRequirement }),
    },
    PARAMETER_FORMAT_ERROR: {
      fallback: intl.formatMessage({ id: msgId.errorPasswordRequirement }),
    },
    PARAMETER_REQUIREMENTS_ERROR: {
      fallback: intl.formatMessage({ id: msgId.errorPasswordRequirement }),
    },
    PASSWORD_MISMATCH_ERROR: {
      fallback: intl.formatMessage({ id: msgId.errorCurrentPasswordMismatch }),
    },
    PARAMETER_INVALID_CHARACTER_ERROR: {
      fallback: intl.formatMessage({ id: msgId.errorPasswordFormat }),
    },
    NOT_FOUND_ERROR: { fallback: intl.formatMessage({ id: msgId.errorLoginId }) },
    NOT_AUTHORIZED_ERROR: { fallback: intl.formatMessage({ id: msgId.errorLoginIdNotAuthorized }) },
    CODE_EXPAIRED_ERROR: { fallback: intl.formatMessage({ id: msgId.errorAuthCode }) },
    CODE_MISMATCH_ERROR: { fallback: intl.formatMessage({ id: msgId.errorAuthCode }) },
    fallback: { fallback: intl.formatMessage({ id: msgId.errorMessageOther }) },
  };

  if (passwordResetError) {
    if (Array.isArray(passwordResetError.response)) {
      errors = passwordResetError.response;
    } else {
      errors = [passwordResetError.response];
    }
  }
  errorMsg = getErrorMessage(errors, labels);

  if (!state.cognitoAccountId) {
    errorMsg['cognitoAccountId'] = intl.formatMessage({ id: msgId.messageInputRequired });
  }

  if (!state.authCode) {
    errorMsg['authCode'] = intl.formatMessage({ id: msgId.messageInputRequired });
  }

  if (!state.newPassword) {
    errorMsg['newPassword'] = intl.formatMessage({ id: msgId.errorPasswordRequirement });
  } else if (state.newPassword !== state.confirmNewPassword) {
    errorMsg['newPassword'] = intl.formatMessage({ id: msgId.errorPasswordMismatch });
  }

  if (!state.confirmNewPassword) {
    errorMsg['confirmNewPassword'] = intl.formatMessage({ id: msgId.errorPasswordRequirement });
  }

  /**
   * バックボタン押下
   */
  const handleBackClick = () => {
    if (step === 2) {
      setStep(1);
    } else {
      dispatch(setPasswordResetOpen(false));
      dispatch(setPasswordResetError(undefined));
    }
  };

  /**
   * 送信ボタン押下時に呼ばれる。
   */
  const handleSendCognitoAccountId = () => {
    dispatch(forgetPassword(state.cognitoAccountId));
  };

  const handleSendAuthCode = () => {
    if (state.cognitoAccountId != null && state.cognitoAccountId !== '') {
      dispatch(sendAuthCode(state.cognitoAccountId, state.authCode, state.newPassword));
    } else if (cognitoUserInfo != null) {
      dispatch(sendAuthCode(cognitoUserInfo.userName, state.authCode, state.newPassword));
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setState({ ...state, [event.target.name]: event.target.value });
  };

  const handleResultClear = () => {
    dispatch(setPasswordResetResult(undefined));
  };

  const handleClose = () => {
    dispatch(setPasswordResetOpen(false));
    dispatch(setPasswordResetError(undefined));
  };

  useEffect(() => {
    if (open) {
      setState({
        cognitoAccountId: '',
        authCode: '',
        newPassword: '',
        confirmNewPassword: '',
      });
      setStep(1);
    }
  }, [open]);

  useEffect(() => {
    if (passwordResetResult != null && passwordResetResult.isSuccess) {
      if (step === 1) {
        setStep(2);
        dispatch(setPasswordResetResult(undefined));
      }
    }
  }, [dispatch, passwordResetResult, step]);

  return (
    <EditDialog
      id="accountPasswordResetDialog"
      open={open}
      onBackClick={handleBackClick}
      title={intl.formatMessage({
        id: step === 1 ? msgId.loginPasswordResetLink : msgId.accountPasswordReset,
      })}
    >
      {step === 1 && (
        <EditDialogContents>
          <CustomScrollBars
            thumbColor={colors.scrollBar.thumb.light}
            width={dimens.dialogContents.width}
            autoHide={false}
          >
            <EditDialogRootGrid>
              <EditDialogTitleGrid>
                <MultiLineTypography
                  variant="h5"
                  align="center"
                  text={intl.formatMessage({ id: msgId.guideMessageResetPassword1 })}
                />
              </EditDialogTitleGrid>
              <EditDialogInputFieldGrid>
                <SmallTextField
                  id="cognitoAccountId"
                  fullWidth={true}
                  label={
                    intl.formatMessage({ id: msgId.accountName }) +
                    '[' +
                    intl.formatMessage({ id: msgId.required }) +
                    ']'
                  }
                  autoComplete="off"
                  value={state.cognitoAccountId}
                  onChange={handleChange}
                  error={!!errorMsg['cognitoAccountId']}
                  helperText={errorMsg['cognitoAccountId']}
                />
              </EditDialogInputFieldGrid>
              <EditDialogFooterButtonGrid>
                <NormalButton
                  label={intl.formatMessage({ id: msgId.send })}
                  onClick={() => handleSendCognitoAccountId()}
                  disabled={state.cognitoAccountId.length == 0}
                />
              </EditDialogFooterButtonGrid>
            </EditDialogRootGrid>
          </CustomScrollBars>
        </EditDialogContents>
      )}
      {step === 2 && (
        <EditDialogContents>
          <CustomScrollBars
            thumbColor={colors.scrollBar.thumb.light}
            width={dimens.dialogContents.width}
            autoHide={false}
          >
            <EditDialogRootGrid>
              <EditDialogTitleGrid sx={{ marginBottom: theme.spacing(2) }}>
                <Typography color="error" variant="h5">
                  {intl.formatMessage({ id: msgId.guideMessageSendAuthCode1 })}
                </Typography>
              </EditDialogTitleGrid>
              <EditDialogTitleGrid>
                <MultiLineTypography
                  variant="body1"
                  align="center"
                  text={intl.formatMessage({ id: msgId.guideMessageSendAuthCode2 })}
                />
              </EditDialogTitleGrid>
              <EditDialogInputFieldGrid>
                <SmallTextField
                  id="authCode"
                  fullWidth={true}
                  label={
                    intl.formatMessage({ id: msgId.authCode }) +
                    '[' +
                    intl.formatMessage({ id: msgId.required }) +
                    ']'
                  }
                  autoComplete="off"
                  value={state.authCode}
                  onChange={handleChange}
                  error={!!errorMsg['authCode']}
                  helperText={errorMsg['authCode']}
                />
              </EditDialogInputFieldGrid>
              <EditDialogInputFieldGrid>
                <PasswordTextField
                  id="newPassword"
                  fullWidth={true}
                  label={intl.formatMessage({ id: msgId.accountChangePasswordNew })}
                  autoComplete="new-password"
                  value={state.newPassword}
                  onChange={handleChange}
                  error={!!errorMsg['newPassword']}
                  helperText={errorMsg['newPassword']}
                  required
                />
              </EditDialogInputFieldGrid>
              <EditDialogInputFieldGrid>
                <PasswordTextField
                  id="confirmNewPassword"
                  fullWidth={true}
                  label={intl.formatMessage({ id: msgId.accountChangePasswordConfirm })}
                  autoComplete="new-password"
                  value={state.confirmNewPassword}
                  onChange={handleChange}
                  error={!!errorMsg['confirmNewPassword']}
                  helperText={errorMsg['confirmNewPassword']}
                  required
                />
              </EditDialogInputFieldGrid>
              <EditDialogFooterButtonGrid>
                <NormalButton
                  label={intl.formatMessage({ id: msgId.registration })}
                  onClick={() => handleSendAuthCode()}
                  disabled={
                    state.authCode.length == 0 ||
                    state.newPassword.length == 0 ||
                    state.confirmNewPassword !== state.newPassword
                  }
                />
              </EditDialogFooterButtonGrid>
            </EditDialogRootGrid>
          </CustomScrollBars>
        </EditDialogContents>
      )}
      <FadeLoading loading={inProgress} />
      {passwordResetResult != null &&
        passwordResetResult.isSuccess &&
        passwordResetResult.step === 2 && (
          <AlertDialog
            title={intl.formatMessage({ id: msgId.confirm })}
            message={intl.formatMessage({ id: msgId.messageChangePasswordSuccess })}
            onClose={() => handleClose()}
          />
        )}
      {passwordResetResult != null &&
        !passwordResetResult.isSuccess &&
        passwordResetResult.showDialog &&
        !passwordResetResult.errorFieldName && (
          <AlertDialog
            title={intl.formatMessage({ id: msgId.error })}
            message={intl.formatMessage({ id: passwordResetResult.msgId })}
            onClose={() => handleResultClear()}
          />
        )}
    </EditDialog>
  );
}
