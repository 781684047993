import { Box, Typography } from '@mui/material';
import React from 'react';
import colors from 'resources/colors';
import config from 'resources/config';
import dimens from 'resources/dimens';
import { theme } from 'resources/theme';

interface MarineWeatherPopupLabelProps {
  label: string;
  lat: number;
  lng: number;
}

export function MarineWeatherPopupLabel(props: MarineWeatherPopupLabelProps): JSX.Element {
  const { label, lat, lng } = props;

  return (
    <Box
      sx={{
        background: theme.palette.primary.main,
        color: colors.white,
        width: dimens.marineWeather.popup.width,
        bottom: dimens.marineWeather.popup.bottom,
        right: dimens.marineWeather.popup.right,
        position: 'absolute',
        overflow: 'hidden',
        pointerEvents: 'none',
      }}
    >
      <Typography variant="body2" sx={{ padding: '4px', textAlign: 'center' }}>
        {label}
      </Typography>
      {config.marineWeatherValueInspection && (
        <Typography variant="body2" sx={{ padding: '4px', textAlign: 'center' }}>
          {' 緯度 : ' + lat + ' 経度 : ' + lng}
        </Typography>
      )}
    </Box>
  );
}
