import React from 'react';
import colors from 'resources/colors';
import dimens from 'resources/dimens';
import { StatisticalValueType } from 'models/analysis';
import msgId from 'resources/intl';
import { useIntl } from 'react-intl';
import { theme } from 'resources/theme';
import { Lens, Remove, Stop } from '@mui/icons-material';
import { List, ListItem, MenuItem, styled, SxProps, Typography } from '@mui/material';
import { ChartSelectField } from './ChartSelectField';

const RootDiv = styled('div')({
  backgroundColor: colors.chart.header.background,
  color: colors.chart.text,
  width: dimens.chart.legend.width,
});

const HeaderDiv = styled('div')({
  width: dimens.chart.legend.width,
  padding: theme.spacing(0.5),
  backgroundColor: colors.chart.legend.background,
  '&.MuiOutlinedInput-inputMarginDense': {
    paddingTop: '1px',
    paddingBottom: '0px',
  },
});

const ShipHeaderDiv = styled('div')({
  width: '100%',
  padding: '4px 8px 4px 8px',
  background: colors.chart.header.background,
});

const StyledList = styled(List)({
  padding: 0,
  width: dimens.chart.legend.width,
  backgroundColor: colors.chart.legend.background,
  color: colors.chart.text,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'contain',
});

const StyledListItem = styled(ListItem)({
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1),
  '&.Mui-selected': {
    backgroundColor: colors.chart.legend.listItem.selected,
    '&:hover': {
      backgroundColor: colors.chart.legend.listItem.hover,
    },
  },
  borderBottom: `1px solid ${colors.chart.legend.border}`,
});

const iconProps: SxProps = {
  width: 16,
  height: 16,
  marginTop: theme.spacing(0.5),
  marginBottom: theme.spacing(0.5),
  marginLeft: '-2px',
  marginRight: theme.spacing(0.5),
};

export type IconType = 'circle' | 'square' | 'checkbox' | 'line';
export interface LegendIndex {
  groupIndex: number;
  itemIndex: number;
}

export interface Legend {
  color: string;
  title: string;
  iconType: IconType;
}

export interface LegendGroup {
  groupName: string;
  legends: Legend[];
}

interface ChartLegendListProps {
  legendGroups: LegendGroup[];
  selectedIndex: LegendIndex | undefined;
  statisticalValueType: StatisticalValueType;
  onChangeStatisticalValueType?: (valueType: StatisticalValueType) => void;
  onSelectChange?: (index: LegendIndex) => void;
  backgroundImage?: string;
  backgroundImageHeight?: string;
}

export function ChartLegendList(props: ChartLegendListProps): JSX.Element {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const {
    legendGroups,
    selectedIndex,
    statisticalValueType,
    onChangeStatisticalValueType,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onSelectChange,
    backgroundImage,
    backgroundImageHeight,
  } = props;
  const intl = useIntl();

  /**
   * センサー名変更
   * @param event イベント
   */
  const handleChangeValueType = (event: React.ChangeEvent<{ value: unknown }>) => {
    const newValueType = event.target.value as StatisticalValueType;
    if (newValueType != null) {
      onChangeStatisticalValueType && onChangeStatisticalValueType(newValueType);
    }
  };

  return (
    <RootDiv>
      {onChangeStatisticalValueType && (
        <HeaderDiv>
          <ChartSelectField
            id="statistical-value-type"
            value={statisticalValueType}
            onChange={handleChangeValueType}
          >
            <MenuItem value="mode">
              {intl.formatMessage({ id: msgId.statisticalValueMode })}
            </MenuItem>
            <MenuItem value="median">
              {intl.formatMessage({ id: msgId.statisticalValueMedian })}
            </MenuItem>
          </ChartSelectField>
        </HeaderDiv>
      )}
      {legendGroups.map((legendGroup, i) => {
        return (
          <React.Fragment key={'list-' + i}>
            {legendGroup.groupName && (
              <ShipHeaderDiv>
                <Typography variant="body2" noWrap sx={{ width: '100%' }}>
                  {legendGroup.groupName}
                </Typography>
              </ShipHeaderDiv>
            )}
            <StyledList sx={{
              backgroundImage: backgroundImage,
              height: backgroundImage? backgroundImageHeight : undefined,
              borderBottom: backgroundImage? `1px solid ${colors.chart.legend.border}` : undefined,
            }}>
              {legendGroup.legends.map((legend, j) => {
                const labelId = `label-${j}`;

                return (
                  <StyledListItem
                    key={labelId}
                    role={undefined}
                    selected={selectedIndex?.groupIndex === i && selectedIndex?.itemIndex === j}
                    dense
                    // button
                    // onClick={() => onSelectChange && onSelectChange({groupIndex: i, itemIndex: j})}
                  >
                    {legend.iconType === 'circle' && (
                      <Lens sx={iconProps} htmlColor={legend.color} />
                    )}
                    {legend.iconType === 'line' && (
                      <Remove sx={iconProps} htmlColor={legend.color} />
                    )}
                    {legend.iconType === 'square' && (
                      <Stop sx={iconProps} htmlColor={legend.color} viewBox="4 4 16 16" />
                    )}
                    <Typography variant="body2">{legend.title}</Typography>
                  </StyledListItem>
                );
              })}
            </StyledList>
          </React.Fragment>
        );
      })}
    </RootDiv>
  );
}

ChartLegendList.defaultProps = {
  selectedIndex: undefined,
  statisticalValueType: undefined,
};
