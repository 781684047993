import { API } from 'aws-amplify';
import { getErrorResult } from 'models/error';
import { MarineAriaData, MarineWeatherBundle2 } from 'models/marineWeather';
import config from 'resources/config';
import AppLogger from 'utils/AppLogger';
import { roundValue } from 'utils/misc';
import { API_NAME } from './maricoApi';

function generateMarineAreaDataList(data: number[]): MarineAriaData[][] {
  const sLat = config.marineWeatherOverlayBounds.left;
  const eLat = config.marineWeatherOverlayBounds.right - 0.0001; // 誤差分引く
  const sLng = config.marineWeatherOverlayBounds.top;
  const eLng = config.marineWeatherOverlayBounds.bottom - 0.0001; // 誤差分引く

  const areaData: MarineAriaData[][] = [];
  let i = 0;
  for (let lat = sLat; lat < eLat; lat += 0.025) {
    const latData: MarineAriaData[] = [];
    for (let lng = sLng; lng < eLng; lng += 0.025) {
      latData.push({
        latitude: roundValue(lat, 3),
        longitude: roundValue(lng, 3),
        isMarine: data[i] <= 0,
      });
      i++;
    }
    areaData.push(latData);
  }

  return areaData;
}

/**
 * 海域データリストを取得する。
 */
export async function getMarineWeatherAreaDataListAsync(): Promise<MarineAriaData[][]> {
  try {
    const params = {
      headers: {
        'Content-Type': 'application/json',
      },
      response: true,
      timeout: 30000,
    };
    const result = await API.get(API_NAME, '/v1/marine-weather/area-data', params);

    return generateMarineAreaDataList(result.data.areaData);
  } catch (error) {
    AppLogger.error('error getMarineWeatherAreaDataAsync: ', error);
    throw getErrorResult(error);
  }
}

/**
 * 気象海象情報データを取得する。
 * @param key キー
 */
export async function getMarineWeatherBundleAsync(
  key: string
): Promise<MarineWeatherBundle2 | undefined> {
  const dateAndHour = key.split('_');
  const date = dateAndHour[0];
  const hour = parseInt(dateAndHour[1], 10);

  try {
    const params = {
      headers: {
        'Content-Type': 'application/json',
      },
      body: {
        date: date,
        hour: hour,
      },
      response: true,
      timeout: 30000,
    };
    const result = await API.post(API_NAME, '/v1/marine-weather/data', params);
    const bundle: MarineWeatherBundle2 = {
      key: key,
      hour: result.data.hour,
      sigHeihtM: result.data.sigHeihtM,
      swellHeightM: result.data.swellHeightM,
      swellPeriodSecs: result.data.swellPeriodSecs,
      weathers: result.data.weathers,
    };

    return bundle;
  } catch (error) {
    const errorResult = getErrorResult(error);
    if (errorResult.status === 404) {
      return undefined;
    } else {
      AppLogger.error('error getMarineWeatherDataListAsync: ', error);
      throw errorResult;
    }
  }
}
