import { API, Storage } from 'aws-amplify';
import AppLogger from 'utils/AppLogger';
import dayjs from 'dayjs';
import { Machine } from 'models/ships';
import { parse, ParseConfig } from 'papaparse';
import config from 'resources/config';
import { roundValue, sleep } from 'utils/misc';
import { KeyObject } from 'models/common';
import {
  CommonSensorData,
  DataRequest,
  DataRequestKey,
  DataRequestResult,
  SensorDataList,
  ShipWaveData,
} from 'models/data';
import { API_NAME, readFileAsText } from './maricoApi';
import { getErrorResult } from 'models/error';
import { DiagnosisListItem } from 'models/diagnosis';
import {
  ConfirmedSearchResultItem,
  SearchRequest,
  SearchRequestResult,
  SensorDataSearchConditions,
} from 'models/search';
import constants from 'resources/constants';

export const sensorMap400 = {
  megSpdP1: 'MegSpdP1',
  megSpdS1: 'MegSpdS1',
  megTCSpdP1: 'MegTCSpdP1',
  megTCSpdS1: 'MegTCSpdS1',
  megTCbstpressP1: 'MegTCbstpressP1COM',
  megTCbstpressS1: 'MegTCbstpressS1COM',
  megLOTmpP1: 'MegLOTmpP1COM',
  megLOTmpS1: 'MegLOTmpS1COM',
  megLOpressP1: 'MegLOpressP1COM',
  megLOpressS1: 'MegLOpressS1COM',
  megFuelRackLvP1: 'MegFuelRackLvP1',
  megFuelRackLvS1: 'MegFuelRackLvS1',
  megHpP1: 'MegHpP1',
  megHpS1: 'MegHpS1',
  megNo1EXTGASTmpP1: 'MegNo1EXTGASTmpP1COM',
  megNo1EXTGASTmpS1: 'MegNo1EXTGASTmpS1COM',
  megNo2EXTGASTmpP1: 'MegNo2EXTGASTmpP1COM',
  megNo2EXTGASTmpS1: 'MegNo2EXTGASTmpS1COM',
  megNo3EXTGASTmpP1: 'MegNo3EXTGASTmpP1COM',
  megNo3EXTGASTmpS1: 'MegNo3EXTGASTmpS1COM',
  megNo4EXTGASTmpP1: 'MegNo4EXTGASTmpP1COM',
  megNo4EXTGASTmpS1: 'MegNo4EXTGASTmpS1COM',
  megNo5EXTGASTmpP1: 'MegNo5EXTGASTmpP1COM',
  megNo5EXTGASTmpS1: 'MegNo5EXTGASTmpS1COM',
  megNo6EXTGASTmpP1: 'MegNo6EXTGASTmpP1COM',
  megNo6EXTGASTmpS1: 'MegNo6EXTGASTmpS1COM',
  megNo7EXTGASTmpP1: 'MegNo7EXTGASTmpP1COM',
  megNo7EXTGASTmpS1: 'MegNo7EXTGASTmpS1COM',
  megNo8EXTGASTmpP1: 'MegNo8EXTGASTmpP1COM',
  megNo8EXTGASTmpS1: 'MegNo8EXTGASTmpS1COM',
  megNo9EXTGASTmpP1: 'MegNo9EXTGASTmpP1COM',
  megNo9EXTGASTmpS1: 'MegNo9EXTGASTmpS1COM',
  megInletAirTmpP1: undefined,
  megInletAirTmpS1: undefined,

  rexLOPressP1C2: 'RexLOPressP1MCOM',
  rexLOPressS1C2: 'RexLOPressS1MCOM',
  rexLOTmpP1C2: 'RexLOTmpP1MCOM',
  rexLOTmpS1C2: 'RexLOTmpS1MCOM',
  rexStrAngOrderP1: 'RexStrAngOrderP1',
  rexStrAngOrderS1: 'RexStrAngOrderS1',
  rexStrAngFBP1: 'RexStrAngFBP1',
  rexStrAngFBS1: 'RexStrAngFBS1',
  rexCPPBladeAngOrderP1: 'RexCPPBladeAngOrderP1',
  rexCPPBladeAngOrderS1: 'RexCPPBladeAngOrderS1',
  rexCPPBladeAngFBP1: 'RexCPPBladeAngFBP1',
  rexCPPBladeAngFBS1: 'RexCPPBladeAngFBS1',
  rexClutchengagedFBP1: 'RexClutchengagedFBP1',
  rexClutchengagedFBS1: 'RexClutchengagedFBS1',

  vesLatC1: 'VesLatC1MCOM',
  vesLonC1: 'VesLonC1MCOM',
  vesSpdSOGC1: 'VesSpdSOGC1MCOM',
  vesCourseTrueDirC1: 'VesCourseTrueDirC1MCOM',
  vesWindDirRelC1: 'VesWindDirRelC1',
  vesWindSpdRelC1: 'VesWindSpdRelC1MCOM',

  engSpdP: undefined,
  engFuelRackLvP: undefined,
  engSftHpP: undefined,
  engTcSpdP: undefined,
  engSpdS: undefined,
  engFuelRackLvS: undefined,
  engSftHpS: undefined,
  engTcSpdS: undefined,
  rexStrAngOrderP: undefined,
  rexStrAngOrderS: undefined,
  rexStrAngFbP: undefined,
  rexStrAngFbS: undefined,
  rexClutchEngFbP: undefined,
  rexClutchEngFbS: undefined,
  engChargedAirPressP: undefined,
  engChargedAirPressS: undefined,
  engLoPressP: undefined,
  engLoPressS: undefined,
  engLoInTempP: undefined,
  engLoInTempS: undefined,
  engNo1ExtGasTempP: undefined,
  engNo1ExtGasTempS: undefined,
  engNo2ExtGasTempP: undefined,
  engNo2ExtGasTempS: undefined,
  engNo3ExtGasTempP: undefined,
  engNo3ExtGasTempS: undefined,
  engNo4ExtGasTempP: undefined,
  engNo4ExtGasTempS: undefined,
  engNo5ExtGasTempP: undefined,
  engNo5ExtGasTempS: undefined,
  engNo6ExtGasTempP: undefined,
  engNo6ExtGasTempS: undefined,
  engTcInTemp145P: undefined,
  engTcInTemp145S: undefined,
  engTcInTemp236P: undefined,
  engTcInTemp236S: undefined,
  engTcOutTempP: undefined,
  engTcOutTempS: undefined,
  rexLOPressP: undefined,
  rexLoPressS: undefined,
  rexLoTempP: undefined,
  rexLoTempS: undefined,
  engChargedAirTempP: undefined,
  engChargedAirTempS: undefined,

  vesLat: undefined,
  vesLon: undefined,
  vesSog: undefined,
  vesHeading: undefined,
  vesWindDirMagnitudMagDeg: undefined,
  vesWindSpd: undefined,
};

export const sensorMap401 = {
  megSpdP1: 'MegSpdP1',
  megSpdS1: 'MegSpdS1',
  megTCSpdP1: 'MegTCSpdP1',
  megTCSpdS1: 'MegTCSpdS1',
  megTCbstpressP1: 'MegTCboostpressP1RIO',
  megTCbstpressS1: 'MegTCboostpressS1RIO',
  megLOTmpP1: undefined,
  megLOTmpS1: undefined,
  megLOpressP1: undefined,
  megLOpressS1: undefined,
  megFuelRackLvP1: 'MegFuelRackLvP1',
  megFuelRackLvS1: 'MegFuelRackLvS1',
  megHpP1: 'MegHpP1',
  megHpS1: 'MegHpS1',
  megNo1EXTGASTmpP1: 'MegNo1EXTGASTmpP1RIO',
  megNo1EXTGASTmpS1: 'MegNo1EXTGASTmpS1RIO',
  megNo2EXTGASTmpP1: 'MegNo2EXTGASTmpP1RIO',
  megNo2EXTGASTmpS1: 'MegNo2EXTGASTmpS1RIO',
  megNo3EXTGASTmpP1: 'MegNo3EXTGASTmpP1RIO',
  megNo3EXTGASTmpS1: 'MegNo3EXTGASTmpS1RIO',
  megNo4EXTGASTmpP1: 'MegNo4EXTGASTmpP1RIO',
  megNo4EXTGASTmpS1: 'MegNo4EXTGASTmpS1RIO',
  megNo5EXTGASTmpP1: 'MegNo5EXTGASTmpP1RIO',
  megNo5EXTGASTmpS1: 'MegNo5EXTGASTmpS1RIO',
  megNo6EXTGASTmpP1: 'MegNo6EXTGASTmpP1RIO',
  megNo6EXTGASTmpS1: 'MegNo6EXTGASTmpS1RIO',
  megNo7EXTGASTmpP1: 'MegNo7EXTGASTmpP1RIO',
  megNo7EXTGASTmpS1: 'MegNo7EXTGASTmpS1RIO',
  megNo8EXTGASTmpP1: 'MegNo8EXTGASTmpP1RIO',
  megNo8EXTGASTmpS1: 'MegNo8EXTGASTmpS1RIO',
  megNo9EXTGASTmpP1: 'MegNo9EXTGASTmpP1RIO',
  megNo9EXTGASTmpS1: 'MegNo9EXTGASTmpS1RIO',
  megInletAirTmpP1: 'MegInletAirTmpP1',
  megInletAirTmpS1: 'MegInletAirTmpS1',

  rexLOTmpP1B: 'RexLOTmpP1RIO',
  rexLOTmpS1B: 'RexLOTmpS1RIO',
  rexLOPressP1B: 'RexLOPressP1RIO',
  rexLOPressS1B: 'RexLOPressS1RIO',
  rexLOPressP1C2: null,
  rexLOPressS1C2: null,
  rexLOTmpP1C2: null,
  rexLOTmpS1C2: null,
  rexStrAngOrderP1: 'RexStrAngOrderP1',
  rexStrAngOrderS1: 'RexStrAngOrderS1',
  rexStrAngFBP1: 'RexStrAngFBP1',
  rexStrAngFBS1: 'RexStrAngFBS1',
  rexCPPBladeAngOrderP1: 'RexCPPBladeAngOrderP1',
  rexCPPBladeAngOrderS1: 'RexCPPBladeAngOrderS1',
  rexCPPBladeAngFBP1: 'RexCPPBladeAngFBP1',
  rexCPPBladeAngFBS1: 'RexCPPBladeAngFBS1',
  rexClutchengagedFBP1: 'RexClutchengagedFBP1',
  rexClutchengagedFBS1: 'RexClutchengagedFBS1',

  vesLatC1: 'VesLatC1GCOM',
  vesLonC1: 'VesLonC1GCOM',
  vesSpdSOGC1: 'VesSpdSogC1GCOM',
  vesCourseTrueDirC1: 'VesCourseTrueDirC1GCOM',
  vesWindDirRelC1: 'VesWindDirmagnitudeindgreeC1',
  vesWindSpdRelC1: 'VesWindSpdRelC1GCOM',

  engSpdP: undefined,
  engFuelRackLvP: undefined,
  engSftHpP: undefined,
  engTcSpdP: undefined,
  engSpdS: undefined,
  engFuelRackLvS: undefined,
  engSftHpS: undefined,
  engTcSpdS: undefined,
  rexStrAngOrderP: undefined,
  rexStrAngOrderS: undefined,
  rexStrAngFbP: undefined,
  rexStrAngFbS: undefined,
  rexClutchEngFbP: undefined,
  rexClutchEngFbS: undefined,
  engChargedAirPressP: undefined,
  engChargedAirPressS: undefined,
  engLoPressP: undefined,
  engLoPressS: undefined,
  engLoInTempP: undefined,
  engLoInTempS: undefined,
  engNo1ExtGasTempP: undefined,
  engNo1ExtGasTempS: undefined,
  engNo2ExtGasTempP: undefined,
  engNo2ExtGasTempS: undefined,
  engNo3ExtGasTempP: undefined,
  engNo3ExtGasTempS: undefined,
  engNo4ExtGasTempP: undefined,
  engNo4ExtGasTempS: undefined,
  engNo5ExtGasTempP: undefined,
  engNo5ExtGasTempS: undefined,
  engNo6ExtGasTempP: undefined,
  engNo6ExtGasTempS: undefined,
  engTcInTemp145P: undefined,
  engTcInTemp145S: undefined,
  engTcInTemp236P: undefined,
  engTcInTemp236S: undefined,
  engTcOutTempP: undefined,
  engTcOutTempS: undefined,
  rexLOPressP: undefined,
  rexLoPressS: undefined,
  rexLoTempP: undefined,
  rexLoTempS: undefined,
  engChargedAirTempP: undefined,
  engChargedAirTempS: undefined,

  vesLat: undefined,
  vesLon: undefined,
  vesSog: undefined,
  vesHeading: undefined,
  vesWindDirMagnitudMagDeg: undefined,
  vesWindSpd: undefined,
};

export const sensorMap402 = {
  megSpdP1: undefined,
  megSpdS1: undefined,
  megTCSpdP1: undefined,
  megTCSpdS1: undefined,
  megTCbstpressP1: undefined,
  megTCbstpressS1: undefined,
  megLOTmpP1: undefined,
  megLOTmpS1: undefined,
  megLOpressP1: undefined,
  megLOpressS1: undefined,
  megFuelRackLvP1: undefined,
  megFuelRackLvS1: undefined,
  megHpP1: undefined,
  megHpS1: undefined,
  megNo1EXTGASTmpP1: undefined,
  megNo1EXTGASTmpS1: undefined,
  megNo2EXTGASTmpP1: undefined,
  megNo2EXTGASTmpS1: undefined,
  megNo3EXTGASTmpP1: undefined,
  megNo3EXTGASTmpS1: undefined,
  megNo4EXTGASTmpP1: undefined,
  megNo4EXTGASTmpS1: undefined,
  megNo5EXTGASTmpP1: undefined,
  megNo5EXTGASTmpS1: undefined,
  megNo6EXTGASTmpP1: undefined,
  megNo6EXTGASTmpS1: undefined,
  megNo7EXTGASTmpP1: undefined,
  megNo7EXTGASTmpS1: undefined,
  megNo8EXTGASTmpP1: undefined,
  megNo8EXTGASTmpS1: undefined,
  megNo9EXTGASTmpP1: undefined,
  megNo9EXTGASTmpS1: undefined,
  megInletAirTmpP1: undefined,
  megInletAirTmpS1: undefined,

  rexLOTmpP1B: undefined,
  rexLOTmpS1B: undefined,
  rexLOPressP1B: undefined,
  rexLOPressS1B: undefined,
  rexLOPressP1C2: undefined,
  rexLOPressS1C2: undefined,
  rexLOTmpP1C2: undefined,
  rexLOTmpS1C2: undefined,
  rexStrAngOrderP1: undefined,
  rexStrAngOrderS1: undefined,
  rexStrAngFBP1: undefined,
  rexStrAngFBS1: undefined,
  rexCPPBladeAngOrderP1: undefined,
  rexCPPBladeAngOrderS1: undefined,
  rexCPPBladeAngFBP1: undefined,
  rexCPPBladeAngFBS1: undefined,
  rexClutchengagedFBP1: undefined,
  rexClutchengagedFBS1: undefined,

  vesLatC1: undefined,
  vesLonC1: undefined,
  vesSpdSOGC1: undefined,
  vesCourseTrueDirC1: undefined,
  vesWindDirRelC1: undefined,
  vesWindSpdRelC1: undefined,

  engSpdP: 'EngSpdP',
  engFuelRackLvP: 'EngFuelRackLvP',
  engSftHpP: 'EngSftHpP',
  engTcSpdP: 'EngTcSpdP',
  engSpdS: 'EngSpdS',
  engFuelRackLvS: 'EngFuelRackLvS',
  engSftHpS: 'EngSftHpS',
  engTcSpdS: 'EngTcSpdS',
  rexStrAngOrderP: 'RexStrAngOrderP',
  rexStrAngOrderS: 'RexStrAngOrderS',
  rexStrAngFbP: 'RexStrAngFbP',
  rexStrAngFbS: 'RexStrAngFbS',
  rexClutchEngFbP: 'RexClutchEngFbP',
  rexClutchEngFbS: 'RexClutchEngFbS',
  engChargedAirPressP: 'EngChargedAirPressP',
  engChargedAirPressS: 'EngChargedAirPressS',
  engLoPressP: 'EngLoPressP',
  engLoPressS: 'EngLoPressS',
  engLoInTempP: 'EngLoInTempP',
  engLoInTempS: 'EngLoInTempS',
  engNo1ExtGasTempP: 'EngNo1ExtGasTempP',
  engNo1ExtGasTempS: 'EngNo1ExtGasTempS',
  engNo2ExtGasTempP: 'EngNo2ExtGasTempP',
  engNo2ExtGasTempS: 'EngNo2ExtGasTempS',
  engNo3ExtGasTempP: 'EngNo3ExtGasTempP',
  engNo3ExtGasTempS: 'EngNo3ExtGasTempS',
  engNo4ExtGasTempP: 'EngNo4ExtGasTempP',
  engNo4ExtGasTempS: 'EngNo4ExtGasTempS',
  engNo5ExtGasTempP: 'EngNo5ExtGasTempP',
  engNo5ExtGasTempS: 'EngNo5ExtGasTempS',
  engNo6ExtGasTempP: 'EngNo6ExtGasTempP',
  engNo6ExtGasTempS: 'EngNo6ExtGasTempS',
  engTcInTemp145P: 'EngTcInTemp145P',
  engTcInTemp145S: 'EngTcInTemp145S',
  engTcInTemp236P: 'EngTcInTemp236P',
  engTcInTemp236S: 'EngTcInTemp236S',
  engTcOutTempP: 'EngTcOutTempP',
  engTcOutTempS: 'EngTcOutTempS',
  rexLOPressP: 'RexLOPressP',
  rexLoPressS: 'RexLoPressS',
  rexLoTempP: 'RexLoTempP',
  rexLoTempS: 'RexLoTempS',
  engChargedAirTempP: 'EngChargedAirTempP',
  engChargedAirTempS: 'EngChargedAirTempS',

  vesLat: 'VesLat',
  vesLon: 'VesLon',
  vesSog: 'VesSog',
  vesHeading: 'VesHeading',
  vesWindDirMagnitudMagDeg: 'VesWindDirMagnitudMagDeg',
  vesWindSpd: 'VesWindSpd',
};

export const shipWaveSensorMap400 = {
  vesLatC1: 'VesLatC1MCOM',
  vesLonC1: 'VesLonC1MCOM',
  vesSpdSOGC1: 'VesSpdSOGC1MCOM',
};

export const shipWaveSensorMap401 = {
  vesLatC1: 'VesLatC1GCOM',
  vesLonC1: 'VesLonC1GCOM',
  vesSpdSOGC1: 'VesSpdSogC1GCOM',
};

export const shipWaveSensorMap402 = {
  vesLat: 'VesLat',
  vesLon: 'VesLon',
  vesSog: 'VesSog',
};

export const reverseSensorMap: KeyObject = {
  MegSpdP1: 'megSpdP1',
  MegSpdS1: 'megSpdS1',
  MegTCSpdP1: 'megTCSpdP1',
  MegTCSpdS1: 'megTCSpdS1',
  MegTCbstpressP1COM: 'megTCbstpressP1',
  MegTCbstpressS1COM: 'megTCbstpressS1',
  MegTCboostpressP1RIO: 'megTCbstpressP1',
  MegTCboostpressS1RIO: 'megTCbstpressS1',
  MegFuelRackLvP1: 'megFuelRackLvP1',
  MegFuelRackLvS1: 'megFuelRackLvS1',
  MegHpP1: 'megHpP1',
  MegHpS1: 'megHpS1',
  MegLOpressP1COM: 'megLOpressP1',
  MegLOpressS1COM: 'megLOpressS1',
  MegLOTmpP1COM: 'megLOTmpP1',
  MegLOTmpS1COM: 'megLOTmpS1',
  MegNo1EXTGASTmpP1COM: 'megNo1EXTGASTmpP1',
  MegNo1EXTGASTmpS1COM: 'megNo1EXTGASTmpS1',
  MegNo2EXTGASTmpP1COM: 'megNo2EXTGASTmpP1',
  MegNo2EXTGASTmpS1COM: 'megNo2EXTGASTmpS1',
  MegNo3EXTGASTmpP1COM: 'megNo3EXTGASTmpP1',
  MegNo3EXTGASTmpS1COM: 'megNo3EXTGASTmpS1',
  MegNo4EXTGASTmpP1COM: 'megNo4EXTGASTmpP1',
  MegNo4EXTGASTmpS1COM: 'megNo4EXTGASTmpS1',
  MegNo5EXTGASTmpP1COM: 'megNo5EXTGASTmpP1',
  MegNo5EXTGASTmpS1COM: 'megNo5EXTGASTmpS1',
  MegNo6EXTGASTmpP1COM: 'megNo6EXTGASTmpP1',
  MegNo6EXTGASTmpS1COM: 'megNo6EXTGASTmpS1',
  MegNo7EXTGASTmpP1COM: 'megNo7EXTGASTmpP1',
  MegNo7EXTGASTmpS1COM: 'megNo7EXTGASTmpS1',
  MegNo8EXTGASTmpP1COM: 'megNo8EXTGASTmpP1',
  MegNo8EXTGASTmpS1COM: 'megNo8EXTGASTmpS1',
  MegNo9EXTGASTmpP1COM: 'megNo9EXTGASTmpP1',
  MegNo9EXTGASTmpS1COM: 'megNo9EXTGASTmpS1',
  MegNo1EXTGASTmpP1RIO: 'megNo1EXTGASTmpP1',
  MegNo1EXTGASTmpS1RIO: 'megNo1EXTGASTmpS1',
  MegNo2EXTGASTmpP1RIO: 'megNo2EXTGASTmpP1',
  MegNo2EXTGASTmpS1RIO: 'megNo2EXTGASTmpS1',
  MegNo3EXTGASTmpP1RIO: 'megNo3EXTGASTmpP1',
  MegNo3EXTGASTmpS1RIO: 'megNo3EXTGASTmpS1',
  MegNo4EXTGASTmpP1RIO: 'megNo4EXTGASTmpP1',
  MegNo4EXTGASTmpS1RIO: 'megNo4EXTGASTmpS1',
  MegNo5EXTGASTmpP1RIO: 'megNo5EXTGASTmpP1',
  MegNo5EXTGASTmpS1RIO: 'megNo5EXTGASTmpS1',
  MegNo6EXTGASTmpP1RIO: 'megNo6EXTGASTmpP1',
  MegNo6EXTGASTmpS1RIO: 'megNo6EXTGASTmpS1',
  MegNo7EXTGASTmpP1RIO: 'megNo7EXTGASTmpP1',
  MegNo7EXTGASTmpS1RIO: 'megNo7EXTGASTmpS1',
  MegNo8EXTGASTmpP1RIO: 'megNo8EXTGASTmpP1',
  MegNo8EXTGASTmpS1RIO: 'megNo8EXTGASTmpS1',
  MegNo9EXTGASTmpP1RIO: 'megNo9EXTGASTmpP1',
  MegNo9EXTGASTmpS1RIO: 'megNo9EXTGASTmpS1',
  MegInletAirTmpP1: 'megInletAirTmpP1',
  MegInletAirTmpS1: 'megInletAirTmpS1',

  RexStrAngOrderP1: 'rexStrAngOrderP1',
  RexStrAngOrderS1: 'rexStrAngOrderS1',
  RexStrAngFBP1: 'rexStrAngFBP1',
  RexStrAngFBS1: 'rexStrAngFBS1',
  RexCPPBladeAngOrderP1: 'rexCPPBladeAngOrderP1',
  RexCPPBladeAngOrderS1: 'rexCPPBladeAngOrderS1',
  RexCPPBladeAngFBP1: 'rexCPPBladeAngFBP1',
  RexCPPBladeAngFBS1: 'rexCPPBladeAngFBS1',
  RexClutchengagedFBP1: 'rexClutchengagedFBP1',
  RexClutchengagedFBS1: 'rexClutchengagedFBS1',
  RexLOTmpP1RIO: 'rexLOTmpP1',
  RexLOTmpS1RIO: 'rexLOTmpS1',
  RexLOPressP1MCOM: 'rexLOPressP1',
  RexLOPressS1MCOM: 'rexLOPressS1',
  RexLOTmpP1MCOM: 'rexLOTmpP1',
  RexLOTmpS1MCOM: 'rexLOTmpS1',
  RexLOPressP1RIO: 'rexLOPressP1',
  RexLOPressS1RIO: 'rexLOPressS1',
  VesLatC1MCOM: 'vesLatC1',
  VesLonC1MCOM: 'vesLonC1',
  VesSpdSOGC1MCOM: 'vesSpdSOGC1',
  VesCourseTrueDirC1MCOM: 'vesCourseTrueDirC1',
  VesWindDirRelC1: 'vesWindDirRelC1',
  VesWindSpdRelC1MCOM: 'vesWindSpdRelC1',
  VesLatC1GCOM: 'vesLatC1',
  VesLonC1GCOM: 'vesLonC1',
  VesSpdSogC1GCOM: 'vesSpdSOGC1',
  VesCourseTrueDirC1GCOM: 'vesCourseTrueDirC1',
  VesWindDirmagnitudeindgreeC1: 'vesWindDirRelC1',
  VesWindSpdRelC1GCOM: 'vesWindSpdRelC1',

  EngSpdP: 'engSpdP',
  EngFuelRackLvP: 'engFuelRackLvP',
  EngSftHpP: 'engSftHpP',
  EngTcSpdP: 'engTcSpdP',
  EngSpdS: 'engSpdS',
  EngFuelRackLvS: 'engFuelRackLvS',
  EngSftHpS: 'engSftHpS',
  EngTcSpdS: 'engTcSpdS',
  RexStrAngOrderP: 'rexStrAngOrderP',
  RexStrAngOrderS: 'rexStrAngOrderS',
  RexStrAngFbP: 'rexStrAngFbP',
  RexStrAngFbS: 'rexStrAngFbS',
  RexClutchEngFbP: 'rexClutchEngFbP',
  RexClutchEngFbS: 'rexClutchEngFbS',
  EngChargedAirPressP: 'engChargedAirPressP',
  EngChargedAirPressS: 'engChargedAirPressS',
  EngLoPressP: 'engLoPressP',
  EngLoPressS: 'engLoPressS',
  EngLoInTempP: 'engLoInTempP',
  EngLoInTempS: 'engLoInTempS',
  EngNo1ExtGasTempP: 'engNo1ExtGasTempP',
  EngNo1ExtGasTempS: 'engNo1ExtGasTempS',
  EngNo2ExtGasTempP: 'engNo2ExtGasTempP',
  EngNo2ExtGasTempS: 'engNo2ExtGasTempS',
  EngNo3ExtGasTempP: 'engNo3ExtGasTempP',
  EngNo3ExtGasTempS: 'engNo3ExtGasTempS',
  EngNo4ExtGasTempP: 'engNo4ExtGasTempP',
  EngNo4ExtGasTempS: 'engNo4ExtGasTempS',
  EngNo5ExtGasTempP: 'engNo5ExtGasTempP',
  EngNo5ExtGasTempS: 'engNo5ExtGasTempS',
  EngNo6ExtGasTempP: 'engNo6ExtGasTempP',
  EngNo6ExtGasTempS: 'engNo6ExtGasTempS',
  EngTcInTemp145P: 'engTcInTemp145P',
  EngTcInTemp145S: 'engTcInTemp145S',
  EngTcInTemp236P: 'engTcInTemp236P',
  EngTcInTemp236S: 'engTcInTemp236S',
  EngTcOutTempP: 'engTcOutTempP',
  EngTcOutTempS: 'engTcOutTempS',
  RexLOPressP: 'rexLOPressP',
  RexLoPressS: 'rexLoPressS',
  RexLoTempP: 'rexLoTempP',
  RexLoTempS: 'rexLoTempS',
  EngChargedAirTempP: 'engChargedAirTempP',
  EngChargedAirTempS: 'engChargedAirTempS',
  VesLat: 'vesLat',
  VesLon: 'vesLon',
  VesSog: 'vesSog',
  VesHeading: 'vesHeading',
  VesWindDirMagnitudMagDeg: 'vesWindDirMagnitudMagDeg',
  VesWindSpd: 'vesWindSpd',
};

/**
 * 航跡の無効値処理を行う。
 * 前回値と比較して0.01超過の場合に無効値とする。
 *
 * @param lonData 緯度データ配列
 * @param latData 経度データ配列
 */
export function exclusionShipWaveData(lonData: number[], latData: number[]) {
  let prevLon = NaN;
  let prevLat = NaN;
  for (let i = 0; i < lonData.length; i++) {
    const lon = lonData[i];
    const lat = latData[i];
    if (prevLon != lon || prevLat != lat) {
      if (!isNaN(lon) && !isNaN(lat) && !isNaN(prevLon) && !isNaN(prevLat)) {
        // doubleの演算誤差防止のため有効桁数6で丸める
        const powLat = roundValue(Math.pow(prevLat - lat, 2), 6);
        const powLon = roundValue(Math.pow(prevLon - lon, 2), 6);
        const dist = Math.sqrt(powLat + powLon);
        if (dist >= config.shipWaveDistanceThreshold) {
          latData[i] = NaN;
          lonData[i] = NaN;
        }
      }
    }
    prevLon = lon;
    prevLat = lat;
  }
}

/**
 * CVS形式の文字列を共通センサーデータに変換して返す。
 * @param machine 機械
 * @param csvString CSV形式の文字列
 */
export function mapToCommonThinnedSensorData(
  machine: Machine,
  sensorNames: string[],
  csvString: string[],
  samplePerMilliSeconds: number,
  date: string | undefined
): Promise<CommonSensorData> {
  return new Promise<CommonSensorData>((resolve, reject) => {
    const commonSensorData: CommonSensorData = {
      machineId: machine.machineId,
      dataFormatId: machine.dataFormatId,
      logdate: [],
      samplePerMilliSeconds: samplePerMilliSeconds,
      sensors: {
        megSpdP1: undefined,
        megSpdS1: undefined,
        megTCSpdP1: undefined,
        megTCSpdS1: undefined,
        megTCbstpressP1: undefined,
        megTCbstpressS1: undefined,
        megFuelRackLvP1: undefined,
        megFuelRackLvS1: undefined,
        megHpP1: undefined,
        megHpS1: undefined,
        megLOpressP1: undefined,
        megLOpressS1: undefined,
        megLOTmpP1: undefined,
        megLOTmpS1: undefined,
        megNo1EXTGASTmpP1: undefined,
        megNo1EXTGASTmpS1: undefined,
        megNo2EXTGASTmpP1: undefined,
        megNo2EXTGASTmpS1: undefined,
        megNo3EXTGASTmpP1: undefined,
        megNo3EXTGASTmpS1: undefined,
        megNo4EXTGASTmpP1: undefined,
        megNo4EXTGASTmpS1: undefined,
        megNo5EXTGASTmpP1: undefined,
        megNo5EXTGASTmpS1: undefined,
        megNo6EXTGASTmpP1: undefined,
        megNo6EXTGASTmpS1: undefined,
        megNo7EXTGASTmpP1: undefined,
        megNo7EXTGASTmpS1: undefined,
        megNo8EXTGASTmpP1: undefined,
        megNo8EXTGASTmpS1: undefined,
        megNo9EXTGASTmpP1: undefined,
        megNo9EXTGASTmpS1: undefined,
        megInletAirTmpP1: undefined,
        megInletAirTmpS1: undefined,
        rexStrAngOrderP1: undefined,
        rexStrAngOrderS1: undefined,
        rexStrAngFBP1: undefined,
        rexStrAngFBS1: undefined,
        rexCPPBladeAngOrderP1: undefined,
        rexCPPBladeAngOrderS1: undefined,
        rexCPPBladeAngFBP1: undefined,
        rexCPPBladeAngFBS1: undefined,
        rexClutchengagedFBP1: undefined,
        rexClutchengagedFBS1: undefined,
        rexLOTmpP1: undefined,
        rexLOTmpS1: undefined,
        rexLOPressP1: undefined,
        rexLOPressS1: undefined,
        vesLatC1: undefined,
        vesLonC1: undefined,
        vesSpdSOGC1: undefined,
        vesCourseTrueDirC1: undefined,
        vesWindDirRelC1: undefined,
        vesWindSpdRelC1: undefined,
        engSpdP: undefined,
        engFuelRackLvP: undefined,
        engSftHpP: undefined,
        engTcSpdP: undefined,
        engSpdS: undefined,
        engFuelRackLvS: undefined,
        engSftHpS: undefined,
        engTcSpdS: undefined,
        rexStrAngOrderP: undefined,
        rexStrAngOrderS: undefined,
        rexStrAngFbP: undefined,
        rexStrAngFbS: undefined,
        rexClutchEngFbP: undefined,
        rexClutchEngFbS: undefined,
        engChargedAirPressP: undefined,
        engChargedAirPressS: undefined,
        engLoPressP: undefined,
        engLoPressS: undefined,
        engLoInTempP: undefined,
        engLoInTempS: undefined,
        engNo1ExtGasTempP: undefined,
        engNo1ExtGasTempS: undefined,
        engNo2ExtGasTempP: undefined,
        engNo2ExtGasTempS: undefined,
        engNo3ExtGasTempP: undefined,
        engNo3ExtGasTempS: undefined,
        engNo4ExtGasTempP: undefined,
        engNo4ExtGasTempS: undefined,
        engNo5ExtGasTempP: undefined,
        engNo5ExtGasTempS: undefined,
        engNo6ExtGasTempP: undefined,
        engNo6ExtGasTempS: undefined,
        engTcInTemp145P: undefined,
        engTcInTemp145S: undefined,
        engTcInTemp236P: undefined,
        engTcInTemp236S: undefined,
        engTcOutTempP: undefined,
        engTcOutTempS: undefined,
        rexLOPressP: undefined,
        rexLoPressS: undefined,
        rexLoTempP: undefined,
        rexLoTempS: undefined,
        engChargedAirTempP: undefined,
        engChargedAirTempS: undefined,
        vesLat: undefined,
        vesLon: undefined,
        vesSog: undefined,
        vesHeading: undefined,
        vesWindDirMagnitudMagDeg: undefined,
        vesWindSpd: undefined,
      },
    };
    const csvStrings = csvString[0].split('\n');
    const header = csvStrings[0];

    const sensorNames: string[] = [];
    const sensors: { [sensorName: string]: number[] } = {};
    let count = 0;
    let blockCount = 0;
    let prevTimestamp = Number.MAX_SAFE_INTEGER;
    let endTimestamp = 0;
    if (date) {
      prevTimestamp =
        dayjs(date + 'T00:00:00Z')
          .utc()
          .valueOf() - samplePerMilliSeconds;
      endTimestamp = dayjs(date + 'T23:59:59Z')
        .utc()
        .valueOf();
    }
    csvString.forEach(async (csv, index) => {
      let csvData = csv;
      if (index > 0) {
        // ヘッダ情報を付加
        csvData = header + '\n' + csv;
      }
      while (blockCount !== index) {
        // 前のパースが終わるまで待つ
        await sleep(1000);
      }
      parse(csvData, {
        header: true,
        fastMode: false,
        worker: true,
        skipEmptyLines: true,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        error: function (error: any) {
          reject(error);
        },
        step: function (row) {
          const data = row.data as KeyObject;
          if (sensorNames.length === 0) {
            for (const key in data) {
              if (key !== 'logdate' && key !== 'machine_id') {
                sensorNames.push(key);
              }
            }
            sensorNames.forEach((x) => (sensors[x] = []));
          }

          let timestamp = dayjs(data['logdate'] + 'Z').valueOf();
          timestamp = Math.trunc(timestamp / samplePerMilliSeconds) * samplePerMilliSeconds;
          // ファイルデータが足りない場合補間する
          if (timestamp > prevTimestamp + samplePerMilliSeconds) {
            const t = prevTimestamp + samplePerMilliSeconds;
            for (let i = t; i < timestamp; i += samplePerMilliSeconds) {
              commonSensorData.logdate.push(i);
              sensorNames.forEach((x) => {
                sensors[x].push(NaN);
              });
              count += 1;
            }
          }
          commonSensorData.logdate.push(timestamp);
          sensorNames.forEach((x) => {
            sensors[x].push(data[x] === '' ? NaN : Number(data[x]));
          });
          count += 1;
          prevTimestamp = timestamp;
        },
        complete: function () {
          // ファイルデータが足りない場合補間する
          if (endTimestamp >= prevTimestamp + samplePerMilliSeconds) {
            const t = prevTimestamp + samplePerMilliSeconds;
            for (let i = t; i <= endTimestamp; i += samplePerMilliSeconds) {
              commonSensorData.logdate.push(i);
              sensorNames.forEach((x) => {
                sensors[x].push(NaN);
              });
              count += 1;
            }
          }

          if (sensors['VesLonC1MCOM'] != null && sensors['VesLatC1MCOM'] != null) {
            exclusionShipWaveData(sensors['VesLonC1MCOM'], sensors['VesLatC1MCOM']);
          }
          if (sensors['VesLonC1GCOM'] != null && sensors['VesLatC1GCOM'] != null) {
            exclusionShipWaveData(sensors['VesLonC1GCOM'], sensors['VesLatC1GCOM']);
          }
          if (sensors['VesLon'] != null && sensors['VesLat'] != null) {
            exclusionShipWaveData(sensors['VesLon'], sensors['VesLat']);
          }

          AppLogger.debug('All done! count=' + count);
          sensorNames.forEach((sensorName) => {
            if (sensors[sensorName]) {
              const sensorGroup = machine?.sensorGroups.find((y) =>
                y.sensors.find((z) => z.sensorName === sensorName)
              );
              const sensor = sensorGroup?.sensors.find((y) => y.sensorName === sensorName);
              if (sensorGroup != null && sensor != null) {
                commonSensorData.sensors[reverseSensorMap[sensorName]] = {
                  sensorGroup: sensorGroup,
                  sensor: sensor,
                  data: sensors[sensorName],
                  max: 0,
                  min: 0,
                  diagnosisStatus: undefined,
                };
              }
            }
          });
          blockCount += 1;
          if (csvString.length === blockCount) {
            resolve(commonSensorData);
          }
        },
      } as ParseConfig);
    });
  });
}

/**
 * データ生成結果を取得する。
 * @param dataRequest データ生成要求
 */
export async function dataResultAsync(dataRequest: DataRequest): Promise<DataRequestResult> {
  try {
    const params = {
      headers: {
        'Content-Type': 'application/json',
      },
      response: true,
    };
    const results = await API.get(API_NAME, '/v1/data/' + dataRequest.dataRequestId, params);

    return results.data as DataRequestResult;
  } catch (error) {
    AppLogger.error('error dataResult: ', error);
    throw getErrorResult(error);
  }
}

/**
 * CVS形式の文字列を共通センサーデータに変換して返す。
 * @param machine 機械
 * @param csvString CSV形式の文字列
 */
export function mapToCommonSensorData(
  confirmedSearchResultItem: ConfirmedSearchResultItem,
  sensorNames: string[],
  csvString: string[],
  samplePerMilliSeconds: number,
  date: string | undefined
): Promise<CommonSensorData> {
  const machine = confirmedSearchResultItem.machine;
  const startTime = dayjs(confirmedSearchResultItem.searchResultItem.startDate).valueOf();
  const endTime = dayjs(confirmedSearchResultItem.searchResultItem.endDate).valueOf();

  return new Promise<CommonSensorData>((resolve, reject) => {
    const commonSensorData: CommonSensorData = {
      machineId: machine.machineId,
      dataFormatId: machine.dataFormatId,
      logdate: [],
      samplePerMilliSeconds: samplePerMilliSeconds,
      sensors: {
        megSpdP1: undefined,
        megSpdS1: undefined,
        megTCSpdP1: undefined,
        megTCSpdS1: undefined,
        megTCbstpressP1: undefined,
        megTCbstpressS1: undefined,
        megFuelRackLvP1: undefined,
        megFuelRackLvS1: undefined,
        megHpP1: undefined,
        megHpS1: undefined,
        megLOpressP1: undefined,
        megLOpressS1: undefined,
        megLOTmpP1: undefined,
        megLOTmpS1: undefined,
        megNo1EXTGASTmpP1: undefined,
        megNo1EXTGASTmpS1: undefined,
        megNo2EXTGASTmpP1: undefined,
        megNo2EXTGASTmpS1: undefined,
        megNo3EXTGASTmpP1: undefined,
        megNo3EXTGASTmpS1: undefined,
        megNo4EXTGASTmpP1: undefined,
        megNo4EXTGASTmpS1: undefined,
        megNo5EXTGASTmpP1: undefined,
        megNo5EXTGASTmpS1: undefined,
        megNo6EXTGASTmpP1: undefined,
        megNo6EXTGASTmpS1: undefined,
        megNo7EXTGASTmpP1: undefined,
        megNo7EXTGASTmpS1: undefined,
        megNo8EXTGASTmpP1: undefined,
        megNo8EXTGASTmpS1: undefined,
        megNo9EXTGASTmpP1: undefined,
        megNo9EXTGASTmpS1: undefined,
        megInletAirTmpP1: undefined,
        megInletAirTmpS1: undefined,
        rexStrAngOrderP1: undefined,
        rexStrAngOrderS1: undefined,
        rexStrAngFBP1: undefined,
        rexStrAngFBS1: undefined,
        rexCPPBladeAngOrderP1: undefined,
        rexCPPBladeAngOrderS1: undefined,
        rexCPPBladeAngFBP1: undefined,
        rexCPPBladeAngFBS1: undefined,
        rexClutchengagedFBP1: undefined,
        rexClutchengagedFBS1: undefined,
        rexLOTmpP1: undefined,
        rexLOTmpS1: undefined,
        rexLOPressP1: undefined,
        rexLOPressS1: undefined,
        vesLatC1: undefined,
        vesLonC1: undefined,
        vesSpdSOGC1: undefined,
        vesCourseTrueDirC1: undefined,
        vesWindDirRelC1: undefined,
        vesWindSpdRelC1: undefined,
        engSpdP: undefined,
        engFuelRackLvP: undefined,
        engSftHpP: undefined,
        engTcSpdP: undefined,
        engSpdS: undefined,
        engFuelRackLvS: undefined,
        engSftHpS: undefined,
        engTcSpdS: undefined,
        rexStrAngOrderP: undefined,
        rexStrAngOrderS: undefined,
        rexStrAngFbP: undefined,
        rexStrAngFbS: undefined,
        rexClutchEngFbP: undefined,
        rexClutchEngFbS: undefined,
        engChargedAirPressP: undefined,
        engChargedAirPressS: undefined,
        engLoPressP: undefined,
        engLoPressS: undefined,
        engLoInTempP: undefined,
        engLoInTempS: undefined,
        engNo1ExtGasTempP: undefined,
        engNo1ExtGasTempS: undefined,
        engNo2ExtGasTempP: undefined,
        engNo2ExtGasTempS: undefined,
        engNo3ExtGasTempP: undefined,
        engNo3ExtGasTempS: undefined,
        engNo4ExtGasTempP: undefined,
        engNo4ExtGasTempS: undefined,
        engNo5ExtGasTempP: undefined,
        engNo5ExtGasTempS: undefined,
        engNo6ExtGasTempP: undefined,
        engNo6ExtGasTempS: undefined,
        engTcInTemp145P: undefined,
        engTcInTemp145S: undefined,
        engTcInTemp236P: undefined,
        engTcInTemp236S: undefined,
        engTcOutTempP: undefined,
        engTcOutTempS: undefined,
        rexLOPressP: undefined,
        rexLoPressS: undefined,
        rexLoTempP: undefined,
        rexLoTempS: undefined,
        engChargedAirTempP: undefined,
        engChargedAirTempS: undefined,
        vesLat: undefined,
        vesLon: undefined,
        vesSog: undefined,
        vesHeading: undefined,
        vesWindDirMagnitudMagDeg: undefined,
        vesWindSpd: undefined,
      },
    };
    const csvStrings = csvString[0].split('\n');
    const header = csvStrings[0];

    const sensorNames: string[] = [];
    const sensors: { [sensorName: string]: number[] } = {};
    let count = 0;
    let blockCount = 0;
    let prevTimestamp = Number.MAX_SAFE_INTEGER;
    let endTimestamp = 0;
    if (date) {
      prevTimestamp =
        dayjs(date + 'T00:00:00Z')
          .utc()
          .valueOf() - samplePerMilliSeconds;
      endTimestamp = dayjs(date + 'T23:59:59Z')
        .utc()
        .valueOf();
    }
    csvString.forEach(async (csv, index) => {
      let csvData = csv;
      if (index > 0) {
        // ヘッダ情報を付加
        csvData = header + '\n' + csv;
      }
      while (blockCount !== index) {
        // 前のパースが終わるまで待つ
        await sleep(1000);
      }
      parse(csvData, {
        header: true,
        fastMode: false,
        worker: true,
        skipEmptyLines: true,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        error: function (error: any) {
          reject(error);
        },
        step: function (row) {
          const data = row.data as KeyObject;
          if (sensorNames.length === 0) {
            for (const key in data) {
              if (key !== 'logdate' && key !== 'machine_id') {
                sensorNames.push(key);
              }
            }
            sensorNames.forEach((x) => (sensors[x] = []));
          }

          let timestamp = dayjs(data['logdate'] + 'Z').valueOf();
          timestamp = Math.trunc(timestamp / samplePerMilliSeconds) * samplePerMilliSeconds;
          if (startTime > timestamp || endTime < timestamp) {
            return;
          }

          // ファイルデータが足りない場合補間する
          if (timestamp > prevTimestamp + samplePerMilliSeconds) {
            const t = prevTimestamp + samplePerMilliSeconds;
            for (let i = t; i < timestamp; i += samplePerMilliSeconds) {
              commonSensorData.logdate.push(i);
              sensorNames.forEach((x) => {
                sensors[x].push(NaN);
              });
              count += 1;
            }
          }
          commonSensorData.logdate.push(timestamp);
          sensorNames.forEach((x) => {
            sensors[x].push(data[x] === '' ? NaN : Number(data[x]));
          });
          count += 1;
          prevTimestamp = timestamp;
        },
        complete: function () {
          // ファイルデータが足りない場合補間する
          if (endTimestamp >= prevTimestamp + samplePerMilliSeconds) {
            const t = prevTimestamp + samplePerMilliSeconds;
            for (let i = t; i <= endTimestamp; i += samplePerMilliSeconds) {
              commonSensorData.logdate.push(i);
              sensorNames.forEach((x) => {
                sensors[x].push(NaN);
              });
              count += 1;
            }
          }

          if (sensors['VesLonC1MCOM'] != null && sensors['VesLatC1MCOM'] != null) {
            exclusionShipWaveData(sensors['VesLonC1MCOM'], sensors['VesLatC1MCOM']);
          }
          if (sensors['VesLonC1GCOM'] != null && sensors['VesLatC1GCOM'] != null) {
            exclusionShipWaveData(sensors['VesLonC1GCOM'], sensors['VesLatC1GCOM']);
          }
          if (sensors['VesLon'] != null && sensors['VesLat'] != null) {
            exclusionShipWaveData(sensors['VesLon'], sensors['VesLat']);
          }

          AppLogger.debug('All done! count=' + count);
          sensorNames.forEach((sensorName) => {
            if (sensors[sensorName]) {
              const sensorGroup = machine?.sensorGroups.find((y) =>
                y.sensors.find((z) => z.sensorName === sensorName)
              );
              const sensor = sensorGroup?.sensors.find((y) => y.sensorName === sensorName);
              if (sensorGroup != null && sensor != null) {
                commonSensorData.sensors[reverseSensorMap[sensorName]] = {
                  sensorGroup: sensorGroup,
                  sensor: sensor,
                  data: sensors[sensorName],
                  max: 0,
                  min: 0,
                  diagnosisStatus: undefined,
                };
              }
            }
          });
          blockCount += 1;
          if (csvString.length === blockCount) {
            resolve(commonSensorData);
          }
        },
      } as ParseConfig);
    });
  });
}

/**
 * CVS形式の文字列をセンサーデータに変換して返す。
 * @param machine 機械
 * @param diagnosis 診断結果
 * @param csvString CSV形式の文字列
 */
export function mapToSensorDataTimeAdjust(
  machine: Machine | undefined,
  diagnosis: DiagnosisListItem | undefined,
  csvString: string,
  samplePerMilliSeconds: number,
  startDate: string,
  endDate: string
): Promise<SensorDataList> {
  return new Promise<SensorDataList>((resolve, reject) => {
    const sensorDataList: SensorDataList = {
      machineId: machine != null ? machine.machineId : 0,
      logdate: [],
      samplePerMilliSeconds: samplePerMilliSeconds,
      sensors: {},
    };
    const sensorNames: string[] = [];
    const startTime = dayjs(startDate).valueOf();
    const endTime = dayjs(endDate).valueOf();

    let count = 0;
    let prevTimestamp = Number.MAX_SAFE_INTEGER;
    parse(csvString, {
      header: true,
      fastMode: false,
      worker: true,
      skipEmptyLines: true,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      error: function (error: any) {
        reject(error);
      },
      step: function (row) {
        const data = row.data as KeyObject;
        if (sensorNames.length === 0) {
          for (const key in data) {
            if (key !== 'logdate' && key !== 'machine_id') {
              sensorNames.push(key);
            }
          }
          sensorNames.forEach((sensorName) => {
            const sensorGroup = machine?.sensorGroups.find((y) =>
              y.sensors.find((z) => z.sensorName === sensorName)
            );
            const sensor = sensorGroup?.sensors.find((y) => y.sensorName === sensorName);
            if (sensorGroup != null && sensor != null) {
              sensorDataList.sensors[sensorName] = {
                sensorGroup: sensorGroup,
                sensor: sensor,
                data: [],
                max: Number.MIN_SAFE_INTEGER,
                min: Number.MAX_VALUE,
                diagnosisStatus: undefined,
              };
            }
          });
        }

        let timestamp = dayjs(data['logdate'] + 'Z').valueOf();
        timestamp = Math.trunc(timestamp / samplePerMilliSeconds) * samplePerMilliSeconds;
        if (startTime > timestamp || endTime < timestamp) {
          return;
        }

        // ファイルデータが足りない場合補間する
        if (timestamp > prevTimestamp + samplePerMilliSeconds) {
          const t = prevTimestamp + samplePerMilliSeconds;
          for (let i = t; i < timestamp; i += samplePerMilliSeconds) {
            sensorDataList.logdate.push(i);
            sensorNames.forEach((x) => {
              sensorDataList.sensors[x].data.push(NaN);
            });
          }
        }
        sensorDataList.logdate.push(timestamp);
        sensorNames.forEach((x) => {
          if (sensorDataList.sensors[x] != null) {
            const value = data[x] === '' ? NaN : Number(data[x]);
            const val =
              sensorDataList.sensors[x].sensorGroup.displayUpperLimit === 360 ||
                sensorDataList.sensors[x].sensorGroup.displayUpperLimit === 359.9
                ? isNaN(value)
                  ? NaN
                  : value > 180
                    ? value - 360
                    : value
                : value;

            sensorDataList.sensors[x].data.push(val);
            if (sensorDataList.sensors[x].max < val) {
              sensorDataList.sensors[x].max = val;
            }
            if (sensorDataList.sensors[x].min > val) {
              sensorDataList.sensors[x].min = val;
            }
          }
        });
        count += 1;
        prevTimestamp = timestamp;
      },
      complete: function () {
        // ファイルデータが足りない場合補間する
        if (endTime >= prevTimestamp + samplePerMilliSeconds) {
          const t = prevTimestamp + samplePerMilliSeconds;
          for (let i = t; i <= endTime; i += samplePerMilliSeconds) {
            sensorDataList.logdate.push(i);
            sensorNames.forEach((x) => {
              sensorDataList.sensors[x].data.push(NaN);
            });
            count += 1;
          }
        }

        if (
          sensorDataList.sensors['VesLonC1MCOM'] != null &&
          sensorDataList.sensors['VesLatC1MCOM'] != null
        ) {
          exclusionShipWaveData(
            sensorDataList.sensors['VesLonC1MCOM'].data,
            sensorDataList.sensors['VesLatC1MCOM'].data
          );
        }
        if (
          sensorDataList.sensors['VesLonC1GCOM'] != null &&
          sensorDataList.sensors['VesLatC1GCOM'] != null
        ) {
          exclusionShipWaveData(
            sensorDataList.sensors['VesLonC1GCOM'].data,
            sensorDataList.sensors['VesLatC1GCOM'].data
          );
        }
        if (
          sensorDataList.sensors['VesLon'] != null &&
          sensorDataList.sensors['VesLat'] != null
        ) {
          exclusionShipWaveData(
            sensorDataList.sensors['VesLon'].data,
            sensorDataList.sensors['VesLat'].data
          );
        }

        AppLogger.debug('All done! count=' + count);
        // 診断結果があれば診断ステータスを設定
        if (diagnosis != null) {
          for (const key in sensorDataList.sensors) {
            if (key === diagnosis.sensorName) {
              sensorDataList.sensors[key].diagnosisStatus = diagnosis.status;
              break;
            }
          }
        }

        resolve(sensorDataList);
      },
    } as ParseConfig);
  });
}

/**
 * CVS形式の文字列をセンサーデータに変換して返す。
 * @param machine 機械
 * @param diagnosis 診断結果
 * @param csvString CSV形式の文字列
 */
export function mapToSensorData(
  machine: Machine | undefined,
  diagnosis: DiagnosisListItem | undefined,
  csvString: string,
  samplePerMilliSeconds: number,
  date: string | undefined
): Promise<SensorDataList> {
  return new Promise<SensorDataList>((resolve, reject) => {
    const sensorDataList: SensorDataList = {
      machineId: machine != null ? machine.machineId : 0,
      logdate: [],
      samplePerMilliSeconds: samplePerMilliSeconds,
      sensors: {},
    };
    const sensorNames: string[] = [];
    let count = 0;
    let prevTimestamp = Number.MAX_SAFE_INTEGER;
    let endTimestamp = 0;
    if (date) {
      prevTimestamp =
        dayjs(date + 'T00:00:00Z')
          .utc()
          .valueOf() - samplePerMilliSeconds;
      endTimestamp = dayjs(date + 'T23:59:59Z')
        .utc()
        .valueOf();
    }
    parse(csvString, {
      header: true,
      fastMode: false,
      worker: true,
      skipEmptyLines: true,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      error: function (error: any) {
        reject(error);
      },
      step: function (row) {
        const data = row.data as KeyObject;
        if (sensorNames.length === 0) {
          for (const key in data) {
            if (key !== 'logdate' && key !== 'machine_id') {
              sensorNames.push(key);
            }
          }
          sensorNames.forEach((sensorName) => {
            const sensorGroup = machine?.sensorGroups.find((y) =>
              y.sensors.find((z) => z.sensorName === sensorName)
            );
            const sensor = sensorGroup?.sensors.find((y) => y.sensorName === sensorName);
            if (sensorGroup != null && sensor != null) {
              sensorDataList.sensors[sensorName] = {
                sensorGroup: sensorGroup,
                sensor: sensor,
                data: [],
                max: Number.MIN_SAFE_INTEGER,
                min: Number.MAX_VALUE,
                diagnosisStatus: undefined,
              };
            }
          });
        }

        let timestamp = dayjs(data['logdate'] + 'Z').valueOf();
        timestamp = Math.trunc(timestamp / samplePerMilliSeconds) * samplePerMilliSeconds;
        // ファイルデータが足りない場合補間する
        if (timestamp > prevTimestamp + samplePerMilliSeconds) {
          const t = prevTimestamp + samplePerMilliSeconds;
          for (let i = t; i < timestamp; i += samplePerMilliSeconds) {
            sensorDataList.logdate.push(i);
            sensorNames.forEach((x) => {
              sensorDataList.sensors[x].data.push(NaN);
            });
          }
        }
        sensorDataList.logdate.push(timestamp);
        sensorNames.forEach((x) => {
          if (sensorDataList.sensors[x] != null) {
            const value = data[x] === '' ? NaN : Number(data[x]);
            const val =
              sensorDataList.sensors[x].sensorGroup.displayUpperLimit === 360 ||
                sensorDataList.sensors[x].sensorGroup.displayUpperLimit === 359.9
                ? isNaN(value)
                  ? NaN
                  : value > 180
                    ? value - 360
                    : value
                : value;

            sensorDataList.sensors[x].data.push(val);
            if (sensorDataList.sensors[x].max < val) {
              sensorDataList.sensors[x].max = val;
            }
            if (sensorDataList.sensors[x].min > val) {
              sensorDataList.sensors[x].min = val;
            }
          }
        });
        count += 1;
        prevTimestamp = timestamp;
      },
      complete: function () {
        // ファイルデータが足りない場合補間する
        if (endTimestamp >= prevTimestamp + samplePerMilliSeconds) {
          const t = prevTimestamp + samplePerMilliSeconds;
          for (let i = t; i <= endTimestamp; i += samplePerMilliSeconds) {
            sensorDataList.logdate.push(i);
            sensorNames.forEach((x) => {
              sensorDataList.sensors[x].data.push(NaN);
            });
            count += 1;
          }
        }

        if (
          sensorDataList.sensors['VesLonC1MCOM'] != null &&
          sensorDataList.sensors['VesLatC1MCOM'] != null
        ) {
          exclusionShipWaveData(
            sensorDataList.sensors['VesLonC1MCOM'].data,
            sensorDataList.sensors['VesLatC1MCOM'].data
          );
        }
        if (
          sensorDataList.sensors['VesLonC1GCOM'] != null &&
          sensorDataList.sensors['VesLatC1GCOM'] != null
        ) {
          exclusionShipWaveData(
            sensorDataList.sensors['VesLonC1GCOM'].data,
            sensorDataList.sensors['VesLatC1GCOM'].data
          );
        }
        if (
          sensorDataList.sensors['VesLon'] != null &&
          sensorDataList.sensors['VesLat'] != null
        ) {
          exclusionShipWaveData(
            sensorDataList.sensors['VesLon'].data,
            sensorDataList.sensors['VesLat'].data
          );
        }

        AppLogger.debug('All done! count=' + count);
        // 診断結果があれば診断ステータスを設定
        if (diagnosis != null) {
          for (const key in sensorDataList.sensors) {
            if (key === diagnosis.sensorName) {
              sensorDataList.sensors[key].diagnosisStatus = diagnosis.status;
              break;
            }
          }
        }

        resolve(sensorDataList);
      },
    } as ParseConfig);
  });
}

/**
 * CVS形式の文字列を船舶航跡データに変換して返す。
 * @param machine 機械
 * @param csvString CSV形式の文字列
 */
function mapToShipWaveData(
  machine: Machine,
  sensorNames: string[],
  csvString: string
): Promise<ShipWaveData> {
  return new Promise<ShipWaveData>((resolve, reject) => {
    const shipWaveData: ShipWaveData = {
      machineId: machine.machineId,
      sensors: {
        vesLatC1: undefined,
        vesLonC1: undefined,
        vesSpdSOGC1: undefined,
        vesLat: undefined,
        vesLon: undefined,
        vesSog: undefined,
      },
    };

    const sensorNames: string[] = [];
    const sensors: { [sensorName: string]: number[] } = {};
    let count = 0;
    let prevVesLatC1 = NaN;
    let prevVesLonC1 = NaN;
    let prevVesSpdSOGC1 = NaN;
    parse(csvString, {
      header: true,
      fastMode: false,
      worker: true,
      skipEmptyLines: true,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      error: function (error: any) {
        reject(error);
      },
      step: function (row) {
        const data = row.data as KeyObject;
        if (sensorNames.length === 0) {
          for (const key in data) {
            if (key !== 'logdate' && key !== 'machine_id') {
              sensorNames.push(key);
            }
          }
          sensorNames.forEach((x) => (sensors[x] = []));
        }

        const vesLatC1 = data[sensorNames[0]] === '' ? NaN : Number(data[sensorNames[0]]);
        const vesLonC1 = data[sensorNames[1]] === '' ? NaN : Number(data[sensorNames[1]]);
        const vesSpdSOGC1 = data[sensorNames[2]] === '' ? NaN : Number(data[sensorNames[2]]);

        if (
          prevVesLatC1 !== vesLatC1 ||
          prevVesLonC1 !== vesLonC1 ||
          prevVesSpdSOGC1 !== vesSpdSOGC1
        ) {
          sensors[sensorNames[0]].push(vesLatC1);
          sensors[sensorNames[1]].push(vesLonC1);
          sensors[sensorNames[2]].push(vesSpdSOGC1);
          prevVesLatC1 = vesLatC1;
          prevVesLonC1 = vesLonC1;
          prevVesSpdSOGC1 = vesSpdSOGC1;
          count += 1;
        }
      },
      complete: function () {
        exclusionShipWaveData(sensors[sensorNames[1]], sensors[sensorNames[0]]);

        AppLogger.debug('All done! count=' + count);
        sensorNames.forEach((sensorName) => {
          if (sensors[sensorName]) {
            const sensorGroup = machine?.sensorGroups.find((y) =>
              y.sensors.find((z) => z.sensorName === sensorName)
            );
            const sensor = sensorGroup?.sensors.find((y) => y.sensorName === sensorName);
            if (sensorGroup != null && sensor != null) {
              shipWaveData.sensors[reverseSensorMap[sensorName]] = {
                sensorGroup: sensorGroup,
                sensor: sensor,
                data: sensors[sensorName],
                max: 0,
                min: 0,
                diagnosisStatus: undefined,
              };
            }
          }
        });

        resolve(shipWaveData);
      },
    } as ParseConfig);
  });
}

/**
 * データ生成結果を取得Keyを返却する。
 * @param request データ生成要求ID
 */
export async function getDataResultKeyAsync(request: DataRequest): Promise<DataRequestKey> {
  // 最初の10秒は1秒間隔、残りは5秒間隔で15分間ポーリング
  let restMs = 60 * 15 * 1000;
  let sleepMs = 1000;
  let fastCall = 10;
  AppLogger.debug('dataRequestId:' + request.dataRequestId);
  try {
    while (restMs > 0) {
      await sleep(sleepMs);
      const dataRequestResult = await dataResultAsync(request);
      AppLogger.debug('status:' + dataRequestResult.status + ' rest:' + restMs);
      if (dataRequestResult.status === 'searching') {
        restMs -= sleepMs;
        fastCall--;
        if (fastCall === 0) {
          sleepMs = 5000;
        }
      } else if (dataRequestResult.status === 'completed') {
        return dataRequestResult.result;
      } else {
        throw getErrorResult(new Error('sensor data is failed.'));
      }
    }
    throw getErrorResult(new Error('sensor data is failed.'));
  } catch (error) {
    throw getErrorResult(error);
  }
}

/**
 * 共通センサーデータを取得する。
 * @param machine 機械
 * @param searchResultItem 検索結果アイテム
 */
export async function getCommonSensorDataAsync(
  confirmedSearchResultItem: ConfirmedSearchResultItem
): Promise<CommonSensorData> {
  try {
    const sd = dayjs(confirmedSearchResultItem.searchResultItem.startDate)
      .utc()
      .format(constants.dateFormat.iso8601);
    const ed = dayjs(confirmedSearchResultItem.searchResultItem.endDate)
      .utc()
      .format(constants.dateFormat.iso8601);
    let sensorNames;
    if (confirmedSearchResultItem.machine.dataFormatId === 400) {
      sensorNames = Object.values(sensorMap400)?.filter((x) => x != null) as string[];
    } else if (confirmedSearchResultItem.machine.dataFormatId === 401) {
      sensorNames = Object.values(sensorMap401)?.filter((x) => x != null) as string[];
    } else if (confirmedSearchResultItem.machine.dataFormatId === 402) {
      sensorNames = Object.values(sensorMap402)?.filter((x) => x != null) as string[];
    }

    if (sensorNames == null) {
      AppLogger.error('error getCommonSensorData: unknown dataFormatId');
      throw getErrorResult(null);
    }

    // アクセス可能なセンサー名でフィルター
    const accessableSensorNames: string[] = [];
    confirmedSearchResultItem.machine.sensorGroups.forEach((sensorGroup) => {
      Array.prototype.push.apply(
        accessableSensorNames,
        sensorGroup.sensors.map((x) => x.sensorName)
      );
    });
    const filteredSensorNames = sensorNames.filter((x) => accessableSensorNames.includes(x));
    if (filteredSensorNames.length === 0) {
      AppLogger.error('error getCommonSensorData: not found accessable sensor.');
      throw getErrorResult(null);
    }

    const params = {
      headers: {
        'Content-Type': 'application/json',
      },
      body: {
        machineId: confirmedSearchResultItem.machine.machineId,
        sensorNames: filteredSensorNames,
        startDate: sd,
        endDate: ed,
        thinningInterval: 1,
      },
      response: true,
      timeout: 30000,
    };
    const result = await API.post(API_NAME, '/v1/data', params);
    const { key, level, identityId } = await getDataResultKeyAsync(result.data);
    AppLogger.debug('getCommonSensorData key:' + key + ', ' + new Date().toLocaleTimeString());
    const data = (await Storage.get(key, {
      level,
      identityId,
      download: true,
    })) as { Body: Blob };
    const blob = data.Body;
    const csv: string[] = [];
    // 500000000だとstringのlengthに引っかかる、45だとparseのメモリエラーになる。40でも大丈夫だが余裕をもって35にする。
    const readSize = 200000000;  //  350000000;
    let current = 0;
    let rest = blob.size;
    while (rest > 0) {
      csv[csv.length] = await blob.slice(current, current + readSize).text();
      if (csv.length >= 2) {
        const lastIndex = csv[csv.length - 2].lastIndexOf('\n');
        if (lastIndex > 0) {
          // 最後の改行から最後までの文字列を一つ前のブロックに移動する
          csv[csv.length - 1] = csv[csv.length - 2].substring(lastIndex + 1) + csv[csv.length - 1];
          csv[csv.length - 2] = csv[csv.length - 2].substring(0, lastIndex);
        }
      }
      current += readSize;
      rest -= readSize;
    }

    return mapToCommonSensorData(confirmedSearchResultItem, sensorNames, csv, 100, undefined);
  } catch (error) {
    AppLogger.error('error getCommonSensorData: ', error);
    throw getErrorResult(error);
  }
}

/**
 * センサーデータを取得する。
 * @param machine 機械
 * @param sensorNames センサー名リスト
 * @param startDate 開始日付
 * @param endDate 終了日付
 */
export async function getSensorDataAsync(
  machine: Machine,
  sensorNames: string[],
  startDate: string,
  endDate: string
): Promise<SensorDataList> {
  try {
    const sd = dayjs(startDate).utc().format(constants.dateFormat.iso8601);
    const ed = dayjs(endDate).utc().format(constants.dateFormat.iso8601);
    const params = {
      headers: {
        'Content-Type': 'application/json',
      },
      body: {
        machineId: machine.machineId,
        sensorNames: sensorNames,
        startDate: sd,
        endDate: ed,
        thinningInterval: 1,
      },
      response: true,
      timeout: 30000,
    };
    const result = await API.post(API_NAME, '/v1/data', params);

    const { key, level, identityId } = await getDataResultKeyAsync(result.data);
    const data = (await Storage.get(key, {
      level,
      identityId,
      download: true,
    })) as { Body: Blob };
    const csv = await readFileAsText(data.Body);

    return mapToSensorDataTimeAdjust(machine, undefined, csv, 100, startDate, endDate);
  } catch (error) {
    AppLogger.error('error getSensorData: ', error);
    throw getErrorResult(error);
  }
}

/**
 * センサーデータ検索を要求する。
 * @param machineIds 機械IDリスト
 * @param searchConditions 検索条件
 */
export async function searchSensorDataRequestAsync(
  machineIds: number[],
  searchConditions: SensorDataSearchConditions
): Promise<SearchRequest> {
  try {
    const sd = dayjs(searchConditions.startDate)
      .utc()
      .format(constants.dateFormat.iso8601WithoutSeconds)
      .replace('00+', '00+');
    const ed = dayjs(searchConditions.endDate)
      .utc()
      .format(constants.dateFormat.iso8601WithoutSeconds)
      .replace('00+', '59+');
    let screeningMinutes = searchConditions.screeningMinutes;
    let condition = searchConditions.conditions;
    if (!searchConditions.enabled) {
      screeningMinutes = 0;
      condition = [];
    }
    const params = {
      headers: {
        'Content-Type': 'application/json',
      },
      body: {
        machineIds: machineIds,
        startDate: sd,
        endDate: ed,
        screeningMinutes: screeningMinutes,
        conditions: condition,
      },
      response: true,
    };

    const results = await API.post(API_NAME, '/v1/data/search', params);

    return results.data;
  } catch (error) {
    AppLogger.error('error searchSensorData: ', error);
    throw getErrorResult(error);
  }
}

/**
 * センサーデータ検索結果を取得する。
 * @param searchRequest 検索要求
 */
export async function searchSensorDataResultAsync(
  searchRequest: SearchRequest
): Promise<SearchRequestResult> {
  try {
    const params = {
      headers: {
        'Content-Type': 'application/json',
      },
      response: true,
    };
    const results = await API.get(
      API_NAME,
      '/v1/data/search/' + searchRequest.searchRequestId,
      params
    );
    const searchResult: SearchRequestResult = results.data;
    if (searchResult.status !== 'completed') {
      return searchResult;
    }

    searchResult.searchResults.forEach((x) => {
      x.results.forEach((y) => {
        y.machineId = x.machineId;
      });
    });

    searchResult.searchResults = searchResult.searchResults.sort((a, b) => {
      if (a.shipId > b.shipId) {
        return 1;
      } else if (a.shipId < b.shipId) {
        return -1;
      } else {
        return 0;
      }
    });

    return searchResult;
  } catch (error) {
    AppLogger.error('error searchSensorData: ', error);
    throw getErrorResult(error);
  }
}

/**
 * 航跡データを取得する。
 * @param confirmedSearchResultItem 確定検索結果アイテム
 */
export async function getShipWaveDataAsync(
  confirmedSearchResultItem: ConfirmedSearchResultItem
): Promise<ShipWaveData> {
  try {
    const inStart = dayjs(confirmedSearchResultItem.searchResultItem.startDate).utc();
    // const inEnd = dayjs(confirmedSearchResultItem.searchResultItem.endDate);
    const year = inStart.year();
    const month = inStart.month();
    const day = inStart.date();
    const start = dayjs(new Date(Date.UTC(year, month, day)));
    const end = start.add(1, 'day');
    // TODO 日跨ぎに対応

    let sensorNames;
    if (confirmedSearchResultItem.machine.dataFormatId === 400) {
      sensorNames = Object.values(shipWaveSensorMap400).filter((x) => x != null);
    } else if (confirmedSearchResultItem.machine.dataFormatId === 401) {
      sensorNames = Object.values(shipWaveSensorMap401).filter((x) => x != null);
    } else if (confirmedSearchResultItem.machine.dataFormatId === 402) {
      sensorNames = Object.values(shipWaveSensorMap402).filter((x) => x != null);
    }

    if (sensorNames == null) {
      AppLogger.error('error getShipWaveData: unknown dataFormatId');
      throw getErrorResult(null);
    }

    const params = {
      headers: {
        'Content-Type': 'application/json',
      },
      body: {
        machineId: confirmedSearchResultItem.machine.machineId,
        sensorNames: sensorNames,
        startDate: start.format(constants.dateFormat.iso8601),
        endDate: end.format(constants.dateFormat.iso8601),
        thinningInterval: config.shipWaveThinningInterval,
      },
      response: true,
      timeout: 30000,
    };
    const result = await API.post(API_NAME, '/v1/data', params);

    const { key, level, identityId } = await getDataResultKeyAsync(result.data);
    AppLogger.debug('getShipWaveData key:' + key);
    const data = (await Storage.get(key, {
      level,
      identityId,
      download: true,
    })) as { Body: Blob };
    const csv = await readFileAsText(data.Body);

    return mapToShipWaveData(confirmedSearchResultItem.machine, sensorNames, csv);
  } catch (error) {
    AppLogger.error('error getShipWaveData: ', error);
    throw getErrorResult(error);
  }
}
