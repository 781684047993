import React from 'react';
import { styled, Typography } from '@mui/material';
import dimens from 'resources/dimens';
import { VerticalTypography } from './VerticalTypography';
import { theme } from 'resources/theme';

const StyledHeader = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  padding: '4px',
  height: dimens.contentsHeader.height,
  width: '100%',
});

const StyledVerticalHeader = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '2px 1px 0px 1px',
  height: '100%',
  justifyContent: 'start',
});

interface ContentsHeaderProps {
  title?: string;
  subTitle?: string;
  children?: React.ReactNode;
  vertical: boolean;
}

export function ContentsHeader(props: ContentsHeaderProps): JSX.Element {
  const { title, subTitle, children, vertical } = props;

  if (!vertical) {
    return (
      <StyledHeader>
        <div>
          {title && (
            <Typography
              sx={{ padding: '0px 4px 0px 4px', color: theme.palette.text.secondary }}
              variant="h5"
              noWrap
            >
              {title}
            </Typography>
          )}
          {subTitle && (
            <Typography
              sx={{ padding: '0px 4px 0px 4px', color: theme.palette.text.secondary }}
              variant="h6"
              noWrap
            >
              {subTitle}
            </Typography>
          )}
        </div>
        <div style={{ flexGrow: 1 }} />
        {children}
      </StyledHeader>
    );
  } else {
    return (
      <StyledVerticalHeader>
        {children}
        {title && (
          <VerticalTypography variant="h5" noWrap sx={{ color: theme.palette.text.secondary }}>
            {title}
          </VerticalTypography>
        )}
        {subTitle && (
          <VerticalTypography variant="h5" noWrap sx={{ color: theme.palette.text.secondary }}>
            {subTitle}
          </VerticalTypography>
        )}
      </StyledVerticalHeader>
    );
  }
}

ContentsHeader.defaultProps = {
  vertical: false,
};
