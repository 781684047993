import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from 'app/store';
import { Machine, Ship } from 'models/ships';
import {} from 'models/data';
import dayjs from 'dayjs';
import { ReportItem, ReportResult } from 'models/reports';
import config from 'resources/config';
import { ErrorResult, getErrorResult } from 'models/error';
import AppLogger from 'utils/AppLogger';
import { cancelReportAsync, postReportAsync } from 'api/maricoApiReports';
import { RootState } from 'app/rootReducer';
import { useSelector } from 'react-redux';
import constants from 'resources/constants';
import { doFilterShips } from 'utils/misc';

/**
 * Report状態モデル
 */
interface ReportState {
  /** 船舶リスト */
  ships: Ship[] | undefined;
  /** 選択された機械リスト */
  checkedMachines: Machine[];
  /** フィルター済み船舶リスト */
  filteredShips: Ship[] | undefined;
  /** フィルター船舶名 */
  filterShipName: string;
  /** レポート進捗状態パネル開閉状態 */
  progressOpen: boolean;
  /** レポート作成中状態 */
  fetching: boolean;
  /** レポート作成エラー */
  fetchErrorResult: ErrorResult | undefined;
  /** レポートキャンセル中状態 */
  canceling: boolean;
  /** レポート要求アイテムリスト */
  reportItems: ReportItem[] | undefined;
  /** アラームレポート出力チェック状態 */
  alarmReportCheck: boolean;
  /** 運航レポート出力チェック状態 */
  operationReportCheck: boolean;
  /** 検索開始日時 */
  startDate: string;
  /** 検索終了日時 */
  endDate: string;
  /** 検索開始日時異常 */
  invalidStartDate: boolean;
  /** 検索終了日時異常 */
  invalidEndDate: boolean;
  /** 検索期間異常 */
  invalidRange: boolean;
  /** 検索可能かどうか */
  canSearch: boolean;
}

const initialDate = (offsetUnixtime: number) => {
  const unixTime = Math.floor((dayjs().unix() + offsetUnixtime) / 60) * 60; // 秒切り捨て

  return dayjs.unix(unixTime).format(constants.dateFormat.iso8601);
};

/**
 * レポート出力パネル初期状態
 */
const initialState: ReportState = {
  ships: undefined,
  checkedMachines: [],
  filteredShips: undefined,
  filterShipName: '',
  progressOpen: false,
  fetching: false,
  fetchErrorResult: undefined,
  canceling: false,
  reportItems: undefined,
  alarmReportCheck: true,
  operationReportCheck: true,
  startDate: initialDate(-24 * 60 * 60),
  endDate: initialDate(0),
  invalidStartDate: false,
  invalidEndDate: false,
  invalidRange: false,
  canSearch: true,
};

/**
 * 検索条件の整合性を確認する。
 * @param state Search状態
 */
const validSearchConditions = (state: ReportState): boolean => {
  const invalidDate = state.invalidStartDate || state.invalidEndDate || state.invalidRange;

  return !invalidDate;
};

export const reportCondition = createSlice({
  name: 'reportCondition',
  initialState,
  reducers: {
    /**
     * レポートの進捗状態パネルの開閉状態を設定する。
     */
    setReportProgressOpen: (state, { payload }: PayloadAction<boolean>) => {
      state.progressOpen = payload;
    },
    /**
     * レポートの取得状態を設定する。
     */
    setReportFetching: (state, { payload }: PayloadAction<boolean>) => {
      state.fetching = payload;
    },
    /**
     * レポート取得エラーを設定する。
     */
    setReportFetchError: (state, { payload }: PayloadAction<ErrorResult | undefined>) => {
      state.fetchErrorResult = payload;
      state.fetching = false;
      state.progressOpen = false;
    },
    setReportCanceling: (state, { payload }: PayloadAction<boolean>) => {
      state.canceling = payload;
    },
    setReportItems: (state, { payload }: PayloadAction<ReportItem[] | undefined>) => {
      state.reportItems = payload;
    },
    /**
     * フィルター船舶名を設定する。
     */
    setFilterShipName: (state, { payload }: PayloadAction<string>) => {
      state.filterShipName = payload;
      if (state.ships != null) {
        state.filteredShips = doFilterShips(state.ships, state.filterShipName);
      }
    },
    /**
     * 船舶リストを設定する。
     */
    setShips: (state, { payload }: PayloadAction<Ship[]>) => {
      const _ships = payload;
      const ships = _ships.map((ship) => {
        return {
          shipId: ship.shipId,
          name: ship.name,
          propulsionSerialNumbers: ship.propulsionSerialNumbers,
          machines: ship.machines.map((machine) => {
            return {
              machineId: machine.machineId,
              name: machine.name,
              serialNumbers: machine.serialNumbers,
              dataFormatId: machine.dataFormatId,
              sensorGroups: machine.sensorGroups.filter((x) =>
                x.sensors.find(
                  (y) => y.isSearch && y.displayLowerLimit != null && y.displayUpperLimit != null
                )
              ),
              checked: false,
            };
          }),
          createdAt: ship.createdAt,
          updatedAt: ship.updatedAt,
          checked: false,
          propulsionSerialNumbersText: ship.propulsionSerialNumbers.join(', '),
          machineNamesText: ship.machines.map((x) => x.name).join(', '),
        };
      });

      ships.forEach((ship) => {
        ship.machines.forEach((machine) => {
          machine.checked = state.checkedMachines.some((x) => x.machineId === machine.machineId);
        });
        ship.checked = ship.machines.every((x) => x.checked);
      });

      state.ships = ships;
      state.filteredShips = doFilterShips(state.ships, state.filterShipName);
    },
    setAlarmReportCheck: (state, { payload }: PayloadAction<boolean>) => {
      state.alarmReportCheck = payload;
    },
    setOperationReportCheck: (state, { payload }: PayloadAction<boolean>) => {
      state.operationReportCheck = payload;
    },
    /**
     * 船舶のチェック状態を設定する。
     */
    setShipCheck: (
      state,
      { payload }: PayloadAction<{ shipId: string; machineId: number; checked: boolean }>
    ) => {
      const ships = state.ships;
      if (ships == null) {
        return;
      }

      if (payload.machineId === 0) {
        const ship = ships.find((x) => x.shipId === payload.shipId);
        if (ship != null) {
          ship.checked = payload.checked;
          ship.machines.forEach((y) => (y.checked = payload.checked));
        }
      } else {
        const ship = ships.find((x) => x.shipId === payload.shipId);
        if (ship != null) {
          const machine = ships
            .find((x) => x.shipId === payload.shipId)
            ?.machines.find((y) => y.machineId === payload.machineId);
          if (machine != null) {
            machine.checked = payload.checked;
            ship.checked = ship.machines.some((x) => x.checked);
          }
        }
      }

      const machines: Machine[] = [];
      ships.forEach((ship) => {
        ship.machines.forEach((machine) => {
          if (machine.checked) {
            machines.push(machine);
          }
        });
      });

      state.checkedMachines = machines;
      state.ships = ships;
      state.filteredShips = doFilterShips(state.ships, state.filterShipName);
    },
    /**
     * 検索期間開始日時を設定する。
     */
    setStartDate: (state, action: PayloadAction<string>) => {
      state.startDate = action.payload;
      const now = dayjs();
      const start = dayjs(state.startDate);
      const end = dayjs(state.endDate);

      let invalidStartDate = start == null;
      let invalidEndDate = end == null;
      let invalidRange = false;
      if (start != null && end != null) {
        if (start > end) {
          invalidStartDate = true;
          invalidEndDate = true;
        }
        if (start > now) {
          invalidStartDate = true;
        }

        invalidRange =
          Math.floor(end.unix() / 60) - Math.floor(start.unix() / 60) >
          config.reportMaxDays * 24 * 60;
      }

      state.invalidStartDate = invalidStartDate;
      state.invalidEndDate = invalidEndDate;
      state.invalidRange = invalidRange;
      state.canSearch = validSearchConditions(state);
    },
    /**
     * 検索期間終了日時を設定する。
     */
    setEndDate: (state, action: PayloadAction<string>) => {
      state.endDate = action.payload;
      const now = dayjs();
      const start = dayjs(state.startDate);
      const end = dayjs(state.endDate);
      let invalidStartDate = start == null;
      let invalidEndDate = now == null;
      let invalidRange = false;
      if (start != null && end != null) {
        if (start > end) {
          invalidStartDate = true;
          invalidEndDate = true;
        }
        if (start > now) {
          invalidStartDate = true;
        }

        invalidRange =
          Math.floor(end.unix() / 60) - Math.floor(start.unix() / 60) >
          config.reportMaxDays * 24 * 60;
      }

      state.invalidStartDate = invalidStartDate;
      state.invalidEndDate = invalidEndDate;
      state.invalidRange = invalidRange;
      state.canSearch = validSearchConditions(state);
    },
    updateReportItemReportRequestId: (
      state,
      { payload }: PayloadAction<{ reportItem: ReportItem; reportRequestId: number }>
    ) => {
      const destReportItem = state.reportItems?.find(
        (x) => x.reportId == payload.reportItem.reportId
      );
      if (destReportItem) {
        destReportItem.reportRequestId = payload.reportRequestId;
      }
    },
    /**
     *
     */
    updateReportItemProgress: (
      state,
      { payload }: PayloadAction<{ reportItem: ReportItem; progress: number }>
    ) => {
      const destReportItem = state.reportItems?.find(
        (x) => x.reportId == payload.reportItem.reportId
      );
      if (destReportItem) {
        destReportItem.progress = payload.progress;
      }
    },
    updateReportItemResult: (
      state,
      { payload }: PayloadAction<{ reportItem: ReportItem; reportResult: ReportResult }>
    ) => {
      const destReportItem = state.reportItems?.find(
        (x) => x.reportId == payload.reportItem.reportId
      );
      if (destReportItem) {
        destReportItem.reportResult = payload.reportResult;
      }
    },
    /**
     * Reportを初期化する。
     */
    clearReport: (state) => {
      state.ships = initialState.ships;
      state.checkedMachines = initialState.checkedMachines;
      state.filteredShips = initialState.filteredShips;
      state.filterShipName = initialState.filterShipName;
      state.progressOpen = initialState.progressOpen;
      state.fetching = initialState.fetching;
      state.fetchErrorResult = initialState.fetchErrorResult;
      state.canceling = initialState.canceling;
      state.reportItems = initialState.reportItems;
      state.alarmReportCheck = initialState.alarmReportCheck;
      state.operationReportCheck = initialState.operationReportCheck;
      state.startDate = initialState.startDate;
      state.endDate = initialState.endDate;
      state.invalidStartDate = initialState.invalidStartDate;
      state.invalidEndDate = initialState.invalidEndDate;
      state.invalidRange = initialState.invalidRange;
      state.canSearch = initialState.canSearch;
    },
  },
});

export const {
  setReportProgressOpen,
  setReportFetchError,
  setReportItems,
  setFilterShipName,
  setShips,
  setAlarmReportCheck,
  setOperationReportCheck,
  setShipCheck,
  setStartDate,
  setEndDate,
  clearReport,
} = reportCondition.actions;

/**
 * レポート生成を要求する。
 */
export const fetchReport =
  (reportItems: ReportItem[], startDate: string, endDate: string): AppThunk =>
  async (dispatch) => {
    const fileNameBase =
      '_' +
      dayjs(startDate).utc().format('YYYYMMDDHHmm') +
      '_' +
      dayjs(endDate).utc().format('YYYYMMDDHHmm') +
      '_';
    const handleAccepted = (reportItem: ReportItem, reportRequestId: number) => {
      AppLogger.debug('handleAccepted reportRequestId=' + reportRequestId);
      dispatch(
        reportCondition.actions.updateReportItemReportRequestId({ reportItem, reportRequestId })
      );
    };
    const handleProgress = (reportItem: ReportItem, progress: number) => {
      AppLogger.debug(
        'handleProgress reportRequestId=' + reportItem.reportRequestId + ' progress=' + progress
      );
      dispatch(reportCondition.actions.updateReportItemProgress({ reportItem, progress }));
    };
    const handleCompeted = (
      reportItem: ReportItem,
      reportResult: ReportResult,
      blob: Blob | undefined
    ) => {
      AppLogger.debug(
        'handleCompeted reportRequestId=' +
          reportItem.reportRequestId +
          ' reportResult=' +
          reportResult
      );
      dispatch(reportCondition.actions.updateReportItemResult({ reportItem, reportResult }));
      if (blob) {
        const url = (window.URL || window.webkitURL).createObjectURL(blob);
        const link = document.createElement('a');
        link.download =
          reportItem.ship.name + fileNameBase + reportItem.reportType.toLowerCase() + '.pdf';
        link.href = url;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    };

    dispatch(reportCondition.actions.setReportItems(reportItems));
    dispatch(reportCondition.actions.setReportProgressOpen(true));
    dispatch(reportCondition.actions.setReportFetching(true));

    try {
      await Promise.all(
        reportItems.map((item) => {
          return postReportAsync(
            item,
            startDate,
            endDate,
            handleAccepted,
            handleProgress,
            handleCompeted
          );
        })
      );

      dispatch(reportCondition.actions.setReportFetching(false));
      dispatch(reportCondition.actions.setReportCanceling(false));
    } catch (error) {
      dispatch(reportCondition.actions.setReportFetchError(getErrorResult(error)));
    }
  };

/**
 * レポート生成をキャンセルする
 * @param reportItems
 * @param startDate
 * @param endDate
 * @returns
 */
export const cancelReport =
  (reportItems: ReportItem[]): AppThunk =>
  async (dispatch) => {
    const handleCanceled = (reportItem: ReportItem) => {
      dispatch(
        reportCondition.actions.updateReportItemResult({ reportItem, reportResult: 'Canceled' })
      );
    };

    const cancelReportItem = reportItems.filter((x) => x.reportResult === 'Generating');
    if (cancelReportItem.length > 0) {
      dispatch(reportCondition.actions.setReportCanceling(true));
      try {
        await Promise.all(
          cancelReportItem.map((item) => {
            AppLogger.debug(item.reportResult);

            return cancelReportAsync(item, handleCanceled);
          })
        );
      } catch (error) {
        AppLogger.warn(error);
      }
    }

    dispatch(reportCondition.actions.setReportItems(undefined));
  };

const reportState = (state: RootState) => state.report;
const selectFilteredShips = createSelector(reportState, (x) => x.filteredShips);
const selectProgressOpen = createSelector(reportState, (x) => x.progressOpen);
const selectFetchErrorResult = createSelector(reportState, (x) => x.fetchErrorResult);
const selectCanceling = createSelector(reportState, (x) => x.canceling);
const selectFilterShipName = createSelector(reportState, (x) => x.filterShipName);
const selectCheckedMachines = createSelector(reportState, (x) => x.checkedMachines);
const selectAlarmReportCheck = createSelector(reportState, (x) => x.alarmReportCheck);
const selectOperationReportCheck = createSelector(reportState, (x) => x.operationReportCheck);
const selectStartDate = createSelector(reportState, (x) => x.startDate);
const selectEndDate = createSelector(reportState, (x) => x.endDate);
const selectInvalidStartDate = createSelector(reportState, (x) => x.invalidStartDate);
const selectInvalidEndDate = createSelector(reportState, (x) => x.invalidEndDate);
const selectInvalidRange = createSelector(reportState, (x) => x.invalidRange);
const selectCanSearch = createSelector(reportState, (x) => x.canSearch);
const selectFetching = createSelector(reportState, (x) => x.fetching);
const selectReportItems = createSelector(reportState, (x) => x.reportItems);

export const useReportFilteredShips = () => useSelector(selectFilteredShips);
export const useReportProgressOpen = () => useSelector(selectProgressOpen);
export const useReportFetchErrorResult = () => useSelector(selectFetchErrorResult);
export const useReportCanceling = () => useSelector(selectCanceling);
export const useReportFilterShipName = () => useSelector(selectFilterShipName);
export const useReportCheckedMachines = () => useSelector(selectCheckedMachines);
export const useReportAlarmReportCheck = () => useSelector(selectAlarmReportCheck);
export const useReportOperationReportCheck = () => useSelector(selectOperationReportCheck);
export const useReportStartDate = () => useSelector(selectStartDate);
export const useReportEndDate = () => useSelector(selectEndDate);
export const useReportInvalidStartDate = () => useSelector(selectInvalidStartDate);
export const useReportInvalidEndDate = () => useSelector(selectInvalidEndDate);
export const useReportInvalidRange = () => useSelector(selectInvalidRange);
export const useReportCanSearch = () => useSelector(selectCanSearch);
export const useReportFetching = () => useSelector(selectFetching);
export const useReportItems = () => useSelector(selectReportItems);

export default reportCondition.reducer;
