import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import colors from 'resources/colors';
import { AnalysisData, StatisticalValueType } from 'models/analysis';
import { ChartHeaderPanel } from 'components/chart/ChartHeaderPanel';
import { LegendGroup, ChartLegendList } from '../common/ChartLegendList';
import { MegLoadChart } from './MegLoadChart';
import { ChartContainer, ChartContentsDiv, ChartDivider } from '../common/ChartContainer';
import msgId from 'resources/intl';
import { useDispatch } from 'react-redux';
import { ConfirmedSearchResultItem } from 'models/search';
import {
  setMegLoadExpanded,
  setMegLoadStatisticalValueType,
  useAnalysisMegLoadExpanded,
  useAnalysisMegLoadStatisticalValueType,
} from '../../analysisSlice';
import dimens from 'resources/dimens';

interface MegLoadContainerProps {
  chartId: string;
  confirmedSearchResultItem: ConfirmedSearchResultItem;
  analysisData: AnalysisData;
  chartHeight: number;
}

export function MegLoadContainer(props: MegLoadContainerProps): JSX.Element {
  const { chartId, confirmedSearchResultItem, analysisData, chartHeight } = props;
  const intl = useIntl();
  const dispatch = useDispatch();
  const expanded = useAnalysisMegLoadExpanded();
  const statisticalValueType = useAnalysisMegLoadStatisticalValueType();

  /**
   * CSV出力
   */
  const handleCsvOutput = () => {
    // noop
  };

  /**
   * 開閉状態変更
   * @param expanded 開閉状態
   */
  const handleExpandChanged = (expanded: boolean) => {
    dispatch(setMegLoadExpanded(expanded));
  };

  /**
   * 分析値タイプ変更
   * @param statisticalValueType 分析値タイプ
   */
  const handleChangeStatisticalValueType = (statisticalValueType: StatisticalValueType) => {
    dispatch(setMegLoadStatisticalValueType(statisticalValueType));
  };

  /**
   * 凡例リスト
   */
  const legendGroups: LegendGroup[] = [
    {
      groupName: analysisData.shipName + ' - ' + analysisData.machineName,
      legends: [
        {
          title: intl.formatMessage({ id: msgId.AnalysisMegBhpP1 }),
          color: colors.chart.lines[0],
          iconType: 'circle',
        },
        {
          title: intl.formatMessage({ id: msgId.AnalysisMegBhpS1 }),
          color: colors.chart.lines[1],
          iconType: 'circle',
        },
      ],
    },
  ];

  const analysisName =
    intl.formatMessage({ id: 'AnalysisSection' + analysisData.data.megLoad?.analysisSectionName }) +
    ' : ' +
    intl.formatMessage({ id: 'AnalysisMenu' + analysisData.data.megLoad?.analysisMenuName });

  const chart = useMemo(
    () => (
      <MegLoadChart
        sx={{
          width: `calc(100% - ${dimens.chart.legend.width}px - 1px)`,
        }}
        analysisData={analysisData}
        statisticalValueType={statisticalValueType}
        height={chartHeight}
      />
    ),
    [analysisData, statisticalValueType, chartHeight]
  );

  return (
    <ChartContainer
      chartId={chartId}
      shipName={analysisData.shipName}
      machineName={analysisData.machineName}
      analysisName={analysisName}
      confirmedSearchResultItem={confirmedSearchResultItem}
      title={analysisName}
      expanded={expanded}
      onExpandChanged={handleExpandChanged}
    >
      <ChartHeaderPanel
        chartId={chartId}
        sx={{ paddingRight: `${dimens.chart.legend.width}px` }}
        title={analysisName}
        titleEditable={false}
        csvOutputEnabled={false}
        csvOutputLocked={true}
      />
      <ChartContentsDiv>
        {chart}
        <ChartDivider orientation="vertical" flexItem />
        <ChartLegendList
          legendGroups={legendGroups}
          statisticalValueType={statisticalValueType}
          onChangeStatisticalValueType={handleChangeStatisticalValueType}
        />
      </ChartContentsDiv>
    </ChartContainer>
  );
}
