import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';
import kawasaki from 'resources/assets/IconKawasaki.svg';
import dimens from 'resources/dimens';
import { theme } from 'resources/theme';

const StyledImage = styled('img')({
  paddingTop: '4px',
  paddingBottom: '4px',
  marginLeft: theme.spacing(1),
});

// KHIアイコン
function KhiIcon(): JSX.Element {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <StyledImage src={kawasaki} alt={'KHI'} sx={{ height: dimens.toolbar.height }} />
    </Box>
  );
}
export default KhiIcon;
