export {};

// 監視ターゲットの取得
const target = document.getElementsByTagName('body');

// オブザーバーの作成
const observer = new MutationObserver((records) => {
  // 変化が発生したときの処理を記述
  if (records && records.length > 0 && records[0].target) {
    // ダイアログのボタンの並びを書き換え
    let element = records[0].target as HTMLDivElement;
    if (element && element.lastElementChild) {
      // cancel okボタンの位置調整(datepicker以外も変更になります)
      const toolbarButtons = element.lastElementChild.querySelectorAll(
        'div .dx-toolbar-items-container .dx-toolbar-after .dx-toolbar-button'
      );
      toolbarButtons.forEach((element) => {
        const button = element as HTMLDivElement;
        button.style.float = 'right';
        button.style.paddingTop = '10px';
      });
    }

    // 日付フィルター、期間のテキストを入力不可に書き換え
    // NOTE: 言語依存、言語が追加された場合は追加すること
    const labels = ['開始', '終了'];
    element = document.getElementsByClassName('dx-overlay-wrapper')[0] as HTMLDivElement;
    if (element) {
      labels.forEach((label) => {
        const placeholderElements = element.querySelectorAll(
          'div[data-dx_placeholder="' + label + '"]'
        );
        placeholderElements.forEach((placeholderElement) => {
          const inputElement = placeholderElement?.previousElementSibling as HTMLInputElement;
          if (inputElement) {
            const inputmode = inputElement.getAttribute('inputmode');
            if (inputmode !== 'decimal') {
              inputElement.readOnly = true;
            } else {
              inputElement.readOnly = false;
            }
          }
        });
      });
    }

    // 日付入力のフィルターを入力不可に書き換え
    const elements = [
      { id: 'accountDataGrid', component: 'datagrid', colIndex: 5 },
      { id: 'accountDataGrid', component: 'datagrid', colIndex: 6 },
      { id: 'accountGroupDataGrid', component: 'datagrid', colIndex: 4 },
      { id: 'accountGroupDataGrid', component: 'datagrid', colIndex: 5 },
      { id: 'shipDataGrid', component: 'datagrid', colIndex: 5 },
      { id: 'shipDataGrid', component: 'datagrid', colIndex: 6 },
      { id: 'diagnosisListItems', component: 'treelist', colIndex: 1 },
      { id: 'generalAccountDataGrid', component: 'datagrid', colIndex: 3 },
      { id: 'maintenanceDataGrid', component: 'datagrid', colIndex: 3 },
      { id: 'maintenanceDataGrid', component: 'datagrid', colIndex: 4 },
      { id: 'dataRecalcDataGrid', component: 'datagrid', colIndex: 1 },
      { id: 'dataRecalcDataGrid', component: 'datagrid', colIndex: 2 },
      { id: 'dataRecalcDataGrid', component: 'datagrid', colIndex: 3 },
    ];
    elements.forEach((element) => {
      const targetElement = document.getElementById(element.id);
      if (targetElement) {
        const dateFilterInput = targetElement.querySelector(
          '.dx-' + element.component + '-filter-row td[aria-colindex="' + element.colIndex + '"]'
        ) as HTMLElement;
        if (dateFilterInput) {
          const inputElement = dateFilterInput.querySelector(
            '.dx-texteditor-input'
          ) as HTMLInputElement;
          if (inputElement) {
            inputElement.readOnly = true;
          }
        }
      }
    });
  }
});

// 監視の開始
observer.observe(target[0], {
  childList: true,
});
