import React from 'react';
import { useIntl } from 'react-intl';
import msgId from 'resources/intl';
import Plotly, { Layout, LayoutAxis, PlotType, Shape, PlotMouseEvent } from 'plotly.js';
import createPlotlyComponent from 'react-plotly.js/factory';
import colors from 'resources/colors';
import { AnalysisData, AnalysisStatisticalValue, StatisticalValueType } from 'models/analysis';
import { getValueColor } from './MegTempLegendList';
import { Box, styled, SxProps } from '@mui/material';
import { roundValueToString } from 'utils/misc';

const Plot = createPlotlyComponent(Plotly);
const outTempColor = colors.chart.analysis.megTemp.outTempColor;
const axisFontColor = colors.chart.analysis.megTemp.axisFontColor;
const gridColor = colors.chart.analysis.megTemp.gridColor;
const y1TickColor = colors.chart.analysis.megTemp.y1TickColor;
const y2TickColor = colors.chart.analysis.megTemp.y2TickColor;
const y1TickFontColor = colors.chart.analysis.megTemp.y1TickFontColor;
const y2TickFontColor = colors.chart.analysis.megTemp.y2TickFontColor;

const HoverDiv = styled('div')({
  position: 'absolute',
  color: 'white',
  background: 'black',
  zIndex: 2,
  fontFamily: 'Arial, sans-serif',
  fontSize: 13,
});

const plotLStyle: React.CSSProperties = {
  width: '100%',
  height: 335,
  background: colors.chart.legend.background,
};

const plotRStyle: React.CSSProperties = {
  width: '100%',
  height: 420,
  background: colors.chart.legend.background,
};

interface MegTempChartProps {
  sx?: SxProps;
  analysisData: AnalysisData;
  selectedLegends: boolean[][];
  statisticalValueType: StatisticalValueType;
  width: number;
}

function unescapeHTML(escapedHtml: string, width: number): string {
  const text = width > 723 ? escapedHtml : escapedHtml.split('&lt;br&gt;')[0];
  const doc = new DOMParser().parseFromString(text, 'text/html');

  return doc.documentElement.textContent as string;
}
export function MegTempChart(props: MegTempChartProps): JSX.Element {
  const intl = useIntl();
  const { sx, analysisData, statisticalValueType, selectedLegends, width } = props;
  const contents = analysisData.data.megTemp;
  const analysisState = statisticalValueType;
  if (contents == null || contents.date.length === 0 || selectedLegends.length === 0) {
    return <Box sx={sx}></Box>;
  }

  const OutDataListL: Plotly.Data[] = [];
  const OutDataListR: Plotly.Data[] = [];
  const InDataListL: Plotly.Data[] = [];
  const InDataListR: Plotly.Data[] = [];
  const xAnalysisData: number[] = [];
  const yOutTempDataL: number[][] = [];
  const yOutTempDataR: number[][] = [];
  const yOutTempLineColorL: string[] = [];
  const yOutTempLineColorR: string[] = [];
  const yInTempDataL: number[] = [];
  const yInTempDataR: number[] = [];
  const yTestDataL: number[] = [];
  const yTestDataR: number[] = [];

  const yOutTempTextL: string[][] = [];
  const yOutTempTextR: string[][] = [];
  const yInTempTextL: string[] = [];
  const yInTempTextR: string[] = [];

  function getHoverInfoText(
    index: number,
    data: AnalysisStatisticalValue,
    unit: string,
    round: number
  ): string {
    let ret = '';
    const max = roundValueToString(data.max[index], round);
    const min = roundValueToString(data.min[index], round);
    const average = roundValueToString(data.average[index], round);
    const median = roundValueToString(data.median[index], round);
    const variance = roundValueToString(data.variance[index], round);
    const mode = roundValueToString(data.mode[index], round);
    ret = intl.formatMessage({ id: msgId.statisticalValueMax }) + ':' + max + unit + '<br>';
    ret += intl.formatMessage({ id: msgId.statisticalValueMin }) + ':' + min + unit + '<br>';
    ret +=
      intl.formatMessage({ id: msgId.statisticalValueAverage }) + ':' + average + unit + '<br>';
    ret += intl.formatMessage({ id: msgId.statisticalValueMedian }) + ':' + median + unit + '<br>';
    ret += intl.formatMessage({ id: msgId.statisticalValueVariance }) + ':' + variance + '<br>';
    ret += intl.formatMessage({ id: msgId.statisticalValueMode }) + ':' + mode + unit + '';

    return ret;
  }
  const nitiji = intl.formatMessage({ id: msgId.AnalysisHoverInfoLabelDate });
  selectedLegends[0].forEach((check, index) => {
    if (check && contents.date[index]) {
      const date = contents.date[index];
      const d = new Date(date);
      const dateStrings = d.toISOString().replace('T', ' ').split('-').join('/').split(':');
      dateStrings.pop();
      const dateString = dateStrings.join(':');

      let text = '';
      yOutTempTextL.push([]);
      yOutTempTextR.push([]);
      yOutTempDataL.push([]);
      yOutTempDataR.push([]);
      let asvL: AnalysisStatisticalValue;
      let asvR: AnalysisStatisticalValue;
      for (let i = 0; i < 9; i++) {
        if (analysisData.dataFormatId !== 402) {
          switch (i) {
            case 0:
              asvL = contents.MegNo1EXTGASTmpP1;
              asvR = contents.MegNo1EXTGASTmpS1;
              break;
            case 1:
              asvL = contents.MegNo2EXTGASTmpP1;
              asvR = contents.MegNo2EXTGASTmpS1;
              break;
            case 2:
              asvL = contents.MegNo3EXTGASTmpP1;
              asvR = contents.MegNo3EXTGASTmpS1;
              break;
            case 3:
              asvL = contents.MegNo4EXTGASTmpP1;
              asvR = contents.MegNo4EXTGASTmpS1;
              break;
            case 4:
              asvL = contents.MegNo5EXTGASTmpP1;
              asvR = contents.MegNo5EXTGASTmpS1;
              break;
            case 5:
              asvL = contents.MegNo6EXTGASTmpP1;
              asvR = contents.MegNo6EXTGASTmpS1;
              break;
            case 6:
              asvL = contents.MegNo7EXTGASTmpP1;
              asvR = contents.MegNo7EXTGASTmpS1;
              break;
            case 7:
              asvL = contents.MegNo8EXTGASTmpP1;
              asvR = contents.MegNo8EXTGASTmpS1;
              break;
            case 8:
              asvL = contents.MegNo9EXTGASTmpP1;
              asvR = contents.MegNo9EXTGASTmpS1;
              break;
            default:
              asvL = contents.MegNo1EXTGASTmpP1;
              asvR = contents.MegNo1EXTGASTmpS1;
              break;
          }
        } else {
          switch (i) {
            case 0:
              asvL = contents.EngNo1ExtGasTempP;
              asvR = contents.EngNo1ExtGasTempS;
              break;
            case 1:
              asvL = contents.EngNo2ExtGasTempP;
              asvR = contents.EngNo2ExtGasTempS;
              break;
            case 2:
              asvL = contents.EngNo3ExtGasTempP;
              asvR = contents.EngNo3ExtGasTempS;
              break;
            case 3:
              asvL = contents.EngNo4ExtGasTempP;
              asvR = contents.EngNo4ExtGasTempS;
              break;
            case 4:
              asvL = contents.EngNo5ExtGasTempP;
              asvR = contents.EngNo5ExtGasTempS;
              break;
            case 5:
              asvL = contents.EngNo6ExtGasTempP;
              asvR = contents.EngNo6ExtGasTempS;
              break;
            case 6:
              asvL = contents.EngTcInTemp145P;
              asvR = contents.EngTcInTemp145S;
              break;
            case 7:
              asvL = contents.EngTcInTemp236P;
              asvR = contents.EngTcInTemp236S;
              break;
            case 8:
              asvL = contents.EngTcOutTempP;
              asvR = contents.EngTcOutTempS;
              break;
            default:
              asvL = contents.EngNo1ExtGasTempP;
              asvR = contents.EngNo1ExtGasTempS;
              break;
          }
        }

        let megEXTGASTmpUnit: number;
        let megEXTGASTmpRoundingPosition: number;
        if (analysisData.dataFormatId !== 402) {
          megEXTGASTmpUnit = contents.displayInformations.MegEXTGASTmp.unit;
          megEXTGASTmpRoundingPosition = contents.displayInformations.MegEXTGASTmp.roundingPosition;
        } else {
          megEXTGASTmpUnit = contents.displayInformations.EngExtGasTemp.unit;
          megEXTGASTmpRoundingPosition = contents.displayInformations.EngExtGasTemp.roundingPosition;

        }

        text =
          nitiji +
          ':' +
          dateString +
          '<br>' +
          getHoverInfoText(
            index,
            asvL,
            megEXTGASTmpUnit + '',
            megEXTGASTmpRoundingPosition
          );
        yOutTempTextL[yOutTempTextL.length - 1].push(text);
        text =
          nitiji +
          ':' +
          dateString +
          '<br>' +
          getHoverInfoText(
            index,
            asvR,
            megEXTGASTmpUnit + '',
            megEXTGASTmpRoundingPosition
          );
        yOutTempTextR[yOutTempTextR.length - 1].push(text);

        if (analysisState === 'mode') {
          yOutTempDataL[yOutTempDataL.length - 1].push(asvL.mode[index]);
          yOutTempDataR[yOutTempDataR.length - 1].push(asvR.mode[index]);
        } else {
          yOutTempDataL[yOutTempDataL.length - 1].push(asvL.median[index]);
          yOutTempDataR[yOutTempDataR.length - 1].push(asvR.median[index]);
        }
      }

      if (analysisData.dataFormatId !== 402) {
        text =
          nitiji +
          ':' +
          dateString +
          '<br>' +
          getHoverInfoText(
            index,
            contents.MegInletAirTmpP1,
            contents.displayInformations.MegInletAirTmp.unit + '',
            contents.displayInformations.MegInletAirTmp.roundingPosition
          );
        yInTempTextL.push(text);
        text =
          nitiji +
          ':' +
          dateString +
          '<br>' +
          getHoverInfoText(
            index,
            contents.MegInletAirTmpS1,
            contents.displayInformations.MegInletAirTmp.unit + '',
            contents.displayInformations.MegInletAirTmp.roundingPosition
          );
        yInTempTextR.push(text);
        if (analysisState === 'mode') {
          yInTempDataL.push(contents.MegInletAirTmpP1.mode[index]);
          yInTempDataR.push(contents.MegInletAirTmpS1.mode[index]);
          yOutTempLineColorL.push(getValueColor(contents.MegHpP1.mode[index]));
          yOutTempLineColorR.push(getValueColor(contents.MegHpS1.mode[index]));
        } else {
          yInTempDataL.push(contents.MegInletAirTmpP1.median[index]);
          yInTempDataR.push(contents.MegInletAirTmpS1.median[index]);
          yOutTempLineColorL.push(getValueColor(contents.MegHpP1.median[index]));
          yOutTempLineColorR.push(getValueColor(contents.MegHpS1.median[index]));
        }
      } else {
        text =
          nitiji +
          ':' +
          dateString +
          '<br>' +
          getHoverInfoText(
            index,
            contents.EngChargedAirTempP,
            contents.displayInformations.EngChargedAirTemp.unit + '',
            contents.displayInformations.EngChargedAirTemp.roundingPosition
          );
        yInTempTextL.push(text);
        text =
          nitiji +
          ':' +
          dateString +
          '<br>' +
          getHoverInfoText(
            index,
            contents.EngChargedAirTempS,
            contents.displayInformations.EngChargedAirTemp.unit + '',
            contents.displayInformations.EngChargedAirTemp.roundingPosition
          );
        yInTempTextR.push(text);
        if (analysisState === 'mode') {
          yInTempDataL.push(contents.EngChargedAirTempP.mode[index]);
          yInTempDataR.push(contents.EngChargedAirTempS.mode[index]);
          yOutTempLineColorL.push(getValueColor(contents.EngSftHpP.mode[index]));
          yOutTempLineColorR.push(getValueColor(contents.EngSftHpS.mode[index]));
        } else {
          yInTempDataL.push(contents.EngChargedAirTempP.median[index]);
          yInTempDataR.push(contents.EngChargedAirTempS.median[index]);
          yOutTempLineColorL.push(getValueColor(contents.EngSftHpP.median[index]));
          yOutTempLineColorR.push(getValueColor(contents.EngSftHpS.median[index]));
        }
      }
    }
  });

  for (let i = 0; i < 9; i++) {
    xAnalysisData.push(i);
  }
  selectedLegends[0]
    .filter((ret) => ret)
    .forEach((val, index) => {
      const OutDataL = {
        x: xAnalysisData,
        y: yOutTempDataL[index],
        type: 'scatter' as PlotType,
        yaxis: 'y',
        mode: 'lines+markers',
        line: { color: yOutTempLineColorL[index] },
        text: yOutTempTextL[index],
        hovertemplate: '%{text}<extra></extra>',
        hoverinfo: 'text',
      } as Plotly.Data;
      const OutDataR = {
        x: xAnalysisData,
        y: yOutTempDataR[index],
        type: 'scatter' as PlotType,
        yaxis: 'y',
        mode: 'lines+markers',
        line: { color: yOutTempLineColorR[index] },
        text: yOutTempTextR[index],
        hovertemplate: '%{text}<extra></extra>',
        hoverinfo: 'text',
      } as Plotly.Data;
      const InDataL = {
        x: [9],
        y: [yInTempDataL[index]],
        type: 'scatter' as PlotType,
        yaxis: 'y2',
        mode: 'markers',
        text: [yInTempTextL[index]],
        hovertemplate: '%{text}<extra></extra>',
        hoverinfo: 'text',
        marker: { size: 5, color: outTempColor },
      } as Plotly.Data;
      const InDataR = {
        x: [9],
        y: [yInTempDataR[index]],
        type: 'scatter' as PlotType,
        yaxis: 'y2',
        mode: 'markers',
        text: [yInTempTextR[index]],
        hovertemplate: '%{text}<extra></extra>',
        hoverinfo: 'text',
        marker: { size: 5, color: outTempColor },
      } as Plotly.Data;

      OutDataListL.push(OutDataL);
      OutDataListR.push(OutDataR);
      InDataListL.push(InDataL);
      InDataListR.push(InDataR);
    });


  if (analysisData.dataFormatId !== 402) {
    yTestDataL.push(contents.factoryTestTemp.MegNo1EXTGASTmpP1);
    yTestDataL.push(contents.factoryTestTemp.MegNo2EXTGASTmpP1);
    yTestDataL.push(contents.factoryTestTemp.MegNo3EXTGASTmpP1);
    yTestDataL.push(contents.factoryTestTemp.MegNo4EXTGASTmpP1);
    yTestDataL.push(contents.factoryTestTemp.MegNo5EXTGASTmpP1);
    yTestDataL.push(contents.factoryTestTemp.MegNo6EXTGASTmpP1);
    yTestDataL.push(contents.factoryTestTemp.MegNo7EXTGASTmpP1);
    yTestDataL.push(contents.factoryTestTemp.MegNo8EXTGASTmpP1);
    yTestDataL.push(contents.factoryTestTemp.MegNo9EXTGASTmpP1);
    yTestDataL.push(contents.factoryTestTemp.MegInletAirTmpP1);
    yTestDataR.push(contents.factoryTestTemp.MegNo1EXTGASTmpS1);
    yTestDataR.push(contents.factoryTestTemp.MegNo2EXTGASTmpS1);
    yTestDataR.push(contents.factoryTestTemp.MegNo3EXTGASTmpS1);
    yTestDataR.push(contents.factoryTestTemp.MegNo4EXTGASTmpS1);
    yTestDataR.push(contents.factoryTestTemp.MegNo5EXTGASTmpS1);
    yTestDataR.push(contents.factoryTestTemp.MegNo6EXTGASTmpS1);
    yTestDataR.push(contents.factoryTestTemp.MegNo7EXTGASTmpS1);
    yTestDataR.push(contents.factoryTestTemp.MegNo8EXTGASTmpS1);
    yTestDataR.push(contents.factoryTestTemp.MegNo9EXTGASTmpS1);
    yTestDataR.push(contents.factoryTestTemp.MegInletAirTmpS1);
  } else {
    yTestDataL.push(contents.factoryTestTemp.EngNo1ExtGasTempP);
    yTestDataL.push(contents.factoryTestTemp.EngNo2ExtGasTempP);
    yTestDataL.push(contents.factoryTestTemp.EngNo3ExtGasTempP);
    yTestDataL.push(contents.factoryTestTemp.EngNo4ExtGasTempP);
    yTestDataL.push(contents.factoryTestTemp.EngNo5ExtGasTempP);
    yTestDataL.push(contents.factoryTestTemp.EngNo6ExtGasTempP);
    yTestDataL.push(contents.factoryTestTemp.EngTcInTemp145P);
    yTestDataL.push(contents.factoryTestTemp.EngTcInTemp236P);
    yTestDataL.push(contents.factoryTestTemp.EngTcOutTempP);
    yTestDataL.push(contents.factoryTestTemp.EngChargedAirTempP);
    yTestDataR.push(contents.factoryTestTemp.EngNo1ExtGasTempS);
    yTestDataR.push(contents.factoryTestTemp.EngNo2ExtGasTempS);
    yTestDataR.push(contents.factoryTestTemp.EngNo3ExtGasTempS);
    yTestDataR.push(contents.factoryTestTemp.EngNo4ExtGasTempS);
    yTestDataR.push(contents.factoryTestTemp.EngNo5ExtGasTempS);
    yTestDataR.push(contents.factoryTestTemp.EngNo6ExtGasTempS);
    yTestDataR.push(contents.factoryTestTemp.EngTcInTemp145S);
    yTestDataR.push(contents.factoryTestTemp.EngTcInTemp236S);
    yTestDataR.push(contents.factoryTestTemp.EngTcOutTempS);
    yTestDataR.push(contents.factoryTestTemp.EngChargedAirTempS);
  }

  const OutDataL = {
    x: xAnalysisData,
    y: yTestDataL,
    type: 'scatter' as PlotType,
    yaxis: 'y',
    mode: 'lines+markers',
    line: { color: 'red', dash: 'dot' },
    hoverinfo: 'none',
  } as Plotly.Data;
  const OutDataR = {
    x: xAnalysisData,
    y: yTestDataR,
    type: 'scatter' as PlotType,
    yaxis: 'y',
    mode: 'lines+markers',
    line: { color: 'red', dash: 'dot' },
    hoverinfo: 'none',
  } as Plotly.Data;
  OutDataListL.push(OutDataL);
  OutDataListR.push(OutDataR);

  const xtickVals: number[] = [];
  const xtickText: string[] = [];
  for (let i = 0; i < 10; i++) {
    xtickVals.push(i);
    switch (i) {
      case 0:
        xtickText.push(
          unescapeHTML(intl.formatMessage({ id: msgId.AnalysisMegTempNo1Label }), width)
        );
        break;
      case 1:
        xtickText.push(
          unescapeHTML(intl.formatMessage({ id: msgId.AnalysisMegTempNo2Label }), width)
        );
        break;
      case 2:
        xtickText.push(
          unescapeHTML(intl.formatMessage({ id: msgId.AnalysisMegTempNo3Label }), width)
        );
        break;
      case 3:
        xtickText.push(
          unescapeHTML(intl.formatMessage({ id: msgId.AnalysisMegTempNo4Label }), width)
        );
        break;
      case 4:
        xtickText.push(
          unescapeHTML(intl.formatMessage({ id: msgId.AnalysisMegTempNo5Label }), width)
        );
        break;
      case 5:
        xtickText.push(
          unescapeHTML(intl.formatMessage({ id: msgId.AnalysisMegTempNo6Label }), width)
        );
        break;
      case 6:
        xtickText.push(
          unescapeHTML(intl.formatMessage({ id: msgId.AnalysisMegTempNo7Label }), width)
        );
        break;
      case 7:
        xtickText.push(
          unescapeHTML(intl.formatMessage({ id: msgId.AnalysisMegTempNo8Label }), width)
        );
        break;
      case 8:
        xtickText.push(
          unescapeHTML(intl.formatMessage({ id: msgId.AnalysisMegTempNo9Label }), width)
        );
        break;
      case 9:
        xtickText.push(
          unescapeHTML(intl.formatMessage({ id: msgId.AnalysisMegTempInLabel }), width)
        );
        break;
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const xtickTextDummy = xtickText.map((_) => '');

  const xaxisL = {
    tickvals: xtickVals,
    ticktext: xtickTextDummy,
    range: [xtickVals[0] - 0.5, xtickVals[xtickVals.length - 1] + 0.5],
    showgrid: true,
    gridcolor: gridColor,
    tickfont: { color: axisFontColor, size: 13 },
  } as Partial<LayoutAxis>;

  const xaxisR = {
    title: {
      text: intl.formatMessage({ id: msgId.AnalysisMegEXTGASTmpXTitle }),
      font: { color: axisFontColor },
    },
    tickvals: xtickVals,
    ticktext: xtickText,
    range: [xtickVals[0] - 0.5, xtickVals[xtickVals.length - 1] + 0.5],
    showgrid: true,
    gridcolor: gridColor,
    tickfont: { color: axisFontColor, size: 13 },
  } as Partial<LayoutAxis>;

  let megEXTGASTmpRangeMax: number;
  let megEXTGASTmpRangeMin: number;
  let megEXTGASTmpRoundingPosition: number;
  let megEXTGASTmpUnit: number;
  let megInletAirTmpRangeMax: number;
  let megInletAirTmpRangeMin: number;
  let megInletAirTmpRoundingPosition: number;
  let megInletAirTmpUnit: number;
  if (analysisData.dataFormatId !== 402) {
    megEXTGASTmpRangeMax = contents.displayInformations.MegEXTGASTmp.rangeMax;
    megEXTGASTmpRangeMin = contents.displayInformations.MegEXTGASTmp.rangeMin;
    megEXTGASTmpRoundingPosition = contents.displayInformations.MegEXTGASTmp.roundingPosition;
    megEXTGASTmpUnit = contents.displayInformations.MegEXTGASTmp.unit;
    megInletAirTmpRangeMax = contents.displayInformations.MegInletAirTmp.rangeMax;
    megInletAirTmpRangeMin = contents.displayInformations.MegInletAirTmp.rangeMin;
    megInletAirTmpRoundingPosition = contents.displayInformations.MegInletAirTmp.roundingPosition;
    megInletAirTmpUnit = contents.displayInformations.MegInletAirTmp.unit;
  } else {
    megEXTGASTmpRangeMax = contents.displayInformations.EngExtGasTemp.rangeMax;
    megEXTGASTmpRangeMin = contents.displayInformations.EngExtGasTemp.rangeMin;
    megEXTGASTmpRoundingPosition = contents.displayInformations.EngExtGasTemp.roundingPosition;
    megEXTGASTmpUnit = contents.displayInformations.EngExtGasTemp.unit;
    megInletAirTmpRangeMax = contents.displayInformations.EngChargedAirTemp.rangeMax;
    megInletAirTmpRangeMin = contents.displayInformations.EngChargedAirTemp.rangeMin;
    megInletAirTmpRoundingPosition = contents.displayInformations.EngChargedAirTemp.roundingPosition;
    megInletAirTmpUnit = contents.displayInformations.EngChargedAirTemp.unit;
  }

  const rate =
    megEXTGASTmpRangeMax /
    megInletAirTmpRangeMax;
  let min = megEXTGASTmpRangeMin;
  let round = megEXTGASTmpRoundingPosition;
  const bhpDTick =
    (megEXTGASTmpRangeMax -
      megEXTGASTmpRangeMin) /
    3;
  const yaxis = {
    title: {
      text:
        intl.formatMessage({ id: msgId.AnalysisMegEXTGASTmpYTitle }) +
        '[' +
        megEXTGASTmpUnit +
        ']',
      font: { color: axisFontColor },
    },
    tickmode: 'array',
    tickvals: [bhpDTick * 0 + min, bhpDTick * 1 + min, bhpDTick * 2 + min, bhpDTick * 3 + min],
    ticktext: [
      roundValueToString(bhpDTick * 0 + min, round),
      roundValueToString(bhpDTick * 1 + min, round),
      roundValueToString(bhpDTick * 2 + min, round),
      roundValueToString(bhpDTick * 3 + min, round),
    ],
    tickfont: { color: y1TickFontColor, size: 13 },
    range: [
      megEXTGASTmpRangeMin,
      megEXTGASTmpRangeMax + rate,
    ],
    showgrid: true,
    gridcolor: gridColor,
  };
  const spdDTick =
    (megInletAirTmpRangeMax -
      megInletAirTmpRangeMin) /
    3;
  min = megInletAirTmpRangeMin;
  round = megInletAirTmpRoundingPosition;
  const yaxis2 = {
    title: {
      text:
        intl.formatMessage({ id: msgId.AnalysisMegInletAirTmpYTitle }) +
        '[' +
        megInletAirTmpUnit +
        ']',
      font: { color: axisFontColor },
    },
    tickmode: 'array',
    side: 'right',
    overlaying: 'y',
    tickvals: [spdDTick * 0 + min, spdDTick * 1 + min, spdDTick * 2 + min, spdDTick * 3 + min],
    ticktext: [
      roundValueToString(spdDTick * 0 + min, round),
      roundValueToString(spdDTick * 1 + min, round),
      roundValueToString(spdDTick * 2 + min, round),
      roundValueToString(spdDTick * 3 + min, round),
    ],
    tickfont: { color: y2TickFontColor, size: 13 },
    range: [
      megInletAirTmpRangeMin,
      megInletAirTmpRangeMax + 1,
    ],
    showgrid: true,
    gridcolor: gridColor,
  };
  const shapeOutLine: Partial<Shape> = {
    type: 'line',
    xref: 'paper',
    yref: 'y',
    x0: 0,
    y0: 0,
    x1: 0,
    y1: megEXTGASTmpRangeMax,
    line: {
      color: y1TickColor,
      width: 2,
    },
  };

  const shapeInLine: Partial<Shape> = {
    type: 'line',
    xref: 'paper',
    yref: 'y2',
    x0: 1,
    y0: megInletAirTmpRangeMax,
    x1: 1,
    y1: 0,
    line: {
      color: y2TickColor,
      width: 2,
    },
  };

  // const annotationY2: Partial<Annotations> = {
  //   xref: 'paper',
  //   yref: 'y2',
  //   x: 1 ,
  //   xanchor: 'left',
  //   y: contents.displayInformations.MegSpd.performanceMax,
  //   yanchor: 'top',
  //   text: '(100%)' + contents.displayInformations.MegSpd.performanceMax,
  //   showarrow: false,
  //   font: {
  //     size: 8,
  //     color: 'red',
  //   }
  // }

  const layoutL = {
    title: {
      text: intl.formatMessage({ id: msgId.trendLabelPort }),
      color: axisFontColor,
      x: 0.05,
      y: 0.98,
      font: { color: axisFontColor },
    },
    height: 400 - 65,
    autosize: true,
    xaxis: xaxisL,
    yaxis: yaxis,
    yaxis2: yaxis2,
    paper_bgcolor: colors.chart.legend.background,
    plot_bgcolor: colors.chart.legend.background,
    margin: {
      t: 25,
      b: 10,
      l: 60,
      r: 60,
      pad: 2,
    },
    showlegend: false,
    shapes: [shapeOutLine, shapeInLine],
    annotations: [],
    hovermode: 'closest',
    hoverlabel: {
      bgcolor: 'black',
      bordercolor: 'black',
      font: {
        color: 'white',
      },
      align: 'left',
    },
  } as Partial<Layout>;

  const layoutR = {
    title: {
      text: intl.formatMessage({ id: msgId.trendLabelStbd }),
      color: axisFontColor,
      x: 0.05,
      y: 0.98,
      font: { color: axisFontColor },
    },
    height: 420,
    autosize: true,
    xaxis: xaxisR,
    yaxis: yaxis,
    yaxis2: yaxis2,
    paper_bgcolor: colors.chart.legend.background,
    plot_bgcolor: colors.chart.legend.background,
    margin: {
      t: 25,
      b: 60,
      l: 60,
      r: 60,
      pad: 2,
    },
    showlegend: false,
    shapes: [shapeOutLine, shapeInLine],
    annotations: [],
    hovermode: 'closest',
    hoverlabel: {
      bgcolor: 'black',
      bordercolor: 'black',
      font: {
        color: 'white',
      },
      align: 'left',
    },
  } as Partial<Layout>;

  const plotConfig = {
    displayModeBar: false,
    showTips: false,
    doubleClick: 'reset',
  } as Partial<Plotly.Config>;

  const panel = 'MegTemp';
  const PlotL = 'PlotL' + panel;
  const PlotR = 'PlotR' + panel;
  let relayouting = false;
  function onRelayout(event: Readonly<Plotly.PlotRelayoutEvent>, side: 'left' | 'right') {
    if (!relayouting) {
      let divId: string;
      relayouting = true;
      setTimeout(() => {
        relayouting = false;
      }, 500);
      if (side === 'left') {
        divId = PlotR;
      } else {
        divId = PlotL;
      }
      Plotly.relayout(document.getElementById(divId) as HTMLElement, event);
    }
  }

  const hoverIdL = PlotL + 'hover';
  const hoverIdR = PlotR + 'hover';
  const dataL = [...OutDataListL, ...InDataListL];
  const dataR = [...OutDataListR, ...InDataListR];

  function onHover(data: Readonly<PlotMouseEvent>, side: 'left' | 'right') {
    const point = data.points[0];
    let showHoverInfo = false;
    for (const [key] of Object.entries(point)) {
      if (key === 'text') {
        showHoverInfo = true;
        break;
      }
    }
    if (showHoverInfo === false) return;
    let hoverTexts;
    let eId: string;
    let offset = 0;
    if (side === 'left') {
      hoverTexts = dataR[point.curveNumber].text as string[];
      eId = hoverIdR;
      offset = 320;
    } else {
      hoverTexts = dataL[point.curveNumber].text as string[];
      eId = hoverIdL;
    }
    if (!hoverTexts) return;
    const hoverText = hoverTexts[point.pointIndex];
    const div = document.getElementById(eId);
    if (div) {
      div.innerHTML = hoverText;
      div.style.left = data.event.offsetX + 'px';
      div.style.top = data.event.offsetY + offset + 'px';
      div.style.padding = '3px';
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  function onUnhover(event: Readonly<Plotly.PlotMouseEvent>) {
    let e = document.getElementById(hoverIdL);
    if (e) {
      e.innerHTML = '';
      e.style.padding = '0px';
    }
    e = document.getElementById(hoverIdR);
    if (e) {
      e.innerHTML = '';
      e.style.padding = '0px';
    }
  }

  return (
    <Box sx={sx}>
      <HoverDiv id={hoverIdL} />
      <Plot
        divId={PlotL}
        onRelayout={(event) => onRelayout(event, 'left')}
        style={plotLStyle}
        data={dataL}
        useResizeHandler
        layout={layoutL}
        onHover={(event) => onHover(event, 'left')}
        onUnhover={onUnhover}
        config={plotConfig}
      />
      <HoverDiv id={hoverIdR} />
      <Plot
        divId={PlotR}
        onRelayout={(event) => onRelayout(event, 'right')}
        style={plotRStyle}
        data={dataR}
        useResizeHandler
        layout={layoutR}
        onHover={(event) => onHover(event, 'right')}
        onUnhover={onUnhover}
        config={plotConfig}
      />
    </Box>
  );
}
