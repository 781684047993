import { API } from 'aws-amplify';
import logger from 'utils/AppLogger';
import { MaintenanceData, UpdateMaintenanceData } from 'models/maintenance';
import { getErrorResult } from 'models/error';
import { API_NAME } from './maricoApi';

/**
 * メンテナンス結果リスト取得
 */
export async function getMaintenanceDataListAsync(): Promise<MaintenanceData[]> {
  try {
    const params = {
      headers: {},
      response: true,
    };
    const result = await API.get(API_NAME, '/v1/maintenances', params);
    let listItems = result.data as MaintenanceData[];
    listItems = listItems.sort((a, b) => {
      if (a.shipName > b.shipName) {
        return -1;
      } else if (a.shipName < b.shipName) {
        return 1;
      } else {
        return 0;
      }
    });

    return listItems;
  } catch (error) {
    logger.error('error getMaintenanceDataListAsync: ', error);
    // throw getErrorResult(error);

    return [];
  }
}

/**
 * メンテナンス情報を登録する。
 * @param maintenanceData メンテナンス情報
 */
export async function registrationMaintenanceDataAsync(
  updateMaintenanceData: UpdateMaintenanceData
): Promise<MaintenanceData> {
  try {
    const params = {
      headers: {},
      response: true,
      body: updateMaintenanceData,
    };

    const result = await API.post(API_NAME, '/v1/maintenances', params);

    return result.data;
  } catch (error) {
    logger.error('error registrationMaintenanceDataAsync: ', error);
    throw getErrorResult(error);
  }
}

/**
 * メンテナンス情報を更新する。
 * @param maintenanceId メンテナンスID
 * @param updateMaintenanceData メンテナンス更新情報
 * @returns 更新されたメンテナンス情報
 */
export async function updateMaintenanceDataAsync(
  maintenanceId: number,
  updateMaintenanceData: UpdateMaintenanceData
): Promise<MaintenanceData> {
  try {
    const params = {
      headers: {},
      response: true,
      body: updateMaintenanceData,
    };

    const result = await API.put(API_NAME, '/v1/maintenances/' + maintenanceId, params);

    return result.data;
  } catch (error) {
    logger.error('error updateMaintenanceDataAsync: ', error);
    throw getErrorResult(error);
  }
}

/**
 * メンテナンスIDを削除する。
 * @param maintenanceId メンテナンスID
 */
export async function deleteMaintenanceDataAsync(maintenanceId: number): Promise<void> {
  try {
    const params = {
      headers: {},
      response: true,
    };

    await API.del(API_NAME, '/v1/maintenances/' + maintenanceId, params);
  } catch (error) {
    logger.error('error deleteMaintenanceDataAsync: ', error);
    throw getErrorResult(error);
  }
}
