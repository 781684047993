import dayjs from 'dayjs';
import { ChartSeries } from 'models/chart';
import { CommonSensorData, SensorThinnedHeaderData } from 'models/data';
import { DiagnosisItem, DiagnosisListItem } from 'models/diagnosis';
import { Ship, Sensor, Machine } from 'models/ships';
import { SensorData } from 'models/data';
import { AnalysisData } from 'models/analysis';
import AppLogger from './AppLogger';
import { locale, LocaleMessage } from 'resources/intl';
import { MaintenanceData } from 'models/maintenance';

function toText(v: number | string | undefined): string {
  if (typeof v === 'string') {
    return v;
  } else if (typeof v === 'number') {
    return isNaN(v) ? '' : String(v);
  } else {
    return '';
  }
}

function toLogText(v: number | string | undefined): string {
  if (typeof v === 'string') {
    return v;
  } else if (typeof v === 'number') {
    if (isNaN(v)) {
      return '';
    } else {
      let logText = String(v);
      if (logText.indexOf('.') == -1) {
        logText += '.0';
      }

      return logText;
    }
  } else {
    return '';
  }
}

function adjustMessage(msg: string): string {
  if (msg == 'レックス') {
    return 'Rex';
  }
  const index = msg.indexOf('（');
  if (index >= 0) {
    return msg.slice(0, index);
  }

  return msg;
}

// ==================================================================
// Ship List
// 【参照】センサー一覧取得_{船舶}
// ==================================================================
export function dumpShips(ships: Ship[]): void {
  const messages = LocaleMessage();

  AppLogger.info('=== dumpShips');
  const headers = [
    'sensor_id',
    'sensor_section_name',
    'sensor_position_name',
    'sensor_display_name',
    'sensor_name',
    'display_upper_limit',
    'display_lower_limit',
    'number_of_display_digits',
    'display_unit',
    'caution_upper_limit',
    'caution_lower_limit',
    'caution_judgment_time',
    'warning_upper_limit',
    'warning_lower_limit',
    'warning_judgment_time',
    'warning_compare_sensor_id',
    'operating_upper_limit',
    'operating_lower_limit',
    'fault_threshold',
    'fault_judgment_time',
    'fault_compare_sensor_id',
    'app_sensor_display_name',
  ];

  ships.forEach((ship) => {
    ship.machines.forEach((machine) => {
      AppLogger.info('ShipName:' + ship.name + ', MachineID:' + machine.machineId);
      AppLogger.info(headers.join('\t'));

      const tmpSensors: Sensor[] = [];
      machine.sensorGroups.forEach((sensorGroup) => {
        sensorGroup.sensors.forEach((sensor) => {
          tmpSensors.push(sensor);
        });
      });

      const sortedSensors: Sensor[] = tmpSensors.sort((n1, n2) => {
        if (n1.sensorId > n2.sensorId) {
          return 1;
        }
        if (n1.sensorId < n2.sensorId) {
          return -1;
        }

        return 0;
      });

      sortedSensors.forEach((sensor) => {
        // const sensorName = messages[sensor.sensorName].split(" ");
        const app_sensor_display_name = messages[sensor.sensorName];

        let dumpLine = String(sensor.sensorId);
        /*
        dumpLine += '\t' + adjustMessage(sensorName[1]);
        dumpLine += '\t' + sensorName[0];
        dumpLine += '\t' + (sensorName.length == 3 ? sensorName[2] : sensorName[2] + ' ' + sensorName[3]);
        */
        dumpLine += '\t' + sensor.sensorName;
        dumpLine += '\t' + String(sensor.displayUpperLimit);
        dumpLine += '\t' + String(sensor.displayLowerLimit);
        dumpLine += '\t' + String(sensor.displayRoundingPosition);
        dumpLine += '\t' + sensor.displayUnit;
        dumpLine += '\t' + String(sensor.cautionUpperLimit);
        dumpLine += '\t' + String(sensor.cautionLowerLimit);
        dumpLine += '\t' + String(sensor.cautionJudgmentTime);
        dumpLine += '\t' + String(sensor.warningUpperLimit);
        dumpLine += '\t' + String(sensor.warningLowerLimit);
        dumpLine += '\t' + String(sensor.warningJudgmentTime);
        let warningCompareSensorId = null;
        sortedSensors.forEach((chkSensor) => {
          if (sensor.warningCompareSensorName == chkSensor.sensorName) {
            warningCompareSensorId = chkSensor.sensorId;

            return;
          }
        });
        dumpLine += '\t' + String(warningCompareSensorId);
        dumpLine += '\t' + String(sensor.operatingUpperLimit);
        dumpLine += '\t' + String(sensor.operatingLowerLimit);
        dumpLine += '\t' + String(sensor.faultThreshold);
        dumpLine += '\t' + String(sensor.faultJudgmentTime);
        let faultCompareSensorId = null;
        sortedSensors.forEach((chkSensor) => {
          if (sensor.faultCompareSensorName == chkSensor.sensorName) {
            faultCompareSensorId = chkSensor.sensorId;

            return;
          }
        });
        dumpLine += '\t' + String(faultCompareSensorId);
        dumpLine += '\t' + app_sensor_display_name;
        AppLogger.info(dumpLine);
      });
    });
  });
}

// ==================================================================
// Diagnosis List
// 【参照】ホーム_警報_リスト
// ==================================================================
export function dumpDiagnosisListItems(items: DiagnosisListItem[]): void {
  const messages = LocaleMessage();

  AppLogger.info('=== dumpDiagnosisListItems');
  const headers = [
    'DateTime',
    'ship_name',
    'machine_name',
    'status_name',
    'app_sensor_display_name',
  ];
  AppLogger.info(headers.join('\t'));

  const timezoneOffset = dayjs().utcOffset() * 60000;
  items.forEach((item) => {
    if (item.listParentId !== 0) {
      // const sensorName = messages[item.sensorName].split(" ");
      const app_sensor_display_name = messages[item.sensorName];

      // let dumpLine = dayjs(item.startDate).utc().add(-timezoneOffset).format('YYYY-MM-DD HH:mm:ss');
      let dumpLine = dayjs(item.startDate).add(-timezoneOffset).format('YYYY-MM-DD HH:mm:ss');
      dumpLine += '\t' + item.shipName;
      dumpLine += '\t' + item.machineName;
      dumpLine += '\t' + messages['diagnosis_status_' + item.status];
      dumpLine += '\t' + app_sensor_display_name;
      AppLogger.info(dumpLine);
    }
  });
}

// ==================================================================
// Diagnosis Graph Sensors
// 【参照】ホーム_警報_グラフ_センサー一覧
// ==================================================================
export function dumpDiagnosisGraphSensors(series: ChartSeries[], ship: Ship | undefined): void {
  const messages = LocaleMessage();

  AppLogger.info('=== dumpDiagnosisGraphSensors');
  const headers = [
    'sensor_id',
    'sensor_name',
    'display_upper_limit',
    'display_lower_limit',
    'number_of_display_digits',
    'display_unit',
    'caution_upper_limit',
    'caution_lower_limit',
    'caution_judgment_time',
    'warning_upper_limit',
    'warning_lower_limit',
    'warning_judgment_time',
    'warning_compare_sensor_id',
    'operating_upper_limit',
    'operating_lower_limit',
    'fault_threshold',
    'fault_judgment_time',
    'fault_compare_sensor_id',
    'app_sensor_display_name',
  ];
  AppLogger.info(headers.join('\t'));

  const tmpSensors: Sensor[] = [];
  if (ship != null && ship.machines[0] != null) {
    ship.machines[0].sensorGroups.forEach((sensorGroup) => {
      sensorGroup.sensors.forEach((sensor) => {
        tmpSensors.push(sensor);
      });
    });
  }

  series.forEach((s) => {
    // 左舷
    const port = s.sensorGroup.sensors.find((s) => s.position === 'Port');
    if (port != null) {
      // const sensorName = messages[port.sensorName].split(" ");
      const app_sensor_display_name = messages[port.sensorName];

      let dumpLine = String(port.sensorId);
      dumpLine += '\t' + port.sensorName;
      dumpLine += '\t' + String(port.displayUpperLimit);
      dumpLine += '\t' + String(port.displayLowerLimit);
      dumpLine += '\t' + String(port.displayRoundingPosition);
      dumpLine += '\t' + port.displayUnit;
      dumpLine += '\t' + String(port.cautionUpperLimit);
      dumpLine += '\t' + String(port.cautionLowerLimit);
      dumpLine += '\t' + String(port.cautionJudgmentTime);
      dumpLine += '\t' + String(port.warningUpperLimit);
      dumpLine += '\t' + String(port.warningLowerLimit);
      dumpLine += '\t' + String(port.warningJudgmentTime);
      let warningCompareSensorId = null;
      tmpSensors.forEach((chkSensor) => {
        if (port.warningCompareSensorName == chkSensor.sensorName) {
          warningCompareSensorId = chkSensor.sensorId;

          return;
        }
      });
      dumpLine += '\t' + String(warningCompareSensorId);
      dumpLine += '\t' + String(port.operatingUpperLimit);
      dumpLine += '\t' + String(port.operatingLowerLimit);
      dumpLine += '\t' + String(port.faultThreshold);
      dumpLine += '\t' + String(port.faultJudgmentTime);
      let faultCompareSensorId = null;
      tmpSensors.forEach((chkSensor) => {
        if (port.faultCompareSensorName == chkSensor.sensorName) {
          faultCompareSensorId = chkSensor.sensorId;

          return;
        }
      });
      dumpLine += '\t' + String(faultCompareSensorId);
      dumpLine += '\t' + app_sensor_display_name;
      AppLogger.info(dumpLine);
    }
    // 右舷
    const startboard = s.sensorGroup.sensors.find((s) => s.position === 'Starboard');
    if (startboard != null) {
      // const sensorName = messages[startboard.sensorName].split(" ");
      const app_sensor_display_name = messages[startboard.sensorName];

      let dumpLine = String(startboard.sensorId);
      dumpLine += '\t' + startboard.sensorName;
      dumpLine += '\t' + String(startboard.displayUpperLimit);
      dumpLine += '\t' + String(startboard.displayLowerLimit);
      dumpLine += '\t' + String(startboard.displayRoundingPosition);
      dumpLine += '\t' + startboard.displayUnit;
      dumpLine += '\t' + String(startboard.cautionUpperLimit);
      dumpLine += '\t' + String(startboard.cautionLowerLimit);
      dumpLine += '\t' + String(startboard.cautionJudgmentTime);
      dumpLine += '\t' + String(startboard.warningUpperLimit);
      dumpLine += '\t' + String(startboard.warningLowerLimit);
      dumpLine += '\t' + String(startboard.warningJudgmentTime);
      let warningCompareSensorId = null;
      tmpSensors.forEach((chkSensor) => {
        if (startboard.warningCompareSensorName == chkSensor.sensorName) {
          warningCompareSensorId = chkSensor.sensorId;

          return;
        }
      });
      dumpLine += '\t' + String(warningCompareSensorId);
      dumpLine += '\t' + String(startboard.operatingUpperLimit);
      dumpLine += '\t' + String(startboard.operatingLowerLimit);
      dumpLine += '\t' + String(startboard.faultThreshold);
      dumpLine += '\t' + String(startboard.faultJudgmentTime);
      let faultCompareSensorId = null;
      tmpSensors.forEach((chkSensor) => {
        if (startboard.faultCompareSensorName == chkSensor.sensorName) {
          faultCompareSensorId = chkSensor.sensorId;

          return;
        }
      });
      dumpLine += '\t' + String(faultCompareSensorId);
      dumpLine += '\t' + app_sensor_display_name;
      AppLogger.info(dumpLine);
    }
    // 船体
    const shp = s.sensorGroup.sensors.find((s) => s.position === 'Shp');
    if (shp != null) {
      // const sensorName = messages[shp.sensorName].split(" ");
      const app_sensor_display_name = messages[shp.sensorName];

      let dumpLine = String(shp.sensorId);
      dumpLine += '\t' + shp.sensorName;
      dumpLine += '\t' + String(shp.displayUpperLimit);
      dumpLine += '\t' + String(shp.displayLowerLimit);
      dumpLine += '\t' + String(shp.displayRoundingPosition);
      dumpLine += '\t' + shp.displayUnit;
      dumpLine += '\t' + String(shp.cautionUpperLimit);
      dumpLine += '\t' + String(shp.cautionLowerLimit);
      dumpLine += '\t' + String(shp.cautionJudgmentTime);
      dumpLine += '\t' + String(shp.warningUpperLimit);
      dumpLine += '\t' + String(shp.warningLowerLimit);
      dumpLine += '\t' + String(shp.warningJudgmentTime);
      let warningCompareSensorId = null;
      tmpSensors.forEach((chkSensor) => {
        if (shp.warningCompareSensorName == chkSensor.sensorName) {
          warningCompareSensorId = chkSensor.sensorId;

          return;
        }
      });
      dumpLine += '\t' + String(warningCompareSensorId);
      dumpLine += '\t' + String(shp.operatingUpperLimit);
      dumpLine += '\t' + String(shp.operatingLowerLimit);
      dumpLine += '\t' + String(shp.faultThreshold);
      dumpLine += '\t' + String(shp.faultJudgmentTime);
      let faultCompareSensorId = null;
      tmpSensors.forEach((chkSensor) => {
        if (shp.faultCompareSensorName == chkSensor.sensorName) {
          faultCompareSensorId = chkSensor.sensorId;

          return;
        }
      });
      dumpLine += '\t' + String(faultCompareSensorId);
      dumpLine += '\t' + app_sensor_display_name;
      AppLogger.info(dumpLine);
    }
  });
}

// ==================================================================
// Diagnosis Graph Data
// 【参照】ホーム_警報_グラフ_値
// ==================================================================
export function dumpDiagnosisGraphData(series: ChartSeries[], ship: Ship | undefined): void {
  AppLogger.info('=== dumpDiagnosisGraphData');

  let dataFormatId = NaN;
  if (ship != null && ship.machines[0] != null) {
    dataFormatId = ship.machines[0].dataFormatId;
  }
  if (isNaN(dataFormatId)) {
    return;
  }

  let targetSensorGroup = null;
  if (dataFormatId != 402) {
    targetSensorGroup = series.find((s) =>
      s.sensorGroup.sensorGroupName.match('^[MegSpd|RexStrAngOrder|VesSpdSOGC1MCOM]')
    );
  } else {
    targetSensorGroup = series.find((s) =>
      s.sensorGroup.sensorGroupName.match('^[VesSog|RexStrAngFb|EngSpd]')
    );
  }

  if (targetSensorGroup != null) {
    if (dataFormatId == 400) {
      const headers = [
        'DateTime',
        targetSensorGroup.sensorGroup.sensors.find((s) => s.position === 'Port')?.sensorName,
        'MegSpdP1',
        'RexStrAngOrderP1',
        'VesSpdSOGC1MCOM',
        targetSensorGroup.sensorGroup.sensors.find((s) => s.position === 'Starboard')?.sensorName,
        'MegSpdS1',
        'RexStrAngOrderS1',
        'VesSpdSOGC1MCOM',
      ];
      AppLogger.info(headers.join('\t'));
    } else if (dataFormatId == 401) {
      const headers = [
        'DateTime',
        targetSensorGroup.sensorGroup.sensors.find((s) => s.position === 'Port')?.sensorName,
        'MegSpdP1',
        'RexStrAngOrderP1',
        'VesSpdSogC1GCOM',
        targetSensorGroup.sensorGroup.sensors.find((s) => s.position === 'Starboard')?.sensorName,
        'MegSpdS1',
        'RexStrAngOrderS1',
        'VesSpdSogC1GCOM',
      ];
      AppLogger.info(headers.join('\t'));
    } else {
      // 402は、Port, Starboard以外の異常センサーがあるため、!== で抽出する
      const headers = [
        'DateTime',
        targetSensorGroup.sensorGroup.sensors.find((s) => s.position !== 'Starboard')?.sensorName,
        'VesSog',
        'RexStrAngFbP',
        'EngSpdP',
        targetSensorGroup.sensorGroup.sensors.find((s) => s.position !== 'Port')?.sensorName,
        'VesSog',
        'RexStrAngFbS',
        'EngSpdS',
      ];
      AppLogger.info(headers.join('\t'));
    }
  } else {
    return;
  }

  const timezoneOffset = dayjs().utcOffset() * 60000;
  if (series[0].timeData != null) {
    for (let i = 0; i < series[0].timeData.length; i++) {
      // let log = dayjs(series[0].timeData[i]).utc().add(-timezoneOffset).format('YYYY/MM/DD HH:mm:ss.SSS') + '\t';
      // let log = dayjs(series[0].timeData[i]).utc().format('YYYY/MM/DD HH:mm:ss.SSS') + '\t';
      let log = dayjs(series[0].timeData[i]).format('YYYY-MM-DDTHH:mm:ss.SSS') + '000\t';

      // let log = dayjs.unix(series[0].timeData[i]).format('YYYY/MM/DD HH:mm:ss') + '\t';
      // 左舷
      series.forEach((s) => {
        let data = s.portSensorData?.data[i];
        if (data != undefined && s.sensorGroup.displayUnit == 'deg') {
          if (data < -0.1) {
            data += 360;
          }
        }
        log += toLogText(data) + '\t';
      });
      // 右舷
      series.forEach((s) => {
        let data = s.starboardSensorData?.data[i];
        if (data != undefined && s.sensorGroup.displayUnit == 'deg') {
          if (data < -0.1) {
            data += 360;
          }
        }
        log += toLogText(data) + '\t';
      });
      log = log.substring(0, log.length - 1);
      AppLogger.info(log);
    }
  }
}

// ==================================================================
// Maintenance List
// 【参照】ホーム_メンテナンス_リスト
// ==================================================================
export function dumpMaintenanceListItems(items: MaintenanceData[]): void {
  AppLogger.info('=== dumpMaintenanceListItems');
  const headers = ['ship_name', 'start_date', 'end_date', 'memo'];
  AppLogger.info(headers.join('\t'));

  const timezoneOffset = dayjs().utcOffset() * 60000;
  items.forEach((item) => {
    let dumpLine = '\t' + item.shipName;
    dumpLine += dayjs(item.startDate).add(-timezoneOffset).format('YYYY-MM-DD HH:mm:ss');
    dumpLine += '\t' + dayjs(item.endDate).add(-timezoneOffset).format('YYYY-MM-DD HH:mm:ss');
    dumpLine += '\t' + item.memo;
    AppLogger.info(dumpLine);
  });
}

// ==================================================================
// Past Data Initial
// 【参照】船舶状態_初期表示_地図_センサー一覧
// ==================================================================
export function dumpPastDataInitialSensorList(headerData: SensorThinnedHeaderData): void {
  const messages = LocaleMessage();

  AppLogger.info('=== dumpPastDataInitialSensorList');
  AppLogger.info('machineId: ' + String(headerData.machineId));

  let vesLatC1MCOMTitle = null;
  let vesLonC1MCOMTitle = null;
  let vesSpdSOGC1Title = null;
  let vesCourseTrueDirC1MCOMTitle = null;
  let vesWindDirRelC1Title = null;
  let VesWindSpdRelC1MCOMTitle = null;

  if (headerData.dataFormatId != 402) {
    vesLatC1MCOMTitle = 'VesLatC1MCOM';
    vesLonC1MCOMTitle = 'VesLonC1MCOM';
    vesSpdSOGC1Title = 'VesSpdSOGC1MCOM';
    vesCourseTrueDirC1MCOMTitle = 'VesCourseTrueDirC1MCOM';
    vesWindDirRelC1Title = 'VesWindDirRelC1';
    VesWindSpdRelC1MCOMTitle = 'VesWindSpdRelC1MCOM';
    if (headerData.sensors.vesLatC1 !== undefined) {
      vesLatC1MCOMTitle = headerData.sensors.vesLatC1.sensor.sensorName;
    }
    if (headerData.sensors.vesLonC1 !== undefined) {
      vesLonC1MCOMTitle = headerData.sensors.vesLonC1.sensor.sensorName;
    }
    if (headerData.sensors.vesSpdSOGC1 !== undefined) {
      vesSpdSOGC1Title = headerData.sensors.vesSpdSOGC1.sensor.sensorName;
    }
    if (headerData.sensors.vesCourseTrueDirC1 !== undefined) {
      vesCourseTrueDirC1MCOMTitle = headerData.sensors.vesCourseTrueDirC1.sensor.sensorName;
    }
    if (headerData.sensors.vesWindDirRelC1 !== undefined) {
      vesWindDirRelC1Title = headerData.sensors.vesWindDirRelC1.sensor.sensorName;
    }
    if (headerData.sensors.vesWindSpdRelC1 !== undefined) {
      VesWindSpdRelC1MCOMTitle = headerData.sensors.vesWindSpdRelC1.sensor.sensorName;
    }
  } else {
    vesLatC1MCOMTitle = 'VesLat';
    vesLonC1MCOMTitle = 'VesLon';
    vesSpdSOGC1Title = 'VesSog';
    vesCourseTrueDirC1MCOMTitle = 'VesHeading';
    vesWindDirRelC1Title = 'VesWindDirMagnitudMagDeg';
    VesWindSpdRelC1MCOMTitle = 'VesWindSpd';
    if (headerData.sensors.vesLat !== undefined) {
      vesLatC1MCOMTitle = headerData.sensors.vesLat.sensor.sensorName;
    }
    if (headerData.sensors.vesLon !== undefined) {
      vesLonC1MCOMTitle = headerData.sensors.vesLon.sensor.sensorName;
    }
    if (headerData.sensors.vesSog !== undefined) {
      vesSpdSOGC1Title = headerData.sensors.vesSog.sensor.sensorName;
    }
    if (headerData.sensors.vesHeading !== undefined) {
      vesCourseTrueDirC1MCOMTitle = headerData.sensors.vesHeading.sensor.sensorName;
    }
    if (headerData.sensors.vesWindDirMagnitudMagDeg !== undefined) {
      vesWindDirRelC1Title = headerData.sensors.vesWindDirMagnitudMagDeg.sensor.sensorName;
    }
    if (headerData.sensors.vesWindSpd !== undefined) {
      VesWindSpdRelC1MCOMTitle = headerData.sensors.vesWindSpd.sensor.sensorName;
    }
  }


  const headers = [
    'DateTime',
    vesLatC1MCOMTitle,
    vesLonC1MCOMTitle,
    vesSpdSOGC1Title,
    vesCourseTrueDirC1MCOMTitle,
    vesWindDirRelC1Title,
    VesWindSpdRelC1MCOMTitle,
  ];
  AppLogger.info(headers.join('\t'));

  let keyList = null;
  if (headerData.dataFormatId != 402) {
    keyList = [
      'vesLatC1',
      'vesLonC1',
      'vesSpdSOGC1',
      'vesCourseTrueDirC1',
      'vesWindDirRelC1',
      'vesWindSpdRelC1',
    ];
  } else {
    keyList = [
      'vesLat',
      'vesLon',
      'vesSog',
      'vesHeading',
      'vesWindDirMagnitudMagDeg',
      'vesWindSpd',
    ];
  }

  keyList.forEach((key) => {
    const sensor = headerData.sensors[key]?.sensor;
    if (sensor === undefined) {
      return;
    }

    // const sensorName = messages[sensor.sensorName].split(" ");
    const app_sensor_display_name = messages[sensor.sensorName];

    let dumpLine = String(sensor.sensorId);
    dumpLine += '\t' + sensor.sensorName;
    dumpLine += '\t' + String(sensor.displayUpperLimit);
    dumpLine += '\t' + String(sensor.displayLowerLimit);
    dumpLine += '\t' + String(sensor.displayRoundingPosition);
    dumpLine += '\t' + sensor.displayUnit;
    dumpLine += '\t' + app_sensor_display_name;
    AppLogger.info(dumpLine);
  });
}

// ==================================================================
// Past Data Initial Map Data
// 【参照】船舶状態_初期表示_地図_値
// ==================================================================
export function dumpPastDataInitialMapData(headerData: SensorThinnedHeaderData): void {
  AppLogger.info('=== dumpPastDataInitialMapData');
  AppLogger.info('machineId: ' + String(headerData.machineId));

  let vesLatC1MCOMTitle = null;
  let vesLonC1MCOMTitle = null;
  let vesSpdSOGC1Title = null;
  let vesCourseTrueDirC1MCOMTitle = null;
  let vesWindDirRelC1Title = null;
  let VesWindSpdRelC1MCOMTitle = null;

  if (headerData.dataFormatId != 402) {
    vesLatC1MCOMTitle = 'VesLatC1MCOM';
    vesLonC1MCOMTitle = 'VesLonC1MCOM';
    vesSpdSOGC1Title = 'VesSpdSOGC1MCOM';
    vesCourseTrueDirC1MCOMTitle = 'VesCourseTrueDirC1MCOM';
    vesWindDirRelC1Title = 'VesWindDirRelC1';
    VesWindSpdRelC1MCOMTitle = 'VesWindSpdRelC1MCOM';
    if (headerData.sensors.vesLatC1 !== undefined) {
      vesLatC1MCOMTitle = headerData.sensors.vesLatC1.sensor.sensorName;
    }
    if (headerData.sensors.vesLonC1 !== undefined) {
      vesLonC1MCOMTitle = headerData.sensors.vesLonC1.sensor.sensorName;
    }
    if (headerData.sensors.vesSpdSOGC1 !== undefined) {
      vesSpdSOGC1Title = headerData.sensors.vesSpdSOGC1.sensor.sensorName;
    }
    if (headerData.sensors.vesCourseTrueDirC1 !== undefined) {
      vesCourseTrueDirC1MCOMTitle = headerData.sensors.vesCourseTrueDirC1.sensor.sensorName;
    }
    if (headerData.sensors.vesWindDirRelC1 !== undefined) {
      vesWindDirRelC1Title = headerData.sensors.vesWindDirRelC1.sensor.sensorName;
    }
    if (headerData.sensors.vesWindSpdRelC1 !== undefined) {
      VesWindSpdRelC1MCOMTitle = headerData.sensors.vesWindSpdRelC1.sensor.sensorName;
    }
  } else {
    vesLatC1MCOMTitle = 'VesLat';
    vesLonC1MCOMTitle = 'VesLon';
    vesSpdSOGC1Title = 'VesSog';
    vesCourseTrueDirC1MCOMTitle = 'VesHeading';
    vesWindDirRelC1Title = 'VesWindDirMagnitudMagDeg';
    VesWindSpdRelC1MCOMTitle = 'VesWindSpd';
    if (headerData.sensors.vesLat !== undefined) {
      vesLatC1MCOMTitle = headerData.sensors.vesLat.sensor.sensorName;
    }
    if (headerData.sensors.vesLon !== undefined) {
      vesLonC1MCOMTitle = headerData.sensors.vesLon.sensor.sensorName;
    }
    if (headerData.sensors.vesSog !== undefined) {
      vesSpdSOGC1Title = headerData.sensors.vesSog.sensor.sensorName;
    }
    if (headerData.sensors.vesHeading !== undefined) {
      vesCourseTrueDirC1MCOMTitle = headerData.sensors.vesHeading.sensor.sensorName;
    }
    if (headerData.sensors.vesWindDirMagnitudMagDeg !== undefined) {
      vesWindDirRelC1Title = headerData.sensors.vesWindDirMagnitudMagDeg.sensor.sensorName;
    }
    if (headerData.sensors.vesWindSpd !== undefined) {
      VesWindSpdRelC1MCOMTitle = headerData.sensors.vesWindSpd.sensor.sensorName;
    }
  }

  const headers = [
    'DateTime',
    vesLatC1MCOMTitle,
    vesLonC1MCOMTitle,
    vesSpdSOGC1Title,
    vesCourseTrueDirC1MCOMTitle,
    vesWindDirRelC1Title,
    VesWindSpdRelC1MCOMTitle,
  ];
  AppLogger.info(headers.join('\t'));

  const timezoneOffset = dayjs().utcOffset() * 60000;
  if (headerData.logdate.length > 0) {
    for (let i = 0; i < headerData.logdate.length; i++) {
      if (isNaN(headerData.logdate[i]) == false) {
        // let dumpLine = dayjs(headerData.logdate[i]).utc().add(-timezoneOffset).format('YYYY/MM/DD HH:mm:ss') + '000';
        let dumpLine =
          dayjs(headerData.logdate[i]).add(-timezoneOffset).format('YYYY-MM-DDTHH:mm:ss.SSS') +
          '000';
        // let dumpLine = dayjs(headerData.logdate[i]).format('YYYY-MM-DDTHH:mm:ss.SSS') + '000';

        if (headerData.dataFormatId != 402) {
          if (headerData.sensors.vesLatC1 !== undefined) {
            dumpLine += '\t' + toLogText(headerData.sensors.vesLatC1.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (headerData.sensors.vesLonC1 !== undefined) {
            dumpLine += '\t' + toLogText(headerData.sensors.vesLonC1.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (headerData.sensors.vesSpdSOGC1 !== undefined) {
            dumpLine += '\t' + toLogText(headerData.sensors.vesSpdSOGC1.data[i]);
          } else {
            dumpLine += '\t';
          }
        } else {
          if (headerData.sensors.vesLat !== undefined) {
            dumpLine += '\t' + toLogText(headerData.sensors.vesLat.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (headerData.sensors.vesLon !== undefined) {
            dumpLine += '\t' + toLogText(headerData.sensors.vesLon.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (headerData.sensors.vesSog !== undefined) {
            dumpLine += '\t' + toLogText(headerData.sensors.vesSog.data[i]);
          } else {
            dumpLine += '\t';
          }
        }

        dumpLine += '\t\t\t';
        AppLogger.info(dumpLine);
      }
    }
  }

  // let dumpLine = dayjs(headerData.lastLogDate).utc().add(-timezoneOffset).format('YYYY/MM/DD HH:mm:ss') + '000';
  let dumpLine =
    dayjs(headerData.lastLogDate).add(-timezoneOffset).format('YYYY-MM-DDTHH:mm:ss.SSS') + '000';
  // let dumpLine = dayjs(headerData.lastLogDate).format('YYYY-MM-DDTHH:mm:ss.SSS') + '000';
  dumpLine += '\t\t\t';
  if (headerData.dataFormatId != 402) {
    if (headerData.sensors.vesCourseTrueDirC1 !== undefined) {
      dumpLine += '\t' + toLogText(headerData.sensors.vesCourseTrueDirC1.data[0]);
    } else {
      dumpLine += '\t';
    }
    if (headerData.sensors.vesWindDirRelC1 !== undefined) {
      dumpLine += '\t' + toLogText(headerData.sensors.vesWindDirRelC1.data[0]);
    } else {
      dumpLine += '\t';
    }
    if (headerData.sensors.vesWindSpdRelC1 !== undefined) {
      dumpLine += '\t' + toLogText(headerData.sensors.vesWindSpdRelC1.data[0]);
    } else {
      dumpLine += '\t';
    }
  } else {
    if (headerData.sensors.vesHeading !== undefined) {
      dumpLine += '\t' + toLogText(headerData.sensors.vesHeading.data[0]);
    } else {
      dumpLine += '\t';
    }
    if (headerData.sensors.vesWindDirMagnitudMagDeg !== undefined) {
      dumpLine += '\t' + toLogText(headerData.sensors.vesWindDirMagnitudMagDeg.data[0]);
    } else {
      dumpLine += '\t';
    }
    if (headerData.sensors.vesWindSpd !== undefined) {
      dumpLine += '\t' + toLogText(headerData.sensors.vesWindSpd.data[0]);
    } else {
      dumpLine += '\t';
    }
  }

  AppLogger.info(dumpLine);
}

// ==================================================================
// Past Data Mimic Sensor List
/// 【参照】船舶状態_センサー一覧_mimic
// ==================================================================
export function dumpPastDataMimicSensorList(commonData: CommonSensorData, machine: Machine): void {
  AppLogger.info('=== dumpPastDataMimicSensorList');
  AppLogger.info('machineId: ' + String(machine.machineId));

  const mimicSensors: Sensor[] = [];
  if (commonData.dataFormatId != 402) {
    if (commonData.sensors.megSpdP1 !== undefined) {
      mimicSensors.push(commonData.sensors.megSpdP1.sensor);
    }
    if (commonData.sensors.megSpdS1 !== undefined) {
      mimicSensors.push(commonData.sensors.megSpdS1.sensor);
    }
    if (commonData.sensors.megTCSpdP1 !== undefined) {
      mimicSensors.push(commonData.sensors.megTCSpdP1.sensor);
    }
    if (commonData.sensors.megTCSpdS1 !== undefined) {
      mimicSensors.push(commonData.sensors.megTCSpdS1.sensor);
    }
    if (commonData.sensors.megTCbstpressP1 !== undefined) {
      mimicSensors.push(commonData.sensors.megTCbstpressP1.sensor);
    }
    if (commonData.sensors.megTCbstpressS1 !== undefined) {
      mimicSensors.push(commonData.sensors.megTCbstpressS1.sensor);
    }
    if (commonData.sensors.megFuelRackLvP1 !== undefined) {
      mimicSensors.push(commonData.sensors.megFuelRackLvP1.sensor);
    }
    if (commonData.sensors.megFuelRackLvS1 !== undefined) {
      mimicSensors.push(commonData.sensors.megFuelRackLvS1.sensor);
    }
    if (commonData.sensors.megHpP1 !== undefined) {
      mimicSensors.push(commonData.sensors.megHpP1.sensor);
    }
    if (commonData.sensors.megHpS1 !== undefined) {
      mimicSensors.push(commonData.sensors.megHpS1.sensor);
    }
    if (commonData.sensors.megLOpressP1 !== undefined) {
      mimicSensors.push(commonData.sensors.megLOpressP1.sensor);
    }
    if (commonData.sensors.megLOpressS1 !== undefined) {
      mimicSensors.push(commonData.sensors.megLOpressS1.sensor);
    }
    if (commonData.sensors.megLOTmpP1 !== undefined) {
      mimicSensors.push(commonData.sensors.megLOTmpP1.sensor);
    }
    if (commonData.sensors.megLOTmpS1 !== undefined) {
      mimicSensors.push(commonData.sensors.megLOTmpS1.sensor);
    }
    if (commonData.sensors.megNo1EXTGASTmpP1 !== undefined) {
      mimicSensors.push(commonData.sensors.megNo1EXTGASTmpP1.sensor);
    }
    if (commonData.sensors.megNo1EXTGASTmpS1 !== undefined) {
      mimicSensors.push(commonData.sensors.megNo1EXTGASTmpS1.sensor);
    }
    if (commonData.sensors.megNo2EXTGASTmpP1 !== undefined) {
      mimicSensors.push(commonData.sensors.megNo2EXTGASTmpP1.sensor);
    }
    if (commonData.sensors.megNo2EXTGASTmpS1 !== undefined) {
      mimicSensors.push(commonData.sensors.megNo2EXTGASTmpS1.sensor);
    }
    if (commonData.sensors.megNo3EXTGASTmpP1 !== undefined) {
      mimicSensors.push(commonData.sensors.megNo3EXTGASTmpP1.sensor);
    }
    if (commonData.sensors.megNo3EXTGASTmpS1 !== undefined) {
      mimicSensors.push(commonData.sensors.megNo3EXTGASTmpS1.sensor);
    }
    if (commonData.sensors.megNo4EXTGASTmpP1 !== undefined) {
      mimicSensors.push(commonData.sensors.megNo4EXTGASTmpP1.sensor);
    }
    if (commonData.sensors.megNo4EXTGASTmpS1 !== undefined) {
      mimicSensors.push(commonData.sensors.megNo4EXTGASTmpS1.sensor);
    }
    if (commonData.sensors.megNo5EXTGASTmpP1 !== undefined) {
      mimicSensors.push(commonData.sensors.megNo5EXTGASTmpP1.sensor);
    }
    if (commonData.sensors.megNo5EXTGASTmpS1 !== undefined) {
      mimicSensors.push(commonData.sensors.megNo5EXTGASTmpS1.sensor);
    }
    if (commonData.sensors.megNo6EXTGASTmpP1 !== undefined) {
      mimicSensors.push(commonData.sensors.megNo6EXTGASTmpP1.sensor);
    }
    if (commonData.sensors.megNo6EXTGASTmpS1 !== undefined) {
      mimicSensors.push(commonData.sensors.megNo6EXTGASTmpS1.sensor);
    }
    if (commonData.sensors.megNo7EXTGASTmpP1 !== undefined) {
      mimicSensors.push(commonData.sensors.megNo7EXTGASTmpP1.sensor);
    }
    if (commonData.sensors.megNo7EXTGASTmpS1 !== undefined) {
      mimicSensors.push(commonData.sensors.megNo7EXTGASTmpS1.sensor);
    }
    if (commonData.sensors.megNo8EXTGASTmpP1 !== undefined) {
      mimicSensors.push(commonData.sensors.megNo8EXTGASTmpP1.sensor);
    }
    if (commonData.sensors.megNo8EXTGASTmpS1 !== undefined) {
      mimicSensors.push(commonData.sensors.megNo8EXTGASTmpS1.sensor);
    }
    if (commonData.sensors.megNo9EXTGASTmpP1 !== undefined) {
      mimicSensors.push(commonData.sensors.megNo9EXTGASTmpP1.sensor);
    }
    if (commonData.sensors.megNo9EXTGASTmpS1 !== undefined) {
      mimicSensors.push(commonData.sensors.megNo9EXTGASTmpS1.sensor);
    }
    if (commonData.sensors.megInletAirTmpP1 !== undefined) {
      mimicSensors.push(commonData.sensors.megInletAirTmpP1.sensor);
    }
    if (commonData.sensors.megInletAirTmpS1 !== undefined) {
      mimicSensors.push(commonData.sensors.megInletAirTmpS1.sensor);
    }
    if (commonData.sensors.rexStrAngOrderP1 !== undefined) {
      mimicSensors.push(commonData.sensors.rexStrAngOrderP1.sensor);
    }
    if (commonData.sensors.rexStrAngOrderS1 !== undefined) {
      mimicSensors.push(commonData.sensors.rexStrAngOrderS1.sensor);
    }
    if (commonData.sensors.rexStrAngFBP1 !== undefined) {
      mimicSensors.push(commonData.sensors.rexStrAngFBP1.sensor);
    }
    if (commonData.sensors.rexStrAngFBS1 !== undefined) {
      mimicSensors.push(commonData.sensors.rexStrAngFBS1.sensor);
    }
    if (commonData.sensors.rexCPPBladeAngOrderP1 !== undefined) {
      mimicSensors.push(commonData.sensors.rexCPPBladeAngOrderP1.sensor);
    }
    if (commonData.sensors.rexCPPBladeAngOrderS1 !== undefined) {
      mimicSensors.push(commonData.sensors.rexCPPBladeAngOrderS1.sensor);
    }
    if (commonData.sensors.rexCPPBladeAngFBP1 !== undefined) {
      mimicSensors.push(commonData.sensors.rexCPPBladeAngFBP1.sensor);
    }
    if (commonData.sensors.rexCPPBladeAngFBS1 !== undefined) {
      mimicSensors.push(commonData.sensors.rexCPPBladeAngFBS1.sensor);
    }
    if (commonData.sensors.rexClutchengagedFBP1 !== undefined) {
      mimicSensors.push(commonData.sensors.rexClutchengagedFBP1.sensor);
    }
    if (commonData.sensors.rexClutchengagedFBS1 !== undefined) {
      mimicSensors.push(commonData.sensors.rexClutchengagedFBS1.sensor);
    }
    if (commonData.sensors.rexLOPressP1 !== undefined) {
      mimicSensors.push(commonData.sensors.rexLOPressP1.sensor);
    }
    if (commonData.sensors.rexLOPressS1 !== undefined) {
      mimicSensors.push(commonData.sensors.rexLOPressS1.sensor);
    }
    if (commonData.sensors.rexLOTmpP1 !== undefined) {
      mimicSensors.push(commonData.sensors.rexLOTmpP1.sensor);
    }
    if (commonData.sensors.rexLOTmpS1 !== undefined) {
      mimicSensors.push(commonData.sensors.rexLOTmpS1.sensor);
    }
  } else {
    if (commonData.sensors.engSpdP !== undefined) {
      mimicSensors.push(commonData.sensors.engSpdP.sensor);
    }
    if (commonData.sensors.engSpdS !== undefined) {
      mimicSensors.push(commonData.sensors.engSpdS.sensor);
    }
    if (commonData.sensors.engTcSpdP !== undefined) {
      mimicSensors.push(commonData.sensors.engTcSpdP.sensor);
    }
    if (commonData.sensors.engTcSpdS !== undefined) {
      mimicSensors.push(commonData.sensors.engTcSpdS.sensor);
    }
    if (commonData.sensors.engChargedAirPressP !== undefined) {
      mimicSensors.push(commonData.sensors.engChargedAirPressP.sensor);
    }
    if (commonData.sensors.engChargedAirPressS !== undefined) {
      mimicSensors.push(commonData.sensors.engChargedAirPressS.sensor);
    }
    if (commonData.sensors.engFuelRackLvP !== undefined) {
      mimicSensors.push(commonData.sensors.engFuelRackLvP.sensor);
    }
    if (commonData.sensors.engFuelRackLvS !== undefined) {
      mimicSensors.push(commonData.sensors.engFuelRackLvS.sensor);
    }
    if (commonData.sensors.engSftHpP !== undefined) {
      mimicSensors.push(commonData.sensors.engSftHpP.sensor);
    }
    if (commonData.sensors.engSftHpS !== undefined) {
      mimicSensors.push(commonData.sensors.engSftHpS.sensor);
    }
    if (commonData.sensors.engLoPressP !== undefined) {
      mimicSensors.push(commonData.sensors.engLoPressP.sensor);
    }
    if (commonData.sensors.engLoPressS !== undefined) {
      mimicSensors.push(commonData.sensors.engLoPressS.sensor);
    }
    if (commonData.sensors.engLoInTempP !== undefined) {
      mimicSensors.push(commonData.sensors.engLoInTempP.sensor);
    }
    if (commonData.sensors.engLoInTempS !== undefined) {
      mimicSensors.push(commonData.sensors.engLoInTempS.sensor);
    }
    if (commonData.sensors.engNo1ExtGasTempP !== undefined) {
      mimicSensors.push(commonData.sensors.engNo1ExtGasTempP.sensor);
    }
    if (commonData.sensors.engNo1ExtGasTempS !== undefined) {
      mimicSensors.push(commonData.sensors.engNo1ExtGasTempS.sensor);
    }
    if (commonData.sensors.engNo2ExtGasTempP !== undefined) {
      mimicSensors.push(commonData.sensors.engNo2ExtGasTempP.sensor);
    }
    if (commonData.sensors.engNo2ExtGasTempS !== undefined) {
      mimicSensors.push(commonData.sensors.engNo2ExtGasTempS.sensor);
    }
    if (commonData.sensors.engNo3ExtGasTempP !== undefined) {
      mimicSensors.push(commonData.sensors.engNo3ExtGasTempP.sensor);
    }
    if (commonData.sensors.engNo3ExtGasTempS !== undefined) {
      mimicSensors.push(commonData.sensors.engNo3ExtGasTempS.sensor);
    }
    if (commonData.sensors.engNo4ExtGasTempP !== undefined) {
      mimicSensors.push(commonData.sensors.engNo4ExtGasTempP.sensor);
    }
    if (commonData.sensors.engNo4ExtGasTempS !== undefined) {
      mimicSensors.push(commonData.sensors.engNo4ExtGasTempS.sensor);
    }
    if (commonData.sensors.engNo5ExtGasTempP !== undefined) {
      mimicSensors.push(commonData.sensors.engNo5ExtGasTempP.sensor);
    }
    if (commonData.sensors.engNo5ExtGasTempS !== undefined) {
      mimicSensors.push(commonData.sensors.engNo5ExtGasTempS.sensor);
    }
    if (commonData.sensors.engNo6ExtGasTempP !== undefined) {
      mimicSensors.push(commonData.sensors.engNo6ExtGasTempP.sensor);
    }
    if (commonData.sensors.engNo6ExtGasTempS !== undefined) {
      mimicSensors.push(commonData.sensors.engNo6ExtGasTempS.sensor);
    }
    if (commonData.sensors.engTcInTemp145P !== undefined) {
      mimicSensors.push(commonData.sensors.engTcInTemp145P.sensor);
    }
    if (commonData.sensors.engTcInTemp145S !== undefined) {
      mimicSensors.push(commonData.sensors.engTcInTemp145S.sensor);
    }
    if (commonData.sensors.engTcInTemp236P !== undefined) {
      mimicSensors.push(commonData.sensors.engTcInTemp236P.sensor);
    }
    if (commonData.sensors.engTcInTemp236S !== undefined) {
      mimicSensors.push(commonData.sensors.engTcInTemp236S.sensor);
    }
    if (commonData.sensors.engTcOutTempP !== undefined) {
      mimicSensors.push(commonData.sensors.engTcOutTempP.sensor);
    }
    if (commonData.sensors.engTcOutTempS !== undefined) {
      mimicSensors.push(commonData.sensors.engTcOutTempS.sensor);
    }
    if (commonData.sensors.rexStrAngOrderP !== undefined) {
      mimicSensors.push(commonData.sensors.rexStrAngOrderP.sensor);
    }
    if (commonData.sensors.rexStrAngOrderS !== undefined) {
      mimicSensors.push(commonData.sensors.rexStrAngOrderS.sensor);
    }
    if (commonData.sensors.rexStrAngFbP !== undefined) {
      mimicSensors.push(commonData.sensors.rexStrAngFbP.sensor);
    }
    if (commonData.sensors.rexStrAngFbS !== undefined) {
      mimicSensors.push(commonData.sensors.rexStrAngFbS.sensor);
    }
    if (commonData.sensors.rexClutchEngFbP !== undefined) {
      mimicSensors.push(commonData.sensors.rexClutchEngFbP.sensor);
    }
    if (commonData.sensors.rexClutchEngFbS !== undefined) {
      mimicSensors.push(commonData.sensors.rexClutchEngFbS.sensor);
    }
    if (commonData.sensors.rexLoPressS !== undefined) {
      mimicSensors.push(commonData.sensors.rexLoPressS.sensor);
    }
    if (commonData.sensors.rexLoTempS !== undefined) {
      mimicSensors.push(commonData.sensors.rexLoTempS.sensor);
    }
    if (commonData.sensors.rexLOPressP !== undefined) {
      mimicSensors.push(commonData.sensors.rexLOPressP.sensor);
    }
    if (commonData.sensors.rexLoTempP !== undefined) {
      mimicSensors.push(commonData.sensors.rexLoTempP.sensor);
    }
    if (commonData.sensors.engChargedAirTempS !== undefined) {
      mimicSensors.push(commonData.sensors.engChargedAirTempS.sensor);
    }
    if (commonData.sensors.engChargedAirTempP !== undefined) {
      mimicSensors.push(commonData.sensors.engChargedAirTempP.sensor);
    }
  }

  const sortedMimicSensors: Sensor[] = mimicSensors.sort((n1, n2) => {
    if (n1.sensorId > n2.sensorId) {
      return 1;
    }
    if (n1.sensorId < n2.sensorId) {
      return -1;
    }

    return 0;
  });

  const messages = LocaleMessage();
  const headers = [
    'sensor_id',
    'sensor_section_name',
    'sensor_position_name',
    'sensor_display_name',
    'sensor_name',
    'display_upper_limit',
    'display_lower_limit',
    'number_of_display_digits',
    'display_unit',
  ];
  AppLogger.info(headers.join('\t'));

  sortedMimicSensors.forEach((sensor) => {
    // const sensorName = messages[sensor.sensorName].split(" ");
    const app_sensor_display_name = messages[sensor.sensorName];

    let dumpLine = String(sensor.sensorId);
    dumpLine += '\t' + sensor.sensorName;
    dumpLine += '\t' + String(sensor.displayUpperLimit);
    dumpLine += '\t' + String(sensor.displayLowerLimit);
    dumpLine += '\t' + String(sensor.displayRoundingPosition);
    dumpLine += '\t' + sensor.displayUnit;
    dumpLine += '\t' + app_sensor_display_name;
    AppLogger.info(dumpLine);
  });
}

// ==================================================================
// Past Data Graph Sensor List
/// 【参照】船舶状態_センサー一覧_グラフ
// ==================================================================
export function dumpPastDataGraphSensorList(baseShip: Ship): void {
  AppLogger.info('=== dumpPastDataGraphSensorList');
  AppLogger.info('machineId: ' + String(baseShip.machines[0].machineId));

  const leftSensors: Sensor[] = [];
  const rightSensors: Sensor[] = [];
  const shipSensors: Sensor[] = [];
  baseShip.machines[0].sensorGroups
    .filter((x) => x.sensors.some((x) => x.isVisualization))
    .map((sensorGroup) => {
      sensorGroup.sensors.forEach((y) => {
        if (y.position == 'Port') {
          leftSensors.push(y);
        } else if (y.position == 'Starboard') {
          rightSensors.push(y);
        } else {
          shipSensors.push(y);
        }
      });
    });

  const sortedLeftSensors: Sensor[] = leftSensors.sort((n1, n2) => {
    if (n1.sensorId > n2.sensorId) {
      return 1;
    }
    if (n1.sensorId < n2.sensorId) {
      return -1;
    }

    return 0;
  });

  const sortedRightSensors: Sensor[] = rightSensors.sort((n1, n2) => {
    if (n1.sensorId > n2.sensorId) {
      return 1;
    }
    if (n1.sensorId < n2.sensorId) {
      return -1;
    }

    return 0;
  });

  const sortedShipSensors: Sensor[] = shipSensors.sort((n1, n2) => {
    if (n1.sensorId > n2.sensorId) {
      return 1;
    }
    if (n1.sensorId < n2.sensorId) {
      return -1;
    }

    return 0;
  });

  const messages = LocaleMessage();
  const headers = [
    'sensor_id',
    'sensor_section_name',
    'sensor_position_name',
    'sensor_display_name',
    'sensor_name',
    'display_upper_limit',
    'display_lower_limit',
    'number_of_display_digits',
    'display_unit',
  ];
  AppLogger.info(headers.join('\t'));

  sortedLeftSensors.forEach((sensor) => {
    // const sensorName = messages[sensor.sensorName].split(" ");
    const app_sensor_display_name = messages[sensor.sensorName];

    let dumpLine = String(sensor.sensorId);
    dumpLine += '\t' + sensor.sensorName;
    dumpLine += '\t' + String(sensor.displayUpperLimit);
    dumpLine += '\t' + String(sensor.displayLowerLimit);
    dumpLine += '\t' + String(sensor.displayRoundingPosition);
    dumpLine += '\t' + sensor.displayUnit;
    dumpLine += '\t' + app_sensor_display_name;
    AppLogger.info(dumpLine);
  });

  sortedRightSensors.forEach((sensor) => {
    // const sensorName = messages[sensor.sensorName].split(" ");
    const app_sensor_display_name = messages[sensor.sensorName];

    let dumpLine = String(sensor.sensorId);
    dumpLine += '\t' + sensor.sensorName;
    dumpLine += '\t' + String(sensor.displayUpperLimit);
    dumpLine += '\t' + String(sensor.displayLowerLimit);
    dumpLine += '\t' + String(sensor.displayRoundingPosition);
    dumpLine += '\t' + sensor.displayUnit;
    dumpLine += '\t' + app_sensor_display_name;
    AppLogger.info(dumpLine);
  });

  sortedShipSensors.forEach((sensor) => {
    // const sensorName = messages[sensor.sensorName].split(" ");
    const app_sensor_display_name = messages[sensor.sensorName];

    let dumpLine = String(sensor.sensorId);
    dumpLine += '\t' + sensor.sensorName;
    dumpLine += '\t' + String(sensor.displayUpperLimit);
    dumpLine += '\t' + String(sensor.displayLowerLimit);
    dumpLine += '\t' + String(sensor.displayRoundingPosition);
    dumpLine += '\t' + sensor.displayUnit;
    dumpLine += '\t' + app_sensor_display_name;
    AppLogger.info(dumpLine);
  });
}

// ==================================================================
// Past Data Day Select Map Data
// 【参照】船舶状態_カレンダー選択_地図
// ==================================================================
export function dumpPastDataDaySelectlMapData(
  commonSensorDataList: CommonSensorData[],
  machineId: number
): void {
  AppLogger.info('=== dumpPastDataDaySelectlMapData');
  AppLogger.info('machineId: ' + String(machineId));

  let commonSensorData = null;
  for (let i = 0; i < commonSensorDataList.length; i++) {
    if (commonSensorDataList[i].machineId == machineId) {
      commonSensorData = commonSensorDataList[i];
      break;
    }
  }
  if (commonSensorData == null) {
    return;
  }

  let vesLatC1MCOMTitle = null;
  let vesLonC1MCOMTitle = null;
  let vesSpdSOGC1Title = null;
  let vesCourseTrueDirC1MCOMTitle = null;
  let vesWindDirRelC1Title = null;
  let VesWindSpdRelC1MCOMTitle = null;
  if (commonSensorData.dataFormatId != 402) {
    vesLatC1MCOMTitle = 'VesLatC1MCOM';
    vesLonC1MCOMTitle = 'VesLonC1MCOM';
    vesSpdSOGC1Title = 'VesSpdSOGC1MCOM';
    vesCourseTrueDirC1MCOMTitle = 'VesCourseTrueDirC1MCOM';
    vesWindDirRelC1Title = 'VesWindDirRelC1';
    VesWindSpdRelC1MCOMTitle = 'VesWindSpdRelC1MCOM';
    if (commonSensorData.sensors.vesLatC1 !== undefined) {
      vesLatC1MCOMTitle = commonSensorData.sensors.vesLatC1.sensor.sensorName;
    }
    if (commonSensorData.sensors.vesLonC1 !== undefined) {
      vesLonC1MCOMTitle = commonSensorData.sensors.vesLonC1.sensor.sensorName;
    }
    if (commonSensorData.sensors.vesSpdSOGC1 !== undefined) {
      vesSpdSOGC1Title = commonSensorData.sensors.vesSpdSOGC1.sensor.sensorName;
    }
    if (commonSensorData.sensors.vesCourseTrueDirC1 !== undefined) {
      vesCourseTrueDirC1MCOMTitle = commonSensorData.sensors.vesCourseTrueDirC1.sensor.sensorName;
    }
    if (commonSensorData.sensors.vesWindDirRelC1 !== undefined) {
      vesWindDirRelC1Title = commonSensorData.sensors.vesWindDirRelC1.sensor.sensorName;
    }
    if (commonSensorData.sensors.vesWindSpdRelC1 !== undefined) {
      VesWindSpdRelC1MCOMTitle = commonSensorData.sensors.vesWindSpdRelC1.sensor.sensorName;
    }
  } else {
    vesLatC1MCOMTitle = 'VesLat';
    vesLonC1MCOMTitle = 'VesLon';
    vesSpdSOGC1Title = 'VesSog';
    vesCourseTrueDirC1MCOMTitle = 'VesHeading';
    vesWindDirRelC1Title = 'VesWindDirMagnitudMagDeg';
    VesWindSpdRelC1MCOMTitle = 'VesWindSpd';
    if (commonSensorData.sensors.vesLat !== undefined) {
      vesLatC1MCOMTitle = commonSensorData.sensors.vesLat.sensor.sensorName;
    }
    if (commonSensorData.sensors.vesLon !== undefined) {
      vesLonC1MCOMTitle = commonSensorData.sensors.vesLon.sensor.sensorName;
    }
    if (commonSensorData.sensors.vesSog !== undefined) {
      vesSpdSOGC1Title = commonSensorData.sensors.vesSog.sensor.sensorName;
    }
    if (commonSensorData.sensors.vesHeading !== undefined) {
      vesCourseTrueDirC1MCOMTitle = commonSensorData.sensors.vesHeading.sensor.sensorName;
    }
    if (commonSensorData.sensors.vesWindDirMagnitudMagDeg !== undefined) {
      vesWindDirRelC1Title = commonSensorData.sensors.vesWindDirMagnitudMagDeg.sensor.sensorName;
    }
    if (commonSensorData.sensors.vesWindSpd !== undefined) {
      VesWindSpdRelC1MCOMTitle = commonSensorData.sensors.vesWindSpd.sensor.sensorName;
    }
  }

  const headers = [
    'DateTime',
    vesLatC1MCOMTitle,
    vesLonC1MCOMTitle,
    vesSpdSOGC1Title,
    vesCourseTrueDirC1MCOMTitle,
    vesWindDirRelC1Title,
    VesWindSpdRelC1MCOMTitle,
  ];
  AppLogger.info(headers.join('\t'));

  const timezoneOffset = dayjs().utcOffset() * 60000;
  if (commonSensorData.logdate.length > 0) {
    for (let i = 0; i < commonSensorData.logdate.length; i++) {
      if (isNaN(commonSensorData.logdate[i]) == false) {
        // let dumpLine = dayjs(commonSensorData.logdate[i]).utc().add(-timezoneOffset).format('YYYY/MM/DD HH:mm:ss');
        let dumpLine =
          dayjs(commonSensorData.logdate[i])
            .add(-timezoneOffset)
            .format('YYYY-MM-DDTHH:mm:ss.SSS') + '000';

        if (commonSensorData.dataFormatId != 402) {
          if (commonSensorData.sensors.vesLatC1 !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.vesLatC1.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.vesLonC1 !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.vesLonC1.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.vesSpdSOGC1 !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.vesSpdSOGC1.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.vesCourseTrueDirC1 !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.vesCourseTrueDirC1.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.vesWindDirRelC1 !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.vesWindDirRelC1.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.vesWindSpdRelC1 !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.vesWindSpdRelC1.data[i]);
          } else {
            dumpLine += '\t';
          }
        } else {
          if (commonSensorData.sensors.vesLat !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.vesLat.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.vesLon !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.vesLon.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.vesSog !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.vesSog.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.vesHeading !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.vesHeading.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.vesWindDirMagnitudMagDeg !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.vesWindDirMagnitudMagDeg.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.vesWindSpd !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.vesWindSpd.data[i]);
          } else {
            dumpLine += '\t';
          }
        }

        AppLogger.info(dumpLine);
      }
    }
  }
}

// ==================================================================
// Past Data Day Select Mimic Data
// 【参照】船舶状態_カレンダー選択_mimic
// ==================================================================
export function dumpPastDataDaySelectMimicData(
  commonSensorDataList: CommonSensorData[],
  machines: Machine[],
  machineId: number
): void {
  AppLogger.info('=== dumpPastDataDaySelectMimicData');
  AppLogger.info('machineId: ' + String(machineId));

  let commonSensorData = null;
  for (let i = 0; i < commonSensorDataList.length; i++) {
    if (commonSensorDataList[i].machineId == machineId) {
      commonSensorData = commonSensorDataList[i];
      break;
    }
  }
  if (commonSensorData == null) {
    return;
  }
  let dataFormatId = NaN;
  for (let i = 0; i < machines.length; i++) {
    if (machineId == machines[i].machineId) {
      dataFormatId = machines[i].dataFormatId;
      break;
    }
  }
  if (isNaN(dataFormatId)) {
    return;
  }

  if (dataFormatId == 400) {
    const headers = [
      'DateTime',
      'MegSpdP1',
      'MegTCSpdP1',
      'MegTCbstpressP1COM',
      'MegFuelRackLvP1',
      'MegHpP1',
      'MegLOpressP1COM',
      'MegLOTmpP1COM',
      'MegNo1EXTGASTmpP1COM',
      'MegNo2EXTGASTmpP1COM',
      'MegNo3EXTGASTmpP1COM',
      'MegNo4EXTGASTmpP1COM',
      'MegNo5EXTGASTmpP1COM',
      'MegNo6EXTGASTmpP1COM',
      'MegNo7EXTGASTmpP1COM',
      'MegNo8EXTGASTmpP1COM',
      'MegNo9EXTGASTmpP1COM',
      'RexLOPressP1MCOM',
      'RexLOTmpP1MCOM',
      'RexStrAngOrderP1',
      'RexStrAngFBP1',
      'RexCPPBladeAngOrderP1',
      'RexCPPBladeAngFBP1',
      'RexClutchengagedFBP1',
      'MegSpdS1',
      'MegTCSpdS1',
      'MegTCbstpressS1COM',
      'MegFuelRackLvS1',
      'MegHpS1',
      'MegLOpressS1COM',
      'MegLOTmpS1COM',
      'MegNo1EXTGASTmpS1COM',
      'MegNo2EXTGASTmpS1COM',
      'MegNo3EXTGASTmpS1COM',
      'MegNo4EXTGASTmpS1COM',
      'MegNo5EXTGASTmpS1COM',
      'MegNo6EXTGASTmpS1COM',
      'MegNo7EXTGASTmpS1COM',
      'MegNo8EXTGASTmpS1COM',
      'MegNo9EXTGASTmpS1COM',
      'RexLOPressS1MCOM',
      'RexLOTmpS1MCOM',
      'RexStrAngOrderS1',
      'RexStrAngFBS1',
      'RexCPPBladeAngOrderS1',
      'RexCPPBladeAngFBS1',
      'RexClutchengagedFBS1',
    ];
    AppLogger.info(headers.join('\t'));

    const timezoneOffset = dayjs().utcOffset() * 60000;
    if (commonSensorData.logdate.length > 0) {
      for (let i = 0; i < commonSensorData.logdate.length; i++) {
        if (isNaN(commonSensorData.logdate[i]) == false) {
          // let dumpLine = dayjs(commonSensorData.logdate[i]).utc().add(-timezoneOffset).format('YYYY/MM/DD HH:mm:ss');
          let dumpLine =
            dayjs(commonSensorData.logdate[i])
              .add(-timezoneOffset)
              .format('YYYY-MM-DDTHH:mm:ss.SSS') + '000';
          if (commonSensorData.sensors.megSpdP1 !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.megSpdP1.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.megTCSpdP1 !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.megTCSpdP1.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.megTCbstpressP1 !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.megTCbstpressP1.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.megFuelRackLvP1 !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.megFuelRackLvP1.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.megHpP1 !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.megHpP1.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.megLOpressP1 !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.megLOpressP1.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.megLOTmpP1 !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.megLOTmpP1.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.megNo1EXTGASTmpP1 !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.megNo1EXTGASTmpP1.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.megNo2EXTGASTmpP1 !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.megNo2EXTGASTmpP1.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.megNo3EXTGASTmpP1 !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.megNo3EXTGASTmpP1.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.megNo4EXTGASTmpP1 !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.megNo4EXTGASTmpP1.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.megNo5EXTGASTmpP1 !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.megNo5EXTGASTmpP1.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.megNo6EXTGASTmpP1 !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.megNo6EXTGASTmpP1.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.megNo7EXTGASTmpP1 !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.megNo7EXTGASTmpP1.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.megNo8EXTGASTmpP1 !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.megNo8EXTGASTmpP1.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.megNo9EXTGASTmpP1 !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.megNo9EXTGASTmpP1.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.rexLOPressP1 !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.rexLOPressP1.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.rexLOTmpP1 !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.rexLOTmpP1.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.rexStrAngOrderP1 !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.rexStrAngOrderP1.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.rexStrAngFBP1 !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.rexStrAngFBP1.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.rexCPPBladeAngOrderP1 !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.rexCPPBladeAngOrderP1.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.rexCPPBladeAngFBP1 !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.rexCPPBladeAngFBP1.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.rexClutchengagedFBP1 !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.rexClutchengagedFBP1.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.megSpdS1 !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.megSpdS1.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.megTCSpdS1 !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.megTCSpdS1.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.megTCbstpressS1 !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.megTCbstpressS1.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.megFuelRackLvS1 !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.megFuelRackLvS1.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.megHpS1 !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.megHpS1.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.megLOpressS1 !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.megLOpressS1.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.megLOTmpS1 !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.megLOTmpS1.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.megNo1EXTGASTmpS1 !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.megNo1EXTGASTmpS1.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.megNo2EXTGASTmpS1 !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.megNo2EXTGASTmpS1.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.megNo3EXTGASTmpS1 !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.megNo3EXTGASTmpS1.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.megNo4EXTGASTmpS1 !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.megNo4EXTGASTmpS1.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.megNo5EXTGASTmpS1 !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.megNo5EXTGASTmpS1.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.megNo6EXTGASTmpS1 !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.megNo6EXTGASTmpS1.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.megNo7EXTGASTmpS1 !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.megNo7EXTGASTmpS1.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.megNo8EXTGASTmpS1 !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.megNo8EXTGASTmpS1.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.megNo9EXTGASTmpS1 !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.megNo9EXTGASTmpS1.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.rexLOPressS1 !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.rexLOPressS1.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.rexLOTmpS1 !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.rexLOTmpS1.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.rexStrAngOrderS1 !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.rexStrAngOrderS1.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.rexStrAngFBS1 !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.rexStrAngFBS1.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.rexCPPBladeAngOrderS1 !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.rexCPPBladeAngOrderS1.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.rexCPPBladeAngFBS1 !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.rexCPPBladeAngFBS1.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.rexClutchengagedFBS1 !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.rexClutchengagedFBS1.data[i]);
          } else {
            dumpLine += '\t';
          }
          AppLogger.info(dumpLine);
        }
      }
    }
  } else if (dataFormatId == 401) {
    const headers = [
      'DateTime',
      'MegSpdP1',
      'MegTCSpdP1',
      'MegTCboostpressP1RIO',
      'MegFuelRackLvP1',
      'MegHpP1',
      'MegInletAirTmpP1',
      'MegNo1EXTGASTmpP1RIO',
      'MegNo2EXTGASTmpP1RIO',
      'MegNo3EXTGASTmpP1RIO',
      'MegNo4EXTGASTmpP1RIO',
      'MegNo5EXTGASTmpP1RIO',
      'MegNo6EXTGASTmpP1RIO',
      'MegNo7EXTGASTmpP1RIO',
      'MegNo8EXTGASTmpP1RIO',
      'MegNo9EXTGASTmpP1RIO',
      'RexLOPressP1RIO',
      'RexLOTmpP1RIO',
      'RexStrAngOrderP1',
      'RexStrAngFBP1',
      'RexCPPBladeAngOrderP1',
      'RexCPPBladeAngFBP1',
      'RexClutchengagedFBP1',
      'MegSpdS1',
      'MegTCSpdS1',
      'MegTCboostpressS1RIO',
      'MegFuelRackLvS1',
      'MegHpS1',
      'MegInletAirTmpS1',
      'MegNo1EXTGASTmpS1RIO',
      'MegNo2EXTGASTmpS1RIO',
      'MegNo3EXTGASTmpS1RIO',
      'MegNo4EXTGASTmpS1RIO',
      'MegNo5EXTGASTmpS1RIO',
      'MegNo6EXTGASTmpS1RIO',
      'MegNo7EXTGASTmpS1RIO',
      'MegNo8EXTGASTmpS1RIO',
      'MegNo9EXTGASTmpS1RIO',
      'RexLOPressS1RIO',
      'RexLOTmpS1RIO',
      'RexStrAngOrderS1',
      'RexStrAngFBS1',
      'RexCPPBladeAngOrderS1',
      'RexCPPBladeAngFBS1',
      'RexClutchengagedFBS1',
    ];
    AppLogger.info(headers.join('\t'));

    const timezoneOffset = dayjs().utcOffset() * 60000;
    if (commonSensorData.logdate.length > 0) {
      for (let i = 0; i < commonSensorData.logdate.length; i++) {
        if (isNaN(commonSensorData.logdate[i]) == false) {
          // let dumpLine = dayjs(commonSensorData.logdate[i]).utc().add(-timezoneOffset).format('YYYY/MM/DD HH:mm:ss');
          let dumpLine =
            dayjs(commonSensorData.logdate[i])
              .add(-timezoneOffset)
              .format('YYYY-MM-DDTHH:mm:ss.SSS') + '000';
          if (commonSensorData.sensors.megSpdP1 !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.megSpdP1.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.megTCSpdP1 !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.megTCSpdP1.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.megTCbstpressP1 !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.megTCbstpressP1.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.megFuelRackLvP1 !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.megFuelRackLvP1.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.megHpP1 !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.megHpP1.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.megInletAirTmpP1 !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.megInletAirTmpP1.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.megNo1EXTGASTmpP1 !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.megNo1EXTGASTmpP1.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.megNo2EXTGASTmpP1 !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.megNo2EXTGASTmpP1.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.megNo3EXTGASTmpP1 !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.megNo3EXTGASTmpP1.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.megNo4EXTGASTmpP1 !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.megNo4EXTGASTmpP1.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.megNo5EXTGASTmpP1 !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.megNo5EXTGASTmpP1.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.megNo6EXTGASTmpP1 !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.megNo6EXTGASTmpP1.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.megNo7EXTGASTmpP1 !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.megNo7EXTGASTmpP1.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.megNo8EXTGASTmpP1 !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.megNo8EXTGASTmpP1.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.megNo9EXTGASTmpP1 !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.megNo9EXTGASTmpP1.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.rexLOPressP1 !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.rexLOPressP1.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.rexLOTmpP1 !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.rexLOTmpP1.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.rexStrAngOrderP1 !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.rexStrAngOrderP1.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.rexStrAngFBP1 !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.rexStrAngFBP1.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.rexCPPBladeAngOrderP1 !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.rexCPPBladeAngOrderP1.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.rexCPPBladeAngFBP1 !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.rexCPPBladeAngFBP1.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.rexClutchengagedFBP1 !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.rexClutchengagedFBP1.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.megSpdS1 !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.megSpdS1.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.megTCSpdS1 !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.megTCSpdS1.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.megTCbstpressS1 !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.megTCbstpressS1.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.megFuelRackLvS1 !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.megFuelRackLvS1.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.megHpS1 !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.megHpS1.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.megInletAirTmpS1 !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.megInletAirTmpS1.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.megNo1EXTGASTmpS1 !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.megNo1EXTGASTmpS1.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.megNo2EXTGASTmpS1 !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.megNo2EXTGASTmpS1.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.megNo3EXTGASTmpS1 !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.megNo3EXTGASTmpS1.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.megNo4EXTGASTmpS1 !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.megNo4EXTGASTmpS1.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.megNo5EXTGASTmpS1 !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.megNo5EXTGASTmpS1.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.megNo6EXTGASTmpS1 !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.megNo6EXTGASTmpS1.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.megNo7EXTGASTmpS1 !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.megNo7EXTGASTmpS1.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.megNo8EXTGASTmpS1 !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.megNo8EXTGASTmpS1.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.megNo9EXTGASTmpS1 !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.megNo9EXTGASTmpS1.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.rexLOPressS1 !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.rexLOPressS1.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.rexLOTmpS1 !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.rexLOTmpS1.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.rexStrAngOrderS1 !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.rexStrAngOrderS1.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.rexStrAngFBS1 !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.rexStrAngFBS1.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.rexCPPBladeAngOrderS1 !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.rexCPPBladeAngOrderS1.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.rexCPPBladeAngFBS1 !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.rexCPPBladeAngFBS1.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.rexClutchengagedFBS1 !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.rexClutchengagedFBS1.data[i]);
          } else {
            dumpLine += '\t';
          }
          AppLogger.info(dumpLine);
        }
      }
    }
  } else {
    const headers = [
      'DateTime',
      'EngSpdP',
      'EngTcSpdP',
      'EngChargedAirPressP',
      'EngFuelRackLvP',
      'EngSftHpP',
      'EngLoPressP',
      'EngChargedAirTempP',
      'EngLoInTempP',
      'EngNo1ExtGasTempP',
      'EngNo2ExtGasTempP',
      'EngNo3ExtGasTempP',
      'EngNo4ExtGasTempP',
      'EngNo5ExtGasTempP',
      'EngNo6ExtGasTempP',
      'EngTcInTemp145P',
      'EngTcInTemp236P',
      'EngTcOutTempP',
      'RexLOPressP',
      'RexLoTempP',
      'RexStrAngOrderP',
      'RexStrAngFbP',
      'RexClutchEngFbP',
      'EngSpdS',
      'EngTcSpdS',
      'EngChargedAirPressS',
      'EngFuelRackLvS',
      'EngSftHpS',
      'EngLoPressS',
      'EngChargedAirTempS',
      'EngLoInTempS',
      'EngNo1ExtGasTempS',
      'EngNo2ExtGasTempS',
      'EngNo3ExtGasTempS',
      'EngNo4ExtGasTempP',
      'EngNo5ExtGasTempS',
      'EngNo6ExtGasTempP',
      'EngTcInTemp145S',
      'EngTcInTemp236S',
      'EngTcOutTempS',
      'RexLoPressS',
      'RexLoTempS',
      'RexStrAngOrderS',
      'RexStrAngFbS',
      'RexClutchEngFbS',
    ];
    AppLogger.info(headers.join('\t'));

    const timezoneOffset = dayjs().utcOffset() * 60000;
    if (commonSensorData.logdate.length > 0) {
      for (let i = 0; i < commonSensorData.logdate.length; i++) {
        if (isNaN(commonSensorData.logdate[i]) == false) {
          // let dumpLine = dayjs(commonSensorData.logdate[i]).utc().add(-timezoneOffset).format('YYYY/MM/DD HH:mm:ss');
          let dumpLine =
            dayjs(commonSensorData.logdate[i])
              .add(-timezoneOffset)
              .format('YYYY-MM-DDTHH:mm:ss.SSS') + '000';
          if (commonSensorData.sensors.engSpdP !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.engSpdP.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.engTcSpdP !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.engTcSpdP.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.engChargedAirPressP !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.engChargedAirPressP.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.engFuelRackLvP !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.engFuelRackLvP.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.engSftHpP !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.engSftHpP.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.engLoPressP !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.engLoPressP.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.engChargedAirTempP !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.engChargedAirTempP.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.engLoInTempP !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.engLoInTempP.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.engNo1ExtGasTempP !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.engNo1ExtGasTempP.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.engNo2ExtGasTempP !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.engNo2ExtGasTempP.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.engNo3ExtGasTempP !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.engNo3ExtGasTempP.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.engNo4ExtGasTempP !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.engNo4ExtGasTempP.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.engNo5ExtGasTempP !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.engNo5ExtGasTempP.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.engNo6ExtGasTempP !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.engNo6ExtGasTempP.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.engTcInTemp145P !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.engTcInTemp145P.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.engTcInTemp236P !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.engTcInTemp236P.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.engTcOutTempP !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.engTcOutTempP.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.rexLOPressP !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.rexLOPressP.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.rexLoTempP !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.rexLoTempP.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.rexStrAngOrderP !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.rexStrAngOrderP.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.rexStrAngFbP !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.rexStrAngFbP.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.rexClutchEngFbP !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.rexClutchEngFbP.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.engSpdS !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.engSpdS.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.engTcSpdS !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.engTcSpdS.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.engChargedAirPressS !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.engChargedAirPressS.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.engFuelRackLvS !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.engFuelRackLvS.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.engSftHpS !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.engSftHpS.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.engLoPressS !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.engLoPressS.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.engChargedAirTempS !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.engChargedAirTempS.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.engLoInTempS !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.engLoInTempS.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.engNo1ExtGasTempS !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.engNo1ExtGasTempS.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.engNo2ExtGasTempS !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.engNo2ExtGasTempS.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.engNo3ExtGasTempS !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.engNo3ExtGasTempS.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.engNo4ExtGasTempS !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.engNo4ExtGasTempS.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.engNo5ExtGasTempS !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.engNo5ExtGasTempS.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.engNo6ExtGasTempS !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.engNo6ExtGasTempS.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.engTcInTemp145S !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.engTcInTemp145S.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.engTcInTemp236S !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.engTcInTemp236S.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.engTcOutTempS !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.engTcOutTempS.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.rexLoPressS !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.rexLoPressS.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.rexLoTempS !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.rexLoTempS.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.rexStrAngOrderS !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.rexStrAngOrderS.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.rexStrAngFbS !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.rexStrAngFbS.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.rexClutchEngFbS !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.rexClutchEngFbS.data[i]);
          } else {
            dumpLine += '\t';
          }
          AppLogger.info(dumpLine);
        }
      }
    }
  }
}

// ==================================================================
// Past Data Day Select Graph Data & Past Data Search Condition
// 【参照】船舶状態_カレンダー選択_グラフ & 【参照】船舶状態_表示条件変更_グラフ
// ==================================================================
export function dumpPastDataDaySelectGraphData(sensorNames: string[], sensorDataList: any): void {
  AppLogger.info('=== dumpPastDataDaySelectGraphData or dumpPastDataSearchConditionGraphData');

  AppLogger.info('DateTime\t' + sensorNames.join('\t'));

  const timezoneOffset = dayjs().utcOffset() * 60000;
  const logdate: number[] = sensorDataList.logdate;
  for (let i = 0; i < logdate.length; i++) {
    if (isNaN(logdate[i]) == false) {
      // let dumpLine = dayjs(logdate[i]).utc().add(-timezoneOffset).format('YYYY/MM/DD HH:mm:ss');
      let dumpLine =
        dayjs(logdate[i]).add(-timezoneOffset).format('YYYY-MM-DDTHH:mm:ss.SSS') + '000';
      sensorNames.forEach((sensorName) => {
        const sensorData: SensorData = sensorDataList.sensors[sensorName];
        let data = sensorData.data[i];
        if (data != undefined && sensorData.sensorGroup.displayUnit == 'deg') {
          if (data < -0.1) {
            data += 360;
          }
        }
        dumpLine += '\t' + toLogText(data);
      });
      AppLogger.info(dumpLine);
    }
  }
}

// ==================================================================
// Past Data Search Condition Map Data
// 【参照】船舶状態_表示条件変更_地図
// ==================================================================
export function dumpPastDataSearchConditionMapData(commonSensorData: CommonSensorData): void {
  AppLogger.info('=== dumpPastDataSearchConditionMapData');
  AppLogger.info('machineId: ' + String(commonSensorData.machineId));

  let vesLatC1MCOMTitle = null;
  let vesLonC1MCOMTitle = null;
  let vesSpdSOGC1Title = null;
  let vesCourseTrueDirC1MCOMTitle = null;
  let vesWindDirRelC1Title = null;
  let VesWindSpdRelC1MCOMTitle = null;
  if (commonSensorData.dataFormatId != 402) {
    vesLatC1MCOMTitle = 'VesLatC1MCOM';
    vesLonC1MCOMTitle = 'VesLonC1MCOM';
    vesSpdSOGC1Title = 'VesSpdSOGC1MCOM';
    vesCourseTrueDirC1MCOMTitle = 'VesCourseTrueDirC1MCOM';
    vesWindDirRelC1Title = 'VesWindDirRelC1';
    VesWindSpdRelC1MCOMTitle = 'VesWindSpdRelC1MCOM';
    if (commonSensorData.sensors.vesLatC1 !== undefined) {
      vesLatC1MCOMTitle = commonSensorData.sensors.vesLatC1.sensor.sensorName;
    }
    if (commonSensorData.sensors.vesLonC1 !== undefined) {
      vesLonC1MCOMTitle = commonSensorData.sensors.vesLonC1.sensor.sensorName;
    }
    if (commonSensorData.sensors.vesSpdSOGC1 !== undefined) {
      vesSpdSOGC1Title = commonSensorData.sensors.vesSpdSOGC1.sensor.sensorName;
    }
    if (commonSensorData.sensors.vesCourseTrueDirC1 !== undefined) {
      vesCourseTrueDirC1MCOMTitle = commonSensorData.sensors.vesCourseTrueDirC1.sensor.sensorName;
    }
    if (commonSensorData.sensors.vesWindDirRelC1 !== undefined) {
      vesWindDirRelC1Title = commonSensorData.sensors.vesWindDirRelC1.sensor.sensorName;
    }
    if (commonSensorData.sensors.vesWindSpdRelC1 !== undefined) {
      VesWindSpdRelC1MCOMTitle = commonSensorData.sensors.vesWindSpdRelC1.sensor.sensorName;
    }
  } else {
    vesLatC1MCOMTitle = 'VesLat';
    vesLonC1MCOMTitle = 'VesLon';
    vesSpdSOGC1Title = 'VesSog';
    vesCourseTrueDirC1MCOMTitle = 'VesHeading';
    vesWindDirRelC1Title = 'VesWindDirMagnitudMagDeg';
    VesWindSpdRelC1MCOMTitle = 'VesWindSpd';
    if (commonSensorData.sensors.vesLat !== undefined) {
      vesLatC1MCOMTitle = commonSensorData.sensors.vesLat.sensor.sensorName;
    }
    if (commonSensorData.sensors.vesLon !== undefined) {
      vesLonC1MCOMTitle = commonSensorData.sensors.vesLon.sensor.sensorName;
    }
    if (commonSensorData.sensors.vesSog !== undefined) {
      vesSpdSOGC1Title = commonSensorData.sensors.vesSog.sensor.sensorName;
    }
    if (commonSensorData.sensors.vesHeading !== undefined) {
      vesCourseTrueDirC1MCOMTitle = commonSensorData.sensors.vesHeading.sensor.sensorName;
    }
    if (commonSensorData.sensors.vesWindDirMagnitudMagDeg !== undefined) {
      vesWindDirRelC1Title = commonSensorData.sensors.vesWindDirMagnitudMagDeg.sensor.sensorName;
    }
    if (commonSensorData.sensors.vesWindSpd !== undefined) {
      VesWindSpdRelC1MCOMTitle = commonSensorData.sensors.vesWindSpd.sensor.sensorName;
    }
  }



  const headers = [
    'DateTime',
    vesLatC1MCOMTitle,
    vesLonC1MCOMTitle,
    vesSpdSOGC1Title,
    vesCourseTrueDirC1MCOMTitle,
    vesWindDirRelC1Title,
    VesWindSpdRelC1MCOMTitle,
  ];
  AppLogger.info(headers.join('\t'));

  const timezoneOffset = dayjs().utcOffset() * 60000;
  if (commonSensorData.logdate.length > 0) {
    for (let i = 0; i < commonSensorData.logdate.length; i++) {
      if (isNaN(commonSensorData.logdate[i]) == false) {
        // let dumpLine = dayjs(commonSensorData.logdate[i]).utc().add(-timezoneOffset).format('YYYY/MM/DD HH:mm:ss');
        let dumpLine =
          dayjs(commonSensorData.logdate[i])
            .add(-timezoneOffset)
            .format('YYYY-MM-DDTHH:mm:ss.SSS') + '000';

        if (commonSensorData.dataFormatId != 402) {
          if (commonSensorData.sensors.vesLatC1 !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.vesLatC1.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.vesLonC1 !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.vesLonC1.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.vesSpdSOGC1 !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.vesSpdSOGC1.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.vesCourseTrueDirC1 !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.vesCourseTrueDirC1.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.vesWindDirRelC1 !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.vesWindDirRelC1.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.vesWindSpdRelC1 !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.vesWindSpdRelC1.data[i]);
          } else {
            dumpLine += '\t';
          }
        } else {
          if (commonSensorData.sensors.vesLat !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.vesLat.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.vesLon !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.vesLon.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.vesSog !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.vesSog.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.vesHeading !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.vesHeading.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.vesWindDirMagnitudMagDeg !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.vesWindDirMagnitudMagDeg.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.vesWindSpd !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.vesWindSpd.data[i]);
          } else {
            dumpLine += '\t';
          }
        }

        AppLogger.info(dumpLine);
      }
    }
  }
}

// ==================================================================
// Past Data Search Condition Mimic Data
// 【参照】船舶状態_表示条件変更_mimic
// ==================================================================
export function dumpPastDataSearchConditionMimicData(
  commonSensorData: CommonSensorData,
  machine: Machine
): void {
  AppLogger.info('=== dumpPastDataSearchConditionMimicData');
  AppLogger.info('machineId: ' + String(commonSensorData.machineId));

  if (machine.dataFormatId == 400) {
    const headers = [
      'DateTime',
      'MegSpdP1',
      'MegTCSpdP1',
      'MegTCbstpressP1COM',
      'MegFuelRackLvP1',
      'MegHpP1',
      'MegLOpressP1COM',
      'MegLOTmpP1COM',
      'MegNo1EXTGASTmpP1COM',
      'MegNo2EXTGASTmpP1COM',
      'MegNo3EXTGASTmpP1COM',
      'MegNo4EXTGASTmpP1COM',
      'MegNo5EXTGASTmpP1COM',
      'MegNo6EXTGASTmpP1COM',
      'MegNo7EXTGASTmpP1COM',
      'MegNo8EXTGASTmpP1COM',
      'MegNo9EXTGASTmpP1COM',
      'RexLOPressP1MCOM',
      'RexLOTmpP1MCOM',
      'RexStrAngOrderP1',
      'RexStrAngFBP1',
      'RexCPPBladeAngOrderP1',
      'RexCPPBladeAngFBP1',
      'RexClutchengagedFBP1',
      'MegSpdS1',
      'MegTCSpdS1',
      'MegTCbstpressS1COM',
      'MegFuelRackLvS1',
      'MegHpS1',
      'MegLOpressS1COM',
      'MegLOTmpS1COM',
      'MegNo1EXTGASTmpS1COM',
      'MegNo2EXTGASTmpS1COM',
      'MegNo3EXTGASTmpS1COM',
      'MegNo4EXTGASTmpS1COM',
      'MegNo5EXTGASTmpS1COM',
      'MegNo6EXTGASTmpS1COM',
      'MegNo7EXTGASTmpS1COM',
      'MegNo8EXTGASTmpS1COM',
      'MegNo9EXTGASTmpS1COM',
      'RexLOPressS1MCOM',
      'RexLOTmpS1MCOM',
      'RexStrAngOrderS1',
      'RexStrAngFBS1',
      'RexCPPBladeAngOrderS1',
      'RexCPPBladeAngFBS1',
      'RexClutchengagedFBS1',
    ];
    AppLogger.info(headers.join('\t'));

    const timezoneOffset = dayjs().utcOffset() * 60000;
    if (commonSensorData.logdate.length > 0) {
      for (let i = 0; i < commonSensorData.logdate.length; i++) {
        if (isNaN(commonSensorData.logdate[i]) == false) {
          // let dumpLine = dayjs(commonSensorData.logdate[i]).utc().add(-timezoneOffset).format('YYYY/MM/DD HH:mm:ss');
          let dumpLine =
            dayjs(commonSensorData.logdate[i])
              .add(-timezoneOffset)
              .format('YYYY-MM-DDTHH:mm:ss.SSS') + '000';
          if (commonSensorData.sensors.megSpdP1 !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.megSpdP1.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.megTCSpdP1 !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.megTCSpdP1.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.megTCbstpressP1 !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.megTCbstpressP1.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.megFuelRackLvP1 !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.megFuelRackLvP1.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.megHpP1 !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.megHpP1.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.megLOpressP1 !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.megLOpressP1.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.megLOTmpP1 !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.megLOTmpP1.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.megNo1EXTGASTmpP1 !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.megNo1EXTGASTmpP1.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.megNo2EXTGASTmpP1 !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.megNo2EXTGASTmpP1.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.megNo3EXTGASTmpP1 !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.megNo3EXTGASTmpP1.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.megNo4EXTGASTmpP1 !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.megNo4EXTGASTmpP1.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.megNo5EXTGASTmpP1 !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.megNo5EXTGASTmpP1.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.megNo6EXTGASTmpP1 !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.megNo6EXTGASTmpP1.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.megNo7EXTGASTmpP1 !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.megNo7EXTGASTmpP1.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.megNo8EXTGASTmpP1 !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.megNo8EXTGASTmpP1.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.megNo9EXTGASTmpP1 !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.megNo9EXTGASTmpP1.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.rexLOPressP1 !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.rexLOPressP1.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.rexLOTmpP1 !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.rexLOTmpP1.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.rexStrAngOrderP1 !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.rexStrAngOrderP1.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.rexStrAngFBP1 !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.rexStrAngFBP1.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.rexCPPBladeAngOrderP1 !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.rexCPPBladeAngOrderP1.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.rexCPPBladeAngFBP1 !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.rexCPPBladeAngFBP1.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.rexClutchengagedFBP1 !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.rexClutchengagedFBP1.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.megSpdS1 !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.megSpdS1.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.megTCSpdS1 !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.megTCSpdS1.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.megTCbstpressS1 !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.megTCbstpressS1.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.megFuelRackLvS1 !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.megFuelRackLvS1.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.megHpS1 !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.megHpS1.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.megLOpressS1 !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.megLOpressS1.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.megLOTmpS1 !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.megLOTmpS1.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.megNo1EXTGASTmpS1 !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.megNo1EXTGASTmpS1.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.megNo2EXTGASTmpS1 !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.megNo2EXTGASTmpS1.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.megNo3EXTGASTmpS1 !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.megNo3EXTGASTmpS1.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.megNo4EXTGASTmpS1 !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.megNo4EXTGASTmpS1.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.megNo5EXTGASTmpS1 !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.megNo5EXTGASTmpS1.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.megNo6EXTGASTmpS1 !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.megNo6EXTGASTmpS1.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.megNo7EXTGASTmpS1 !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.megNo7EXTGASTmpS1.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.megNo8EXTGASTmpS1 !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.megNo8EXTGASTmpS1.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.megNo9EXTGASTmpS1 !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.megNo9EXTGASTmpS1.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.rexLOPressS1 !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.rexLOPressS1.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.rexLOTmpS1 !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.rexLOTmpS1.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.rexStrAngOrderS1 !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.rexStrAngOrderS1.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.rexStrAngFBS1 !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.rexStrAngFBS1.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.rexCPPBladeAngOrderS1 !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.rexCPPBladeAngOrderS1.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.rexCPPBladeAngFBS1 !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.rexCPPBladeAngFBS1.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.rexClutchengagedFBS1 !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.rexClutchengagedFBS1.data[i]);
          } else {
            dumpLine += '\t';
          }
          AppLogger.info(dumpLine);
        }
      }
    }
  } else if (machine.dataFormatId == 401) {
    const headers = [
      'DateTime',
      'MegSpdP1',
      'MegTCSpdP1',
      'MegTCboostpressP1RIO',
      'MegFuelRackLvP1',
      'MegHpP1',
      'MegInletAirTmpP1',
      'MegNo1EXTGASTmpP1RIO',
      'MegNo2EXTGASTmpP1RIO',
      'MegNo3EXTGASTmpP1RIO',
      'MegNo4EXTGASTmpP1RIO',
      'MegNo5EXTGASTmpP1RIO',
      'MegNo6EXTGASTmpP1RIO',
      'MegNo7EXTGASTmpP1RIO',
      'MegNo8EXTGASTmpP1RIO',
      'MegNo9EXTGASTmpP1RIO',
      'RexLOPressP1RIO',
      'RexLOTmpP1RIO',
      'RexStrAngOrderP1',
      'RexStrAngFBP1',
      'RexCPPBladeAngOrderP1',
      'RexCPPBladeAngFBP1',
      'RexClutchengagedFBP1',
      'MegSpdS1',
      'MegTCSpdS1',
      'MegTCboostpressS1RIO',
      'MegFuelRackLvS1',
      'MegHpS1',
      'MegInletAirTmpS1',
      'MegNo1EXTGASTmpS1RIO',
      'MegNo2EXTGASTmpS1RIO',
      'MegNo3EXTGASTmpS1RIO',
      'MegNo4EXTGASTmpS1RIO',
      'MegNo5EXTGASTmpS1RIO',
      'MegNo6EXTGASTmpS1RIO',
      'MegNo7EXTGASTmpS1RIO',
      'MegNo8EXTGASTmpS1RIO',
      'MegNo9EXTGASTmpS1RIO',
      'RexLOPressS1RIO',
      'RexLOTmpS1RIO',
      'RexStrAngOrderS1',
      'RexStrAngFBS1',
      'RexCPPBladeAngOrderS1',
      'RexCPPBladeAngFBS1',
      'RexClutchengagedFBS1',
    ];
    AppLogger.info(headers.join('\t'));

    const timezoneOffset = dayjs().utcOffset() * 60000;
    if (commonSensorData.logdate.length > 0) {
      for (let i = 0; i < commonSensorData.logdate.length; i++) {
        if (isNaN(commonSensorData.logdate[i]) == false) {
          // let dumpLine = dayjs(commonSensorData.logdate[i]).utc().add(-timezoneOffset).format('YYYY/MM/DD HH:mm:ss');
          let dumpLine =
            dayjs(commonSensorData.logdate[i])
              .add(-timezoneOffset)
              .format('YYYY-MM-DDTHH:mm:ss.SSS') + '000';
          if (commonSensorData.sensors.megSpdP1 !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.megSpdP1.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.megTCSpdP1 !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.megTCSpdP1.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.megTCbstpressP1 !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.megTCbstpressP1.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.megFuelRackLvP1 !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.megFuelRackLvP1.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.megHpP1 !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.megHpP1.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.megInletAirTmpP1 !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.megInletAirTmpP1.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.megNo1EXTGASTmpP1 !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.megNo1EXTGASTmpP1.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.megNo2EXTGASTmpP1 !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.megNo2EXTGASTmpP1.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.megNo3EXTGASTmpP1 !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.megNo3EXTGASTmpP1.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.megNo4EXTGASTmpP1 !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.megNo4EXTGASTmpP1.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.megNo5EXTGASTmpP1 !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.megNo5EXTGASTmpP1.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.megNo6EXTGASTmpP1 !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.megNo6EXTGASTmpP1.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.megNo7EXTGASTmpP1 !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.megNo7EXTGASTmpP1.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.megNo8EXTGASTmpP1 !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.megNo8EXTGASTmpP1.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.megNo9EXTGASTmpP1 !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.megNo9EXTGASTmpP1.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.rexLOPressP1 !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.rexLOPressP1.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.rexLOTmpP1 !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.rexLOTmpP1.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.rexStrAngOrderP1 !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.rexStrAngOrderP1.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.rexStrAngFBP1 !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.rexStrAngFBP1.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.rexCPPBladeAngOrderP1 !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.rexCPPBladeAngOrderP1.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.rexCPPBladeAngFBP1 !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.rexCPPBladeAngFBP1.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.rexClutchengagedFBP1 !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.rexClutchengagedFBP1.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.megSpdS1 !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.megSpdS1.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.megTCSpdS1 !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.megTCSpdS1.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.megTCbstpressS1 !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.megTCbstpressS1.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.megFuelRackLvS1 !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.megFuelRackLvS1.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.megHpS1 !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.megHpS1.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.megInletAirTmpS1 !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.megInletAirTmpS1.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.megNo1EXTGASTmpS1 !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.megNo1EXTGASTmpS1.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.megNo2EXTGASTmpS1 !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.megNo2EXTGASTmpS1.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.megNo3EXTGASTmpS1 !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.megNo3EXTGASTmpS1.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.megNo4EXTGASTmpS1 !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.megNo4EXTGASTmpS1.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.megNo5EXTGASTmpS1 !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.megNo5EXTGASTmpS1.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.megNo6EXTGASTmpS1 !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.megNo6EXTGASTmpS1.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.megNo7EXTGASTmpS1 !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.megNo7EXTGASTmpS1.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.megNo8EXTGASTmpS1 !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.megNo8EXTGASTmpS1.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.megNo9EXTGASTmpS1 !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.megNo9EXTGASTmpS1.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.rexLOPressS1 !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.rexLOPressS1.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.rexLOTmpS1 !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.rexLOTmpS1.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.rexStrAngOrderS1 !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.rexStrAngOrderS1.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.rexStrAngFBS1 !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.rexStrAngFBS1.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.rexCPPBladeAngOrderS1 !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.rexCPPBladeAngOrderS1.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.rexCPPBladeAngFBS1 !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.rexCPPBladeAngFBS1.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.rexClutchengagedFBS1 !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.rexClutchengagedFBS1.data[i]);
          } else {
            dumpLine += '\t';
          }
          AppLogger.info(dumpLine);
        }
      }
    }
  } else {
    const headers = [
      'DateTime',
      'EngSpdP',
      'EngTcSpdP',
      'EngChargedAirPressP',
      'EngFuelRackLvP',
      'EngSftHpP',
      'EngLoPressP',
      'EngChargedAirTempP',
      'EngLoInTempP',
      'EngNo1ExtGasTempP',
      'EngNo2ExtGasTempP',
      'EngNo3ExtGasTempP',
      'EngNo4ExtGasTempP',
      'EngNo5ExtGasTempP',
      'EngNo6ExtGasTempP',
      'EngTcInTemp145P',
      'EngTcInTemp236P',
      'EngTcOutTempP',
      'RexLOPressP',
      'RexLoTempP',
      'RexStrAngOrderP',
      'RexStrAngFbP',
      'RexClutchEngFbP',
      'EngSpdS',
      'EngTcSpdS',
      'EngChargedAirPressS',
      'EngFuelRackLvS',
      'EngSftHpS',
      'EngLoPressS',
      'EngChargedAirTempS',
      'EngLoInTempS',
      'EngNo1ExtGasTempS',
      'EngNo2ExtGasTempS',
      'EngNo3ExtGasTempS',
      'EngNo4ExtGasTempS',
      'EngNo5ExtGasTempS',
      'EngNo6ExtGasTempS',
      'EngTcInTemp145S',
      'EngTcInTemp236S',
      'EngTcOutTempS',
      'RexLoPressS',
      'RexLoTempS',
      'RexStrAngOrderS',
      'RexStrAngFbS',
      'RexClutchEngFbS',
    ];
    AppLogger.info(headers.join('\t'));

    const timezoneOffset = dayjs().utcOffset() * 60000;
    if (commonSensorData.logdate.length > 0) {
      for (let i = 0; i < commonSensorData.logdate.length; i++) {
        if (isNaN(commonSensorData.logdate[i]) == false) {
          // let dumpLine = dayjs(commonSensorData.logdate[i]).utc().add(-timezoneOffset).format('YYYY/MM/DD HH:mm:ss');
          let dumpLine =
            dayjs(commonSensorData.logdate[i])
              .add(-timezoneOffset)
              .format('YYYY-MM-DDTHH:mm:ss.SSS') + '000';
          if (commonSensorData.sensors.engSpdP !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.engSpdP.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.engTcSpdP !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.engTcSpdP.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.engChargedAirPressP !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.engChargedAirPressP.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.engFuelRackLvP !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.engFuelRackLvP.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.engSftHpP !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.engSftHpP.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.engLoPressP !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.engLoPressP.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.engChargedAirTempP !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.engChargedAirTempP.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.engLoInTempP !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.engLoInTempP.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.engNo1ExtGasTempP !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.engNo1ExtGasTempP.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.engNo2ExtGasTempP !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.engNo2ExtGasTempP.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.engNo3ExtGasTempP !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.engNo3ExtGasTempP.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.engNo4ExtGasTempP !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.engNo4ExtGasTempP.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.engNo5ExtGasTempP !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.engNo5ExtGasTempP.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.engNo6ExtGasTempP !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.engNo6ExtGasTempP.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.engTcInTemp145P !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.engTcInTemp145P.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.engTcInTemp236P !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.engTcInTemp236P.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.engTcOutTempP !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.engTcOutTempP.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.rexLOPressP !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.rexLOPressP.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.rexLoTempP !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.rexLoTempP.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.rexStrAngOrderP !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.rexStrAngOrderP.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.rexStrAngFbP !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.rexStrAngFbP.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.rexClutchEngFbP !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.rexClutchEngFbP.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.engSpdS !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.engSpdS.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.engTcSpdS !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.engTcSpdS.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.engChargedAirPressS !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.engChargedAirPressS.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.engFuelRackLvS !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.engFuelRackLvS.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.engSftHpS !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.engSftHpS.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.engLoPressS !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.engLoPressS.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.engChargedAirTempS !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.engChargedAirTempS.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.engLoInTempS !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.engLoInTempS.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.engNo1ExtGasTempS !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.engNo1ExtGasTempS.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.engNo2ExtGasTempS !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.engNo2ExtGasTempS.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.engNo3ExtGasTempS !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.engNo3ExtGasTempS.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.engNo4ExtGasTempS !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.engNo4ExtGasTempS.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.engNo5ExtGasTempS !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.engNo5ExtGasTempS.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.engNo6ExtGasTempS !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.engNo6ExtGasTempS.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.engTcInTemp145S !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.engTcInTemp145S.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.engTcInTemp236S !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.engTcInTemp236S.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.engTcOutTempS !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.engTcOutTempS.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.rexLoPressS !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.rexLoPressS.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.rexLoTempS !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.rexLoTempS.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.rexStrAngOrderS !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.rexStrAngOrderS.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.rexStrAngFbS !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.rexStrAngFbS.data[i]);
          } else {
            dumpLine += '\t';
          }
          if (commonSensorData.sensors.rexClutchEngFbS !== undefined) {
            dumpLine += '\t' + toLogText(commonSensorData.sensors.rexClutchEngFbS.data[i]);
          } else {
            dumpLine += '\t';
          }
          AppLogger.info(dumpLine);
        }
      }
    }
  }
}

// ==================================================================
// Analysis Rex GearAnomalyDetection
// 【参照】データ分析_ベベルギア診断_グラフ
// ==================================================================
export function dumpAnalysisRexGearAnomalyDetection(analysisData: AnalysisData): void {
  AppLogger.info('=== dumpAnalysisRexGearAnomalyDetection');
  AppLogger.info('machineId: ' + String(analysisData.machineId));

  if (analysisData.data.rexGearAnomalyDetection == null) {
    return;
  }

  if (analysisData.data.rexGearAnomalyDetection.date.length > 0) {
    const portData = [
      analysisData.data.rexGearAnomalyDetection.UpperBevelMainShaftP,
      analysisData.data.rexGearAnomalyDetection.UpperBevelMainShaftS,
      analysisData.data.rexGearAnomalyDetection.UpperBevelVerticalShaftP,
      analysisData.data.rexGearAnomalyDetection.UpperBevelVerticalShaftS,
      analysisData.data.rexGearAnomalyDetection.LowerBevelPropellerShaftP,
      analysisData.data.rexGearAnomalyDetection.LowerBevelPropellerShaftS,
      analysisData.data.rexGearAnomalyDetection.LowerBevelVerticalShaftP,
      analysisData.data.rexGearAnomalyDetection.LowerBevelVerticalShaftS,
    ];
    const prefixNames = [
      'L_UpperBevel_MainShaft',
      'R_UpperBevel_MainShaft',
      'L_UpperBevel_VerticalShaft',
      'R_UpperBevel_VerticalShaft',
      'L_LowerBevel_PropellerShaft',
      'R_LowerBevel_PropellerShaft',
      'L_LowerBevel_VerticalShaft',
      'R_LowerBevel_VerticalShaft',
    ];
    const suffixNames = ['A', 'A2', 'A5', 'A8', 'A9', 'A11', 'A13', 'A14', 'A16', 'A17', 'color'];

    let headers = 'shipid\tlogdate';
    for (let i = 0; i < prefixNames.length; i++) {
      for (let j = 0; j < suffixNames.length; j++) {
        headers += '\t' + prefixNames[i] + '-' + suffixNames[j];
      }
    }
    AppLogger.info(headers);

    const timezoneOffset = dayjs().utcOffset() * 60000;
    for (let i = 0; i < analysisData.data.rexGearAnomalyDetection.date.length; i++) {
      // let dumpLine = analysisData.shipId + '\t' + dayjs(analysisData.data.rexGearAnomalyDetection.date[i]).utc().add(-timezoneOffset).format('YYYY-MM-DDTHH:mm:ss.SSS') + '000';
      // let dumpLine = analysisData.shipId + '\t' + dayjs(analysisData.data.rexGearAnomalyDetection.date[i]).add(-timezoneOffset).format('YYYY-MM-DDTHH:mm:ss.SSS') + '000';
      let dumpLine =
        analysisData.shipId +
        '\t' +
        dayjs(analysisData.data.rexGearAnomalyDetection.date[i])
          .add(-timezoneOffset)
          .format('YYYY-MM-DD');

      for (let j = 0; j < portData.length; j++) {
        const data = portData[j];

        for (let k = 0; k < suffixNames.length; k++) {
          if (suffixNames[k] == 'A') {
            dumpLine += '\t' + String(data.values[i]);
          } else if (suffixNames[k] == 'color') {
            dumpLine += '\t' + String(data.colors[i]);
          } else {
            const details = data.details[i];
            switch (suffixNames[k]) {
              case 'A2':
                dumpLine += '\t' + String(details.A02);
                break;
              case 'A5':
                dumpLine += '\t' + String(details.A05);
                break;
              case 'A8':
                dumpLine += '\t' + String(details.A08);
                break;
              case 'A9':
                dumpLine += '\t' + String(details.A09);
                break;
              case 'A11':
                dumpLine += '\t' + String(details.A11);
                break;
              case 'A13':
                dumpLine += '\t' + String(details.A13);
                break;
              case 'A14':
                dumpLine += '\t' + String(details.A14);
                break;
              case 'A16':
                dumpLine += '\t' + String(details.A16);
                break;
              case 'A17':
                dumpLine += '\t' + String(details.A17);
                break;
            }
          }
        }
      }
      AppLogger.info(dumpLine);
    }
  }
}

// ==================================================================
// Analysis Rex BearingAnomalyDetection
// 【参照】データ分析_軸受診断_グラフ
// ==================================================================
export function dumpAnalysisRexBearingAnomalyDetection(analysisData: AnalysisData): void {
  AppLogger.info('=== dumpAnalysisRexBearingAnomalyDetection');
  AppLogger.info('machineId: ' + String(analysisData.machineId));

  if (analysisData.data.rexBearingAnomalyDetection == null) {
    return;
  }

  if (analysisData.data.rexBearingAnomalyDetection.date.length > 0) {
    const portData = [
      analysisData.data.rexBearingAnomalyDetection.Bearing7P,
      analysisData.data.rexBearingAnomalyDetection.Bearing7S,
      analysisData.data.rexBearingAnomalyDetection.Bearing8P,
      analysisData.data.rexBearingAnomalyDetection.Bearing8S,
    ];
    const prefixNames = ['L_Bearing7', 'R_Bearing7', 'L_Bearing8', 'R_Bearing8'];
    const suffixNames = [
      'A',
      'Inner_A02',
      'Inner_A05',
      'Inner_A08',
      'Inner_A09',
      'Inner_A11',
      'Inner_A13',
      'Inner_A14',
      'Inner_A16',
      'Inner_A17',
      'Outer_A02',
      'Outer_A05',
      'Outer_A08',
      'Outer_A09',
      'Outer_A11',
      'Outer_A13',
      'Outer_A14',
      'Outer_A16',
      'Outer_A17',
    ];

    let headers = 'shipid\tlogdate';
    for (let i = 0; i < prefixNames.length; i++) {
      for (let j = 0; j < suffixNames.length; j++) {
        headers += '\t' + prefixNames[i] + '_' + suffixNames[j];
      }
    }
    AppLogger.info(headers);

    const timezoneOffset = dayjs().utcOffset() * 60000;
    for (let i = 0; i < analysisData.data.rexBearingAnomalyDetection.date.length; i++) {
      // let dumpLine = analysisData.shipId + '\t' + dayjs(analysisData.data.rexBearingAnomalyDetection.date[i]).utc().add(-timezoneOffset).format('YYYY-MM-DDTHH:mm:ss.SSS') + '000';
      // let dumpLine = analysisData.shipId + '\t' + dayjs(analysisData.data.rexBearingAnomalyDetection.date[i]).add(-timezoneOffset).format('YYYY-MM-DDTHH:mm:ss.SSS') + '000';
      let dumpLine =
        analysisData.shipId +
        '\t' +
        dayjs(analysisData.data.rexBearingAnomalyDetection.date[i])
          .add(-timezoneOffset)
          .format('YYYY-MM-DD');

      for (let j = 0; j < portData.length; j++) {
        const data = portData[j];

        for (let k = 0; k < suffixNames.length; k++) {
          if (suffixNames[k] == 'A') {
            dumpLine += '\t' + String(data.values[i]);
          } else {
            const details = data.details[i];
            switch (suffixNames[k]) {
              case 'Inner_A02':
                dumpLine += '\t' + String(details.InnerA02);
                break;
              case 'Inner_A05':
                dumpLine += '\t' + String(details.InnerA05);
                break;
              case 'Inner_A08':
                dumpLine += '\t' + String(details.InnerA08);
                break;
              case 'Inner_A09':
                dumpLine += '\t' + String(details.InnerA09);
                break;
              case 'Inner_A11':
                dumpLine += '\t' + String(details.InnerA11);
                break;
              case 'Inner_A13':
                dumpLine += '\t' + String(details.InnerA13);
                break;
              case 'Inner_A14':
                dumpLine += '\t' + String(details.InnerA14);
                break;
              case 'Inner_A16':
                dumpLine += '\t' + String(details.InnerA16);
                break;
              case 'Inner_A17':
                dumpLine += '\t' + String(details.InnerA17);
                break;
              case 'Outer_A02':
                dumpLine += '\t' + String(details.OuterA02);
                break;
              case 'Outer_A05':
                dumpLine += '\t' + String(details.OuterA05);
                break;
              case 'Outer_A08':
                dumpLine += '\t' + String(details.OuterA08);
                break;
              case 'Outer_A09':
                dumpLine += '\t' + String(details.OuterA09);
                break;
              case 'Outer_A11':
                dumpLine += '\t' + String(details.OuterA11);
                break;
              case 'Outer_A13':
                dumpLine += '\t' + String(details.OuterA13);
                break;
              case 'Outer_A14':
                dumpLine += '\t' + String(details.OuterA14);
                break;
              case 'Outer_A16':
                dumpLine += '\t' + String(details.OuterA16);
                break;
              case 'Outer_A17':
                dumpLine += '\t' + String(details.OuterA17);
                break;
            }
          }
        }
      }
      AppLogger.info(dumpLine);
    }
  }
}

// ==================================================================
// Analysis Rex PropellerForeignMatterEntrainment
// 【参照】データ分析_プロペラ異物巻込み(変化点スコア)_グラフ、【参照】データ分析_プロペラ異物巻込み_グラフ
// ==================================================================
export function dumpAnalysisRexPropellerForeignMatterEntrainment(analysisData: AnalysisData): void {
  // 【参照】データ分析_プロペラ異物巻込み(変化点スコア)_グラフ
  AppLogger.info('=== dumpAnalysisRexPropellerForeignMatterEntrainment (1)');
  AppLogger.info('machineId: ' + String(analysisData.machineId));

  if (analysisData.data.rexPropellerForeignMatterEntrainment == null) {
    return;
  }

  const headers1 = ['shipid', 'logdate', 'mah_port', 'mah_stbd', 'alerm_flags'];
  AppLogger.info(headers1.join('\t'));

  const timezoneOffset = dayjs().utcOffset() * 60000;
  for (
    let i = 0;
    i < analysisData.data.rexPropellerForeignMatterEntrainment.mahalanobis.date.length;
    i++
  ) {
    // let dumpLine = analysisData.shipId + '\t' + dayjs(analysisData.data.rexBearingAnomalyDetection.date[i]).utc().add(-timezoneOffset).format('YYYY-MM-DDTHH:mm:ss.SSS') + '000';
    // let dumpLine = analysisData.shipId + '\t' + dayjs(analysisData.data.rexBearingAnomalyDetection.date[i]).add(-timezoneOffset).format('YYYY-MM-DDTHH:mm:ss.SSS') + '000';
    let dumpLine =
      analysisData.shipId +
      '\t' +
      dayjs(analysisData.data.rexPropellerForeignMatterEntrainment.mahalanobis.date[i])
        .add(-timezoneOffset)
        .format('YYYY-MM-DD HH:mm:ss');
    dumpLine +=
      '\t' +
      toLogText(
        analysisData.data.rexPropellerForeignMatterEntrainment.mahalanobis.MahalanobisP1[i]
      );
    dumpLine +=
      '\t' +
      toLogText(
        analysisData.data.rexPropellerForeignMatterEntrainment.mahalanobis.MahalanobisS1[i]
      );
    dumpLine +=
      '\t' +
      toLogText(analysisData.data.rexPropellerForeignMatterEntrainment.mahalanobis.AlertFlags[i]);
    AppLogger.info(dumpLine);
  }

  // const test = analysisData.data.rexPropellerForeignMatterEntrainment.sensorData['MegSpdP1'];

  // 【参照】データ分析_プロペラ異物巻込み_グラフ
  AppLogger.info('=== dumpAnalysisRexPropellerForeignMatterEntrainment (2)');
  AppLogger.info('machineId: ' + String(analysisData.machineId));

  let headers2 = [];
  let degHeaders = [];
  if (analysisData.dataFormatId != 402) {
    headers2 = [
      'MegSpdP1',
      'MegFuelRackLvP1',
      'MegTCSpdP1',
      'RexLtStrPressP1RIO',
      'RexRtStrPressP1RIO',
      'RexCPPBladeAngOrderP1',
      'RexCPPBladeAngFBP1',
      'RexStrAngOrderP1',
      'RexStrAngFBP1',
      'MegSpdS1',
      'MegFuelRackLvS1',
      'MegTCSpdS1',
      'RexLtStrPressS1RIO',
      'RexRtStrPressS1RIO',
      'RexCPPBladeAngOrderS1',
      'RexCPPBladeAngFBS1',
      'RexStrAngOrderS1',
      'RexStrAngFBS1',
    ];

    degHeaders = [
      'RexCPPBladeAngOrderP1',
      'RexCPPBladeAngFBP1',
      'RexStrAngOrderP1',
      'RexStrAngFBP1',
      'RexCPPBladeAngOrderS1',
      'RexCPPBladeAngFBS1',
      'RexStrAngOrderS1',
      'RexStrAngFBS1',
    ];
  } else {
    headers2 = [
      'EngSpdP',
      'EngFuelRackLvP',
      'EngTcSpdP',
      'RexLtStrPressP',
      'RexRtStrPressP',
      'RexStrAngOrderP',
      'RexStrAngFbP',
      'EngSpdS',
      'EngFuelRackLvS',
      'EngTcSpdS',
      'RexLtStrPressS',
      'RexRtStrPressS',
      'RexStrAngOrderS',
      'RexStrAngFbS',
    ];

    degHeaders = [
      'RexStrAngOrderP',
      'RexStrAngFbP',
      'RexStrAngOrderS',
      'RexStrAngFbS',
    ];
  }

  const search = (targetElement: string) => (arrElement: string) => arrElement === targetElement;

  const tmpHeaders = ['DateTime'].concat(headers2);
  AppLogger.info(tmpHeaders.join('\t'));

  for (
    let i = 0;
    i < analysisData.data.rexPropellerForeignMatterEntrainment.sensorData.date.length;
    i++
  ) {
    let dumpLine =
      dayjs(analysisData.data.rexPropellerForeignMatterEntrainment.sensorData.date[i])
        .add(-timezoneOffset)
        .format('YYYY-MM-DDTHH:mm:ss.SSS') + '000';
    for (let j = 0; j < headers2.length; j++) {
      const sensorData =
        analysisData.data.rexPropellerForeignMatterEntrainment.sensorData[headers2[j]];
      let data = sensorData[i];
      if (degHeaders.some(search(headers2[j]))) {
        if (data < 0) {
          data += 360;
        }
      }
      dumpLine += '\t' + toLogText(data);
    }
    AppLogger.info(dumpLine);
  }
}
