import React from 'react';
import { styled } from '@mui/material';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import colors from 'resources/colors';

export const ChartSelectField = styled((props: TextFieldProps) => (
  <TextField select variant="outlined" size="small" fullWidth {...props} />
))({
  '& .MuiOutlinedInput-root': {
    color: colors.chart.header.color,
    '& svg': {
      color: colors.chart.header.color,
      right: '4px',
    },
    // '& fieldset': {
    //   borderColor: colors.listItem.boader,
    // },
    // '&:hover fieldset': {
    //   borderColor: colors.listItem.boader,
    // },
    // '&.Mui-focused fieldset': {
    //   borderColor: colors.listItem.boader,
    // },
  },
  '& .MuiOutlinedInput-input': {
    background: colors.primary,
    padding: '4px 8px',
  },
});
