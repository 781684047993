import { API } from 'aws-amplify';
import dayjs from 'dayjs';
import { Account, AccountGroup, AdminAccount, AdminAccountGroup } from 'models/accounts';
import { AdminShip, AdminMachine, AdminDataRecalc, DataRecalc } from 'models/admin';
import { KeyObject } from 'models/common';
import { getErrorResult } from 'models/error';
import { Ship, Machine } from 'models/ships';
import constants from 'resources/constants';
import AppLogger from 'utils/AppLogger';
import { API_NAME } from './maricoApi';

export type SettingType =
  | 'SensorAccessAuthorities'
  | 'AnalysisMenuAccessAuthorities'
  | 'SensorDiagnosisThresholds'
  | 'MegFactoryTestData'
  | 'MaritimeDrivingData';

/**
 * アカウント情報リストを取得する。
 */
export async function getAccountsAdminAsync(): Promise<Account[]> {
  try {
    const params = {
      headers: {},
      response: true,
    };

    const result = await API.get(API_NAME, '/v1/admin/accounts', params);
    const accounts = result.data as Account[];

    return accounts.map((x) => {
      return {
        accountId: x.accountId,
        cognitoAccountId: x.cognitoAccountId,
        authorityId: x.authorityId,
        authorityName: x.authorityName,
        accountGroupId: x.accountGroupId,
        accountGroupName: x.accountGroupName,
        mailAddress: x.mailAddress,
        mailAddressVerified: x.mailAddressVerified,
        accountStatus: x.accountStatus,
        homePath: x.homePath,
        createdAt: dayjs(x.createdAt).format(constants.dateFormat.iso8601WithoutSeconds),
        updatedAt: dayjs(x.updatedAt).format(constants.dateFormat.iso8601WithoutSeconds),
      };
    });
  } catch (error) {
    AppLogger.error('error getAccountsAdminAsync: ', error);
    throw getErrorResult(error);
  }
}

/**
 * アカウント情報を登録する。
 * @param account アカウント登録情報
 * @returns 登録されたアカウント情報
 */
export async function registrationAccountAdminAsync(account: AdminAccount): Promise<Account> {
  try {
    const params = {
      headers: {},
      response: true,
      body: account,
    };

    const result = await API.post(API_NAME, '/v1/admin/accounts', params);

    return result.data;
  } catch (error) {
    AppLogger.error('error registrationAccountAdminAsync: ', error);
    throw getErrorResult(error);
  }
}

/**
 * アカウント情報を更新する。
 * @param accountId アカウントID
 * @param account アカウント更新情報
 * @returns 更新されたアカウント情報
 */
export async function updateAccountAdminAsync(
  accountId: number,
  account: AdminAccount
): Promise<Account> {
  try {
    const params = {
      headers: {},
      response: true,
      body: account,
    };

    const result = await API.put(API_NAME, '/v1/admin/accounts/' + accountId, params);

    return result.data;
  } catch (error) {
    AppLogger.error('error updateAccountAdminAsync: ', error);
    throw getErrorResult(error);
  }
}

/**
 * アカウント情報を削除する。
 * @param accountId アカウントID
 */
export async function deleteAccountAdminAsync(accountId: number): Promise<void> {
  try {
    const params = {
      headers: {},
      response: true,
    };

    await API.del(API_NAME, '/v1/admin/accounts/' + accountId, params);
  } catch (error) {
    AppLogger.error('error deleteAccountAdminAsync: ', error);
    throw getErrorResult(error);
  }
}

/**
 * アカウントグループリストを取得する。
 */
export async function getAccountGroupsAdminAsync(): Promise<AccountGroup[]> {
  try {
    const params = {
      headers: {},
      response: true,
    };

    const result = await API.get(API_NAME, '/v1/admin/account-groups', params);
    const accountGroups = result.data as AccountGroup[];

    return accountGroups.map((x) => {
      return {
        accountGroupId: x.accountGroupId,
        accountGroupName: x.accountGroupName,
        accountGroupUrl: x.accountGroupUrl,
        accountGroupUrlText: x.accountGroupUrlText,
        accountGroupLogoUrl: x.accountGroupLogoUrl,
        createdAt: dayjs(x.createdAt).format(constants.dateFormat.iso8601WithoutSeconds),
        updatedAt: dayjs(x.updatedAt).format(constants.dateFormat.iso8601WithoutSeconds),
      };
    });
  } catch (error) {
    AppLogger.error('error getAccountGroupsAdminAsync: ', error);
    throw getErrorResult(error);
  }
}

/**
 * アカウントグループを登録する。
 * @param accountgroup アカウントグループ登録情報
 * @returns 更新されたアカウントグループ
 */
export async function registrationAccountGroupAdminAsync(
  accountgroup: AdminAccountGroup
): Promise<AccountGroup> {
  try {
    const params = {
      headers: {},
      response: true,
      body: accountgroup,
    };

    const result = await API.post(API_NAME, '/v1/admin/account-groups', params);

    return result.data;
  } catch (error) {
    AppLogger.error('error registrationAccountGroupAdminAsync: ', error);
    throw getErrorResult(error);
  }
}

/**
 * アカウントグループを更新する。
 * @param accountGroupId アカウントグループID
 * @param accountgroup アカウントグループ更新情報
 * @returns 更新されたアカウントグループ
 */
export async function updateAccountGroupAdminAsync(
  accountGroupId: number,
  accountgroup: AdminAccountGroup
): Promise<AccountGroup> {
  const body: KeyObject = {
    accountGroupId: accountgroup.accountGroupId,
    accountGroupName: accountgroup.accountGroupName,
  };
  if (accountgroup.accountGroupUrl) {
    body['accountGroupUrl'] = accountgroup.accountGroupUrl;
  }
  if (accountgroup.accountGroupLogo || accountgroup.accountGroupLogoCleared == true) {
    body['accountGroupLogo'] = accountgroup.accountGroupLogo ? accountgroup.accountGroupLogo : null;
  }

  try {
    const params = {
      headers: {},
      response: true,
      body: body,
    };

    const result = await API.put(API_NAME, '/v1/admin/account-groups/' + accountGroupId, params);

    return result.data;
  } catch (error) {
    AppLogger.error('error updateAccountGroupAdminAsync: ', error);
    throw getErrorResult(error);
  }
}

/**
 * アカウントグループを削除する。
 * @param accountGroupId アカウントグループID
 */
export async function deleteAccountGroupAdminAsync(accountGroupId: number): Promise<void> {
  try {
    const params = {
      headers: {},
      response: true,
    };

    await API.del(API_NAME, '/v1/admin/account-groups/' + accountGroupId, params);
  } catch (error) {
    AppLogger.error('error deleteAccountGroupAdminAsync: ', error);
    throw getErrorResult(error);
  }
}

/**
 * 船舶リストを取得する。
 */
export async function getShipsAdminAsync(): Promise<Ship[]> {
  try {
    const params = {
      headers: {},
      response: true,
    };

    const result = await API.get(API_NAME, '/v1/admin/ships', params);
    const ships = result.data as Ship[];

    return ships.map((x) => {
      return {
        shipId: x.shipId,
        name: x.name,
        machines: x.machines,
        propulsionSerialNumbers: x.propulsionSerialNumbers,
        createdAt: dayjs(x.createdAt).format(constants.dateFormat.iso8601WithoutSeconds),
        updatedAt: dayjs(x.updatedAt).format(constants.dateFormat.iso8601WithoutSeconds),
        checked: x.checked,
        propulsionSerialNumbersText: x.propulsionSerialNumbersText,
        machineNamesText: x.machineNamesText,
      };
    });
  } catch (error) {
    AppLogger.error('error getShipsAdminAsync: ', error);
    throw getErrorResult(error);
  }
}

/**
 * 船舶を登録する。
 * @param ship 船舶の登録情報
 * @returns 登録された船舶
 */
export async function registrationShipsAdminAsync(ship: AdminShip): Promise<Ship> {
  try {
    const params = {
      headers: {},
      response: true,
      body: ship,
    };

    const result = await API.post(API_NAME, '/v1/admin/ships', params);

    return result.data;
  } catch (error) {
    AppLogger.error('error registrationShipsAdminAsync: ', error);
    throw getErrorResult(error);
  }
}

/**
 * 船舶を更新する。
 * @param shipId 船舶ID
 * @param ship 船舶の更新情報
 * @returns 更新された船舶
 */
export async function updateShipsAdminAsync(shipId: string, ship: AdminShip): Promise<Ship> {
  try {
    const params = {
      headers: {},
      response: true,
      body: ship,
    };

    const result = await API.put(API_NAME, '/v1/admin/ships/' + shipId, params);

    return result.data;
  } catch (error) {
    AppLogger.error('error updateShipsAdminAsync: ', error);
    throw getErrorResult(error);
  }
}

/**
 * 船舶を削除する。
 * @param shipId 船舶ID
 */
export async function deleteShipAdminAsync(shipId: string): Promise<void> {
  try {
    const params = {
      headers: {},
      response: true,
    };

    await API.del(API_NAME, '/v1/admin/ships/' + shipId, params);
  } catch (error) {
    AppLogger.error('error deleteShipAdminAsync: ', error);
    throw getErrorResult(error);
  }
}

/**
 * 機械を登録する。
 * @param shipId 船舶ID
 * @param machine 機械の登録情報
 * @returns 登録された船舶
 */
export async function registrationMachineAdminAsync(
  shipId: string,
  machine: AdminMachine
): Promise<Ship> {
  try {
    const params = {
      headers: {},
      response: true,
      body: {
        machineId: machine.machineId,
        machineName: machine.name,
        dataFormatId: machine.dataFormatId,
        serialNumbers: machine.serialNumber ? machine.serialNumber.split(',') : [],
      },
    };

    const result = await API.post(API_NAME, '/v1/admin/ships/' + shipId + '/machines', params);

    return result.data;
  } catch (error) {
    AppLogger.error('error registrationShipsAdminAsync: ', error);
    throw getErrorResult(error);
  }
}

/**
 * 船舶を更新する。
 * @param shipId 船舶ID
 * @param ship 船舶の更新情報
 * @returns 更新された船舶
 */
export async function updateMachineAdminAsync(
  shipId: string,
  machineId: string,
  machine: AdminMachine
): Promise<Machine> {
  try {
    const params = {
      headers: {},
      response: true,
      body: {
        machineId: machine.machineId?.toString(),
        machineName: machine.name,
        dataFormatId: machine.dataFormatId,
        serialNumbers: machine.serialNumber ? machine.serialNumber.split(',') : undefined,
      },
    };

    const result = await API.put(
      API_NAME,
      '/v1/admin/ships/' + shipId + '/machines/' + machineId,
      params
    );

    return result.data;
  } catch (error) {
    AppLogger.error('error updateShipsAdminAsync: ', error);
    throw getErrorResult(error);
  }
}

/**
 * 船舶を削除する。
 * @param shipId 船舶ID
 */
export async function deleteMachineAdminAsync(shipId: string, machineId: string): Promise<void> {
  try {
    const params = {
      headers: {},
      response: true,
    };

    await API.del(API_NAME, '/v1/admin/ships/' + shipId + '/machines/' + machineId, params);
  } catch (error) {
    AppLogger.error('error deleteShipAdminAsync: ', error);
    throw getErrorResult(error);
  }
}

/**
 * 船舶を削除する。
 * @param shipId 船舶ID
 */
export async function uploadSettingsAdminAsync(
  settingType: SettingType,
  settings: string
): Promise<void> {
  try {
    const params = {
      headers: {},
      response: true,
      body: {
        settingType: settingType,
        settings: settings,
      },
    };

    await API.post(API_NAME, '/v1/admin/settings', params);
  } catch (error) {
    AppLogger.error('error uploadSettingsAdminAsync: ', error);
    throw getErrorResult(error);
  }
}

/**
 * データ再計算情報リストを取得する。
 */
export async function getDataRecalcAdminAsync(): Promise<DataRecalc[]> {
  try {
    const params = {
      headers: {},
      response: true,
    };

    const result = await API.get(API_NAME, '/v1/admin/data-recalcs', params);
    const dataRecalcs = result.data as DataRecalc[];

    return dataRecalcs.map((x) => {
      return {
        dataRecalcId: x.dataRecalcId,
        shipId: x.shipId,
        shipName: x.shipName,
        machineId: x.machineId,
        machineName: x.machineName,
        startDate: x.startDate,
        endDate: x.endDate,
        status: x.status,
        createdAt: dayjs(x.createdAt).format(constants.dateFormat.iso8601WithoutSeconds),
        updatedAt: dayjs(x.updatedAt).format(constants.dateFormat.iso8601WithoutSeconds),
      };
    });
  } catch (error) {
    AppLogger.error('error getDataRecalcAdminAsync: ', error);
    throw getErrorResult(error);
  }
}

/**
 * データ再計算情報を登録する。
 * @param dataRecalc データ再計算登録情報
 * @returns 登録されたデータ再計算情報
 */
export async function registrationDataRecalcAdminAsync(
  dataRecalc: AdminDataRecalc
): Promise<DataRecalc> {
  try {
    const params = {
      headers: {},
      response: true,
      body: dataRecalc,
    };

    const result = await API.post(API_NAME, '/v1/admin/data-recalcs', params);

    return result.data;
  } catch (error) {
    AppLogger.error('error registrationDataRecalcAdminAsync: ', error);
    throw getErrorResult(error);
  }
}
