import React from 'react';
import { useIntl } from 'react-intl';
import colors from 'resources/colors';
import {
  AnalysisSection,
  PeriodUnit,
  FuelConsumptionDetectionConditionItem,
} from 'models/analysis';
import { Checkbox, Divider, FormControl, Grid, styled, Typography } from '@mui/material';
import { theme } from 'resources/theme';
import { Lock } from '@mui/icons-material';
import { SearchConditionItem } from './SearchConditionItem';

const SectionHeader = styled(Grid)({
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1),
  minHeight: 32,
  background: colors.listItem.subHeader,
});

const ItemHeader = styled(Grid)({
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1),
  minHeight: 32,
  background: theme.palette.background.default,
});

export interface AnalysisMenuListProps {
  periodUnit: PeriodUnit;
  analysisSections: AnalysisSection[];
  onChange: (analysisSectionId: number, analysisMenuId: number, checked: boolean) => void;
  onFuelConsumptionDetectionConditionChange: (
    id: string,
    sensorName: string,
    upperLimit: number,
    lowerLimit: number,
    upperValue: number,
    lowerValue: number
  ) => void;
  fuelConsumptionDetectionCondition: FuelConsumptionDetectionConditionItem[];
}

export function AnalysisMenuList(props: AnalysisMenuListProps): JSX.Element {
  const {
    periodUnit,
    analysisSections,
    onChange,
    onFuelConsumptionDetectionConditionChange,
    fuelConsumptionDetectionCondition,
  } = props;
  const intl = useIntl();

  return (
    <div>
      {analysisSections.map((section) => {
        return (
          <React.Fragment key={section.analysisSectionName}>
            <SectionHeader container wrap="nowrap" alignItems="center">
              <Grid item>
                <Typography variant="body2" noWrap sx={{ verticalAlign: 'center' }}>
                  {intl.formatMessage({ id: 'AnalysisSection' + section.analysisSectionName })}
                </Typography>
              </Grid>
            </SectionHeader>
            <FormControl
              sx={{
                width: '100%',
                background: colors.subDrawer.background,
              }}
            >
              {section.analysisMenus.map((menu) => {
                return (
                  <React.Fragment key={section.analysisSectionName + '_' + menu.analysisMenuName}>
                    <ItemHeader container wrap="nowrap" alignItems="center">
                      <Grid item sx={{ width: 32, minWidth: 32 }}>
                        <Checkbox
                          sx={{ width: 24, height: 24 }}
                          size="small"
                          color="primary"
                          value={menu.analysisMenuName}
                          disabled={menu.locked || !menu[periodUnit]}
                          checked={menu.checked && menu[periodUnit]}
                          onChange={(event) =>
                            onChange(
                              section.analysisSectionId,
                              menu.analysisMenuId,
                              event.target.checked
                            )
                          }
                        />
                      </Grid>
                      <Grid item>
                        <Typography
                          variant="body2"
                          noWrap
                          sx={{
                            color:
                              menu.locked || !menu[periodUnit]
                                ? theme.palette.text.disabled
                                : theme.palette.text.primary,
                          }}
                        >
                          {intl.formatMessage({ id: 'AnalysisMenu' + menu.analysisMenuName })}
                        </Typography>
                      </Grid>
                      {menu.locked && (
                        <Grid item>
                          <Lock
                            sx={{
                              width: 18,
                              height: 18,
                              marginLeft: theme.spacing(1),
                              paddingTop: theme.spacing(0.5),
                            }}
                            htmlColor={theme.palette.text.disabled}
                          />
                        </Grid>
                      )}
                    </ItemHeader>
                    {menu.analysisMenuId === 15 &&
                      menu.checked &&
                      menu[periodUnit] &&
                      fuelConsumptionDetectionCondition.map((condition) => {
                        return (
                          <React.Fragment key={condition.id}>
                            <Divider />
                            <ItemHeader>
                              <SearchConditionItem
                                condition={condition}
                                onChange={onFuelConsumptionDetectionConditionChange}
                              />
                            </ItemHeader>
                          </React.Fragment>
                        );
                      })}
                    <Divider />
                  </React.Fragment>
                );
              })}
            </FormControl>
          </React.Fragment>
        );
      })}
    </div>
  );
}
