import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import msgId from 'resources/intl';
import colors from 'resources/colors';
import { Box, Grid, IconButton, styled, SxProps, Typography } from '@mui/material';
import { Attachment, Cancel } from '@mui/icons-material';
import { theme } from 'resources/theme';
import { NormalButton } from './NormalButton';
import dimens from 'resources/dimens';

const DropzonGrid = styled(Grid)({
  borderStyle: 'dashed',
  borderWidth: 2,
  borderColor: colors.border,
  background: colors.white,
  borderRadius: 6,
  minHeight: 80,
  maxWidth: dimens.dialogContents.width,
  margin: theme.spacing(0.5),
  padding: theme.spacing(1),
  color: colors.border,
  '&:hover': {
    borderColor: colors.accent,
  },
  '&:focus': {
    borderColor: colors.accent,
  },
  overflow: 'hidden',
});

const AttachmentIcon = styled(Attachment)({
  transform: 'rotate(-45deg)',
  width: 28,
  height: 28,
  marginTop: 4,
});

const ButtonGrid = styled(Grid)({
  marginBottom: theme.spacing(1),
});

const ButtonLabel = styled('label')({
  color: colors.primary,
  padding: '4px 16px 4px 16px',
  border: `1px solid ${colors.primary}`,
  borderRadius: '25px',
  paddingTop: '3px',
  paddingBottom: '3px',
  marginTop: theme.spacing(1),
  cursor: 'pointer',
});

interface FileUplodProps {
  id: string;
  sx?: SxProps;
  acceptFileType: string;
  onAttachFile?: (file: File | undefined) => void;
  onUpload?: (file: File) => void;
  errorMessage: string | undefined;
  attatchedFileName?: string;
}

export function FileUplod(props: FileUplodProps): JSX.Element {
  const { id, sx, acceptFileType, onAttachFile, onUpload, errorMessage, attatchedFileName } = props;
  const intl = useIntl();
  const [isDragHighlight, setIsHighlight] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [attachmentFile, setAttachmentFile] = useState<File | undefined>(undefined);

  const appendAttachmentFiles = (files: FileList) => {
    if (files.length > 0) {
      setAttachmentFile(files[0]);
      onAttachFile && onAttachFile(files[0]);
    }
  };

  const handleDragEnter = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  const handleDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    if (isDragging) {
      setIsDragging(false);
    }
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    if (!isDragging) {
      setIsDragging(true);
    }
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragging(false);
    if (event.dataTransfer.files && event.dataTransfer.files.length > 0) {
      appendAttachmentFiles(event.dataTransfer.files);
    }
  };

  const handleSelectAttachmentFiles = (event: React.ChangeEvent<HTMLInputElement>) => {
    const target: HTMLInputElement = event.target as HTMLInputElement;
    if (target !== null && target.files != null) {
      const files: FileList = target.files;
      appendAttachmentFiles(files);
      target.value = '';
    }
  };

  const handleClear = () => {
    setAttachmentFile(undefined);
    onAttachFile && onAttachFile(undefined);
  };

  const handleUploadClick = () => {
    if (attachmentFile) {
      onUpload && onUpload(attachmentFile);
    }
  };

  useEffect(() => {
    // 子要素に移動で発生のleave対策
    // leaveの後にoverが発生していなければハイライト解除のままとなる
    setIsHighlight(isDragging);
  }, [isDragging]);

  return (
    <Box sx={sx}>
      <Typography
        variant="body2"
        noWrap
        sx={{
          margin: '8px 4px 4px 8px',
          color: colors.accent,
        }}
      >
        {intl.formatMessage({ id: msgId.attachmentFiles })}
      </Typography>
      <DropzonGrid
        sx={{ borderColor: isDragHighlight ? colors.accent : colors.border }}
        container
        direction="column"
        alignItems="center"
        justifyContent="center"
        wrap="nowrap"
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
        onDragOver={handleDragOver}
        onDrop={handleDrop}
      >
        <Grid item>
          <Grid
            item
            container
            direction="row"
            alignItems="center"
            justifyContent="flex-start"
            wrap="nowrap"
          >
            <Grid item>
              <AttachmentIcon color="primary" />
            </Grid>
            <Grid item>
              <Typography variant="body2" sx={{ padding: '4px 4px 4px 8px' }}>
                {intl.formatMessage({ id: msgId.attachmentFilesGuiide })}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <ButtonGrid item>
          <input
            color="primary"
            accept={acceptFileType}
            type="file"
            multiple={false}
            onChange={(e) => handleSelectAttachmentFiles(e)}
            id={id}
            style={{ display: 'none' }}
          />
          <ButtonLabel
            htmlFor={id}
            style={{
              pointerEvents: isDragHighlight ? 'none' : 'inherit',
              whiteSpace: 'nowrap',
            }}
          >
            {intl.formatMessage({ id: msgId.fileSelect })}
          </ButtonLabel>
        </ButtonGrid>
      </DropzonGrid>
      {errorMessage && (
        <Typography variant="body2" color="error" sx={{ margin: '4px 8px 0px 8px' }}>
          {errorMessage}
        </Typography>
      )}
      {(attachmentFile || attatchedFileName) && (
        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="flex-start"
          wrap="nowrap"
        >
          <Grid item>
            <IconButton sx={{ margin: 0 }} onClick={handleClear}>
              <Cancel fontSize="inherit" htmlColor={colors.border} />
            </IconButton>
          </Grid>
          <Grid item>
            <Typography variant="body2" sx={{ paddingTop: '2px' }}>
              {attatchedFileName ? attatchedFileName : attachmentFile ? attachmentFile.name : ''}
            </Typography>
          </Grid>
        </Grid>
      )}
      {onUpload && (
        <NormalButton
          disabled={attachmentFile == null || (attachmentFile != null && errorMessage != null)}
          onClick={() => handleUploadClick()}
          label={intl.formatMessage({ id: msgId.import })}
          sx={{ margin: '4px 4px 4px 8px' }}
        />
      )}
    </Box>
  );
}
