import React from 'react';
import ShishinBg from 'resources/assets/ShishinBg.svg';
import ShishinFb from 'resources/assets/ShishinFb.svg';
import ShishinOl from 'resources/assets/ShishinOl.svg';
import { Box, styled, SxProps } from '@mui/material';

const size = 80;

const RootDiv = styled('div')({
  width: size,
  height: size,
  backgroundImage: `url(${ShishinBg})`,
  backgroundSize: `${size}px ${size}px`,
  position: 'relative',
});

const FbSpan = styled('span')({
  width: size,
  height: size,
  backgroundImage: `url(${ShishinFb})`,
  backgroundSize: `${size}px ${size}px`,
  position: 'absolute',
  left: 0,
  top: 0,
  zIndex: 3,
});

const OlSpan = styled('span')({
  width: size,
  height: size,
  backgroundImage: `url(${ShishinOl})`,
  backgroundSize: `${size}px ${size}px`,
  position: 'absolute',
  left: 0,
  top: 0,
  zIndex: 2,
});

interface SteeringInfoPanelProps {
  sx?: SxProps;
  fb: number;
  ol: number;
}

export function SteeringInfoPanel(props: SteeringInfoPanelProps): JSX.Element {
  const { sx, fb, ol } = props;

  return (
    <Box sx={sx}>
      <RootDiv>
        {!isNaN(ol) && <OlSpan sx={{ transform: `rotate(${ol}deg)` }} />}
        {!isNaN(fb) && <FbSpan sx={{ transform: `rotate(${fb}deg)` }} />}
      </RootDiv>
    </Box>
  );
}
