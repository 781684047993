import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Sensor, Machine, Ship } from 'models/ships';
import { AppThunk } from 'app/store';
import { sleep, doFilterShips } from 'utils/misc';
import dayjs from 'dayjs';
import {
  ConfirmedSearchResultItem,
  SearchResult,
  SearchResultItem,
  SensorDataSearchConditionItem,
  SensorDataSearchConditions,
} from 'models/search';
import { ErrorResult, getErrorResult } from 'models/error';
import { searchSensorDataRequestAsync, searchSensorDataResultAsync } from 'api/maricoApiData';
import AppLogger from 'utils/AppLogger';
import { RootState } from 'app/rootReducer';
import { useSelector } from 'react-redux';
import config from 'resources/config';
import constants from 'resources/constants';

/**
 * SearchState インターフェース
 */
interface SearchState {
  /** 検索画面オープン状態 */
  open: boolean;
  /** 船舶確定状態 */
  shipConfirmed: boolean;
  /** 船舶リスト */
  ships: Ship[] | undefined;
  /** 共通船舶リスト */
  commonShips: Ship[] | undefined;
  /** 選択された機械リスト */
  checkedMachines: Machine[];
  /** フィルター済み船舶リスト */
  filteredShips: Ship[] | undefined;
  /** フィルター船舶名 */
  filterShipName: string;
  /** センサーリスト */
  sensors: Sensor[];
  /** 検索条件 */
  searchConditions: SensorDataSearchConditions;
  /** 検索可能かどうか */
  canSearch: boolean;
  /** 検索中かどうか */
  searching: boolean;
  /** 検索結果リスト */
  searchResults: SearchResult[] | undefined;
  /** 検索エラー */
  searchError: ErrorResult | undefined;
  /** 選択された検索結果 */
  selectedSearchResultItem: SearchResultItem | undefined;
  /** 確定検索結果 */
  confirmedSearchResultItem: ConfirmedSearchResultItem | undefined;
}

const initialDate = (offsetUnixtime: number) => {
  const unixTime = ((dayjs().unix().valueOf() + offsetUnixtime) / 60) * 60; // 秒切り捨て

  return dayjs.unix(unixTime).format(constants.dateFormat.iso8601);
};

/**
 * Search の初期状態
 */
const initialState: SearchState = {
  open: false,
  shipConfirmed: false,
  ships: undefined,
  commonShips: undefined,
  checkedMachines: [],
  filteredShips: undefined,
  filterShipName: '',
  sensors: [],
  searchConditions: {
    startDate: initialDate(-24 * 60 * 60),
    endDate: initialDate(0),
    enabled: false,
    screeningMinutes: 60,
    conditions: [],
    invalidStartDate: false,
    invalidEndDate: false,
  },
  canSearch: true,
  searching: false,
  searchResults: undefined,
  searchError: undefined,
  selectedSearchResultItem: undefined,
  confirmedSearchResultItem: undefined,
};

/**
 * Search の確定状態
 */
const fixedState: SearchState = {
  open: true,
  shipConfirmed: false,
  ships: undefined,
  commonShips: undefined,
  checkedMachines: [],
  filteredShips: undefined,
  filterShipName: '',
  sensors: [],
  searchConditions: {
    startDate: initialDate(-24 * 60 * 60),
    endDate: initialDate(0),
    enabled: false,
    screeningMinutes: 60,
    conditions: [],
    invalidStartDate: false,
    invalidEndDate: false,
  },
  canSearch: true,
  searching: false,
  searchResults: undefined,
  searchError: undefined,
  selectedSearchResultItem: undefined,
  confirmedSearchResultItem: undefined,
};

/**
 * 検索確定状態の保持
 * @param state Search状態
 */
const setFixedState = (state: SearchState) => {
  fixedState.shipConfirmed = state.shipConfirmed;
  fixedState.ships = state.ships?.map((ship) => {
    return {
      ...ship,
      machines: ship.machines.map((v) => {
        return {
          ...v,
          serialNumbers: v.serialNumbers.map((va) => va),
          sensorGroups: v.sensorGroups.map((va) => {
            return {
              ...va,
              sensors: va.sensors.map((val) => {
                return { ...val };
              }),
            };
          }),
        };
      }),
      propulsionSerialNumbers: ship.propulsionSerialNumbers.map((v) => v),
    };
  });
  fixedState.checkedMachines = state.checkedMachines?.map((v) => {
    return {
      ...v,
      serialNumbers: v.serialNumbers.map((va) => va),
      sensorGroups: v.sensorGroups.map((va) => {
        return {
          ...va,
          sensors: va.sensors.map((val) => {
            return { ...val };
          }),
        };
      }),
    };
  });
  fixedState.filteredShips = state.filteredShips?.map((ship) => {
    return {
      ...ship,
      machines: ship.machines.map((v) => {
        return {
          ...v,
          serialNumbers: v.serialNumbers.map((va) => va),
          sensorGroups: v.sensorGroups.map((va) => {
            return {
              ...va,
              sensors: va.sensors.map((val) => {
                return { ...val };
              }),
            };
          }),
        };
      }),
      propulsionSerialNumbers: ship.propulsionSerialNumbers.map((v) => v),
    };
  });
  fixedState.filterShipName = state.filterShipName;
  fixedState.searchConditions = {
    ...state.searchConditions,
    conditions: state.searchConditions.conditions.map((v) => {
      return { ...v };
    }),
  };
  fixedState.canSearch = state.canSearch;
  fixedState.searchResults = state.searchResults?.map((v) => {
    return {
      ...v,
      results: v.results.map((va) => {
        return { ...va };
      }),
    };
  });
  fixedState.searchError = state.searchError;
  fixedState.sensors = state.sensors?.map((v) => {
    return { ...v };
  });
  fixedState.selectedSearchResultItem = { ...state.selectedSearchResultItem } as SearchResultItem;
  fixedState.confirmedSearchResultItem = {
    ...state.confirmedSearchResultItem,
    machine: {
      ...state.confirmedSearchResultItem?.machine,
      serialNumbers: state.confirmedSearchResultItem?.machine.serialNumbers.map((v) => v),
      sensorGroups: state.confirmedSearchResultItem?.machine.sensorGroups.map((v) => {
        return {
          ...v,
          sensors: v.sensors.map((val) => {
            return { ...val };
          }),
        };
      }),
    },
  } as ConfirmedSearchResultItem;
};

/**
 * 検索確定状態の再設定
 * @param state Search状態
 */
const resetFixedState = (state: SearchState) => {
  if (fixedState.confirmedSearchResultItem !== undefined) {
    state.shipConfirmed = fixedState.shipConfirmed;
    state.ships = fixedState.ships;
    state.checkedMachines = [...fixedState.checkedMachines];
    state.filteredShips = fixedState.filteredShips;
    state.filterShipName = fixedState.filterShipName;
    state.searchConditions = { ...fixedState.searchConditions };
    state.canSearch = fixedState.canSearch;
    state.searchResults = fixedState.searchResults;
    state.searchError = fixedState.searchError;
    state.selectedSearchResultItem = fixedState.selectedSearchResultItem;
    state.sensors = [...fixedState.sensors];
    state.confirmedSearchResultItem = fixedState.confirmedSearchResultItem;
  }
};

/**
 * 検索結果をクリアする。
 * @param state Search状態
 */
const clearSearchResult = (state: SearchState) => {
  state.searchResults = undefined;
  state.selectedSearchResultItem = undefined;
  //  state.confirmedSearchResultItem = undefined;
};

/**
 * 検索条件の整合性を確認する。
 * @param state Search状態
 */
const validSearchConditions = (state: SearchState): boolean => {
  const sd = dayjs(state.searchConditions.startDate);
  const ed = dayjs(state.searchConditions.endDate);
  const invalidDate =
    state.searchConditions.invalidStartDate ||
    state.searchConditions.invalidEndDate ||
    Math.floor(ed.unix() / 60) - Math.floor(sd.unix() / 60) > 2 * 24 * 60;
  if (state.searchConditions.enabled) {
    const invalidConditions = state.searchConditions.conditions.some(
      (x) => x.invalidLowerLimit || x.invalidUpperLimit
    );

    return !invalidDate && !invalidConditions;
  } else {
    return !invalidDate;
  }
};

export const search = createSlice({
  name: 'shipStateSearch',
  initialState,
  reducers: {
    /**
     * 検索画面の開閉状態を設定する。
     */
    setOpen: (state, { payload }: PayloadAction<boolean>) => {
      state.open = payload;
      resetFixedState(state);
    },
    /**
     * 船舶確定状態を設定する。
     */
    setShipConfirmed: (state, { payload }: PayloadAction<boolean>) => {
      if (payload) {
        // 現在選択中の機械から共通のセンサーリストを作成
        let sensors: Sensor[] = [];
        const machines = state.checkedMachines;
        machines.forEach((machine) => {
          const newSensors: Sensor[] = [];
          machine.sensorGroups.forEach((sensorGroup) => {
            newSensors.push(...sensorGroup.sensors);
          });
          if (sensors.length === 0) {
            sensors = newSensors;
          } else {
            // すでに入っているセンサーと新しいセンサーリストの両方にあるものを残す
            sensors = newSensors.filter((x) => sensors.find((y) => y.sensorName === x.sensorName));
          }
        });

        state.sensors = sensors.sort((a, b) => {
          if (a.sensorName > b.sensorName) {
            return 1;
          } else if (a.sensorName < b.sensorName) {
            return -1;
          } else {
            return 0;
          }
        });
      } else {
        state.sensors = [];
      }

      state.shipConfirmed = payload;
    },
    /**
     * 船舶リストを設定する。
     */
    setShips: (state, { payload }: PayloadAction<Ship[]>) => {
      const commonShips = payload;
      const ships = commonShips.map((commonShip) => {
        return {
          shipId: commonShip.shipId,
          name: commonShip.name,
          propulsionSerialNumbers: commonShip.propulsionSerialNumbers,
          machines: commonShip.machines.map((commonMachine) => {
            return {
              machineId: commonMachine.machineId,
              name: commonMachine.name,
              serialNumbers: commonMachine.serialNumbers,
              dataFormatId: commonMachine.dataFormatId,
              sensorGroups: commonMachine.sensorGroups.filter((x) =>
                x.sensors.find(
                  (y) => y.isSearch && y.displayLowerLimit != null && y.displayUpperLimit != null
                )
              ),
              checked: false,
            };
          }),
          createdAt: commonShip.createdAt,
          updatedAt: commonShip.updatedAt,
          checked: false,
          propulsionSerialNumbersText: commonShip.propulsionSerialNumbers.join(', '),
          machineNamesText: commonShip.machines.map((x) => x.name).join(', '),
        };
      });

      ships.forEach((ship) => {
        ship.machines.forEach((machine) => {
          machine.checked = state.checkedMachines.some((x) => x.machineId === machine.machineId);
        });
        ship.checked = ship.machines.every((x) => x.checked);
      });

      state.ships = ships;
      state.commonShips = commonShips;
      state.filteredShips = doFilterShips(state.ships, state.filterShipName);
    },
    /**
     * 船舶のチェック状態を設定する。
     */
    setShipCheck: (
      state,
      { payload }: PayloadAction<{ shipId: string; machineId: number; checked: boolean }>
    ) => {
      const ships = state.ships;
      if (ships == null) {
        return;
      }

      if (payload.machineId === 0) {
        const ship = ships.find((x) => x.shipId === payload.shipId);
        if (ship != null) {
          ship.checked = payload.checked;
          ship.machines.map((y) => (y.checked = payload.checked));
        }
      } else {
        const ship = ships.find((x) => x.shipId === payload.shipId);
        if (ship != null) {
          const machine = ships
            .find((x) => x.shipId === payload.shipId)
            ?.machines.find((y) => y.machineId === payload.machineId);
          if (machine != null) {
            machine.checked = payload.checked;
            ship.checked = ship.machines.some((x) => x.checked);
          }
        }
      }

      const machines: Machine[] = [];
      ships.forEach((ship) => {
        ship.machines.forEach((machine) => {
          if (machine.checked) {
            machines.push(machine);
          }
        });
      });

      state.checkedMachines = machines;
      state.ships = ships;
      state.filteredShips = doFilterShips(state.ships, state.filterShipName);
      state.searchConditions.conditions = [];
      state.canSearch = true;
      state.shipConfirmed = false;
      clearSearchResult(state);
    },
    /**
     * フィルター船舶名を設定する。
     */
    setFilterShipName: (state, { payload }: PayloadAction<string>) => {
      state.filterShipName = payload;
      if (state.ships != null) {
        state.filteredShips = doFilterShips(state.ships, state.filterShipName);
      }
    },
    /**
     * 検索期間開始日時を設定する。
     */
    setStartDate: (state, { payload }: PayloadAction<string>) => {
      const startDate = payload;
      const now = dayjs();
      const start = dayjs(startDate);
      const end = dayjs(state.searchConditions.endDate);
      let invalidStartDate = start == null;
      let invalidEndDate = end == null;
      if (start != null && end != null) {
        if (start > end) {
          invalidStartDate = true;
          invalidEndDate = true;
        }
        if (start > now) {
          invalidStartDate = true;
        }
        if (start < end) {
          const diff = end.diff(start);
          if (diff > config.searchRangeLimit) {
            invalidStartDate = true;
            invalidEndDate = true;
          }
        }
      }

      state.searchConditions = {
        ...state.searchConditions,
        startDate: startDate,
        invalidStartDate: invalidStartDate,
        invalidEndDate: invalidEndDate,
      };
      state.canSearch = validSearchConditions(state);
      clearSearchResult(state);
    },
    /**
     * 検索期間終了日時を設定する。
     */
    setEndDate: (state, { payload }: PayloadAction<string>) => {
      const endDate = payload;
      const now = dayjs();
      const start = dayjs(state.searchConditions.startDate);
      const end = dayjs(endDate);
      let invalidStartDate = start == null;
      let invalidEndDate = end == null;
      if (start != null && end != null) {
        if (start > end) {
          invalidStartDate = true;
          invalidEndDate = true;
        }
        if (end > now) {
          invalidEndDate = true;
        }
        if (start < end) {
          const diff = end.diff(start);
          if (diff > config.searchRangeLimit) {
            invalidStartDate = true;
            invalidEndDate = true;
          }
        }
      }

      state.searchConditions = {
        ...state.searchConditions,
        endDate: endDate,
        invalidStartDate: invalidStartDate,
        invalidEndDate: invalidEndDate,
      };
      state.canSearch = validSearchConditions(state);
      clearSearchResult(state);
    },
    /**
     * 検索条件の有効無効を設定する。
     */
    setSearchConditionsEnabled: (state, { payload }: PayloadAction<boolean>) => {
      state.searchConditions.enabled = payload;
      state.canSearch = validSearchConditions(state);
      clearSearchResult(state);
    },
    /**
     * スクリーニング単位[分]を設定する。
     */
    setScreeningMinutes: (state, { payload }: PayloadAction<number>) => {
      state.searchConditions.screeningMinutes = payload;
      clearSearchResult(state);
    },
    /**
     * 検索条件を追加する。
     */
    addCondition: (state, { payload }: PayloadAction<SensorDataSearchConditionItem>) => {
      state.searchConditions.conditions.push(payload);
      state.canSearch = validSearchConditions(state);
      clearSearchResult(state);
    },
    /**
     * 検索条件を削除する。
     */
    removeCondition: (state, { payload }: PayloadAction<string>) => {
      state.searchConditions.conditions = state.searchConditions.conditions.filter(
        (x) => x.id !== payload
      );
      state.canSearch = validSearchConditions(state);
      clearSearchResult(state);
    },
    /**
     * 検索条件をすべて削除する。
     */
    clearConditions: (state) => {
      state.searchConditions.conditions = [];
      state.canSearch = validSearchConditions(state);
      clearSearchResult(state);
    },
    /**
     * 検索条件を更新する。
     */
    updateCondition: (
      state,
      action: PayloadAction<{
        id: string;
        sensorName: string;
        upperLimit: number;
        lowerLimit: number;
        upperValue: number;
        lowerValue: number;
      }>
    ) => {
      const condition = state.searchConditions.conditions.find((x) => x.id === action.payload.id);
      if (condition != null) {
        condition.sensorName = action.payload.sensorName;
        condition.upperLimit = action.payload.upperLimit;
        condition.lowerLimit = action.payload.lowerLimit;
        condition.upperValue = action.payload.upperValue;
        condition.lowerValue = action.payload.lowerValue;
        condition.invalidLowerLimit =
          condition.upperValue < condition.lowerValue ||
          condition.lowerValue < condition.lowerLimit ||
          condition.lowerValue > condition.upperLimit;
        condition.invalidUpperLimit =
          condition.upperValue < condition.lowerValue ||
          condition.upperValue < condition.lowerLimit ||
          condition.upperValue > condition.upperLimit;
        state.canSearch = validSearchConditions(state);
        clearSearchResult(state);
      }
    },
    /**
     * 検索を開始する。
     */
    startSearch: (state) => {
      state.searching = true;
    },
    /**
     * 検索結果を設定する。
     */
    setSearchResult: (state, action: PayloadAction<SearchResult[] | undefined>) => {
      if (action.payload != null) {
        state.searchResults = action.payload;
        state.searching = false;
      } else {
        clearSearchResult(state);
      }
    },
    /**
     * 検索エラーを設定する。
     */
    setSearchError: (state, action: PayloadAction<ErrorResult | undefined>) => {
      state.searchError = action.payload;
      state.searching = false;
    },
    /**
     * 検索結果を設定する。
     */
    setSelectedSearchResultItem: (state, action: PayloadAction<SearchResultItem | undefined>) => {
      state.selectedSearchResultItem = action.payload;
    },
    /**
     * 検索結果を確定する。
     */
    setConfirmedResultItem: (state, action: PayloadAction<SearchResultItem | undefined>) => {
      const searchResultItem = action.payload;
      if (searchResultItem != null && state.commonShips != null) {
        const machine = state.commonShips
          .map((x) => x.machines.find((y) => y.machineId === searchResultItem.machineId))
          .filter((x) => x != null)[0];
        if (machine != null) {
          state.confirmedSearchResultItem = {
            machine: machine,
            searchResultItem: searchResultItem,
          };
          setFixedState(state);
        }
      } else {
        state.confirmedSearchResultItem = undefined;
        fixedState.confirmedSearchResultItem = undefined;
      }
    },
    /**
     * ShipStateSearchをクリアする。
     */
    clearShipStateSearch: (state) => {
      state.open = initialState.open;
      state.shipConfirmed = initialState.shipConfirmed;
      state.ships = initialState.ships;
      state.commonShips = initialState.commonShips;
      state.checkedMachines = [];
      state.filteredShips = initialState.filteredShips;
      state.filterShipName = '';
      state.sensors = [];
      state.searchConditions = {
        startDate: initialDate(-24 * 60 * 60),
        endDate: initialDate(0),
        enabled: false,
        screeningMinutes: 60,
        conditions: [],
        invalidStartDate: false,
        invalidEndDate: false,
      };
      state.canSearch = initialState.canSearch;
      state.searching = initialState.searching;
      state.searchResults = initialState.searchResults;
      state.searchError = initialState.searchError;
      state.selectedSearchResultItem = initialState.selectedSearchResultItem;
      state.confirmedSearchResultItem = initialState.confirmedSearchResultItem;

      fixedState.open = true;
      fixedState.shipConfirmed = false;
      fixedState.ships = undefined;
      fixedState.commonShips = undefined;
      fixedState.checkedMachines = [];
      fixedState.filteredShips = undefined;
      fixedState.filterShipName = '';
      fixedState.sensors = [];
      fixedState.searchConditions = {
        startDate: initialDate(-24 * 60 * 60),
        endDate: initialDate(0),
        enabled: false,
        screeningMinutes: 60,
        conditions: [],
        invalidStartDate: false,
        invalidEndDate: false,
      };
      fixedState.canSearch = true;
      fixedState.searching = false;
      fixedState.searchResults = undefined;
      fixedState.searchError = undefined;
      fixedState.selectedSearchResultItem = undefined;
      fixedState.confirmedSearchResultItem = undefined;
    },
  },
});

export const {
  setOpen,
  setShipConfirmed,
  setShips,
  setShipCheck,
  setFilterShipName,
  setStartDate,
  setEndDate,
  setSearchConditionsEnabled,
  setScreeningMinutes,
  addCondition,
  removeCondition,
  clearConditions,
  updateCondition,
  setSearchResult,
  setSearchError,
  setSelectedSearchResultItem,
  setConfirmedResultItem,
  clearShipStateSearch,
} = search.actions;

export const searchSensorData =
  (machines: Machine[], searchConditions: SensorDataSearchConditions): AppThunk =>
  async (dispatch) => {
    dispatch(search.actions.startSearch());
    try {
      const machineIds = machines.map((x) => x.machineId);
      const searchRequest = await searchSensorDataRequestAsync(machineIds, searchConditions);
      // 5秒間隔で15分間ポーリング
      let retry = 180;
      let fastTimeRetry = 5;
      AppLogger.debug('searchRequestId:' + searchRequest.searchRequestId);
      while (retry > 0) {
        await sleep(fastTimeRetry > 0 ? 1000 : 5000);
        const searchRequestResult = await searchSensorDataResultAsync(searchRequest);
        AppLogger.debug('status:' + searchRequestResult.status + ' retry:' + retry);
        if (searchRequestResult.status === 'searching') {
          retry -= 1;
          fastTimeRetry -= 1;
        } else if (searchRequestResult.status === 'completed') {
          dispatch(search.actions.setSearchResult(searchRequestResult.searchResults));

          return;
        } else {
          throw new Error('sensor data search is failed.');
        }
      }

      throw new Error('sensor data search is timeout.');
    } catch (error) {
      dispatch(search.actions.setSearchError(getErrorResult(error)));
    }
  };

const pastDataSearchState = (state: RootState) => state.pastDataSearch;
const selectOpen = createSelector(pastDataSearchState, (x) => x.open);
const selectShipConfirmed = createSelector(pastDataSearchState, (x) => x.shipConfirmed);
const selectFilteredShips = createSelector(pastDataSearchState, (x) => x.filteredShips);
const selectFilterShipName = createSelector(pastDataSearchState, (x) => x.filterShipName);
const selectCheckedMachines = createSelector(pastDataSearchState, (x) => x.checkedMachines);
const selectSearchConditions = createSelector(pastDataSearchState, (x) => x.searchConditions);
const selectSensors = createSelector(pastDataSearchState, (x) => x.sensors);
const selectCanSearch = createSelector(pastDataSearchState, (x) => x.canSearch);
const selectSearching = createSelector(pastDataSearchState, (x) => x.searching);
const selectSearchResults = createSelector(pastDataSearchState, (x) => x.searchResults);
const selectSearchError = createSelector(pastDataSearchState, (x) => x.searchError);
const selectSelectedSearchResultItem = createSelector(
  pastDataSearchState,
  (x) => x.selectedSearchResultItem
);
const selectConfirmedSearchResultItem = createSelector(
  pastDataSearchState,
  (x) => x.confirmedSearchResultItem
);

export const usePastDataSearchOpen = () => useSelector(selectOpen);
export const usePastDataSearchShipConfirmed = () => useSelector(selectShipConfirmed);
export const usePastDataSearchFilteredShips = () => useSelector(selectFilteredShips);
export const usePastDataSearchFilterShipName = () => useSelector(selectFilterShipName);
export const usePastDataSearchCheckedMachines = () => useSelector(selectCheckedMachines);
export const usePastDataSearchConditions = () => useSelector(selectSearchConditions);
export const usePastDataSearchSensors = () => useSelector(selectSensors);
export const usePastDataCanSearch = () => useSelector(selectCanSearch);
export const usePastDataSearching = () => useSelector(selectSearching);
export const usePastDataSearchResults = () => useSelector(selectSearchResults);
export const usePastDataSearchError = () => useSelector(selectSearchError);
export const usePastDataSearchSelectedSearchResultItem = () =>
  useSelector(selectSelectedSearchResultItem);
export const usePastDataSearchConfirmedSearchResultItem = () =>
  useSelector(selectConfirmedSearchResultItem);

export default search.reducer;
