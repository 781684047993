import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'app/rootReducer';
import { MarineWeatherType } from 'models/marineWeather';
import { Ship } from 'models/ships';
import { useSelector } from 'react-redux';

interface MainAppBarState {
  /** マップモード */
  mapMode: number;
  /** 並び替え船舶リスト */
  reorderShips: Ship[] | undefined;
  /** 表示船舶IDリスト */
  displayShipIds: string[];
  /** 確認済み並び替え船舶リスト */
  confirmedReorderShips: Ship[];
  /** 気象海象情報表示タイプ */
  marineWeatherType: MarineWeatherType;
}

const initialState: MainAppBarState = {
  mapMode: 0,
  reorderShips: undefined,
  displayShipIds: [],
  confirmedReorderShips: [],
  marineWeatherType: 'none',
};

export const mainAppBarSlice = createSlice({
  name: 'mainAppBar',
  initialState,
  reducers: {
    /**
     * マップモードを設定する。
     */
    setMapMode: (state: MainAppBarState, { payload }: PayloadAction<number>) => {
      state.mapMode = payload;
    },
    /**
     * 並び替え船舶リストを設定する。
     */
    setReorderShips: (state: MainAppBarState, { payload }: PayloadAction<Ship[]>) => {
      state.reorderShips = payload.map((ship) => ship);
    },
    /**
     * 表示船舶IDリストを設定する。
     */
    setDisplayShipIds: (state: MainAppBarState, { payload }: PayloadAction<string[]>) => {
      state.displayShipIds = payload;
    },
    /**
     * 確認済み並び替え船舶リストを設定する。
     */
    setConfirmedReorderShips: (state: MainAppBarState, { payload }: PayloadAction<Ship[]>) => {
      state.confirmedReorderShips = payload;
    },
    /**
     * 気象海象情報表示タイプを設定する。
     */
    setMarineWeatherType: (
      state: MainAppBarState,
      { payload }: PayloadAction<MarineWeatherType>
    ) => {
      state.marineWeatherType = payload;
    },
    /**
     * AppBarをクリアする。
     */
    clearAppBar: (state: MainAppBarState) => {
      state.mapMode = initialState.mapMode;
      state.reorderShips = initialState.reorderShips;
      state.displayShipIds = [];
      state.confirmedReorderShips = [];
      state.marineWeatherType = initialState.marineWeatherType;
    },
  },
});

export const {
  setMapMode,
  setReorderShips,
  setDisplayShipIds,
  setConfirmedReorderShips,
  setMarineWeatherType,
  clearAppBar,
} = mainAppBarSlice.actions;

const appMarState = (state: RootState) => state.appBar;
const selectMapMode = createSelector(appMarState, (x) => x.mapMode);
const selectReorderShips = createSelector(appMarState, (x) => x.reorderShips);
const selectDisplayShipIds = createSelector(appMarState, (x) => x.displayShipIds);
const selectConfirmedReorderShips = createSelector(appMarState, (x) => x.confirmedReorderShips);
const selectMarineWeatherType = createSelector(appMarState, (x) => x.marineWeatherType);

export const useAppBarMapMode = () => useSelector(selectMapMode);
export const useAppBarReorderShips = () => useSelector(selectReorderShips);
export const useAppBarDisplayShipIds = () => useSelector(selectDisplayShipIds);
export const useAppBarConfirmedReorderShips = () => useSelector(selectConfirmedReorderShips);
export const useMarineWeatherType = () => useSelector(selectMarineWeatherType);

export default mainAppBarSlice.reducer;
