import { API, Storage } from 'aws-amplify';
import { API_NAME, zipBlobToStringAsync } from './maricoApi';
import {
  CommonSensorData,
  SensorDataList,
  SensorThinnedDataDates,
  SensorThinnedHeaderData,
} from 'models/data';
import { Machine } from 'models/ships';
import {
  exclusionShipWaveData,
  mapToCommonThinnedSensorData,
  mapToSensorData,
  reverseSensorMap,
  sensorMap400,
  sensorMap401,
  sensorMap402,
} from './maricoApiData';
import { ErrorResult, getErrorResult } from 'models/error';
import AppLogger from 'utils/AppLogger';
import dayjs from 'dayjs';

interface SensorThinnedHeaderDataResponse {
  /** 機械ID */
  machineId: number;
  dataFormatId: number;
  /** 最終データ日時 */
  lastLogdate: number;
  /** データ日時 */
  logdate: number[];

  /** 400: 針路(真方位) */
  VesCourseTrueDirC1MCOM: number;
  /** 400: 風向 */
  VesWindDirRelC1: number;
  /** 400: 風速 */
  VesWindSpdRelC1MCOM: number;
  /** 400: 船体 緯度 */
  VesLatC1MCOM: number[];
  /** 400: 船体 経度 */
  VesLonC1MCOM: number[];
  /** 400: 船速(SOG) */
  VesSpdSogC1MCOM: number[];

  /** 401: 針路(真方位) */
  VesCourseTrueDirC1GCOM: number;
  /** 401: 風向 */
  VesWindDirmagnitudeindgreeC1: number;
  /** 401: 風速 */
  VesWindSpdRelC1GCOM: number;
  /** 401: 船体 緯度 */
  VesLatC1GCOM: number;
  /** 401: 船体 経度 */
  VesLonC1GCOM: number;
  /** 401: 船速(SOG) */
  VesSpdSogC1GCOM: number;

  VesHeading: number;
  VesWindDirMagnitudMagDeg: number;
  VesWindSpd: number;
  VesLat: number;
  VesLon: number;
  VesSog: number;

  [key: string]: number | number[];
}

const thinnedSensorNames400 = [
  'VesLatC1MCOM',
  'VesLonC1MCOM',
  'VesSpdSOGC1MCOM',
  'VesCourseTrueDirC1MCOM',
  'VesWindDirRelC1',
  'VesWindSpdRelC1MCOM',
];

const thinnedSensorNames401 = [
  'VesLatC1GCOM',
  'VesLonC1GCOM',
  'VesSpdSogC1GCOM',
  'VesCourseTrueDirC1GCOM',
  'VesWindDirmagnitudeindgreeC1',
  'VesWindSpdRelC1GCOM',
];

const thinnedSensorNames402 = [
  'VesLat',
  'VesLon',
  'VesSog',
  'VesHeading',
  'VesWindDirMagnitudMagDeg',
  'VesWindSpd',
];

const thinnedShipWaveSensorMap400 = {
  vesLatC1: 'VesLatC1MCOM',
  vesLonC1: 'VesLonC1MCOM',
  vesSpdSOGC1: 'VesSpdSOGC1MCOM',
  vesCourseTrueDirC1: 'VesCourseTrueDirC1MCOM',
  vesWindDirRelC1: 'VesWindDirRelC1',
  vesWindSpdRelC1: 'VesWindSpdRelC1MCOM',
};

const thinnedShipWaveSensorMap401 = {
  vesLatC1: 'VesLatC1GCOM',
  vesLonC1: 'VesLonC1GCOM',
  vesSpdSOGC1: 'VesSpdSogC1GCOM',
  vesCourseTrueDirC1: 'VesCourseTrueDirC1GCOM',
  vesWindDirRelC1: 'VesWindDirmagnitudeindgreeC1',
  vesWindSpdRelC1: 'VesWindSpdRelC1GCOM',
};

const thinnedShipWaveSensorMap402 = {
  vesLat: 'VesLat',
  vesLon: 'VesLon',
  vesSog: 'VesSog',
  vesHeading: 'VesHeading',
  vesWindDirMagnitudMagDeg: 'VesWindDirMagnitudMagDeg',
  vesWindSpd: 'VesWindSpd',
};

/**
 * 間引きデータが存在する日付リストを取得する。
 * @param machineIds 機械IDリスト
 */
export async function getThinnedDataDatesListAsync(
  machineIds: number[]
): Promise<SensorThinnedDataDates[]> {
  try {
    const params = {
      headers: {
        'Content-Type': 'application/json',
      },
      body: {
        machineIds: machineIds,
      },
      response: true,
      timeout: 30000,
    };

    const result = await API.post(API_NAME, '/v1/thinned-data/dates', params);

    return result.data;
  } catch (error) {
    AppLogger.error('error getThinnedDataDates: ', error);
    throw getErrorResult(error);
  }
}

/**
 * CVS形式の文字列をセンサーデータに変換して返す。
 * @param machine 機械
 * @param diagnosis 診断結果
 * @param csvString CSV形式の文字列
 */
function mapToSensorHeaderData(
  machine: Machine,
  dataList: SensorThinnedHeaderDataResponse[]
): SensorThinnedHeaderData {
  const headerData: SensorThinnedHeaderData = {
    lastLogDate: 0,
    logdate: [],
    machineId: machine.machineId,
    dataFormatId: machine.dataFormatId,
    sensors: {
      vesCourseTrueDirC1: undefined,
      vesWindDirRelC1: undefined,
      vesWindSpdRelC1: undefined,
      vesLatC1: undefined,
      vesLonC1: undefined,
      vesSpdSOGC1: undefined,
      vesLat: undefined,
      vesLon: undefined,
      vesSog: undefined,
      vesHeading: undefined,
      vesWindDirMagnitudMagDeg: undefined,
      vesWindSpd: undefined,
    },
  };

  dataList.forEach((data, index) => {
    if (index === dataList.length - 1) {
      headerData.lastLogDate = dayjs(data.lastLogdate + 'Z').valueOf();
    }

    if (data.logdate.length > 0 && index !== dataList.length - 1) {
      data.logdate.push(NaN);
    }
    data.logdate.forEach((d) => headerData.logdate.push(dayjs(d + 'Z').valueOf()));

    if (machine.dataFormatId === 400) {
      thinnedSensorNames400.forEach((sensorName) => {
        const sensorGroup = machine?.sensorGroups.find((y) =>
          y.sensors.find((z) => z.sensorName === sensorName)
        );
        const sensor = sensorGroup?.sensors.find((y) => y.sensorName === sensorName);
        if (sensorGroup != null && sensor != null) {
          const reverseSensorName = reverseSensorMap[sensorName];
          if (!headerData.sensors[reverseSensorName]) {
            headerData.sensors[reverseSensorName] = {
              sensorGroup: sensorGroup,
              sensor: sensor,
              data: [],
              max: 0,
              min: 0,
              diagnosisStatus: undefined,
            };
          }

          if (
            reverseSensorName === 'vesCourseTrueDirC1' ||
            reverseSensorName === 'vesWindDirRelC1' ||
            reverseSensorName === 'vesWindSpdRelC1'
          ) {
            if (index === dataList.length - 1) {
              headerData.sensors[reverseSensorName]?.data.push(data[sensorName] as number);
            }
          } else {
            if (sensorName === 'VesSpdSOGC1MCOM') {
              sensorName = 'VesSpdSogC1MCOM';
            }
            const sensorData = data[sensorName] as number[];
            if (sensorData && sensorData.length > 0) {
              if (index !== dataList.length - 1) {
                sensorData.push(NaN);
              }
              Array.prototype.push.apply(headerData.sensors[reverseSensorName]?.data, sensorData);
            }
          }
        }
      });
    } else if (machine.dataFormatId === 401) {
      thinnedSensorNames401.forEach((sensorName) => {
        const sensorGroup = machine?.sensorGroups.find((y) =>
          y.sensors.find((z) => z.sensorName === sensorName)
        );
        const sensor = sensorGroup?.sensors.find((y) => y.sensorName === sensorName);
        if (sensorGroup != null && sensor != null) {
          const reverseSensorName = reverseSensorMap[sensorName];
          if (!headerData.sensors[reverseSensorName]) {
            headerData.sensors[reverseSensorName] = {
              sensorGroup: sensorGroup,
              sensor: sensor,
              data: [],
              max: 0,
              min: 0,
              diagnosisStatus: undefined,
            };
          }

          if (
            reverseSensorName === 'vesCourseTrueDirC1' ||
            reverseSensorName === 'vesWindDirRelC1' ||
            reverseSensorName === 'vesWindSpdRelC1'
          ) {
            if (index === dataList.length - 1) {
              headerData.sensors[reverseSensorName]?.data.push(data[sensorName] as number);
            }
          } else {
            const sensorData = data[sensorName] as number[];
            if (sensorData && sensorData.length > 0) {
              if (index !== dataList.length - 1) {
                sensorData.push(NaN);
              }
              Array.prototype.push.apply(headerData.sensors[reverseSensorName]?.data, sensorData);
            }
          }
        }
      });
    } else {
      thinnedSensorNames402.forEach((sensorName) => {
        const sensorGroup = machine?.sensorGroups.find((y) =>
          y.sensors.find((z) => z.sensorName === sensorName)
        );
        const sensor = sensorGroup?.sensors.find((y) => y.sensorName === sensorName);
        if (sensorGroup != null && sensor != null) {
          const reverseSensorName = reverseSensorMap[sensorName];
          if (!headerData.sensors[reverseSensorName]) {
            headerData.sensors[reverseSensorName] = {
              sensorGroup: sensorGroup,
              sensor: sensor,
              data: [],
              max: 0,
              min: 0,
              diagnosisStatus: undefined,
            };
          }

          if (
            reverseSensorName === 'vesHeading' ||
            reverseSensorName === 'vesWindDirMagnitudMagDeg' ||
            reverseSensorName === 'vesWindSpd'
          ) {
            if (index === dataList.length - 1) {
              headerData.sensors[reverseSensorName]?.data.push(data[sensorName] as number);
            }
          } else {
            const sensorData = data[sensorName] as number[];
            if (sensorData && sensorData.length > 0) {
              if (index !== dataList.length - 1) {
                sensorData.push(NaN);
              }
              Array.prototype.push.apply(headerData.sensors[reverseSensorName]?.data, sensorData);
            }
          }
        }
      });
    }
  });

  if (machine.dataFormatId !== 402) {
    if (headerData.sensors['vesLonC1'] != null && headerData.sensors['vesLatC1'] != null) {
      exclusionShipWaveData(headerData.sensors['vesLonC1'].data, headerData.sensors['vesLatC1'].data);
    }
  } else {
    if (headerData.sensors['vesLon'] != null && headerData.sensors['vesLat'] != null) {
      exclusionShipWaveData(headerData.sensors['vesLon'].data, headerData.sensors['vesLat'].data);
    }
  }

  return headerData;
}

/**
 * 指定した間引きヘッダーデータを取得する。
 * @param machineId 機械ID
 * @param dates 取得する日付リスト
 */
export async function getThinnedHeaderDataAsync(
  machine: Machine,
  dates: string[]
): Promise<SensorThinnedHeaderData> {
  try {
    const results = await Promise.all(
      dates.map((date) => {
        const params = {
          headers: {
            'Content-Type': 'application/octet-stream',
          },
          body: {
            machineId: machine.machineId,
            date: date,
          },
          response: true,
          timeout: 30000,
        };

        return API.post(API_NAME, '/v1/thinned-data/header', params);
      })
    );
    const blobList = await Promise.all(
      results.map((x) => {
        return base64DecodeAsBlob(x.data, 'application/octet-stream');
      })
    );
    const tmpDataList = await Promise.all(
      blobList.map((x) => {
        return zipBlobToStringAsync('writestr.json', x);
      })
    );

    const dataList: any[] = await Promise.all(
      tmpDataList.map((x) => {
        return JSON.parse(x);
      })
    );

    const resultDataList = dataList
      .sort((first, second) => {
        const firstData: SensorThinnedHeaderDataResponse = first;
        const secondData: SensorThinnedHeaderDataResponse = second;

        return dayjs(firstData.lastLogdate).valueOf() - dayjs(secondData.lastLogdate).valueOf();
      });
    // .map((x) => x);

    return mapToSensorHeaderData(machine, resultDataList);
  } catch (error) {
    AppLogger.error('error getThinnedHeaderData: ', error);
    throw getErrorResult(error);
  }
}

/**
 * 共通間引きセンサーデータリストを取得する。
 * メインの機械IDのみ共通センサーデータが含まれる。
 * メイン以外の機械は地図表示用データのみ含まれる。
 * @param machines 機械リスト
 * @param mainMachineId メインの機械ID
 * @param date 日付
 */
export async function getCommonSensorThinnedDataListAsync(
  machines: Machine[],
  mainMachineId: number,
  date: string
): Promise<CommonSensorData[]> {
  try {
    let isAuthError = false;
    let isInternalError = false;
    const results = await Promise.all(
      machines.map(async (machine) => {
        let sensorNames: string[] | null = null;
        if (machine.machineId === mainMachineId) {
          if (machine.dataFormatId === 400) {
            sensorNames = Object.values(sensorMap400)?.filter((x) => x != null) as string[];
          } else if (machine.dataFormatId === 401) {
            sensorNames = Object.values(sensorMap401)?.filter((x) => x != null) as string[];
          } else if (machine.dataFormatId === 402) {
            sensorNames = Object.values(sensorMap402)?.filter((x) => x != null) as string[];
          }
        } else {
          if (machine.dataFormatId === 400) {
            sensorNames = Object.values(thinnedShipWaveSensorMap400).filter((x) => x != null);
          } else if (machine.dataFormatId === 401) {
            sensorNames = Object.values(thinnedShipWaveSensorMap401)?.filter((x) => x != null);
          } else if (machine.dataFormatId === 402) {
            sensorNames = Object.values(thinnedShipWaveSensorMap402)?.filter((x) => x != null);
          }
        }

        if (sensorNames == null) {
          AppLogger.error('error getCommonSensorThinnedData: unknown dataFormatId');
          throw getErrorResult(null);
        }

        // アクセス可能なセンサー名でフィルター
        const accessableSensorNames: string[] = [];
        machine.sensorGroups.forEach((sensorGroup) => {
          Array.prototype.push.apply(
            accessableSensorNames,
            sensorGroup.sensors.map((x) => x.sensorName)
          );
        });
        const filteredSensorNames = sensorNames.filter((x) => accessableSensorNames.includes(x));
        if (filteredSensorNames.length === 0) {
          AppLogger.error('error getCommonSensorData: not found accessable sensor.');
          throw getErrorResult(null);
        }

        const params = {
          headers: {
            'Content-Type': 'application/json',
          },
          body: {
            machineId: machine.machineId,
            sensorNames: filteredSensorNames,
            date: date,
          },
          response: true,
          timeout: 30000,
        };

        try {
          const result = await API.post(API_NAME, '/v1/thinned-data', params);
          const { key, level, identityId, fileName } = result.data;
          AppLogger.debug(
            'getCommonSensorThinnedData key:' + key + ', ' + new Date().toLocaleTimeString()
          );
          const data = (await Storage.get(key, {
            level,
            identityId,
            download: true,
          })) as { Body: Blob };
          const csv = await zipBlobToStringAsync(fileName, data.Body);

          return mapToCommonThinnedSensorData(machine, sensorNames, [csv], 1000, date);
        } catch (error) {
          const errorResult = getErrorResult(error);
          if (errorResult.status === 401) {
            isAuthError = true;
          } else if (errorResult.status === 500) {
            isInternalError = true;
          }

          return undefined;
        }
      })
    );

    const data = results.filter((x) => x) as CommonSensorData[];
    if (data.length === 0) {
      if (isAuthError) {
        const errorResult: ErrorResult = {
          status: 401,
          response: {
            errorDescription: 'Auth error.',
            errorId: 'ACCOUNT_AUTH_ERROR',
            errorField: '',
          },
        };
        throw errorResult;
      } else if (isInternalError) {
        const errorResult: ErrorResult = {
          status: 500,
          response: {
            errorDescription: 'Internal server error.',
            errorId: 'INTERNAL_SERVER_ERROR',
            errorField: '',
          },
        };
        throw errorResult;
      } else {
        throw new Error('no data.');
      }
    }

    return data;
  } catch (error) {
    AppLogger.error('error getCommonSensorThinnedData: ', error);
    throw getErrorResult(error);
  }
}

/**
 * 間引きセンサーデータを取得する。
 * @param machine 機械
 * @param sensorNames センサー名リスト
 * @param date 日付
 */
export async function getSensorThinnedDataAsync(
  machine: Machine,
  sensorNames: string[],
  date: string
): Promise<SensorDataList> {
  try {
    const params = {
      headers: {
        'Content-Type': 'application/json',
      },
      body: {
        machineId: machine.machineId,
        sensorNames: sensorNames,
        date: date,
      },
      response: true,
      timeout: 30000,
    };
    const result = await API.post(API_NAME, '/v1/thinned-data', params);

    const { key, level, identityId, fileName } = result.data;
    const data = (await Storage.get(key, {
      level,
      identityId,
      download: true,
    })) as { Body: Blob };
    const csv = await zipBlobToStringAsync(fileName, data.Body);

    return mapToSensorData(machine, undefined, csv, 1000, date);
  } catch (error) {
    AppLogger.error('error getSensorThinnedData: ', error);
    throw getErrorResult(error);
  }
}

export function base64DecodeAsBlob(text: string, type = 'text/plain;charset=UTF-8') {
  return fetch(`data:${type};base64,` + text).then((response) => response.blob());
}
