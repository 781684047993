import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getMarineWeatherAreaDataListAsync } from 'api/maricoApiMarineWeather';
import { getShipsAsync } from 'api/maricoApiShips';
import { AppThunk } from 'app/store';
import { ErrorResult, getErrorResult } from 'models/error';
import { MarineAriaData } from 'models/marineWeather';
import { Ship } from 'models/ships';
import { useSelector } from 'react-redux';
import { RootState } from './rootReducer';

/**
 * Common インターフェース
 */
interface CommonState {
  /** 船舶一覧 */
  ships: Ship[] | undefined;
  /** 海上エリアデータマトリクス */
  marineAriaDataMatrix: MarineAriaData[][] | undefined;
  /** エラー情報 */
  fetchError: ErrorResult | undefined;
  /** ビジー状態 */
  busyProgress: boolean;
}

/**
 * Common の初期状態
 */
const initialState: CommonState = {
  ships: undefined,
  marineAriaDataMatrix: undefined,
  fetchError: undefined,
  busyProgress: false,
};

export const common = createSlice({
  name: 'common',
  initialState,
  reducers: {
    /**
     * 船舶情報リストを設定する。
     */
    setShips: (state: CommonState, { payload }: PayloadAction<Ship[]>) => {
      state.ships = payload;
    },
    /**
     * 海上エリアデータマトリクスを設定する。
     */
    setMarineAreaDataMatrix: (
      state: CommonState,
      { payload }: PayloadAction<MarineAriaData[][]>
    ) => {
      state.marineAriaDataMatrix = payload;
    },
    /**
     * エラー情報を設定する。
     */
    setFetchError: (state: CommonState, { payload }: PayloadAction<ErrorResult | undefined>) => {
      state.fetchError = payload;
    },
    setBusyProgress: (state: CommonState, { payload }: PayloadAction<boolean>) => {
      state.busyProgress = payload;
    },
    clearCommon: (state: CommonState) => {
      state.ships = initialState.ships;
      state.fetchError = initialState.fetchError;
      state.busyProgress = initialState.busyProgress;
    },
  },
});

export const { setFetchError, setBusyProgress, clearCommon } = common.actions;

/**
 * 船舶リストを取得する。
 */
export const fetchCommonShips = (): AppThunk => async (dispatch) => {
  try {
    const ships = await getShipsAsync();
    dispatch(common.actions.setShips(ships));
  } catch (error) {
    dispatch(common.actions.setFetchError(getErrorResult(error)));
  }
};

/**
 * 海上エリアデータリストを取得する。
 */
export const fetchMarineAreaDataList = (): AppThunk => async (dispatch) => {
  try {
    const marineAreaDataList = await getMarineWeatherAreaDataListAsync();
    dispatch(common.actions.setMarineAreaDataMatrix(marineAreaDataList));
  } catch (error) {
    dispatch(common.actions.setFetchError(getErrorResult(error)));
  }
};

const commonState = (state: RootState) => state.common;
const selectShips = createSelector(commonState, (x) => x.ships);
const selectMarineAriaDataMatrix = createSelector(commonState, (x) => x.marineAriaDataMatrix);
const selectFetchError = createSelector(commonState, (x) => x.fetchError);
const selectBusyProgress = createSelector(commonState, (x) => x.busyProgress);

export const useCommonShips = () => useSelector(selectShips);
export const useCommonMarineAriaDataMatrix = () => useSelector(selectMarineAriaDataMatrix);
export const useCommonFetchError = () => useSelector(selectFetchError);
export const useCommonBusyProgress = () => useSelector(selectBusyProgress);

export default common.reducer;
