/**
 * 定数の定義
 */
const constants = {
  dateFormat: {
    iso8601: 'YYYY-MM-DDTHH:mm:ssZ',
    iso8601WithSSS: 'YYYY-MM-DDTHH:mm:ss.SSSZ',
    iso8601WithoutSeconds: 'YYYY-MM-DDTHH:mm:00Z',
    iso8601WithoutSecondsEndDate: 'YYYY-MM-DDT23:59:00Z',
    iso8601WithoutHours: 'YYYY-MM-DDT00:00:00Z',
    YYYYMMDD: 'YYYY/MM/DD',
    yyyyMMdd: 'yyyy/MM/dd',
    YYYYMMDDHHmm: 'YYYY/MM/DD HH:mm',
    yyyyMMddHHmm: 'yyyy/MM/dd HH:mm',
    YYYYMMDDHHmmss: 'YYYY/MM/DD HH:mm:ss',
    yyyyMMddHHmmss: 'yyyy/MM/dd HH:mm:ss',
    yyyyMMddHHmmssSSS: 'yyyy/MM/dd HH:mm:ss.SSS',
    YYYYMMDDHHmmssSSS: 'YYYY/MM/DD HH:mm:ss.SSS',
    csvTitleyyyyMMddHHmmss: 'YYYYMMDDHHmmss',
  },
  labelPrefix: {
    mimic: 'Mimic',
    trend: 'Trend',
  },
  zIndex: {
    active: 1,
    hidden: 0,
    splitter: 12,
    scrollbarThumb: 5,
    popper: 2000,
  },
  map: {
    defaultLatLng: { lat: 34.6710587, lng: 135.2045417 },
    defaultZoom: 7,
  },
  dataFormatIds: [400, 401, 402],
  dataGrid: {
    dateFilterOperations: ['<', '>', '<=', '>=', 'between'],
  },
  diagnosis: {
    operationJudgementTime: 30,
  },
  marineWeather: {
    legend: {
      windSpeedLabels: ['30', '27', '24', '21', '18', '15', '12', '9', '6', '3', '0'],
      sigHeihgtLabels: ['10', '9', '8', '7', '6', '5', '4', '3', '2', '1', '0'],
      swellHeightLabels: ['10', '9', '8', '7', '6', '5', '4', '3', '2', '1', '0'],
      swellPeriodLabels: ['20', '18', '16', '14', '12', '10', '8', '6', '4', '2', '0'],
    },
  },
  adminSettings: {
    accountGroup: {
      logo: {
        supportWidth: 635,
        supportHeight: 170,
      },
    },
  },
  invalid: {
    text: '----',
    value: '--.--',
  },
  analysis: {
    fuelConsumption: {
      conditions: {
        vesSpdSOG: {
          lowerValue: 7,
          upperValue: 20,
        },
        megSpd: {
          lowerValue: 350,
          upperValue: 450,
        }
      }
    }
  }
};

export default constants;
