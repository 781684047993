import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { CssBaseline, ThemeProvider } from '@mui/material';
import msgId, { locale } from 'resources/intl';
import awsconfig from '../aws-exports';
import { theme } from 'resources/theme';
import { checkAccounts, useAccount, useIsAuthCheckCompleted } from 'features/login/accountSlice';
import { Main } from 'features/main/Main';
import { Login } from 'features/login/Login';
import { MapContainer } from 'features/contents/analysis/map/MapContainer';
// eslint-disable-next-line import/no-named-as-default
import { Amplify } from 'aws-amplify';

Amplify.configure(awsconfig);

export function App(): JSX.Element {
  const intl = useIntl();
  const dispatch = useDispatch();

  document.documentElement.lang = locale;
  document.title = intl.formatMessage({ id: msgId.appName });

  const isAuthCheckCompleted = useIsAuthCheckCompleted();
  const account = useAccount();

  useEffect(() => {
    if (!account) {
      dispatch(checkAccounts());
    }
  }, [dispatch, account]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {/* ログインのAccountPasswordChangeDialogなどでMainAppBarのuseNavigateがRouter内で無いとエラーになる */}
      {isAuthCheckCompleted && !account && (
        <BrowserRouter>
          <Routes>
            <Route path="/*" element={<Login />} />
          </Routes>
        </BrowserRouter>
      )}
      {isAuthCheckCompleted && account && (
        <BrowserRouter>
          <Routes>
            <Route path="/map/:shipName/:lat/:lng/:deg/:date" element={<MapContainer />}></Route>
            <Route path="/*" element={<Main />} />
          </Routes>
        </BrowserRouter>
      )}
    </ThemeProvider>
  );
}
