import React, { ReactNode } from 'react';
import colors from 'resources/colors';
import { Box } from '@mui/material';
import { Scrollbars } from 'react-custom-scrollbars-2';
import constants from 'resources/constants';

interface CustomScrollBarsProps {
  children: ReactNode;
  autoHide?: boolean;
  width?: number | string;
  height?: number | string;
  autoHeight?: boolean;
  autoHeightMax?: number | string;
  thumbColor: string;
  trackWidth: number;
}

export function CustomScrollBars(props: CustomScrollBarsProps): JSX.Element {
  const { children, autoHide, width, height, autoHeight, autoHeightMax, thumbColor, trackWidth } =
    props;

  function renderTrackVertical({ ...props }) {
    const { style, ...restProps } = props;
    const customStyle = {
      ...style,
      top: '2px',
      bottom: '2px',
      right: `${trackWidth / 3}px`,
      borderRadius: '3px',
      width: trackWidth,
    };

    // eslint-disable-next-line react/jsx-props-no-spreading
    return <div {...restProps} style={customStyle} />;
  }

  function renderThumbVertical() {
    return (
      <Box
        sx={{
          position: 'relative',
          display: 'block',
          width: '100%',
          cursor: 'pointer',
          borderRadius: 'inherit',
          backgroundColor: thumbColor,
          zIndex: constants.zIndex.scrollbarThumb,
        }}
      />
    );
  }

  function renderThumbHorizontal() {
    return (
      <Box
        sx={{
          position: 'relative',
          display: 'block',
          height: '100%',
          cursor: 'pointer',
          borderRadius: 'inherit',
          backgroundColor: thumbColor,
          zIndex: constants.zIndex.scrollbarThumb,
        }}
      />
    );
  }

  return (
    <Scrollbars
      renderThumbVertical={renderThumbVertical}
      renderThumbHorizontal={renderThumbHorizontal}
      renderTrackVertical={renderTrackVertical}
      autoHide={autoHide}
      autoHeight={autoHeight}
      autoHeightMax={autoHeightMax}
      style={{ width: width, height: height }}
    >
      {children}
    </Scrollbars>
  );
}

CustomScrollBars.defaultProps = {
  children: null,
  className: null,
  autoHide: true,
  width: '100%',
  height: '100%',
  autoHeight: false,
  autoHeightMax: 0,
  thumbColor: colors.scrollBar.thumb.light,
  trackWidth: 6,
};
