import React from 'react';
import { Button, styled, SxProps } from '@mui/material';
import { theme } from 'resources/theme';
import dimens from 'resources/dimens';

const StyledButton = styled(Button)({
  width: dimens.button.width,
  height: dimens.button.height,
  maxHeight: dimens.button.height,
  minWidth: dimens.button.minWidth,
  borderRadius: dimens.button.borderRadius,
});

interface NormalButtonProps {
  label: string;
  sx?: SxProps;
  color?: 'primary' | 'secondary' | 'success' | 'error';
  startIcon?: React.ReactNode;
  disabled?: boolean;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

export function NormalButton(props: NormalButtonProps): JSX.Element {
  const { label, sx, color, startIcon, disabled, onClick } = props;

  return (
    <StyledButton
      variant="contained"
      disableElevation
      color={color}
      sx={{
        marginLeft: startIcon ? theme.spacing(0.5) : 0,
        ...sx,
      }}
      startIcon={startIcon}
      disabled={disabled}
      onClick={onClick}
    >
      {label}
    </StyledButton>
  );
}
