import React, { useCallback, useEffect, useRef, useState } from 'react';
import { GoogleMap, useLoadScript, GroundOverlay } from '@react-google-maps/api';
import config from 'resources/config';
import { useDispatch } from 'react-redux';
import { AllShipWavePath } from './AllShipWavePath';
import { CommonSensorData, SensorThinnedHeaderData } from 'models/data';
import { Ship } from 'models/ships';
import colors from 'resources/colors';
import { setCenter, usePastDataMapCenter, usePastDataMapReset } from './mapSlice';
import { Box, Button, ButtonProps, styled } from '@mui/material';
import { MyLocation } from '@mui/icons-material';
import { ShipWavePath } from './ShipWavePath';
import { ShipMarker } from './ShipMarker';
import { useMarineWeatherType } from 'features/appBar/mainAppBarSlice';
import { usePastDataTimeSliderCurrentIndex } from '../timeSlider/timeSliderSlice';
import dayjs from 'dayjs';
import { MarineWeatherLegend } from './MarineWeatherLegend';
import constants from 'resources/constants';
import { MarineWeatherPopupLabel } from './MarineWeatherPopupLabel';
import {
  generateMarkerDataList,
  GoogleMapState,
  MarineWeatherMarkerData,
} from 'models/marineWeather';
import { useCommonMarineAriaDataMatrix } from 'app/commonSlice';
import { useIntl } from 'react-intl';
import msgId from 'resources/intl';
import {
  fetchMarineWeatherBundle,
  usePastDataMarineWeatherBundles,
  usePastDataShipWaveData,
} from '../pastDataSlice';
import { MarineWeatherWindMarker } from './MarineWeatherWindMarker';
import AppLogger from 'utils/AppLogger';
import { MarineWeatherSwellMarker } from './MarineWeatherSwellMarker';
import { FocusShipMarker } from './FocusShipMarker';
import dimens from 'resources/dimens';

const RootDiv = styled('div')({
  height: '100%',
});

const StyledMapButton = styled(Button)({
  minWidth: 40,
  width: 40,
  maxWidth: 40,
  height: 40,
  padding: 0,
  marginLeft: 10,
  marginTop: -150,
  background: colors.white,
  '&:hover': {
    background: colors.listItem.hover,
  },
});

const MapButton = React.forwardRef(function MapButton(
  props: ButtonProps,
  ref: React.Ref<HTMLButtonElement>
) {
  const { sx, children, onClick } = props;

  return (
    <StyledMapButton
      ref={ref}
      onClick={onClick}
      sx={{
        ...sx,
      }}
      variant="contained"
      disableElevation
      {...props}
    >
      {children}
    </StyledMapButton>
  );
});

const options: google.maps.MapOptions = {
  disableDefaultUI: true,
  scaleControl: true,
  scaleControlOptions: {
    style: 0, // google.maps.ScaleControlStyle.DEFAULT,
  },
  fullscreenControl: true,
  fullscreenControlOptions: {
    position: 5, // google.maps.ControlPosition.LEFT_TOP,
  },
  zoomControl: true,
  zoomControlOptions: {
    position: 4, // google.maps.ControlPosition.LEFT_CENTER,
  },
};

const mapContainerStyle = {
  height: '100%',
  width: '100%',
};

interface MapContainerProps {
  width: number;
  defaultCenter: { lat: number; lng: number };
  defaultZoom: number;
  focusShip: Ship | undefined;
  machineIdtoShipName: { [key: string]: string };
  commonSensorData: CommonSensorData | undefined;
  sensorThinnedHeaderDataList: SensorThinnedHeaderData[] | undefined;
  isThinned: boolean;
  onCenterClick?: () => void;
}

export function MapContainer(props: MapContainerProps): JSX.Element {
  const {
    width,
    defaultCenter,
    defaultZoom,
    focusShip,
    machineIdtoShipName,
    commonSensorData,
    sensorThinnedHeaderDataList,
    isThinned,
    onCenterClick,
  } = props;
  const dispatch = useDispatch();
  const intl = useIntl();
  const center = usePastDataMapCenter();
  const shipWaveData = usePastDataShipWaveData();
  const marineAriaDataMatrix = useCommonMarineAriaDataMatrix();
  const marineWeatherType = useMarineWeatherType();
  const marineWeatherBundles = usePastDataMarineWeatherBundles();
  const timeSliderCurrentIndex = usePastDataTimeSliderCurrentIndex();
  const reset = usePastDataMapReset();
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: config.googleMapKey,
  });
  const [googleMap, setGoogleMap] = useState<google.maps.Map | undefined>(undefined);
  const [mapState, setMapState] = useState<GoogleMapState | undefined>(undefined);
  const [focusMarkerData, setFocusMarkerData] = useState<MarineWeatherMarkerData | undefined>(
    undefined
  );
  const buttonRef = useRef<HTMLButtonElement>(null);
  const legendRef = useRef<HTMLDivElement>(null);

  let overlayBounds: google.maps.LatLngBounds | undefined = undefined;

  let date = null;
  let hour = null;
  let currentKey: string | null = null;
  if (commonSensorData != null) {
    const d = dayjs(
      timeSliderCurrentIndex * commonSensorData.samplePerMilliSeconds + commonSensorData.logdate[0]
    );
    const dateAndTime = d.utc().format('YYYY-MM-DDTHH:00:00Z').split('T');
    date = dateAndTime[0];
    hour = parseInt(dateAndTime[1].substring(0, 2), 10);
    currentKey = date + '_' + hour;
  } else if (sensorThinnedHeaderDataList != null && sensorThinnedHeaderDataList.length > 0) {
    const lastHeaderData = sensorThinnedHeaderDataList[sensorThinnedHeaderDataList.length - 1];
    const d = dayjs(lastHeaderData.lastLogDate);
    const dateAndTime = d.utc().format('YYYY-MM-DDTHH:00:00Z').split('T');
    const date = dateAndTime[0];
    const hour = parseInt(dateAndTime[1].substring(0, 2), 10);
    currentKey = date + '_' + hour;
  }

  // AppLogger.debug('currentKey=' + currentKey);

  const updateMapState = (googleMap: google.maps.Map) => {
    if (googleMap != null) {
      const bounds = googleMap.getBounds();
      const zoom = googleMap.getZoom();
      if (bounds != null && zoom != null) {
        if (mapState?.bounds != bounds || mapState?.zoom != zoom) {
          setMapState({ bounds: bounds, zoom: zoom });
        }
      }
    }
  };

  const onMapLoad = useCallback((map) => {
    setGoogleMap(map);
    if (map != null) {
      map.controls[google.maps.ControlPosition.LEFT_CENTER].push(buttonRef.current);
      const bounds = map.getBounds();
      const zoom = map.getZoom();
      if (bounds != null && zoom != null) {
        setMapState({ bounds: bounds, zoom: zoom });
      }
      if (legendRef.current) {
        map.controls[google.maps.ControlPosition.RIGHT_BOTTOM].clear();
        map.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(legendRef.current);
      }
    }
  }, []);

  const onMapIdle = () => {
    if (googleMap != null) {
      updateMapState(googleMap);
    }
  };

  useEffect(() => {
    if (center != null) {
      if ((isNaN(center.lat) || center.lat < 1) && (isNaN(center.lng) || center.lng < 1)) {
        googleMap?.setCenter({ lat: 0, lng: 0 });
        googleMap?.setZoom(1);
      } else {
        googleMap?.setCenter(center);
        const zoom = googleMap?.getZoom();
        if (zoom === undefined) {
          googleMap?.setZoom(7);
        } else if (zoom <= 1) {
          googleMap?.setZoom(7);
        }
      }
    }
    dispatch(setCenter(undefined));
  }, [dispatch, googleMap, center]);

  useEffect(() => {
    if (commonSensorData != null) {
      const hourInTime = 60 * 60 * 1000;
      const start = dayjs(Math.floor(commonSensorData.logdate[0] / hourInTime) * hourInTime);
      const end = dayjs(
        Math.floor(commonSensorData.logdate[commonSensorData.logdate.length - 1] / hourInTime) *
          hourInTime
      );

      const keys = [];
      for (let d = start; d <= end; d = d.add(1, 'hour')) {
        const dateAndTime = d.utc().format('YYYY-MM-DDTHH:00:00Z').split('T');
        const date = dateAndTime[0];
        const hour = parseInt(dateAndTime[1].substring(0, 2), 10);
        const key = date + '_' + hour;
        keys.push(key);
      }

      AppLogger.debug('call fetchMarineWeatherDataAndColorMap 1 keys=' + keys);
      dispatch(fetchMarineWeatherBundle(keys));
    }
  }, [dispatch, commonSensorData]);

  useEffect(() => {
    if (sensorThinnedHeaderDataList != null && sensorThinnedHeaderDataList.length > 0) {
      const lastHeaderData = sensorThinnedHeaderDataList[sensorThinnedHeaderDataList.length - 1];
      const d = dayjs(lastHeaderData.lastLogDate);
      const dateAndTime = d.utc().format('YYYY-MM-DDTHH:00:00Z').split('T');
      const date = dateAndTime[0];
      const hour = parseInt(dateAndTime[1].substring(0, 2), 10);
      const key = date + '_' + hour;

      if (marineWeatherBundles[key] == null) {
        AppLogger.debug('call fetchMarineWeatherDataAndColorMap 2 key=' + key);
        dispatch(fetchMarineWeatherBundle([key]));
      }
    }
  }, [dispatch, marineWeatherBundles, sensorThinnedHeaderDataList]);

  // オーバーレイする画像の右上と左下の点
  if (!isLoaded) {
    return <React.Fragment />;
  } else {
    // オーバーレイする画像の右上と左下の点
    overlayBounds = new google.maps.LatLngBounds(
      {
        lat: config.marineWeatherOverlayBounds.left - 0.0125,
        lng: config.marineWeatherOverlayBounds.top - 0.0125,
      },
      {
        lat: config.marineWeatherOverlayBounds.right - 0.0375,
        lng: config.marineWeatherOverlayBounds.bottom - 0.0375,
      }
    );
  }

  if (loadError) {
    return <div>Map cannot be loaded right now, sorry.</div>;
  }

  if (!isLoaded) {
    return <React.Fragment />;
  }

  const handleHover = (markerData: MarineWeatherMarkerData) => {
    setFocusMarkerData(markerData);
  };

  const handleUnhover = () => {
    setFocusMarkerData(undefined);
  };

  return (
    <RootDiv sx={{ width }}>
      <GoogleMap
        id="map"
        options={options}
        mapContainerStyle={mapContainerStyle}
        zoom={defaultZoom}
        center={defaultCenter}
        onLoad={onMapLoad}
        onIdle={onMapIdle}
      >
        {!reset &&
          Object.keys(marineWeatherBundles).map((key) => {
            if (key === currentKey) {
              const marineWeatherBundle = marineWeatherBundles[key];
              if (marineWeatherBundle != null) {
                return (
                  <React.Fragment key="weather_container">
                    {/** 風向風速マーカー */}
                    {marineWeatherType === 'wind_dir_wind_speed' &&
                      marineAriaDataMatrix != null &&
                      mapState != null && (
                        <MarineWeatherWindMarker
                          key={'past_wind_marker_' + key}
                          markerDataList={generateMarkerDataList(
                            mapState,
                            marineAriaDataMatrix,
                            marineWeatherBundle.weathers
                          )}
                          zoom={mapState.zoom}
                          onHover={handleHover}
                          onUnhover={handleUnhover}
                        />
                      )}
                    {/** 波の高さ */}
                    {marineWeatherType === 'sig_height' && overlayBounds != null && (
                      <GroundOverlay
                        key={'past_sig_height_overlay_' + key}
                        url={marineWeatherBundle.sigHeihtM}
                        bounds={overlayBounds}
                        opacity={0.8}
                      />
                    )}
                    {/** うねり高さマーカー */}
                    {marineWeatherType === 'swell_height_swell_dir' &&
                      marineAriaDataMatrix != null &&
                      mapState != null && (
                        <MarineWeatherSwellMarker
                          key={'past_swell_height_swell_dir_marker_' + key}
                          markerDataList={generateMarkerDataList(
                            mapState,
                            marineAriaDataMatrix,
                            marineWeatherBundle.weathers
                          )}
                          zoom={mapState.zoom}
                          onHover={handleHover}
                          onUnhover={handleUnhover}
                        />
                      )}
                    {/** うねり向きオーバーレイ */}
                    {marineWeatherType === 'swell_height_swell_dir' && overlayBounds != null && (
                      <GroundOverlay
                        key={'past_swell_height_swell_dir_overlay_' + key}
                        url={marineWeatherBundle.swellHeightM}
                        bounds={overlayBounds}
                        opacity={0.8}
                      />
                    )}
                    {/** うねり周期マーカー */}
                    {marineWeatherType === 'swell_period_swell_dir' &&
                      marineAriaDataMatrix != null &&
                      mapState != null && (
                        <MarineWeatherSwellMarker
                          key={'past_swell_period_swell_dir_marker_' + key}
                          markerDataList={generateMarkerDataList(
                            mapState,
                            marineAriaDataMatrix,
                            marineWeatherBundle.weathers
                          )}
                          zoom={mapState.zoom}
                          onHover={handleHover}
                          onUnhover={handleUnhover}
                        />
                      )}
                    {/** うねり向きオーバーレイ */}
                    {marineWeatherType === 'swell_period_swell_dir' && overlayBounds != null && (
                      <GroundOverlay
                        key={'past_swell_period_swell_dir_overlay_' + key}
                        url={marineWeatherBundle.swellPeriodSecs}
                        bounds={overlayBounds}
                        opacity={0.8}
                      />
                    )}
                  </React.Fragment>
                );
              }

              return <React.Fragment key="empty_fragment" />;
            }
          })}

        <AllShipWavePath key="all_wave_path" shipWaveData={shipWaveData} isThinned={isThinned} />
        <ShipWavePath
          key="wave_path"
          commonSensorData={commonSensorData}
          sensorThinnedHeaderDataList={sensorThinnedHeaderDataList}
          isThinned={isThinned}
        />

        {!reset && !isThinned && commonSensorData && (
          <FocusShipMarker
            machineIdtoShipName={machineIdtoShipName}
            commonSensorData={commonSensorData}
            sensorThinnedHeaderData={undefined}
            isThinned={isThinned}
          />
        )}
        {!reset &&
          isThinned &&
          sensorThinnedHeaderDataList?.map((sensorThinnedHeaderData) => {
            const isFocus =
              sensorThinnedHeaderDataList.length === 1
                ? true
                : focusShip
                ? focusShip.machines.some((x) => x.machineId === sensorThinnedHeaderData.machineId)
                : false;
            if (isFocus) {
              return (
                <FocusShipMarker
                  key={sensorThinnedHeaderData.machineId}
                  machineIdtoShipName={machineIdtoShipName}
                  commonSensorData={commonSensorData}
                  sensorThinnedHeaderData={sensorThinnedHeaderData}
                  isThinned={isThinned}
                />
              );
            } else {
              return (
                <ShipMarker
                  key={sensorThinnedHeaderData.machineId}
                  machineIdtoShipName={machineIdtoShipName}
                  commonSensorData={commonSensorData}
                  sensorThinnedHeaderData={sensorThinnedHeaderData}
                  isThinned={isThinned}
                />
              );
            }
          })}

        <MapButton
          onClick={onCenterClick}
          ref={buttonRef}
          sx={{ display: onCenterClick ? 'inline-flex' : 'none' }}
        >
          <MyLocation htmlColor={colors.primary} />
        </MapButton>

        <Box ref={legendRef} sx={{ pointerEvents: 'none' }}>
          <Box sx={{ position: 'relative', width: 300, height: 250 }}>
            {/** 風向風速 */}
            {!reset &&
              (width === -1 || width > dimens.marineWeather.popup.displayMinWidth) &&
              marineWeatherType === 'wind_dir_wind_speed' &&
              focusMarkerData != null && (
                <MarineWeatherPopupLabel
                  label={
                    intl.formatMessage({ id: msgId.shipInfoWindSpeed }) +
                    ' : ' +
                    focusMarkerData.windSpeed +
                    'm/s ' +
                    intl.formatMessage({ id: msgId.shipInfoWindDirection }) +
                    ' : ' +
                    focusMarkerData.windDirection16Point
                  }
                  lat={focusMarkerData.latitude}
                  lng={focusMarkerData.longitude}
                />
              )}
            {!reset &&
              (width === -1 || width > dimens.marineWeather.legend.displayMinWidth) &&
              marineWeatherType === 'wind_dir_wind_speed' && (
                <MarineWeatherLegend
                  legendColors={colors.marineWeather.windSpeed.slice(0).reverse()}
                  legendLabels={constants.marineWeather.legend.windSpeedLabels}
                  unit="[m/s]"
                />
              )}

            {/** 有義波高 */}
            {!reset &&
              (width === -1 || width > dimens.marineWeather.legend.displayMinWidth) &&
              marineWeatherType === 'sig_height' && (
                <MarineWeatherLegend
                  legendColors={colors.marineWeather.swellHeight.slice(0).reverse()}
                  legendLabels={constants.marineWeather.legend.sigHeihgtLabels}
                  unit="[m]"
                />
              )}

            {/** うねりの高さ */}
            {!reset &&
              (width === -1 || width > dimens.marineWeather.popup.displayMinWidth) &&
              marineWeatherType === 'swell_height_swell_dir' &&
              focusMarkerData != null && (
                <MarineWeatherPopupLabel
                  label={
                    intl.formatMessage({ id: msgId.swellHeight }) +
                    ' : ' +
                    focusMarkerData.swellHeight +
                    'm ' +
                    intl.formatMessage({ id: msgId.swellDir }) +
                    ' : ' +
                    focusMarkerData.swellDirection16Point
                  }
                  lat={focusMarkerData.latitude}
                  lng={focusMarkerData.longitude}
                />
              )}
            {!reset &&
              (width === -1 || width > dimens.marineWeather.legend.displayMinWidth) &&
              marineWeatherType === 'swell_height_swell_dir' && (
                <MarineWeatherLegend
                  legendColors={colors.marineWeather.swellHeight.slice(0).reverse()}
                  legendLabels={constants.marineWeather.legend.swellHeightLabels}
                  unit="[m]"
                />
              )}

            {/** うねりの周期 */}
            {!reset &&
              (width === -1 || width > dimens.marineWeather.popup.displayMinWidth) &&
              marineWeatherType === 'swell_period_swell_dir' &&
              focusMarkerData != null && (
                <MarineWeatherPopupLabel
                  label={
                    intl.formatMessage({ id: msgId.swellPeriod }) +
                    ' : ' +
                    focusMarkerData.swellPeriodSecs +
                    's ' +
                    intl.formatMessage({ id: msgId.swellDir }) +
                    ' : ' +
                    focusMarkerData.swellDirection16Point
                  }
                  lat={focusMarkerData.latitude}
                  lng={focusMarkerData.longitude}
                />
              )}
            {!reset &&
              (width === -1 || width > dimens.marineWeather.legend.displayMinWidth) &&
              marineWeatherType === 'swell_period_swell_dir' && (
                <MarineWeatherLegend
                  legendColors={colors.marineWeather.swellPeriod.slice(0).reverse()}
                  legendLabels={constants.marineWeather.legend.swellPeriodLabels}
                  unit="[s]"
                />
              )}
          </Box>
        </Box>
      </GoogleMap>
    </RootDiv>
  );
}

MapContainer.defaultProps = {
  defaultCenter: { lat: 0, lng: 0 },
  defaultZoom: 1,
};
