import React from 'react';
import colors from 'resources/colors';
import { useIntl } from 'react-intl';
import msgId from 'resources/intl';
import { FileUplod } from 'components/FileUpload';
import { useDispatch } from 'react-redux';
import { Box, styled, Typography } from '@mui/material';
import { MultiLineTypography } from 'components/MultiLineTypography';
import AppLogger from 'utils/AppLogger';
import { theme } from 'resources/theme';
import { ContentsHeader } from 'components/ContentsHeader';
import {
  uploadMaritimeDrivingData,
  uploadMegFactoryTestData,
  useSystemSettingsMaritimeDrivingDataError,
  useSystemSettingsMegFactoryTestDataError,
  setMegFactoryTestDataError,
  setMaritimeDrivingDataError,
} from '../systemSettingsSlice';
import { CustomScrollBars } from 'components/CustomScrollBars';

const MenuContents = styled('div')({
  paddingBottom: theme.spacing(1),
  minWidth: '200px',
});

const MenuHeader = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  padding: '4px 4px 4px 8px',
  border: '1px solid rgba(164, 175, 183, 1)',
  background: colors.listItem.header,
});

interface TestDataManagementProps {
  open: boolean;
}

export function TestDataManagement(props: TestDataManagementProps): JSX.Element {
  AppLogger.debug('CALL: TestDataManagement');
  const { open } = props;
  const intl = useIntl();
  const dispatch = useDispatch();
  const megFactoryTestDataError = useSystemSettingsMegFactoryTestDataError();
  const maritimeDrivingDataError = useSystemSettingsMaritimeDrivingDataError();

  const handleAttachFileMegFactoryTestData = (file: File | undefined) => {
    // 拡張子チェック
    const error = file != null && !file.name.toLowerCase().endsWith('.csv');
    dispatch(setMegFactoryTestDataError(error));
  };

  const handleUploadMegFactoryTestData = (file: File) => {
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        const url = reader.result as string;
        const data = url.replace(/^.*,/, '');
        dispatch(uploadMegFactoryTestData(data));
      };
      reader.readAsDataURL(file);
    }
  };

  const handleAttachFileMaritimeDrivingData = (file: File | undefined) => {
    // 拡張子チェック
    const error = file != null && !file.name.toLowerCase().endsWith('.csv');
    dispatch(setMaritimeDrivingDataError(error));
  };

  const handleUploadMaritimeDrivingData = (file: File) => {
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        const url = reader.result as string;
        const data = url.replace(/^.*,/, '');
        dispatch(uploadMaritimeDrivingData(data));
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <Box
      sx={{
        position: 'absolute',
        width: '100%',
        height: '100%',
        background: colors.subDrawer.background,
        visibility: open ? 'visible' : 'hidden',
      }}
    >
      <CustomScrollBars thumbColor={colors.scrollBar.thumb.light}>
        <ContentsHeader title={intl.formatMessage({ id: msgId.testDataManagement })} />
        <MenuContents>
          <MenuHeader>
            <Typography variant="body2" noWrap>
              {intl.formatMessage({ id: msgId.megFactoryTestDataSettings })}
            </Typography>
          </MenuHeader>
          <MultiLineTypography
            text={intl.formatMessage({ id: msgId.megFactoryTestDataSettingsDescription })}
            variant="body2"
            align="left"
            sx={{ padding: '4px 4px 4px 8px' }}
          />
          <FileUplod
            id="FileUplodMegFactoryTestData"
            acceptFileType=".csv"
            onAttachFile={handleAttachFileMegFactoryTestData}
            onUpload={handleUploadMegFactoryTestData}
            errorMessage={
              megFactoryTestDataError
                ? intl.formatMessage({ id: msgId.fileIsNotSupported })
                : undefined
            }
            sx={{ marginRight: '8px' }}
          />
        </MenuContents>
        <MenuContents>
          <MenuHeader>
            <Typography variant="body2" noWrap>
              {intl.formatMessage({ id: msgId.maritimeDrivingDataSettings })}
            </Typography>
          </MenuHeader>
          <MultiLineTypography
            text={intl.formatMessage({ id: msgId.maritimeDrivingDataSettingsDescription })}
            variant="body2"
            align="left"
            sx={{ padding: '4px 4px 4px 8px' }}
          />
          <FileUplod
            id="FileUplodMaritimeDrivingData"
            acceptFileType=".csv"
            onAttachFile={handleAttachFileMaritimeDrivingData}
            onUpload={handleUploadMaritimeDrivingData}
            errorMessage={
              maritimeDrivingDataError
                ? intl.formatMessage({ id: msgId.fileIsNotSupported })
                : undefined
            }
            sx={{ marginRight: '8px' }}
          />
        </MenuContents>
      </CustomScrollBars>
    </Box>
  );
}
