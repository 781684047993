import React from 'react';
import { styled, SxProps } from '@mui/material';
import { theme } from 'resources/theme';
import { DateBox } from 'devextreme-react';
import EventIcon from '@mui/icons-material/Event';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import config from 'resources/config';
import constants from 'resources/constants';
import dxDateBox from 'devextreme/ui/date_box';

dayjs.extend(utc);

const StyledDateBox = styled(DateBox)({
  fontSize: theme.typography.body2.fontSize,
  marginLeft: theme.spacing(1),
  marginRight: theme.spacing(1),
  width: 150,
});

interface DatePickerProps {
  sx?: SxProps;
  type?: 'date' | 'datetime' | 'time';
  value: string;
  unsetMax: boolean;
  addMaxDay: number;
  displayFormat: string;
  error: boolean;
  onValueChanged: (value: string | undefined) => void;
}

export function DatePicker(props: DatePickerProps): JSX.Element {
  const { sx, type, value, unsetMax, addMaxDay, displayFormat, error, onValueChanged } = props;
  const dateBoxRef = React.createRef<DateBox>();

  const renderCalendarIcon = () => {
    return (
      <EventIcon
        color="primary"
        sx={{
          width: 20,
          height: 20,
          position: 'absolute',
          top: '50%',
          left: '50%',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
        }}
      />
    );
  };

  // popup dateboxの設定
  const setInitialBox = (box: dxDateBox) => {
    const ele = box.field() as HTMLInputElement;
    ele.readOnly = true;
  };

  // 開くときにdivが作られると思うので設定をする。
  const handleDateBoxOpend = (): void => {
    if (dateBoxRef && dateBoxRef.current && dateBoxRef.current.instance) {
      setInitialBox(dateBoxRef.current.instance);
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleValueChanged = (e: any) => {
    const utcDate = dayjs(e.value).add(-new Date().getTimezoneOffset(), 'minute');
    onValueChanged(utcDate.format(constants.dateFormat.iso8601));
  };

  const displayDate = (date: Date) => {
    return dayjs(date).format(displayFormat);
  };

  const now = new Date();
  const localTime = dayjs(now).add(now.getTimezoneOffset(), 'minute').add(addMaxDay, 'day');
  const max = localTime.format(constants.dateFormat.YYYYMMDD) + ' 23:59:59';

  return (
    <StyledDateBox
      sx={sx}
      value={dayjs(value).add(now.getTimezoneOffset(), 'minute').toDate()}
      type={type}
      displayFormat={displayDate}
      stylingMode="underlined"
      min={dayjs(config.minDate).toDate()}
      max={unsetMax ? undefined : dayjs(max).toDate()}
      dropDownButtonRender={renderCalendarIcon}
      onValueChanged={handleValueChanged}
      validationStatus={error ? 'invalid' : 'valid'}
      ref={dateBoxRef}
      onOpened={handleDateBoxOpend}
      onFocusIn={handleDateBoxOpend}
      applyValueMode={displayFormat.length<=10?'instantly':'useButtons'}
    />
  );
}

DatePicker.defaultProps = {
  error: false,
  unsetMax: false,
  addMaxDay: 0,
};
