import React from 'react';
import { CircularProgress, Fade, styled } from '@mui/material';
import colors from 'resources/colors';

const ProgressContainer = styled('div')({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  background: colors.loading.background,
  zIndex: 12,
});

const StyledCircularProgress = styled(CircularProgress)({
  position: 'absolute',
  top: '50%',
  left: '50%',
  marginTop: 12,
  marginLeft: -24,
  background: 'transparent',
});

interface FadeLoadingProps {
  loading: boolean;
}

export function FadeLoading(props: FadeLoadingProps): JSX.Element {
  const { loading } = props;

  return (
    <Fade
      in={loading}
      style={{
        transitionDelay: loading ? '500ms' : '0ms',
      }}
      unmountOnExit
    >
      <ProgressContainer>
        <StyledCircularProgress size={48} disableShrink />
      </ProgressContainer>
    </Fade>
  );
}
