import React from 'react';
import { useIntl } from 'react-intl';
import msgId from 'resources/intl';
import Plotly, { Layout, LayoutAxis, Shape, PlotMouseEvent, Annotations } from 'plotly.js';
import createPlotlyComponent from 'react-plotly.js/factory';
import { AnalysisData, AnalysisStatisticalValue, StatisticalValueType } from 'models/analysis';
import { Box, SxProps } from '@mui/material';
import colors from 'resources/colors';
import { theme } from 'resources/theme';
import { roundValueToString } from 'utils/misc';

const Plot = createPlotlyComponent(Plotly);
const testDataLineColor = colors.chart.analysis.vesShipSpeed.testDataLineColor;
const axisFontColor = colors.chart.analysis.vesShipSpeed.axisFontColor;
const gridColor = colors.chart.analysis.vesShipSpeed.gridColor;
const y1TickColor = colors.chart.analysis.vesShipSpeed.y1TickColor;
const y2TickColor = colors.chart.analysis.vesShipSpeed.y2TickColor;
const y1TickFontColor = colors.chart.analysis.vesShipSpeed.y1TickFontColor;
const y2TickFontColor = colors.chart.analysis.vesShipSpeed.y2TickFontColor;

const plotStyle: React.CSSProperties = {
  width: '100%',
  height: '100%',
  background: colors.chart.header.background,
  transition: theme.transitions.create(['width'], {
    easing: theme.transitions.easing.easeOut,
    duration: theme.transitions.duration.leavingScreen,
  }),
};

interface VesShipSpeedChartProps {
  sx?: SxProps;
  analysisData: AnalysisData;
  statisticalValueType: StatisticalValueType;
  height: number;
}

export function VesShipSpeedChart(props: VesShipSpeedChartProps): JSX.Element {
  const intl = useIntl();
  const { sx, analysisData, statisticalValueType, height } = props;
  const vesShipSpeed = analysisData.data.vesShipSpeed;
  const analysisState = statisticalValueType;
  if (vesShipSpeed == null || vesShipSpeed.date.length === 0) {
    return <Box sx={sx}></Box>;
  }

  let xTestData: number[];
  if (analysisData.dataFormatId !== 402) {
    xTestData = vesShipSpeed.maritimeDrivingData.VesSpdSog.map((value) =>
      value < 0 ? NaN : value
    );
  } else {
    xTestData = vesShipSpeed.maritimeDrivingData.VesSog.map((value) =>
      value < 0 ? NaN : value
    );
  }

  let xAnalysisData: number[];
  let yMegBhpDataL: number[];
  let yMegBhpDataR: number[];
  let yMegSpdDataL: number[];
  let yMegSpdDataR: number[];

  const yMegBhpTextL: string[] = [];
  const yMegBhpTextR: string[] = [];
  const yMegSpdTextL: string[] = [];
  const yMegSpdTextR: string[] = [];

  function getHoverInfoText(
    index: number,
    data: AnalysisStatisticalValue,
    unit: string,
    round: number
  ): string {
    let ret = '';
    const max = roundValueToString(data.max[index], round);
    const min = roundValueToString(data.min[index], round);
    const average = roundValueToString(data.average[index], round);
    const median = roundValueToString(data.median[index], round);
    const variance = roundValueToString(data.variance[index], round);
    const mode = roundValueToString(data.mode[index], round);
    ret = intl.formatMessage({ id: msgId.statisticalValueMax }) + ':' + max + unit + '<br>';
    ret += intl.formatMessage({ id: msgId.statisticalValueMin }) + ':' + min + unit + '<br>';
    ret +=
      intl.formatMessage({ id: msgId.statisticalValueAverage }) + ':' + average + unit + '<br>';
    ret += intl.formatMessage({ id: msgId.statisticalValueMedian }) + ':' + median + unit + '<br>';
    ret += intl.formatMessage({ id: msgId.statisticalValueVariance }) + ':' + variance + '<br>';
    ret += intl.formatMessage({ id: msgId.statisticalValueMode }) + ':' + mode + unit + '';

    return ret;
  }
  const nitiji = intl.formatMessage({ id: msgId.AnalysisHoverInfoLabelDate });
  vesShipSpeed.date.forEach((date, index) => {
    const d = new Date(date);
    const dateStrings = d.toISOString().replace('T', ' ').split('-').join('/').split(':');
    dateStrings.pop();
    const dateString = dateStrings.join(':');

    if (analysisData.dataFormatId !== 402) {
      yMegBhpTextL.push(
        nitiji +
        ':' +
        dateString +
        '<br>' +
        getHoverInfoText(
          index,
          vesShipSpeed.MegBhpP1,
          vesShipSpeed.displayInformations.MegBhp.unit + '',
          vesShipSpeed.displayInformations.MegBhp.roundingPosition
        )
      );
      yMegBhpTextR.push(
        nitiji +
        ':' +
        dateString +
        '<br>' +
        getHoverInfoText(
          index,
          vesShipSpeed.MegBhpS1,
          vesShipSpeed.displayInformations.MegBhp.unit + '',
          vesShipSpeed.displayInformations.MegBhp.roundingPosition
        )
      );
      yMegSpdTextL.push(
        nitiji +
        ':' +
        dateString +
        '<br>' +
        getHoverInfoText(
          index,
          vesShipSpeed.MegSpdP1,
          vesShipSpeed.displayInformations.MegSpd.unit + '',
          vesShipSpeed.displayInformations.MegSpd.roundingPosition
        )
      );
      yMegSpdTextR.push(
        nitiji +
        ':' +
        dateString +
        '<br>' +
        getHoverInfoText(
          index,
          vesShipSpeed.MegSpdS1,
          vesShipSpeed.displayInformations.MegSpd.unit + '',
          vesShipSpeed.displayInformations.MegSpd.roundingPosition
        )
      );
    } else {
      yMegBhpTextL.push(
        nitiji +
        ':' +
        dateString +
        '<br>' +
        getHoverInfoText(
          index,
          vesShipSpeed.EngBhpP,
          vesShipSpeed.displayInformations.EngBhp.unit + '',
          vesShipSpeed.displayInformations.EngBhp.roundingPosition
        )
      );
      yMegBhpTextR.push(
        nitiji +
        ':' +
        dateString +
        '<br>' +
        getHoverInfoText(
          index,
          vesShipSpeed.EngBhpS,
          vesShipSpeed.displayInformations.EngBhp.unit + '',
          vesShipSpeed.displayInformations.EngBhp.roundingPosition
        )
      );
      yMegSpdTextL.push(
        nitiji +
        ':' +
        dateString +
        '<br>' +
        getHoverInfoText(
          index,
          vesShipSpeed.EngSpdP,
          vesShipSpeed.displayInformations.EngSpd.unit + '',
          vesShipSpeed.displayInformations.EngSpd.roundingPosition
        )
      );
      yMegSpdTextR.push(
        nitiji +
        ':' +
        dateString +
        '<br>' +
        getHoverInfoText(
          index,
          vesShipSpeed.EngSpdS,
          vesShipSpeed.displayInformations.EngSpd.unit + '',
          vesShipSpeed.displayInformations.EngSpd.roundingPosition
        )
      );
    }
  });

  let MegBhpTestData: Plotly.Data;
  let MegSpdTestData: Plotly.Data;
  if (analysisData.dataFormatId !== 402) {
    if (analysisState === 'mode') {
      xAnalysisData = vesShipSpeed.VesSpdSog.mode.map((value) => (value < 0 ? NaN : value));
      yMegBhpDataL = vesShipSpeed.MegBhpP1.mode.map((value) => (value < 0 ? NaN : value));
      yMegBhpDataR = vesShipSpeed.MegBhpS1.mode.map((value) => (value < 0 ? NaN : value));
      yMegSpdDataL = vesShipSpeed.MegSpdP1.mode.map((value) => (value < 0 ? NaN : value));
      yMegSpdDataR = vesShipSpeed.MegSpdS1.mode.map((value) => (value < 0 ? NaN : value));
    } else {
      xAnalysisData = vesShipSpeed.VesSpdSog.median.map((value) => (value < 0 ? NaN : value));
      yMegBhpDataL = vesShipSpeed.MegBhpP1.median.map((value) => (value < 0 ? NaN : value));
      yMegBhpDataR = vesShipSpeed.MegBhpS1.median.map((value) => (value < 0 ? NaN : value));
      yMegSpdDataL = vesShipSpeed.MegSpdP1.median.map((value) => (value < 0 ? NaN : value));
      yMegSpdDataR = vesShipSpeed.MegSpdS1.median.map((value) => (value < 0 ? NaN : value));
    }

    MegBhpTestData = {
      x: xTestData,
      y: vesShipSpeed.maritimeDrivingData.MegBhp,
      type: 'scatter',
      hoverinfo: 'none',
      yaxis: 'y',
      mode: 'lines',
      line: { color: testDataLineColor },
    } as Plotly.Data;

    MegSpdTestData = {
      x: xTestData,
      y: vesShipSpeed.maritimeDrivingData.MegSpd,
      type: 'scatter',
      hoverinfo: 'none',
      yaxis: 'y2',
      mode: 'lines',
      line: { color: testDataLineColor },
    } as Plotly.Data;
  } else {
    if (analysisState === 'mode') {
      xAnalysisData = vesShipSpeed.VesSog.mode.map((value) => (value < 0 ? NaN : value));
      yMegBhpDataL = vesShipSpeed.EngBhpP.mode.map((value) => (value < 0 ? NaN : value));
      yMegBhpDataR = vesShipSpeed.EngBhpS.mode.map((value) => (value < 0 ? NaN : value));
      yMegSpdDataL = vesShipSpeed.EngSpdP.mode.map((value) => (value < 0 ? NaN : value));
      yMegSpdDataR = vesShipSpeed.EngSpdS.mode.map((value) => (value < 0 ? NaN : value));
    } else {
      xAnalysisData = vesShipSpeed.VesSog.median.map((value) => (value < 0 ? NaN : value));
      yMegBhpDataL = vesShipSpeed.EngBhpP.median.map((value) => (value < 0 ? NaN : value));
      yMegBhpDataR = vesShipSpeed.EngBhpS.median.map((value) => (value < 0 ? NaN : value));
      yMegSpdDataL = vesShipSpeed.EngSpdP.median.map((value) => (value < 0 ? NaN : value));
      yMegSpdDataR = vesShipSpeed.EngSpdS.median.map((value) => (value < 0 ? NaN : value));
    }

    MegBhpTestData = {
      x: xTestData,
      y: vesShipSpeed.maritimeDrivingData.EngBhp,
      type: 'scatter',
      hoverinfo: 'none',
      yaxis: 'y',
      mode: 'lines',
      line: { color: testDataLineColor },
    } as Plotly.Data;

    MegSpdTestData = {
      x: xTestData,
      y: vesShipSpeed.maritimeDrivingData.EngSpd,
      type: 'scatter',
      hoverinfo: 'none',
      yaxis: 'y2',
      mode: 'lines',
      line: { color: testDataLineColor },
    } as Plotly.Data;
  }

  const MegBhpAnalysisDataL = {
    x: xAnalysisData,
    y: yMegBhpDataL,
    type: 'scatter',
    yaxis: 'y',
    mode: 'markers',
    line: { color: colors.chart.lines[0] },
    text: yMegBhpTextL,
    hovertemplate: '%{text}<extra></extra>',
    hoverinfo: 'text',
  } as Plotly.Data;

  const MegBhpAnalysisDataR = {
    x: xAnalysisData,
    y: yMegBhpDataR,
    type: 'scatter',
    yaxis: 'y',
    mode: 'markers',
    line: { color: colors.chart.lines[1] },
    text: yMegBhpTextR,
    hovertemplate: '%{text}<extra></extra>',
    hoverinfo: 'text',
  } as Plotly.Data;

  const MegSpdAnalysisDataL = {
    x: xAnalysisData,
    y: yMegSpdDataL,
    type: 'scatter',
    yaxis: 'y2',
    mode: 'markers',
    line: { color: colors.chart.lines[2] },
    text: yMegSpdTextL,
    hovertemplate: '%{text}<extra></extra>',
    hoverinfo: 'text',
  } as Plotly.Data;

  const MegSpdAnalysisDataR = {
    x: xAnalysisData,
    y: yMegSpdDataR,
    type: 'scatter',
    yaxis: 'y2',
    mode: 'markers',
    line: { color: colors.chart.lines[3] },
    text: yMegSpdTextR,
    hovertemplate: '%{text}<extra></extra>',
    hoverinfo: 'text',
  } as Plotly.Data;

  const xtickVals: number[] = [];
  const xtickText: string[] = [];
  for (let i = 0; i <= 20; i++) {
    xtickVals.push(i);
    if (i % 5 === 0) {
      xtickText.push(i + '');
    } else {
      xtickText.push('');
    }
  }

  let vesSpdSogRangeUnit: number;
  let vesSpdSogRangeMin: number;
  let vesSpdSogRangeMax: number;
  let megBhpRangeMax: number;
  let megBhpPerformanceMax: number | undefined;
  let megBhpUnit: number;
  let megBhpRoundingPosition: number;
  let megSpdRangeMax: number;
  let megSpdRangeMin: number;
  let megSpdPerformanceMax: number | undefined;
  let megSpdUnit: number;
  let megSpdRoundingPosition: number;
  if (analysisData.dataFormatId !== 402) {
    vesSpdSogRangeUnit = vesShipSpeed.displayInformations.VesSpdSog.unit;
    vesSpdSogRangeMin = vesShipSpeed.displayInformations.VesSpdSog.rangeMin;
    vesSpdSogRangeMax = vesShipSpeed.displayInformations.VesSpdSog.rangeMax;
    megBhpRangeMax = vesShipSpeed.displayInformations.MegBhp.rangeMax;
    megBhpPerformanceMax = vesShipSpeed.displayInformations.MegBhp.performanceMax;
    megBhpUnit = vesShipSpeed.displayInformations.MegBhp.unit;
    megBhpRoundingPosition = vesShipSpeed.displayInformations.MegBhp.roundingPosition;
    megSpdRangeMax = vesShipSpeed.displayInformations.MegSpd.rangeMax;
    megSpdRangeMin = vesShipSpeed.displayInformations.MegSpd.rangeMin;
    megSpdPerformanceMax = vesShipSpeed.displayInformations.MegSpd.performanceMax;
    megSpdUnit = vesShipSpeed.displayInformations.MegSpd.unit;
    megSpdRoundingPosition = vesShipSpeed.displayInformations.MegSpd.roundingPosition;
  } else {
    vesSpdSogRangeUnit = vesShipSpeed.displayInformations.VesSog.unit;
    vesSpdSogRangeMin = vesShipSpeed.displayInformations.VesSog.rangeMin;
    vesSpdSogRangeMax = vesShipSpeed.displayInformations.VesSog.rangeMax;
    megBhpRangeMax = vesShipSpeed.displayInformations.EngBhp.rangeMax;
    megBhpPerformanceMax = vesShipSpeed.displayInformations.EngBhp.performanceMax;
    megBhpUnit = vesShipSpeed.displayInformations.EngBhp.unit;
    megBhpRoundingPosition = vesShipSpeed.displayInformations.EngBhp.roundingPosition;
    megSpdRangeMax = vesShipSpeed.displayInformations.EngSpd.rangeMax;
    megSpdRangeMin = vesShipSpeed.displayInformations.EngSpd.rangeMin;
    megSpdPerformanceMax = vesShipSpeed.displayInformations.EngSpd.performanceMax;
    megSpdUnit = vesShipSpeed.displayInformations.EngSpd.unit;
    megSpdRoundingPosition = vesShipSpeed.displayInformations.EngSpd.roundingPosition;
  }

  const xaxis = {
    title: {
      text:
        intl.formatMessage({ id: msgId.AnalysisVesSpdSog }) +
        '[' +
        vesSpdSogRangeUnit +
        ']',
      font: { color: axisFontColor },
    },
    tickvals: xtickVals,
    ticktext: xtickText,
    range: [
      vesSpdSogRangeMin,
      vesSpdSogRangeMax,
    ],
    showgrid: true,
    gridcolor: gridColor,
    tickfont: { color: axisFontColor, size: 13 },
  } as Partial<LayoutAxis>;

  const bhpDTick = megBhpRangeMax / 4;
  const yaxis = {
    title: {
      text:
        intl.formatMessage({ id: msgId.AnalysisMegBhpYTitle }) +
        '[' +
        megBhpUnit +
        ']',
      font: { color: axisFontColor },
    },
    tickmode: 'array',
    tickvals: [
      bhpDTick * -4,
      bhpDTick * -3,
      bhpDTick * -2,
      bhpDTick * -1,
      bhpDTick * 0,
      bhpDTick * 1,
      bhpDTick * 2,
      bhpDTick * 3,
      bhpDTick * 4,
    ],
    ticktext: ['', '', '', '', bhpDTick * 0 + '', '', bhpDTick * 2 + '', '', bhpDTick * 4 + ''],
    tickfont: { color: y1TickFontColor, size: 13 },
    range: [
      megBhpRangeMax * -1,
      megBhpRangeMax,
    ],
    showgrid: true,
    gridcolor: gridColor,
  };

  const spdDTick = megSpdRangeMax / 4;
  const yaxis2 = {
    title: {
      text:
        intl.formatMessage({ id: msgId.megRotationSpeed }) +
        '[' +
        megSpdUnit +
        ']',
      font: { color: axisFontColor },
    },
    tickmode: 'array',
    side: 'right',
    overlaying: 'y',
    tickvals: [
      spdDTick * 0,
      spdDTick * 1,
      spdDTick * 2,
      spdDTick * 3,
      spdDTick * 4,
      spdDTick * 5,
      spdDTick * 6,
      spdDTick * 7,
      spdDTick * 8,
    ],
    ticktext: [spdDTick * 0 + '', '', spdDTick * 2 + '', '', spdDTick * 4 + '', '', '', '', ''],
    tickfont: { color: y2TickFontColor, size: 13 },
    range: [
      megSpdRangeMin,
      megSpdRangeMax * 2,
    ],
    showgrid: true,
    gridcolor: gridColor,
  };
  // vesShipSpeed.displayInformations.MegBhp.performanceMax
  // vesShipSpeed.displayInformations.MegSpd.performanceMax
  const shapeBhp: Partial<Shape> = {
    type: 'line',
    xref: 'paper',
    yref: 'y',
    x0: 0,
    y0: megBhpPerformanceMax,
    x1: 1,
    y1: megBhpPerformanceMax,
    line: {
      color: 'red',
      width: 1,
      dash: 'dot',
    },
  };

  const shapeBhpLine: Partial<Shape> = {
    type: 'line',
    xref: 'paper',
    yref: 'y',
    x0: 0,
    y0: 0,
    x1: 0,
    y1: megBhpRangeMax,
    line: {
      color: y1TickColor,
      width: 2,
    },
  };

  const shapeSpd: Partial<Shape> = {
    type: 'line',
    xref: 'paper',
    yref: 'y2',
    x0: 0,
    y0: megSpdPerformanceMax,
    x1: 1,
    y1: megSpdPerformanceMax,
    line: {
      color: 'red',
      width: 1,
      dash: 'dot',
    },
  };

  const shapeSpdLine: Partial<Shape> = {
    type: 'line',
    xref: 'paper',
    yref: 'y2',
    x0: 1,
    y0: megSpdRangeMax,
    x1: 1,
    y1: 0,
    line: {
      color: y2TickColor,
      width: 2,
    },
  };

  const annotationY1: Partial<Annotations> = {
    xref: 'paper',
    yref: 'y',
    x: 0,
    xanchor: 'left',
    y: megBhpPerformanceMax,
    yanchor: 'bottom',
    text:
      roundValueToString(
        megBhpPerformanceMax,
        megBhpRoundingPosition
      ) + '(100%)',
    showarrow: false,
    font: {
      size: 13,
      color: 'red',
    },
  };
  const annotationY2: Partial<Annotations> = {
    xref: 'paper',
    yref: 'y2',
    x: 1,
    xanchor: 'right',
    y: megSpdPerformanceMax,
    yanchor: 'bottom',
    text:
      roundValueToString(
        megSpdPerformanceMax,
        megSpdRoundingPosition
      ) + '(100%)',
    showarrow: false,
    font: {
      size: 13,
      color: 'red',
    },
  };

  const layout = {
    height: height,
    autosize: true,
    xaxis: xaxis,
    yaxis: yaxis,
    yaxis2: yaxis2,
    paper_bgcolor: colors.chart.legend.background,
    plot_bgcolor: colors.chart.legend.background,
    margin: {
      t: 10,
      b: 40,
      l: 90,
      r: 90,
      pad: 2,
    },
    showlegend: false,
    shapes: [shapeBhp, shapeSpd, shapeBhpLine, shapeSpdLine],
    annotations: [annotationY1, annotationY2],
    hovermode: 'closest',
    hoverlabel: {
      bgcolor: 'black',
      bordercolor: 'black',
      font: {
        color: 'white',
      },
      align: 'left',
    },
  } as Partial<Layout>;

  const plotConfig = {
    displayModeBar: false,
    showTips: false,
    doubleClick: 'reset',
  } as Partial<Plotly.Config>;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  function onHover(data: Readonly<PlotMouseEvent>) {
    // noop
  }

  return (
    <Box sx={sx}>
      <Plot
        style={plotStyle}
        data={[
          MegSpdTestData,
          MegBhpTestData,
          MegBhpAnalysisDataL,
          MegBhpAnalysisDataR,
          MegSpdAnalysisDataL,
          MegSpdAnalysisDataR,
        ]}
        useResizeHandler
        layout={layout}
        onHover={(event) => onHover(event)}
        config={plotConfig}
      />
    </Box>
  );
}
