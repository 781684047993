import React from 'react';
import colors from 'resources/colors';
import { ListItemButton, ListItemText, styled } from '@mui/material';

const StyledListItem = styled(ListItemButton)(({ theme }) => ({
  padding: '4px 0px 4px 8px',
  minHeight: 32,
  '&:hover': {
    background: colors.listItem.hover,
  },
  borderTop: `1px solid ${theme.palette.divider}`,
  background: colors.listItem.normal,
  '&:last-child': {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  '&:disabled': {
    color: colors.text.disabled,
  },
}));

interface MenuItemProps {
  index: number;
  selected: boolean;
  title: string;
  onItemClicked: (index: number) => void;
}

export function MenuItem(props: MenuItemProps): JSX.Element {
  const { index, selected, title, onItemClicked } = props;

  const handleItemClick = (index: number) => {
    if (onItemClicked != null) {
      onItemClicked(index);
    }
  };

  return (
    <StyledListItem
      selected={selected}
      onClick={() => handleItemClick(index)}
      disabled={selected}
      style={{
        opacity: selected ? '1.0' : '',
      }}
    >
      <ListItemText primaryTypographyProps={{ variant: 'body2' }} primary={title} />
    </StyledListItem>
  );
}
