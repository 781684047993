import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'reduxjs-toolkit-persist';
import storage from 'reduxjs-toolkit-persist/lib/storage';
import logger from 'redux-logger';
import rootReducer, { RootState } from './rootReducer';
import config from 'resources/config';

const persistConfig = {
  key: 'rexcms',
  version: 4,
  storage,
  whitelist: ['displaySettings'],
  blacklist: undefined,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);
const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => {
    const mw = getDefaultMiddleware({
      thunk: true,
      serializableCheck: false,
      immutableCheck: false,
    });
    if (config.reduxLog) {
      return mw.concat(logger);
    } else {
      return mw;
    }
  },
});

export type AppDispatch = typeof store.dispatch;

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

export const persistor = persistStore(store);
export default store;
