import React, { useRef } from 'react';
import { styled } from '@mui/material';
import colors from 'resources/colors';
import dimens from 'resources/dimens';
import constants from 'resources/constants';
import grabberVertical from 'resources/assets/grabber_vertical.svg';
import grabberVerticalHover from 'resources/assets/grabber_vertical_hover.svg';
import grabberHorizontal from 'resources/assets/grabber_horizontal.svg';
import grabberHorizontalHover from 'resources/assets/grabber_horizontal_hover.svg';

const HorizontalFrame = styled('div')({
  position: 'absolute',
  width: '100%',
  top: 0,
  left: 0,
  height: dimens.splitter.width,
  zIndex: constants.zIndex.splitter - 1,
  backgroundColor: colors.primary,
});

const HorizontalSplitter = styled('div')({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: dimens.splitter.width,
  zIndex: constants.zIndex.splitter,
  tabIndex: -1,
  outline: 'none',
  cursor: 'row-resize',
  pointerEvents: 'auto',
  backgroundColor: colors.primary,
  backgroundImage: `url(${grabberHorizontal})`,
  backgroundRepeat: 'no-repeat',
  backgroundPosition: '50%',
  '&:hover': {
    backgroundImage: `url(${grabberHorizontalHover})`,
  },
});

const VerticalFrame = styled('div')({
  position: 'absolute',
  height: '100%',
  top: 0,
  left: 0,
  width: dimens.splitter.width,
  zIndex: constants.zIndex.splitter - 1,
  backgroundColor: colors.primary,
});

const VerticalSplitter = styled('div')({
  position: 'absolute',
  top: 0,
  left: 0,
  height: '100%',
  width: dimens.splitter.width,
  zIndex: constants.zIndex.splitter,
  tabIndex: -1,
  outline: 'none',
  cursor: 'col-resize',
  pointerEvents: 'auto',
  backgroundColor: colors.primary,
  backgroundImage: `url(${grabberVertical})`,
  backgroundRepeat: 'no-repeat',
  backgroundPosition: '50%',
  '&:hover': {
    backgroundImage: `url(${grabberVerticalHover})`,
  },
});

type SplitterDirection = 'horizontal' | 'vertical';

interface SplitterProps {
  direction: SplitterDirection;
  offsetX: number;
  offsetY: number;
  position: number;
  minPosition: number;
  onPositionChanged: (position: number) => void;
}

export function Splitter(props: SplitterProps): JSX.Element {
  const { direction, offsetX, offsetY, position, minPosition, onPositionChanged } = props;
  const splitterRef = useRef<HTMLInputElement>(null);

  const createDivElement = (id: string) => {
    const div = document.createElement('div');
    div.id = id;
    div.style.position = 'absolute';
    div.style.left = '0';
    div.style.top = '0';
    div.style.right = '0';
    div.style.bottom = '0';
    div.style.zIndex = '2';
    document.body.appendChild(div);
  };
  const removeDivElement = (id: string) => {
    const div = document.getElementById(id);
    div && document.body.removeChild(div);
  };

  const handleHorizontalMouseDown = (event: React.MouseEvent<EventTarget>) => {
    let newPosition = event.pageY - offsetY;
    const divId = 'horizontalSplitterBG';
    createDivElement(divId);

    function unFocus() {
      try {
        window.getSelection()?.removeAllRanges();
        // eslint-disable-next-line no-empty
      } catch (e) {}
    }

    function onMouseMove(eventMove: MouseEvent) {
      unFocus();
      if (splitterRef.current != null) {
        newPosition = eventMove.pageY - offsetY;
        const max = document.documentElement.clientHeight - offsetY - dimens.splitter.width;
        if (newPosition < minPosition) {
          newPosition = minPosition;
        } else if (newPosition > max) {
          newPosition = max - dimens.splitter.width;
        }

        splitterRef.current.style.top = newPosition + 'px';
        if (eventMove.buttons === 0) {
          // logger.debug('buttons == 0');
          // eslint-disable-next-line @typescript-eslint/no-use-before-define
          onMouseUp();
        }
      }
    }

    function onMouseUp() {
      onPositionChanged(newPosition);

      document.removeEventListener('mousemove', onMouseMove);
      document.removeEventListener('mouseup', onMouseUp);
      removeDivElement(divId);
    }

    document.addEventListener('mousemove', onMouseMove);
    document.addEventListener('mouseup', onMouseUp);
  };

  const handleVerticalMouseDown = (event: React.MouseEvent<EventTarget>) => {
    let newPosition = event.pageX - offsetX;
    const divId = 'verticalSplitterBG';
    createDivElement(divId);

    function unFocus() {
      try {
        window.getSelection()?.removeAllRanges();
        // eslint-disable-next-line no-empty
      } catch (e) {}
    }

    function onMouseMove(eventMove: MouseEvent) {
      unFocus();
      if (splitterRef.current != null) {
        newPosition = eventMove.pageX - offsetX;
        const max = document.documentElement.clientWidth - offsetX - dimens.splitter.width;
        if (newPosition < minPosition) {
          newPosition = minPosition;
        } else if (newPosition > max) {
          newPosition = max - dimens.splitter.width;
        }

        splitterRef.current.style.left = newPosition + 'px';
        if (eventMove.buttons === 0) {
          // logger.debug('buttons == 0');
          // eslint-disable-next-line @typescript-eslint/no-use-before-define
          onMouseUp();
        }
      }
    }

    function onMouseUp() {
      onPositionChanged(newPosition);

      document.removeEventListener('mousemove', onMouseMove);
      document.removeEventListener('mouseup', onMouseUp);
      removeDivElement(divId);
    }

    document.addEventListener('mousemove', onMouseMove);
    document.addEventListener('mouseup', onMouseUp);
  };

  if (direction === 'horizontal') {
    return (
      <React.Fragment>
        <HorizontalFrame sx={{ top: position }} />
        <HorizontalSplitter
          ref={splitterRef}
          style={{ top: position }}
          role="button"
          tabIndex={-1}
          onMouseDown={handleHorizontalMouseDown}
          onDragStart={() => {
            return false;
          }}
        />
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        <VerticalFrame sx={{ left: position }} />
        <VerticalSplitter
          ref={splitterRef}
          style={{ left: position }}
          role="button"
          tabIndex={-1}
          onMouseDown={handleVerticalMouseDown}
          onDragStart={() => {
            return false;
          }}
        />
      </React.Fragment>
    );
  }
}
