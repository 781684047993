import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MarineWeatherType } from 'models/marineWeather';
import { useSelector } from 'react-redux';
import { RootState } from './rootReducer';

/**
 * DisplaySettings インターフェース
 */
interface DisplaySettingsState {
  /** ユーザー毎の並び替え済み船舶IDリスト */
  orderedShipIds: { [key: number]: string[] };
  /** ユーザー毎の表示船舶IDリスト */
  displayShipIds: { [key: number]: string[] };
  /** ユーザー毎の表示気象海象タイプ */
  displayMarineWeatherType: { [key: number]: MarineWeatherType };
}

/**
 * DisplaySettings の初期状態
 */
const initialState: DisplaySettingsState = {
  orderedShipIds: {},
  displayShipIds: {},
  displayMarineWeatherType: {},
};

export const displaySettings = createSlice({
  name: 'displaySettings',
  initialState,
  reducers: {
    /**
     * アカウント毎の並び替え済み船舶IDリストを設定する。
     */
    setDisplaySettingsShipIds: (
      state,
      {
        payload,
      }: PayloadAction<{ accountId: number; orderedShipIds: string[]; displayShipIds: string[] }>
    ) => {
      state.orderedShipIds[payload.accountId] = payload.orderedShipIds;
      state.displayShipIds[payload.accountId] = payload.displayShipIds;
    },
    /**
     * アカウント毎の気象海象タイプを設定する。
     */
    setDisplaySettingsMarineWeatherType: (
      state,
      { payload }: PayloadAction<{ accountId: number; marineWeatherType: MarineWeatherType }>
    ) => {
      if (state.displayMarineWeatherType == null) {
        state.displayMarineWeatherType = {};
      }
      state.displayMarineWeatherType[payload.accountId] = payload.marineWeatherType;
    },
  },
});

export const { setDisplaySettingsShipIds, setDisplaySettingsMarineWeatherType } =
  displaySettings.actions;

const displaySettingsState = (state: RootState) => state.displaySettings;
const selectDisplaySettingsOrderedShipIds = createSelector(
  displaySettingsState,
  (x) => x.orderedShipIds
);
const selectDisplaySettingsDisplayShipIds = createSelector(
  displaySettingsState,
  (x) => x.displayShipIds
);
const selectDisplaySettingsMarineWeatherType = createSelector(
  displaySettingsState,
  (x) => x.displayMarineWeatherType
);

export const useDisplaySettingsOrderedShipIds = () =>
  useSelector(selectDisplaySettingsOrderedShipIds);
export const useDisplaySettingsDisplayShipIds = () =>
  useSelector(selectDisplaySettingsDisplayShipIds);
export const useDisplaySettingsDisplayMarineWeatherType = () =>
  useSelector(selectDisplaySettingsMarineWeatherType);

export default displaySettings.reducer;
