import React, { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import msgId from 'resources/intl';
import { locale, loadMessages } from 'devextreme/localization';
import dxJaMessages from 'resources/intl/locales/dx.ja.json';
import dimens from 'resources/dimens';
import DashboardIcon from '@mui/icons-material/Dashboard';
import TimelineIcon from '@mui/icons-material/Timeline';
import PieChartIcon from '@mui/icons-material/PieChart';
import SettingsIcon from '@mui/icons-material/Settings';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import SystemSettingsIcon from 'resources/icons/SystemSettings';
import AdministratorSettingsIcon from 'resources/icons/AdministratorSettings';
import { AlertDialog } from 'components/AlertDialog';
import { ApiErrorDialog } from 'components/ApiErrorDialog';
import { FadeLoading } from 'components/FadeLoading';
import { MainAppBar } from '../appBar/MainAppBar';
import { MainDrawer, MainDrawerItem } from '../drawer/MainDrawer';
import { DashboardFragment } from 'features/contents/dashboard/DashboardFragment';
import { PastDataFragment } from 'features/contents/shipState/pastData/PastDataFragment';
import { RealtimeAisDataFragment } from 'features/contents/shipState/realtimeAisData/RealtimeAisDataFragment';
import { AnalysisFragment } from 'features/contents/analysis/AnalysisFragment';
import { SystemSettingsFragment } from 'features/contents/systemSettings/SystemSettingsFragment';
import { AdminSettingsFragment } from 'features/contents/adminSettings/AdminSettingsFragment';
import { AccountInformationDialog } from './accountInformation/AccountInformationDialog';
import {
  fetchCommonShips,
  setFetchError,
  useCommonBusyProgress,
  useCommonFetchError,
  useCommonShips,
} from 'app/commonSlice';
import { useAccount } from 'features/login/accountSlice';
import { useMainDrawerOpen, useMainDrawerSelectedMenuKey } from 'features/drawer/mainDrawerSlice';
import { useAppBarMapMode } from 'features/appBar/mainAppBarSlice';
import 'resources/theme.dx.css';
import 'resources/map.css';
import { styled } from '@mui/material';
import { AccountMailAddressChangeDialog } from 'features/login/AccountMailAddressChangeDialog';
import { AccountPasswordChangeDialog } from 'features/login/AccountPasswordChangeDialog';
import { allClearAndSignOut } from 'utils/common';
import { useNavigate } from 'react-router-dom';
import { pause } from 'features/contents/shipState/pastData/timeSlider/timeSliderSlice';
import 'utils/DxObserver';
import { fetchMarineAreaDataList } from '../../app/commonSlice';
import config from 'resources/config';
import { dumpShips } from 'utils/dumpData';
import { GeneralSettingsFragment } from 'features/contents/generalSettings/GeneralSettingsFragment';
import { AuthorityAdministrators, AuthorityAdminUsers, AuthorityDevelopers } from 'models/accounts';
import {
  MenuAdminSettings,
  MenuAnalysisData,
  MenuContactSupport,
  MenuDashboard,
  MenuGeneralSettings,
  MenuSensorData,
  MenuSystemSettings,
} from 'models/menu';

const RootDiv = styled('div')({
  display: 'flex',
  overflow: 'hidden',
});

const ContentsMain = styled('main')({
  position: 'absolute',
  top: dimens.toolbar.height,
  overflow: 'hidden',
  height: `calc(100% - ${dimens.toolbar.height}px)`,
});

export function Main(): JSX.Element {
  const intl = useIntl();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const ships = useCommonShips();
  const fetchError = useCommonFetchError();
  const busyProgress = useCommonBusyProgress();
  const mainDrawerOpen = useMainDrawerOpen();
  const selectedMenuKey = useMainDrawerSelectedMenuKey();
  const mapMode = useAppBarMapMode();
  const account = useAccount();

  const drawerItems: MainDrawerItem[] = [
    {
      title: intl.formatMessage({ id: msgId.dashboard }),
      buttonKey: MenuDashboard,
      icon: DashboardIcon,
      enabled: true,
    },
    {
      title: intl.formatMessage({ id: msgId.sensorDataBrowsing }),
      buttonKey: MenuSensorData,
      icon: TimelineIcon,
      enabled: true,
    },
    {
      title: intl.formatMessage({ id: msgId.analysisDataBrowsing }),
      buttonKey: MenuAnalysisData,
      icon: PieChartIcon,
      enabled: true,
    },
    // {
    //   index: 3,
    //   title: intl.formatMessage({ id: msgId.systemProposal }),
    //   buttonKey: 'system-proposal',
    //   icon: EmojiObjectsIcon,
    //   lockIcon: LockIcon,
    //   enabled: false,
    // },
  ];

  if (account) {
    if (
      account.authorityId === AuthorityAdministrators ||
      account.authorityId === AuthorityDevelopers ||
      account.authorityId === AuthorityAdminUsers
    ) {
      drawerItems.push({
        title: intl.formatMessage({ id: msgId.generalSettings }),
        buttonKey: MenuGeneralSettings,
        icon: SettingsIcon,
        enabled: true,
      });
    }
    drawerItems.push({
      title: intl.formatMessage({ id: msgId.contactSupport }),
      buttonKey: MenuContactSupport,
      icon: ContactSupportIcon,
      enabled: true,
    });
    if (
      account.authorityId === AuthorityAdministrators ||
      account.authorityId === AuthorityDevelopers
    ) {
      drawerItems.push({
        title: intl.formatMessage({ id: msgId.systemSettings }),
        buttonKey: MenuSystemSettings,
        icon: SystemSettingsIcon,
        enabled: true,
      });
    }
    if (account.authorityId === AuthorityAdministrators) {
      drawerItems.push({
        title: intl.formatMessage({ id: msgId.administratorSettings }),
        buttonKey: 'admin-settings',
        icon: AdministratorSettingsIcon,
        enabled: true,
      });
    }
  }

  const hideDashboard = selectedMenuKey !== MenuDashboard;
  const hideSensorData = selectedMenuKey !== MenuSensorData || mapMode !== 0;
  const hideRealtimeAisData = selectedMenuKey !== MenuSensorData || mapMode !== 1;
  const hideAnalysis = selectedMenuKey !== MenuAnalysisData;
  const hideGeneralSettings = selectedMenuKey !== MenuGeneralSettings;
  const hideSystemSettings = selectedMenuKey !== MenuSystemSettings;
  const hideAdminSettings = selectedMenuKey !== MenuAdminSettings;
  const dashboard = useMemo(() => <DashboardFragment hidden={hideDashboard} />, [hideDashboard]);
  const pastData = useMemo(() => <PastDataFragment hidden={hideSensorData} />, [hideSensorData]);
  const realtimeAisData = useMemo(
    () => <RealtimeAisDataFragment hidden={hideRealtimeAisData} />,
    [hideRealtimeAisData]
  );
  const analysis = useMemo(() => <AnalysisFragment hidden={hideAnalysis} />, [hideAnalysis]);
  const generalSettings = useMemo(
    () => <GeneralSettingsFragment hidden={hideGeneralSettings} />,
    [hideGeneralSettings]
  );
  const systemSettings = useMemo(
    () => <SystemSettingsFragment hidden={hideSystemSettings} />,
    [hideSystemSettings]
  );
  const adminSettings = useMemo(
    () => <AdminSettingsFragment hidden={hideAdminSettings} />,
    [hideAdminSettings]
  );

  const handleNoData = () => {
    allClearAndSignOut(dispatch, account?.homePath, navigate);
  };

  const handleClearError = () => {
    dispatch(setFetchError(undefined));
  };

  useEffect(() => {
    dispatch(fetchCommonShips());
    dispatch(fetchMarineAreaDataList());
  }, [dispatch]);

  useEffect(() => {
    loadMessages(dxJaMessages);
    locale(intl.locale);
  }, [intl]);

  useEffect(() => {
    if (selectedMenuKey !== MenuSensorData || mapMode !== 0) {
      dispatch(pause());
    }
  }, [dispatch, selectedMenuKey, mapMode]);

  useEffect(() => {
    if (config.valueInspectionLog) {
      if (ships != null) {
        dumpShips(ships);
      }
    }
  }, [ships]);

  let menuIndex = drawerItems.findIndex((x) => x.buttonKey === selectedMenuKey);
  if (menuIndex <= 0) {
    menuIndex = 0;
  }

  return (
    <RootDiv>
      <MainAppBar
        title={drawerItems[menuIndex].title}
        showSensorDataMenu={selectedMenuKey === MenuSensorData}
        account={account}
        mainDrawerOpen={mainDrawerOpen}
      />
      <MainDrawer items={drawerItems} />
      <ContentsMain
        sx={
          mainDrawerOpen
            ? { left: dimens.drawer.width, width: `calc(100% - ${dimens.drawer.width}px)` }
            : { left: dimens.drawer.minWidth, width: `calc(100% - ${dimens.drawer.minWidth}px)` }
        }
      >
        {/* 船舶取得中 */}
        {((fetchError == null && ships == null) || busyProgress) && <FadeLoading loading={true} />}
        {/* 船舶取得済み */}
        {fetchError == null && ships != null && ships.length > 0 && (
          <React.Fragment>
            {dashboard}
            {pastData}
            {realtimeAisData}
            {analysis}
            {account &&
              (account.authorityId === AuthorityAdministrators ||
                account.authorityId === AuthorityDevelopers ||
                account.authorityId === AuthorityAdminUsers) &&
              generalSettings}
            {account &&
              (account.authorityId === AuthorityAdministrators ||
                account.authorityId === AuthorityDevelopers) &&
              systemSettings}
            {account && account.authorityId === AuthorityAdministrators && adminSettings}
          </React.Fragment>
        )}
        {/* 船舶なし */}
        {ships != null && ships.length === 0 && (
          <AlertDialog
            title={intl.formatMessage({ id: msgId.error })}
            message={intl.formatMessage({ id: msgId.errorMessageNoData })}
            onClose={handleNoData}
          />
        )}
        {/* 船舶取得エラー */}
        {fetchError != null && <ApiErrorDialog error={fetchError} onClose={handleClearError} />}
        {/* アカウント情報ダイアログ */}
        <AccountInformationDialog />
        {/* アカウント情報h変更ダイアログ(メールアドレス) */}
        <AccountMailAddressChangeDialog />
        {/* アカウント情報h変更ダイアログ(パスワード) */}
        <AccountPasswordChangeDialog />
      </ContentsMain>
    </RootDiv>
  );
}
