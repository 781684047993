import React, { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import AppLogger from 'utils/AppLogger';
import msgId from 'resources/intl';
import { ShipDrawer } from 'components/shipDrawer/ShipDrawer';
import { FadeLoading } from 'components/FadeLoading';
import { SearchConditionsBar } from 'components/SearchConditionsBar';
import { Splitter } from 'components/Splitter';
import { TimeSlider } from './timeSlider/TimeSlider';
import { MapContainer } from './map/MapContainer';
import { SearchFragment } from './search/SearchFragment';
import { ChartContainer } from './chart/ChartContainer';
import {
  setShips,
  setContractedShipId,
  fetchCommonSensorData,
  setFetchError,
  setFilterShipName,
  fetchSensorThinnedDataDatesList,
  fetchSensorThinnedHeaderData,
  setThinnedShips,
  setCurrentThinnedConditions,
  fetchCommonSensorThinnedDataList,
  usePastDataFilteredShips,
  usePastDataContractedShipIds,
  usePastDataFetching,
  usePastDataCommonSensorData,
  usePastDataFetchError,
  usePastDataShips,
  usePastDataSensorThinnedDataDatesList,
  usePastDataSensorThinnedHeaderData,
  usePastDataCommonSensorThinnedDataList,
  usePastDataThinnedShips,
  usePastDataThinnedFilteredShips,
  usePastDataCurrentThinnedConditions,
  setCommonSensorThinnedDataList,
  setShipWaveData,
  setSensorThinnedHeaderData,
  setCommonSensorData,
  usePastThinnedDataDatesListFetching,
  usePastThinnedHeaderDataFetching,
  usePastDataTrendOnly,
  setTrendOnly,
} from './pastDataSlice';
import {
  setOpen as setSeachOpen,
  setConfirmedResultItem,
  usePastDataSearchOpen,
  usePastDataSearchConditions,
  usePastDataSearchConfirmedSearchResultItem,
} from './search/searchSlice';
import { resetMap, setCenter } from './map/mapSlice';
import {
  clearShipStatePastDataTimeSlider,
  getTimeSliderCurrentIndex,
  restoreRange,
  setSpeedMagnification,
} from './timeSlider/timeSliderSlice';
import { SensorThinnedHeaderData, CommonSensorData } from 'models/data';
import { Machine } from 'models/ships';
import {
  removeChart,
  addChart,
  usePastDataTrendChartFetching,
  usePastDataTrendChartConfirmedCondition,
  updateApplyChartSeriesList,
  setConfirmedCondition as setTrendChartConfirmedCondition,
  setCopyFromTempContexts,
  resetChartSeriesList,
} from './chart/trend/trendChartSlice';
import dayjs from 'dayjs';
import { SensorDataSearchConditions, ThinnedSearchConditions } from 'models/search';
import {
  fetchDiagnosisListItems,
  useDiagnosisListItems,
} from '../../dashboard/diagnosis/diagnosisSlice';
import { useMainDrawerOpen } from 'features/drawer/mainDrawerSlice';
import { useCommonShips } from 'app/commonSlice';
import { useAppBarConfirmedReorderShips } from 'features/appBar/mainAppBarSlice';
import { styled } from '@mui/material';
import dimens from 'resources/dimens';
import config from 'resources/config';
import { dumpPastDataInitialSensorList, dumpPastDataInitialMapData } from 'utils/dumpData';
import { RootContainer } from 'components/RootContainer';
import { SlideFragmentContainer } from 'components/SlideFragmentContainer';
import { ApiErrorDialog } from 'components/ApiErrorDialog';
import constants from 'resources/constants';

const ChartDiv = styled('div')({
  position: 'absolute',
  overflow: 'hidden',
  top: dimens.searchBar.height,
  height: `calc(100% - ${dimens.searchBar.height}px)`,
});

const minPosition = 0;

interface PastDataFragmentProps {
  hidden: boolean;
}

export function PastDataFragment(props: PastDataFragmentProps): JSX.Element {
  AppLogger.debug('CALL: PastDataFragment');
  const { hidden } = props;
  const intl = useIntl();
  const dispatch = useDispatch();
  const [shipDrawerOpen, setShipDrawerOpen] = useState(true);
  const [clear, setClear] = useState(false);
  const mainDrawerOpen = useMainDrawerOpen();
  const commonShips = useCommonShips();
  const _ships = usePastDataShips();
  const _filteredShips = usePastDataFilteredShips();
  const contractedShipIds = usePastDataContractedShipIds();
  const searchOpen = usePastDataSearchOpen();
  const _searchConditions = usePastDataSearchConditions();
  const confirmedSearchResultItem = usePastDataSearchConfirmedSearchResultItem();
  const sensorDataFetching = usePastDataFetching();
  const thinnedDataDatesListFetching = usePastThinnedDataDatesListFetching();
  const thinnedHeaderDataFetching = usePastThinnedHeaderDataFetching();
  const trendChartFetching = usePastDataTrendChartFetching();
  const _commonSensorData = usePastDataCommonSensorData();
  const fetchError = usePastDataFetchError();
  const diagnosisListItems = useDiagnosisListItems();
  const trendOnly = usePastDataTrendOnly();

  const thinningShips = useAppBarConfirmedReorderShips();
  const sensorThinnedDataDatesList = usePastDataSensorThinnedDataDatesList();
  const sensorThinnedHeaderData = usePastDataSensorThinnedHeaderData();
  const commonSensorThinnedDataList = usePastDataCommonSensorThinnedDataList();
  const thinnedShips = usePastDataThinnedShips();
  const thinnedFilteredShips = usePastDataThinnedFilteredShips();
  const currentThinnedConditions = usePastDataCurrentThinnedConditions();
  const selectedTrendChartConfirmedCondition = usePastDataTrendChartConfirmedCondition();
  const thinnedSensorDataSearchConditions: SensorDataSearchConditions = {
    conditions: [],
    enabled: false,
    invalidEndDate: false,
    invalidStartDate: false,
    screeningMinutes: 60 * 24,
    startDate: '',
    endDate: '',
  };
  const isThinned = !confirmedSearchResultItem;
  const thinnedHeaderDatas: SensorThinnedHeaderData[] = [];
  const machineIdtoShipName: { [key: string]: string } = {};
  let commonSensorData: CommonSensorData | undefined = undefined;
  let thinnedCommonSensorData: CommonSensorData | undefined = undefined;
  if (confirmedSearchResultItem && _commonSensorData) {
    // 条件検索表示
    commonSensorData = _commonSensorData;
  } else if (currentThinnedConditions) {
    // カレンダー日付選択表示
    if (currentThinnedConditions.startDate.length > 0) {
      // 日付選択済み
      thinnedSensorDataSearchConditions.startDate = currentThinnedConditions.startDate;
      thinnedSensorDataSearchConditions.endDate = currentThinnedConditions.endDate;
      commonSensorThinnedDataList?.forEach((list) => {
        thinnedHeaderDatas.push({
          lastLogDate: list.logdate[list.logdate.length - 1],
          logdate: list.logdate,
          machineId: list.machineId,
          dataFormatId: list.dataFormatId,
          sensors: list.sensors,
        });
        if (list.machineId === currentThinnedConditions.machineId) {
          commonSensorData = list;
          thinnedCommonSensorData = list;
        }
      });
    } else {
      // 初期表示
      if (sensorThinnedDataDatesList) {
        sensorThinnedDataDatesList.forEach((list) => {
          if (list.machineId === currentThinnedConditions.machineId) {
            const date = list.dates[list.dates.length - 1];
            thinnedSensorDataSearchConditions.endDate = date + 'T23:59:59+00:00';
            if (list.dates.length >= 7) {
              const startDate = list.dates[list.dates.length - 7];
              thinnedSensorDataSearchConditions.startDate = startDate + 'T00:00:00+00:00';
            } else {
              const startDate = list.dates[0];
              thinnedSensorDataSearchConditions.startDate = startDate + 'T00:00:00+00:00';
            }
          }
        });
      }
      sensorThinnedHeaderData && thinnedHeaderDatas.push(sensorThinnedHeaderData);
    }
  }

  // 機械IDから船舶名の検索リストを作成
  commonShips?.forEach((ship) =>
    ship.machines.forEach((machine) => (machineIdtoShipName[String(machine.machineId)] = ship.name))
  );

  const containerRef = useRef<HTMLDivElement>(null);
  const [chartContainerLeft, setChartContainerLeft] = useState<number>(0);
  const [contentSize, setContentSize] = useState<{ width: number; height: number }>({
    width: -1,
    height: 0,
  });
  const [thinnedMode, setThinnedMode] = useState<boolean | undefined>(undefined);
  const [thinnedCondition, setThinnedCondition] = useState<
    { sensorNames: string[]; machine: Machine } | undefined
  >(undefined);
  const [resetRange, setResetRange] = useState<boolean>(false);
  const [selectCondition, setSelectCondition] = useState<
    | {
      machineId: number;
      startDate: string;
      endDate: string | undefined;
    }
    | undefined
  >(undefined);

  const ships = !isThinned ? _ships : thinnedShips;
  const filteredShips = !isThinned ? _filteredShips : thinnedFilteredShips;
  const searchConditions = !isThinned ? _searchConditions : thinnedSensorDataSearchConditions;
  const fetching =
    sensorDataFetching ||
    thinnedDataDatesListFetching ||
    thinnedHeaderDataFetching ||
    trendChartFetching;

  commonSensorData =
    ships &&
      ships.some((ship) =>
        ship.machines.some((machine) => machine.machineId === commonSensorData?.machineId)
      )
      ? commonSensorData
      : undefined;

  /**
   * 検索入力変更
   * @param event イベント
   */
  const handleSeachInputChange = (input: string) => {
    dispatch(setFilterShipName(input));
  };

  /**
   * 検索条件変更クリック
   */
  const handleSeachClick = () => {
    dispatch(setSeachOpen(true));
    if (selectedTrendChartConfirmedCondition && confirmedSearchResultItem === undefined) {
      setThinnedCondition({
        sensorNames: [...selectedTrendChartConfirmedCondition.sensorNames],
        machine: { ...selectedTrendChartConfirmedCondition.machine },
      });
    }
  };

  /**
   * 船舶ドロワー開閉状態変更
   * @param open 開閉状態
   */
  const handleShipDrawerChangeOpen = (open: boolean) => {
    setShipDrawerOpen(open);
  };

  /**
   *  船舶ドロワーの船舶開閉状態変更
   * @param shipId 船舶ID
   * @param expanded 開閉状態
   */
  const handleShipDrawerExpandedChange = (shipId: string, expanded: boolean) => {
    dispatch(setContractedShipId({ shipId, expanded }));
  };

  /**
   *  船舶ドロワーの船舶位置移動
   * @param shipId 船舶ID
   * @param expanded 開閉状態
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleShipLocationLocation = (shipId: string) => {
    if (isThinned) {
      if (thinnedHeaderDatas != null) {
        // 間引きヘッダーデータから選択された船舶IDと一致するデータの緯度経度を取得して
        // 地図のセンターに設定する
        thinnedHeaderDatas.forEach((thinnedHeader) => {
          thinningShips.forEach((ship) => {
            if (shipId === ship.shipId && ship.machines.length > 0) {
              ship.machines.forEach((machine) => {
                if (thinnedHeader.machineId === machine.machineId) {
                  if (machine.dataFormatId !== 402) {
                    if (
                      thinnedHeader.sensors.vesLatC1 != null &&
                      thinnedHeader.sensors.vesLonC1 != null &&
                      thinnedHeader.sensors.vesLatC1.data.length > 0 &&
                      thinnedHeader.sensors.vesLonC1.data.length > 0
                    ) {
                      if (commonSensorThinnedDataList != null) {
                        // カレンダー日付選択表示
                        const index = getTimeSliderCurrentIndex();
                        dispatch(
                          setCenter({
                            lat: thinnedHeader.sensors.vesLatC1.data[index],
                            lng: thinnedHeader.sensors.vesLonC1.data[index],
                          })
                        );
                      } else {
                        // 初期表示
                        dispatch(
                          setCenter({
                            lat: thinnedHeader.sensors.vesLatC1.data[
                              thinnedHeader.sensors.vesLatC1.data.length - 1
                            ],
                            lng: thinnedHeader.sensors.vesLonC1.data[
                              thinnedHeader.sensors.vesLonC1.data.length - 1
                            ],
                          })
                        );
                      }
                    }
                  } else {
                    if (
                      thinnedHeader.sensors.vesLat != null &&
                      thinnedHeader.sensors.vesLon != null &&
                      thinnedHeader.sensors.vesLat.data.length > 0 &&
                      thinnedHeader.sensors.vesLon.data.length > 0
                    ) {
                      if (commonSensorThinnedDataList != null) {
                        // カレンダー日付選択表示
                        const index = getTimeSliderCurrentIndex();
                        dispatch(
                          setCenter({
                            lat: thinnedHeader.sensors.vesLat.data[index],
                            lng: thinnedHeader.sensors.vesLon.data[index],
                          })
                        );
                      } else {
                        // 初期表示
                        dispatch(
                          setCenter({
                            lat: thinnedHeader.sensors.vesLat.data[
                              thinnedHeader.sensors.vesLat.data.length - 1
                            ],
                            lng: thinnedHeader.sensors.vesLon.data[
                              thinnedHeader.sensors.vesLon.data.length - 1
                            ],
                          })
                        );
                      }
                    }
                  }
                }
              });
            }
          });
        });
      }
    } else {
      // 検索条件表示
      if (commonSensorData != null) {
        if (commonSensorData.dataFormatId !== 402) {
          if (
            commonSensorData.sensors.vesLatC1 != null &&
            commonSensorData.sensors.vesLonC1 != null
          ) {
            const timeSliderCurrentIndex = getTimeSliderCurrentIndex();
            dispatch(
              setCenter({
                lat: commonSensorData.sensors.vesLatC1.data[timeSliderCurrentIndex],
                lng: commonSensorData.sensors.vesLonC1.data[timeSliderCurrentIndex],
              })
            );
          }
        } else {
          if (
            commonSensorData.sensors.vesLat != null &&
            commonSensorData.sensors.vesLon != null
          ) {
            const timeSliderCurrentIndex = getTimeSliderCurrentIndex();
            dispatch(
              setCenter({
                lat: commonSensorData.sensors.vesLat.data[timeSliderCurrentIndex],
                lng: commonSensorData.sensors.vesLon.data[timeSliderCurrentIndex],
              })
            );
          }
        }
      }
    }
  };

  /**
   * 検索条件表示の時にマップ上の現在地ボタンを押下したときに呼ばれる
   */
  const handleCenterClick = () => {
    if (commonSensorData != null) {
      if (commonSensorData.dataFormatId !== 402) {
        if (commonSensorData.sensors.vesLatC1 != null && commonSensorData.sensors.vesLonC1 != null) {
          const timeSliderCurrentIndex = getTimeSliderCurrentIndex();
          dispatch(
            setCenter({
              lat: commonSensorData.sensors.vesLatC1.data[timeSliderCurrentIndex],
              lng: commonSensorData.sensors.vesLonC1.data[timeSliderCurrentIndex],
            })
          );
        }
      } else {
        if (commonSensorData.sensors.vesLat != null && commonSensorData.sensors.vesLon != null) {
          const timeSliderCurrentIndex = getTimeSliderCurrentIndex();
          dispatch(
            setCenter({
              lat: commonSensorData.sensors.vesLat.data[timeSliderCurrentIndex],
              lng: commonSensorData.sensors.vesLon.data[timeSliderCurrentIndex],
            })
          );
        }
      }

    }
  };

  /**
   * カレンダーの日付を選択したときに呼ばれる
   * @param machineId 機械ID
   * @param date 選択した日
   */
  const handleClickDay = (machineId: number, date: string) => {
    if (
      (currentThinnedConditions &&
        currentThinnedConditions.machineId === machineId &&
        currentThinnedConditions.startDate.substring(0, 10) === date) ||
      !isThinned
    ) {
      // 同じ条件なので何もしない
    } else {
      if (currentThinnedConditions && currentThinnedConditions.machineId === machineId) {
        if (currentThinnedConditions.startDate === '') {
          dispatch(
            addChart({ chartId: '0', title: intl.formatMessage({ id: msgId.trendDefaultTitle }) })
          );
        }
      } else {
        dispatch(removeChart({ chartId: '0' }));
        dispatch(
          addChart({ chartId: '0', title: intl.formatMessage({ id: msgId.trendDefaultTitle }) })
        );
      }
      thinningShips.forEach((x) => {
        x.machines.forEach((y) => {
          if (y.machineId === machineId) {
            dispatch(
              setCurrentThinnedConditions({
                ship: x,
                machineId: machineId,
                startDate: date + 'T00:00:00+00:00',
                endDate: date + 'T23:59:59+00:00',
              })
            );
            if (selectedTrendChartConfirmedCondition) {
              // 選択した船舶と、トレンドグラフで表示中の船舶が同じであれば引き継ぐ
              // 異なる場合は初期化
              if (selectedTrendChartConfirmedCondition.machine.machineId === machineId) {
                dispatch(
                  setTrendChartConfirmedCondition({
                    chartId: selectedTrendChartConfirmedCondition.chartId,
                    machine: selectedTrendChartConfirmedCondition.machine,
                    sensorNames: selectedTrendChartConfirmedCondition.sensorNames,
                    thinnedData: selectedTrendChartConfirmedCondition.thinnedData,
                    startDate: date + 'T00:00:00+00:00',
                    endDate: date + 'T23:59:59+00:00',
                    applied: false,
                  })
                );
                dispatch(resetChartSeriesList({ chartId: '0' }));
              } else {
                dispatch(setTrendOnly(false));
                dispatch(setTrendChartConfirmedCondition(undefined));
                dispatch(removeChart({ chartId: '0' }));
                dispatch(
                  addChart({
                    chartId: '0',
                    title: intl.formatMessage({ id: msgId.trendDefaultTitle }),
                  })
                );
              }
            } else {
              dispatch(resetMap(true));
              dispatch(setTrendOnly(false));
              dispatch(resetChartSeriesList({ chartId: '0' }));
            }
          }
        });
      });
    }
  };
  const onChangeShip = (shipId: string): void => {
    dispatch(removeChart({ chartId: '0' }));
    dispatch(
      addChart({ chartId: '0', title: intl.formatMessage({ id: msgId.trendDefaultTitle }) })
    );
    dispatch(setTrendOnly(false));

    if (currentThinnedConditions && sensorThinnedDataDatesList) {
      const conditions = { ...currentThinnedConditions };
      conditions.ship = thinningShips.filter((x) => x.shipId === shipId)[0];
      if (conditions.startDate.length > 0) {
        const currentDate = conditions.startDate.substring(0, 10);
        const machines = conditions.ship.machines.filter(
          (machine) =>
            sensorThinnedDataDatesList.filter(
              (list) =>
                machine.machineId === list.machineId &&
                list.dates.length > 0 &&
                list.dates.includes(currentDate)
            ).length > 0
        );
        if (machines.length > 0) {
          // 現在日時の情報をmachineが持っていればlength > 0で返却される。
          conditions.machineId = machines[0].machineId;
        } else {
          // 現在日時の情報をmachineが持っていなければmachineの先頭を選択し、日付情報を初期化する。
          conditions.machineId = conditions.ship.machines[0].machineId;
          conditions.startDate = '';
          conditions.endDate = '';
        }

        dispatch(setSensorThinnedHeaderData(undefined));
        dispatch(setCurrentThinnedConditions(conditions));
      } else {
        const machine = conditions.ship.machines.filter(
          (machine) =>
            sensorThinnedDataDatesList.filter(
              (list) => machine.machineId === list.machineId && list.dates.length > 0
            ).length > 0
        );
        if (machine.length > 0) {
          conditions.machineId = machine[0].machineId;
          dispatch(setSensorThinnedHeaderData(undefined));
          dispatch(setCurrentThinnedConditions(conditions));
        }
      }
    }
  };

  /**
   * データ取得エラー
   */
  const handleFetchError = () => {
    dispatch(setFetchError(undefined));
  };

  /**
   * スプリッタ位置変更
   * @param position 移動位置
   */
  const handlePositionChanged = (position: number) => {
    AppLogger.debug('position: ' + position);
    setChartContainerLeft(position);
  };

  /**
   * 表示条件クリアが押下されたときに呼ばれる
   */
  const handlConfirmedResultItemClear = () => {
    dispatch(resetMap(true));
    dispatch(setConfirmedResultItem(undefined));
    dispatch(setCommonSensorData(undefined));
    dispatch(setShipWaveData(undefined));
    dispatch(setTrendOnly(false));
    setClear(true);
    if (currentThinnedConditions) {
      if (
        thinnedShips?.some((x) =>
          x.machines.some((y) => y.machineId === currentThinnedConditions.machineId)
        ) &&
        thinnedCondition != null
      ) {
        // 船舶が同じ場合は復元
        dispatch(setCopyFromTempContexts({ chartId: '0' }));
        dispatch(resetChartSeriesList({ chartId: '0' }));
        dispatch(
          setTrendChartConfirmedCondition({
            chartId: '0',
            machine: thinnedCondition.machine,
            sensorNames: thinnedCondition.sensorNames,
            thinnedData: true,
            startDate: currentThinnedConditions.startDate,
            endDate: currentThinnedConditions.endDate,
            applied: false,
          })
        );
      } else {
        // 船舶が異なるためトレンド設定クリア
        dispatch(setTrendChartConfirmedCondition(undefined));
        dispatch(removeChart({ chartId: '0' }));
        dispatch(
          addChart({ chartId: '0', title: intl.formatMessage({ id: msgId.trendDefaultTitle }) })
        );
      }
    } else {
      dispatch(setTrendChartConfirmedCondition(undefined));
      dispatch(removeChart({ chartId: '0' }));
      dispatch(
        addChart({ chartId: '0', title: intl.formatMessage({ id: msgId.trendDefaultTitle }) })
      );
    }

    if (commonSensorThinnedDataList) {
      dispatch(restoreRange(true));
    } else {
      dispatch(setSensorThinnedHeaderData(undefined));
      dispatch(clearShipStatePastDataTimeSlider());
      dispatch(setTrendOnly(false));
    }
  };

  const handlThinnedConfirmedResultItemClear = () => {
    dispatch(resetMap(true));
    if (currentThinnedConditions) {
      const condition = { ...currentThinnedConditions } as ThinnedSearchConditions;
      condition.startDate = '';
      condition.endDate = '';
      condition.machineId = condition.ship.machines[0].machineId;
      dispatch(clearShipStatePastDataTimeSlider());
      dispatch(setCurrentThinnedConditions(condition));
      dispatch(setCommonSensorThinnedDataList(undefined));
      dispatch(setSensorThinnedHeaderData(undefined));
      dispatch(removeChart({ chartId: '0' }));
      dispatch(
        addChart({ chartId: '0', title: intl.formatMessage({ id: msgId.trendDefaultTitle }) })
      );
      dispatch(setTrendOnly(false));
    }
    setClear(true);
  };

  const handleDateFormat = (date: string) => {
    if (isThinned) {
      return dayjs(date).utc().format(constants.dateFormat.YYYYMMDDHHmm);
    } else {
      return dayjs(date).utc().format(constants.dateFormat.YYYYMMDDHHmm);
    }
  };

  useEffect(() => {
    if (commonShips != null && confirmedSearchResultItem != null) {
      dispatch(setShips({ commonShips, confirmedSearchResultItem }));
      dispatch(resetMap(true));
      dispatch(resetChartSeriesList({ chartId: '0' }));
      dispatch(setShipWaveData(undefined));
      dispatch(fetchCommonSensorData(confirmedSearchResultItem));
    }
  }, [dispatch, commonShips, confirmedSearchResultItem]);

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      const width = entries[0].contentRect.width;
      const height = entries[0].contentRect.height;
      if (commonSensorData != null) {
        setContentSize({ width, height });
        if (contentSize.width === -1) {
          setChartContainerLeft(width - dimens.shipState.chartContainer.width - 5);
        }
      }
    });
    containerRef.current && resizeObserver.observe(containerRef.current);

    return (): void => {
      resizeObserver.disconnect();
    };
  }, [dispatch, mainDrawerOpen, shipDrawerOpen, commonSensorData, contentSize.width]);

  useEffect(() => {
    if (commonSensorData != null) {
      if (commonSensorData.dataFormatId !== 402) {
        if (commonSensorData.sensors.vesLatC1 != null && commonSensorData.sensors.vesLonC1 != null) {
          const index = getTimeSliderCurrentIndex();
          if (commonSensorData.sensors.vesLatC1.data.length > index) {
            dispatch(
              setCenter({
                lat: commonSensorData.sensors.vesLatC1.data[index],
                lng: commonSensorData.sensors.vesLonC1.data[index],
              })
            );
          }
        }
      } else {
        if (commonSensorData.sensors.vesLat != null && commonSensorData.sensors.vesLon != null) {
          const index = getTimeSliderCurrentIndex();
          if (commonSensorData.sensors.vesLat.data.length > index) {
            dispatch(
              setCenter({
                lat: commonSensorData.sensors.vesLat.data[index],
                lng: commonSensorData.sensors.vesLon.data[index],
              })
            );
          }
        }
      }


    }
  }, [dispatch, commonSensorData]);

  useEffect(() => {
    if (commonSensorThinnedDataList === undefined) {
      dispatch(restoreRange(false));
    }
  }, [dispatch, commonSensorThinnedDataList]);

  let offsetX = mainDrawerOpen ? dimens.drawer.width : dimens.drawer.minWidth;
  if (isThinned) {
    offsetX += shipDrawerOpen ? dimens.subDrawer.width : dimens.subDrawer.minWidth;
  }

  if (chartContainerLeft < minPosition) {
    setChartContainerLeft(minPosition);
  } else if (contentSize.width > 5 && chartContainerLeft >= contentSize.width) {
    setChartContainerLeft(contentSize.width - 5);
  }

  const chartContainerWidth =
    contentSize.width === -1
      ? chartContainerLeft + dimens.splitter.width
      : contentSize.width - (chartContainerLeft + dimens.splitter.width);

  useEffect(() => {
    if (diagnosisListItems == null) {
      dispatch(fetchDiagnosisListItems());
    }
  }, [dispatch, diagnosisListItems]);

  useEffect(() => {
    if (confirmedSearchResultItem === undefined && thinningShips.length > 0) {
      if (thinnedShips != null) {
        const mid1: number[] = [];
        thinningShips.forEach((ts) => {
          ts.machines.forEach((m) => {
            mid1.push(m.machineId);
          });
        });
        const mid2: number[] = [];
        thinnedShips.forEach((ts) => {
          ts.machines.forEach((m) => {
            mid2.push(m.machineId);
          });
        });
        if (mid1.toString() != mid2.toString()) {
          if (
            sensorThinnedHeaderData != null &&
            !mid1.includes(sensorThinnedHeaderData.machineId)
          ) {
            dispatch(clearShipStatePastDataTimeSlider());
            dispatch(setTrendOnly(false));
          }
          dispatch(setSensorThinnedHeaderData(undefined));
          dispatch(fetchSensorThinnedDataDatesList(thinningShips));
          dispatch(setThinnedShips(thinningShips));
        }
      } else {
        dispatch(clearShipStatePastDataTimeSlider());
        dispatch(setSensorThinnedHeaderData(undefined));
        dispatch(fetchSensorThinnedDataDatesList(thinningShips));
        dispatch(setThinnedShips(thinningShips));
      }
    }
  }, [dispatch, thinningShips, confirmedSearchResultItem, thinnedShips, sensorThinnedHeaderData]);

  useEffect(() => {
    if (
      sensorThinnedDataDatesList &&
      sensorThinnedDataDatesList.length > 0 &&
      confirmedSearchResultItem === undefined &&
      currentThinnedConditions
    ) {
      const currentDate = currentThinnedConditions.startDate;
      if (currentDate === undefined || currentDate.length === 0) {
        // 初期表示
        let date: string;
        const dates: string[] = [];
        let machine: Machine | undefined;
        sensorThinnedDataDatesList.forEach((x) => {
          // 初期表示では常に先頭の機械のみ表示
          thinningShips.forEach((ship) => {
            ship.machines.forEach((y) => {
              if (
                y.machineId === x.machineId &&
                y.machineId === currentThinnedConditions.machineId
              ) {
                machine = ship.machines[0];
              }
            });
          });
        });
        sensorThinnedDataDatesList.forEach((x) => {
          if (machine != null && machine.machineId === x.machineId) {
            date = x.dates[x.dates.length - 1];
            const d = new Date(date);
            for (let i = 0; i < 7; i++) {
              if (x.dates.length <= i) break;
              const d2 = new Date(x.dates[x.dates.length - 1 - i]);
              if (d.getTime() - 7 * 24 * 60 * 60 * 1000 <= d2.getTime()) {
                dates.push(x.dates[x.dates.length - 1 - i]);
              }
            }

            if (
              sensorThinnedHeaderData == null ||
              currentThinnedConditions.startDate !== '' ||
              currentThinnedConditions.endDate !== ''
            ) {
              if (
                currentThinnedConditions.startDate !== '' ||
                (selectedTrendChartConfirmedCondition != null &&
                  selectedTrendChartConfirmedCondition.startDate !== '')
              ) {
                const condition = {
                  ...currentThinnedConditions,
                  machineId: machine.machineId,
                  startDate: '',
                  endDate: '',
                } as ThinnedSearchConditions;
                dispatch(setTrendChartConfirmedCondition(undefined));
                dispatch(clearShipStatePastDataTimeSlider());
                dispatch(setCurrentThinnedConditions(condition));
                dispatch(removeChart({ chartId: '0' }));
                dispatch(setTrendOnly(false));
              }
              dispatch(setCommonSensorThinnedDataList(undefined));
              dispatch(setSpeedMagnification(1));
              dispatch(resetMap(true));
              dispatch(fetchSensorThinnedHeaderData(machine, dates));
              setContentSize({ width: -1, height: 0 });
            }
          }
        });
      }
    }
  }, [
    dispatch,
    thinningShips,
    sensorThinnedDataDatesList,
    confirmedSearchResultItem,
    currentThinnedConditions,
    selectedTrendChartConfirmedCondition,
    sensorThinnedHeaderData,
  ]);

  useEffect(() => {
    if (
      sensorThinnedDataDatesList &&
      sensorThinnedDataDatesList.length > 0 &&
      confirmedSearchResultItem === undefined &&
      currentThinnedConditions
    ) {
      const currentDate = currentThinnedConditions.startDate;
      if (currentDate != null && currentDate.length != 0) {
        // カレンダー日付選択時
        const date = currentThinnedConditions.startDate.substring(0, 10);
        const dates: string[] = [];
        const machines: Machine[] = [];
        sensorThinnedDataDatesList.forEach((x) => {
          thinningShips.forEach((ship) => {
            ship.machines.forEach((y) => {
              if (y.machineId === x.machineId && x.dates.includes(date)) {
                dates.push(date);
                machines.push(y);
              }
            });
          });
        });
        const todoMachines: Machine[] = [];
        const targetDate = currentDate.substring(0, 10);
        thinningShips.forEach((x) => {
          const mainMachines = x.machines.filter(
            (y) => y.machineId === currentThinnedConditions.machineId
          );
          if (mainMachines.length > 0) {
            Array.prototype.push.apply(todoMachines, mainMachines);
          } else {
            for (let i = 0; i < x.machines.length; i++) {
              const datesList = sensorThinnedDataDatesList.find(
                (y) => y.machineId == x.machines[i].machineId
              );
              if (datesList?.dates.includes(targetDate)) {
                todoMachines.push(x.machines[i]);
                break;
              }
            }
          }
        });
        if (todoMachines.find((x) => x.machineId === currentThinnedConditions.machineId) != null) {
          dispatch(resetMap(true));
          dispatch(
            fetchCommonSensorThinnedDataList(
              todoMachines,
              currentThinnedConditions.machineId,
              targetDate
            )
          );
        }
      }
    }
  }, [
    dispatch,
    thinningShips,
    sensorThinnedDataDatesList,
    confirmedSearchResultItem,
    currentThinnedConditions,
  ]);

  useEffect(() => {
    if (sensorThinnedHeaderData && commonSensorThinnedDataList == null) {
      if (sensorThinnedHeaderData.dataFormatId !== 402) {
        if (sensorThinnedHeaderData.sensors.vesLatC1 && sensorThinnedHeaderData.sensors.vesLonC1) {
          if (
            sensorThinnedHeaderData.sensors.vesLatC1.data.length === 0 ||
            sensorThinnedHeaderData.sensors.vesLonC1.data.length === 0 ||
            isNaN(sensorThinnedHeaderData.sensors.vesLatC1.data[0]) ||
            isNaN(sensorThinnedHeaderData.sensors.vesLonC1.data[0])
          ) {
            dispatch(setCenter({ lat: NaN, lng: NaN }));
          } else {
            dispatch(
              setCenter({
                lat: sensorThinnedHeaderData.sensors.vesLatC1.data[
                  sensorThinnedHeaderData.sensors.vesLatC1.data.length - 1
                ],
                lng: sensorThinnedHeaderData.sensors.vesLonC1.data[
                  sensorThinnedHeaderData.sensors.vesLonC1.data.length - 1
                ],
              })
            );
          }

          if (config.valueInspectionLog) {
            dumpPastDataInitialSensorList(sensorThinnedHeaderData);
            dumpPastDataInitialMapData(sensorThinnedHeaderData);
          }
        }
      } else {
        if (sensorThinnedHeaderData.sensors.vesLat && sensorThinnedHeaderData.sensors.vesLon) {
          if (
            sensorThinnedHeaderData.sensors.vesLat.data.length === 0 ||
            sensorThinnedHeaderData.sensors.vesLon.data.length === 0 ||
            isNaN(sensorThinnedHeaderData.sensors.vesLat.data[0]) ||
            isNaN(sensorThinnedHeaderData.sensors.vesLon.data[0])
          ) {
            dispatch(setCenter({ lat: NaN, lng: NaN }));
          } else {
            dispatch(
              setCenter({
                lat: sensorThinnedHeaderData.sensors.vesLat.data[
                  sensorThinnedHeaderData.sensors.vesLon.data.length - 1
                ],
                lng: sensorThinnedHeaderData.sensors.vesLon.data[
                  sensorThinnedHeaderData.sensors.vesLon.data.length - 1
                ],
              })
            );
          }

          if (config.valueInspectionLog) {
            dumpPastDataInitialSensorList(sensorThinnedHeaderData);
            dumpPastDataInitialMapData(sensorThinnedHeaderData);
          }
        }
      }


    }
  }, [commonSensorThinnedDataList, dispatch, sensorThinnedHeaderData]);

  // 通常、間引き表示切替時のmimic,trendコンテナの位置調整
  useEffect(() => {
    if (thinnedMode === undefined) {
      setThinnedMode(isThinned);
    } else if (thinnedMode !== isThinned) {
      // モード切替時に加減算する
      if (isThinned) {
        setChartContainerLeft(
          chartContainerLeft - (shipDrawerOpen ? dimens.subDrawer.width : dimens.subDrawer.minWidth)
        );
      } else {
        setChartContainerLeft(
          chartContainerLeft + (shipDrawerOpen ? dimens.subDrawer.width : dimens.subDrawer.minWidth)
        );
        // 初期状態だと地図部分の長さが足りないので加算して全体的に表示されるようにする
        if (_commonSensorData === undefined && contentSize.width !== -1) {
          setContentSize({
            width:
              contentSize.width +
              (shipDrawerOpen ? dimens.subDrawer.width : dimens.subDrawer.minWidth),
            height: contentSize.height,
          });
        }
      }
      setThinnedMode(isThinned);
    }
  }, [
    setChartContainerLeft,
    setThinnedMode,
    setContentSize,
    isThinned,
    thinnedMode,
    chartContainerLeft,
    shipDrawerOpen,
    _commonSensorData,
    contentSize.width,
    contentSize.height,
  ]);
  useEffect(() => {
    if (clear) {
      setClear(false);
    }
  }, [clear]);

  useEffect(() => {
    if (
      selectedTrendChartConfirmedCondition &&
      selectedTrendChartConfirmedCondition.applied === false &&
      selectedTrendChartConfirmedCondition.sensorNames.length > 0 &&
      selectedTrendChartConfirmedCondition.startDate
    ) {
      dispatch(
        updateApplyChartSeriesList({
          chartId: selectedTrendChartConfirmedCondition.chartId,
          machine: selectedTrendChartConfirmedCondition.machine,
          sensorNames: selectedTrendChartConfirmedCondition.sensorNames,
          thinnedData: selectedTrendChartConfirmedCondition.thinnedData,
          startDate: selectedTrendChartConfirmedCondition.startDate,
          endDate: selectedTrendChartConfirmedCondition.endDate,
        })
      );
    }
  }, [dispatch, selectedTrendChartConfirmedCondition]);

  // 表示の条件が変わったら拡大率をリセットするフラグを設定
  useEffect(() => {
    if (selectedTrendChartConfirmedCondition && selectedTrendChartConfirmedCondition.startDate) {
      if (selectCondition) {
        if (
          !(
            selectedTrendChartConfirmedCondition.machine.machineId == selectCondition.machineId &&
            selectedTrendChartConfirmedCondition.startDate == selectCondition.startDate &&
            selectedTrendChartConfirmedCondition.endDate == selectCondition.endDate
          )
        ) {
          setResetRange(true);
          setSelectCondition({
            machineId: selectedTrendChartConfirmedCondition.machine.machineId,
            startDate: selectedTrendChartConfirmedCondition.startDate,
            endDate: selectedTrendChartConfirmedCondition.endDate,
          });
        }
      } else {
        setSelectCondition({
          machineId: selectedTrendChartConfirmedCondition.machine.machineId,
          startDate: selectedTrendChartConfirmedCondition.startDate,
          endDate: selectedTrendChartConfirmedCondition.endDate,
        });
      }
    }
  }, [dispatch, selectedTrendChartConfirmedCondition, selectCondition]);

  useEffect(() => {
    if (_commonSensorData) {
      dispatch(resetMap(false));
    }
  }, [dispatch, _commonSensorData]);

  useEffect(() => {
    if (commonSensorThinnedDataList) {
      dispatch(resetMap(false));
    }
  }, [dispatch, commonSensorThinnedDataList]);

  useEffect(() => {
    if (sensorThinnedHeaderData) {
      dispatch(resetMap(false));
    }
  }, [dispatch, sensorThinnedHeaderData]);

  // グラフの拡大率をリセットするフラグをオフにする
  const handleSetResetRange = () => {
    setResetRange(false);
  };

  // AppLogger.debug('offsetX=' + offsetX);
  // AppLogger.debug('chartContainerLeft=' + chartContainerLeft);
  // AppLogger.debug('contentWidth=' + contentSize.width);

  return (
    <RootContainer hidden={hidden}>
      <SearchConditionsBar
        handleSeachClick={handleSeachClick}
        sensorDataSearchConditions={searchConditions}
        onDateFormat={handleDateFormat}
        onClear={
          !isThinned
            ? handlConfirmedResultItemClear
            : currentThinnedConditions && currentThinnedConditions.startDate.length > 0
              ? handlThinnedConfirmedResultItemClear
              : undefined
        }
        buttonLabelId={isThinned ? msgId.clear : msgId.clearSearchConditions}
        disabled={fetching}
      />
      {isThinned && !clear && (
        <ShipDrawer
          sx={{
            position: 'absolute',
            top: dimens.searchBar.height,
            height: `calc(100% - ${dimens.searchBar.height}px)`,
          }}
          ships={filteredShips != null ? filteredShips : []}
          contractedShipIds={contractedShipIds}
          open={shipDrawerOpen}
          onChangeOpen={handleShipDrawerChangeOpen}
          onExpandedChange={handleShipDrawerExpandedChange}
          onLocation={handleShipLocationLocation}
          onSearchInputChange={handleSeachInputChange}
          showCalendar={true}
          diagnosisListItems={diagnosisListItems}
          sensorThinnedDataDatesList={sensorThinnedDataDatesList}
          onClickDay={handleClickDay}
          currentMachineId={currentThinnedConditions?.machineId}
          currentDate={currentThinnedConditions?.startDate.substring(0, 10)}
          isMultipleShips={isThinned}
          scrollvbarMargin={dimens.shipState.pastDataScrollbar.margin}
          onChangeShip={onChangeShip}
        />
      )}
      {((ships != null && ships.length > 0) ||
        (!(confirmedSearchResultItem != null) && thinningShips != null)) && (
          <ChartDiv
            sx={{
              left: isThinned
                ? shipDrawerOpen
                  ? dimens.subDrawer.width
                  : dimens.subDrawer.minWidth
                : 0,
              width: isThinned
                ? shipDrawerOpen
                  ? `calc(100% - ${dimens.subDrawer.width}px)`
                  : `calc(100% - ${dimens.subDrawer.minWidth}px)`
                : '100%',
            }}
            ref={containerRef}
          >
            <MapContainer
              width={commonSensorData ? chartContainerLeft : contentSize.width}
              focusShip={isThinned ? currentThinnedConditions?.ship : undefined}
              machineIdtoShipName={machineIdtoShipName}
              commonSensorData={commonSensorData}
              sensorThinnedHeaderDataList={thinnedHeaderDatas}
              isThinned={isThinned}
              onCenterClick={isThinned ? undefined : handleCenterClick}
            />
            {commonSensorData && ships != null && (
              <React.Fragment>
                {/** commonSensorDataの値によりsetRangeを実行させるためカレンダー画面と条件設定後の画面を分割 */}
                {/** 条件設定画面 */}
                {!trendOnly &&
                  contentSize.height > dimens.timeSlider.displayMinHeight &&
                  !isThinned && (
                    <TimeSlider
                      sx={{
                        top: `calc(100% - ${dimens.timeSlider.height + 30}px)`,
                        left: 0,
                        position: 'absolute',
                        overflow: 'hidden',
                        display: isThinned ? 'none' : undefined,
                      }}
                      startDate={
                        confirmedSearchResultItem
                          ? confirmedSearchResultItem.searchResultItem.startDate
                          : ''
                      }
                      endDate={
                        confirmedSearchResultItem
                          ? confirmedSearchResultItem.searchResultItem.endDate
                          : ''
                      }
                      commonSensorData={_commonSensorData}
                    />
                  )}
                {/** カレンダー画面 */}
                {!trendOnly &&
                  contentSize.height > dimens.timeSlider.displayMinHeight &&
                  isThinned && (
                    <TimeSlider
                      sx={{
                        top: `calc(100% - ${dimens.timeSlider.height + 30}px)`,
                        left: 0,
                        position: 'absolute',
                        overflow: 'hidden',
                        display: isThinned ? undefined : 'none',
                      }}
                      startDate={currentThinnedConditions ? currentThinnedConditions.startDate : ''}
                      endDate={currentThinnedConditions ? currentThinnedConditions.endDate : ''}
                      commonSensorData={thinnedCommonSensorData}
                    />
                  )}
                {!trendOnly && (
                  <Splitter
                    direction="vertical"
                    offsetX={offsetX}
                    onPositionChanged={handlePositionChanged}
                    position={chartContainerLeft}
                    minPosition={minPosition}
                    offsetY={0}
                  />
                )}
                <ChartContainer
                  ships={
                    confirmedSearchResultItem
                      ? ships
                      : currentThinnedConditions
                        ? [currentThinnedConditions.ship]
                        : []
                  }
                  startDate={
                    confirmedSearchResultItem
                      ? confirmedSearchResultItem.searchResultItem.startDate
                      : currentThinnedConditions
                        ? searchConditions.startDate
                        : ''
                  }
                  endDate={
                    confirmedSearchResultItem
                      ? confirmedSearchResultItem.searchResultItem.endDate
                      : currentThinnedConditions
                        ? searchConditions.endDate
                        : ''
                  }
                  trendOnly={trendOnly}
                  left={trendOnly ? 0 : chartContainerLeft + dimens.splitter.width}
                  width={trendOnly ? contentSize.width : chartContainerWidth}
                  height={contentSize.height}
                  thinnedData={!confirmedSearchResultItem}
                  commonSensorData={commonSensorData}
                  condition={selectedTrendChartConfirmedCondition}
                  resetRange={resetRange}
                  setResetRange={handleSetResetRange}
                />
              </React.Fragment>
            )}
          </ChartDiv>
        )}
      <FadeLoading loading={fetching} />
      {fetchError != null && <ApiErrorDialog error={fetchError} onClose={handleFetchError} />}
      <SlideFragmentContainer open={searchOpen}>
        <SearchFragment
          machineId={
            selectedTrendChartConfirmedCondition &&
            selectedTrendChartConfirmedCondition.machine.machineId
          }
          condition={selectedTrendChartConfirmedCondition}
        />
      </SlideFragmentContainer>
    </RootContainer>
  );
}
