import React from 'react';
import { useIntl } from 'react-intl';
import { SensorValue } from './MimicContainer';
import colors from 'resources/colors';
import { styled, SxProps } from '@mui/material';
import constants from 'resources/constants';
import { roundValue, roundValueToString } from 'utils/misc';

const Meg01_L = 'MegSpdP1';
const Meg01_R = 'MegSpdS1';
const Meg03_L = 'MegTCSpdP1';
const Meg03_R = 'MegTCSpdS1';
const Meg04_L = 'MegTCbstpressP1COM';
const Meg04_R = 'MegTCbstpressS1COM';
const Meg04_L2 = 'MegTCboostpressP1RIO';
const Meg04_R2 = 'MegTCboostpressS1RIO';

const Meg01_402L = 'EngSpdP';
const Meg01_402R = 'EngSpdS';
const Meg03_402L = 'EngTcSpdP';
const Meg03_402R = 'EngTcSpdS';
const Meg04_402L = 'EngChargedAirPressP';
const Meg04_402R = 'EngChargedAirPressS';

const RootDiv = styled('div')({
  position: 'absolute',
  fontSize: 0,
  padding: '3px',
  border: `1px solid ${colors.chart.mimic.border}`,
});

const ContentsDiv = styled('div')({
  width: '100%',
  height: '100%',
  position: 'relative',
  padding: '3px',
  background: colors.chart.mimic.background,
});

interface MegInfoPanelProps {
  sx?: SxProps;
  values: SensorValue[];
  top: number;
  left: number;
  rateV?: number;
  rateH?: number;
  dataFormatId?: number;
}

interface MegItemProps {
  value: SensorValue;
  top: number;
  left: number;
  rateV?: number;
  rateH?: number;
}

function MegItem(props: MegItemProps) {
  const intl = useIntl();
  const valueProp = props.value;
  const { sensor, group, value } = valueProp;
  const rateV = props.rateV ? props.rateV : 1;
  const rateH = props.rateH ? props.rateH : 1;
  const rowSize = 30 * rateV;
  const sensorTitle = intl.formatMessage({ id: constants.labelPrefix.mimic + sensor.sensorName });
  const sensorUnit = group.displayUnit;
  const styleBasePanel: React.CSSProperties = {
    top: props.top * rateV,
    left: props.left * rateH,
    width: '100%',
    height: rowSize,
    position: 'absolute',
    fontSize: 0,
    padding: '0 3px',
    float: 'left',
    borderBottom: '1px solid black',
  };

  const styleTitle: React.CSSProperties = {
    width: '65%',
    height: rowSize,
    position: 'relative',
    fontSize: 12,
    color: 'white',
    textAlign: 'left',
    lineHeight: `${rowSize}px`,
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    userSelect: 'none',
    float: 'left',
  };

  const roundedvalue =
    isNaN(value) || value === null ? undefined : roundValue(value, sensor.displayRoundingPosition);
  const isDanger =
    sensor.warningUpperLimit != null && roundedvalue != null
      ? roundedvalue >= sensor.warningUpperLimit
      : false;

  const styleValue: React.CSSProperties = {
    width: '35%',
    float: 'right',
    height: rowSize,
    lineHeight: `${rowSize}px`,
    color: isDanger ? colors.chart.mimic.danger : colors.chart.mimic.value,
    fontSize: 13,
    textAlign: 'right',
    userSelect: 'none',
  };

  const valueText = roundValueToString(roundedvalue, sensor.displayRoundingPosition, true);
  const unit = !(sensorUnit === undefined || sensorUnit === null || sensorUnit.length === 0)
    ? '[' + sensorUnit + ']'
    : '';

  // AppLogger.info(
  //   '[*MegInfo] ' +
  //     sensor.sensorName +
  //     ': value=' +
  //     value +
  //     ', isDanger=' +
  //     isDanger +
  //     ', valueText=' +
  //     valueText +
  //     ', warningUpperLimit=' +
  //     sensor.warningUpperLimit
  // );

  return (
    <div style={styleBasePanel}>
      <div style={styleTitle}>{sensorTitle + unit}</div>
      <div style={styleValue}>{valueText}</div>
    </div>
  );
}

export function MegInfoPanel(props: MegInfoPanelProps): JSX.Element {
  const { sx, values, dataFormatId } = props;
  const rateV = props.rateV ? props.rateV : 1;
  const rateH = props.rateH ? props.rateH : 1;
  const rowSize = 30 * rateV;
  const basePanelHeight = 96 * rateV;
  const basePanelWidth = 180 * rateH;

  if (values.length === 0) {
    return (
      <RootDiv
        sx={{
          top: props.top * rateV,
          left: props.left * rateH,
          width: basePanelWidth,
          height: basePanelHeight,
          ...sx,
        }}
      ></RootDiv>
    );
  }
  let index3 = 1;
  let index4 = 2;
  if (dataFormatId !== 402) {
    if (
      !values.some((item) => item.sensor.sensorName === Meg01_L || item.sensor.sensorName === Meg01_R)
    ) {
      index3--;
      index4--;
    }
    if (
      !values.some((item) => item.sensor.sensorName === Meg03_L || item.sensor.sensorName === Meg03_R)
    ) {
      index4--;
    }
  } else {
    if (
      !values.some((item) => item.sensor.sensorName === Meg01_402L || item.sensor.sensorName === Meg01_402R)
    ) {
      index3--;
      index4--;
    }
    if (
      !values.some((item) => item.sensor.sensorName === Meg03_402L || item.sensor.sensorName === Meg03_402R)
    ) {
      index4--;
    }
  }

  // 主機回転数、T/C回転数、T/C過給圧
  return (
    <RootDiv
      sx={{
        top: props.top * rateV,
        left: props.left * rateH,
        width: basePanelWidth,
        height: basePanelHeight,
        ...sx,
      }}
    >
      <ContentsDiv>
        {values.map((item) => {
          const { sensor, value } = item;
          const key = sensor.sensorName + '_' + value;
          switch (sensor.sensorName) {
            case Meg01_L:
            case Meg01_R:
            case Meg01_402L:
            case Meg01_402R:
              return (
                <MegItem
                  key={key}
                  top={rowSize * 0}
                  left={0}
                  value={item}
                  rateH={rateH}
                  rateV={rateV}
                />
              );
            case Meg03_L:
            case Meg03_R:
            case Meg03_402L:
            case Meg03_402R:
              return (
                <MegItem
                  key={key}
                  top={rowSize * index3}
                  left={0}
                  value={item}
                  rateH={rateH}
                  rateV={rateV}
                />
              );
            case Meg04_L:
            case Meg04_R:
            case Meg04_L2:
            case Meg04_R2:
            case Meg04_402L:
            case Meg04_402R:
              return (
                <MegItem
                  key={key}
                  top={rowSize * index4}
                  left={0}
                  value={item}
                  rateH={rateH}
                  rateV={rateV}
                />
              );
            default:
              return <React.Fragment key={key} />;
          }
        })}
      </ContentsDiv>
    </RootDiv>
  );
}
