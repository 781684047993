import React from 'react';
import dimens from 'resources/dimens';
import { MenuItem } from './MenuItem';
import { ContentsHeader } from 'components/ContentsHeader';
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  CloseIconButton,
  SubDrawerBox,
} from 'components/subDrawer';
import { CustomScrollBars } from 'components/CustomScrollBars';

interface MenuDrawerProps {
  menus: string[];
  selectedMenu: number;
  open?: boolean;
  onSelectMenu: (index: number) => void;
  onChangeOpen?: (open: boolean) => void;
}

export function MenuDrawer(props: MenuDrawerProps): JSX.Element {
  const { menus, selectedMenu, open, onSelectMenu, onChangeOpen } = props;

  return (
    <SubDrawerBox
      sx={{
        width: open ? dimens.subDrawer.width : dimens.subDrawer.minWidth,
        minWidth: open ? dimens.subDrawer.width : dimens.subDrawer.minWidth,
      }}
    >
      {open && (
        <React.Fragment>
          <ContentsHeader title="">
            {onChangeOpen != null && (
              <CloseIconButton onClick={() => onChangeOpen(false)}>
                <ChevronLeftIcon />
              </CloseIconButton>
            )}
          </ContentsHeader>
          <CustomScrollBars>
            {menus.length > 0 &&
              menus.map((menu, index) => {
                return (
                  <MenuItem
                    key={'menu' + index}
                    index={index}
                    selected={index === selectedMenu}
                    title={menu}
                    onItemClicked={(index) => onSelectMenu(index)}
                  />
                );
              })}
          </CustomScrollBars>
        </React.Fragment>
      )}
      {onChangeOpen != null && !open && (
        <React.Fragment>
          <ContentsHeader title={undefined}>
            {onChangeOpen != null && (
              <CloseIconButton onClick={() => onChangeOpen(true)}>
                <ChevronRightIcon />
              </CloseIconButton>
            )}
          </ContentsHeader>
        </React.Fragment>
      )}
    </SubDrawerBox>
  );
}

MenuDrawer.defaultProps = {
  open: true,
};
