import React from 'react';
import { styled } from '@mui/material';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import colors from 'resources/colors';

export const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))({
  padding: 0,
  border: '0px solid rgba(0, 0, 0, .125)',
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
  '&$expanded': {
    margin: 'auto',
  },
});

export const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary {...props} />
))({
  padding: 0,
  backgroundColor: colors.accordion.header,
  marginBottom: 0,
  minHeight: 32,
  '&$expanded': {
    minHeight: 32,
    padding: 0,
  },
  borderTop: `1px solid ${colors.accordion.border}`,
  borderBottom: `1px solid ${colors.accordion.border}`,
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    margin: '0px 0',
    '&$expanded': {
      margin: '0px 0',
    },
    overflow: 'hidden',
  },
  expanded: {
    margin: '0px 0',
  },
});

export const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(0),
  margin: theme.spacing(0),
  alignItems: 'center',
}));
