import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getRealtimeAisDataListAsync } from 'api/maricoApiAisData';
import { RootState } from 'app/rootReducer';
import { AppThunk } from 'app/store';
import { useSelector } from 'react-redux';
import { AisData } from 'models/data';
import { ErrorResult, getErrorResult } from 'models/error';
import { Ship } from 'models/ships';
import { MarineWeatherBundle2 } from 'models/marineWeather';
import { getMarineWeatherBundleAsync } from 'api/maricoApiMarineWeather';
import dayjs from 'dayjs';
import { doFilterShips } from 'utils/misc';

/**
 * RealtimeAisDataState インターフェース
 */
interface RealtimeAisDataState {
  /** 船舶リスト */
  ships: Ship[] | undefined;
  /** フィルター済み船舶リスト */
  filteredShips: Ship[];
  /** フィルター船舶名 */
  filterShipName: string;
  /** 船舶開閉状態リスト */
  contractedShipIds: string[];
  /** データ取得中状態 */
  fetching: boolean;
  /** データ取得エラー */
  fetchError: ErrorResult | undefined;
  /** 初回取得かどうか */
  isFirstTime: boolean;
  /** AISデータ */
  aisDataList: AisData[] | undefined;
  /** 気象海象データ */
  marineWeatherBundle: MarineWeatherBundle2 | undefined;
}

/**
 * RealtimeAisData の初期状態
 */
const initialState: RealtimeAisDataState = {
  ships: undefined,
  filteredShips: [],
  filterShipName: '',
  contractedShipIds: [],
  fetching: false,
  fetchError: undefined,
  isFirstTime: true,
  aisDataList: undefined,
  marineWeatherBundle: undefined,
};

export const realtimeAisData = createSlice({
  name: 'shipStateRealtimeAisData',
  initialState,
  reducers: {
    /**
     * 選択済み機械から船舶リストを設定する。
     */
    setShips: (state, { payload }: PayloadAction<Ship[]>) => {
      const tmpShips = payload;
      const ships: Ship[] = [];
      tmpShips.forEach((tmpShips) => {
        const machines = tmpShips.machines;
        if (machines.length > 0) {
          ships.push({
            shipId: tmpShips.shipId,
            name: tmpShips.name,
            propulsionSerialNumbers: tmpShips.propulsionSerialNumbers,
            machines: machines.map((commonMachine) => {
              return {
                machineId: commonMachine.machineId,
                name: commonMachine.name,
                serialNumbers: commonMachine.serialNumbers,
                dataFormatId: commonMachine.dataFormatId,
                sensorGroups: commonMachine.sensorGroups.filter((x) =>
                  x.sensors.find(
                    (y) =>
                      y.isVisualization &&
                      y.displayLowerLimit != null &&
                      y.displayUpperLimit != null
                  )
                ),
                checked: false,
              };
            }),
            createdAt: tmpShips.createdAt,
            updatedAt: tmpShips.updatedAt,
            checked: false,
            propulsionSerialNumbersText: tmpShips.propulsionSerialNumbers.join(', '),
            machineNamesText: tmpShips.machines.map((x) => x.name).join(', '),
          });
        }
      });

      state.ships = ships;
      state.filteredShips = doFilterShips(state.ships, state.filterShipName);
      const shipIds = ships.map((x) => x.shipId);
      state.contractedShipIds = state.contractedShipIds.filter((x) => shipIds.includes(x));
    },
    /**
     * フィルター船舶名を設定する。
     */
    setFilterShipName: (state, action: PayloadAction<string>) => {
      state.filterShipName = action.payload;
      if (state.ships != null) {
        state.filteredShips = doFilterShips(state.ships, state.filterShipName);
      }
    },
    /**
     * 船舶リストをクリアする。
     */
    clearShips: (state) => {
      state.ships = undefined;
      state.filterShipName = '';
      state.filteredShips = [];
      state.contractedShipIds = [];
    },
    /**
     * 船舶リストの指定した船舶を開閉状態を変更する。
     */
    setContractedShipId: (state, action: PayloadAction<{ shipId: string; expanded: boolean }>) => {
      if (action.payload.expanded) {
        state.contractedShipIds = state.contractedShipIds.filter(
          (x) => x !== action.payload.shipId
        );
      } else {
        state.contractedShipIds.push(action.payload.shipId);
      }
    },
    /**
     * 取得の開始を設定する。
     */
    startFetch: (state, action: PayloadAction<boolean>) => {
      state.fetching = action.payload;
    },
    /**
     * AISデータリストを設定する。
     */
    setAisDataList: (state, { payload }: PayloadAction<AisData[]>) => {
      state.isFirstTime = state.aisDataList === undefined;
      state.aisDataList = payload;
      state.fetching = false;
    },
    /**
     * 気象海象データとカラーマップを設定する。
     */
    setMarineWeatherBundle: (state, { payload }: PayloadAction<MarineWeatherBundle2>) => {
      state.marineWeatherBundle = payload;
    },
    /**
     * 取得エラーを設定する。
     */
    setFetchError: (state, action: PayloadAction<ErrorResult | undefined>) => {
      state.fetchError = action.payload;
      state.fetching = false;
    },
    /**
     * shipStateRealtimeAisDataをクリアする。
     */
    clearShipStateRealtimeAisData: (state) => {
      state.ships = initialState.ships;
      state.filteredShips = initialState.filteredShips;
      state.filterShipName = initialState.filterShipName;
      state.contractedShipIds = initialState.contractedShipIds;
      state.fetching = initialState.fetching;
      state.fetchError = initialState.fetchError;
      state.isFirstTime = initialState.isFirstTime;
      state.aisDataList = initialState.aisDataList;
      state.marineWeatherBundle = initialState.marineWeatherBundle;
    },
  },
});

export const {
  setShips,
  setFilterShipName,
  clearShips,
  setContractedShipId,
  setFetchError,
  clearShipStateRealtimeAisData,
} = realtimeAisData.actions;

/**
 * リアルタイムAISデータを取得する。
 */
export const fetchRealtimeAisDataList =
  (ships: Ship[]): AppThunk =>
  async (dispatch) => {
    dispatch(realtimeAisData.actions.startFetch(true));
    try {
      const shipIds = ships.map((x) => {
        return x.shipId;
      });
      const aisData = await getRealtimeAisDataListAsync(shipIds);
      dispatch(realtimeAisData.actions.setAisDataList(aisData));
    } catch (error) {
      dispatch(realtimeAisData.actions.setFetchError(getErrorResult(error)));
    }
  };

/**
 * 気象海象データとカラーマップを取得する。
 */
export const fetchMarineWeatherBundle = (): AppThunk => async (dispatch) => {
  try {
    const nowDate = dayjs().utc().format('YYYY-MM-DDTHH:00:00Z');
    const dateAndTime = nowDate.split('T');
    const date = dateAndTime[0];
    const hour = parseInt(dateAndTime[1].substring(0, 2), 10);
    const bundle = await getMarineWeatherBundleAsync(date + '_' + hour);
    if (bundle != null) {
      dispatch(realtimeAisData.actions.setMarineWeatherBundle(bundle));
    }
  } catch (error) {
    const errorResult = getErrorResult(error);
    // 404ならエラーにしない
    if (errorResult.status !== 404) {
      dispatch(realtimeAisData.actions.setFetchError(errorResult));
    }
  }
};

const realtimeAisDataState = (state: RootState) => state.realtimeAisData;
const selectFilteredShips = createSelector(realtimeAisDataState, (x) => x.filteredShips);
const selectFilterShipName = createSelector(realtimeAisDataState, (x) => x.filterShipName);
const selectContractedShipIds = createSelector(realtimeAisDataState, (x) => x.contractedShipIds);
const selectFetching = createSelector(realtimeAisDataState, (x) => x.fetching);
const selectFetchError = createSelector(realtimeAisDataState, (x) => x.fetchError);
const selectIsFirsttime = createSelector(realtimeAisDataState, (x) => x.isFirstTime);
const selectAisDataList = createSelector(realtimeAisDataState, (x) => x.aisDataList);
const selectMarineWeatherBundle = createSelector(
  realtimeAisDataState,
  (x) => x.marineWeatherBundle
);

export const useRealtimeAisDataFilteredShips = () => useSelector(selectFilteredShips);
export const useRealtimeAisDataFilterShipName = () => useSelector(selectFilterShipName);
export const useRealtimeAisDataContractedShipIds = () => useSelector(selectContractedShipIds);
export const useRealtimeAisDataFetching = () => useSelector(selectFetching);
export const useRealtimeAisDataFetchError = () => useSelector(selectFetchError);
export const useRealtimeAisDataIsFirsttime = () => useSelector(selectIsFirsttime);
export const useRealtimeAisDataList = () => useSelector(selectAisDataList);
export const useRealtimeAisMarineWeatherBundle = () => useSelector(selectMarineWeatherBundle);

export default realtimeAisData.reducer;
