import { DiagnosisStatus } from './diagnosis';
import { Sensor, SensorGroup } from './ships';

/** ストレージアクセスレベル */
export declare type StorageAccessLevel = 'public' | 'protected' | 'private';

/** センサー位置：左舷 */
export const PositionPort = 'Port';

/** センサー位置：左舷 */
export const PositionStarboard = 'Starboard';

/**
 * センサーデータリストモデル
 */
export interface SensorDataList {
  /** 機械ID */
  machineId: number;
  /** 開始日時 */
  logdate: number[];
  /** 1サンプルあたりのミリ秒 */
  samplePerMilliSeconds: number;
  /** センサーデータリスト */
  sensors: { [sensorName: string]: SensorData };
}

/**
 * センサーデータモデル
 */
export interface SensorData {
  /** センサーグループ情報 */
  sensorGroup: SensorGroup;
  /** センサー情報 */
  sensor: Sensor;
  /** データ */
  data: number[];
  /** 最大値 */
  max: number;
  /** 最小値 */
  min: number;
  /** センサー診断ステータス */
  diagnosisStatus: DiagnosisStatus | undefined;
}

/**
 * 共通センサーデータモデル
 */
export interface CommonSensorData {
  /** 機械ID */
  machineId: number;
  dataFormatId: number;
  /** 開始日時 */
  logdate: number[];
  /** 1サンプルあたりのミリ秒 */
  samplePerMilliSeconds: number;
  /** センサーデータリスト */
  sensors: {
    /** 左舷 主機 回転数 */
    megSpdP1: SensorData | undefined;
    /** 右舷 主機 回転数 */
    megSpdS1: SensorData | undefined;
    /** 左舷 主機 過給機回転数 */
    megTCSpdP1: SensorData | undefined;
    /** 右舷 主機 過給機回転数 */
    megTCSpdS1: SensorData | undefined;
    /** 左舷 主機 過給機ブースト圧 */
    megTCbstpressP1: SensorData | undefined;
    /** 右舷 主機 過給機ブースト圧 */
    megTCbstpressS1: SensorData | undefined;
    /** 左舷 主機 潤滑油圧力C2 */
    megLOpressP1: SensorData | undefined;
    /** 右舷 主機 潤滑油圧力C2 */
    megLOpressS1: SensorData | undefined;
    /** 左舷 主機 潤滑油温度C2 */
    megLOTmpP1: SensorData | undefined;
    /** 右舷 主機 潤滑油温度C2 */
    megLOTmpS1: SensorData | undefined;
    /** 左舷 主機 燃料ポンプラックレベル */
    megFuelRackLvP1: SensorData | undefined;
    /** 右舷 主機 燃料ポンプラックレベル */
    megFuelRackLvS1: SensorData | undefined;
    /** 左舷 主機 出力馬力A */
    megHpP1: SensorData | undefined;
    /** 右舷 主機 出力馬力A */
    megHpS1: SensorData | undefined;
    /** 左舷 主機 排気温度1 */
    megNo1EXTGASTmpP1: SensorData | undefined;
    /** 右舷 主機 排気温度1 */
    megNo1EXTGASTmpS1: SensorData | undefined;
    /** 左舷 主機 排気温度2 */
    megNo2EXTGASTmpP1: SensorData | undefined;
    /** 右舷 主機 排気温度2 */
    megNo2EXTGASTmpS1: SensorData | undefined;
    /** 左舷 主機 排気温度3 */
    megNo3EXTGASTmpP1: SensorData | undefined;
    /** 右舷 主機 排気温度3 */
    megNo3EXTGASTmpS1: SensorData | undefined;
    /** 左舷 主機 排気温度4 */
    megNo4EXTGASTmpP1: SensorData | undefined;
    /** 右舷 主機 排気温度4 */
    megNo4EXTGASTmpS1: SensorData | undefined;
    /** 左舷 主機 排気温度5 */
    megNo5EXTGASTmpP1: SensorData | undefined;
    /** 右舷 主機 排気温度5 */
    megNo5EXTGASTmpS1: SensorData | undefined;
    /** 左舷 主機 排気温度6 */
    megNo6EXTGASTmpP1: SensorData | undefined;
    /** 右舷 主機 排気温度6 */
    megNo6EXTGASTmpS1: SensorData | undefined;
    /** 左舷 主機 排気温度7 */
    megNo7EXTGASTmpP1: SensorData | undefined;
    /** 右舷 主機 排気温度7 */
    megNo7EXTGASTmpS1: SensorData | undefined;
    /** 左舷 主機 排気温度8 */
    megNo8EXTGASTmpP1: SensorData | undefined;
    /** 右舷 主機 排気温度8 */
    megNo8EXTGASTmpS1: SensorData | undefined;
    /** 左舷 主機 排気温度9 */
    megNo9EXTGASTmpP1: SensorData | undefined;
    /** 右舷 主機 排気温度9 */
    megNo9EXTGASTmpS1: SensorData | undefined;
    /** 左舷 主機 吸気温度 */
    megInletAirTmpP1: SensorData | undefined;
    /** 右舷 主機 吸気温度 */
    megInletAirTmpS1: SensorData | undefined;

    /** 左舷 レックスペラ 潤滑油温度 */
    rexLOTmpP1: SensorData | undefined;
    /** 右舷 レックスペラ 潤滑油温度 */
    rexLOTmpS1: SensorData | undefined;
    /** 左舷 レックスペラ 潤滑油圧 */
    rexLOPressP1: SensorData | undefined;
    /** 右舷 レックスペラ 潤滑油圧 */
    rexLOPressS1: SensorData | undefined;
    /** 左舷 レックスペラ 旋回角ハンドルオーダC1 */
    rexStrAngOrderP1: SensorData | undefined;
    /** 右舷 レックスペラ 旋回角ハンドルオーダC1 */
    rexStrAngOrderS1: SensorData | undefined;
    /** 左舷 レックスペラ 旋回角フィードバックC1 */
    rexStrAngFBP1: SensorData | undefined;
    /** 右舷 レックスペラ 旋回角フィードバックC1 */
    rexStrAngFBS1: SensorData | undefined;
    /** 左舷 レックスペラ CPP翼角オーダC1 */
    rexCPPBladeAngOrderP1: SensorData | undefined;
    /** 右舷 レックスペラ CPP翼角オーダC1 */
    rexCPPBladeAngOrderS1: SensorData | undefined;
    /** 左舷 レックスペラ CPP翼角フィードバックC1 */
    rexCPPBladeAngFBP1: SensorData | undefined;
    /** 右舷 レックスペラ CPP翼角フィードバックC1 */
    rexCPPBladeAngFBS1: SensorData | undefined;
    /** 左舷 レックスペラ クラッチ嵌完了(F/B)C1 */
    rexClutchengagedFBP1: SensorData | undefined;
    /** 右舷 レックスペラ クラッチ嵌完了(F/B)C1 */
    rexClutchengagedFBS1: SensorData | undefined;

    /** 船体 緯度 */
    vesLatC1: SensorData | undefined;
    /** 船体 経度 */
    vesLonC1: SensorData | undefined;
    /** 船速(SOG) */
    vesSpdSOGC1: SensorData | undefined;
    /** 針路(真方位) */
    vesCourseTrueDirC1: SensorData | undefined;
    /** 風向 */
    vesWindDirRelC1: SensorData | undefined;
    /** 風速 */
    vesWindSpdRelC1: SensorData | undefined;

    engSpdP: SensorData | undefined;
    engFuelRackLvP: SensorData | undefined;
    engSftHpP: SensorData | undefined;
    engTcSpdP: SensorData | undefined;
    engSpdS: SensorData | undefined;
    engFuelRackLvS: SensorData | undefined;
    engSftHpS: SensorData | undefined;
    engTcSpdS: SensorData | undefined;
    rexStrAngOrderP: SensorData | undefined;
    rexStrAngOrderS: SensorData | undefined;
    rexStrAngFbP: SensorData | undefined;
    rexStrAngFbS: SensorData | undefined;
    rexClutchEngFbP: SensorData | undefined;
    rexClutchEngFbS: SensorData | undefined;
    engChargedAirPressP: SensorData | undefined;
    engChargedAirPressS: SensorData | undefined;
    engLoPressP: SensorData | undefined;
    engLoPressS: SensorData | undefined;
    engLoInTempP: SensorData | undefined;
    engLoInTempS: SensorData | undefined;
    engNo1ExtGasTempP: SensorData | undefined;
    engNo1ExtGasTempS: SensorData | undefined;
    engNo2ExtGasTempP: SensorData | undefined;
    engNo2ExtGasTempS: SensorData | undefined;
    engNo3ExtGasTempP: SensorData | undefined;
    engNo3ExtGasTempS: SensorData | undefined;
    engNo4ExtGasTempP: SensorData | undefined;
    engNo4ExtGasTempS: SensorData | undefined;
    engNo5ExtGasTempP: SensorData | undefined;
    engNo5ExtGasTempS: SensorData | undefined;
    engNo6ExtGasTempP: SensorData | undefined;
    engNo6ExtGasTempS: SensorData | undefined;
    engTcInTemp145P: SensorData | undefined;
    engTcInTemp145S: SensorData | undefined;
    engTcInTemp236P: SensorData | undefined;
    engTcInTemp236S: SensorData | undefined;
    engTcOutTempP: SensorData | undefined;
    engTcOutTempS: SensorData | undefined;
    rexLOPressP: SensorData | undefined;
    rexLoPressS: SensorData | undefined;
    rexLoTempP: SensorData | undefined;
    rexLoTempS: SensorData | undefined;
    engChargedAirTempP: SensorData | undefined;
    engChargedAirTempS: SensorData | undefined;

    vesLat: SensorData | undefined;
    vesLon: SensorData | undefined;
    vesSog: SensorData | undefined;
    vesHeading: SensorData | undefined;
    vesWindDirMagnitudMagDeg: SensorData | undefined;
    vesWindSpd: SensorData | undefined;

    [key: string]: SensorData | undefined;
  };
}

/**
 * 間引きセンサーヘッダーデータモデル
 */
export interface SensorThinnedHeaderData {
  /** 機械ID */
  machineId: number;
  dataFormatId: number;
  /** 最終データ日時 */
  lastLogDate: number;
  /** データ日時 */
  logdate: number[];
  /** センサーデータリスト */
  sensors: {
    /** 針路(真方位) */
    vesCourseTrueDirC1: SensorData | undefined;
    /** 風向 */
    vesWindDirRelC1: SensorData | undefined;
    /** 風速 */
    vesWindSpdRelC1: SensorData | undefined;
    /** 船体 緯度 */
    vesLatC1: SensorData | undefined;
    /** 船体 経度 */
    vesLonC1: SensorData | undefined;
    /** 船速(SOG) */
    vesSpdSOGC1: SensorData | undefined;

    vesLat: SensorData | undefined;
    vesLon: SensorData | undefined;
    vesSog: SensorData | undefined;
    vesHeading: SensorData | undefined;
    vesWindDirMagnitudMagDeg: SensorData | undefined;
    vesWindSpd: SensorData | undefined;

    [key: string]: SensorData | undefined;
  };
}

/**
 * 船舶航跡データモデル
 */
export interface ShipWaveData {
  /** 機械ID */
  machineId: number;
  /** センサーデータリスト */
  sensors: {
    /** 船体 緯度 */
    vesLatC1: SensorData | undefined;
    /** 船体 経度 */
    vesLonC1: SensorData | undefined;
    /** 船体 船速 */
    vesSpdSOGC1: SensorData | undefined;

    vesLat: SensorData | undefined;
    vesLon: SensorData | undefined;
    vesSog: SensorData | undefined;

    [key: string]: SensorData | undefined;
  };
}

/**
 * 間引きセンサーデータ日付リストモデル
 */
export interface SensorThinnedDataDates {
  /** 機械ID */
  machineId: number;
  /** 日付リスト */
  dates: string[];
}

/**
 * AISデータモデル
 */
export interface AisData {
  /** 船舶ID */
  shipId: string;
  /** 緯度 */
  latitude: number;
  /** 経度 */
  longitude: number;
  /** 船速[knots] */
  speed: number;
  /** 船首方位角[degrees] */
  heading: number;
  /** 進路[degrees] */
  course: number;
  /** ステータス(0:運航中、0以外:非運航中) */
  status: number;
  /** 最新のデータに含まれていたかどうかのフラグ */
  isIncludedInLastResponse: boolean;
  /** 対象船舶の位置がMarineTrafficによって記録された日時(UTC) */
  timestamp: string;
  /** 最終更新日時(UTC) */
  updatedAt: string;
}

/**
 * データ生成要求モデル
 */
export interface DataRequest {
  /** データ生成要求ID */
  dataRequestId: number;
}

/**
 * データ生成結果要求モデル
 */
export interface DataRequestKey {
  /** データ生成要求ID */
  key: string;
  level: StorageAccessLevel;
  identityId: string;
}

/**
 * データ生成結果要求結果モデル
 */
export interface DataRequestResult {
  /** 処理状態 */
  status: 'searching' | 'completed' | 'failed';
  /** 処理結果情報 */
  result: DataRequestKey;
}
