import React from 'react';
import colors from 'resources/colors';
import { Box, styled, Typography } from '@mui/material';
import { theme } from 'resources/theme';
import dimens from 'resources/dimens';
import { ToolbarBackButton } from './ToolbarBackButton';

const RootBox = styled(Box)({
  width: '100%',
  paddingLeft: 0,
  paddingRight: 0,
  height: dimens.searchBar.height,
  background: colors.subDrawer.background,
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'start',
  flexWrap: 'nowrap',
  overflow: 'hidden',
  boxShadow:
    '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
});

interface NavigationBackBarProps {
  title: string;
  handleBackClick: () => void;
  canBack: boolean;
}

export function NavigationBackBar(props: NavigationBackBarProps): JSX.Element {
  const { title, handleBackClick, canBack } = props;

  return (
    <RootBox>
      {canBack && <ToolbarBackButton onClick={() => handleBackClick()} />}
      <Typography variant="h5" noWrap sx={{ marginLeft: theme.spacing(1) }}>
        {title}
      </Typography>
    </RootBox>
  );
}
