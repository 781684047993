import React from 'react';
import { useIntl } from 'react-intl';
import Plotly, { Layout, LayoutAxis } from 'plotly.js';
import createPlotlyComponent from 'react-plotly.js/factory';
import colors from 'resources/colors';
import { Box, styled, SxProps } from '@mui/material';
import msgId from 'resources/intl';

const Plot = createPlotlyComponent(Plotly);
const axisFontColor = colors.chart.analysis.rexPropellerInvolvement.axisFontColor;
const gridColor = colors.chart.analysis.rexPropellerInvolvement.gridColor;
const graphHeightFooter = 50;

const PlotPanelDiv = styled('div')({
  position: 'relative',
  width: '50%',
  padding: 0,
  float: 'left',
  borderLeft: `1px solid ${colors.chart.legend.border}`,
  borderBottom: `1px solid ${colors.chart.legend.border}`,
});

const HoverDiv = styled('div')({
  position: 'absolute',
  color: 'white',
  background: 'black',
  zIndex: 2,
  fontFamily: 'Arial, sans-serif',
  fontSize: 13,
  whiteSpace: 'nowrap',
});

const plotLFooterStyle: React.CSSProperties = {
  width: '100%',
  height: graphHeightFooter,
  background: colors.chart.legend.background,
};

const plotRFooterStyle: React.CSSProperties = {
  width: '100%',
  height: graphHeightFooter,
  background: colors.chart.legend.background,
};

interface RexBearingAnomalyDetectionFooterChartProps {
  sx?: SxProps;
  titleId: string;
  dates: number[];
}

export function RexBearingAnomalyDetectionFooterChart(
  props: RexBearingAnomalyDetectionFooterChartProps
): JSX.Element {
  const intl = useIntl();
  const { sx, titleId, dates } = props;

  const plotDataListL: Plotly.Data[] = [];
  const plotDataListR: Plotly.Data[] = [];

  const day = 1000 * 60 * 60 * 24;
  let dateNum = 0;
  if (dates.length > 0) {
    dateNum = dates[0];
  } else {
    dateNum = Date.now();
  }
  const d = new Date(dateNum);
  const startDate = new Date(d.getFullYear(), d.getMonth(), 1);
  const endDate = new Date(d.getFullYear(), d.getMonth() + 1, 0);
  const xaxis = {
    title: {
      text: intl.formatMessage({ id: msgId.AnalysisMenuLoadBandAnalysisXTitle }),
      font: { color: axisFontColor },
    },
    type: 'date',
    gridcolor: gridColor,
    range: [startDate.getTime() - day, endDate.getTime() + day],
    autorange: false,
    side: 'bottom',
    showticklabels: true,
    tickfont: { color: 'white' },
    showgrid: false,
    zeroline: false,
    fixedrange: true,
    tickformat: '%e',
  } as Partial<LayoutAxis>;
  const yaxis = {
    title: {
      text: '',
      font: { color: axisFontColor, size: 11 },
    },
    gridcolor: gridColor,
    ticklabelposition: 'inside',
    ticks: 'inside',
    tick0: 0,
    fixedrange: true,
  } as Partial<LayoutAxis>;

  const layoutL = {
    title: {
      text: '',
      color: axisFontColor,
      x: 0.05,
      y: 0.98,
      font: { color: axisFontColor },
    },
    height: graphHeightFooter,
    autosize: true,
    xaxis: xaxis,
    yaxis: yaxis,
    paper_bgcolor: colors.chart.plotarea.background,
    plot_bgcolor: colors.chart.plotarea.background,
    margin: {
      t: 0,
      b: 70,
      l: 35,
      r: 30,
      pad: 2,
    },
    showlegend: false,
    hovermode: 'closest',
    hoverlabel: {
      bgcolor: 'black',
      bordercolor: 'black',
      font: {
        color: 'white',
      },
      align: 'left',
    },
    barmode: 'stack',
  } as Partial<Layout>;
  const layoutR = Object.assign({}, layoutL);
  Object.assign(layoutR, {
    title: {
      text: '',
      color: axisFontColor,
      x: 0.05,
      y: 0.98,
      font: { color: axisFontColor },
    },
    yaxis: yaxis,
  });
  const plotConfig = {
    displayModeBar: false,
    showTips: false,
    doubleClick: false,
  } as Partial<Plotly.Config>;

  const panel = 'RexBearingAnomalyDetection';
  const PlotL = 'PlotL' + panel + titleId;
  const PlotR = 'PlotR' + panel + titleId;
  const hoverIdL = PlotL + 'hover';
  const hoverIdR = PlotR + 'hover';

  return (
    <Box sx={sx}>
      <PlotPanelDiv>
        <HoverDiv id={hoverIdL} />
        <Plot
          divId={PlotL}
          style={plotLFooterStyle}
          data={plotDataListL}
          useResizeHandler
          layout={layoutL}
          config={plotConfig}
        />
      </PlotPanelDiv>
      <PlotPanelDiv>
        <HoverDiv id={hoverIdR} />
        <Plot
          divId={PlotR}
          style={plotRFooterStyle}
          data={plotDataListR}
          useResizeHandler
          layout={layoutR}
          config={plotConfig}
        />
      </PlotPanelDiv>
    </Box>
  );
}
