import React from 'react';
import { Box, SxProps, Typography } from '@mui/material';
import { Variant } from '@mui/material/styles/createTypography';

interface MultiLineTypographyProps {
  text: string;
  sx?: SxProps;
  variant: Variant | 'inherit';
  fontSize?: string;
  color?: 'primary' | 'secondary' | 'error';
  align?: 'inherit' | 'left' | 'center' | 'right' | 'justify';
}

export function MultiLineTypography(props: MultiLineTypographyProps): JSX.Element {
  const { text, sx, variant, fontSize, color, align } = props;

  return (
    <Box sx={sx}>
      {text.split('\n').map((t, i) => {
        return (
          <Typography key={i} variant={variant} color={color} fontSize={fontSize} align={align}>
            {t}
          </Typography>
        );
      })}
    </Box>
  );
}
