import React, { useRef } from 'react';
// eslint-disable-next-line import/no-named-as-default
import Calendar, { CalendarTileProperties } from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import colors from 'resources/colors';
import { ShipStatusPopup, ShipStatus, PopupChangeEvent } from './ShipStatusPopup';
import { SensorThinnedDataDates } from 'models/data';
import dayjs from 'dayjs';
import { DiagnosisListItem } from 'models/diagnosis';
import { Box, styled } from '@mui/material';
import Empty from 'resources/icons/Empty';
import dimens from 'resources/dimens';
import config from 'resources/config';

const StyledCalendar = styled(Calendar)({
  padding: 0,
  margin: 0,
  width: '100%',
  background: colors.calendar.background,
  maxWidth: dimens.subDrawer.width,
  border: '0px',
  fontFamily: 'inherit',
  lineHeight: '1.125em',
  '& .react-calendar__tile': {
    paddingTop: 4,
    paddingBottom: 0,
    cursor: 'pointer',
  },
  '& .react-calendar__navigation__label': {
    color: 'black',
  },
  '& .react-calendar__tile--now': {
    background: 'none',
    color: 'white',
    fontWeight: 'bold',
    '& abbr': {
      border: '3px solid dodgerblue',
      borderRadius: '50%',
      background: 'dodgerblue',
      width: '21px',
      height: '21px',
      display: 'inline-block',
    },
  },
  '& .react-calendar__month-view__days__day--weekend:disabled': {
    color: 'rgba(209, 0, 0, 0.3)',
  },
  activeTile: {
    background: colors.calendar.activeTile,
  },
  currentTile: {
    background: colors.calendar.currentTile,
  },
});

const EmptyIcon = styled(Empty)({
  width: 16,
  height: 16,
});

interface ShipStatusCalendarProps {
  diagnosisListItems?: DiagnosisListItem[];
  sensorThinnedDataDates?: SensorThinnedDataDates;
  onClickDay?: (date: string) => void;
  selectValue?: Date | Date[] | null | undefined;
}

export function ShipStatusCalendar(props: ShipStatusCalendarProps): JSX.Element {
  const { diagnosisListItems, sensorThinnedDataDates, onClickDay, selectValue } = props;
  const popupOpen = useRef<boolean>(false);
  let beforeEvent: PopupChangeEvent = 'awayClick';

  const shipStatuses: { [date: string]: ShipStatus[] } = {};
  if (diagnosisListItems != null) {
    diagnosisListItems
      .filter((parent) => parent.listParentId === 0)
      .filter((parent) => parent.machineId === sensorThinnedDataDates?.machineId)
      .forEach((x) => {
        const day = dayjs(x.startDate).utc().format('YYYY-MM-DD');
        if (shipStatuses[day] == null) {
          shipStatuses[day] = [];
        }

        shipStatuses[day].push({
          operation: false,
          maintenance: false,
          diagnosisStatus: x.status,
          sensorName: x.sensorName,
          startDate: x.startDate,
        });
      });
  }

  // state の日付と同じ表記に変換
  const getFormatDate = (date: Date) => {
    return `${date.getFullYear()}-${('0' + (date.getMonth() + 1)).slice(-2)}-${(
      '0' + date.getDate()
    ).slice(-2)}`;
  };

  const getTileDisabled = (props: CalendarTileProperties): boolean => {
    const { date } = props;
    if (sensorThinnedDataDates) {
      if (sensorThinnedDataDates.dates.includes(getFormatDate(date))) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  };

  const getTileClass = (props: CalendarTileProperties) => {
    const { date, view } = props;

    // 月表示のときのみ
    if (view !== 'month') {
      return '';
    }

    const day = getFormatDate(date);
    const classNames = [];
    const shipStatus = shipStatuses && shipStatuses[day];
    if (shipStatus != null && shipStatus.find((x) => x.operation != null)) {
      const currentDay = selectValue ? dayjs(selectValue as Date).format('YYYY-MM-DD') : '';
      if (currentDay === day) {
        classNames.push('currentTile');
      } else {
        classNames.push('activeTile');
      }
    }

    return classNames;
  };

  const dateCallback = (value: Date): void => {
    if (!popupOpen.current && beforeEvent !== 'close') {
      onClickDay && onClickDay(getFormatDate(value));
      popupOpen.current = false;
    }
    beforeEvent = 'awayClick';
  };

  const handlePopupChange = (event: PopupChangeEvent) => {
    popupOpen.current = event !== 'awayClick' && event !== 'close';
    beforeEvent = event;
  };

  // 日付の内容を出力
  const getTileContent = (props: CalendarTileProperties) => {
    const { date, view } = props;

    // 月表示のときのみ
    if (view !== 'month') {
      return null;
    }
    const day = getFormatDate(date);
    const shipStatus = shipStatuses && shipStatuses[day];
    if (shipStatus != null && shipStatus.find((x) => x.diagnosisStatus != null || x.maintenance)) {
      return (
        <Box
          sx={{ marginTop: '2px', marginBottom: '0', marginLeft: '-10px', marginRight: '-10px' }}
        >
          <ShipStatusPopup shipStatus={shipStatus} onPopupChange={handlePopupChange} />
        </Box>
      );
    } else {
      return (
        <div style={{ marginTop: '2px', marginBottom: '0' }}>
          <EmptyIcon />
        </div>
      );
    }
  };

  // 日のフォーマット
  const formatDay = (locale: string, date: Date): string => {
    return date.getDate() + '';
  };

  // 年月のフォーマット
  const formatMonthYear = (locale: string, date: Date): string => {
    return date.getFullYear() + '/' + (date.getMonth() + 1);
  };

  // 週のフォーマット
  const formatShortWeekday = (locale: string, date: Date): string => {
    return new Intl.DateTimeFormat(['en'], {
      weekday: 'short', // ?? what should I put here
    })
      .format(date)
      .substring(0, 1);
  };

  return (
    <StyledCalendar
      locale="en-US"
      calendarType="US"
      minDetail="month"
      tileDisabled={getTileDisabled}
      tileClassName={getTileClass}
      tileContent={getTileContent}
      onClickDay={dateCallback}
      value={selectValue}
      formatDay={formatDay}
      formatMonthYear={formatMonthYear}
      formatShortWeekday={formatShortWeekday}
      minDate={dayjs(config.minDate).utc().toDate()}
      maxDate={dayjs().utc().toDate()}
    />
  );
}
