import colors from 'resources/colors';
import { Box, IconButton, styled } from '@mui/material';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';

export const SubDrawerBox = styled(Box)({
  whiteSpace: 'nowrap',
  padding: 0,
  height: '100%',
  borderRight: '1px solid rgba(0, 0, 0, 0.12)',
  background: colors.subDrawer.background,
});

export const CloseIconButton = styled(IconButton)({
  width: 30,
  height: 30,
});

export const ChevronLeftIcon = styled(ChevronLeft)({
  borderRadius: 15,
  color: colors.subDrawer.background,
  background: colors.primary,
});

export const ChevronRightIcon = styled(ChevronRight)({
  borderRadius: 15,
  color: colors.subDrawer.background,
  background: colors.primary,
});
