import React from 'react';
import { useIntl } from 'react-intl';
import msgId from 'resources/intl';
import colors from 'resources/colors';
import { AnalysisSearchConditions } from 'models/analysis';
import { SensorDataSearchConditions } from 'models/search';
import { Box, Button, ButtonGroup, styled, Typography } from '@mui/material';
import { theme } from 'resources/theme';
import { NormalButton } from './NormalButton';
import dimens from 'resources/dimens';
import { ClearButton } from './ClearButton';
import { TooltipButton } from './TooltipButton';

const RootBox = styled(Box)({
  width: '100%',
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(0),
  height: dimens.searchBar.height,
  background: colors.subDrawer.background,
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'start',
  flexWrap: 'nowrap',
  overflow: 'hidden',
  boxShadow:
    '0px 2px 4px -1px rgba(51,51,51,0.2), 0px 4px 5px 0px rgba(51,51,51,0.14), 0px 1px 10px 0px rgba(51,51,51,0.12)',
  zIndex: theme.zIndex.drawer + 1,
});

const TabButtonNormal = styled(Button)({
  color: theme.palette.primary.main,
  backgroundColor: theme.palette.background.default,
  '&:hover': {
    color: theme.palette.background.default,
    background: 'rgba(5, 158, 179, 0.55)',
  },
  minWidth: 160,
  width: 160,
  '&.MuiButton-outlinedPrimary': {
    border: '1px solid rgba(5, 158, 179, 1)',
  },
  '&.Mui-disabled': {
    color: theme.palette.text.disabled,
  },
});

const TabButtonSelected = styled(Button)({
  color: theme.palette.background.default,
  background: 'rgba(5, 158, 179, 1.0)',
  minWidth: 160,
  width: 160,
  '&.MuiButton-outlinedPrimary': {
    border: '1px solid rgba(5, 158, 179, 1)',
  },
  '&.Mui-disabled': {
    color: theme.palette.text.disabled,
  },
});

interface SearchConditionsBarProps {
  handleSeachClick: () => void;
  sensorDataSearchConditions?: SensorDataSearchConditions;
  analysisSearchConditions?: AnalysisSearchConditions;
  onDateFormat: (date: string) => string;
  tabIndex?: number;
  buttonLabelId?: string;
  disabled?: boolean;
  onTabClick?: (tabIndex: number) => void;
  onClear?: () => void;
}

export function SearchConditionsBar(props: SearchConditionsBarProps): JSX.Element {
  const {
    handleSeachClick,
    sensorDataSearchConditions,
    analysisSearchConditions,
    onDateFormat,
    tabIndex,
    buttonLabelId,
    disabled,
    onTabClick,
    onClear,
  } = props;
  const intl = useIntl();
  let propeller = false;
  let analysis = false;
  const tabDisabled: number[] = [];

  let startDate, endDate;
  let screeningMinutes = undefined;
  let chips: string[] = [];
  if (sensorDataSearchConditions != null) {
    startDate = sensorDataSearchConditions.startDate;
    endDate = sensorDataSearchConditions.endDate;
    if (sensorDataSearchConditions.enabled) {
      screeningMinutes = intl.formatMessage({ id: msgId.screeningUnits }) + ':';
      if (sensorDataSearchConditions.screeningMinutes === 60) {
        screeningMinutes += '1' + intl.formatMessage({ id: msgId.hour });
      } else if (sensorDataSearchConditions.screeningMinutes === 1) {
        screeningMinutes += '1' + intl.formatMessage({ id: msgId.minute });
      } else {
        screeningMinutes +=
          sensorDataSearchConditions.screeningMinutes + intl.formatMessage({ id: msgId.minutes });
      }
      chips = sensorDataSearchConditions.conditions.map((x) => {
        return intl.formatMessage({ id: x.sensorName }) + ':' + x.lowerValue + ' - ' + x.upperValue;
      });
    }
  }
  if (analysisSearchConditions != null) {
    startDate = analysisSearchConditions.startDate;
    endDate = analysisSearchConditions.endDate;
    screeningMinutes = intl.formatMessage({ id: analysisSearchConditions.periodUnit });
    chips = analysisSearchConditions.analysisMenuNames.map((x) => {
      return intl.formatMessage({ id: 'AnalysisMenu' + x });
    });
    propeller = analysisSearchConditions.analysisMenuIds.includes(13);
    analysis =
      analysisSearchConditions.analysisMenuIds.filter((val) => [1, 3, 4, 5, 9, 11, 14].includes(val))
        .length > 0;
    analysis && tabDisabled.push(0);
    propeller && tabDisabled.push(1);
  }
  const buttons = [
    { title: intl.formatMessage({ id: msgId.analysisTabAnalysis }), locked: !analysis },
    { title: intl.formatMessage({ id: msgId.analysisTabPropeller }), locked: !propeller },
  ];

  return (
    <RootBox>
      {chips.length > 0 && screeningMinutes ? (
        <TooltipButton
          title={<span style={{ whiteSpace: 'pre-line' }}>{chips.join('\n')}</span>}
          sx={{ width: dimens.button.headerWidth }}
          label={intl.formatMessage({ id: msgId.changeSearchConditions })}
          color="primary"
          onClick={() => handleSeachClick()}
          disabled={disabled}
        />
      ) : (
        <NormalButton
          sx={{ width: dimens.button.headerWidth }}
          label={intl.formatMessage({ id: msgId.changeSearchConditions })}
          color="primary"
          onClick={() => handleSeachClick()}
          disabled={disabled}
        />
      )}
      {analysisSearchConditions === undefined && onClear && buttonLabelId && (
        <ClearButton
          label={intl.formatMessage({ id: buttonLabelId })}
          onClick={() => onClear()}
          disabled={disabled}
        />
      )}
      <div style={{ width: theme.spacing(2), minWidth: theme.spacing(0.5) }} />
      {startDate && endDate && (
        <React.Fragment>
          <Typography variant="body2" noWrap>
            {intl.formatMessage({ id: msgId.periodSearch })}
          </Typography>
          <div style={{ width: theme.spacing(1), minWidth: theme.spacing(0.5) }} />
          <Typography variant="body2" noWrap>
            {onDateFormat(startDate) + ' - ' + onDateFormat(endDate)}
          </Typography>
          <div style={{ width: theme.spacing(1), minWidth: theme.spacing(0.5) }} />
          <Typography variant="body2" noWrap>
            {screeningMinutes ?? screeningMinutes}
          </Typography>
        </React.Fragment>
      )}
      <div style={{ marginLeft: theme.spacing(2), marginRight: theme.spacing(2) }} />
      {analysisSearchConditions && (
        <React.Fragment>
          <ButtonGroup color="primary">
            {buttons.map((row, index) => {
              if (tabIndex === index) {
                return (
                  <TabButtonSelected
                    key={index}
                    disabled={tabDisabled.includes(index) === false}
                    onClick={() => {
                      onTabClick && onTabClick(index);
                    }}
                  >
                    <Typography variant="body2" noWrap>
                      {row.title}
                    </Typography>
                  </TabButtonSelected>
                );
              } else {
                return (
                  <TabButtonNormal
                    key={index}
                    disabled={tabDisabled.includes(index) === false}
                    onClick={() => {
                      onTabClick && onTabClick(index);
                    }}
                  >
                    <Typography variant="body2" noWrap>
                      {row.title}
                    </Typography>
                  </TabButtonNormal>
                );
              }
            })}
          </ButtonGroup>
        </React.Fragment>
      )}
    </RootBox>
  );
}
