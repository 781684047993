import { Button, styled, SxProps } from '@mui/material';
import React from 'react';
import colors from 'resources/colors';
import dimens from 'resources/dimens';
import { theme } from 'resources/theme';

const StyledButton = styled(Button)({
  height: dimens.chart.button.height,
  minWidth: dimens.chart.button.minWidth,
  '&.Mui-disabled': {
    color: theme.palette.text.disabled,
  },
});

const ContainedButton = styled(Button)({
  height: dimens.chart.button.height,
  minWidth: dimens.chart.button.minWidth,
  borderRadius: dimens.chart.button.borderRadius,
  '&.Mui-disabled': {
    color: theme.palette.text.disabled,
    backgroundColor: colors.chart.header.color,
  },
});

const ApplyContainedButton = styled(Button)({
  height: dimens.chart.button.height,
  minWidth: dimens.chart.button.minWidth,
  borderRadius: dimens.chart.button.borderRadius,
  '&.Mui-disabled': {
    color: theme.palette.text.disabled,
    backgroundColor: colors.chart.header.color,
  },
  '&.MuiButton-containedSecondary': {
    backgroundColor: colors.chart.button.apply,
    color: colors.chart.text,
  },
});

interface ChartButtonProps {
  sx?: SxProps;
  variant?: 'text' | 'outlined' | 'contained';
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  disabled?: boolean;
  label: string;
  apply?: boolean;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

export function ChartButton(props: ChartButtonProps): JSX.Element {
  const { sx, variant, startIcon, endIcon, disabled, label, apply, onClick } = props;
  const ButtonComponent =
    variant === 'contained' ? (apply ? ApplyContainedButton : ContainedButton) : StyledButton;

  return (
    <ButtonComponent
      size="small"
      variant={variant}
      disableElevation
      color="secondary"
      startIcon={startIcon}
      endIcon={endIcon}
      sx={sx}
      disabled={disabled}
      onClick={onClick}
    >
      {label}
    </ButtonComponent>
  );
}
