import React, { useEffect, useRef, useState } from 'react';
import { styled } from '@mui/material';
import { RootContainer } from 'components/RootContainer';
import { useMainDrawerOpen } from 'features/drawer/mainDrawerSlice';
import dimens from 'resources/dimens';
import { ReportPanel } from './report/ReportPanel';
import { Splitter } from 'components/Splitter';
import { DiagnosisChartContainer } from './diagnosis/DiagnosisChartContainer';
import { DiagnosisList } from './diagnosis/DiagnosisList';
import { MaintenanceList } from './maintenance/MaintenanceList';

const VertPane1Div = styled('div')({
  height: '100%',
  position: 'relative',
  float: 'left',
});

const LeftHorzPane1Div = styled('div')({
  position: 'absolute',
  width: '100%',
  left: 0,
  top: 0,
});

const LeftHorzPane2Div = styled('div')({
  position: 'absolute',
  width: '100%',
  left: 0,
});

const VertPane2Div = styled('div')({
  height: '100%',
  left: dimens.splitter.width,
  position: 'relative',
  float: 'left',
});

const RightHorzPane1Div = styled('div')({
  position: 'absolute',
  left: 0,
  top: 0,
  width: '100%',
});

const RightHorzPane2Div = styled('div')({
  position: 'absolute',
  left: 0,
  width: '100%',
});

interface DashboardFragmentProps {
  hidden: boolean;
}

export function DashboardFragment(props: DashboardFragmentProps): JSX.Element {
  const { hidden } = props;
  const mainDrawerOpen = useMainDrawerOpen();
  const containerRef = useRef<HTMLDivElement>(null);
  const [windowSize, setWindowSize] = useState({ width: 0, height: 0 });
  const [state, setState] = useState({ pane1Height: 400, pane1Width: 800, pane2Height: 500 });

  /**
   * 垂直スプリッタ位置変更
   * @param position 移動位置
   */
  const handleVerticalPositionChanged = (position: number) => {
    // logger.debug('position: ' + position);
    setState({ ...state, pane1Width: position });
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 400);
  };

  /**
   * 左側水平スプリッタ位置変更
   * @param position 移動位置
   */
  const handleLeftHorizontalPositionChanged = (position: number) => {
    // logger.debug('position: ' + position);
    setState({ ...state, pane1Height: position });
  };

  /**
   * 右側水平スプリッタ位置変更
   * @param position 移動位置
   */
  const handleRightHorizontalPositionChanged = (position: number) => {
    // logger.debug('position: ' + position);
    setState({ ...state, pane2Height: position });
  };

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      const width = entries[0].contentRect.width;
      const height = entries[0].contentRect.height;
      if (width > 0 && height > 0) {
        if (windowSize.width !== width || windowSize.height !== height) {
          setWindowSize({ width, height });
        }

        let newState = { ...state };
        let stateChanged = false;
        const maxWidth = width - dimens.splitter.width;
        const pane1MaxWidth =
          maxWidth < dimens.chart.legend.width + 50 ? dimens.chart.legend.width + 50 : maxWidth;
        if (state.pane1Width > pane1MaxWidth) {
          newState = { ...newState, pane1Width: pane1MaxWidth };
          stateChanged = true;
        }

        let maxHeight = height - dimens.splitter.width;
        maxHeight = maxHeight < 0 ? 0 : maxHeight;
        if (state.pane1Height > maxHeight) {
          newState = { ...newState, pane1Height: maxHeight };
          stateChanged = true;
        }
        const pane2MaxHeight =
          maxHeight < dimens.dataGrid.minHeight ? dimens.dataGrid.minHeight : maxHeight;
        if (state.pane2Height > pane2MaxHeight) {
          newState = { ...newState, pane2Height: pane2MaxHeight };
          stateChanged = true;
        }

        if (stateChanged) {
          setState(newState);
        }
      }
    });
    containerRef.current && resizeObserver.observe(containerRef.current);

    return (): void => {
      resizeObserver.disconnect();
    };
  });

  useEffect(() => {
    if (mainDrawerOpen && windowSize.width > 0) {
      // スプリッタ位置が領域外になる場合は補正する
      const maxPosition =
        windowSize.width - (dimens.drawer.width - dimens.drawer.minWidth) - dimens.splitter.width;
      if (state.pane1Width > maxPosition) {
        setState({ ...state, pane1Width: maxPosition });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mainDrawerOpen]);

  const offsetX = mainDrawerOpen ? dimens.drawer.width : dimens.drawer.minWidth;

  return (
    <RootContainer hidden={hidden} ref={containerRef}>
      <VertPane1Div sx={{ width: state.pane1Width }}>
        <LeftHorzPane1Div sx={{ height: state.pane1Height }}>
          <ReportPanel width={state.pane1Width} height={state.pane1Height} />
        </LeftHorzPane1Div>
        <Splitter
          direction="horizontal"
          offsetY={dimens.toolbar.height}
          onPositionChanged={handleLeftHorizontalPositionChanged}
          position={state.pane1Height}
          minPosition={0}
          offsetX={0}
        />
        <LeftHorzPane2Div
          sx={{
            top: state.pane1Height + dimens.splitter.width,
            height: `calc(100% - ${state.pane1Height - dimens.splitter.width}px)`,
          }}
        >
          <MaintenanceList
            height={windowSize.height - state.pane1Height - dimens.splitter.width}
            contentsHeight={windowSize.height}
          />
        </LeftHorzPane2Div>
      </VertPane1Div>
      <Splitter
        direction="vertical"
        offsetX={offsetX}
        onPositionChanged={handleVerticalPositionChanged}
        position={state.pane1Width}
        minPosition={dimens.chart.legend.width + 50}
        offsetY={0}
      />
      <VertPane2Div sx={{ width: `calc(100% - ${state.pane1Width + dimens.splitter.width}px)` }}>
        <RightHorzPane1Div sx={{ height: state.pane2Height }}>
          <DiagnosisList height={state.pane2Height} />
        </RightHorzPane1Div>
        <Splitter
          direction="horizontal"
          offsetY={dimens.toolbar.height}
          onPositionChanged={handleRightHorizontalPositionChanged}
          position={state.pane2Height}
          minPosition={dimens.dataGrid.minHeight}
          offsetX={0}
        />
        <RightHorzPane2Div
          sx={{
            top: state.pane2Height + dimens.splitter.width,
            height: `calc(100% - ${state.pane2Height + dimens.splitter.width}px)`,
          }}
        >
          <DiagnosisChartContainer
            width={windowSize.width - state.pane1Width - dimens.splitter.width}
            height={windowSize.height - state.pane2Height - dimens.splitter.width}
          />
        </RightHorzPane2Div>
      </VertPane2Div>
    </RootContainer>
  );
}
