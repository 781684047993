import React from 'react';
import { useIntl } from 'react-intl';
import msgId from 'resources/intl';
import ReactDOM from 'react-dom';
import AppLogger from 'utils/AppLogger';
import Plotly, { PlotType, Layout, PlotMouseEvent, Datum, Annotations, Shape } from 'plotly.js';
import createPlotlyComponent from 'react-plotly.js/factory';
import { ChartSeries } from 'models/chart';
import { SensorData } from 'models/data';
import { styled } from '@mui/material';
import colors from 'resources/colors';
import { roundValueToString } from 'utils/misc';
import constants from 'resources/constants';
import { PlotTitle } from 'features/contents/shipState/pastData/chart/trend/TrendChart';
import { Figure } from 'react-plotly.js';
import { DiagnosisListItem, DiagnosisStatus } from 'models/diagnosis';
import dayjs from 'dayjs';
// import dayjs from 'dayjs';

const Plot = createPlotlyComponent(Plotly);
const ActiveLine = true;
const PlotlyRestyle = Plotly.restyle;
const whiteFontColor = '#ddd';
const trendLabelPrefix = 'Trend';
export interface LayoutChange {
  'xaxis.range[0]': Datum;
  'xaxis.range[1]': Datum;
  'yaxis.range[0]': Datum;
  'yaxis.range[1]': Datum;
  'xaxis.autorange': boolean;
  'yaxis.autorange': boolean;
  index: number;
  minParsent: number;
  maxParsent: number;
}
const RootRiv = styled('div')({
  position: 'relative',
  width: '100%',
  height: '100%',
  background: '#000000',
  fontSize: '0',
  border: 'none',
  margin: 0,
});

const ValuePanel = styled('div')({
  position: 'absolute',
  fontSize: '8px',
  color: whiteFontColor,
  zIndex: 3,
});

const topLabelHeiht = 13;
const layoutBottomMargin = 30;

export interface TrendGraphProps {
  chartId: string;
  machineId: number;
  dataFormatId: number;
  chartSeriesList: ChartSeries[];
  diagnosisListItems: DiagnosisListItem[];
  height: number;
  width: number;
}

export default function DiagnosisGraph(props: TrendGraphProps): JSX.Element {
  AppLogger.debug('  CALL: DiagnosisGraph');
  const { chartId, machineId, dataFormatId, chartSeriesList, diagnosisListItems, height, width } = props;
  const intl = useIntl();
  const graphPanelWidth = width;
  const graphPanelHeight = height / 2;
  const [layoutRange, setLayoutRange] = React.useState<Partial<LayoutChange>>({
    index: 0,
    minParsent: 0,
    maxParsent: 100,
  });
  let doubleClick = false;
  let startTime = 0;
  let endTime = 0;
  let timeData: number[] = [];

  if (chartSeriesList != null && chartSeriesList[0].timeData != null) {
    timeData = chartSeriesList[0].timeData;
    startTime = timeData[0];
    endTime = timeData[timeData.length - 1];
  }

  let graphDataL: Plotly.Data[] = [];
  let graphDataR: Plotly.Data[] = [];
  let graphHighLightL: Plotly.Data[] = [];
  let graphHighLightR: Plotly.Data[] = [];
  const thresholdShapeL: Partial<Shape>[] = [];
  const thresholdShapeR: Partial<Shape>[] = [];
  const alertPointL: Partial<Annotations>[] = [];
  const alertPointR: Partial<Annotations>[] = [];

  const timezoneOffset = dayjs().utcOffset() * 60000;
  const diagnosisStartTimes: number[] = [];
  diagnosisListItems
    .filter((x) => x.listParentId !== 0 && x.machineId === machineId)
    .forEach((y) => {
      diagnosisStartTimes.push(dayjs(y.startDate).utc().add(-timezoneOffset).valueOf());
    });

  const dumyData = {
    x: [],
    y: [],
    name: '',
    type: 'scatter' as PlotType,
    hoverinfo: 'none',
    line: { color: 'rgba(0,0,0,0)' },
  } as Plotly.Data;

  function highLightColor(color: string, opacity: number): string {
    const r = '0x' + color.substring(1, 3);
    const g = '0x' + color.substring(3, 5);
    const b = '0x' + color.substring(5);

    return (
      'rgba(' +
      parseInt(r, 16) +
      ',' +
      parseInt(g, 16) +
      ',' +
      parseInt(b, 16) +
      ',' +
      opacity +
      ')'
    );
  }
  function getTargetSensorData(
    chartSeries: ChartSeries[],
    sensorName: string
  ): { data: number[] | undefined; targetThreshold: number } {
    let ret: { data: number[] | undefined; targetThreshold: number } = {
      data: undefined,
      targetThreshold: NaN,
    };
    if (sensorName === undefined || sensorName === null || sensorName.length === 0) {
      return ret;
    }
    chartSeries.forEach((series) => {
      series.sensorGroup.sensors.forEach((sensor) => {
        if (sensor.sensorName === sensorName) {
          if (sensor.position === 'Port') {
            ret = {
              data: series.portSensorData?.data,
              targetThreshold: sensor.operatingLowerLimit,
            };
          } else {
            ret = {
              data: series.starboardSensorData?.data,
              targetThreshold: sensor.operatingLowerLimit,
            };
          }
        }
      });
    });

    return ret;
  }

  function highLightData(
    timeData: number[],
    sensorData: SensorData,
    diagnosisStatus: DiagnosisStatus | undefined,
    targetData?: { data: number[] | undefined; targetThreshold: number }
  ): { xDataU: number[]; yDataU: number[]; xDataD: number[]; yDataD: number[]; xDataA: number[] } {
    const xDataU: number[] = [];
    const xDataD: number[] = [];
    const yDataU: number[] = [];
    const yDataD: number[] = [];
    const xDataA: number[] = [];
    let thresholdUpper = sensorData.sensor.displayUpperLimit;
    let thresholLower = sensorData.sensor.displayLowerLimit;
    const displayUpperLimit = sensorData.sensor.displayUpperLimit;
    const displayLowerLimit = sensorData.sensor.displayLowerLimit;
    let operationJudgementTime = 0;
    let sensorJudgementTime = 0;
    let upperContinuousJudgement = false;
    let lowerContinuousJudgement = false;
    switch (diagnosisStatus) {
      case 'Caution':
        thresholdUpper =
          sensorData.sensor.cautionUpperLimit === null
            ? Number.MAX_SAFE_INTEGER
            : sensorData.sensor.cautionUpperLimit;
        thresholLower =
          sensorData.sensor.cautionLowerLimit === null
            ? Number.MIN_SAFE_INTEGER
            : sensorData.sensor.cautionLowerLimit;
        operationJudgementTime = constants.diagnosis.operationJudgementTime * 1000;
        sensorJudgementTime = sensorData.sensor.cautionJudgmentTime * 1000;
        break;
      case 'Warning':
        thresholdUpper =
          sensorData.sensor.warningUpperLimit === null
            ? Number.MAX_SAFE_INTEGER
            : sensorData.sensor.warningUpperLimit;
        thresholLower =
          sensorData.sensor.warningLowerLimit === null
            ? Number.MIN_SAFE_INTEGER
            : sensorData.sensor.warningLowerLimit;
        operationJudgementTime = constants.diagnosis.operationJudgementTime * 1000;
        sensorJudgementTime = sensorData.sensor.warningJudgmentTime * 1000;
        break;
      case 'Fault':
        thresholdUpper = sensorData.sensor.faultThreshold;
        thresholLower = Number.MAX_SAFE_INTEGER;
        operationJudgementTime = constants.diagnosis.operationJudgementTime * 1000;
        sensorJudgementTime = sensorData.sensor.faultJudgmentTime * 1000;
        break;
    }

    const ydata = sensorData.data;
    let operationStartTime = 0;
    let upperStartTime = 0;
    let lowerStartTime = 0;
    let isOperation = false;
    timeData.forEach((time, i) => {
      const data = targetData?.data;
      const targetThreshold = targetData?.targetThreshold;

      if (diagnosisStatus === 'Fault') {
        // 故障の判定
        if (data === undefined || targetThreshold === undefined) {
          isOperation = false;
        } else {
          // ・OFF→ONの判定：タイマーを設けず、閾値を上回れば即時ON判定
          // ・ON→OFFの判定：30sのタイマーを設ける
          if (data[i] <= targetThreshold) {
            if (isOperation) {
              if (operationStartTime === 0) {
                operationStartTime = time;
              } else if (time - operationStartTime >= operationJudgementTime) {
                isOperation = false;
                // AppLogger.debug('運転停止: i=' + i + ', time=' + dayjs(time).toISOString());
              }
            }
          } else {
            operationStartTime = 0;
            // if (!isOperation) {
            //   AppLogger.debug('運転開始: i=' + i + ', time=' + dayjs(time).toISOString());
            // }
            isOperation = true;
          }
        }

        // 上限チェック
        // 運転していない時にfaultThresholdを超えていると異常。
        if (!isOperation && thresholdUpper !== null && ydata[i] >= thresholdUpper) {
          if (upperStartTime === 0) {
            upperStartTime = time;
          } else if (time - upperStartTime >= sensorJudgementTime) {
            // 異常検出
            if (!upperContinuousJudgement) {
              upperContinuousJudgement = true;
              if (checkDiagnosisStartTimes(upperStartTime)) {
                xDataA.push(upperStartTime);
              }
              let count = Math.round((time - upperStartTime) / 100);
              while (count > 0) {
                xDataU.push(time - count * 100);
                yDataU.push(ydata[i - count]);
                count--;
              }
            }
            xDataU.push(time);
            yDataU.push(ydata[i]);
          }
        } else {
          // 運転中または、上限値以下のためリセット
          upperStartTime = 0;
          upperContinuousJudgement = false;
          xDataU.push(time);
          yDataU.push(NaN);
        }
      } else {
        // 注意・警告の判定
        // targetが無い場合の注意・警告は常に運転中として処理する
        if (data === undefined || targetThreshold === undefined) {
          isOperation = true;
        } else {
          // ・OFF→ONの判定：30sのタイマーを設ける
          // ・ON→OFFの判定：タイマーを設けず、閾値を下回れば即時OFF判定
          if (data[i] >= targetThreshold) {
            if (!isOperation) {
              if (operationStartTime === 0) {
                operationStartTime = time;
              } else if (time - operationStartTime >= operationJudgementTime) {
                isOperation = true;
                // AppLogger.debug('運転開始: i=' + i + ', time=' + dayjs(time).toISOString());
              }
            }
          } else {
            operationStartTime = 0;
            // if (isOperation) {
            //   AppLogger.debug('運転停止: i=' + i + ', time=' + dayjs(time).toISOString());
            // }
            isOperation = false;
          }
        }

        if (thresholdUpper !== null && ydata[i] >= thresholdUpper && ydata[i] <= displayUpperLimit) {
          // 運転状態に関わらず、上限値を超えてn秒以上経過したら警告と判定
          if (lowerContinuousJudgement) {
            xDataU.push(time);
            yDataU.push(NaN);
          }
          lowerStartTime = 0;
          lowerContinuousJudgement = false;
          if (upperStartTime === 0) {
            upperStartTime = time;
          } else if (time - upperStartTime >= sensorJudgementTime) {
            // 警報検出
            if (!upperContinuousJudgement) {
              upperContinuousJudgement = true;
              if (checkDiagnosisStartTimes(upperStartTime)) {
                xDataA.push(upperStartTime);
              }
              let count = Math.round((time - upperStartTime) / 100);
              while (count > 0) {
                xDataU.push(time - count * 100);
                yDataU.push(ydata[i - count]);
                count--;
              }
            }
            xDataU.push(time);
            yDataU.push(ydata[i]);
          }
        } else if (thresholLower !== null && ydata[i] <= thresholLower && ydata[i] >= displayLowerLimit) {
          // 運転中に下限値を下回ってn秒以上経過したら警告と判定
          if (upperContinuousJudgement) {
            xDataU.push(time);
            yDataU.push(NaN);
          }
          upperStartTime = 0;
          upperContinuousJudgement = false;
          if (isOperation) {
            if (lowerStartTime === 0) {
              lowerStartTime = time;
            } else if (time - lowerStartTime >= sensorJudgementTime) {
              // 警報検出
              if (!lowerContinuousJudgement) {
                lowerContinuousJudgement = true;
                if (checkDiagnosisStartTimes(lowerStartTime)) {
                  xDataA.push(lowerStartTime);
                }
                let count = Math.round((time - lowerStartTime) / 100);
                while (count > 0) {
                  xDataU.push(time - count * 100);
                  yDataU.push(ydata[i - count]);
                  count--;
                }
              }
              xDataU.push(time);
              yDataU.push(ydata[i]);
            }
          } else {
            // 運転していないためリセット
            lowerStartTime = 0;
            lowerContinuousJudgement = false;
            xDataU.push(time);
            yDataU.push(NaN);
          }
        } else {
          // 範囲内、またはグラフ上下限外なのでリセット
          if (upperContinuousJudgement) {
            xDataU.push(time);
            yDataU.push(NaN);
          }
          upperStartTime = 0;
          upperContinuousJudgement = false;
          if (lowerContinuousJudgement) {
            xDataU.push(time);
            yDataU.push(NaN);
          }
          lowerStartTime = 0;
          lowerContinuousJudgement = false;
        }
      }
    });

    return { xDataD: xDataD, xDataU: xDataU, yDataD: yDataD, yDataU: yDataU, xDataA: xDataA };
  }

  function checkDiagnosisStartTimes(checkTime: number): boolean {
    let isHit = false;
    if (dataFormatId !== 402) {
      if (diagnosisStartTimes.includes(checkTime)) {
        isHit = true;
      }
    } else {
      const checkStartTime = checkTime - 900;
      const checkEndTime = checkTime + 900;

      for (let i = 0; i < diagnosisStartTimes.length; i++) {
        if ((checkStartTime <= diagnosisStartTimes[i]) && (diagnosisStartTimes[i] <= checkEndTime)) {
          isHit = true;
          break;
        }
      }
    }

    return isHit;
  }

  chartSeriesList.map((series, index) => {
    const ydata =
      series.sensorGroup.displayUpperLimit === 360 || series.sensorGroup.displayUpperLimit === 359.9
        ? series.portSensorData?.data.map((val) => (val > 180 ? val - 360 : val))
        : series.portSensorData?.data;
    const yaxis = 'y' + (index !== 0 ? index + 1 : '');
    graphDataL = [
      ...graphDataL,
      {
        x: timeData,
        y: ydata,
        name: series.sensorGroup.sensorGroupName,
        type: 'scatter' as PlotType,
        hoverinfo: 'none',
        line: { color: series.color, width: 1 },
        yaxis: yaxis,
      },
    ] as Plotly.Data[];
    let diagnosisStatus: DiagnosisStatus | undefined = undefined;
    if (series.portSensorData && series.portSensorData.diagnosisStatus) {
      diagnosisStatus = series.portSensorData.diagnosisStatus;
    } else if (series.starboardSensorData && series.starboardSensorData.diagnosisStatus) {
      diagnosisStatus = series.starboardSensorData.diagnosisStatus;
    }
    if (series.portSensorData != null && diagnosisStatus != null) {
      const { xDataU, yDataU, xDataD, yDataD, xDataA } = highLightData(
        timeData,
        series.portSensorData,
        diagnosisStatus,
        getTargetSensorData(
          chartSeriesList,
          series.portSensorData.sensor.faultCompareSensorName ||
          series.portSensorData.sensor.warningCompareSensorName
        )
      );
      graphHighLightL = [
        ...graphHighLightL,
        {
          x: xDataU,
          y: yDataU,
          name: series.sensorGroup.sensorGroupName,
          type: 'scatter' as PlotType,
          hoverinfo: 'none',
          line: { color: highLightColor(series.color, 0.7), width: 20, shape: 'spline' },
          yaxis: yaxis,
        },
      ] as Plotly.Data[];
      graphHighLightL = [
        ...graphHighLightL,
        {
          x: xDataD,
          y: yDataD,
          name: series.sensorGroup.sensorGroupName,
          type: 'scatter' as PlotType,
          hoverinfo: 'none',
          line: { color: highLightColor(series.color, 0.7), width: 20, shape: 'spline' },
          yaxis: yaxis,
        },
      ] as Plotly.Data[];
      xDataA.forEach((date) => {
        const annotation: Partial<Annotations> = {
          xref: 'x',
          yref: 'paper',
          x: date,
          xanchor: 'center',
          y: 1,
          yanchor: 'top',
          text: '▼',
          showarrow: false,
          font: {
            size: 8,
            color: 'red',
          },
        };
        alertPointL.push(annotation);
      });
      let thresholdU: number | undefined;
      let thresholdD: number | undefined;
      switch (diagnosisStatus) {
        case 'Caution':
          thresholdU = series.portSensorData.sensor.cautionUpperLimit;
          thresholdD = series.portSensorData.sensor.cautionLowerLimit;
          break;
        case 'Warning':
          thresholdU = series.portSensorData.sensor.warningUpperLimit;
          thresholdD = series.portSensorData.sensor.warningLowerLimit;
          break;
        case 'Fault':
          thresholdD = series.portSensorData.sensor.faultThreshold;
          break;
      }
      if (!(thresholdU === undefined) && !(thresholdU === null)) {
        thresholdShapeL.push({
          type: 'line',
          xref: 'paper',
          yref: yaxis as 'y',
          x0: 0,
          y0: thresholdU,
          x1: 1,
          y1: thresholdU,
          line: {
            color: 'red',
            width: 1,
            dash: 'dot',
          },
        });
      }
      if (!(thresholdD === undefined) && !(thresholdD === null)) {
        thresholdShapeL.push({
          type: 'line',
          xref: 'paper',
          yref: yaxis as 'y',
          x0: 0,
          y0: thresholdD,
          x1: 1,
          y1: thresholdD,
          line: {
            color: 'red',
            width: 1,
            dash: 'dot',
          },
        });
      }
      if (series.starboardSensorData) {
        switch (diagnosisStatus) {
          case 'Caution':
            thresholdU = series.starboardSensorData.sensor.cautionUpperLimit;
            thresholdD = series.starboardSensorData.sensor.cautionLowerLimit;
            break;
          case 'Warning':
            thresholdU = series.starboardSensorData.sensor.warningUpperLimit;
            thresholdD = series.starboardSensorData.sensor.warningLowerLimit;
            break;
          case 'Fault':
            thresholdD = series.starboardSensorData.sensor.faultThreshold;
            break;
        }
        if (!(thresholdU === undefined) && !(thresholdU === null)) {
          thresholdShapeR.push({
            type: 'line',
            xref: 'paper',
            yref: yaxis as 'y',
            x0: 0,
            y0: thresholdU,
            x1: 1,
            y1: thresholdU,
            line: {
              color: 'red',
              width: 1,
              dash: 'dot',
            },
          });
        }
        if (!(thresholdD === undefined) && !(thresholdD === null)) {
          thresholdShapeR.push({
            type: 'line',
            xref: 'paper',
            yref: yaxis as 'y',
            x0: 0,
            y0: thresholdD,
            x1: 1,
            y1: thresholdD,
            line: {
              color: 'red',
              width: 1,
              dash: 'dot',
            },
          });
        }
      }
    }

    return true;
  });

  chartSeriesList.map((series, index) => {
    const ydata =
      series.sensorGroup.displayUpperLimit === 360 || series.sensorGroup.displayUpperLimit === 359.9
        ? series.starboardSensorData?.data.map((val) => (val > 180 ? val - 360 : val))
        : series.starboardSensorData?.data;
    const yaxis = 'y' + (index !== 0 ? index + 1 : '');
    graphDataR = [
      ...graphDataR,
      {
        x: timeData,
        y: ydata,
        name: series.sensorGroup.sensorGroupName,
        type: 'scatter' as PlotType,
        hoverinfo: 'none',
        line: { color: series.color, width: 1 },
        yaxis: yaxis,
      },
    ] as Plotly.Data[];
    let diagnosisStatus: DiagnosisStatus | undefined = undefined;
    if (series.starboardSensorData && series.starboardSensorData.diagnosisStatus) {
      diagnosisStatus = series.starboardSensorData.diagnosisStatus;
    } else if (series.portSensorData && series.portSensorData.diagnosisStatus) {
      diagnosisStatus = series.portSensorData.diagnosisStatus;
    }
    if (series.starboardSensorData != null && diagnosisStatus != null) {
      const { xDataU, yDataU, xDataD, yDataD, xDataA } = highLightData(
        timeData,
        series.starboardSensorData,
        diagnosisStatus,
        getTargetSensorData(
          chartSeriesList,
          series.starboardSensorData.sensor.faultCompareSensorName ||
          series.starboardSensorData.sensor.warningCompareSensorName
        )
      );
      graphHighLightR = [
        ...graphHighLightR,
        {
          x: xDataU,
          y: yDataU,
          name: series.sensorGroup.sensorGroupName,
          type: 'scatter' as PlotType,
          hoverinfo: 'none',
          line: { color: highLightColor(series.color, 0.7), width: 20, shape: 'spline' },
          yaxis: yaxis,
        },
      ] as Plotly.Data[];
      graphHighLightR = [
        ...graphHighLightR,
        {
          x: xDataD,
          y: yDataD,
          name: series.sensorGroup.sensorGroupName,
          type: 'scatter' as PlotType,
          hoverinfo: 'none',
          line: { color: highLightColor(series.color, 0.7), width: 20, shape: 'spline' },
          yaxis: yaxis,
        },
      ] as Plotly.Data[];
      xDataA.forEach((date) => {
        const annotation: Partial<Annotations> = {
          xref: 'x',
          yref: 'paper',
          x: date,
          xanchor: 'center',
          y: 1,
          yanchor: 'top',
          text: '▼',
          showarrow: false,
          font: {
            size: 8,
            color: 'red',
          },
        };
        alertPointR.push(annotation);
      });
      let thresholdU: number | undefined;
      let thresholdD: number | undefined;
      switch (diagnosisStatus) {
        case 'Caution':
          thresholdU = series.starboardSensorData.sensor.cautionUpperLimit;
          thresholdD = series.starboardSensorData.sensor.cautionLowerLimit;
          break;
        case 'Warning':
          thresholdU = series.starboardSensorData.sensor.warningUpperLimit;
          thresholdD = series.starboardSensorData.sensor.warningLowerLimit;
          break;
        case 'Fault':
          thresholdD = series.starboardSensorData.sensor.faultThreshold;
          break;
      }
      if (!(thresholdU === undefined) && !(thresholdU === null)) {
        thresholdShapeR.push({
          type: 'line',
          xref: 'paper',
          yref: yaxis as 'y',
          x0: 0,
          y0: thresholdU,
          x1: 1,
          y1: thresholdU,
          line: {
            color: 'red',
            width: 1,
            dash: 'dot',
          },
        });
      }
      if (!(thresholdD === undefined) && !(thresholdD === null)) {
        thresholdShapeR.push({
          type: 'line',
          xref: 'paper',
          yref: yaxis as 'y',
          x0: 0,
          y0: thresholdD,
          x1: 1,
          y1: thresholdD,
          line: {
            color: 'red',
            width: 1,
            dash: 'dot',
          },
        });
      }
      if (series.portSensorData) {
        switch (diagnosisStatus) {
          case 'Caution':
            thresholdU = series.portSensorData.sensor.cautionUpperLimit;
            thresholdD = series.portSensorData.sensor.cautionLowerLimit;
            break;
          case 'Warning':
            thresholdU = series.portSensorData.sensor.warningUpperLimit;
            thresholdD = series.portSensorData.sensor.warningLowerLimit;
            break;
          case 'Fault':
            thresholdD = series.portSensorData.sensor.faultThreshold;
            break;
        }
        if (!(thresholdU === undefined) && !(thresholdU === null)) {
          thresholdShapeL.push({
            type: 'line',
            xref: 'paper',
            yref: yaxis as 'y',
            x0: 0,
            y0: thresholdU,
            x1: 1,
            y1: thresholdU,
            line: {
              color: 'red',
              width: 1,
              dash: 'dot',
            },
          });
        }
        if (!(thresholdD === undefined) && !(thresholdD === null)) {
          thresholdShapeL.push({
            type: 'line',
            xref: 'paper',
            yref: yaxis as 'y',
            x0: 0,
            y0: thresholdD,
            x1: 1,
            y1: thresholdD,
            line: {
              color: 'red',
              width: 1,
              dash: 'dot',
            },
          });
        }
      }
    }

    return true;
  });

  const layout = {
    title: {
      text: '',
      x: 0,
      xref: 'paper',
      xanchor: 'left',
      font: {
        color: whiteFontColor,
        size: 16,
      },
    },
    template: { themeRef: 'PLOTLY_DARK' },
    height: graphPanelHeight - topLabelHeiht,
    width: graphPanelWidth,
    margin: {
      l: 5,
      r: 5,
      t: 45,
      b: 4,
    },
    xaxis: {
      color: whiteFontColor,
      zeroline: true,
      tickfont: {
        color: whiteFontColor,
        size: 10,
      },
      gridcolor: 'rgba(0,0,0,0)',
      zerolinecolor: '#444',
      visible: true,
      autorange: true,
      range: [startTime, endTime],
      tickmode: 'auto',
      tickangle: 0,
      nticks: 5,
    },
    yaxis: {
      color: whiteFontColor,
      zeroline: true,
      // range: [0,101],
      tickfont: {
        color: whiteFontColor,
      },
      showticklabels: false,
      gridcolor: '#444',
      zerolinecolor: '#444',
      dtick: 10,
    },
    yaxis2: {
      overlaying: 'y',
    },
    yaxis3: {
      overlaying: 'y',
    },
    yaxis4: {
      overlaying: 'y',
    },
    yaxis5: {
      overlaying: 'y',
    },
    yaxis6: {
      overlaying: 'y',
    },
    yaxis7: {
      overlaying: 'y',
    },
    yaxis8: {
      overlaying: 'y',
    },
    yaxis9: {
      overlaying: 'y',
    },
    showlegend: false,
    legend: { borderwidth: 1 },
    paper_bgcolor: colors.chart.plotarea.background,
    plot_bgcolor: colors.chart.plotarea.background,
    gridcolor: colors.chart.plotarea.grid,
    font: {
      color: whiteFontColor,
    },
  } as Partial<Layout>;
  if (ActiveLine) {
    layout.hovermode = 'closest';
  }
  const layoutLeft = Object.assign({}, layout);
  const layoutRight = Object.assign({}, layout);
  layoutLeft.title = {
    ...(layoutLeft.title as PlotTitle),
    text: intl.formatMessage({ id: msgId.trendLabelPort }),
    y: 1,
    pad: { t: 6, b: 0, l: 0, r: 0 },
  };
  layoutLeft.yaxis = {
    ...layoutLeft.yaxis,
    mirror: true,
    range: [0, 100],
    linecolor: whiteFontColor,
  };
  layoutLeft.xaxis = {
    ...layoutLeft.xaxis,
    mirror: true,
    showticklabels: false,
    linecolor: whiteFontColor,
    gridcolor: 'rgba(0,0,0,0)',
    zeroline: false,
  };
  layoutRight.title = {
    ...(layoutRight.title as PlotTitle),
    text: intl.formatMessage({ id: msgId.trendLabelStbd }),
    y: 1,
    pad: { t: 6, b: 0, l: 0, r: 0 },
  };
  layoutRight.yaxis = {
    ...layoutRight.yaxis,
    mirror: true,
    range: [0, 100],
    linecolor: whiteFontColor,
  };
  layoutRight.xaxis = {
    ...layoutRight.xaxis,
    mirror: true,
    showticklabels: false,
    linecolor: whiteFontColor,
    gridcolor: 'rgba(0,0,0,0)',
    zeroline: false,
  };
  layoutRight.margin = { ...layoutRight.margin, b: layoutBottomMargin };
  layoutRight.height = graphPanelHeight + topLabelHeiht;
  let layout1: Partial<Layout>[] = [];
  let layout2: Partial<Layout>[] = [];
  const minPercent = layoutRange.minParsent ? layoutRange.minParsent : 0;
  const maxPercent = layoutRange.maxParsent ? layoutRange.maxParsent : 100;
  const annotations: Array<Partial<Annotations>> = [];
  const shapes: Array<Partial<Shape>> = [];

  layout.paper_bgcolor = 'rgba(0,0,0,0)';
  layout.plot_bgcolor = 'rgba(0,0,0,0)';
  layout.xaxis = {
    ...layout.xaxis,
    gridcolor: 'rgba(0,0,0,0)',
    zeroline: false,
    showticklabels: false,
    type: 'date',
    tickformatstops: [
      { dtickrange: [null, 1000], value: '%H:%M:%S.%Lms' },
      { dtickrange: [1000, 60000], value: '%H:%M:%Ss' },
      { dtickrange: [60000, 3600000], value: '%H:%Mm' },
      { dtickrange: [3600000, 86400000], value: '%H:%Mh' },
      { dtickrange: [86400000, 604800000], value: '%m-%d' },
      { dtickrange: [604800000, 'M1'], value: '%Y-%m-%d' },
      { dtickrange: ['M1', 'M12'], value: '%Y-%m-%d' },
      { dtickrange: ['M12', null], value: '%Y-%m' },
    ],
  };
  layout.yaxis = { ...layout.yaxis, gridcolor: 'rgba(0,0,0,0)', zeroline: false, type: 'linear' };

  chartSeriesList.map((series, index) => {
    const seriesRange = {
      min:
        series.sensorGroup.displayUpperLimit === 360 ||
          series.sensorGroup.displayUpperLimit === 359.9
          ? series.range.min - 180
          : series.range.min,
      max:
        series.sensorGroup.displayUpperLimit === 360 ||
          series.sensorGroup.displayUpperLimit === 359.9
          ? series.range.max - 180
          : series.range.max,
    };
    const range = seriesRange.max - seriesRange.min;
    const minVal = Math.round(((range * minPercent) / 100 + seriesRange.min) * 100) / 100;
    const maxVal = Math.round(((range * maxPercent) / 100 + seriesRange.min) * 100) / 100;
    const l1 = Object.assign({}, layout);
    if (layoutRange['xaxis.range[0]']) {
      l1.xaxis = {
        ...l1.xaxis,
        gridcolor: '#444',
        showticklabels: true,
        autorange: false,
        range: [layoutRange['xaxis.range[0]'], layoutRange['xaxis.range[1]']],
      };
    } else {
      l1.xaxis = { ...l1.xaxis, gridcolor: '#444', showticklabels: true, autorange: true };
    }
    const annStart = 0.01;
    const annWidth = (582 / (width - 10)) * 0.07;
    const annLineWidth = 1;
    const annLabelOffset = 0.005;
    const annotationUnit: Partial<Annotations> = {
      xref: 'paper',
      yref: 'paper',
      x: annStart + annWidth * index,
      xanchor: 'left',
      y: 1,
      yanchor: 'bottom',
      text: series.sensorGroup.displayUnit != null ? series.sensorGroup.displayUnit : '',
      showarrow: false,
      font: {
        size: 9,
        color: series.labelColor,
      },
    };
    const annotationMax: Partial<Annotations> = {
      ...annotationUnit,
      y: 1.0 + (322 / (graphPanelHeight - topLabelHeiht - 49)) * 0.027,
      text: roundValueToString(maxVal, series.sensorGroup.displayRoundingPosition),
    };
    const annotationMid: Partial<Annotations> = {
      ...annotationUnit,
      y: 0.5,
      text: roundValueToString((maxVal + minVal) / 2, series.sensorGroup.displayRoundingPosition),
    };
    const annotationMin: Partial<Annotations> = {
      ...annotationUnit,
      y: 0,
      text: roundValueToString(minVal, series.sensorGroup.displayRoundingPosition),
    };
    const shape: Partial<Shape> = {
      type: 'line',
      xref: 'paper',
      yref: 'paper',
      x0: annStart + annWidth * index,
      y0: 0,
      x1: annStart + annWidth * index,
      y1: 1,
      line: {
        color: series.color,
        width: annLineWidth,
      },
    };
    const shapeMax: Partial<Shape> = {
      ...shape,
      y0: 1,
      x1: annStart + annWidth * index + annLabelOffset,
      y1: 1,
    };
    const shapeMid: Partial<Shape> = {
      ...shapeMax,
      y0: 0.5,
      y1: 0.5,
    };
    const shapeMin: Partial<Shape> = {
      ...shapeMax,
      y0: 1,
      y1: 1,
    };
    annotations.push(annotationUnit);
    annotations.push(annotationMax);
    annotations.push(annotationMid);
    annotations.push(annotationMin);
    shapes.push(shape);
    shapes.push(shapeMax);
    shapes.push(shapeMid);
    shapes.push(shapeMin);

    l1.yaxis = { ...l1.yaxis, range: [minVal, maxVal], showgrid: false };
    const l2 = Object.assign({}, l1);
    layout1 = [...layout1, l1];
    layout2 = [...layout2, l2];

    return true;
  });

  layout.annotations = annotations;
  layout.shapes = shapes;
  layout1.map((value, index) => {
    switch (index) {
      case 0:
        layout.xaxis = { ...value.xaxis };
        layout.yaxis = { ...value.yaxis };
        break;
      case 1:
        layout.yaxis2 = { ...value.yaxis, overlaying: 'y' };
        break;
      case 2:
        layout.yaxis3 = { ...value.yaxis, overlaying: 'y' };
        break;
      case 3:
        layout.yaxis4 = { ...value.yaxis, overlaying: 'y' };
        break;
      case 4:
        layout.yaxis5 = { ...value.yaxis, overlaying: 'y' };
        break;
      case 5:
        layout.yaxis6 = { ...value.yaxis, overlaying: 'y' };
        break;
      case 6:
        layout.yaxis7 = { ...value.yaxis, overlaying: 'y' };
        break;
      case 7:
        layout.yaxis8 = { ...value.yaxis, overlaying: 'y' };
        break;
      case 8:
        layout.yaxis9 = { ...value.yaxis, overlaying: 'y' };
        break;
      default:
        break;
    }

    return true;
  });

  const plotConfig = { displayModeBar: false, showTips: false };

  function hoverProc(event: PlotMouseEvent) {
    let xval: Datum = null;
    if (event.points.length > 0) {
      xval = event.points[0].x;
      if (xval != null) {
        if (typeof xval === 'string') {
          if (xval.length === 21) {
            xval = dayjs(xval + '00')
              .format(constants.dateFormat.YYYYMMDDHHmmssSSS)
              .substring(0, 21);
          } else {
            xval = dayjs(xval).format(constants.dateFormat.YYYYMMDDHHmmssSSS).substring(0, 21);
          }
        } else {
          xval = dayjs(xval).format(constants.dateFormat.YYYYMMDDHHmmssSSS).substring(0, 21);
        }
      }
    }
    let indexName: string;
    if (xval === null) {
      indexName = '';
    } else {
      indexName = xval.toString().split('-').join('/');
      if (indexName.length !== 21) {
        indexName += '.0';
      }
    }
    const element = event.points[0];

    const divL = React.createElement(
      'div',
      { style: { lineHeight: '1', background: '#000', padding: '1px', marginLeft: 'auto' } },
      indexName,
      chartSeriesList.map((series, i) => {
        const color = { style: { color: series.color, fontSize: 8 } };
        let elementValue;
        if (series.portSensorData != null) {
          let data = series.portSensorData.data[element.pointIndex];
          if (
            series.sensorGroup.displayUpperLimit === 360 ||
            series.sensorGroup.displayUpperLimit === 359.9
          ) {
            data = data > 180 ? data - 360 : data;
          }

          const dispval = roundValueToString(
            data,
            series.portSensorData.sensor.displayRoundingPosition
          );
          elementValue =
            ',' +
            intl.formatMessage({
              id: trendLabelPrefix + series.sensorGroup.sensors[0].sensorName,
            }) +
            ':' +
            dispval;
        } else {
          elementValue = '';
        }

        return React.createElement('span', { key: 'diagHoverLSeries' + i, ...color }, elementValue);
      })
    );

    const divR = React.createElement(
      'div',
      { style: { lineHeight: '1', background: '#000', padding: '1px' } },
      indexName,
      chartSeriesList.map((series, i) => {
        const color = { style: { color: series.color, fontSize: 8 } };
        let elementValue;
        if (series.starboardSensorData != null) {
          let data = series.starboardSensorData.data[element.pointIndex];
          if (
            series.sensorGroup.displayUpperLimit === 360 ||
            series.sensorGroup.displayUpperLimit === 359.9
          ) {
            data = data > 180 ? data - 360 : data;
          }
          const dispval = roundValueToString(
            data,
            series.starboardSensorData.sensor.displayRoundingPosition
          );
          elementValue =
            ',' +
            intl.formatMessage({
              id:
                trendLabelPrefix +
                (series.sensorGroup.sensors.length === 2
                  ? series.sensorGroup.sensors[1].sensorName
                  : series.sensorGroup.sensors[0].sensorName),
            }) +
            ':' +
            dispval;
        } else {
          elementValue = '';
        }

        return React.createElement('span', { key: 'diagHoverRSeries' + i, ...color }, elementValue);
      })
    );

    const tickLeftId = 'tickLeft' + chartId;
    const tickRightId = 'tickRight' + chartId;

    ReactDOM.render(divL, document.getElementById(tickLeftId));
    ReactDOM.render(divR, document.getElementById(tickRightId));
  }

  function onHover(event: PlotMouseEvent) {
    hoverProc(event);
  }

  function onClick(event: Readonly<Plotly.PlotMouseEvent>) {
    // logger.debug(event);
    if (ActiveLine) {
      const curveNumber = event.points[0].curveNumber;
      const PlotLId = 'PlotL' + chartId;
      const PlotRId = 'PlotR' + chartId;
      const elementL = document.querySelector('#' + PlotLId) as HTMLElement;
      const elementR = document.querySelector('#' + PlotRId) as HTMLElement;
      chartSeriesList.forEach((series, index) => {
        if (curveNumber === index) {
          PlotlyRestyle(elementL, { line: { width: 3, color: series.color } }, index);
          PlotlyRestyle(elementR, { line: { width: 3, color: series.color } }, index);
        } else {
          PlotlyRestyle(elementL, { line: { width: 1, color: series.color } }, index);
          PlotlyRestyle(elementR, { line: { width: 1, color: series.color } }, index);
        }
      });
    }
  }
  function onUnHover() {
    const tickLeftId = 'tickLeft' + chartId;
    const tickRightId = 'tickRight' + chartId;
    const div = React.createElement('div');
    ReactDOM.render(div, document.getElementById(tickLeftId));
    ReactDOM.render(div, document.getElementById(tickRightId));
  }
  function onRelayout(event: Plotly.PlotRelayoutEvent) {
    if (doubleClick) return;
    const e = event as LayoutChange;
    if (
      e['xaxis.autorange'] === undefined &&
      e['xaxis.range[0]'] === undefined &&
      e['xaxis.range[1]'] === undefined &&
      e['yaxis.autorange'] === undefined &&
      e['yaxis.range[0]'] === undefined &&
      e['yaxis.range[1]'] === undefined
    ) {
      return;
    }
    if (chartSeriesList.length === 0) {
      return;
    }

    let xaxis = {};
    let yaxis = {};
    if (e['xaxis.autorange'] !== undefined) {
      if (e['xaxis.autorange']) {
        xaxis = { 'xaxis.autorange': true };
      } else {
        xaxis = {
          'xaxis.autorange': false,
          'xaxis.range[0]': layoutRange['xaxis.range[0]'],
          'xaxis.range[1]': layoutRange['xaxis.range[1]'],
        };
      }
    } else {
      if (e['xaxis.range[0]'] !== undefined) {
        xaxis = {
          'xaxis.autorange': false,
          'xaxis.range[0]': layoutRange['xaxis.range[0]'],
          'xaxis.range[1]': layoutRange['xaxis.range[1]'],
        };
      } else {
        xaxis = {
          'xaxis.range[0]': layoutRange['xaxis.range[0]'],
          'xaxis.range[1]': layoutRange['xaxis.range[1]'],
        };
      }
    }
    if (e['yaxis.autorange'] !== undefined) {
      if (e['yaxis.autorange']) {
        yaxis = {
          'yaxis.autorange': false,
          'yaxis.range[0]': chartSeriesList[0].range.min,
          'yaxis.range[1]': chartSeriesList[0].range.max,
        };
      } else {
        yaxis = {
          'yaxis.autorange': false,
          'yaxis.range[0]': layoutRange['yaxis.range[0]'],
          'yaxis.range[1]': layoutRange['yaxis.range[1]'],
        };
      }
    } else {
      if (e['yaxis.range[0]'] !== undefined) {
        yaxis = {
          'yaxis.autorange': false,
          'yaxis.range[0]': layoutRange['yaxis.range[0]'],
          'yaxis.range[1]': layoutRange['yaxis.range[1]'],
        };
      } else {
        yaxis = {
          'yaxis.range[0]': layoutRange['yaxis.range[0]'],
          'yaxis.range[1]': layoutRange['yaxis.range[1]'],
        };
      }
    }
    const layout = Object.assign(xaxis, yaxis) as LayoutChange;
    const changeLayout = Object.assign(layout, event) as LayoutChange;
    let minParsent = 0;
    let maxParsent = 100;
    if (changeLayout['yaxis.range[0]'] !== undefined && chartSeriesList.length > 0) {
      const min = changeLayout['yaxis.range[0]'] as number;
      const max = changeLayout['yaxis.range[1]'] as number;
      const range = chartSeriesList[0].range.max - chartSeriesList[0].range.min;
      minParsent = ((min - chartSeriesList[0].range.min) / range) * 100;
      maxParsent = ((max - chartSeriesList[0].range.min) / range) * 100;
    }

    setLayoutRange({
      ...layout,
      ...event,
      index: 0,
      minParsent: minParsent,
      maxParsent: maxParsent,
    });
  }

  function resetLayout() {
    doubleClick = true;
    setLayoutRange({ 'xaxis.autorange': true, minParsent: 0, maxParsent: 100 });
    setTimeout(() => {
      doubleClick = false;
    }, 50);
  }

  const styleLeftPlot: React.CSSProperties = {
    position: 'absolute',
    top: 0,
    left: 0,
    background: 'rgba(0,0,0,0)',
  };
  const styleRightPlot: React.CSSProperties = {
    position: 'absolute',
    top: graphPanelHeight - topLabelHeiht,
    left: 0,
    background: 'rgba(0,0,0,0)',
  };
  const styleTickRight: React.CSSProperties = {
    textAlign: 'right',
    maxWidth: graphPanelWidth - 50 + 'px',
    height: layoutBottomMargin,
    top: graphPanelHeight - topLabelHeiht + 'px',
    right: 0,
  };
  const styleTickLeft: React.CSSProperties = {
    textAlign: 'right',
    maxWidth: graphPanelWidth - 50 + 'px',
    height: layoutBottomMargin,
    top: 0,
    right: 0,
  };

  const setAxisDragHandlesDisabled = (graphDiv: HTMLDivElement | undefined) => {
    if (graphDiv != null) {
      const classNames = [
        'nwdrag drag cursor-nw-resize',
        'swdrag drag cursor-sw-resize',
        'nsdrag drag cursor-ns-resize',
        'sdrag drag cursor-s-resize',
        'ndrag drag cursor-n-resize',
      ];
      classNames.forEach((cn) => {
        const rects = graphDiv.getElementsByClassName(cn);
        if (rects != null) {
          for (let i = 0; i < rects.length; i++) {
            const style = rects[i].getAttribute('style')?.replace('all', 'none');
            if (style != null) {
              rects[i].setAttribute('style', style);
            }
          }
        }
      });
    }
  };

  const handleInitialized = (figure: Readonly<Figure>, graphDiv: Readonly<HTMLElement>) => {
    graphDiv.addEventListener('mouseout', () => {
      setTimeout(onUnHover, 20);
    });

    setAxisDragHandlesDisabled(graphDiv as HTMLDivElement);
  };

  const handleUpdate = (figure: Readonly<Figure>, graphDiv: Readonly<HTMLElement>) => {
    setAxisDragHandlesDisabled(graphDiv as HTMLDivElement);
  };

  const PlotLId = 'PlotL' + chartId;
  const PlotRId = 'PlotR' + chartId;

  layoutRight.margin = { ...layoutRight.margin, b: layoutBottomMargin };
  layoutRight.height = graphPanelHeight + topLabelHeiht;
  const tickLeftId = 'tickLeft' + chartId;
  const tickRightId = 'tickRight' + chartId;

  const layoutL = Object.assign({}, layout);
  const layoutR = Object.assign({}, layout);
  layoutL.shapes = layoutL.shapes?.concat(thresholdShapeL);
  layoutR.shapes = layoutR.shapes?.concat(thresholdShapeR);
  layoutL.annotations = layoutL.annotations?.concat(alertPointL);
  layoutR.annotations = layoutR.annotations?.concat(alertPointR);

  return (
    <RootRiv sx={{ width: width }}>
      <ValuePanel id={tickLeftId} sx={styleTickLeft} />
      <ValuePanel id={tickRightId} sx={styleTickRight} />
      <Plot style={styleLeftPlot} data={[dumyData]} layout={layoutLeft} config={plotConfig} />
      <Plot
        divId={PlotLId}
        style={styleLeftPlot}
        data={[...graphDataL, ...graphHighLightL]}
        layout={{ ...layoutL, xaxis: { ...layoutL.xaxis, tickfont: { color: 'rgba(0,0,0,0)' } } }}
        config={plotConfig}
        onInitialized={handleInitialized}
        onUpdate={handleUpdate}
        onHover={(e) => onHover(e)}
        onUnhover={() => onUnHover()}
        onRelayout={(e) => onRelayout(e)}
        onDoubleClick={() => resetLayout()}
        onClick={onClick}
      />
      <Plot style={styleRightPlot} data={[dumyData]} layout={layoutRight} config={plotConfig} />
      <Plot
        divId={PlotRId}
        style={styleRightPlot}
        data={[...graphDataR, ...graphHighLightR]}
        layout={{ ...layoutR, margin: { ...layoutRight.margin }, height: layoutRight.height }}
        config={plotConfig}
        onInitialized={handleInitialized}
        onUpdate={handleUpdate}
        onHover={(e) => onHover(e)}
        onUnhover={() => onUnHover()}
        onRelayout={(e) => onRelayout(e)}
        onDoubleClick={() => resetLayout()}
        onClick={onClick}
      />
    </RootRiv>
  );
}
