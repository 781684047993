import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { uploadSettingsAdminAsync } from 'api/maricoApiAdmin';
import { RootState } from 'app/rootReducer';
import { AppThunk } from 'app/store';
import { UpdateResult } from 'models/admin';
import { getErrorMsgIdFromErrorResult, getErrorResult } from 'models/error';
import { useSelector } from 'react-redux';
import msgId from 'resources/intl';

interface SystemSettingsState {
  /** 更新中状態 */
  updating: boolean;
  /** 更新結果 */
  updateResult: UpdateResult | undefined;
  /** センサー診断閾値設定エラー */
  sensorDiagnosisThresholdsError: boolean;
  /** 主機出荷時工場試験データ値設定エラー */
  megFactoryTestDataError: boolean;
  /** 海上運転データ値設定エラー */
  maritimeDrivingDataError: boolean;
}

const initialState: SystemSettingsState = {
  updating: false,
  updateResult: undefined,
  sensorDiagnosisThresholdsError: false,
  megFactoryTestDataError: false,
  maritimeDrivingDataError: false,
};

const systemSettings = createSlice({
  name: 'systemSettings',
  initialState,
  reducers: {
    /**
     * 更新の開始を設定する。
     */
    startUpdate: (state, action: PayloadAction<boolean>) => {
      state.updating = action.payload;
    },
    /**
     * 更新結果を設定する。
     */
    setUpdateResult: (state, { payload }: PayloadAction<UpdateResult | undefined>) => {
      state.updateResult = payload;
      state.updating = false;
    },
    /**
     * センサー診断閾値エラーを設定する。
     */
    setSensorDiagnosisThresholdsError: (state, { payload }: PayloadAction<boolean>) => {
      state.sensorDiagnosisThresholdsError = payload;
    },
    /**
     * 主機出荷時工場試験データ値エラーを設定する。
     */
    setMegFactoryTestDataError: (state, { payload }: PayloadAction<boolean>) => {
      state.megFactoryTestDataError = payload;
    },
    /**
     * 海上運転データ値エラーを設定する。
     */
    setMaritimeDrivingDataError: (state, { payload }: PayloadAction<boolean>) => {
      state.maritimeDrivingDataError = payload;
    },
    clearSystemSettings: (state) => {
      state.updating = initialState.updating;
      state.updateResult = initialState.updateResult;
      state.sensorDiagnosisThresholdsError = initialState.sensorDiagnosisThresholdsError;
      state.megFactoryTestDataError = initialState.megFactoryTestDataError;
      state.maritimeDrivingDataError = initialState.maritimeDrivingDataError;
    },
  },
});

export const {
  startUpdate,
  setUpdateResult,
  setSensorDiagnosisThresholdsError,
  setMegFactoryTestDataError,
  setMaritimeDrivingDataError,
  clearSystemSettings,
} = systemSettings.actions;

/**
 * センサー診断閾値設定をアップロードする。
 */
export const uploadSensorDiagnosisThresholds =
  (settings: string): AppThunk =>
  async (dispatch) => {
    dispatch(systemSettings.actions.startUpdate(true));
    try {
      await uploadSettingsAdminAsync('SensorDiagnosisThresholds', settings);
      dispatch(
        systemSettings.actions.setUpdateResult({
          isSuccess: true,
          showDialog: true,
          msgId: msgId.resultCompleted,
          isDeleteRequest: false,
        })
      );
    } catch (error) {
      const errorResult = getErrorResult(error);
      dispatch(
        systemSettings.actions.setUpdateResult({
          isSuccess: false,
          showDialog: true,
          msgId: getErrorMsgIdFromErrorResult(errorResult),
          isDeleteRequest: false,
        })
      );
    }
  };

/**
 * 主機出荷時工場試験データ値設定をアップロードする。
 */
export const uploadMegFactoryTestData =
  (settings: string): AppThunk =>
  async (dispatch) => {
    dispatch(systemSettings.actions.startUpdate(true));
    try {
      await uploadSettingsAdminAsync('MegFactoryTestData', settings);
      dispatch(
        systemSettings.actions.setUpdateResult({
          isSuccess: true,
          showDialog: true,
          msgId: msgId.resultCompleted,
          isDeleteRequest: false,
        })
      );
    } catch (error) {
      const errorResult = getErrorResult(error);
      dispatch(
        systemSettings.actions.setUpdateResult({
          isSuccess: false,
          showDialog: true,
          msgId: getErrorMsgIdFromErrorResult(errorResult),
          isDeleteRequest: false,
        })
      );
    }
  };

/**
 * 海上運転データ値設定をアップロードする。
 */
export const uploadMaritimeDrivingData =
  (settings: string): AppThunk =>
  async (dispatch) => {
    dispatch(systemSettings.actions.startUpdate(true));
    try {
      await uploadSettingsAdminAsync('MaritimeDrivingData', settings);
      dispatch(
        systemSettings.actions.setUpdateResult({
          isSuccess: true,
          showDialog: true,
          msgId: msgId.resultCompleted,
          isDeleteRequest: false,
        })
      );
    } catch (error) {
      const errorResult = getErrorResult(error);
      dispatch(
        systemSettings.actions.setUpdateResult({
          isSuccess: false,
          showDialog: true,
          msgId: getErrorMsgIdFromErrorResult(errorResult),
          isDeleteRequest: false,
        })
      );
    }
  };

const systemSettingsState = (state: RootState) => state.systemSettings;
const selectUpdating = createSelector(systemSettingsState, (x) => x.updating);
const selectUpdateResult = createSelector(systemSettingsState, (x) => x.updateResult);
const selectSensorDiagnosisThresholdsError = createSelector(
  systemSettingsState,
  (x) => x.sensorDiagnosisThresholdsError
);
const selectMegFactoryTestDataError = createSelector(
  systemSettingsState,
  (x) => x.megFactoryTestDataError
);
const selectMaritimeDrivingDataError = createSelector(
  systemSettingsState,
  (x) => x.maritimeDrivingDataError
);

export const useSystemSettingsUpdating = () => useSelector(selectUpdating);
export const useSystemSettingsUpdateResult = () => useSelector(selectUpdateResult);
export const useSystemSettingsSensorDiagnosisThresholdsError = () =>
  useSelector(selectSensorDiagnosisThresholdsError);
export const useSystemSettingsMegFactoryTestDataError = () =>
  useSelector(selectMegFactoryTestDataError);
export const useSystemSettingsMaritimeDrivingDataError = () =>
  useSelector(selectMaritimeDrivingDataError);

export default systemSettings.reducer;
