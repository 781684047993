import { styled, SxProps } from '@mui/material';
import React from 'react';
import { useIntl } from 'react-intl';
import colors from 'resources/colors';
import constants from 'resources/constants';
import { roundValue, roundValueToString } from 'utils/misc';
import { SensorValue } from './MimicContainer';
import { TempInfoComponent as TempInfo } from './TempInfoComponent';

const gageWidth = 140;
const gageBaseLeft = 30;
const gageHeight = 20;
const gageTop = 12;
const zeroPointColor = '#9EFF3E';

const RootDiv = styled('div')({
  border: `1px solid ${colors.chart.mimic.border}`,
  padding: '2px',
  position: 'absolute',
  overflow: 'hidden',
  fontSize: 0,
});

const BaseBackgroundDiv = styled('div')({
  width: '100%',
  height: '100%',
  position: 'relative',
  background: 'black',
  paddingLeft: '2px',
});

const PanelBaseDiv = styled('div')({
  width: '100%',
  height: '100%',
});

const ItemCppDiv = styled('div')({
  width: '100%',
  padding: '1px 4px',
  position: 'absolute',
});

const CppPanelDiv = styled('div')({
  border: `1px solid ${colors.chart.header.background}`,
  width: 'calc(100% + 4px)',
  left: -2,
  height: 90,
  top: 55,
  position: 'absolute',
});

const StrAngPanelDiv = styled('div')({
  width: '100%',
  left: 0,
  height: 66,
  top: 145,
  position: 'absolute',
  fontSize: 10,
  color: 'white',
});

const TitleOdFbDiv = styled('div')({
  fontSize: 12,
  color: 'white',
  float: 'left',
  maxWidth: '65%',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  userSelect: 'none',
});

const ValueOdFbDiv = styled('div')({
  fontSize: 14,
  color: colors.chart.mimic.value,
  float: 'right',
  maxWidth: '35%',
  userSelect: 'none',
});

const TitleAngleDiv = styled('div')({
  fontSize: 12,
  color: 'white',
  float: 'left',
  width: '100%',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  userSelect: 'none',
});

const ValueAngOdFbDiv = styled('div')({
  fontSize: 14,
  color: colors.chart.mimic.value,
  width: '100%',
  userSelect: 'none',
});

const GagePanelDiv = styled('div')({
  position: 'absolute',
  width: '100%',
  top: 40,
});

const GageBaseDiv = styled('div')({
  position: 'relative',
});

const TriangleDiv = styled('div')({
  top: 2,
  left: gageBaseLeft,
  position: 'absolute',
  width: 0,
  height: 0,
  borderStyle: 'solid',
  borderWidth: '10px 7px 0 7px',
  borderColor: 'white transparent transparent transparent',
});

const As20Div = styled('div')({
  width: gageBaseLeft,
  position: 'absolute',
  fontSize: 10,
  color: 'white',
  left: 0,
  textAlign: 'right',
  top: gageTop,
  lineHeight: `${gageHeight}px`,
  verticalAlign: 'middle',
  padding: '2px',
  userSelect: 'none',
});

const GageBarBaseDiv = styled('div')({
  position: 'absolute',
  fontSize: 10,
  color: 'white',
  left: gageBaseLeft,
  width: gageWidth,
  border: `1px solid ${colors.chart.header.background}`,
  height: gageHeight,
  padding: '-1px',
  top: gageTop,
});

const Ah30Div = styled('div')({
  width: gageBaseLeft,
  position: 'absolute',
  fontSize: 10,
  color: 'white',
  left: gageBaseLeft + gageWidth,
  top: gageTop,
  lineHeight: `${gageHeight}px`,
  verticalAlign: 'middle',
  padding: '2px',
  userSelect: 'none',
});

const Gage10ParsentDiv = styled('div')({
  position: 'absolute',
  float: 'left',
  width: '10%',
  height: '100%',
  borderRight: `1px solid ${colors.chart.header.background}`,
  zIndex: 2,
});

const ZeroPointDiv = styled('div')({
  borderLeft: `3px solid ${zeroPointColor}`,
  position: 'absolute',
  marginTop: '-1px',
  marginBottom: '-1px',
  height: '100%',
  left: 'calc(40% - 2px)',
  top: 1,
  zIndex: 3,
});

const GageValueBarDiv = styled('div')({
  position: 'absolute',
  height: '100%',
  marginTop: '-1px',
  marginBottom: '-1px',
  top: 1,
  background: colors.chart.mimic.border,
  zIndex: 1,
});

const ZeroPointLabelDiv = styled('div')({
  position: 'absolute',
  height: '100%',
  left: gageBaseLeft + gageWidth * 0.4 - 4,
  top: 30,
  fontSize: 12,
  color: 'white',
  userSelect: 'none',
});

interface OdFbPanelProps {
  od: SensorValue;
  fb: SensorValue;
}
function CppPanel(props: OdFbPanelProps) {
  const { od, fb } = props;
  const intl = useIntl();

  let value = isNaN(od.value) ? 0 : od.value;
  value =
    value > od.sensor.displayUpperLimit
      ? od.sensor.displayUpperLimit
      : value < od.sensor.displayLowerLimit
        ? od.sensor.displayLowerLimit
        : value;
  let range = od.sensor.displayUpperLimit - od.sensor.displayLowerLimit;
  const pos = ((value - od.sensor.displayLowerLimit) / range) * gageWidth;
  const odValueRounded =
    isNaN(od.value) || od.value === null
      ? undefined
      : roundValue(od.value, od.sensor.displayRoundingPosition);
  const odDanger =
    od.sensor.warningUpperLimit != null && odValueRounded != null
      ? odValueRounded >= od.sensor.warningUpperLimit
      : false;
  const odValueText = roundValueToString(odValueRounded, od.sensor.displayRoundingPosition, true);

  value = isNaN(fb.value) ? 0 : fb.value;
  range = fb.sensor.displayUpperLimit - fb.sensor.displayLowerLimit;
  value =
    value > fb.sensor.displayUpperLimit
      ? fb.sensor.displayUpperLimit
      : value < fb.sensor.displayLowerLimit
        ? fb.sensor.displayLowerLimit
        : value;
  const rate = (value - fb.sensor.displayLowerLimit) / range;
  const fbValueRounded =
    isNaN(fb.value) || fb.value === null
      ? undefined
      : roundValue(fb.value, fb.sensor.displayRoundingPosition);
  const fbDanger =
    fb.sensor.warningUpperLimit != null && fbValueRounded != null
      ? fbValueRounded >= fb.sensor.warningUpperLimit
      : false;
  const fbValueText = roundValueToString(fbValueRounded, fb.sensor.displayRoundingPosition, true);

  let left: number | string = rate * gageWidth;
  let width: number;
  if (value < 0) {
    width = ((40 - rate * 100) / 100) * gageWidth;
  } else {
    width = ((rate * 100 - 40) / 100) * gageWidth;
    left = 'calc(40% - 1px)';
  }
  const fbProps: SxProps = {
    left: left,
    width: width + 'px',
    background: fbDanger ? colors.chart.mimic.gageDanger : colors.chart.mimic.border,
  };
  const separateProps: SxProps[] = [
    { left: 'calc(10% * 0)' },
    { left: 'calc(10% * 1)' },
    { left: 'calc(10% * 2)' },
    { left: 'calc(10% * 3)' },
    { left: 'calc(10% * 4)' },
    { left: 'calc(10% * 5)' },
    { left: 'calc(10% * 6)' },
    { left: 'calc(10% * 7)' },
    { left: 'calc(10% * 8)' },
    { left: 'calc(10% * 8)', border: 'none' },
  ];

  let unitOd = od.sensor.displayUnit;
  let unitFb = fb.sensor.displayUnit;
  unitOd = !(unitOd === undefined || unitOd === null || unitOd.length === 0)
    ? '[' + unitOd + ']'
    : '';
  unitFb = !(unitFb === undefined || unitFb === null || unitFb.length === 0)
    ? '[' + unitFb + ']'
    : '';

  // AppLogger.info(
  //   '[*CppPanel od] ' +
  //     od.sensor.sensorName +
  //     ': value=' +
  //     od.value +
  //     ', odDanger=' +
  //     odDanger +
  //     ', valueText=' +
  //     odValueText +
  //     ', warningUpperLimit=' +
  //     od.sensor.warningUpperLimit
  // );

  // AppLogger.info(
  //   '[*CppPanel fb] ' +
  //     fb.sensor.sensorName +
  //     ': value=' +
  //     fb.value +
  //     ', fbDanger=' +
  //     odDanger +
  //     ', valueText=' +
  //     fbValueText +
  //     ', warningUpperLimit=' +
  //     fb.sensor.warningUpperLimit
  // );

  return (
    <PanelBaseDiv>
      <ItemCppDiv sx={{ top: 4 }}>
        <TitleOdFbDiv>
          {intl.formatMessage({ id: constants.labelPrefix.mimic + od.sensor.sensorName }) + unitOd}
        </TitleOdFbDiv>
        <ValueOdFbDiv
          sx={{ color: odDanger ? colors.chart.mimic.danger : colors.chart.mimic.value }}
        >
          {odValueText}
        </ValueOdFbDiv>
      </ItemCppDiv>
      <ItemCppDiv sx={{ top: 22 }}>
        <TitleOdFbDiv>
          {intl.formatMessage({ id: constants.labelPrefix.mimic + fb.sensor.sensorName }) + unitFb}
        </TitleOdFbDiv>
        <ValueOdFbDiv
          sx={{ color: fbDanger ? colors.chart.mimic.danger : colors.chart.mimic.value }}
        >
          {fbValueText}
        </ValueOdFbDiv>
      </ItemCppDiv>
      <GagePanelDiv>
        <GageBaseDiv>
          {!isNaN(od.value) && <TriangleDiv sx={{ left: gageBaseLeft + pos - 7 }} />}
          <As20Div>AS20</As20Div>
          <GageBarBaseDiv>
            <ZeroPointDiv />
            <GageValueBarDiv sx={fbProps} />
            <Gage10ParsentDiv sx={separateProps[0]} />
            <Gage10ParsentDiv sx={separateProps[1]} />
            <Gage10ParsentDiv sx={separateProps[2]} />
            <Gage10ParsentDiv sx={separateProps[3]} />
            <Gage10ParsentDiv sx={separateProps[4]} />
            <Gage10ParsentDiv sx={separateProps[5]} />
            <Gage10ParsentDiv sx={separateProps[6]} />
            <Gage10ParsentDiv sx={separateProps[7]} />
            <Gage10ParsentDiv sx={separateProps[8]} />
            <Gage10ParsentDiv sx={separateProps[9]} />
          </GageBarBaseDiv>
          <Ah30Div>AH30</Ah30Div>
          <ZeroPointLabelDiv>0</ZeroPointLabelDiv>
        </GageBaseDiv>
      </GagePanelDiv>
    </PanelBaseDiv>
  );
}

function StrAngPanel(props: OdFbPanelProps) {
  const { od, fb } = props;
  const intl = useIntl();

  const odValueRounded =
    isNaN(od.value) || od.value === null
      ? undefined
      : roundValue(od.value, od.sensor.displayRoundingPosition);
  const odDanger =
    od.sensor.warningUpperLimit != null && odValueRounded != null
      ? odValueRounded >= od.sensor.warningUpperLimit
      : false;
  const odValueText = roundValueToString(odValueRounded, fb.sensor.displayRoundingPosition, true);

  const fbValueRounded =
    isNaN(fb.value) || fb.value === null
      ? undefined
      : roundValue(fb.value, fb.sensor.displayRoundingPosition);
  const fbDanger =
    fb.sensor.warningUpperLimit != null && fbValueRounded != null
      ? fbValueRounded >= fb.sensor.warningUpperLimit
      : false;
  const fbValueText = roundValueToString(fbValueRounded, fb.sensor.displayRoundingPosition, true);

  let unitOd = od.sensor.displayUnit;
  let unitFb = fb.sensor.displayUnit;
  unitOd = !(unitOd === undefined || unitOd === null || unitOd.length === 0)
    ? '[' + unitOd + ']'
    : '';
  unitFb = !(unitFb === undefined || unitFb === null || unitFb.length === 0)
    ? '[' + unitFb + ']'
    : '';

  return (
    <PanelBaseDiv sx={{ width: '100%', padding: '1px 4px' }}>
      <TitleAngleDiv>
        {intl.formatMessage({ id: constants.labelPrefix.mimic + od.sensor.sensorName }) + unitOd}
      </TitleAngleDiv>
      <ValueAngOdFbDiv
        sx={{ color: odDanger ? colors.chart.mimic.danger : colors.chart.mimic.value }}
      >
        {odValueText}
      </ValueAngOdFbDiv>
      <TitleAngleDiv>
        {intl.formatMessage({ id: constants.labelPrefix.mimic + fb.sensor.sensorName }) + unitFb}
      </TitleAngleDiv>
      <ValueAngOdFbDiv
        sx={{ color: fbDanger ? colors.chart.mimic.danger : colors.chart.mimic.value }}
      >
        {fbValueText}
      </ValueAngOdFbDiv>
    </PanelBaseDiv>
  );
}

interface RexPropellerInfoPanelProps {
  value?: SensorValue[];
  top: number;
  left: number;
  title?: string;
  unit?: string;
  rateV?: number;
  rateH?: number;
}

export function RexPropellerInfoPanel(props: RexPropellerInfoPanelProps): JSX.Element {
  const intl = useIntl();
  const rateH = props.rateH ? props.rateH : 1;
  const rateV = props.rateV ? props.rateV : 1;
  const basePanelHeight = 230 * rateV;
  const basePanelWidth = 210 * rateH;

  const rootDivProps: SxProps = {
    top: props.top * rateV,
    left: props.left * rateH,
    width: basePanelWidth,
    height: basePanelHeight,
  };

  if (!props.value) {
    return <RootDiv sx={rootDivProps}></RootDiv>;
  }

  let cppod: SensorValue | undefined;
  let cppfb: SensorValue | undefined;
  let saod: SensorValue | undefined;
  let safb: SensorValue | undefined;
  let temp: SensorValue | undefined;
  let press: SensorValue | undefined;
  props.value.forEach((sv) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { value, sensor, group } = sv;
    switch (group.sensorGroupName) {
      case 'RexCPPBladeAngOrderC1':
        cppod = sv;
        break;
      case 'RexCPPBladeAngFBC1':
        cppfb = sv;
        break;
      case 'RexStrAngOrderC1':
      case 'RexStrAngOrder':
        saod = sv;
        break;
      case 'RexStrAngFBC1':
      case 'RexStrAngFb':
        safb = sv;
        break;
      case 'RexLOTmpB':
      case 'RexLOTmpC2':
      case 'RexLoTemp':
        temp = sv;
        break;
      case 'RexLOPressC2':
      case 'RexLOPressB':
      case 'RexLoPress':
        press = sv;
        break;
      default:
        break;
    }
  });
  let tempTitle = '';
  if (temp) {
    let unit = temp.sensor.displayUnit;
    unit = !(unit === undefined || unit === null || unit.length === 0) ? '[' + unit + ']' : '';
    tempTitle =
      intl.formatMessage({ id: constants.labelPrefix.mimic + temp.sensor.sensorName }) + unit;
  }
  let pressTitle = '';
  if (press) {
    let unit = press.sensor.displayUnit;
    unit = !(unit === undefined || unit === null || unit.length === 0) ? '[' + unit + ']' : '';
    pressTitle =
      intl.formatMessage({ id: constants.labelPrefix.mimic + press.sensor.sensorName }) + unit;
  }

  // LO温度、LO圧力、CPP指令、CPP F/B、旋回角指令、旋回角F/B
  return (
    <RootDiv sx={rootDivProps}>
      <BaseBackgroundDiv>
        {temp && (
          <TempInfo
            key={temp.sensor.sensorName + '_' + temp.value}
            title={tempTitle}
            top={6}
            left={3}
            width={198}
            value={temp.value}
            sensor={temp.sensor}
            group={temp.group}
          ></TempInfo>
        )}
        {press && (
          <TempInfo
            key={press.sensor.sensorName + '_' + press.value}
            title={pressTitle}
            top={28}
            left={3}
            width={198}
            value={press.value}
            sensor={press.sensor}
            group={press.group}
          ></TempInfo>
        )}
        <CppPanelDiv>
          {cppod && cppfb && (
            <CppPanel key={cppod.value + '_' + cppfb.value} od={cppod} fb={cppfb} />
          )}
        </CppPanelDiv>
        <StrAngPanelDiv>
          {saod && safb && <StrAngPanel key={saod.value + '_' + safb.value} od={saod} fb={safb} />}
        </StrAngPanelDiv>
      </BaseBackgroundDiv>
    </RootDiv>
  );
}
