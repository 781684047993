import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import msgId from 'resources/intl';
import {
  deleteAccountGroup,
  setAccountGroupEditing,
  setEditAccountGroupError,
  useAdminSettingsEditAccountGroup,
  useAdminSettingsEditMode,
  useAdminSettingssEditAccountGroupError,
  registrationAccountGroup,
  updateAccountGroup,
  useAdminSettingsEditAccountGroupResult as useAdminSettingsUpdateResult,
  setEditAccountGroupResult as setUpdateResult,
  useAdminSettingsEditAccountGroupLogoBase64,
  getAccountGroupsLogoUrl,
  setAccountGroupLogoBase64,
  useAdminSettingsFetching,
} from '../adminSettingsSlice';
import { AdminAccountGroup } from 'models/accounts';
import {
  EditDialog,
  EditDialogContents,
  EditDialogFooterButtonGrid,
  EditDialogInputFieldGrid,
  EditDialogRootGrid,
  EditDialogTitleGrid,
} from 'components/EditDialog';
import { Box, Grid, Typography, styled } from '@mui/material';
import { SmallTextField } from 'components/SmallTextField';
import { theme } from 'resources/theme';
import { NormalButton } from 'components/NormalButton';
import { FileUplod } from 'components/FileUpload';
import { AlertDialog } from 'components/AlertDialog';
import { ErrorInfo, ErrorMessages, FieldLabels, getErrorMessage } from 'models/error';
import dimens from 'resources/dimens';
import IconKawasaki from 'resources/assets/IconKawasaki.svg';
import { CustomScrollBars } from 'components/CustomScrollBars';
import colors from 'resources/colors';
import { FadeLoading } from 'components/FadeLoading';
import constants from 'resources/constants';

const GroupLogoContents = styled('img')({
  marginTop: theme.spacing(1),
});

const GroupLogoEmpty = styled('div')({
  marginTop: theme.spacing(1),
});

interface AccountGroupEditDialogProps {
  contentHeight: number;
}

export function AccountGroupEditDialog(props: AccountGroupEditDialogProps): JSX.Element {
  const { contentHeight } = props;
  const intl = useIntl();
  const dispatch = useDispatch();
  const editMode = useAdminSettingsEditMode();
  const editAccountGroup = useAdminSettingsEditAccountGroup();
  const updateResult = useAdminSettingsUpdateResult();
  const editAccountGroupError = useAdminSettingssEditAccountGroupError();
  const accountGroupLogoBase64 = useAdminSettingsEditAccountGroupLogoBase64();
  const inProgress = useAdminSettingsFetching();
  const [state, setState] = useState<AdminAccountGroup>({
    accountGroupId: 0,
    accountGroupName: '',
    accountGroupUrl: '',
    accountGroupLogo: undefined,
    accountGroupLogoCleared: undefined,
  });
  const [deleteDialog, setDeleteDialog] = useState<boolean>(false);
  const [logoImg, setLogoImg] = useState<HTMLImageElement | undefined>(undefined);

  let errorMsg: ErrorMessages = {};
  let errors: ErrorInfo[] = [];
  const labels: FieldLabels = {
    PARAMETER_LENGTH_ERROR: {
      fallback: intl.formatMessage({ id: msgId.errorMessageLengthError100 }),
      accountGroupUrl: intl.formatMessage({ id: msgId.errorMessageLengthError50 }),
    },
    PARAMETER_DUPLICATION_ERROR: {
      fallback: intl.formatMessage({ id: msgId.errorMessageDuplicateGroupUrl }),
    },
    PARAMETER_INVALID_CHARACTER_ERROR: {
      fallback: intl.formatMessage({
        id: msgId.errorGroupUrlInvalidCharacter,
      }),
    },
    PARAMETER_REFERENCE_ERROR: {
      fallback: undefined,
    },
    fallback: { fallback: intl.formatMessage({ id: msgId.errorMessageOther }) },
  };

  if (editAccountGroupError) {
    if (Array.isArray(editAccountGroupError.response)) {
      errors = editAccountGroupError.response;
    } else {
      errors = [editAccountGroupError.response];
    }
  }
  errorMsg = getErrorMessage(errors, labels);

  if (logoImg) {
    if (errorMsg['accountGroupLogo']) {
      errorMsg['accountGroupLogo'] = intl.formatMessage({ id: msgId.logoFileIsNotSupported });
    } else if (!logoImg.src.includes('data:image/png')) {
      errorMsg['accountGroupLogo'] = intl.formatMessage({ id: msgId.logoFileIsNotSupported });
    } else if (
      !(
        logoImg.naturalWidth === constants.adminSettings.accountGroup.logo.supportWidth &&
        logoImg.naturalHeight === constants.adminSettings.accountGroup.logo.supportHeight
      )
    ) {
      errorMsg['accountGroupLogo'] = intl.formatMessage({ id: msgId.logoFileIsNotSupported });
    }
  } else if (state.accountGroupLogo != null) {
    // ファイルが設定されたがイメージではない場合
    errorMsg['accountGroupLogo'] = intl.formatMessage({ id: msgId.logoFileIsNotSupported });
  } else {
    errorMsg['accountGroupLogo'] = '';
  }
  if (state.accountGroupName === '') {
    errorMsg['accountGroupName'] = intl.formatMessage({ id: msgId.messageInputRequired });
  }
  if (
    state.accountGroupUrl !== undefined &&
    state.accountGroupUrl.length === 0 &&
    state.accountGroupId !== 1
  ) {
    errorMsg['accountGroupUrl'] = intl.formatMessage({ id: msgId.messageInputRequired });
  }
  if (state.accountGroupId === 0) {
    errorMsg['accountGroupId'] = intl.formatMessage({ id: msgId.messageInputRequired });
  }

  /**
   * バックボタン押下
   */
  const handleBackClick = () => {
    dispatch(setAccountGroupEditing({ editing: false, accountGroup: undefined }));
    dispatch(setEditAccountGroupError(undefined));
    setState({
      accountGroupId: 0,
      accountGroupName: '',
      accountGroupUrl: '',
      accountGroupLogo: undefined,
      accountGroupLogoCleared: undefined,
    });
    setLogoImg(undefined);
    dispatch(setAccountGroupLogoBase64(undefined));
  };

  /**
   * 削除ボタン押下時に呼ばれる。
   */
  const handleDeleteClick = () => {
    setDeleteDialog(true);
  };

  /**
   * 削除実行時に呼ばれる。
   */
  const handleDeleteClickOk = (result: string) => {
    if (editAccountGroup && result === 'ok') {
      dispatch(deleteAccountGroup(editAccountGroup.accountGroupId));
    }
    setDeleteDialog(false);
  };

  /**
   * 登録ボタン押下時に呼ばれる。
   */
  const handleRegistrationClick = () => {
    if (!editAccountGroup) {
      dispatch(registrationAccountGroup(state));
    } else {
      dispatch(
        updateAccountGroup(editAccountGroup.accountGroupId, {
          accountGroupId: state.accountGroupId,
          accountGroupName: state.accountGroupName,
          accountGroupUrl:
            editAccountGroup.accountGroupUrl === state.accountGroupUrl
              ? undefined
              : state.accountGroupUrl,
          accountGroupLogo: state.accountGroupLogo,
          accountGroupLogoCleared: state.accountGroupLogoCleared,
        })
      );
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    if (event.target.name === 'accountGroupId') {
      const val = event.target.value.replace(/[^0-9]/g, '');
      setState({ ...state, ['accountGroupId']: val === '' ? 0 : parseInt(val, 10) });
    } else {
      setState({ ...state, [event.target.name]: event.target.value });
    }
  };

  const handleAttachFile = (file: File | undefined) => {
    if (file) {
      const img = new Image();
      const reader = new FileReader();
      img.onload = () => {
        setLogoImg(img);
      };
      reader.onload = () => {
        const url = reader.result as string;
        img.src = url;
        const data = url.replace(/^.*,/, '');
        setState({ ...state, ['accountGroupLogo']: data });
      };
      reader.readAsDataURL(file);
    } else {
      setLogoImg(undefined);
      if (state.accountGroupLogoCleared != undefined) {
        setState({ ...state, ['accountGroupLogo']: undefined, ['accountGroupLogoCleared']: true });
        dispatch(setAccountGroupLogoBase64(IconKawasaki));
      } else {
        setState({ ...state, ['accountGroupLogo']: undefined });
      }
    }
  };

  const handleResultClear = (isSuccess: boolean) => {
    if (isSuccess) {
      dispatch(setAccountGroupEditing({ editing: false, accountGroup: undefined }));
      dispatch(setEditAccountGroupError(undefined));
      setDeleteDialog(false);
      setState({
        accountGroupId: 0,
        accountGroupName: '',
        accountGroupUrl: '',
        accountGroupLogo: undefined,
        accountGroupLogoCleared: undefined,
      });
      dispatch(setAccountGroupLogoBase64(undefined));
    }
    dispatch(setUpdateResult(undefined));
  };

  useEffect(() => {
    setState({
      accountGroupId: editAccountGroup ? editAccountGroup.accountGroupId : 0,
      accountGroupName: editAccountGroup ? editAccountGroup.accountGroupName : '',
      accountGroupUrl: editAccountGroup ? editAccountGroup.accountGroupUrl : '',
      accountGroupLogo: undefined,
      accountGroupLogoCleared:
        editAccountGroup && editAccountGroup.accountGroupLogoUrl != null ? false : undefined,
    });
    if (editAccountGroup) {
      dispatch(getAccountGroupsLogoUrl(editAccountGroup.accountGroupUrl));
    }
    setLogoImg(undefined);
    dispatch(setAccountGroupLogoBase64(undefined));
  }, [dispatch, editAccountGroup]);

  let logImageSrc = editAccountGroup
    ? logoImg
      ? logoImg.src
      : accountGroupLogoBase64
      ? accountGroupLogoBase64
      : undefined
    : logoImg
    ? logoImg.src
    : IconKawasaki;

  if (errorMsg['accountGroupLogo'].length > 0) {
    logImageSrc = undefined;
  }

  useEffect(() => {
    if (updateResult && updateResult.isSuccess && updateResult.isDeleteRequest) {
      dispatch(setAccountGroupEditing({ editing: false, accountGroup: undefined }));
      dispatch(setEditAccountGroupError(undefined));
      setDeleteDialog(false);
      setState({
        accountGroupId: 0,
        accountGroupName: '',
        accountGroupUrl: '',
        accountGroupLogo: undefined,
        accountGroupLogoCleared: undefined,
      });
      dispatch(setAccountGroupLogoBase64(undefined));
      dispatch(setUpdateResult(undefined));
    }
  }, [dispatch, updateResult]);

  return (
    <EditDialog
      id="accountGroupEditDialog"
      open={editMode === 'accountGroup'}
      onBackClick={handleBackClick}
      title={intl.formatMessage({
        id: editAccountGroup ? msgId.accountGroupInformation : msgId.accountGroupNewRegistration,
      })}
    >
      <EditDialogContents>
        <CustomScrollBars
          thumbColor={colors.scrollBar.thumb.light}
          height={contentHeight}
          width={dimens.dialogContents.width}
          autoHide={false}
        >
          <EditDialogRootGrid>
            <EditDialogTitleGrid>
              <Typography variant="h5">
                {intl.formatMessage({ id: msgId.guideMessageEditAccountGroup })}
              </Typography>
            </EditDialogTitleGrid>
            {editAccountGroup && (
              <EditDialogInputFieldGrid>
                <SmallTextField
                  id="accountGroupId"
                  fullWidth={true}
                  label={intl.formatMessage({ id: msgId.accountGroupId })}
                  required={true}
                  autoComplete="off"
                  value={state.accountGroupId !== 0 ? state.accountGroupId.toString() : ''}
                  disabled={editAccountGroup ? true : false}
                  onChange={handleChange}
                  error={errorMsg['accountGroupId'].length > 0}
                  helperText={errorMsg['accountGroupId']}
                />
              </EditDialogInputFieldGrid>
            )}
            <EditDialogInputFieldGrid>
              <SmallTextField
                id="accountGroupName"
                fullWidth={true}
                label={intl.formatMessage({ id: msgId.accountGroupName })}
                required={true}
                autoComplete="off"
                value={state.accountGroupName}
                onChange={handleChange}
                error={errorMsg['accountGroupName'].length > 0}
                helperText={errorMsg['accountGroupName']}
              />
            </EditDialogInputFieldGrid>
            <Grid
              container
              item
              direction="row"
              wrap="nowrap"
              justifyContent="center"
              alignItems="center"
              sx={{ width: 350 }}
            >
              <Typography variant="body2" sx={{ marginRight: '4px' }}>
                {window.location.origin}/
              </Typography>
              <SmallTextField
                id="accountGroupUrl"
                fullWidth={true}
                label={intl.formatMessage({ id: msgId.accountGroupUrl })}
                required={editAccountGroup && state.accountGroupId === 1 ? false : true}
                autoComplete="off"
                value={state.accountGroupUrl}
                onChange={handleChange}
                error={errorMsg['accountGroupUrl'].length > 0}
                helperText={errorMsg['accountGroupUrl']}
              />
            </Grid>
            <EditDialogInputFieldGrid>
              <Box sx={{ marginTop: theme.spacing(1) }}>
                {intl
                  .formatMessage({ id: msgId.guideMessageEditAccountGroupLogo })
                  .split('\n')
                  .map((t, i) => {
                    return (
                      <Typography key={i} variant="body2" align="left">
                        {t}
                      </Typography>
                    );
                  })}
              </Box>
            </EditDialogInputFieldGrid>
            <EditDialogInputFieldGrid>
              {
                /** edit modeから抜けたら初期化 */
                editMode === 'accountGroup' && (
                  <FileUplod
                    id="FileUplodAccountGroupLogo"
                    acceptFileType=".png"
                    onAttachFile={handleAttachFile}
                    errorMessage={errorMsg['accountGroupLogo']}
                    attatchedFileName={
                      state.accountGroupLogo != null ||
                      (state.accountGroupLogoCleared != null && !state.accountGroupLogoCleared)
                        ? intl.formatMessage({ id: msgId.groupLogo })
                        : undefined
                    }
                    sx={{ marginRight: '8px' }}
                  />
                )
              }
            </EditDialogInputFieldGrid>
            <EditDialogInputFieldGrid>
              {logImageSrc != null && (
                <GroupLogoContents src={logImageSrc} width={158.75} height={42.5} />
              )}
              {logImageSrc == null && <GroupLogoEmpty style={{ width: 158.75, height: 47.5 }} />}
            </EditDialogInputFieldGrid>
            <EditDialogFooterButtonGrid>
              {editAccountGroup && (
                <NormalButton
                  sx={{ marginRight: theme.spacing(2), width: dimens.button.footerWidth }}
                  label={intl.formatMessage({ id: msgId.delete })}
                  color="error"
                  onClick={() => handleDeleteClick()}
                />
              )}
              <NormalButton
                sx={{ width: dimens.button.footerWidth }}
                label={intl.formatMessage({ id: msgId.registration })}
                color="primary"
                disabled={
                  (!(editAccountGroup && editAccountGroup.accountGroupId === 1) &&
                    !state.accountGroupUrl) ||
                  !state.accountGroupName ||
                  errorMsg['accountGroupLogo'].length > 0
                }
                onClick={() => handleRegistrationClick()}
              />
            </EditDialogFooterButtonGrid>
          </EditDialogRootGrid>
          {updateResult != null && updateResult.showDialog && editMode === 'accountGroup' && (
            <AlertDialog
              title={intl.formatMessage({
                id: updateResult.isSuccess ? msgId.confirm : msgId.error,
              })}
              message={intl.formatMessage({ id: updateResult.msgId })}
              onClose={() => handleResultClear(updateResult.isSuccess)}
            />
          )}
          {deleteDialog && editMode === 'accountGroup' && (
            <AlertDialog
              title={intl.formatMessage({ id: msgId.delete })}
              message={intl.formatMessage({ id: msgId.confirmMessageDelete })}
              onClose={(result) => handleDeleteClickOk(result)}
              negativeButton={intl.formatMessage({ id: msgId.cancel })}
              positiveButton={intl.formatMessage({ id: msgId.ok })}
            />
          )}
        </CustomScrollBars>
        <FadeLoading loading={inProgress} />
      </EditDialogContents>
    </EditDialog>
  );
}
