import { createTheme } from '@mui/material/styles';
import colors from 'resources/colors';
import dimens from './dimens';

// A custom theme for this app
export const theme = createTheme({
  palette: {
    primary: {
      main: colors.primary,
    },
    secondary: {
      main: '#B4D4FF',
    },
    error: {
      main: '#CD0000',
    },
    background: {
      default: '#FFFFFF',
    },
    text: {
      primary: '#141414',
      secondary: '#43425D',
      disabled: colors.text.disabled,
    },
  },
  typography: {
    fontFamily: 'inherit',
    h1: {
      fontSize: '2.0rem',
    },
    h2: {
      fontSize: '1.8rem',
    },
    h3: {
      fontSize: '1.4rem',
    },
    h4: {
      fontSize: '16px',
    },
    h5: {
      fontSize: '13px',
    },
    h6: {
      fontSize: '12px',
    },
    subtitle1: {
      fontSize: '13px',
    },
    subtitle2: {
      fontSize: '12px',
    },
    body1: {
      fontSize: '13px',
    },
    body2: {
      fontSize: '12px',
    },
  },
  mixins: {
    toolbar: {
      minHeight: dimens.toolbar.height,
      height: dimens.toolbar.height,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          '&$disabled': {
            color: colors.text.disabled,
          },
          textTransform: 'none',
        },
        startIcon: {
          marginRight: '4px',
        },
        // label: {
        //   whiteSpace: 'nowrap',
        // },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          padding: '8px',
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          padding: 0,
          height: dimens.toolbar.height,
        },
        dense: {
          padding: 0,
          height: dimens.toolbar.height,
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          fontSize: '1.4rem',
        },
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        barColorPrimary: {
          color: colors.accent,
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        outlined: {
          color: colors.accent,
          '&.Mui-focused': {
            color: colors.accent,
          },
        },
        root: {
          color: colors.accent,
          '&.Mui-focused': {
            color: colors.accent,
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: '#666c72',
            },
            '&:hover fieldset': {
              borderColor: colors.accent,
            },
            '&.Mui-focused fieldset': {
              borderColor: colors.accent,
            },
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          background: colors.white,
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: '#666c72',
        },
      },
    },
  },
});

// overrides: {

//   MuiFormLabel: {
//     root: {
//       color: colors.accent,
//       '&$focused': {
//         color: colors.accent,
//       },
//     },
//     focused: {},
//   },
//   MuiInput: {
//     underline: {
//       '&:after': {
//         borderBottom: `2px solid ${colors.accent}`,
//       },
//       '&:hover:not($disabled):not($focused):not($error):before': {
//         borderBottom: `2px solid ${colors.primary}`,
//       },
//     },
//   },
//   MuiOutlinedInput: {
//     root: {
//       '&:hover $notchedOutline': {
//         borderColor: colors.primary,
//       },
//       focused: {
//         border: `1px solid ${colors.primary}`,
//       },
//       '& $notchedOutline': {
//         border: `1px solid ${colors.primary}`,
//       },
//       '&$focused $notchedOutline': {
//         borderColor: colors.accent,
//         borderWidth: '2px',
//       },
//     },
//     adornedEnd: {
//       paddingRight: '0px',
//     },
//   },
