import React from 'react';
import { useIntl } from 'react-intl';
import msgId from 'resources/intl';
import colors from 'resources/colors';
import { FuelConsumptionDetectionConditionItem } from 'models/analysis';
import { styled, Typography } from '@mui/material';
import { theme } from 'resources/theme';
import { NumberTextField } from 'components/NumberTextField';

const RootDiv = styled('div')({
  paddingLeft: theme.spacing(1),
  paddingTop: theme.spacing(0.5),
  width: 'auto',
  background: '#FFF',
  '& .MuiInputBase-root': {
    fontSize: '0.8rem',
  },
  flexWrap: 'nowrap',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
});

const numberTextFieldSxProps = {
  margin: theme.spacing(1),
  width: 90,
  minWidth: 90,
  color: colors.primary,
};

interface SearchConditionItemProps {
  condition: FuelConsumptionDetectionConditionItem;
  onChange: (
    id: string,
    caption: string,
    upperLimit: number,
    lowerLimit: number,
    upperValue: number,
    lowerValue: number
  ) => void;
}

export function SearchConditionItem(props: SearchConditionItemProps): JSX.Element {
  const { condition, onChange } = props;
  const intl = useIntl();

  /**
   * 下限変更
   */
  const handleMinChange = (value: string) => {
    const lowerValue = Number(value);
    if (lowerValue != null && !isNaN(lowerValue)) {
      onChange(
        condition.id,
        condition.sensorName,
        condition.upperLimit,
        condition.lowerLimit,
        condition.upperValue,
        lowerValue
      );
    }
  };

  /**
   * 下限確定
   */
  const handleMinBlur = (value: string) => {
    let lowerValue = Number(value);
    if (lowerValue != null && !isNaN(lowerValue)) {
      if (lowerValue < condition.lowerLimit) {
        lowerValue = condition.lowerLimit;
      } else if (lowerValue > condition.upperLimit) {
        lowerValue = condition.upperLimit;
      }
      onChange(
        condition.id,
        condition.sensorName,
        condition.upperLimit,
        condition.lowerLimit,
        condition.upperValue,
        lowerValue
      );
    }
  };

  /**
   * 上限変更
   */
  const handleMaxChange = (value: string) => {
    const upperValue = Number(value);
    if (upperValue != null && !isNaN(upperValue)) {
      onChange(
        condition.id,
        condition.sensorName,
        condition.upperLimit,
        condition.lowerLimit,
        upperValue,
        condition.lowerValue
      );
    }
  };

  /**
   * 上限確定
   */
  const handleMaxBlur = (value: string) => {
    let upperValue = Number(value);
    if (upperValue != null && !isNaN(upperValue)) {
      if (upperValue != null) {
        if (upperValue < condition.lowerLimit) {
          upperValue = condition.lowerLimit;
        } else if (upperValue > condition.upperLimit) {
          upperValue = condition.upperLimit;
        }
        onChange(
          condition.id,
          condition.sensorName,
          condition.upperLimit,
          condition.lowerLimit,
          upperValue,
          condition.lowerValue
        );
      }
    }
  };

  return (
    <RootDiv>
      <Typography
        sx={{
          width: 200,
        }}
      >
        {condition.sensorName}
      </Typography>
      <NumberTextField
        id={'analysis-min_' + condition.id}
        sx={numberTextFieldSxProps}
        variant="standard"
        label={intl.formatMessage({ id: msgId.dataLowerLimit })}
        value={condition.lowerValue}
        onChange={(value) => handleMinChange(value as string)}
        onBlur={(value) => handleMinBlur(value as string)}
        error={condition.invalidLowerLimit}
      />
      <Typography variant="body2">–</Typography>
      <NumberTextField
        id={'analysis-max_' + condition.id}
        sx={numberTextFieldSxProps}
        variant="standard"
        label={intl.formatMessage({ id: msgId.dataUpperLimit })}
        value={condition.upperValue}
        onChange={(value) => handleMaxChange(value as string)}
        onBlur={(value) => handleMaxBlur(value as string)}
        error={condition.invalidUpperLimit}
      />
      <Typography variant="body2" noWrap sx={{ paddingTop: '20px', width: 30 }}>
        {condition.unit}
      </Typography>
    </RootDiv>
  );
}
