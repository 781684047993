import React from 'react';
import ClutchBg from 'resources/assets/ClutchBg.svg';
import ClutchOff from 'resources/assets/ClutchOff.svg';
import ClutchOn from 'resources/assets/ClutchOn.svg';
import ClutchNan from 'resources/assets/ClutchNan.svg';
import { styled, SxProps } from '@mui/material';
const ClutchSize = 60;

const RootDiv = styled('div')({
  width: 280,
  height: 125,
  backgroundImage: `url(${ClutchBg})`,
  backgroundSize: '280px 125px',
});

const OnDiv = styled('div')({
  position: 'relative',
  left: 96,
  top: 26,
  width: ClutchSize,
  height: ClutchSize,
  backgroundImage: `url(${ClutchOn})`,
  backgroundSize: `${ClutchSize}px ${ClutchSize}px`,
});

const OffDiv = styled('div')({
  position: 'relative',
  left: 96,
  top: 26,
  width: ClutchSize,
  height: ClutchSize,
  backgroundImage: `url(${ClutchOff})`,
  backgroundSize: `${ClutchSize}px ${ClutchSize}px`,
});

const NanDiv = styled('div')({
  position: 'relative',
  left: 96,
  top: 26,
  width: ClutchSize,
  height: ClutchSize,
  backgroundImage: `url(${ClutchNan})`,
  backgroundSize: `${ClutchSize}px ${ClutchSize}px`,
});

interface ClutchPanelProps {
  sx?: SxProps;
  value: number;
  visible: boolean;
}

export function ClutchPanel(props: ClutchPanelProps): JSX.Element {
  const { sx, value, visible } = props;

  return (
    <RootDiv sx={sx}>
      {!isNaN(value) && (value === 1.0 || value === 0.0) ? (
        visible && value === 1.0 ? (
          <OnDiv />
        ) : (
          <OffDiv />
        )
      ) : (
        <NanDiv />
      )}
    </RootDiv>
  );
}
