import React from 'react';
import { Marker } from '@react-google-maps/api';
import { MarineWeatherMarkerData } from 'models/marineWeather';

interface MarineWeatherWindMarkerProps {
  markerDataList: MarineWeatherMarkerData[];
  zoom: number;
  onHover: (windInfo: MarineWeatherMarkerData) => void;
  onUnhover: (windInfo: MarineWeatherMarkerData) => void;
}

export function MarineWeatherWindMarker(props: MarineWeatherWindMarkerProps): JSX.Element {
  const { markerDataList, zoom, onHover, onUnhover } = props;
  let scale = (zoom + 1) / 10;
  if (scale > 1) {
    scale = 1;
  }

  return (
    <React.Fragment>
      {markerDataList.map((markerData, i) => {
        return (
          <Marker
            key={'marker' + i}
            position={{ lat: markerData.latitude, lng: markerData.longitude }}
            icon={{
              fillColor: markerData.color,
              fillOpacity: 0.8,
              strokeOpacity: 0.2,
              anchor: new window.google.maps.Point(12, 12),
              path: 'M12,2L4.5,20.29L5.21,21L12,18L18.79,21L19.5,20.29L12,2Z',
              rotation: markerData.windDirection + 180,
              scale: scale,
            }}
            onMouseOver={() => onHover(markerData)}
            onMouseOut={() => onUnhover(markerData)}
          />
        );
      })}
    </React.Fragment>
  );
}
