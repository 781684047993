import React, { useRef, useEffect } from 'react';
import { Box } from '@mui/material';
import { Ship } from 'models/ships';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import {
  setShipEditing,
  useAdminSettingsShips,
  useAdminSettingsEditShipResult as useAdminSettingsUpdateResult,
  fetchShipsSilent,
} from '../adminSettingsSlice';
import { ContentsHeader } from 'components/ContentsHeader';
import msgId from 'resources/intl';
import { AddCircle as AddCircleIcon, Search as SearchIcon } from '@mui/icons-material';
import colors from 'resources/colors';
import { NormalButton } from 'components/NormalButton';
import { DataGrid, Column, FilterRow, Scrolling, Selection } from 'devextreme-react/data-grid';
import dimens from 'resources/dimens';
import { DxDataGridColumn } from 'components/DxDataGrid';
import constants from 'resources/constants';
import { ShipEditDialog } from './ShipEditDialog';
import dayjs from 'dayjs';
import config from 'resources/config';
import { getTodayLimit } from 'utils/misc';

interface ShipManagementProps {
  open: boolean;
  height: number;
}

export function ShipManagement(props: ShipManagementProps): JSX.Element {
  const { open, height } = props;
  const intl = useIntl();
  const dispatch = useDispatch();
  const ships = useAdminSettingsShips();
  const updateResult = useAdminSettingsUpdateResult();
  const dataGridRef = useRef<DataGrid<Ship, unknown>>(null);
  const minDate = dayjs(config.minDate).toDate();
  const maxDate = dayjs(getTodayLimit()).toDate();
  const columns: DxDataGridColumn[] = [
    {
      key: 'shipId',
      dataField: 'shipId',
      caption: intl.formatMessage({ id: msgId.shipId }),
      width: 100,
      defaultSortOrder: 'asc',
    },
    {
      key: 'name',
      dataField: 'name',
      caption: intl.formatMessage({ id: msgId.shipName }),
      width: 200,
    },
    {
      key: 'propulsionSerialNumbersText',
      dataField: 'propulsionSerialNumbersText',
      caption: intl.formatMessage({ id: msgId.propulsionSerialNumber }),
    },
    {
      key: 'machineNamesText',
      dataField: 'machineNamesText',
      caption: intl.formatMessage({ id: msgId.machineName }),
      width: 150,
    },
    {
      key: 'createdAt',
      dataField: 'createdAt',
      dataType: 'datetime',
      filterOperations: constants.dataGrid.dateFilterOperations,
      format: constants.dateFormat.yyyyMMddHHmm,
      caption: intl.formatMessage({ id: msgId.createdAt }),
      width: 160,
      editorOptions: { min: minDate, max: maxDate },
    },
    {
      key: 'updatedAt',
      dataField: 'updatedAt',
      dataType: 'datetime',
      filterOperations: constants.dataGrid.dateFilterOperations,
      format: constants.dateFormat.yyyyMMddHHmm,
      caption: intl.formatMessage({ id: msgId.updatedAt }),
      width: 160,
      editorOptions: { min: minDate, max: maxDate },
    },
  ];

  /**
   * 行選択
   * @param ship 船舶
   */
  const handleSelectionChanged = (ship: Ship) => {
    dataGridRef.current?.instance.clearSelection();
    if (ship != null) {
      dispatch(setShipEditing({ editing: true, ship: ship }));
    }
  };

  /**
   * 新規登録
   */
  const handleRegistrationClick = () => {
    dispatch(setShipEditing({ editing: true, ship: undefined }));
  };

  /**
   * フィルターリセット
   */
  const handleFilterResetClick = () => {
    if (dataGridRef.current) {
      const dataGrid = dataGridRef.current.instance;
      columns.forEach((c) => {
        dataGrid.columnOption(c.dataField, 'selectedFilterOperation', null);
      });
      dataGrid.clearFilter();
    }
  };

  // 日付入力のフィルターをreadonlyにする。
  const setInputReadOnly = () => {
    const element = document.getElementById('shipDataGrid');
    if (element) {
      const elements = [5, 6];
      elements.forEach((colIndex) => {
        const dateFilterInput = element.querySelector(
          '.dx-datagrid-filter-row td[aria-colindex="' + colIndex + '"]'
        ) as HTMLElement;
        if (dateFilterInput) {
          const inputElement = dateFilterInput.querySelector(
            '.dx-texteditor-input'
          ) as HTMLInputElement;
          if (inputElement) {
            inputElement.readOnly = true;
          }
        }
      });
    }
  };

  /**
   * API完了後船舶情報を再取得
   */
  useEffect(() => {
    if (open && updateResult && updateResult.isSuccess) {
      dispatch(fetchShipsSilent());
    }
  }, [dispatch, open, updateResult]);

  return (
    <Box
      sx={{
        position: 'absolute',
        width: '100%',
        background: colors.subDrawer.background,
        visibility: open ? 'visible' : 'hidden',
      }}
    >
      {ships && (
        <React.Fragment>
          <ContentsHeader title={intl.formatMessage({ id: msgId.shipManagement })}>
            <NormalButton
              color="primary"
              label={intl.formatMessage({ id: msgId.filterReset })}
              startIcon={<SearchIcon />}
              onClick={() => handleFilterResetClick()}
            />
            <NormalButton
              label={intl.formatMessage({ id: msgId.newRegistration })}
              color="primary"
              startIcon={<AddCircleIcon />}
              onClick={() => handleRegistrationClick()}
            />
          </ContentsHeader>
          <DataGrid
            id="shipDataGrid"
            style={{ margin: dimens.dataGrid.margin }}
            ref={dataGridRef}
            height={height - dimens.dataGrid.offsetHeight}
            dataSource={ships}
            showBorders={true}
            allowColumnResizing={true}
            hoverStateEnabled={true}
            columnResizingMode="nextColumn"
            keyExpr="shipId"
            columnAutoWidth={true}
            onSelectionChanged={(e) => handleSelectionChanged(e.selectedRowsData[0])}
            onCellClick={setInputReadOnly}
          >
            <Selection mode="single" />
            <FilterRow visible={true} />
            <Scrolling mode="virtual" />
            {columns.map((column) => {
              return (
                <Column
                  key={column.key}
                  name={column.key}
                  dataField={column.dataField}
                  minWidth={dimens.dataGrid.column.minWidth}
                  dataType={column.dataType}
                  format={column.format}
                  caption={column.caption}
                  width={column.width}
                  filterOperations={column.filterOperations}
                  defaultSortOrder={column.defaultSortOrder}
                  editorOptions={column.editorOptions}
                />
              );
            })}
          </DataGrid>
        </React.Fragment>
      )}
      <ShipEditDialog contentHeight={height} />
    </Box>
  );
}
