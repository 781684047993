import React, { useEffect, useRef } from 'react';
import { styled } from '@mui/material';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { ContentsHeader } from 'components/ContentsHeader';
import msgId from 'resources/intl';
import { AddCircle as AddCircleIcon, Search as SearchIcon } from '@mui/icons-material';
import colors from 'resources/colors';
import { NormalButton } from 'components/NormalButton';
import { DataGrid, Column, FilterRow, Scrolling, Selection } from 'devextreme-react/data-grid';
import dimens from 'resources/dimens';
import { DxDataGridColumn } from 'components/DxDataGrid';
import constants from 'resources/constants';
import { MaintenanceEditDialog } from './MaintenanceEditDialog';
import config from 'resources/config';
import dayjs from 'dayjs';
import { MaintenanceData } from 'models/maintenance';
import {
  fetchMaintenanceDataList,
  setEditMaintenanceData,
  useEditMaintenanceDataResult,
  useMaintenanceDataList,
  useMaintenanceListFetching,
} from './maintenanceSlice';
import { FadeLoading } from 'components/FadeLoading';
import { useAccount } from 'features/login/accountSlice';
import { AuthorityAdministrators, AuthorityAdminUsers, AuthorityDevelopers } from 'models/accounts';
import { fetchDiagnosisListItems } from '../diagnosis/diagnosisSlice';

const RootDiv = styled('div')({
  width: '100%',
  height: '100%',
  padding: 0,
  background: colors.subDrawer.background,
  overflow: 'hidden',
});

interface MaintenanceListProps {
  height: number;
  contentsHeight: number;
}

export function MaintenanceList(props: MaintenanceListProps): JSX.Element {
  const { height, contentsHeight } = props;
  const intl = useIntl();
  const dispatch = useDispatch();
  const account = useAccount();
  const maintenanceDataList = useMaintenanceDataList();
  const maintenanceDataResult = useEditMaintenanceDataResult();
  const listFetching = useMaintenanceListFetching();
  const dataGridRef = useRef<DataGrid<MaintenanceData, unknown>>(null);
  const minDate = dayjs(config.minDate).toDate();
  const timezoneOffset = new Date().getTimezoneOffset();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const startDateCalculateDisplayValue = (rowData: any) => {
    const maintenanceData: MaintenanceData = rowData;

    return dayjs(maintenanceData.startDate).utc().format(constants.dateFormat.YYYYMMDDHHmm);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const startDateCalculateCellValue = (rowData: any) => {
    const maintenanceData: MaintenanceData = rowData;
    const date = dayjs(maintenanceData.startDate).add(timezoneOffset, 'minute').valueOf();

    return date;
  };

  const startDateCalculateFilterExpression = (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    value: any,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    comparisonOperator: any,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unused-vars
    target: any
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ): any[] => {
    if (comparisonOperator === 'between' && Array.isArray(value)) {
      const start = dayjs(value[0]).valueOf();
      const end = dayjs(value[1]).valueOf();
      const filterExpression = [
        [startDateCalculateCellValue, '>=', start],
        'and',
        [startDateCalculateCellValue, '<=', end],
      ];

      return filterExpression;
    } else {
      const date = dayjs(value).valueOf();

      return [startDateCalculateCellValue, comparisonOperator, date];
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const endDateCalculateDisplayValue = (rowData: any) => {
    const maintenanceData: MaintenanceData = rowData;

    return dayjs(maintenanceData.endDate).utc().format(constants.dateFormat.YYYYMMDDHHmm);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const endDateCalculateCellValue = (rowData: any) => {
    const maintenanceData: MaintenanceData = rowData;
    const date = dayjs(maintenanceData.endDate).add(timezoneOffset, 'minute').valueOf();

    return date;
  };

  const endDateCalculateFilterExpression = (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    value: any,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    comparisonOperator: any,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unused-vars
    target: any
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ): any[] => {
    if (comparisonOperator === 'between' && Array.isArray(value)) {
      const start = dayjs(value[0]).valueOf();
      const end = dayjs(value[1]).valueOf();
      const filterExpression = [
        [endDateCalculateCellValue, '>=', start],
        'and',
        [endDateCalculateCellValue, '<=', end],
      ];

      return filterExpression;
    } else {
      const date = dayjs(value).valueOf();

      return [endDateCalculateCellValue, comparisonOperator, date];
    }
  };

  const columns: DxDataGridColumn[] = [
    {
      key: 'shipName',
      dataField: 'shipName',
      caption: intl.formatMessage({ id: msgId.shipName }),
      defaultSortOrder: 'asc',
    },
    {
      key: 'machineNames',
      dataField: 'machineNames',
      caption: intl.formatMessage({ id: msgId.machineName }),
    },
    {
      key: 'startDate',
      dataField: 'startDate',
      dataType: 'datetime',
      filterOperations: constants.dataGrid.dateFilterOperations,
      format: constants.dateFormat.yyyyMMddHHmm,
      caption: intl.formatMessage({ id: msgId.startDate }),
      width: 160,
      calculateDisplayValue: startDateCalculateDisplayValue,
      calculateCellValue: startDateCalculateCellValue,
      calculateFilterExpression: startDateCalculateFilterExpression,
      editorOptions: { min: minDate },
    },
    {
      key: 'endDate',
      dataField: 'endDate',
      dataType: 'datetime',
      filterOperations: constants.dataGrid.dateFilterOperations,
      format: constants.dateFormat.yyyyMMddHHmm,
      caption: intl.formatMessage({ id: msgId.endDate }),
      width: 160,
      calculateDisplayValue: endDateCalculateDisplayValue,
      calculateCellValue: endDateCalculateCellValue,
      calculateFilterExpression: endDateCalculateFilterExpression,
      editorOptions: { min: minDate },
    },
    {
      key: 'memo',
      dataField: 'memo',
      caption: intl.formatMessage({ id: msgId.remarks }),
      width: 238,
    },
  ];

  /**
   * 行選択
   * @param maintenanceData メンテナンスデータ
   */
  const handleSelectionChanged = (maintenanceData: MaintenanceData) => {
    dataGridRef.current?.instance.clearSelection();
    if (
      account &&
      (account.authorityId === AuthorityAdministrators ||
        account.authorityId === AuthorityDevelopers ||
        account.authorityId === AuthorityAdminUsers)
    ) {
      dispatch(setEditMaintenanceData(maintenanceData));
    }
  };

  /**
   * 新規登録
   */
  const handleRegistrationClick = () => {
    dispatch(
      setEditMaintenanceData({
        maintenanceId: 0,
        shipId: '',
        shipName: '',
        startDate: dayjs().utc().format(constants.dateFormat.iso8601WithoutSeconds),
        endDate: dayjs().utc().add(1, 'day').format(constants.dateFormat.iso8601WithoutSeconds),
        machineNames: '',
        memo: '',
      })
    );
  };

  /**
   * フィルターリセット
   */
  const handleFilterResetClick = () => {
    if (dataGridRef.current) {
      const dataGrid = dataGridRef.current.instance;
      columns.forEach((c) => {
        dataGrid.columnOption(c.dataField, 'selectedFilterOperation', null);
      });
      dataGrid.clearFilter();
    }
  };

  // 日付入力のフィルターをreadonlyにする。
  const setInputReadOnly = () => {
    const element = document.getElementById('maintenanceDataGrid');
    if (element) {
      const elements = [3, 4];
      elements.forEach((colIndex) => {
        const dateFilterInput = element.querySelector(
          '.dx-datagrid-filter-row td[aria-colindex="' + colIndex + '"]'
        ) as HTMLElement;
        if (dateFilterInput) {
          const inputElement = dateFilterInput.querySelector(
            '.dx-texteditor-input'
          ) as HTMLInputElement;
          if (inputElement) {
            inputElement.readOnly = true;
          }
        }
      });
    }
  };

  /**
   * メンテナンスデータ初回取得
   */
  useEffect(() => {
    if (maintenanceDataList == null) {
      dispatch(fetchMaintenanceDataList());
    }
  }, [dispatch, maintenanceDataList]);

  /**
   * API完了後Account情報を再取得
   */
  useEffect(() => {
    if (maintenanceDataResult && maintenanceDataResult.isSuccess) {
      dispatch(fetchMaintenanceDataList());
      dispatch(fetchDiagnosisListItems());
    }
  }, [dispatch, maintenanceDataResult]);

  return (
    <RootDiv>
      {maintenanceDataList && (
        <React.Fragment>
          <ContentsHeader
            title={intl.formatMessage({ id: msgId.maintenanceTitle })}
            subTitle={intl.formatMessage({ id: msgId.maintenanceSubtitle })}
          >
            <NormalButton
              label={intl.formatMessage({ id: msgId.filterReset })}
              startIcon={<SearchIcon />}
              onClick={() => handleFilterResetClick()}
            />
            {account &&
              (account.authorityId === AuthorityAdministrators ||
                account.authorityId === AuthorityDevelopers ||
                account.authorityId === AuthorityAdminUsers) && (
                <NormalButton
                  label={intl.formatMessage({ id: msgId.newRegistration })}
                  color="primary"
                  startIcon={<AddCircleIcon />}
                  onClick={() => handleRegistrationClick()}
                />
              )}
          </ContentsHeader>
          <DataGrid
            id="maintenanceDataGrid"
            style={{ margin: dimens.dataGrid.margin }}
            ref={dataGridRef}
            height={height - dimens.dataGrid.offsetHeight}
            dataSource={
              maintenanceDataList
                ? maintenanceDataList
                : [
                    {
                      maintenanceId: 0,
                      shipId: '',
                      shipName: '',
                      machineNames: '',
                      startDate: '',
                      endDate: '',
                      memo: '',
                    },
                  ]
            }
            showBorders={true}
            allowColumnResizing={true}
            hoverStateEnabled={true}
            columnResizingMode="nextColumn"
            keyExpr="maintenanceId"
            columnAutoWidth={false}
            noDataText={intl.formatMessage({
              id: maintenanceDataList.length > 0 ? msgId.noResults : msgId.emptyMaintenanceList,
            })}
            onSelectionChanged={(e) => handleSelectionChanged(e.selectedRowsData[0])}
            onCellClick={setInputReadOnly}
          >
            <Selection mode="single" />
            <FilterRow visible={true} />
            <Scrolling mode="virtual" />
            {columns.map((column) => {
              return (
                <Column
                  key={column.key}
                  name={column.key}
                  dataField={column.dataField}
                  minWidth={dimens.dataGrid.column.minWidth}
                  dataType={column.dataType}
                  format={column.format}
                  caption={column.caption}
                  width={column.width}
                  filterOperations={column.filterOperations}
                  defaultSortOrder={column.defaultSortOrder}
                  editorOptions={column.editorOptions}
                  calculateDisplayValue={column.calculateDisplayValue}
                  calculateCellValue={column.calculateCellValue}
                  calculateFilterExpression={column.calculateFilterExpression}
                />
              );
            })}
          </DataGrid>
        </React.Fragment>
      )}
      <MaintenanceEditDialog contentHeight={contentsHeight} />
      <FadeLoading loading={listFetching} />
    </RootDiv>
  );
}
