import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from 'app/store';
import { Ship } from 'models/ships';
import { AnalysisData, AnalysisSearchConditions, StatisticalValueType } from 'models/analysis';
import { ErrorResult, getErrorResult } from 'models/error';
import { ConfirmedSearchResultItem } from 'models/search';
import { getAnalysisDataAsync } from 'api/maricoApiAnalysis';
import { useSelector } from 'react-redux';
import { RootState } from 'app/rootReducer';
import { MegTempLegendIndex } from 'models/chart';
import { doFilterShips } from 'utils/misc';

/**
 * AnalysisState インターフェース
 */
interface AnalysisState {
  /** 船舶リスト */
  ships: Ship[] | undefined;
  /** フィルター済み船舶リスト */
  filteredShips: Ship[];
  /** フィルター船舶名 */
  filterShipName: string;
  /** 船舶開閉状態リスト */
  contractedShipIds: string[];
  /** データ取得中状態 */
  fetching: boolean;
  /** データ取得エラー */
  fetchError: ErrorResult | undefined;
  /** 分析データ */
  analysisData: AnalysisData | undefined;
  /** 最後の分析データの機械ID */
  lastAnalysisMachineId: number | undefined;
  /** 船体：船速分析 */
  vesShipSpeed: {
    /** 開閉状態 */
    expanded: boolean;
    /** 統計値タイプ */
    statisticalValueType: StatisticalValueType;
  };
  /** 主機：負荷分析 */
  megLoad: {
    /** 開閉状態 */
    expanded: boolean;
    /** 統計値タイプ */
    statisticalValueType: StatisticalValueType;
  };
  /** 主機：排気温度分析 */
  megTemp: {
    /** 開閉状態 */
    expanded: boolean;
    /** 統計値タイプ */
    statisticalValueType: StatisticalValueType;
    /** 凡例選択状態 */
    selectedLegends: boolean[][];
  };
  /** 主機：負荷帯分析 */
  megLoadBand: {
    /** 開閉状態 */
    expanded: boolean;
  };
  /** レックス：操作量分析 */
  rexPropulsionOperation: {
    /** 開閉状態 */
    expanded: boolean;
  };
  /** レックス：ベベルギア診断 */
  rexGearAnomalyDetection: {
    /** 開閉状態 */
    expanded: boolean;
  };
  /** レックス：軸受診断 */
  rexBearingAnomalyDetection: {
    /** 開閉状態 */
    expanded: boolean;
  };
  /** レックス：プロペラ巻き込み分析 */
  rexPropellerInvolvement: {
    /** 開閉状態 */
    expanded: boolean;
  };
  /** 分析、プロペラ巻き込み分析タブ状態設定 */
  tabIndex: number;
  /** 開閉更新フラグ */
  isExpandUpdated: boolean;
}

/**
 * Analysis の初期状態
 */
const initialState: AnalysisState = {
  ships: undefined,
  filteredShips: [],
  filterShipName: '',
  contractedShipIds: [],
  fetching: false,
  fetchError: undefined,
  analysisData: undefined,
  lastAnalysisMachineId: undefined,
  vesShipSpeed: {
    expanded: true,
    statisticalValueType: 'mode',
  },
  megLoad: {
    expanded: true,
    statisticalValueType: 'mode',
  },
  megTemp: {
    expanded: true,
    selectedLegends: [[]],
    statisticalValueType: 'mode',
  },
  megLoadBand: {
    expanded: true,
  },
  rexPropulsionOperation: {
    expanded: true,
  },
  rexGearAnomalyDetection: {
    expanded: true,
  },
  rexBearingAnomalyDetection: {
    expanded: true,
  },
  rexPropellerInvolvement: {
    expanded: true,
  },
  tabIndex: 0,
  isExpandUpdated: false,
};

export const analysis = createSlice({
  name: 'analysis',
  initialState,
  reducers: {
    /**
     * 選択済み機械から船舶リストを設定する。
     */
    setShips: (
      state,
      {
        payload,
      }: PayloadAction<{
        ships: Ship[];
        confirmedSearchResultItem: ConfirmedSearchResultItem;
      }>
    ) => {
      const commonShips = payload.ships;
      const confirmedSearchResultItem = payload.confirmedSearchResultItem;
      const ships: Ship[] = [];
      commonShips.forEach((ship) => {
        const machines = ship.machines.filter(
          (x) => x.machineId === confirmedSearchResultItem.machine.machineId
        );
        if (machines.length > 0) {
          ships.push({
            shipId: ship.shipId,
            name: ship.name,
            propulsionSerialNumbers: ship.propulsionSerialNumbers,
            createdAt: ship.createdAt,
            updatedAt: ship.updatedAt,
            machines: machines.map((commonMachine) => {
              return {
                machineId: commonMachine.machineId,
                name: commonMachine.name,
                serialNumbers: commonMachine.serialNumbers,
                dataFormatId: commonMachine.dataFormatId,
                sensorGroups: commonMachine.sensorGroups.filter((x) =>
                  x.sensors.find(
                    (y) => y.isSearch && y.displayLowerLimit != null && y.displayUpperLimit != null
                  )
                ),
                checked: false,
              };
            }),
            checked: false,
            propulsionSerialNumbersText: ship.propulsionSerialNumbers.join(', '),
            machineNamesText: ship.machines.map((x) => x.name).join(', '),
          });
        }
      });

      state.ships = ships;
      state.filteredShips = doFilterShips(state.ships, state.filterShipName);
      const shipIds = ships.map((x) => x.shipId);
      state.contractedShipIds = state.contractedShipIds.filter((x) => shipIds.includes(x));
    },
    /**
     * フィルター船舶名を設定する。
     */
    setFilterShipName: (state, { payload }: PayloadAction<string>) => {
      state.filterShipName = payload;
      if (state.ships != null) {
        state.filteredShips = doFilterShips(state.ships, state.filterShipName);
      }
    },
    /**
     * 船舶リストをクリアする。
     */
    clearShips: (state) => {
      state.ships = undefined;
      state.filterShipName = '';
      state.filteredShips = [];
      state.contractedShipIds = [];
    },
    /**
     * 船舶リストの指定した船舶を開閉状態を変更する。
     */
    setContractedShipId: (
      state,
      { payload }: PayloadAction<{ shipId: string; expanded: boolean }>
    ) => {
      if (payload.expanded) {
        state.contractedShipIds = state.contractedShipIds.filter((x) => x !== payload.shipId);
      } else {
        state.contractedShipIds.push(payload.shipId);
      }
    },
    /**
     * 取得の開始を設定する。
     */
    startFetch: (state, { payload }: PayloadAction<boolean>) => {
      state.fetching = payload;
    },
    /**
     * 取得エラーを設定する。
     */
    setFetchError: (state, { payload }: PayloadAction<ErrorResult | undefined>) => {
      state.fetchError = payload;
      state.fetching = false;
    },
    /**
     * 分析データを設定する。
     */
    setAnalysisData: (state, { payload }: PayloadAction<AnalysisData | undefined>) => {
      if (payload != null) {
        // 前回データとの比較
        // 機械が変わったら初期化
        if (state.lastAnalysisMachineId !== payload.machineId) {
          state.vesShipSpeed = { ...initialState.vesShipSpeed };
          state.megLoad = { ...initialState.megLoad };
          state.megTemp = { ...initialState.megTemp, selectedLegends: [[]] };
          state.megLoadBand = { ...initialState.megLoadBand };
          state.rexPropulsionOperation = { ...initialState.rexPropulsionOperation };
          state.rexGearAnomalyDetection = { ...initialState.rexGearAnomalyDetection };
          state.rexBearingAnomalyDetection = { ...initialState.rexBearingAnomalyDetection };
          state.rexPropellerInvolvement = { ...initialState.rexPropellerInvolvement };
        }
        state.lastAnalysisMachineId = payload.machineId;

        // 主機排気温度分析の選択状態を初期化
        const megTemp = payload.data.megTemp;
        const selected: boolean[][] = [[]];
        if (megTemp != null) {
          for (let i = 0; i < megTemp.date.length; i++) {
            selected[0][i] = true;
          }
        }
        state.megTemp.selectedLegends = selected;
        state.fetching = false;
        if (
          payload.data.megLoad ||
          payload.data.megLoadBand ||
          payload.data.megTemp ||
          payload.data.rexPropulsionOperation ||
          payload.data.rexGearAnomalyDetection ||
          payload.data.rexBearingAnomalyDetection ||
          payload.data.vesShipSpeed
        ) {
          state.tabIndex = 0;
        } else if (payload.data.rexPropellerForeignMatterEntrainment) {
          state.tabIndex = 1;
        }
      }

      state.analysisData = payload;
    },
    /**
     * タブの選択変更
     */
    setTabIndex: (state, action: PayloadAction<number>) => {
      if (state.tabIndex != action.payload) {
        state.tabIndex = action.payload;
      }
    },
    /**
     * 船体船速分析の開閉状態を設定する。
     */
    setVesShipSpeedExpanded(state, action: PayloadAction<boolean>) {
      state.vesShipSpeed.expanded = action.payload;
      state.isExpandUpdated = true;
    },
    /**
     * 船体船速分析の分析値タイプを設定する。
     */
    setVesShipSpeedStatisticalValueType(state, action: PayloadAction<StatisticalValueType>) {
      state.vesShipSpeed.statisticalValueType = action.payload;
    },
    /**
     * 主機負荷分析の開閉状態を設定する。
     */
    setMegLoadExpanded(state, action: PayloadAction<boolean>) {
      state.megLoad.expanded = action.payload;
      state.isExpandUpdated = true;
    },
    /**
     * 主機負荷分析の分析値タイプを設定する。
     */
    setMegLoadStatisticalValueType(state, action: PayloadAction<StatisticalValueType>) {
      state.megLoad.statisticalValueType = action.payload;
    },
    /**
     * 主機負荷分析の開閉状態を設定する。
     */
    setMegTempExpanded(state, action: PayloadAction<boolean>) {
      state.megTemp.expanded = action.payload;
      state.isExpandUpdated = true;
    },
    /**
     * 主機排気温度分析の分析値タイプを設定する。
     */
    setMegTempStatisticalValueType(state, action: PayloadAction<StatisticalValueType>) {
      state.megTemp.statisticalValueType = action.payload;
    },
    /**
     * 主機排気温度分析の凡例選択を設定する。
     */
    setMegTempSelectedLegends(state, action: PayloadAction<MegTempLegendIndex>) {
      const index = action.payload;
      state.megTemp.selectedLegends[index.groupIndex][index.itemIndex] =
        !state.megTemp.selectedLegends[index.groupIndex][index.itemIndex];
    },
    /**
     * 主機負荷帯分析の開閉状態を設定する。
     */
    setMegLoadBandExpanded(state, action: PayloadAction<boolean>) {
      state.megLoadBand.expanded = action.payload;
      state.isExpandUpdated = true;
    },
    /**
     * レックス操作量分析の開閉状態を設定する。
     */
    setRexPropulsionOperationExpanded(state, action: PayloadAction<boolean>) {
      state.rexPropulsionOperation.expanded = action.payload;
      state.isExpandUpdated = true;
    },
    /**
     * レックスベベルギア振動の開閉状態を設定する。
     */
    setRexGearAnomalyDetectionExpanded(state, action: PayloadAction<boolean>) {
      state.rexGearAnomalyDetection.expanded = action.payload;
      state.isExpandUpdated = true;
    },
    /**
     * レックス軸受診断の開閉状態を設定する。
     */
    setRexBearingAnomalyDetectionExpanded(state, action: PayloadAction<boolean>) {
      state.rexBearingAnomalyDetection.expanded = action.payload;
      state.isExpandUpdated = true;
    },
    /**
     * レックスプロペラ異物巻き込み分析の開閉状態を設定する。
     */
    setRexPropellerInvolvementExpanded(state, action: PayloadAction<boolean>) {
      state.rexPropellerInvolvement.expanded = action.payload;
    },
    /**
     * 開閉状態の更新を設定する。
     */
    setExpandUpdated(state, action: PayloadAction<boolean>) {
      state.isExpandUpdated = action.payload;
    },
    clearAnalysis: (state) => {
      state.ships = initialState.ships;
      state.filteredShips = [];
      state.filterShipName = '';
      state.contractedShipIds = [];
      state.fetching = initialState.fetching;
      state.fetchError = initialState.fetchError;
      state.analysisData = undefined;
      state.lastAnalysisMachineId = undefined;
      state.vesShipSpeed = { ...initialState.vesShipSpeed };
      state.megLoad = { ...initialState.megLoad };
      state.megTemp = { ...initialState.megTemp, selectedLegends: [[]] };
      state.megLoadBand = { ...initialState.megLoadBand };
      state.rexPropulsionOperation = { ...initialState.rexPropulsionOperation };
      state.rexGearAnomalyDetection = { ...initialState.rexGearAnomalyDetection };
      state.rexBearingAnomalyDetection = { ...initialState.rexBearingAnomalyDetection };
      state.rexPropellerInvolvement = { ...initialState.rexPropellerInvolvement };
      state.tabIndex = initialState.tabIndex;
      state.isExpandUpdated = initialState.isExpandUpdated;
    },
  },
});

export const {
  setShips,
  setFilterShipName,
  clearShips,
  setContractedShipId,
  setFetchError,
  setAnalysisData,
  setVesShipSpeedExpanded,
  setVesShipSpeedStatisticalValueType,
  setMegLoadExpanded,
  setMegLoadStatisticalValueType,
  setMegTempExpanded,
  setMegTempStatisticalValueType,
  setMegTempSelectedLegends,
  setMegLoadBandExpanded,
  setRexPropulsionOperationExpanded,
  setRexGearAnomalyDetectionExpanded,
  setRexBearingAnomalyDetectionExpanded,
  setRexPropellerInvolvementExpanded,
  setTabIndex,
  setExpandUpdated,
  clearAnalysis,
} = analysis.actions;

/**
 * 分析データを取得する。
 * @param searchConditions 検索条件
 * @param confirmedSearchResultItem 確定検索結果アイテム
 */
export const fetchAnalysisData =
  (
    searchConditions: AnalysisSearchConditions,
    confirmedSearchResultItem: ConfirmedSearchResultItem
  ): AppThunk =>
  async (dispatch) => {
    dispatch(analysis.actions.startFetch(true));
    try {
      const analysisData = await getAnalysisDataAsync(searchConditions, confirmedSearchResultItem);
      dispatch(analysis.actions.setAnalysisData(analysisData));
    } catch (error) {
      dispatch(analysis.actions.setFetchError(getErrorResult(error)));
    }
  };

const analysisState = (state: RootState) => state.analysis;
const selectFilteredShips = createSelector(analysisState, (x) => x.filteredShips);
const selectFilterShipName = createSelector(analysisState, (x) => x.filterShipName);
const selectContractedShipIds = createSelector(analysisState, (x) => x.contractedShipIds);
const selectFetching = createSelector(analysisState, (x) => x.fetching);
const selectFetchError = createSelector(analysisState, (x) => x.fetchError);
const selectAnalysisData = createSelector(analysisState, (x) => x.analysisData);
const selectVesShipSpeedExpanded = createSelector(analysisState, (x) => x.vesShipSpeed.expanded);
const selectVesShipSpeedStatisticalValueType = createSelector(
  analysisState,
  (x) => x.vesShipSpeed.statisticalValueType
);
const selectMegTempExpanded = createSelector(analysisState, (x) => x.megTemp.expanded);
const selectMegTempStatisticalValueType = createSelector(
  analysisState,
  (x) => x.megTemp.statisticalValueType
);
const selectMegTempSelectedLegends = createSelector(
  analysisState,
  (x) => x.megTemp.selectedLegends
);
const selectMegLoadExpanded = createSelector(analysisState, (x) => x.megLoad.expanded);
const selectMegLoadStatisticalValueType = createSelector(
  analysisState,
  (x) => x.megLoad.statisticalValueType
);
const selectMegLoadBandExpanded = createSelector(analysisState, (x) => x.megLoadBand.expanded);
const selectRexPropulsionOperationExpanded = createSelector(
  analysisState,
  (x) => x.rexPropulsionOperation.expanded
);
const selectRexGearAnomalyDetectionExpanded = createSelector(
  analysisState,
  (x) => x.rexGearAnomalyDetection.expanded
);
const selectRexBearingAnomalyDetectionExpanded = createSelector(
  analysisState,
  (x) => x.rexBearingAnomalyDetection.expanded
);
const selectRexPropellerInvolvementExpanded = createSelector(
  analysisState,
  (x) => x.rexPropellerInvolvement.expanded
);
const selectTabIndex = createSelector(analysisState, (x) => x.tabIndex);
const selectIsExpandUpdated = createSelector(analysisState, (x) => x.isExpandUpdated);

export const useAnalysisFilteredShips = () => useSelector(selectFilteredShips);
export const useAnalysisFilterShipName = () => useSelector(selectFilterShipName);
export const useAnalysisContractedShipIds = () => useSelector(selectContractedShipIds);
export const useAnalysisFetching = () => useSelector(selectFetching);
export const useAnalysisFetchError = () => useSelector(selectFetchError);
export const useAnalysisData = () => useSelector(selectAnalysisData);
export const useAnalysisVesShipSpeedExpanded = () => useSelector(selectVesShipSpeedExpanded);
export const useAnalysisVesShipSpeedStatisticalValueType = () =>
  useSelector(selectVesShipSpeedStatisticalValueType);
export const useAnalysisMegTempExpanded = () => useSelector(selectMegTempExpanded);
export const useAnalysisMegTempStatisticalValueType = () =>
  useSelector(selectMegTempStatisticalValueType);
export const useAnalysisMegTempSelectedLegends = () => useSelector(selectMegTempSelectedLegends);
export const useAnalysisMegLoadExpanded = () => useSelector(selectMegLoadExpanded);
export const useAnalysisMegLoadStatisticalValueType = () =>
  useSelector(selectMegLoadStatisticalValueType);
export const useAnalysisMegLoadBandExpanded = () => useSelector(selectMegLoadBandExpanded);
export const useAnalysisRexPropulsionOperationExpanded = () =>
  useSelector(selectRexPropulsionOperationExpanded);
export const useAnalysisRexGearAnomalyDetectionExpanded = () =>
  useSelector(selectRexGearAnomalyDetectionExpanded);
export const useAnalysisRexBearingAnomalyDetectionExpanded = () =>
  useSelector(selectRexBearingAnomalyDetectionExpanded);
export const useAnalysisRexPropellerInvolvementExpanded = () =>
  useSelector(selectRexPropellerInvolvementExpanded);
export const useAnalysisTabIndex = () => useSelector(selectTabIndex);
export const useIsExpandUpdated = () => useSelector(selectIsExpandUpdated);

export default analysis.reducer;
