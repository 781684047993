import React from 'react';
import { useIntl } from 'react-intl';
import msgId from 'resources/intl';
import Plotly, { Layout, LayoutAxis, Shape, PlotMouseEvent, Annotations } from 'plotly.js';
import createPlotlyComponent from 'react-plotly.js/factory';
import colors from 'resources/colors';
import { AnalysisBearingAnomalyDetectionValue } from 'models/analysis';
import { Box, styled, SxProps } from '@mui/material';

const Plot = createPlotlyComponent(Plotly);
const axisFontColor = colors.chart.analysis.rexPropellerInvolvement.axisFontColor;
const gridColor = colors.chart.analysis.rexPropellerInvolvement.gridColor;
const graphHeight = 130;

const PlotPanelDiv = styled('div')({
  position: 'relative',
  width: '50%',
  padding: 0,
  float: 'left',
  borderLeft: `1px solid ${colors.chart.legend.border}`,
  borderBottom: `1px solid ${colors.chart.legend.border}`,
});

const HoverDiv = styled('div')({
  position: 'absolute',
  color: 'white',
  background: 'black',
  zIndex: 2,
  fontFamily: 'Arial, sans-serif',
  fontSize: 13,
  whiteSpace: 'nowrap',
  pointerEvents: 'none',
});

const plotLStyle: React.CSSProperties = {
  width: '100%',
  height: graphHeight,
  background: colors.chart.legend.background,
};

const plotRStyle: React.CSSProperties = {
  width: '100%',
  height: graphHeight,
  background: colors.chart.legend.background,
};

const TitleDiv = styled('div')({
  height: '100%',
  width: `50%`,
  float: 'left',
  color: 'white',
  fontSize: '16px',
  paddingLeft: '40px',
  paddingTop: '2px',
  borderLeft: `1px solid ${colors.chart.legend.border}`,
  backgroundColor: colors.chart.legend.background,
});

interface RexBearingAnomalyDetectionChartProps {
  sx?: SxProps;
  titleId: string;
  dates: number[];
  ShaftPort: AnalysisBearingAnomalyDetectionValue;
  ShaftStarboard: AnalysisBearingAnomalyDetectionValue;
}

export function RexBearingAnomalyDetectionChart(
  props: RexBearingAnomalyDetectionChartProps
): JSX.Element {
  const intl = useIntl();
  const { sx, titleId, dates, ShaftPort, ShaftStarboard } = props;

  const yDataL: number[][] = [[], [], []];
  const yDataR: number[][] = [[], [], []];
  const yTextL: string[] = [];
  const yTextR: string[] = [];
  const plotDataListL: Plotly.Data[] = [];
  const plotDataListR: Plotly.Data[] = [];
  const sharpL = [];
  const sharpR = [];

  const getHoverJudgeText = (risk: number): string => {
    if (risk === 0) {
      return intl.formatMessage({ id: msgId.labelNormal });
    } else if (risk === 1) {
      return '<font color="red">' + intl.formatMessage({ id: msgId.labelRisk }) + '</font>';
    } else {
      return '---';
    }
  };

  const nitiji = intl.formatMessage({ id: msgId.AnalysisHoverInfoLabelDate });
  dates.forEach((date, index) => {
    yDataL[0][index] = NaN;
    yDataL[1][index] = NaN;
    yDataL[2][index] = NaN;
    yDataL[0][index] = ShaftPort.values[index];
    yDataR[0][index] = NaN;
    yDataR[1][index] = NaN;
    yDataR[2][index] = NaN;
    yDataR[0][index] = ShaftStarboard.values[index];
    const d = new Date(date);
    const dateStrings = d.toISOString().split('T');
    const dateString = dateStrings[0].split('-').join('/');

    const td1 = '  <td style="padding:0px 3px;">';
    const td2 = '  <td style="padding:0px 3px;" align="center">';
    const td3 = '  <td style="padding:0px 3px;border-left:1px solid gray;" align="center">';

    yTextL[index] =
      '<table style="border:0px;border-collapse:collapse;">' +
      '<tr><td colspan="3" style="padding:0px;">' + nitiji + ':' + dateString + '</td></tr>' +
      '<tr>' +
      td1 + '</td>' +
      td2 + intl.formatMessage({ id: msgId.rexBearingAnomalyDetectionInner }) + '</td>' +
      td3 + intl.formatMessage({ id: msgId.rexBearingAnomalyDetectionOuter }) + '</td>' +
      '</tr>' +
      '<tr>' +
      td1 + intl.formatMessage({ id: msgId.rexAnalysisSensorA02 }) + '：</td>' +
      td2 + getHoverJudgeText(ShaftPort.details[index].InnerA02) + '</td>' +
      td3 + getHoverJudgeText(ShaftPort.details[index].OuterA02) + '</td>' +
      '</tr>' +
      '<tr>' +
      td1 + intl.formatMessage({ id: msgId.rexAnalysisSensorA05 }) + '：</td>' +
      td2 + getHoverJudgeText(ShaftPort.details[index].InnerA05) + '</td>' +
      td3 + getHoverJudgeText(ShaftPort.details[index].OuterA05) + '</td>' +
      '</tr>' +
      '<tr>' +
      td1 + intl.formatMessage({ id: msgId.rexAnalysisSensorA08 }) + '：</td>' +
      td2 + getHoverJudgeText(ShaftPort.details[index].InnerA08) + '</td>' +
      td3 + getHoverJudgeText(ShaftPort.details[index].OuterA08) + '</td>' +
      '</tr>' +
      '<tr>' +
      td1 + intl.formatMessage({ id: msgId.rexAnalysisSensorA09 }) + '：</td>' +
      td2 + getHoverJudgeText(ShaftPort.details[index].InnerA09) + '</td>' +
      td3 + getHoverJudgeText(ShaftPort.details[index].OuterA09) + '</td>' +
      '</tr>' +
      '<tr>' +
      td1 + intl.formatMessage({ id: msgId.rexAnalysisSensorA11 }) + '：</td>' +
      td2 + getHoverJudgeText(ShaftPort.details[index].InnerA11) + '</td>' +
      td3 + getHoverJudgeText(ShaftPort.details[index].OuterA11) + '</td>' +
      '</tr>' +
      '<tr>' +
      td1 + intl.formatMessage({ id: msgId.rexAnalysisSensorA13 }) + '：</td>' +
      td2 + getHoverJudgeText(ShaftPort.details[index].InnerA13) + '</td>' +
      td3 + getHoverJudgeText(ShaftPort.details[index].OuterA13) + '</td>' +
      '</tr>' +
      '<tr>' +
      td1 + intl.formatMessage({ id: msgId.rexAnalysisSensorA14 }) + '：</td>' +
      td2 + getHoverJudgeText(ShaftPort.details[index].InnerA14) + '</td>' +
      td3 + getHoverJudgeText(ShaftPort.details[index].OuterA14) + '</td>' +
      '</tr>' +
      '<tr>' +
      td1 + intl.formatMessage({ id: msgId.rexAnalysisSensorA16 }) + '：</td>' +
      td2 + getHoverJudgeText(ShaftPort.details[index].InnerA16) + '</td>' +
      td3 + getHoverJudgeText(ShaftPort.details[index].OuterA16) + '</td>' +
      '</tr>' +
      '<tr>' +
      td1 + intl.formatMessage({ id: msgId.rexAnalysisSensorA17 }) + '：</td>' +
      td2 + getHoverJudgeText(ShaftPort.details[index].InnerA17) + '</td>' +
      td3 + getHoverJudgeText(ShaftPort.details[index].OuterA17) + '</td>' +
      '</tr></table>';

    yTextR[index] =
      '<table style="border:0px;border-collapse:collapse;">' +
      '<tr><td colspan="3" style="padding:0px;">' + nitiji + ':' + dateString + '</td></tr>' +
      '<tr>' +
      td1 + '</td>' +
      td2 + intl.formatMessage({ id: msgId.rexBearingAnomalyDetectionInner }) + '</td>' +
      td3 + intl.formatMessage({ id: msgId.rexBearingAnomalyDetectionOuter }) + '</td>' +
      '</tr>' +
      '<tr>' +
      td1 + intl.formatMessage({ id: msgId.rexAnalysisSensorA02 }) + '：</td>' +
      td2 + getHoverJudgeText(ShaftStarboard.details[index].InnerA02) + '</td>' +
      td3 + getHoverJudgeText(ShaftStarboard.details[index].OuterA02) + '</td>' +
      '</tr>' +
      '<tr>' +
      td1 + intl.formatMessage({ id: msgId.rexAnalysisSensorA05 }) + '：</td>' +
      td2 + getHoverJudgeText(ShaftStarboard.details[index].InnerA05) + '</td>' +
      td3 + getHoverJudgeText(ShaftStarboard.details[index].OuterA05) + '</td>' +
      '</tr>' +
      '<tr>' +
      td1 + intl.formatMessage({ id: msgId.rexAnalysisSensorA08 }) + '：</td>' +
      td2 + getHoverJudgeText(ShaftStarboard.details[index].InnerA08) + '</td>' +
      td3 + getHoverJudgeText(ShaftStarboard.details[index].OuterA08) + '</td>' +
      '</tr>' +
      '<tr>' +
      td1 + intl.formatMessage({ id: msgId.rexAnalysisSensorA09 }) + '：</td>' +
      td2 + getHoverJudgeText(ShaftStarboard.details[index].InnerA09) + '</td>' +
      td3 + getHoverJudgeText(ShaftStarboard.details[index].OuterA09) + '</td>' +
      '</tr>' +
      '<tr>' +
      td1 + intl.formatMessage({ id: msgId.rexAnalysisSensorA11 }) + '：</td>' +
      td2 + getHoverJudgeText(ShaftStarboard.details[index].InnerA11) + '</td>' +
      td3 + getHoverJudgeText(ShaftStarboard.details[index].OuterA11) + '</td>' +
      '</tr>' +
      '<tr>' +
      td1 + intl.formatMessage({ id: msgId.rexAnalysisSensorA13 }) + '：</td>' +
      td2 + getHoverJudgeText(ShaftStarboard.details[index].InnerA13) + '</td>' +
      td3 + getHoverJudgeText(ShaftStarboard.details[index].OuterA13) + '</td>' +
      '</tr>' +
      '<tr>' +
      td1 + intl.formatMessage({ id: msgId.rexAnalysisSensorA14 }) + '：</td>' +
      td2 + getHoverJudgeText(ShaftStarboard.details[index].InnerA14) + '</td>' +
      td3 + getHoverJudgeText(ShaftStarboard.details[index].OuterA14) + '</td>' +
      '</tr>' +
      '<tr>' +
      td1 + intl.formatMessage({ id: msgId.rexAnalysisSensorA16 }) + '：</td>' +
      td2 + getHoverJudgeText(ShaftStarboard.details[index].InnerA16) + '</td>' +
      td3 + getHoverJudgeText(ShaftStarboard.details[index].OuterA16) + '</td>' +
      '</tr>' +
      '<tr>' +
      td1 + intl.formatMessage({ id: msgId.rexAnalysisSensorA17 }) + '：</td>' +
      td2 + getHoverJudgeText(ShaftStarboard.details[index].InnerA17) + '</td>' +
      td3 + getHoverJudgeText(ShaftStarboard.details[index].OuterA17) + '</td>' +
      '</tr></table>';
  });
  const timezoneOffset = new Date().getTimezoneOffset() * 1000 * 60;
  yDataL.forEach((data, index) => {
    const BearingAnomalyDetectionDataL = {
      x: dates.map((x) => x + timezoneOffset),
      y: data,
      type: 'scatter',
      yaxis: 'y',
      mode: 'markers',
      line: { color: colors.chart.analysis.rexBearingAnomalyDetection.dotColor },
      hoverinfo: 'none',
    } as Plotly.Data;
    plotDataListL.push(BearingAnomalyDetectionDataL);
  });
  yDataR.forEach((data, index) => {
    const BearingAnomalyDetectionDataR = {
      x: dates.map((x) => x + timezoneOffset),
      y: data,
      type: 'scatter',
      yaxis: 'y',
      mode: 'markers',
      line: { color: colors.chart.analysis.rexBearingAnomalyDetection.dotColor },
      hoverinfo: 'none',
    } as Plotly.Data;
    plotDataListR.push(BearingAnomalyDetectionDataR);
  });

  const shape: Partial<Shape> = {
    type: 'line',
    xref: 'paper',
    yref: 'y',
    x0: 0,
    y0: 1,
    x1: 1,
    y1: 1,
    line: {
      color: colors.chart.analysis.rexBearingAnomalyDetection.auxiliaryLineColor,
      width: 1,
      dash: 'dot',
    },
  };
  sharpL.push(shape);
  sharpR.push(shape);

  const shapeLeftLine: Partial<Shape> = {
    type: 'line',
    xref: 'paper',
    yref: 'paper',
    x0: 0,
    y0: 0,
    x1: 0,
    y1: 1,
    line: {
      width: 1,
      color: colors.chart.analysis.rexBearingAnomalyDetection.y1TickColor,
    },
  };
  sharpL.push(shapeLeftLine);
  sharpR.push(shapeLeftLine);

  const annotationsRisk: Partial<Annotations> = {
    xref: 'paper',
    yref: 'y',
    text: intl.formatMessage({ id: msgId.labelRisk }),
    showarrow: false,
    xanchor: 'left',
    yanchor: 'bottom',
    y: 1,
    x: 0,
    font: {
      size: 13,
      color: colors.chart.analysis.rexBearingAnomalyDetection.auxiliaryLineColor,
    },
  };

  const annotationsNormal: Partial<Annotations> = {
    xref: 'paper',
    yref: 'y',
    text: intl.formatMessage({ id: msgId.labelNormal }),
    showarrow: false,
    xanchor: 'left',
    yanchor: 'bottom',
    y: 0,
    x: 0,
    font: {
      size: 13,
      color: colors.chart.analysis.rexBearingAnomalyDetection.axisFontColor,
    },
  };

  const day = 1000 * 60 * 60 * 24;
  let dateNum = 0;
  if (dates.length > 0) {
    dateNum = dates[0];
  } else {
    dateNum = Date.now();
  }
  const d = new Date(dateNum);
  const startDate = new Date(d.getFullYear(), d.getMonth(), 1);
  const endDate = new Date(d.getFullYear(), d.getMonth() + 1, 0);
  const xaxis = {
    type: 'date',
    gridcolor: gridColor,
    range: [startDate.getTime() - day, endDate.getTime() + day],
    showticklabels: false,
    side: 'top',
    autorange: false,
    zeroline: true,
    tickfont: { color: 'white' },
    fixedrange: true,
  } as Partial<LayoutAxis>;
  const yaxis = {
    title: {
      text: '',
      font: { color: axisFontColor, size: 11 },
    },
    gridcolor: gridColor,
    ticklabelposition: 'inside',
    ticks: 'inside',
    tick0: 0,
    range: [-0.1, 2.1],
    tickvals: [],
    ticktext: [],
    fixedrange: true,
  } as Partial<LayoutAxis>;

  const layoutL = {
    title: {
      text: intl.formatMessage({ id: titleId }),
      color: axisFontColor,
      x: 0.5,
      y: 0.94,
      font: { color: axisFontColor },
    },
    height: graphHeight,
    autosize: true,
    annotations: [annotationsRisk, annotationsNormal],
    xaxis: xaxis,
    yaxis: yaxis,
    paper_bgcolor: colors.chart.plotarea.background,
    plot_bgcolor: colors.chart.plotarea.background,
    margin: {
      t: 25,
      b: 5,
      l: 35,
      r: 30,
      pad: 2,
    },
    showlegend: false,
    shapes: sharpL,
    hovermode: 'closest',
    hoverlabel: {
      bgcolor: 'black',
      bordercolor: 'black',
      font: {
        color: 'white',
      },
      align: 'left',
    },
    barmode: 'stack',
  } as Partial<Layout>;
  const layoutR = Object.assign({}, layoutL);
  Object.assign(layoutR, {
    title: {
      text: intl.formatMessage({ id: titleId }),
      color: axisFontColor,
      x: 0.5,
      y: 0.94,
      font: { color: axisFontColor },
    },
    shapes: sharpR,
    yaxis: yaxis,
  });
  const plotConfig = {
    displayModeBar: false,
    showTips: false,
    doubleClick: false,
  } as Partial<Plotly.Config>;

  const panel = 'RexBearingAnomalyDetection';
  const PlotL = 'PlotL' + panel + titleId;
  const PlotR = 'PlotR' + panel + titleId;
  const hoverIdL = PlotL + 'hover';
  const hoverIdR = PlotR + 'hover';
  const hoverId = 'Plot' + panel + titleId.substring(0, titleId.length - 1) + 'hover';

  const setHoverInfo = (
    data: Readonly<PlotMouseEvent>,
    elementId: string,
    hoverText: string,
    index: number
  ) => {
    const div = document.getElementById(hoverId);
    const plot = document.getElementById(PlotL);
    let offsetLeft = 0;
    if (div && plot) {
      if (elementId === hoverIdR) {
        offsetLeft = plot.clientWidth;
      }
      const plotClientWidth = plot.clientWidth;
      div.innerHTML = hoverText;
      div.style.top = '140px';
      if (plotClientWidth > data.event.offsetX + 110) {
        div.style.left = offsetLeft + data.event.offsetX + 5 + 'px';
      } else {
        if (data.event.offsetX - 110 < 5) {
          div.style.left = offsetLeft + 5 + 'px';
        } else {
          div.style.left = offsetLeft + data.event.offsetX - 110 + 'px';
        }
      }
      div.style.padding = '3px';
    }
  };

  const handleHoverInfo = (event: Readonly<PlotMouseEvent>, elementId: string) => {
    if (event && event.points && event.points[0]) {
      const index = event.points[0].pointIndex;
      let hoverText = yTextL[index];
      if (elementId !== hoverIdL) {
        hoverText = yTextR[index];
      }
      setHoverInfo(event, elementId, hoverText, index);
    }
  };

  const handleUnhover = (event: Readonly<Plotly.PlotMouseEvent>) => {
    const e = document.getElementById(hoverId);
    if (e) {
      e.innerHTML = '';
      e.style.padding = '0px';
    }
  };

  return (
    <Box sx={sx}>
      {titleId === msgId.rexBearingAnomalyDetectionBearing7 && (
        <React.Fragment>
          <TitleDiv>{intl.formatMessage({ id: msgId.trendLabelPort })}</TitleDiv>
          <TitleDiv>{intl.formatMessage({ id: msgId.trendLabelStbd })}</TitleDiv>
        </React.Fragment>
      )}
      <PlotPanelDiv>
        <HoverDiv id={hoverIdL} />
        <Plot
          divId={PlotL}
          style={plotLStyle}
          data={plotDataListL}
          useResizeHandler
          layout={layoutL}
          config={plotConfig}
          onHover={(event) => handleHoverInfo(event, hoverIdL)}
          onUnhover={handleUnhover}
        />
      </PlotPanelDiv>
      <PlotPanelDiv>
        <HoverDiv id={hoverIdR} />
        <Plot
          divId={PlotR}
          style={plotRStyle}
          data={plotDataListR}
          useResizeHandler
          layout={layoutR}
          config={plotConfig}
          onHover={(event) => handleHoverInfo(event, hoverIdR)}
          onUnhover={handleUnhover}
        />
      </PlotPanelDiv>
    </Box>
  );
}
