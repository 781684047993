import React, { useEffect, useRef } from 'react';
import { Box, Typography } from '@mui/material';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import {
  useAdminSettingsDataRecalcs,
  useAdminSettingsEditDataRecalcResult,
  setDataRecalcEditing,
  fetchDataRecalcsSilent,
  fetchDataRecalcs,
} from '../adminSettingsSlice';
import { ContentsHeader } from 'components/ContentsHeader';
import msgId from 'resources/intl';
import {
  Replay as ReplayIcon,
  AddCircle as AddCircleIcon,
  Search as SearchIcon,
} from '@mui/icons-material';
import colors from 'resources/colors';
import { NormalButton } from 'components/NormalButton';
import { DataGrid, Column, FilterRow, Scrolling, Selection } from 'devextreme-react/data-grid';
import dimens from 'resources/dimens';
import { DxDataGridColumn } from 'components/DxDataGrid';
import constants from 'resources/constants';
import { DataRecalcEditDialog } from './DataRecalcEditDialog';
import config from 'resources/config';
import dayjs from 'dayjs';
import { DataRecalc } from 'models/admin';
import { getTodayLimit } from 'utils/misc';

interface DataRecalcManagementProps {
  open: boolean;
  height: number;
}

export function DataRecalcManagement(props: DataRecalcManagementProps): JSX.Element {
  const { open, height } = props;
  const intl = useIntl();
  const dispatch = useDispatch();
  const dataRecalcs = useAdminSettingsDataRecalcs();
  const dataRecalcResult = useAdminSettingsEditDataRecalcResult();
  const dataGridRef = useRef<DataGrid<DataRecalc, unknown>>(null);
  const minDate = dayjs(config.minDate).toDate();
  const maxDate = dayjs(getTodayLimit()).toDate();
  const maxDateRecalc = dayjs(getTodayLimit()).add(-1, 'day').toDate();
  const timezoneOffset = new Date().getTimezoneOffset();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const startDateCalculateDisplayValue = (rowData: any) => {
    const dataRecalc: DataRecalc = rowData;

    return dayjs(dataRecalc.startDate).utc().format(constants.dateFormat.YYYYMMDD);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const startDateCalculateCellValue = (rowData: any) => {
    const dataRecalc: DataRecalc = rowData;
    const date = dayjs(dataRecalc.startDate).add(timezoneOffset, 'minute').valueOf();

    return date;
  };

  const startDateCalculateFilterExpression = (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    value: any,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    comparisonOperator: any,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unused-vars
    target: any
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ): any[] => {
    if (comparisonOperator === 'between' && Array.isArray(value)) {
      const start = dayjs(value[0]).valueOf();
      const end = dayjs(value[1]).valueOf();
      const filterExpression = [
        [startDateCalculateCellValue, '>=', start],
        'and',
        [startDateCalculateCellValue, '<=', end],
      ];

      return filterExpression;
    } else {
      const date = dayjs(value).valueOf();

      return [startDateCalculateCellValue, comparisonOperator, date];
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const endDateCalculateDisplayValue = (rowData: any) => {
    const dataRecalc: DataRecalc = rowData;

    return dayjs(dataRecalc.endDate).utc().format(constants.dateFormat.YYYYMMDD);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const endDateCalculateCellValue = (rowData: any) => {
    const dataRecalc: DataRecalc = rowData;
    const date = dayjs(dataRecalc.endDate).add(timezoneOffset, 'minute').valueOf();

    return date;
  };

  const endDateCalculateFilterExpression = (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    value: any,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    comparisonOperator: any,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unused-vars
    target: any
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ): any[] => {
    if (comparisonOperator === 'between' && Array.isArray(value)) {
      const start = dayjs(value[0]).valueOf();
      const end = dayjs(value[1]).valueOf();
      const filterExpression = [
        [endDateCalculateCellValue, '>=', start],
        'and',
        [endDateCalculateCellValue, '<=', end],
      ];

      return filterExpression;
    } else {
      const date = dayjs(value).valueOf();

      return [endDateCalculateCellValue, comparisonOperator, date];
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const statusCellRender = (rowData: any) => {
    const dataRecalc: DataRecalc = rowData.data;

    return (
      <>
        {dataRecalc?.status === 'Failed' && (
          <Typography style={{ verticalAlign: 'center' }} color="error" noWrap>
            {intl.formatMessage({ id: 'data_recalc_status_' + dataRecalc.status })}
          </Typography>
        )}
        {dataRecalc?.status !== 'Failed' && (
          <Typography style={{ verticalAlign: 'center' }} noWrap>
            {intl.formatMessage({ id: 'data_recalc_status_' + dataRecalc.status })}
          </Typography>
        )}
      </>
    );
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const statusCalculateCellValue = (rowData: any) => {
    const dataRecalc: DataRecalc = rowData;

    return intl.formatMessage({ id: 'data_recalc_status_' + dataRecalc.status });
  };

  const columns: DxDataGridColumn[] = [
    {
      key: 'createdAt',
      dataField: 'createdAt',
      dataType: 'datetime',
      filterOperations: constants.dataGrid.dateFilterOperations,
      format: constants.dateFormat.yyyyMMddHHmm,
      caption: intl.formatMessage({ id: msgId.createdAt }),
      width: 160,
      editorOptions: { min: minDate, max: maxDate },
      defaultSortOrder: 'desc',
    },
    {
      key: 'startDate',
      dataField: 'startDate',
      dataType: 'date',
      filterOperations: constants.dataGrid.dateFilterOperations,
      format: constants.dateFormat.yyyyMMdd,
      caption: intl.formatMessage({ id: msgId.startDay }),
      width: 126,
      calculateDisplayValue: startDateCalculateDisplayValue,
      calculateCellValue: startDateCalculateCellValue,
      calculateFilterExpression: startDateCalculateFilterExpression,
      editorOptions: { min: minDate, max: maxDateRecalc },
    },
    {
      key: 'endDate',
      dataField: 'endDate',
      dataType: 'date',
      filterOperations: constants.dataGrid.dateFilterOperations,
      format: constants.dateFormat.yyyyMMdd,
      caption: intl.formatMessage({ id: msgId.endDay }),
      width: 126,
      calculateDisplayValue: endDateCalculateDisplayValue,
      calculateCellValue: endDateCalculateCellValue,
      calculateFilterExpression: endDateCalculateFilterExpression,
      editorOptions: { min: minDate, max: maxDateRecalc },
    },
    {
      key: 'shipName',
      dataField: 'shipName',
      caption: intl.formatMessage({ id: msgId.shipName }),
    },
    {
      key: 'machineName',
      dataField: 'machineName',
      caption: intl.formatMessage({ id: msgId.machineName }),
      width: 200,
    },
    {
      key: 'status',
      dataField: 'status',
      caption: intl.formatMessage({ id: msgId.status }),
      width: 100,
      cellRender: statusCellRender,
      calculateCellValue: statusCalculateCellValue,
    },
  ];

  /**
   * 行選択
   * @param account アカウント
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleSelectionChanged = (dataRecalc: DataRecalc) => {
    dataGridRef.current?.instance.clearSelection();
  };

  /**
   * 新規登録
   */
  const handleRegistrationClick = () => {
    dispatch(setDataRecalcEditing({ editing: true, dataRecalc: undefined }));
  };

  /**
   * 更新
   */
  const handleReloadClick = () => {
    dispatch(fetchDataRecalcs());
  };

  /**
   * フィルターリセット
   */
  const handleFilterResetClick = () => {
    if (dataGridRef.current) {
      const dataGrid = dataGridRef.current.instance;
      columns.forEach((c) => {
        dataGrid.columnOption(c.dataField, 'selectedFilterOperation', null);
      });
      dataGrid.clearFilter();
    }
  };

  // 日付入力のフィルターをreadonlyにする。
  const setInputReadOnly = () => {
    const element = document.getElementById('dataRecalcDataGrid');
    if (element) {
      const elements = [1, 2, 3];
      elements.forEach((colIndex) => {
        const dateFilterInput = element.querySelector(
          '.dx-datagrid-filter-row td[aria-colindex="' + colIndex + '"]'
        ) as HTMLElement;
        if (dateFilterInput) {
          const inputElement = dateFilterInput.querySelector(
            '.dx-texteditor-input'
          ) as HTMLInputElement;
          if (inputElement) {
            inputElement.readOnly = true;
          }
        }
      });
    }
  };

  /**
   * API完了後データ再計算情報を再取得
   */
  useEffect(() => {
    if (open && dataRecalcResult && dataRecalcResult.isSuccess) {
      dispatch(fetchDataRecalcsSilent());
    }
  }, [dispatch, open, dataRecalcResult]);

  return (
    <Box
      sx={{
        position: 'absolute',
        width: '100%',
        background: colors.subDrawer.background,
        visibility: open ? 'visible' : 'hidden',
      }}
    >
      {dataRecalcs && (
        <React.Fragment>
          <ContentsHeader title={intl.formatMessage({ id: msgId.dataRecalcRegistration })}>
            <NormalButton
              label={intl.formatMessage({ id: msgId.trendButtonUpdate })}
              color="primary"
              startIcon={<ReplayIcon />}
              onClick={() => handleReloadClick()}
            />
            <NormalButton
              label={intl.formatMessage({ id: msgId.filterReset })}
              color="primary"
              startIcon={<SearchIcon />}
              onClick={() => handleFilterResetClick()}
            />
            <NormalButton
              label={intl.formatMessage({ id: msgId.newRegistration })}
              color="primary"
              startIcon={<AddCircleIcon />}
              onClick={() => handleRegistrationClick()}
            />
          </ContentsHeader>
          <DataGrid
            id="dataRecalcDataGrid"
            style={{ margin: dimens.dataGrid.margin }}
            ref={dataGridRef}
            height={height - dimens.dataGrid.offsetHeight}
            dataSource={dataRecalcs}
            showBorders={true}
            allowColumnResizing={true}
            hoverStateEnabled={true}
            columnResizingMode="nextColumn"
            columnAutoWidth={true}
            noDataText={intl.formatMessage({ id: msgId.noResults })}
            onSelectionChanged={(e) => handleSelectionChanged(e.selectedRowsData[0])}
            onCellClick={setInputReadOnly}
          >
            <Selection mode="single" />
            <FilterRow visible={true} />
            <Scrolling mode="virtual" />
            {columns.map((column) => {
              return (
                <Column
                  key={column.key}
                  name={column.key}
                  dataField={column.dataField}
                  minWidth={dimens.dataGrid.column.minWidth}
                  dataType={column.dataType}
                  format={column.format}
                  caption={column.caption}
                  width={column.width}
                  filterOperations={column.filterOperations}
                  defaultSortOrder={column.defaultSortOrder}
                  editorOptions={column.editorOptions}
                  cellRender={column.cellRender}
                  calculateDisplayValue={column.calculateDisplayValue}
                  calculateCellValue={column.calculateCellValue}
                  calculateFilterExpression={column.calculateFilterExpression}
                />
              );
            })}
          </DataGrid>
        </React.Fragment>
      )}
      <DataRecalcEditDialog contentHeight={height} />
    </Box>
  );
}
