import React from 'react';
import { SxProps, TextField } from '@mui/material';
import { useIntl } from 'react-intl';
import msgId from 'resources/intl';

interface SelectTextFieldProps {
  id: string;
  label: string;
  value?: string | number;
  fullWidth: boolean;
  sx?: SxProps;
  disabled?: boolean;
  error?: boolean;
  helperText?: string;
  required?: boolean;
  variant: 'standard' | 'outlined' | 'filled';
  children?: React.ReactNode;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyPress?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
}

export function SelectTextField(props: SelectTextFieldProps): JSX.Element {
  const {
    id,
    label,
    value,
    fullWidth,
    sx,
    disabled,
    error,
    helperText,
    required,
    variant,
    children,
    onChange,
    onKeyPress,
  } = props;
  const intl = useIntl();

  let _label;
  if (required === true) {
    _label = label + ' [' + intl.formatMessage({ id: msgId.required }) + ']';
  } else {
    _label = label;
  }

  return (
    <TextField
      select
      label={_label}
      id={id}
      name={id}
      sx={sx}
      value={value}
      fullWidth={fullWidth}
      size="small"
      variant={variant}
      disabled={disabled}
      error={error}
      helperText={helperText}
      onChange={onChange}
      onKeyPress={onKeyPress}
    >
      {children}
    </TextField>
  );
}

SelectTextField.defaultProps = {
  fullWidth: false,
  variant: 'outlined',
};
