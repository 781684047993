import React, { ChangeEvent, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import {
  setOpen,
  setShipConfirmed,
  setCommonShips,
  setShipCheck,
  setFilterShipName,
  setPreriodUnit,
  setAnalysisSectionChecked,
  searchAnalysisData,
  searchAnalysisFuelOilConsumDetectionSearch,
  setSelectedSearchResultItem,
  setConfirmedResultItem,
  setAnalysisSectionsError,
  setSearchError,
  setStartDate,
  setEndDate,
  useAnalysisSearchShipConfirmed,
  useAnalysisSearchFilterShipName,
  useAnalysisSearchCheckedMachines,
  useAnalysisSearchConditions,
  useAnalysisSearchCanSearch,
  useAnalysisSearchSearching,
  useAnalysisSearchResults,
  useAnalysisSearchFuelResult,
  useAnalysisSearchError,
  useAnalysisSearchFixed,
  useAnalysisSearchAnalysisSections,
  useAnalysisSearchAnalysisSectionsError,
  useAnalysisSearchSelectedSearchResultItem,
  useAnalysisSearchFilteredShips,
  fetchAnalysisSections,
  setConfirmedSearchConditions,
  setFuelConsumptionSearchConditions,
} from './searchSlice';
import colors from 'resources/colors';
import msgId from 'resources/intl';
import { useIntl } from 'react-intl';
import { FadeLoading } from 'components/FadeLoading';
import { ApiErrorDialog } from 'components/ApiErrorDialog';
import { Divider, FormControl, Grid, MenuItem, styled, TextField, Typography } from '@mui/material';
import { PeriodUnit, FuelConsumptionDetectionConditionItem } from 'models/analysis';
import { SearchShipList } from 'components/search/SearchShipList';
import { NavigationBackBar } from 'components/NavigationBackBar';
import { theme } from 'resources/theme';
import dimens from 'resources/dimens';
import { SearchResultList } from 'components/search/SearchResultList';
import { AnalysisMenuList } from './AnalysisMenuList';
import { SearchResult, SearchResultItem } from 'models/search';
import AppLogger from 'utils/AppLogger';
import { ContentsHeader } from 'components/ContentsHeader';
import { useCommonShips } from 'app/commonSlice';
import { CheckCircle } from '@mui/icons-material';
import { NormalButton } from 'components/NormalButton';
import { CustomScrollBars } from 'components/CustomScrollBars';
import constants from 'resources/constants';
import { DatePicker } from 'components/DatePicker';
import { setAnalysisData, setTabIndex } from '../analysisSlice';
import { FuelConsumptionDetectionResult } from './FuelConsumptionDetectionResult';
import { roundValueToString } from 'utils/misc';

const RootDiv = styled('div')({
  width: '100%',
  height: '100%',
  zIndex: theme.zIndex.drawer + 10,
  background: colors.subDrawer.background,
});

const GridContents = styled(Grid)({
  padding: 0,
  height: '100%',
  '& > div': {
    overflow: 'hidden',
  },
});

const GridConditions = styled(Grid)({
  width: 600,
  minWidth: 600,
});

const GridItem = styled(Grid)({
  width: '100%',
  '&:last-child': {
    height: '100%',
  },
});

const DateRow = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  width: '100%',
  background: theme.palette.background.default,
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),
});
const DateErrorRow = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  width: '100%',
  background: theme.palette.background.default,
  paddingTop: 0,
  paddingBottom: 0,
});

const PeriodRow = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  width: '100%',
  background: theme.palette.background.default,
});

const FooterDiv = styled('div')({
  width: '100%',
  padding: theme.spacing(1),
  textAlign: 'center',
});

dayjs.extend(utc);

export function SearchFragment(): JSX.Element {
  AppLogger.debug('  CALL: analysis/search/SearchFragment');
  const intl = useIntl();
  const dispatch = useDispatch();
  const ships = useCommonShips();
  const filteredShips = useAnalysisSearchFilteredShips();
  const shipConfirmed = useAnalysisSearchShipConfirmed();
  const filterShipName = useAnalysisSearchFilterShipName();
  const checkedMachines = useAnalysisSearchCheckedMachines();
  const sections = useAnalysisSearchAnalysisSections();
  const sectionsErrorResult = useAnalysisSearchAnalysisSectionsError();
  const searchConditions = useAnalysisSearchConditions();
  const canSearch = useAnalysisSearchCanSearch();
  const searching = useAnalysisSearchSearching();
  const searchResults = useAnalysisSearchResults();
  const searchFuelResult = useAnalysisSearchFuelResult();
  const searchErrorResult = useAnalysisSearchError();
  const searchFixed = useAnalysisSearchFixed();
  const selectedSearchResultItem = useAnalysisSearchSelectedSearchResultItem();
  const [fuelConsumptionResults, setFuelConsumptionResults] = useState<
    { caption: string; value: string }[] | undefined
  >(undefined);
  const [aggregateResults, setAggregateResults] = useState<
    { caption: string; value: string }[] | undefined
  >(undefined);

  let csvEnabled = true;
  let filteredSearchResults: SearchResult[] = [];
  if (searchResults != null) {
    filteredSearchResults = searchResults.filter((x) => x.results.length > 0);
  }

  /**
   * 燃費診断の値変更
   */
  const onFuelConsumptionDetectionConditionChange = (
    id: string,
    sensorName: string,
    upperLimit: number,
    lowerLimit: number,
    upperValue: number,
    lowerValue: number
  ) => {
    const conditions = [...searchConditions.conditions];
    dispatch(
      setFuelConsumptionSearchConditions({
        data: conditions.map((condition) => {
          if (condition.id === id) {
            return {
              ...condition,
              lowerValue: lowerValue,
              upperValue: upperValue,
              invalidLowerLimit:
                lowerValue < condition.lowerLimit ||
                lowerValue > condition.upperLimit ||
                lowerValue > upperValue,
              invalidUpperLimit:
                upperValue < condition.lowerLimit ||
                upperValue > condition.upperLimit ||
                lowerValue > upperValue,
            };
          } else {
            return condition;
          }
        }),
        init: false,
      })
    );
  };

  /**
   * 燃費診断結果出力ボタン処理
   */
  const handleCsvOutput = () => {
    if (!csvEnabled) {
      return;
    }
    if (!searchFuelResult) return;
    const haederLabels = [
      intl.formatMessage({ id: msgId.analysisFuelConsumptionSog }),
      intl.formatMessage({ id: msgId.analysisFuelConsumptionOil }),
      intl.formatMessage({ id: msgId.analysisFuelConsumptionDisOperation }),
      intl.formatMessage({ id: msgId.analysisFuelConsumptionFuelDistance }),
      intl.formatMessage({ id: msgId.analysisFuelConsumptionTimeOperation }),
      intl.formatMessage({ id: msgId.analysisFuelConsumptionFuelTime }),
      intl.formatMessage({ id: msgId.analysisFuelConsumptionTotalTime }),
      intl.formatMessage({ id: msgId.analysisFuelConsumptionTimeForLow }),
      intl.formatMessage({ id: msgId.analysisFuelConsumptionRateOfLow }),
    ];
    const summary = searchFuelResult.summary;
    const headerValues = [
      roundValueToString(summary.SogForLowSpeedOperation, 1, true),
      roundValueToString(summary.FueloilConsumptionForLowSpeedOperation, 1, true),
      roundValueToString(summary.DisForLowSpeedOperation, 1, true),
      roundValueToString(summary.FuelPerDistance, 1, true),
      roundValueToString(summary.TimeForLowSpeedOperation, 1, true),
      roundValueToString(summary.FuelPertime, 1, true),
      roundValueToString(summary.TotalTime, 1, true),
      roundValueToString(summary.TimeForLowSpeedOperation, 1, true),
      roundValueToString(summary.RateOfLowSpeedOperation, 1, true),
    ];
    let bodyHeaders = [];
    if (searchFuelResult.dataFormatId !== 402) {
      bodyHeaders = [
        intl.formatMessage({ id: msgId.analysisFuelConsumptionCsvDate }),
        intl.formatMessage({ id: msgId.analysisFuelConsumptionCsvSog }),
        intl.formatMessage({ id: msgId.analysisFuelConsumptionCsvSpeedPort }),
        intl.formatMessage({ id: msgId.analysisFuelConsumptionCsvSpeedStbd }),
        intl.formatMessage({ id: msgId.analysisFuelConsumptionCsvFuelOilConsumptionPort }),
        intl.formatMessage({ id: msgId.analysisFuelConsumptionCsvFuelOilConsumptionStbd }),
        intl.formatMessage({ id: msgId.analysisFuelConsumptionCsvStatus }),
      ];
    } else {
      bodyHeaders = [
        intl.formatMessage({ id: msgId.analysisFuelConsumptionCsvDate }),
        intl.formatMessage({ id: msgId.analysisFuelConsumptionCsvSog }),
        intl.formatMessage({ id: msgId.analysisFuelConsumptionCsvSpeedPort }),
        intl.formatMessage({ id: msgId.analysisFuelConsumptionCsvSpeedStbd }),
        intl.formatMessage({ id: msgId.analysisFuelConsumptionCsvFuelOilConsumption }),
        intl.formatMessage({ id: msgId.analysisFuelConsumptionCsvStatus }),
      ];
    }

    const csvArray = new Array<Array<string>>();
    {
      let csvString = haederLabels.join(',') + '\r\n' + headerValues.join(',') + '\r\n\r\n';
      searchFuelResult.data.date.forEach((value, index) => {
        const row = new Array<string>();
        row.push(value.replace('T', ' ').substring(0, 19));
        if (searchFuelResult.dataFormatId !== 402) {
          row.push(
            String(
              searchFuelResult.data.VesSpdSog[index] === null
                ? ''
                : searchFuelResult.data.VesSpdSog[index]
            )
          );
          row.push(
            String(
              searchFuelResult.data.MegSpdP1[index] === null
                ? ''
                : searchFuelResult.data.MegSpdP1[index]
            )
          );
          row.push(
            String(
              searchFuelResult.data.MegSpdS1[index] === null
                ? ''
                : searchFuelResult.data.MegSpdS1[index]
            )
          );
          row.push(
            String(
              searchFuelResult.data.FuelOilConsumP1[index] === null
                ? ''
                : searchFuelResult.data.FuelOilConsumP1[index]
            )
          );
          row.push(
            String(
              searchFuelResult.data.FuelOilConsumS1[index] === null
                ? ''
                : searchFuelResult.data.FuelOilConsumS1[index]
            )
          );
          row.push(
            String(
              searchFuelResult.data.Status[index] === null ? '' : searchFuelResult.data.Status[index]
            )
          );
        } else {
          row.push(
            String(
              searchFuelResult.data.VesSog[index] === null
                ? ''
                : searchFuelResult.data.VesSog[index]
            )
          );
          row.push(
            String(
              searchFuelResult.data.EngSpdP[index] === null
                ? ''
                : searchFuelResult.data.EngSpdP[index]
            )
          );
          row.push(
            String(
              searchFuelResult.data.EngSpdS[index] === null
                ? ''
                : searchFuelResult.data.EngSpdS[index]
            )
          );
          row.push(
            String(
              searchFuelResult.data.MeFoFlowTotal[index] === null
                ? ''
                : searchFuelResult.data.MeFoFlowTotal[index]
            )
          );
          row.push(
            String(
              searchFuelResult.data.Status[index] === null ? '' : searchFuelResult.data.Status[index]
            )
          );
        }
        csvArray.push(row);
      });
      csvString += bodyHeaders.join(',') + '\r\n';
      csvArray.forEach((row) => {
        csvString += row.join(',') + '\r\n';
      });

      const bom = new Uint8Array([0xef, 0xbb, 0xbf]);
      const blob = new Blob([bom, csvString], { type: 'text/csv' });
      const url = (window.URL || window.webkitURL).createObjectURL(blob);
      const link = document.createElement('a');
      const fileName =
        intl.formatMessage({ id: msgId.AnalysisMenuFuelConsumptionDetection }) +
        '_' +
        dayjs(searchConditions.startDate)
          .utc()
          .format(constants.dateFormat.csvTitleyyyyMMddHHmmss) +
        '_' +
        dayjs(searchConditions.endDate).utc().format(constants.dateFormat.csvTitleyyyyMMddHHmmss) +
        '.csv';
      link.download = fileName;
      link.href = url;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      // } else {
      //   alert(intl.formatMessage({ id: msgId.errorMessageNoData }));
    }
    csvEnabled = false;
    setTimeout(() => {
      csvEnabled = true;
    }, 1000);
  };

  /**
   * バックボタン押下
   */
  const handleBackClick = () => {
    dispatch(setOpen(false));
  };

  /**
   * 船舶リストのチェック状態変更
   * @param shipId 船舶ID
   * @param machineId 機械ID
   * @param checked チェック状態
   */
  const handleCheckChange = (shipId: string, machineId: number, checked: boolean) => {
    dispatch(setShipCheck({ shipId, machineId, checked }));
  };

  /**
   * 検索入力変更
   * @param input 入力値
   */
  const handleSeachInputChange = (input: string) => {
    dispatch(setFilterShipName(input));
  };

  /**
   * 船舶検索条件決定
   */
  const handleShipOkClick = () => {
    dispatch(setShipConfirmed(true));
  };

  /**
   * 開始日付の変更
   * @param value 日付
   */
  const handleStartDateChange = (value: string | undefined) => {
    // logger.debug('handleStartDateChange value=' + value);
    if (value != null) {
      dispatch(setStartDate(value));
    }
  };

  /**
   * 終了日付の変更
   * @param value 日付
   */
  const handleEndDateChange = (value: string | undefined) => {
    // logger.debug('handleEndDateChange value=' + value);
    if (value != null) {
      dispatch(setEndDate(value));
    }
  };

  /**
   * 期間単位変更
   * @param event イベント
   */
  const handlePeriodUnitChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    dispatch(setPreriodUnit(event.target.value as PeriodUnit));
    if (event.target.value !== 'other' && searchConditions.periodUnit === 'other') {
      handleStartDateChange(
        dayjs(searchConditions.startDate).utc().format(constants.dateFormat.iso8601WithoutHours)
      );
      handleEndDateChange(
        dayjs(searchConditions.endDate)
          .utc()
          .format(constants.dateFormat.iso8601WithoutSecondsEndDate)
      );
    }
  };

  /**
   *分析メニューチェック状態変更
   */
  const handleAnalysisMenuChange = (
    analysisSectionId: number,
    analysisMenuId: number,
    checked: boolean
  ) => {
    dispatch(setAnalysisSectionChecked({ analysisSectionId, analysisMenuId, checked }));
  };

  /**
   * 検索実行
   */
  const handleSearchClick = () => {
    if (searchConditions.periodUnit === 'other') {
      dispatch(searchAnalysisFuelOilConsumDetectionSearch(checkedMachines[0], searchConditions));
    } else {
      dispatch(searchAnalysisData(checkedMachines, searchConditions));
    }
  };

  /**
   * 分析要素区分リストエラー
   */
  const handleAnalysisSectionsErrorResult = () => {
    dispatch(setAnalysisSectionsError(undefined));
  };

  /**
   * 検索エラー
   */
  const handleSearchErrorResult = () => {
    dispatch(setSearchError(undefined));
  };

  /**
   * 検索結果選択変更
   */
  const handleSearchResultItemChange = (searchResultItem: SearchResultItem) => {
    dispatch(setSelectedSearchResultItem(searchResultItem));
  };

  /**
   * 検索結果選択決定
   */
  const handleSelectResultClick = () => {
    dispatch(setAnalysisData(undefined));
    dispatch(setConfirmedSearchConditions(searchConditions));
    dispatch(setConfirmedResultItem(selectedSearchResultItem));
    dispatch(setOpen(false));
    dispatch(setTabIndex(searchConditions.analysisMenuIds.includes(13) ? 1 : 0));
  };

  useEffect(() => {
    if (ships != null) {
      dispatch(setCommonShips(ships));
    }
    if (sections == null) {
      dispatch(fetchAnalysisSections());
    }
  }, [dispatch, ships, sections]);

  useEffect(() => {
    if (checkedMachines.length === 1 && searchConditions.periodUnit === 'other') {
      const list: FuelConsumptionDetectionConditionItem[] = [];
      setFuelConsumptionResults(undefined);
      setAggregateResults(undefined);
      checkedMachines.forEach((machine) => {
        machine.sensorGroups.forEach((group) => {
          group.sensors.forEach((sensor) => {
            if (
              sensor.sensorName === 'VesSpdSOGC1MCOM' ||
              sensor.sensorName === 'VesSpdSogC1GCOM' ||
              sensor.sensorName === 'VesSog'
            ) {
              const item = {
                id: sensor.sensorName,
                sensorName: intl.formatMessage({ id: msgId.analysisFuelConsumptionVesSpd }),
                lowerLimit: sensor.displayLowerLimit,
                upperLimit: sensor.displayUpperLimit,
                lowerValue: constants.analysis.fuelConsumption.conditions.vesSpdSOG.lowerValue,
                upperValue: constants.analysis.fuelConsumption.conditions.vesSpdSOG.upperValue,
                unit: sensor.displayUnit,
                invalidLowerLimit: false,
                invalidUpperLimit: false,
                order: 0,
              } as FuelConsumptionDetectionConditionItem;
              if (list.length === 0) {
                list.push(item);
              } else {
                list.unshift(item);
              }
            }
            if (
              sensor.sensorName === 'MegSpdP1' ||
              sensor.sensorName === 'EngSpdP'
            ) {
              list.push({
                id: sensor.sensorName,
                sensorName: intl.formatMessage({ id: msgId.analysisFuelConsumptionMegSpd }),
                lowerLimit: sensor.displayLowerLimit,
                upperLimit: sensor.displayUpperLimit,
                lowerValue: constants.analysis.fuelConsumption.conditions.megSpd.lowerValue,
                upperValue: constants.analysis.fuelConsumption.conditions.megSpd.upperValue,
                unit: sensor.displayUnit,
                invalidLowerLimit: false,
                invalidUpperLimit: false,
                order: 0,
              });
            }
          });
        });
      });
      dispatch(setFuelConsumptionSearchConditions({ data: list, init: true }));
    }
  }, [dispatch, checkedMachines, searchConditions.periodUnit, intl]);

  useEffect(() => {
    if (searchFuelResult) {
      const summary = searchFuelResult.summary;
      setFuelConsumptionResults([
        {
          caption: intl.formatMessage({ id: msgId.analysisFuelConsumptionSog }),
          value: roundValueToString(summary.SogForLowSpeedOperation, 1, true),
        },
        {
          caption: intl.formatMessage({ id: msgId.analysisFuelConsumptionOil }),
          value: roundValueToString(summary.FueloilConsumptionForLowSpeedOperation, 1, true),
        },
        {
          caption: intl.formatMessage({ id: msgId.analysisFuelConsumptionDisOperation }),
          value: roundValueToString(summary.DisForLowSpeedOperation, 1, true),
        },
        {
          caption: intl.formatMessage({ id: msgId.analysisFuelConsumptionFuelDistance }),
          value: roundValueToString(summary.FuelPerDistance, 1, true),
        },
        {
          caption: intl.formatMessage({ id: msgId.analysisFuelConsumptionTimeOperation }),
          value: roundValueToString(summary.TimeForLowSpeedOperation, 1, true),
        },
        {
          caption: intl.formatMessage({ id: msgId.analysisFuelConsumptionFuelTime }),
          value: roundValueToString(summary.FuelPertime, 1, true),
        },
      ]);
      setAggregateResults([
        {
          caption: intl.formatMessage({ id: msgId.analysisFuelConsumptionTotalTime }),
          value: roundValueToString(summary.TotalTime, 1, true),
        },
        {
          caption: intl.formatMessage({ id: msgId.analysisFuelConsumptionTimeForLow }),
          value: roundValueToString(summary.TimeForLowSpeedOperation, 1, true),
        },
        {
          caption: intl.formatMessage({ id: msgId.analysisFuelConsumptionRateOfLow }),
          value: roundValueToString(summary.RateOfLowSpeedOperation, 1, true),
        },
      ]);
    } else {
      setFuelConsumptionResults(undefined);
      setAggregateResults(undefined);
    }
  }, [searchFuelResult, intl]);

  return (
    <RootDiv>
      <NavigationBackBar
        title={intl.formatMessage({ id: msgId.changeSearchConditions })}
        handleBackClick={handleBackClick}
        canBack={searchFixed}
      />
      <CustomScrollBars autoHide={false} height={`calc(100% - ${dimens.searchBar.height}px)`}>
        <GridContents container wrap="nowrap">
          {/* 船舶選択 */}
          <SearchShipList
            ships={filteredShips}
            checkedMachines={checkedMachines}
            filterShipName={filterShipName}
            onSearchInputChange={handleSeachInputChange}
            onCheckChange={handleCheckChange}
            onOkClick={handleShipOkClick}
          />
          <Divider orientation="vertical" flexItem />
          {/* 要素区分設定 */}
          <GridConditions item>
            <Grid
              container
              item
              direction="column"
              wrap="nowrap"
              sx={{ width: '100%', height: '100%' }}
            >
              <GridItem item>
                <ContentsHeader title={intl.formatMessage({ id: msgId.analysisSectionSettings })} />
                {shipConfirmed && sections != null && (
                  <React.Fragment>
                    <PeriodRow>
                      <FormControl
                        sx={{
                          margin: theme.spacing(1),
                          minWidth: 160,
                        }}
                      >
                        <TextField
                          id="period-unit"
                          select
                          label={intl.formatMessage({ id: msgId.periodUnit })}
                          value={searchConditions.periodUnit}
                          onChange={handlePeriodUnitChange}
                          variant="standard"
                          error={
                            checkedMachines.length !== 1 && searchConditions.periodUnit === 'other'
                          }
                        >
                          <MenuItem value="monthly">
                            {intl.formatMessage({ id: msgId.monthly })}
                          </MenuItem>
                          <MenuItem value="daily">
                            {intl.formatMessage({ id: msgId.daily })}
                          </MenuItem>
                          <MenuItem value="other">
                            {intl.formatMessage({ id: msgId.other })}
                          </MenuItem>
                        </TextField>
                      </FormControl>
                      <Typography variant="body2" sx={{ paddingLeft: theme.spacing(1) }}>
                        {intl.formatMessage({ id: msgId.guidePeriodUnits })}
                      </Typography>
                    </PeriodRow>
                    {checkedMachines.length !== 1 && searchConditions.periodUnit === 'other' && (
                      <PeriodRow>
                        <Typography
                          variant="body2"
                          sx={{ paddingLeft: theme.spacing(1) }}
                          color={'red'}
                        >
                          {intl.formatMessage({ id: msgId.analysisSelectOtherError })}
                        </Typography>
                      </PeriodRow>
                    )}
                    <DateRow>
                      <Typography
                        variant="body2"
                        noWrap
                        sx={{ paddingLeft: theme.spacing(1), color: colors.accent }}
                      >
                        {intl.formatMessage({ id: msgId.periodSearch })}
                      </Typography>
                      <DatePicker
                        value={searchConditions.startDate}
                        type={searchConditions.periodUnit === 'other' ? 'datetime' : 'date'}
                        displayFormat={
                          searchConditions.periodUnit === 'other'
                            ? constants.dateFormat.YYYYMMDDHHmm
                            : constants.dateFormat.YYYYMMDD
                        }
                        onValueChanged={handleStartDateChange}
                        error={searchConditions.invalidStartDate}
                      />
                      <Typography variant="h6" sx={{ paddingTop: '2px' }}>
                        -
                      </Typography>
                      <DatePicker
                        value={searchConditions.endDate}
                        type={searchConditions.periodUnit === 'other' ? 'datetime' : 'date'}
                        displayFormat={
                          searchConditions.periodUnit === 'other'
                            ? constants.dateFormat.YYYYMMDDHHmm
                            : constants.dateFormat.YYYYMMDD
                        }
                        onValueChanged={handleEndDateChange}
                        error={searchConditions.invalidEndDate}
                      />
                    </DateRow>
                    {searchConditions.invalidStartDate &&
                      searchConditions.invalidEndDate &&
                      dayjs(searchConditions.startDate) < dayjs(searchConditions.endDate) &&
                      searchConditions.periodUnit === 'other' && (
                        <DateErrorRow>
                          <Typography
                            variant="body2"
                            sx={{ paddingLeft: theme.spacing(1) }}
                            color={'red'}
                          >
                            {intl.formatMessage({ id: msgId.analysisSelectOtherDateError })}
                          </Typography>
                        </DateErrorRow>
                      )}
                    <Divider />
                  </React.Fragment>
                )}
              </GridItem>
              <GridItem item>
                {shipConfirmed && sections != null && (
                  <CustomScrollBars autoHide={false}>
                    <AnalysisMenuList
                      periodUnit={searchConditions.periodUnit}
                      analysisSections={sections}
                      onChange={handleAnalysisMenuChange}
                      onFuelConsumptionDetectionConditionChange={
                        onFuelConsumptionDetectionConditionChange
                      }
                      fuelConsumptionDetectionCondition={searchConditions.conditions}
                    />
                    <FooterDiv>
                      <NormalButton
                        label={intl.formatMessage({ id: msgId.decision })}
                        color="primary"
                        startIcon={<CheckCircle />}
                        disabled={!canSearch}
                        onClick={() => handleSearchClick()}
                      />
                    </FooterDiv>
                  </CustomScrollBars>
                )}
              </GridItem>
            </Grid>
          </GridConditions>
          <Divider orientation="vertical" flexItem />
          {/* 検索結果 */}
          {searchConditions.periodUnit === 'other' && (
            <FuelConsumptionDetectionResult
              fuelConsumptionResults={fuelConsumptionResults}
              aggregateResults={aggregateResults}
              nodata={searchFuelResult !== undefined && searchFuelResult.data.date.length === 0}
              onOutputClick={handleCsvOutput}
            ></FuelConsumptionDetectionResult>
          )}
          {searchConditions.periodUnit !== 'other' && (
            <SearchResultList
              searchResults={searchResults && filteredSearchResults}
              selectedItem={selectedSearchResultItem}
              dateFormat={constants.dateFormat.YYYYMMDD}
              dateOnly={true}
              onChange={handleSearchResultItemChange}
              onOkClick={handleSelectResultClick}
            />
          )}
        </GridContents>
      </CustomScrollBars>
      <FadeLoading loading={searching} />
      {sectionsErrorResult != null && (
        <ApiErrorDialog error={sectionsErrorResult} onClose={handleAnalysisSectionsErrorResult} />
      )}
      {searchErrorResult != null && (
        <ApiErrorDialog error={searchErrorResult} onClose={handleSearchErrorResult} />
      )}
    </RootDiv>
  );
}
