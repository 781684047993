import React, { useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import {
  Box,
  Button,
  ClickAwayListener,
  MenuItem,
  MenuList,
  Paper,
  Popover,
  styled,
} from '@mui/material';
import { theme } from 'resources/theme';
import { AddCircle } from '@mui/icons-material';
import msgId from 'resources/intl';
import { SensorGroup, Ship } from 'models/ships';
import { ChartSeries } from 'models/chart';
import colors from 'resources/colors';
import config from 'resources/config';
import { dumpPastDataGraphSensorList } from 'utils/dumpData';

const StyledPopover = styled(Popover)({
  zIndex: theme.zIndex.drawer + 101,
});

const StyledMenuList = styled(MenuList)({
  padding: 0,
  minWidth: 200,
});

interface ChartSensorPopupProps {
  baseShip: Ship;
  chartSeriesList: ChartSeries[];
  onClick?: (event: React.MouseEvent<EventTarget>, sensorGroup: SensorGroup) => void;
}

export function ChartSensorPopup(props: ChartSensorPopupProps): JSX.Element {
  const { baseShip, chartSeriesList, onClick } = props;
  const intl = useIntl();
  const anchorRef = useRef<HTMLButtonElement>(null);
  const [open, setOpen] = useState(false);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClickAway = (event: MouseEvent | TouchEvent) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  const handleClick = (event: React.MouseEvent<EventTarget>, sensorGroup: SensorGroup) => {
    if (open) {
      setOpen(false);
      onClick && onClick(event, sensorGroup);
    }
  };

  if (config.valueInspectionLog) {
    dumpPastDataGraphSensorList(baseShip);
  }

  return (
    <Box sx={{ background: colors.chart.button.footer }}>
      <Button
        size="small"
        variant="text"
        color="primary"
        sx={{ width: '100%', height: 32, borderRadius: 0 }}
        startIcon={<AddCircle />}
        disabled={chartSeriesList.length >= 9}
        ref={anchorRef}
        aria-controls={open ? 'sensor-list-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
      >
        {intl.formatMessage({ id: msgId.add })}
      </Button>
      <StyledPopover
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Paper>
          <ClickAwayListener onClickAway={handleClickAway}>
            <StyledMenuList
              autoFocusItem={open}
              id="sensor-list-grow"
              onKeyDown={handleListKeyDown}
            >
              {baseShip.machines[0].sensorGroups
                .filter(
                  (x) =>
                    chartSeriesList.every(
                      (y) => y.sensorGroup.sensorGroupName !== x.sensorGroupName
                    ) && x.sensors.some((x) => x.isVisualization)
                )
                .map((sensorGroup) => {
                  return (
                    <MenuItem
                      key={sensorGroup.sensorGroupName}
                      onClick={(event) => handleClick(event, sensorGroup)}
                    >
                      {(
                        intl.formatMessage({ id: sensorGroup.section }) +
                        ' ' +
                        intl.formatMessage({ id: sensorGroup.sensorGroupName })
                      ).trim()}
                    </MenuItem>
                  );
                })}
            </StyledMenuList>
          </ClickAwayListener>
        </Paper>
      </StyledPopover>
    </Box>
  );
}
