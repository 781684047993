import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  getAccountGroupsAdminAsync,
  getAccountsAdminAsync,
  getShipsAdminAsync,
  registrationAccountAdminAsync,
  updateAccountAdminAsync,
  uploadSettingsAdminAsync,
  deleteAccountAdminAsync,
  registrationAccountGroupAdminAsync,
  updateAccountGroupAdminAsync,
  deleteAccountGroupAdminAsync,
  registrationShipsAdminAsync,
  updateShipsAdminAsync,
  deleteShipAdminAsync,
  registrationMachineAdminAsync,
  updateMachineAdminAsync,
  deleteMachineAdminAsync,
  registrationDataRecalcAdminAsync,
  getDataRecalcAdminAsync,
} from 'api/maricoApiAdmin';
import { RootState } from 'app/rootReducer';
import { AppThunk } from 'app/store';
import { Account, AccountGroup, AdminAccount, AdminAccountGroup } from 'models/accounts';
import { AdminShip, UpdateResult, AdminMachine, AdminDataRecalc, DataRecalc } from 'models/admin';
import { ErrorResult, getErrorMsgIdFromErrorResult, getErrorResult } from 'models/error';
import { Machine, Ship } from 'models/ships';
import { useSelector } from 'react-redux';
import msgId from 'resources/intl';
import { getAccountGroupsLogoAsync } from 'api/maricoApiAccounts';
import IconKawasaki from 'resources/assets/IconKawasaki.svg';
import AppLogger from 'utils/AppLogger';
export type EditMode = 'account' | 'accountGroup' | 'ship' | 'dataRecalc';

interface AdminSettingsState {
  /** アカウント情報リスト */
  accounts: Account[] | undefined;
  /** アカウントグループリスト */
  accountGroups: AccountGroup[] | undefined;
  /** 船舶リスト */
  ships: Ship[] | undefined;
  /** データ再計算リスト */
  dataRecalcs: DataRecalc[] | undefined;
  /** データ取得中状態 */
  fetching: boolean;
  /** データ取得エラー */
  fetchError: ErrorResult | undefined;
  /** 編集モード */
  editMode: EditMode | undefined;
  /** 編集アカウントAPI結果 */
  editAccountResult: UpdateResult | undefined;
  /** 編集対象アカウント */
  editAccount: Account | undefined;
  /** 編集対象アカウントエラー */
  editAccountError: ErrorResult | undefined;
  /** 編集アカウントグループAPI結果 */
  editAccountGroupResult: UpdateResult | undefined;
  /** 編集対象アカウントグループ */
  editAccountGroup: AccountGroup | undefined;
  /** 編集対象アカウントグループエラー */
  editAccountGroupError: ErrorResult | undefined;
  /** 編集対象船舶API結果 */
  editShipResult: UpdateResult | undefined;
  /** 編集対象船舶 */
  editShip: Ship | undefined;
  /** 編集対象船舶エラー */
  editShipError: ErrorResult | undefined;
  /** 編集データ再計算API結果 */
  editDataRecalcResult: UpdateResult | undefined;
  /** 編集対象データ再計算 */
  editDataRecalc: DataRecalc | undefined;
  /** 編集対象データ再計算エラー */
  editDataRecalcError: ErrorResult | undefined;
  /** センサーアクセス権限設定エラー */
  sensorAccessAuthoritiesError: boolean;
  /** 分析メニューアクセス権限設定エラー */
  analysisMenuAccessAuthoritiesError: boolean;
  /** ファイルアップロードAPI結果 */
  fileUploadResult: UpdateResult | undefined;
  /** Logoのbase64文字列 */
  accountGroupLogoBase64: string | undefined;
}

const initialState: AdminSettingsState = {
  accounts: undefined,
  accountGroups: undefined,
  ships: undefined,
  dataRecalcs: undefined,
  fetching: false,
  fetchError: undefined,
  editMode: undefined,
  editAccountResult: undefined,
  editAccount: undefined,
  editAccountError: undefined,
  editAccountGroupResult: undefined,
  editAccountGroup: undefined,
  editAccountGroupError: undefined,
  editShipResult: undefined,
  editShip: undefined,
  editShipError: undefined,
  editDataRecalcResult: undefined,
  editDataRecalc: undefined,
  editDataRecalcError: undefined,
  sensorAccessAuthoritiesError: false,
  analysisMenuAccessAuthoritiesError: false,
  fileUploadResult: undefined,
  accountGroupLogoBase64: undefined,
};

const adminSettings = createSlice({
  name: 'adminSettings',
  initialState,
  reducers: {
    /**
     * 取得の開始を設定する。
     */
    startFetch: (state, { payload }: PayloadAction<boolean>) => {
      state.fetching = { payload }.payload;
    },
    /**
     * 取得エラーを設定する。
     */
    setFetchError: (state, { payload }: PayloadAction<ErrorResult | undefined>) => {
      state.fetchError = payload;
      state.fetching = false;
    },
    /**
     * 各種リストを設定する。
     */
    setAllList: (
      state,
      {
        payload,
      }: PayloadAction<{
        accounts: Account[];
        accountGroups: AccountGroup[];
        ships: Ship[];
        dataRecalcs: DataRecalc[];
      }>
    ) => {
      state.accounts = payload.accounts;
      const tmpGroup = payload.accountGroups;
      const groups: AccountGroup[] = [];
      tmpGroup.forEach((group) => {
        groups.push({
          accountGroupId: group.accountGroupId,
          accountGroupName: group.accountGroupName,
          accountGroupUrl: group.accountGroupUrl,
          accountGroupUrlText: window.location.origin + '/' + group.accountGroupUrl,
          accountGroupLogoUrl: group.accountGroupLogoUrl,
          createdAt: group.createdAt,
          updatedAt: group.updatedAt,
        });
      });
      state.accountGroups = groups;
      const tmpShips = payload.ships;
      const ships: Ship[] = [];
      tmpShips.forEach((tmpShip) => {
        ships.push({
          shipId: tmpShip.shipId,
          name: tmpShip.name,
          propulsionSerialNumbers: tmpShip.propulsionSerialNumbers,
          createdAt: tmpShip.createdAt,
          updatedAt: tmpShip.updatedAt,
          machines: tmpShip.machines.map((machine) => {
            return {
              machineId: machine.machineId,
              name: machine.name,
              serialNumbers: machine.serialNumbers,
              dataFormatId: machine.dataFormatId,
              sensorGroups: [],
              checked: false,
            };
          }),
          checked: false,
          propulsionSerialNumbersText: tmpShip.propulsionSerialNumbers.join(', '),
          machineNamesText: tmpShip.machines.map((x) => x.name).join(', '),
        });
      });
      state.ships = ships;
      state.dataRecalcs = payload.dataRecalcs;

      state.fetching = false;
    },
    /**
     * アカウント情報リスト、アカウントグループリスト、船舶リストを設定する。
     */
    setAccounts: (state, { payload }: PayloadAction<Account[]>) => {
      state.accounts = payload;
      state.fetching = false;
    },
    /**
     * アカウント編集状態を設定する。
     */
    setAccountEditing: (
      state,
      { payload }: PayloadAction<{ editing: boolean; account: Account | undefined }>
    ) => {
      state.editAccount = payload.account;
      state.editMode = payload.editing ? 'account' : undefined;
    },
    /**
     * アカウント編集結果を設定する。
     */
    setEditAccountResult: (state, { payload }: PayloadAction<UpdateResult | undefined>) => {
      state.editAccountResult = payload;
      state.fetching = false;
    },

    /**
     * 編集対象アカウントエラーを設定する。
     */
    setEditAccountError: (state, { payload }: PayloadAction<ErrorResult | undefined>) => {
      state.editAccountError = payload;
      state.fetching = false;
    },
    /**
     * アカウントグループリストを設定する。
     */
    setAccountGroups: (state, { payload }: PayloadAction<AccountGroup[]>) => {
      const tmpGroup = payload;
      const groups: AccountGroup[] = [];
      tmpGroup.forEach((group) => {
        groups.push({
          accountGroupId: group.accountGroupId,
          accountGroupName: group.accountGroupName,
          accountGroupUrl: group.accountGroupUrl,
          accountGroupUrlText: window.location.origin + '/' + group.accountGroupUrl,
          accountGroupLogoUrl: group.accountGroupLogoUrl,
          createdAt: group.createdAt,
          updatedAt: group.updatedAt,
        });
      });
      state.accountGroups = groups;
      state.fetching = false;
    },
    /**
     * アカウントグループ編集状態を設定する。
     */
    setAccountGroupEditing: (
      state,
      { payload }: PayloadAction<{ editing: boolean; accountGroup: AccountGroup | undefined }>
    ) => {
      state.editAccountGroup = payload.accountGroup;
      state.editMode = payload.editing ? 'accountGroup' : undefined;
    },
    /**
     * アカウントグループ編集結果を設定する。
     */
    setEditAccountGroupResult: (state, { payload }: PayloadAction<UpdateResult | undefined>) => {
      state.editAccountGroupResult = payload;
      state.fetching = false;
    },
    /**
     * 編集対象アカウントグループエラーを設定する。
     */
    setEditAccountGroupError: (state, { payload }: PayloadAction<ErrorResult | undefined>) => {
      state.editAccountGroupError = payload;
      state.fetching = false;
    },
    /**
     * 船舶リストを設定する。
     */
    setShips: (state, { payload }: PayloadAction<Ship[]>) => {
      const tmpShips = payload;
      const ships: Ship[] = [];
      tmpShips.forEach((tmpShip) => {
        ships.push({
          shipId: tmpShip.shipId,
          name: tmpShip.name,
          propulsionSerialNumbers: tmpShip.propulsionSerialNumbers,
          createdAt: tmpShip.createdAt,
          updatedAt: tmpShip.updatedAt,
          machines: tmpShip.machines.map((machine) => {
            return {
              machineId: machine.machineId,
              name: machine.name,
              serialNumbers: machine.serialNumbers,
              dataFormatId: machine.dataFormatId,
              sensorGroups: [],
              checked: false,
            };
          }),
          checked: false,
          propulsionSerialNumbersText: tmpShip.propulsionSerialNumbers.join(', '),
          machineNamesText: tmpShip.machines.map((x) => x.name).join(', '),
        });
      });
      state.ships = ships;
      state.fetching = false;
    },
    /**
     * 船舶編集状態を設定する。
     */
    setShipEditing: (
      state,
      { payload }: PayloadAction<{ editing: boolean; ship: Ship | undefined }>
    ) => {
      state.editShip = payload.ship;
      state.editMode = payload.editing ? 'ship' : undefined;
    },
    /**
     * 船舶編集結果を設定する。
     */
    setEditShipResult: (state, { payload }: PayloadAction<UpdateResult | undefined>) => {
      state.editShipResult = payload;
      state.fetching = false;
    },
    /**
     * 編集対象船舶エラーを設定する。
     */
    setEditShipError: (state, { payload }: PayloadAction<ErrorResult | undefined>) => {
      state.editShipError = payload;
      state.fetching = false;
    },
    setShipMachineEditing: (
      state,
      { payload }: PayloadAction<{ editing: boolean; machine: Machine }>
    ) => {
      if (state.editShip != null) {
        state.editShip = {
          shipId: state.editShip.shipId,
          name: state.editShip.name,
          propulsionSerialNumbers: state.editShip.propulsionSerialNumbers,
          createdAt: state.editShip.createdAt,
          updatedAt: state.editShip.updatedAt,
          machines: state.editShip.machines.map((machine) => {
            if (machine.machineId === payload.machine.machineId) {
              return {
                machineId: payload.machine.machineId,
                name: payload.machine.name,
                serialNumbers: payload.machine.serialNumbers,
                dataFormatId: payload.machine.dataFormatId,
                sensorGroups: [],
                checked: false,
              };
            } else {
              return {
                machineId: machine.machineId,
                name: machine.name,
                serialNumbers: machine.serialNumbers,
                dataFormatId: machine.dataFormatId,
                sensorGroups: [],
                checked: false,
              };
            }
          }),
          checked: false,
          propulsionSerialNumbersText: state.editShip.propulsionSerialNumbers.join(', '),
          machineNamesText: state.editShip.machines.map((x) => x.name).join(', '),
        };
        state.editMode = payload.editing ? 'ship' : undefined;
      }
    },
    /**
     * データ再計算リストを設定する。
     */
    setDataRecalcs: (state, { payload }: PayloadAction<DataRecalc[]>) => {
      state.dataRecalcs = payload;
      state.fetching = false;
    },
    /**
     * データ再計算編集状態を設定する。
     */
    setDataRecalcEditing: (
      state,
      { payload }: PayloadAction<{ editing: boolean; dataRecalc: DataRecalc | undefined }>
    ) => {
      state.editDataRecalc = payload.dataRecalc;
      state.editMode = payload.editing ? 'dataRecalc' : undefined;
    },
    /**
     * データ再計算編集結果を設定する。
     */
    setEditDataRecalcResult: (state, { payload }: PayloadAction<UpdateResult | undefined>) => {
      state.editDataRecalcResult = payload;
      state.fetching = false;
    },
    /**
     * 編集対象データ再計算エラーを設定する。
     */
    setEditDataRecalcError: (state, { payload }: PayloadAction<ErrorResult | undefined>) => {
      state.editDataRecalcError = payload;
      state.fetching = false;
    },
    /**
     * クリアする。
     */
    clear: (state) => {
      state.accounts = undefined;
      state.accountGroups = undefined;
      state.ships = undefined;
      state.dataRecalcs = undefined;
    },
    setSensorAccessAuthoritiesError: (state, { payload }: PayloadAction<boolean>) => {
      state.sensorAccessAuthoritiesError = payload;
    },
    setAnalysisMenuAccessAuthoritiesError: (state, { payload }: PayloadAction<boolean>) => {
      state.analysisMenuAccessAuthoritiesError = payload;
    },
    /**
     * stateからMachineを削除する。
     */
    deleteMachineState: (state, { payload }: PayloadAction<number>) => {
      const machineId = payload;
      if (state.editShip !== undefined) {
        const ship = {
          shipId: state.editShip.shipId,
          name: state.editShip.name,
          propulsionSerialNumbers: state.editShip.propulsionSerialNumbers,
          createdAt: state.editShip.createdAt,
          updatedAt: state.editShip.updatedAt,
          machines: state.editShip.machines
            .filter((x) => x.machineId !== machineId)
            .map((machine) => {
              return {
                machineId: machine.machineId,
                name: machine.name,
                serialNumbers: machine.serialNumbers,
                dataFormatId: machine.dataFormatId,
                sensorGroups: [],
                checked: false,
              };
            }),
          checked: false,
          propulsionSerialNumbersText: state.editShip.propulsionSerialNumbers.join(', '),
          machineNamesText: state.editShip.machines.map((x) => x.name).join(', '),
        };
        state.editShip = ship;
      }
    },
    /**
     * メールアドレス変更エラー状態を設定する。
     */
    setAccountGroupLogoBase64: (state, { payload }: PayloadAction<string | undefined>) => {
      if (payload && payload.length > 'data:image/png;base64,'.length) {
        state.accountGroupLogoBase64 = payload;
      } else {
        state.accountGroupLogoBase64 = undefined;
      }
    },
    /**
     * 船舶編集結果を設定する。
     */
    setFileUploadResult: (state, { payload }: PayloadAction<UpdateResult | undefined>) => {
      state.fileUploadResult = payload;
      state.fetching = false;
    },
    /**
     * AdminSettingsを設定する。
     */
    clearAdminSettings: (state) => {
      state.accounts = initialState.accounts;
      state.accountGroups = initialState.accountGroups;
      state.ships = initialState.ships;
      state.dataRecalcs = initialState.dataRecalcs;
      state.fetching = initialState.fetching;
      state.fetchError = initialState.fetchError;
      state.editMode = initialState.editMode;
      state.editAccount = initialState.editAccount;
      state.editAccountResult = initialState.editAccountResult;
      state.editAccountError = initialState.editAccountError;
      state.editAccountGroup = initialState.editAccountGroup;
      state.editAccountGroupResult = initialState.editAccountGroupResult;
      state.editAccountGroupError = initialState.editAccountGroupError;
      state.editShip = initialState.editShip;
      state.editShipResult = initialState.editShipResult;
      state.editShipError = initialState.editShipError;
      state.editDataRecalc = initialState.editDataRecalc;
      state.editDataRecalcResult = initialState.editDataRecalcResult;
      state.editDataRecalcError = initialState.editDataRecalcError;
      state.sensorAccessAuthoritiesError = initialState.sensorAccessAuthoritiesError;
      state.analysisMenuAccessAuthoritiesError = initialState.analysisMenuAccessAuthoritiesError;
      state.fileUploadResult = initialState.fileUploadResult;
    },
  },
});

export const {
  setFetchError,
  setAccountEditing,
  setEditAccountResult,
  setEditAccountError,
  setAccountGroupEditing,
  setEditAccountGroupResult,
  setEditAccountGroupError,
  setShipEditing,
  setEditShipResult,
  setEditShipError,
  setDataRecalcEditing,
  setEditDataRecalcResult,
  setEditDataRecalcError,
  clear,
  setSensorAccessAuthoritiesError,
  setAnalysisMenuAccessAuthoritiesError,
  setFileUploadResult,
  clearAdminSettings,
  setAccountGroupLogoBase64,
} = adminSettings.actions;

/**
 * アカウント情報リスト、アカウントグループリスト、船舶リストを取得する。
 */
export const fetchAllList = (): AppThunk => async (dispatch) => {
  dispatch(adminSettings.actions.startFetch(true));
  try {
    const accounts = await getAccountsAdminAsync();
    const accountGroups = await getAccountGroupsAdminAsync();
    const ships = await getShipsAdminAsync();
    const dataRecalcs = await getDataRecalcAdminAsync();
    dispatch(adminSettings.actions.setAllList({ accounts, accountGroups, ships, dataRecalcs }));
  } catch (error) {
    dispatch(adminSettings.actions.setFetchError(getErrorResult(error)));
  }
};

/**
 * アカウント情報リストを取得する。
 */
export const fetchAccounts = (): AppThunk => async (dispatch) => {
  dispatch(adminSettings.actions.startFetch(true));
  try {
    const accounts = await getAccountsAdminAsync();
    dispatch(adminSettings.actions.setAccounts(accounts));
  } catch (error) {
    dispatch(adminSettings.actions.setFetchError(getErrorResult(error)));
  }
};

/**
 * アカウント情報リストを取得する。(fetchingなし)
 */
export const fetchAccountsSilent = (): AppThunk => async (dispatch) => {
  try {
    const accounts = await getAccountsAdminAsync();
    dispatch(adminSettings.actions.setAccounts(accounts));
  } catch (error) {
    dispatch(adminSettings.actions.setFetchError(getErrorResult(error)));
  }
};

/**
 * アカウントグループリストを取得する。
 */
export const fetchAccountGroups = (): AppThunk => async (dispatch) => {
  dispatch(adminSettings.actions.startFetch(true));
  try {
    const accountGroups = await getAccountGroupsAdminAsync();
    dispatch(adminSettings.actions.setAccountGroups(accountGroups));
  } catch (error) {
    dispatch(adminSettings.actions.setFetchError(getErrorResult(error)));
  }
};

/**
 * アカウントグループリストを取得する。(fetchingなし)
 */
export const fetchAccountGroupsSilent = (): AppThunk => async (dispatch) => {
  try {
    const accountGroups = await getAccountGroupsAdminAsync();
    dispatch(adminSettings.actions.setAccountGroups(accountGroups));
  } catch (error) {
    dispatch(adminSettings.actions.setFetchError(getErrorResult(error)));
  }
};

/**
 * 船舶リストを取得する。
 */
export const fetchShips = (): AppThunk => async (dispatch) => {
  dispatch(adminSettings.actions.startFetch(true));
  try {
    const ships = await getShipsAdminAsync();
    dispatch(adminSettings.actions.setShips(ships));
  } catch (error) {
    dispatch(adminSettings.actions.setFetchError(getErrorResult(error)));
  }
};

/**
 * 船舶リストを取得する。(fetchingなし)
 */
export const fetchShipsSilent = (): AppThunk => async (dispatch) => {
  try {
    const ships = await getShipsAdminAsync();
    dispatch(adminSettings.actions.setShips(ships));
  } catch (error) {
    dispatch(adminSettings.actions.setFetchError(getErrorResult(error)));
  }
};

/**
 * データ再計算リストを取得する。
 */
export const fetchDataRecalcs = (): AppThunk => async (dispatch) => {
  dispatch(adminSettings.actions.startFetch(true));
  try {
    const dataRecalcs = await getDataRecalcAdminAsync();
    dispatch(adminSettings.actions.setDataRecalcs(dataRecalcs));
  } catch (error) {
    dispatch(adminSettings.actions.setFetchError(getErrorResult(error)));
  }
};

/**
 * データ再計算リストを取得する。(fetchingなし)
 */
export const fetchDataRecalcsSilent = (): AppThunk => async (dispatch) => {
  try {
    const dataRecalcs = await getDataRecalcAdminAsync();
    dispatch(adminSettings.actions.setDataRecalcs(dataRecalcs));
  } catch (error) {
    dispatch(adminSettings.actions.setFetchError(getErrorResult(error)));
  }
};

/**
 * アカウント情報を登録する。
 */
export const registrationAccount =
  (
    authorityId: number,
    accountGroupId: number,
    mailAddress: string,
    cognitoAccountId: string
  ): AppThunk =>
  async (dispatch) => {
    dispatch(adminSettings.actions.startFetch(true));
    try {
      await registrationAccountAdminAsync({
        authorityId: authorityId,
        accountGroupId: accountGroupId,
        mailAddress: mailAddress,
        cognitoAccountId: cognitoAccountId,
      });
      dispatch(
        adminSettings.actions.setEditAccountResult({
          isSuccess: true,
          showDialog: true,
          msgId: msgId.resultCompleted,
          isDeleteRequest: false,
        })
      );
    } catch (error) {
      const errorResult = getErrorResult(error);
      dispatch(adminSettings.actions.setEditAccountError(errorResult));
      dispatch(
        adminSettings.actions.setEditAccountResult({
          isSuccess: false,
          showDialog: errorResult.status !== 400,
          msgId: getErrorMsgIdFromErrorResult(errorResult),
          isDeleteRequest: false,
        })
      );
    }
  };

/**
 * アカウント情報を更新する。
 */
export const updateAccount =
  (accountId: number, account: AdminAccount): AppThunk =>
  async (dispatch) => {
    dispatch(adminSettings.actions.startFetch(true));
    try {
      await updateAccountAdminAsync(accountId, account);
      dispatch(
        adminSettings.actions.setEditAccountResult({
          isSuccess: true,
          showDialog: true,
          msgId: msgId.resultCompleted,
          isDeleteRequest: false,
        })
      );
    } catch (error) {
      const errorResult = getErrorResult(error);
      dispatch(adminSettings.actions.setEditAccountError(errorResult));
      dispatch(
        adminSettings.actions.setEditAccountResult({
          isSuccess: false,
          showDialog: errorResult.status !== 400,
          msgId: getErrorMsgIdFromErrorResult(errorResult),
          isDeleteRequest: false,
        })
      );
    }
  };

/**
 * アカウント情報を削除する。
 */
export const deleteAccount =
  (accountId: number): AppThunk =>
  async (dispatch) => {
    dispatch(adminSettings.actions.startFetch(true));
    try {
      await deleteAccountAdminAsync(accountId);
      dispatch(
        adminSettings.actions.setEditAccountResult({
          isSuccess: true,
          showDialog: false,
          msgId: msgId.resultCompleted,
          isDeleteRequest: true,
        })
      );
    } catch (error) {
      const errorResult = getErrorResult(error);
      dispatch(adminSettings.actions.setEditAccountError(errorResult));
      dispatch(
        adminSettings.actions.setEditAccountResult({
          isSuccess: false,
          showDialog: errorResult.status !== 400,
          msgId: getErrorMsgIdFromErrorResult(errorResult),
          isDeleteRequest: true,
        })
      );
    }
  };

/**
 * アカウントグループ情報を登録する。
 */
export const registrationAccountGroup =
  (accountGroup: AdminAccountGroup): AppThunk =>
  async (dispatch) => {
    dispatch(adminSettings.actions.startFetch(true));
    try {
      await registrationAccountGroupAdminAsync(accountGroup);
      dispatch(
        adminSettings.actions.setEditAccountGroupResult({
          isSuccess: true,
          showDialog: true,
          msgId: msgId.resultCompleted,
          isDeleteRequest: false,
        })
      );
    } catch (error) {
      const errorResult = getErrorResult(error);
      dispatch(adminSettings.actions.setEditAccountGroupError(errorResult));
      dispatch(
        adminSettings.actions.setEditAccountGroupResult({
          isSuccess: false,
          showDialog: errorResult.status !== 400,
          msgId: getErrorMsgIdFromErrorResult(errorResult),
          isDeleteRequest: false,
        })
      );
    }
  };

/**
 * アカウントグループ情報を更新する。
 */
export const updateAccountGroup =
  (accountGroupId: number, accountGroup: AdminAccountGroup): AppThunk =>
  async (dispatch) => {
    dispatch(adminSettings.actions.startFetch(true));
    try {
      await updateAccountGroupAdminAsync(accountGroupId, accountGroup);
      dispatch(
        adminSettings.actions.setEditAccountGroupResult({
          isSuccess: true,
          showDialog: true,
          msgId: msgId.resultCompleted,
          isDeleteRequest: false,
        })
      );
    } catch (error) {
      const errorResult = getErrorResult(error);
      dispatch(adminSettings.actions.setEditAccountGroupError(errorResult));
      dispatch(
        adminSettings.actions.setEditAccountGroupResult({
          isSuccess: false,
          showDialog: errorResult.status !== 400,
          msgId: getErrorMsgIdFromErrorResult(errorResult),
          isDeleteRequest: false,
        })
      );
    }
  };

/**
 * アカウントグループ情報を削除する。
 */
export const deleteAccountGroup =
  (accountGroupId: number): AppThunk =>
  async (dispatch) => {
    dispatch(adminSettings.actions.startFetch(true));
    try {
      await deleteAccountGroupAdminAsync(accountGroupId);
      dispatch(
        adminSettings.actions.setEditAccountGroupResult({
          isSuccess: true,
          showDialog: false,
          msgId: msgId.resultCompleted,
          isDeleteRequest: true,
        })
      );
    } catch (error) {
      const errorResult = getErrorResult(error);
      if (errorResult.response && !Array.isArray(errorResult.response)) {
        const errorInfo = errorResult.response;
        if (errorInfo.errorId === 'PARAMETER_REFERENCE_ERROR') {
          dispatch(
            adminSettings.actions.setEditAccountGroupResult({
              isSuccess: false,
              showDialog: true,
              msgId: msgId.errorMessageRelationError,
              isDeleteRequest: true,
            })
          );
        } else {
          dispatch(
            adminSettings.actions.setEditAccountGroupResult({
              isSuccess: false,
              showDialog: errorResult.status !== 400,
              msgId: getErrorMsgIdFromErrorResult(errorResult),
              isDeleteRequest: true,
            })
          );
        }
      } else if (errorResult.response && Array.isArray(errorResult.response)) {
        const result: UpdateResult = {
          isSuccess: false,
          showDialog: errorResult.status !== 400,
          msgId: getErrorMsgIdFromErrorResult(errorResult),
          isDeleteRequest: true,
        };
        errorResult.response.forEach((info) => {
          if (info.errorId === 'PARAMETER_REFERENCE_ERROR') {
            result.showDialog = true;
            result.msgId = msgId.errorMessageRelationError;
          }
        });
        dispatch(adminSettings.actions.setEditAccountGroupResult(result));
      } else {
        dispatch(
          adminSettings.actions.setEditAccountGroupResult({
            isSuccess: false,
            showDialog: errorResult.status !== 400,
            msgId: getErrorMsgIdFromErrorResult(errorResult),
            isDeleteRequest: true,
          })
        );
      }
      dispatch(adminSettings.actions.setEditAccountGroupError(errorResult));
    }
  };

/**
 * 船舶情報を登録する。
 */
export const registrationShips =
  (ship: AdminShip): AppThunk =>
  async (dispatch) => {
    dispatch(adminSettings.actions.startFetch(true));
    try {
      const result = await registrationShipsAdminAsync(ship);
      dispatch(adminSettings.actions.setShipEditing({ editing: true, ship: result }));
      dispatch(
        adminSettings.actions.setEditShipResult({
          isSuccess: true,
          showDialog: true,
          msgId: msgId.resultCompleted,
          isDeleteRequest: false,
        })
      );
    } catch (error) {
      const errorResult = getErrorResult(error);
      dispatch(
        adminSettings.actions.setEditShipResult({
          isSuccess: false,
          showDialog: errorResult.status !== 400,
          msgId: getErrorMsgIdFromErrorResult(errorResult),
          isDeleteRequest: false,
        })
      );
      dispatch(adminSettings.actions.setEditShipError(errorResult));
    }
  };

/**
 * 船舶情報を更新する。
 */
export const updateShips =
  (shipId: string, ship: AdminShip): AppThunk =>
  async (dispatch) => {
    dispatch(adminSettings.actions.startFetch(true));
    try {
      const result = await updateShipsAdminAsync(shipId, ship);
      dispatch(adminSettings.actions.setShipEditing({ editing: true, ship: result }));
      dispatch(
        adminSettings.actions.setEditShipResult({
          isSuccess: true,
          showDialog: true,
          msgId: msgId.resultCompleted,
          isDeleteRequest: false,
        })
      );
    } catch (error) {
      const errorResult = getErrorResult(error);
      dispatch(
        adminSettings.actions.setEditShipResult({
          isSuccess: false,
          showDialog: errorResult.status !== 400,
          msgId: getErrorMsgIdFromErrorResult(errorResult),
          isDeleteRequest: false,
        })
      );
      dispatch(adminSettings.actions.setEditShipError(errorResult));
    }
  };

/**
 * 船舶情報を削除する。
 */
export const deleteShips =
  (shipId: string): AppThunk =>
  async (dispatch) => {
    dispatch(adminSettings.actions.startFetch(true));
    try {
      await deleteShipAdminAsync(shipId);
      dispatch(
        adminSettings.actions.setEditShipResult({
          isSuccess: true,
          showDialog: false,
          msgId: msgId.resultCompleted,
          isDeleteRequest: true,
        })
      );
    } catch (error) {
      const errorResult = getErrorResult(error);
      if (errorResult.response && !Array.isArray(errorResult.response)) {
        const errorInfo = errorResult.response;
        if (errorInfo.errorId === 'PARAMETER_REFERENCE_ERROR') {
          dispatch(
            adminSettings.actions.setEditShipResult({
              isSuccess: false,
              showDialog: true,
              msgId: msgId.errorMessageRelationError,
              isDeleteRequest: true,
            })
          );
          dispatch(
            adminSettings.actions.setEditShipError({
              status: 400,
              response: {
                errorId: 'PARAMETER_REFERENCE_ERROR',
                errorField: '',
                errorDescription: '',
              },
            })
          );
        } else {
          dispatch(
            adminSettings.actions.setEditShipResult({
              isSuccess: false,
              showDialog: errorResult.status !== 400,
              msgId: getErrorMsgIdFromErrorResult(errorResult),
              isDeleteRequest: true,
            })
          );
          dispatch(adminSettings.actions.setEditShipError(errorResult));
        }
      } else if (errorResult.response && Array.isArray(errorResult.response)) {
        const result: UpdateResult = {
          isSuccess: false,
          showDialog: errorResult.status !== 400,
          msgId: getErrorMsgIdFromErrorResult(errorResult),
          isDeleteRequest: true,
        };
        errorResult.response.forEach((info) => {
          if (info.errorId === 'PARAMETER_REFERENCE_ERROR') {
            result.showDialog = true;
            result.msgId = msgId.errorMessageRelationError;
          }
        });
        dispatch(adminSettings.actions.setEditShipResult(result));
        if (result.msgId === msgId.errorMessageRelationError) {
          dispatch(
            adminSettings.actions.setEditShipError({
              status: 400,
              response: {
                errorId: 'PARAMETER_REFERENCE_ERROR',
                errorField: '',
                errorDescription: '',
              },
            })
          );
        } else {
          dispatch(adminSettings.actions.setEditShipError(errorResult));
        }
      } else {
        dispatch(
          adminSettings.actions.setEditShipResult({
            isSuccess: false,
            showDialog: errorResult.status !== 400,
            msgId: getErrorMsgIdFromErrorResult(errorResult),
            isDeleteRequest: true,
          })
        );
        dispatch(adminSettings.actions.setEditShipError(errorResult));
      }
    }
  };

/**
 * 機械情報を登録する。
 */
export const registrationMachine =
  (shipId: string, machine: AdminMachine): AppThunk =>
  async (dispatch) => {
    dispatch(adminSettings.actions.startFetch(true));
    try {
      const result = await registrationMachineAdminAsync(shipId, machine);
      dispatch(adminSettings.actions.setShipEditing({ editing: true, ship: result }));
      dispatch(
        adminSettings.actions.setEditShipResult({
          isSuccess: true,
          showDialog: true,
          msgId: msgId.resultCompleted,
          isDeleteRequest: false,
        })
      );
    } catch (error) {
      const errorResult = getErrorResult(error);
      dispatch(
        adminSettings.actions.setEditShipResult({
          isSuccess: false,
          showDialog: errorResult.status !== 400,
          msgId: getErrorMsgIdFromErrorResult(errorResult),
          isDeleteRequest: false,
        })
      );
      dispatch(adminSettings.actions.setEditShipError(errorResult));
    }
  };

/**
 * 機械情報を更新する。
 */
export const updateMachine =
  (shipId: string, machineId: string, machine: AdminMachine): AppThunk =>
  async (dispatch) => {
    dispatch(adminSettings.actions.startFetch(true));
    try {
      const result = await updateMachineAdminAsync(shipId, machineId, machine);
      dispatch(adminSettings.actions.setShipMachineEditing({ editing: true, machine: result }));
      dispatch(
        adminSettings.actions.setEditShipResult({
          isSuccess: true,
          showDialog: true,
          msgId: msgId.resultCompleted,
          isDeleteRequest: false,
        })
      );
    } catch (error) {
      const errorResult = getErrorResult(error);
      dispatch(
        adminSettings.actions.setEditShipResult({
          isSuccess: false,
          showDialog: errorResult.status !== 400,
          msgId: getErrorMsgIdFromErrorResult(errorResult),
          isDeleteRequest: false,
        })
      );
      dispatch(adminSettings.actions.setEditShipError(errorResult));
    }
  };

/**
 * 機械情報を削除する。
 */
export const deleteMachine =
  (shipId: string, machineId: string): AppThunk =>
  async (dispatch) => {
    dispatch(adminSettings.actions.startFetch(true));
    try {
      await deleteMachineAdminAsync(shipId, machineId);
      dispatch(
        adminSettings.actions.setEditShipResult({
          isSuccess: true,
          showDialog: false,
          msgId: msgId.resultCompleted,
          isDeleteRequest: true,
        })
      );
      dispatch(adminSettings.actions.deleteMachineState(parseInt(machineId + '', 10)));
    } catch (error) {
      const errorResult = getErrorResult(error);
      if (errorResult.response && !Array.isArray(errorResult.response)) {
        const errorInfo = errorResult.response;
        if (errorInfo.errorId === 'PARAMETER_REFERENCE_ERROR') {
          dispatch(
            adminSettings.actions.setEditShipResult({
              isSuccess: false,
              showDialog: true,
              msgId: msgId.errorMessageRelationError,
              isDeleteRequest: true,
            })
          );
        } else {
          dispatch(
            adminSettings.actions.setEditShipResult({
              isSuccess: false,
              showDialog: errorResult.status !== 400,
              msgId: getErrorMsgIdFromErrorResult(errorResult),
              isDeleteRequest: true,
            })
          );
        }
      } else if (errorResult.response && Array.isArray(errorResult.response)) {
        const result: UpdateResult = {
          isSuccess: false,
          showDialog: errorResult.status !== 400,
          msgId: getErrorMsgIdFromErrorResult(errorResult),
          isDeleteRequest: true,
        };
        errorResult.response.forEach((info) => {
          if (info.errorId === 'PARAMETER_REFERENCE_ERROR') {
            result.showDialog = true;
            result.msgId = msgId.errorMessageRelationError;
          }
        });
        dispatch(adminSettings.actions.setEditShipResult(result));
      } else {
        dispatch(
          adminSettings.actions.setEditShipResult({
            isSuccess: false,
            showDialog: errorResult.status !== 400,
            msgId: getErrorMsgIdFromErrorResult(errorResult),
            isDeleteRequest: true,
          })
        );
      }
      dispatch(adminSettings.actions.setEditShipError(errorResult));
    }
  };

/**
 * データ再計算情報を登録する。
 */
export const registrationDataRecalc =
  (dataRecalc: AdminDataRecalc): AppThunk =>
  async (dispatch) => {
    dispatch(adminSettings.actions.startFetch(true));
    try {
      const result = await registrationDataRecalcAdminAsync(dataRecalc);
      dispatch(adminSettings.actions.setDataRecalcEditing({ editing: true, dataRecalc: result }));
      dispatch(
        adminSettings.actions.setEditDataRecalcResult({
          isSuccess: true,
          showDialog: true,
          msgId: msgId.resultCompleted,
          isDeleteRequest: false,
        })
      );
    } catch (error) {
      const errorResult = getErrorResult(error);
      dispatch(
        adminSettings.actions.setEditDataRecalcResult({
          isSuccess: false,
          showDialog: errorResult.status !== 400,
          msgId: getErrorMsgIdFromErrorResult(errorResult),
          isDeleteRequest: false,
        })
      );
      dispatch(adminSettings.actions.setEditDataRecalcError(errorResult));
    }
  };

/**
 * センサーアクセス権限設定をアップロードする。
 */
export const uploadSensorAccessAuthorities =
  (settings: string): AppThunk =>
  async (dispatch) => {
    dispatch(adminSettings.actions.startFetch(true));
    try {
      await uploadSettingsAdminAsync('SensorAccessAuthorities', settings);
      dispatch(
        adminSettings.actions.setFileUploadResult({
          isSuccess: true,
          showDialog: true,
          msgId: msgId.resultCompleted,
          isDeleteRequest: false,
        })
      );
    } catch (error) {
      const errorResult = getErrorResult(error);
      dispatch(
        adminSettings.actions.setFileUploadResult({
          isSuccess: false,
          showDialog: true,
          msgId: getErrorMsgIdFromErrorResult(errorResult),
          isDeleteRequest: false,
        })
      );
    }
  };

/**
 * 分析メニューアクセス権限設定をアップロードする。
 */
export const uploadAnalysisMenuAuthority =
  (settings: string): AppThunk =>
  async (dispatch) => {
    dispatch(adminSettings.actions.startFetch(true));
    try {
      await uploadSettingsAdminAsync('AnalysisMenuAccessAuthorities', settings);
      dispatch(
        adminSettings.actions.setFileUploadResult({
          isSuccess: true,
          showDialog: true,
          msgId: msgId.resultCompleted,
          isDeleteRequest: false,
        })
      );
    } catch (error) {
      const errorResult = getErrorResult(error);
      dispatch(
        adminSettings.actions.setFileUploadResult({
          isSuccess: false,
          showDialog: true,
          msgId: getErrorMsgIdFromErrorResult(errorResult),
          isDeleteRequest: false,
        })
      );
    }
  };

export const getAccountGroupsLogoUrl =
  (accountGroupsUrl: string): AppThunk =>
  async (dispatch) => {
    try {
      const accountGroupsLogo = await getAccountGroupsLogoAsync(accountGroupsUrl);
      dispatch(adminSettings.actions.setAccountGroupLogoBase64(accountGroupsLogo));
    } catch (error) {
      AppLogger.debug('error accountGroupsLogoUrl: ', error);
      dispatch(adminSettings.actions.setAccountGroupLogoBase64(IconKawasaki.toString()));
    }
  };

const adminSettingsState = (state: RootState) => state.adminSettings;
const selectAccounts = createSelector(adminSettingsState, (x) => x.accounts);
const selectAccountGroups = createSelector(adminSettingsState, (x) => x.accountGroups);
const selectShips = createSelector(adminSettingsState, (x) => x.ships);
const selectDataRecalcs = createSelector(adminSettingsState, (x) => x.dataRecalcs);
const selectFetchFetching = createSelector(adminSettingsState, (x) => x.fetching);
const selectFetchError = createSelector(adminSettingsState, (x) => x.fetchError);
const selectEditMode = createSelector(adminSettingsState, (x) => x.editMode);
const selectEditAccount = createSelector(adminSettingsState, (x) => x.editAccount);
const selectEditAccountGroup = createSelector(adminSettingsState, (x) => x.editAccountGroup);
const selectEditShip = createSelector(adminSettingsState, (x) => x.editShip);
const selectEditDataRecalc = createSelector(adminSettingsState, (x) => x.editDataRecalc);
const selectSensorAccessAuthoritiesError = createSelector(
  adminSettingsState,
  (x) => x.sensorAccessAuthoritiesError
);
const selectAnalysisMenuAccessAuthoritiesError = createSelector(
  adminSettingsState,
  (x) => x.analysisMenuAccessAuthoritiesError
);
const selectEditAccountError = createSelector(adminSettingsState, (x) => x.editAccountError);
const selectEditAccountGroupError = createSelector(
  adminSettingsState,
  (x) => x.editAccountGroupError
);
const selectEditShipError = createSelector(adminSettingsState, (x) => x.editShipError);
const selectEditDataRecalcError = createSelector(adminSettingsState, (x) => x.editDataRecalcError);
const selectFileUploadResult = createSelector(adminSettingsState, (x) => x.fileUploadResult);
const selectEditAccountResult = createSelector(adminSettingsState, (x) => x.editAccountResult);
const selectEditAccountGroupResult = createSelector(
  adminSettingsState,
  (x) => x.editAccountGroupResult
);
const selectEditShipResult = createSelector(adminSettingsState, (x) => x.editShipResult);
const selectEditDataRecalcResult = createSelector(
  adminSettingsState,
  (x) => x.editDataRecalcResult
);
const selectAccountGroupLogoBase64 = createSelector(
  adminSettingsState,
  (x) => x.accountGroupLogoBase64
);

export const useAdminSettingsAccounts = () => useSelector(selectAccounts);
export const useAdminSettingsAccountGroups = () => useSelector(selectAccountGroups);
export const useAdminSettingsShips = () => useSelector(selectShips);
export const useAdminSettingsDataRecalcs = () => useSelector(selectDataRecalcs);
export const useAdminSettingsFetching = () => useSelector(selectFetchFetching);
export const useAdminSettingsFetchError = () => useSelector(selectFetchError);
export const useAdminSettingsEditMode = () => useSelector(selectEditMode);
export const useAdminSettingsEditAccount = () => useSelector(selectEditAccount);
export const useAdminSettingsEditAccountGroup = () => useSelector(selectEditAccountGroup);
export const useAdminSettingsEditShip = () => useSelector(selectEditShip);
export const useAdminSettingsEditDataRecalc = () => useSelector(selectEditDataRecalc);
export const useAdminSettingsSensorAccessAuthoritiesError = () =>
  useSelector(selectSensorAccessAuthoritiesError);
export const useAdminSettingsAnalysisMenuAccessAuthoritiesError = () =>
  useSelector(selectAnalysisMenuAccessAuthoritiesError);
export const useAdminSettingssEditAccountError = () => useSelector(selectEditAccountError);
export const useAdminSettingssEditAccountGroupError = () =>
  useSelector(selectEditAccountGroupError);
export const useAdminSettingssEditShipError = () => useSelector(selectEditShipError);
export const useAdminSettingssEditDataRecalcError = () => useSelector(selectEditDataRecalcError);
export const useAdminSettingsFileUploadResult = () => useSelector(selectFileUploadResult);
export const useAdminSettingsEditAccountResult = () => useSelector(selectEditAccountResult);
export const useAdminSettingsEditAccountGroupResult = () =>
  useSelector(selectEditAccountGroupResult);
export const useAdminSettingsEditShipResult = () => useSelector(selectEditShipResult);
export const useAdminSettingsEditDataRecalcResult = () => useSelector(selectEditDataRecalcResult);
export const useAdminSettingsEditAccountGroupLogoBase64 = () =>
  useSelector(selectAccountGroupLogoBase64);

export default adminSettings.reducer;
