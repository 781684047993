import React, { useEffect, useRef } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { FadeLoading } from 'components/FadeLoading';
import {
  fetchDiagnosisListItems,
  setSelectedDiagnosis,
  setSensorDataList,
  useDiagnosisCurrentDiagnosis,
  useDiagnosisDataFetching,
  useDiagnosisListFetching,
  useDiagnosisListItems,
  useDiagnosisSelectedDiagnosis,
} from './diagnosisSlice';

import msgId from 'resources/intl';
import colors from 'resources/colors';
import { TreeList, Column, FilterRow, Selection, Scrolling } from 'devextreme-react/tree-list';
import { RowPreparedEvent } from 'devextreme/ui/tree_list';
import { DiagnosisListItem } from 'models/diagnosis';
import { styled, Tooltip } from '@mui/material';
import { ContentsHeader } from 'components/ContentsHeader';
import { NormalButton } from 'components/NormalButton';
import { Search as SearchIcon } from '@mui/icons-material';
import {
  CautionIcon,
  FaultIcon,
  WarningIcon,
  MaintenanceErrorIcon,
} from 'components/shipDrawer/ShipItem';
import dimens from 'resources/dimens';
import constants from 'resources/constants';
import { DxDataGridColumn } from 'components/DxDataGrid';
import config from 'resources/config';
import { dumpDiagnosisListItems } from 'utils/dumpData';
import { getTodayLimit } from 'utils/misc';

const RootDiv = styled('div')({
  width: '100%',
  height: '100%',
  padding: 0,
  background: colors.subDrawer.background,
});

const IconDiv = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignContent: 'center',
});

dayjs.extend(utc);

export interface DiagnosisListProps {
  height: number;
}

export function DiagnosisList(props: DiagnosisListProps): JSX.Element {
  const { height } = props;
  const intl = useIntl();
  const dispatch = useDispatch();
  const diagnosisListItems = useDiagnosisListItems();
  const selectedDiagnosis = useDiagnosisSelectedDiagnosis();
  const currentDiagnosis = useDiagnosisCurrentDiagnosis();
  const listFetching = useDiagnosisListFetching();
  const dataFetching = useDiagnosisDataFetching();
  const treeListRef = useRef<TreeList<DiagnosisListItem, unknown>>(null);
  const timezoneOffset = new Date().getTimezoneOffset();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const startDateIconCellRender = (rowData: any) => {
    const diagnosisListItem: DiagnosisListItem = rowData.data;
    if (diagnosisListItem != null) {
      if (diagnosisListItem.maintenanceAlert) {
        return (
          <Tooltip title={intl.formatMessage({ id: msgId.warningMessagePartialMaintenance })}>
            <IconDiv>
              <MaintenanceErrorIcon sx={{ width: 18, height: 18 }} />
            </IconDiv>
          </Tooltip>
        );
      } else if (diagnosisListItem.inMaintenance) {
        // for debug
        return (
          <Tooltip title={'メンテナンス期間内'}>
            <IconDiv>
              <CautionIcon sx={{ width: 18, height: 18 }} />
            </IconDiv>
          </Tooltip>
        );
      } else {
        return <IconDiv />;
      }
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const startDateCalculateDisplayValue = (rowData: any) => {
    const diagnosisListItem: DiagnosisListItem = rowData;
    const date = dayjs(diagnosisListItem.startDate)
      .utc()
      .format(constants.dateFormat.YYYYMMDDHHmmssSSS);
    const tmp = date.substring(0, date.length - 2);

    return tmp;
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const startDateCalculateCellValue = (rowData: any) => {
    const diagnosisListItem: DiagnosisListItem = rowData;
    const date = dayjs(diagnosisListItem.startDate).add(timezoneOffset, 'minute').valueOf();

    return date;
  };

  const startDateCalculateFilterExpression = (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    value: any,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    comparisonOperator: any,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unused-vars
    target: any
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ): any[] => {
    if (comparisonOperator === 'between' && Array.isArray(value)) {
      const start = dayjs(value[0]).valueOf();
      const end = dayjs(value[1]).valueOf();
      const filterExpression = [
        [startDateCalculateCellValue, '>=', start],
        'and',
        [startDateCalculateCellValue, '<=', end],
      ];

      return filterExpression;
    } else {
      const date = dayjs(value).valueOf();

      return [startDateCalculateCellValue, comparisonOperator, date];
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const alertStatusCalculateCellValue = (rowData: any) => {
    const diagnosisListItem: DiagnosisListItem = rowData;

    return intl.formatMessage({ id: 'diagnosis_status_' + diagnosisListItem.status });
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const sensorNameCalculateCellValue = (rowData: any) => {
    const diagnosisListItem: DiagnosisListItem = rowData;

    return intl.formatMessage({ id: diagnosisListItem.sensorName });
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const countCalculateDisplayValue = (rowData: any) => {
    const diagnosisListItem: DiagnosisListItem = rowData;
    if (diagnosisListItem.listParentId === 0) {
      return diagnosisListItem.count;
    } else {
      return undefined;
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const countCalculateCellValue = (rowData: any) => {
    const diagnosisListItem: DiagnosisListItem = rowData;

    return diagnosisListItem.count;
  };

  const countCalculateFilterExpression = (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    value: any,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    comparisonOperator: any,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unused-vars
    target: any
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ): any[] => {
    if (comparisonOperator === 'between' && Array.isArray(value)) {
      const start = value[0];
      const end = value[1];
      const filterExpression = [
        [countCalculateCellValue, '>=', start],
        'and',
        [countCalculateCellValue, '<=', end],
      ];

      return filterExpression;
    } else {
      return [countCalculateCellValue, comparisonOperator, value];
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const statusIconCellRender = (rowData: any) => {
    const diagnosisListItem: DiagnosisListItem = rowData.data;

    return (
      <IconDiv>
        {diagnosisListItem?.status === 'Caution' && <CautionIcon sx={{ width: 18, height: 18 }} />}
        {diagnosisListItem?.status === 'Warning' && <WarningIcon sx={{ width: 18, height: 18 }} />}
        {diagnosisListItem?.status === 'Fault' && <FaultIcon sx={{ width: 18, height: 18 }} />}
      </IconDiv>
    );
  };

  const minDate = dayjs(config.minDate).toDate();
  const maxDate = dayjs(getTodayLimit()).toDate();
  const columns: DxDataGridColumn[] = [
    {
      key: 'startDate',
      dataField: 'startDate',
      caption: intl.formatMessage({ id: msgId.alertStartDate }),
      width: 190,
      minWidth: dimens.dataGrid.column.minWidth,
      dataType: 'datetime',
      filterOperations: constants.dataGrid.dateFilterOperations,
      defaultSortOrder: 'desc',
      calculateDisplayValue: startDateCalculateDisplayValue,
      calculateCellValue: startDateCalculateCellValue,
      calculateFilterExpression: startDateCalculateFilterExpression,
      editorOptions: { min: minDate, max: maxDate },
    },
    {
      key: 'startDateIcon',
      dataField: 'startDate',
      caption: '',
      width: 28,
      minWidth: 28,
      cellRender: startDateIconCellRender,
      allowFiltering: false,
    },
    {
      key: 'shipName',
      dataField: 'shipName',
      caption: intl.formatMessage({ id: msgId.shipName }),
      width: 120,
      minWidth: dimens.dataGrid.column.minWidth,
    },
    {
      key: 'machineName',
      dataField: 'machineName',
      caption: intl.formatMessage({ id: msgId.machineName }),
      width: 100,
      minWidth: dimens.dataGrid.column.minWidth,
    },
    {
      key: 'statusIcon',
      dataField: 'status',
      caption: '',
      width: 28,
      minWidth: 28,
      cellRender: statusIconCellRender,
      allowFiltering: false,
    },
    {
      key: 'status',
      dataField: 'status',
      caption: intl.formatMessage({ id: msgId.alertStatus }),
      width: 60,
      minWidth: dimens.dataGrid.column.minWidth,
      calculateCellValue: alertStatusCalculateCellValue,
    },
    {
      key: 'sensorName',
      dataField: 'sensorName',
      minWidth: dimens.dataGrid.column.minWidth,
      caption: intl.formatMessage({ id: msgId.alertSensorName }),
      calculateCellValue: sensorNameCalculateCellValue,
    },
    {
      key: 'count',
      dataField: 'count',
      caption: intl.formatMessage({ id: msgId.alertCount }),
      width: 60,
      minWidth: dimens.dataGrid.column.minWidth,
      calculateDisplayValue: countCalculateDisplayValue,
      calculateCellValue: countCalculateCellValue,
      calculateFilterExpression: countCalculateFilterExpression,
    },
  ];

  /**
   * 行選択
   * @param diagnosisListItem 診断結果
   */
  const handleSelectionChanged = (diagnosisListItem: DiagnosisListItem | undefined) => {
    if (diagnosisListItem != null) {
      if (diagnosisListItem.diagnosisId === 0 && treeListRef.current) {
        const treeList = treeListRef.current.instance;
        treeList.clearSelection();
        if (treeList.isRowExpanded(diagnosisListItem.listId)) {
          treeList.collapseRow(diagnosisListItem.listId);
        } else {
          treeList.expandRow(diagnosisListItem.listId);
        }
        if (selectedDiagnosis != null) {
          treeList.selectRows([selectedDiagnosis.listId], true);
        }
      } else {
        dispatch(setSelectedDiagnosis(diagnosisListItem));
      }
    }
  };

  const handleRowPrepared = (e: RowPreparedEvent<DiagnosisListItem, unknown>) => {
    if (e.rowType === 'data') {
      if (e.data.diagnosisId === 0) {
        e.rowElement.style.background = colors.listItem.subHeader;
      }
    }
  };

  /**
   * フィルターリセット
   */
  const handleFilterResetClick = () => {
    if (treeListRef.current) {
      const treeList = treeListRef.current.instance;
      columns.forEach((c) => {
        treeList.columnOption(c.dataField, 'selectedFilterOperation', null);
      });
      treeList.clearFilter();
    }
  };

  // 日付入力のフィルターをreadonlyにする。
  const setInputReadOnly = () => {
    const element = document.getElementById('diagnosisListItems');
    if (element) {
      const dateFilterInput = element.querySelector(
        '.dx-treelist-filter-row td[aria-colindex="1"]'
      ) as HTMLElement;
      if (dateFilterInput) {
        const inputElement = dateFilterInput.querySelector(
          '.dx-texteditor-input'
        ) as HTMLInputElement;
        if (inputElement) {
          inputElement.readOnly = true;
        }
      }
    }
  };

  useEffect(() => {
    if (diagnosisListItems == null) {
      dispatch(fetchDiagnosisListItems());
    } else {
      if (config.valueInspectionLog) {
        dumpDiagnosisListItems(diagnosisListItems);
      }
    }
  }, [dispatch, diagnosisListItems]);

  useEffect(() => {
    if (diagnosisListItems != null) {
      if (selectedDiagnosis != null) {
        const item = diagnosisListItems.find(
          (x) => x.diagnosisId === selectedDiagnosis.diagnosisId
        );
        if (item != null) {
          // リストから表示中の警報が存在し、
          // 選択中の警報アイテムのリストIDが異なる場合再設定
          if (selectedDiagnosis.listId !== item.listId) {
            if (treeListRef.current) {
              const treeList = treeListRef.current.instance;
              treeList.clearSelection();
              treeList.selectRows([item.listId], true);
            }
          }
        }
      }
      if (currentDiagnosis != null) {
        const item = diagnosisListItems.find((x) => x.diagnosisId === currentDiagnosis.diagnosisId);
        if (item == null) {
          // リストから表示中の警報が無くなった場合
          dispatch(setSensorDataList({ diagnosisListItem: undefined, sensorDataList: undefined }));
          dispatch(setSelectedDiagnosis(undefined));
          if (treeListRef.current) {
            const treeList = treeListRef.current.instance;
            treeList.clearSelection();
          }
        }
      }
    }
  }, [dispatch, diagnosisListItems, selectedDiagnosis, currentDiagnosis]);

  return (
    <RootDiv>
      {diagnosisListItems && (
        <React.Fragment>
          <ContentsHeader title={intl.formatMessage({ id: msgId.alertTitle })}>
            <NormalButton
              label={intl.formatMessage({ id: msgId.filterReset })}
              startIcon={<SearchIcon />}
              onClick={() => handleFilterResetClick()}
            />
          </ContentsHeader>
          <TreeList
            style={{ margin: dimens.treeList.margin }}
            ref={treeListRef}
            height={height - dimens.treeList.offsetHeight}
            id="diagnosisListItems"
            dataSource={diagnosisListItems}
            columnAutoWidth={false}
            showRowLines={true}
            showBorders={true}
            hoverStateEnabled={true}
            allowColumnResizing={true}
            columnResizingMode="nextColumn"
            keyExpr="listId"
            parentIdExpr="listParentId"
            noDataText={intl.formatMessage({
              id: diagnosisListItems.length > 0 ? msgId.noResults : msgId.emptyDiagnosisList,
            })}
            onSelectionChanged={(e) => handleSelectionChanged(e.selectedRowsData[0])}
            onRowPrepared={handleRowPrepared}
            onCellClick={setInputReadOnly}
          >
            <FilterRow visible={true} />
            <Scrolling mode="virtual" />
            <Selection mode="single" />
            {columns.map((column) => {
              return (
                <Column
                  key={column.key}
                  name={column.key}
                  dataField={column.dataField}
                  minWidth={column.minWidth}
                  dataType={column.dataType}
                  format={column.format}
                  caption={column.caption}
                  width={column.width}
                  filterOperations={column.filterOperations}
                  calculateDisplayValue={column.calculateDisplayValue}
                  calculateCellValue={column.calculateCellValue}
                  calculateFilterExpression={column.calculateFilterExpression}
                  cellRender={column.cellRender}
                  defaultSortOrder={column.defaultSortOrder}
                  editorOptions={column.editorOptions}
                  allowFiltering={column.allowFiltering}
                  allowResizing={column.allowResizing}
                />
              );
            })}
          </TreeList>
        </React.Fragment>
      )}
      <FadeLoading loading={listFetching || dataFetching} />
    </RootDiv>
  );
}
