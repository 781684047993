import React, { useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import colors from 'resources/colors';
import { Machine, SensorGroup, Ship } from 'models/ships';
import msgId from 'resources/intl';
import { ChartSeries } from 'models/chart';
import { CustomScrollBars } from 'components/CustomScrollBars';
import { ChartButton } from './ChartButton';
import { SensorData } from 'models/data';
import { theme } from 'resources/theme';
import { Box, Grid, IconButton, styled, Typography } from '@mui/material';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { Cancel, CheckCircle, DeleteForever, ExpandMore, Remove } from '@mui/icons-material';
import dimens from 'resources/dimens';
import { ChartSensorPopup } from './ChartSensorPopup';
import { CautionIcon, FaultIcon, WarningIcon } from 'components/shipDrawer/ShipItem';
import { roundValueToString } from 'utils/misc';
import { NumberTextField } from 'components/NumberTextField';

const iconSizeProps = { width: 18, height: 18 };

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))({
  padding: 0,
  border: '0px solid rgba(0, 0, 0, .125)',
  boxShadow: 'none',
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
  '&$expanded': {
    margin: 'auto',
  },
  expanded: {},
});

export const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary {...props} />
))({
  padding: 0,
  color: theme.palette.secondary.main,
  backgroundColor: colors.chart.legend.background,
  marginBottom: 0,
  minHeight: 32,
  '&$expanded': {
    minHeight: 32,
    padding: 0,
  },
  borderTop: `1px solid ${colors.chart.legend.border}`,
  '& .MuiAccordionSummary-content': {
    margin: '0px 0',
    '&$expanded': {
      margin: '0px 0',
    },
    overflow: 'hidden',
  },
  expanded: {
    margin: '0px 0',
  },
});

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(0),
  margin: theme.spacing(0),
  alignItems: 'center',
  color: theme.palette.background.default,
  background: colors.chart.legend.background,
}));

const RootDiv = styled('div')({
  width: dimens.chart.legend.width,
  backgroundColor: colors.chart.header.background,
  color: colors.chart.text,
});

const ButtonContainerGrid = styled(Grid)({
  height: 32,
  padding: `4px 8px`,
});

const ValueTextTypography = styled(Typography)({
  minWidth: 90,
});

const SectionDiv = styled('div')({
  color: theme.palette.secondary.main,
  background: colors.chart.header.background,
  padding: `4px 8px`,
});

const ShipValueDiv = styled('div')({
  padding: `4px 8px`,
});

const StyledNumberTextField = styled(NumberTextField)({
  margin: 0,
  width: 74,
  minWidth: 74,
  '& input': {
    backgroundColor: 'white',
    textAlign: 'center',
    marginRight: 4,
    marginLeft: 4,
    fontSize: '12px',
    padding: 4,
  },
});

const SummaryDiv = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  width: '100%',
  height: 20,
});
const RangeDiv = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  width: '100%',
  padding: `4px 8px`,
});

interface ChartLegendPanelProps {
  id?: string;
  chartId: string;
  baseShip: Ship;
  compareShip?: Ship;
  height: number | string;
  chartSeriesList: ChartSeries[];
  chartSeriesChanged: boolean;
  canExpand: boolean;
  canRangeInput: boolean;
  width?: number;
  onReset?: (chartId: string) => void;
  onApply?: (chartId: string, ship: Ship, machine: Machine, chartSeriesList: ChartSeries[]) => void;
  onAdd?: (chartId: string, sensorGroup: SensorGroup) => void;
  onRemove?: (chartId: string, chartSeries: ChartSeries) => void;
  onChangeRange: (chartId: string, chartSeries: ChartSeries, min: number, max: number) => void;
  onExpandChanged?: (chartId: string, sensorGroupName: string, expanded: boolean) => void;
}

export function ChartLegendPanel(props: ChartLegendPanelProps): JSX.Element {
  const {
    id,
    chartId,
    baseShip,
    compareShip,
    height,
    chartSeriesList,
    chartSeriesChanged,
    canExpand,
    canRangeInput,
    width,
    onReset,
    onApply,
    onAdd,
    onRemove,
    onChangeRange,
    onExpandChanged,
  } = props;
  const intl = useIntl();
  const [expands, setExpands] = useState<{ [key: string]: boolean }>({});
  const anchorRef = useRef<HTMLButtonElement>(null);

  const handleChange = (index: string) => {
    let bool;
    if (expands[index] === undefined) {
      bool = true;
    } else {
      bool = !expands[index];
    }
    setExpands({ ...expands, [index]: bool });
    onExpandChanged && onExpandChanged(chartId, index, bool);
  };

  /**
   * 上限変更
   * @param event イベント
   */
  const handleUpperLimitChange = (value: number | string, chartSeries: ChartSeries) => {
    const max = Number(value);
    if (max != null) {
      onChangeRange(chartId, chartSeries, chartSeries.range.min, max);
    }
  };

  /**
   * 下限変更
   * @param event イベント
   */
  const handleLowerLimitChange = (value: number | string, chartSeries: ChartSeries) => {
    const min = Number(value);
    if (min != null) {
      onChangeRange(chartId, chartSeries, min, chartSeries.range.max);
    }
  };

  /**
   * チャート凡例削除
   * @param event イベント
   */
  function handleRemove(event: React.SyntheticEvent, chartSeries: ChartSeries) {
    event.stopPropagation();
    onRemove && onRemove(chartId, chartSeries);
    setExpands({ ...expands, [chartSeries.sensorGroup.sensorGroupName]: false });
    onExpandChanged && onExpandChanged(chartId, chartSeries.sensorGroup.sensorGroupName, false);
  }

  /**
   * ポップアップメニュークリック
   * @param event イベント
   * @param sensorGroup センサーグループ
   */
  const handleMenuClick = (event: React.MouseEvent<EventTarget>, sensorGroup: SensorGroup) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    onAdd && onAdd(chartId, sensorGroup);
  };

  /**
   * 値からセンサーの表示値を取得
   * @param value 値
   * @param sensorData センサーデータ
   */
  const getValueText = (value: number, sensorData: SensorData) => {
    let text = roundValueToString(value, sensorData.sensor.displayRoundingPosition);
    if (sensorData.sensor.displayUnit != null) {
      text += sensorData.sensor.displayUnit;
    }

    return text;
  };

  return (
    <RootDiv id={id} sx={{ height: height, width: width }}>
      {/* ヘッダーボタンコンテナ */}
      <ButtonContainerGrid
        container
        direction="row"
        justifyContent="space-between"
        alignContent="center"
      >
        {onReset && (
          <ChartButton
            variant="contained"
            startIcon={
              <Cancel
                htmlColor={
                  chartSeriesChanged ? theme.palette.primary.main : theme.palette.text.disabled
                }
              />
            }
            disabled={!chartSeriesChanged}
            onClick={() => onReset(chartId)}
            label={intl.formatMessage({ id: msgId.cancel })}
          />
        )}
        {onApply && (
          <ChartButton
            variant="contained"
            startIcon={<CheckCircle />}
            disabled={!chartSeriesChanged}
            apply={chartSeriesList.length >= 0 && chartSeriesChanged}
            onClick={() => onApply(chartId, baseShip, baseShip.machines[0], chartSeriesList)}
            label={intl.formatMessage({ id: msgId.trendButtonUpdate })}
          />
        )}
      </ButtonContainerGrid>
      <CustomScrollBars thumbColor={colors.scrollBar.thumb.dark} height={height} trackWidth={12}>
        {/* 船舶リスト */}
        <Box sx={{ padding: `4px 8px` }}>
          <Typography variant="body2" noWrap>
            {baseShip.name} - {baseShip.machines[0].name}
          </Typography>
          {compareShip != null && (
            <Typography variant="body2" noWrap>
              {compareShip.name} - {compareShip.machines[0].name}
            </Typography>
          )}
        </Box>
        {chartSeriesList.map((series, index) => {
          return (
            <Accordion
              key={series.sensorGroup.sensorGroupName + index}
              square
              expanded={
                canExpand
                  ? expands[series.sensorGroup.sensorGroupName] != null &&
                  expands[series.sensorGroup.sensorGroupName]
                  : false
              }
              onChange={() => handleChange(series.sensorGroup.sensorGroupName)}
            >
              {/* サマリー */}
              <AccordionSummary
                aria-controls={series.sensorGroup.sensorGroupName + index}
                id={series.sensorGroup.sensorGroupName + index}
              >
                <SummaryDiv>
                  {canExpand && (
                    <IconButton
                      size="small"
                      sx={{
                        padding: 0,
                        marginRight: '0px',
                        marginLeft: '0px',
                        transform: expands[series.sensorGroup.sensorGroupName]
                          ? 'rotate(180deg)'
                          : 'rotate(0deg)',
                        transition: theme.transitions.create('transform', {
                          duration: theme.transitions.duration.shortest,
                        }),
                      }}
                      focusRipple={false}
                      disableRipple
                      disabled={true}
                    >
                      <ExpandMore color="secondary" />
                    </IconButton>
                  )}
                  <Remove
                    sx={{
                      width: 18,
                      height: 18,
                      marginRight: theme.spacing(0.5),
                      marginLeft: canExpand ? 0 : theme.spacing(0.5),
                    }}
                    htmlColor={series.color}
                  />
                  <Typography variant="body2" noWrap>
                    {(
                      intl.formatMessage({ id: series.sensorGroup.section }) +
                      ' ' +
                      intl.formatMessage({ id: series.sensorGroup.sensorGroupName })
                    ).trim()}
                  </Typography>
                  <Box sx={{ flexGrow: 1 }} />
                  {series.portSensorData?.diagnosisStatus != null && (
                    <IconButton
                      size="small"
                      disabled
                      sx={{ paddingLeft: 0, paddingRight: theme.spacing(0.5) }}
                    >
                      {series.portSensorData.diagnosisStatus === 'Caution' && (
                        <CautionIcon sx={iconSizeProps} />
                      )}
                      {series.portSensorData.diagnosisStatus === 'Warning' && (
                        <WarningIcon sx={iconSizeProps} />
                      )}
                      {series.portSensorData.diagnosisStatus === 'Fault' && (
                        <FaultIcon sx={iconSizeProps} />
                      )}
                    </IconButton>
                  )}
                  {series.starboardSensorData?.diagnosisStatus != null &&
                    series.starboardSensorData?.sensor.position === 'Starboard' && (
                      <IconButton
                        size="small"
                        disabled
                        sx={{ paddingLeft: 0, paddingRight: theme.spacing(0.5) }}
                      >
                        {series.starboardSensorData.diagnosisStatus === 'Caution' && (
                          <CautionIcon sx={iconSizeProps} />
                        )}
                        {series.starboardSensorData.diagnosisStatus === 'Warning' && (
                          <WarningIcon sx={iconSizeProps} />
                        )}
                        {series.starboardSensorData.diagnosisStatus === 'Fault' && (
                          <FaultIcon sx={iconSizeProps} />
                        )}
                      </IconButton>
                    )}
                  {onRemove && (
                    <IconButton
                      size="small"
                      onFocus={(event) => event.stopPropagation()}
                      onClick={(event) => {
                        handleRemove(event, series);
                      }}
                    >
                      <DeleteForever
                        sx={{ marginRight: theme.spacing(0.5), ...iconSizeProps }}
                        htmlColor={colors.chart.header.color}
                      />
                    </IconButton>
                  )}
                </SummaryDiv>
              </AccordionSummary>
              {/* 詳細 */}
              <AccordionDetails>
                <Box sx={{ width: '100%' }}>
                  {canRangeInput && (
                    <RangeDiv>
                      <Typography variant="body2" noWrap>
                        {intl.formatMessage({ id: msgId.trendYaxisRange })}
                      </Typography>
                      <StyledNumberTextField
                        id="outlined-number-min"
                        variant="outlined"
                        value={series.range.min}
                        maxLength={10}
                        onChange={(value) => handleLowerLimitChange(value, series)}
                        onBlur={(value) => handleLowerLimitChange(value, series)}
                      />
                      <Typography variant="h6">-</Typography>
                      <StyledNumberTextField
                        id="outlined-number-max"
                        variant="outlined"
                        value={series.range.max}
                        maxLength={10}
                        onChange={(value) => handleUpperLimitChange(value, series)}
                        onBlur={(value) => handleUpperLimitChange(value, series)}
                      />
                      <Typography variant="body2" noWrap>
                        {series.sensorGroup.displayUnit}
                      </Typography>
                    </RangeDiv>
                  )}
                  <SectionDiv>
                    <Typography variant="body2" noWrap>
                      {intl.formatMessage({ id: msgId.trendAggregateMax })}
                    </Typography>
                  </SectionDiv>
                  <ShipValueDiv>
                    <Typography variant="body2" noWrap>
                      {baseShip.name + ' - ' + baseShip.machines[0].name}:
                    </Typography>
                    <Grid container direction="column" wrap="nowrap">
                      {series.portSensorData && (
                        <Grid
                          container
                          item
                          direction="row"
                          wrap="nowrap"
                          justifyContent="flex-end"
                          spacing={1}
                        >
                          <Grid item>
                            <Typography variant="body2" noWrap>
                              {intl.formatMessage({ id: msgId.trendLabelPort })}
                            </Typography>
                          </Grid>
                          <Grid item>
                            <ValueTextTypography variant="body2" noWrap align="right">
                              {getValueText(series.portSensorData.max, series.portSensorData)}
                            </ValueTextTypography>
                          </Grid>
                        </Grid>
                      )}
                      {series.starboardSensorData && (
                        <Grid
                          container
                          item
                          direction="row"
                          wrap="nowrap"
                          justifyContent="flex-end"
                          spacing={1}
                        >
                          <Grid item>
                            <Typography variant="body2" noWrap>
                              {intl.formatMessage({ id: msgId.trendLabelStbd })}
                            </Typography>
                          </Grid>
                          <Grid item>
                            <ValueTextTypography variant="body2" noWrap align="right">
                              {getValueText(
                                series.starboardSensorData.max,
                                series.starboardSensorData
                              )}
                            </ValueTextTypography>
                          </Grid>
                        </Grid>
                      )}
                    </Grid>
                  </ShipValueDiv>
                  <SectionDiv>
                    <Typography variant="body2" noWrap>
                      {intl.formatMessage({ id: msgId.trendAggregateMin })}
                    </Typography>
                  </SectionDiv>
                  <ShipValueDiv>
                    <Typography variant="body2" noWrap>
                      {baseShip.name + ' - ' + baseShip.machines[0].name}:
                    </Typography>
                    <Grid container direction="column" wrap="nowrap">
                      {series.portSensorData && (
                        <Grid
                          container
                          item
                          direction="row"
                          wrap="nowrap"
                          justifyContent="flex-end"
                          spacing={1}
                        >
                          <Grid item>
                            <Typography variant="body2" noWrap>
                              {intl.formatMessage({ id: msgId.trendLabelPort })}
                            </Typography>
                          </Grid>
                          <Grid item>
                            <ValueTextTypography variant="body2" noWrap align="right">
                              {getValueText(series.portSensorData.min, series.portSensorData)}
                            </ValueTextTypography>
                          </Grid>
                        </Grid>
                      )}
                      {series.starboardSensorData && (
                        <Grid
                          container
                          item
                          direction="row"
                          wrap="nowrap"
                          justifyContent="flex-end"
                          spacing={1}
                        >
                          <Grid item>
                            <Typography variant="body2" noWrap>
                              {intl.formatMessage({ id: msgId.trendLabelStbd })}
                            </Typography>
                          </Grid>
                          <Grid item>
                            <ValueTextTypography variant="body2" noWrap align="right">
                              {getValueText(
                                series.starboardSensorData.min,
                                series.starboardSensorData
                              )}
                            </ValueTextTypography>
                          </Grid>
                        </Grid>
                      )}
                    </Grid>
                  </ShipValueDiv>
                  {compareShip != null && (
                    <Typography variant="body2" noWrap>
                      {compareShip.name + ' - ' + compareShip.machines[0].name}:
                    </Typography>
                  )}
                </Box>
              </AccordionDetails>
            </Accordion>
          );
        })}
        {/* 追加ボタン */}
        {onAdd && (
          <ChartSensorPopup
            baseShip={baseShip}
            chartSeriesList={chartSeriesList}
            onClick={handleMenuClick}
          />
        )}
      </CustomScrollBars>
    </RootDiv>
  );
}

ChartLegendPanel.defaultProps = {
  canExpand: true,
  canRangeInput: true,
};
