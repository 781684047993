import React from 'react';
import { Button, styled, SxProps, Typography } from '@mui/material';
import { theme } from 'resources/theme';
import dimens from 'resources/dimens';
import { Cancel } from '@mui/icons-material';
import colors from 'resources/colors';

const StyledButton = styled(Button)({
  height: dimens.button.clearHeight,
  maxHeight: dimens.button.clearHeight,
  borderRadius: dimens.button.borderRadius,
  color: colors.button.clear.color,
  background: colors.button.clear.background,
  '&:hover': {
    background: colors.button.clear.hover,
  },
});

interface ClearButtonProps {
  label: string;
  sx?: SxProps;
  disabled?: boolean;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

export function ClearButton(props: ClearButtonProps): JSX.Element {
  const { label, sx, disabled, onClick } = props;

  return (
    <StyledButton
      variant="contained"
      disableElevation
      sx={{ marginLeft: theme.spacing(0.5), ...sx }}
      startIcon={<Cancel htmlColor={colors.border} />}
      disabled={disabled}
      onClick={onClick}
    >
      <Typography variant="body1" noWrap>
        {label}
      </Typography>
    </StyledButton>
  );
}
