import React from 'react';
import { useIntl } from 'react-intl';
import msgId from 'resources/intl';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';

export type DialogResult = 'ok' | 'cancel' | 'abort';

interface AlertDialogProps {
  title: string;
  message: string;
  positiveButton?: string;
  negativeButton?: string;
  onClose: (result: DialogResult) => void;
}

export function AlertDialog(props: AlertDialogProps): JSX.Element {
  const { title, message, positiveButton, negativeButton, onClose } = props;
  const intl = useIntl();
  const positiveButtonText = positiveButton || intl.formatMessage({ id: msgId.ok });

  const handleClose = (
    reason: 'backdropClick' | 'escapeKeyDown' | 'userClose',
    result: boolean
  ) => {
    if (reason === 'backdropClick') {
      return false;
    }

    if (reason === 'escapeKeyDown') {
      return false;
    }

    onClose(result ? 'ok' : 'cancel');
  };

  return (
    <Dialog
      open={true}
      onClose={(e, reason) => handleClose(reason, false)}
      disableEscapeKeyDown
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">{message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        {negativeButton != null && (
          <Button onClick={() => handleClose('userClose', false)} color="primary">
            {negativeButton}
          </Button>
        )}
        <Button onClick={() => handleClose('userClose', true)} color="primary">
          {positiveButtonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
