import React, { Fragment, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { styled, Button, ButtonGroup, Divider, Typography, Box } from '@mui/material';
import AppBar, { AppBarProps } from '@mui/material/AppBar';
import Toolbar, { ToolbarProps } from '@mui/material/Toolbar';
import { setOpen as setAccountInformationOpen } from '../main/accountInformation/accountInformationSlice';
import {
  setMapMode,
  setReorderShips,
  setDisplayShipIds,
  setConfirmedReorderShips,
  useAppBarMapMode,
  useAppBarReorderShips,
  useAppBarDisplayShipIds,
  useMarineWeatherType,
  setMarineWeatherType,
} from './mainAppBarSlice';
import colors from 'resources/colors';
import dimens from 'resources/dimens';
import msgId from 'resources/intl';
import AppLogger from 'utils/AppLogger';
import { DialogResult } from 'components/AlertDialog';
import { Ship } from 'models/ships';
import { ShipReorderPopup } from './ShipReorderButton';
// import { setCurrentThinnedConditions } from 'features/contents/shipState/pastData/pastDataSlice';
import { useCommonShips } from 'app/commonSlice';
import { theme } from 'resources/theme';
import { ToolbarBackButton } from 'components/ToolbarBackButton';
import { Lock } from '@mui/icons-material';
import { AccountButton } from './AccountButton';
import { Account } from 'models/accounts';
import { allClearAndSignOut } from 'utils/common';
import { useNavigate } from 'react-router-dom';
import {
  setDisplaySettingsShipIds,
  setDisplaySettingsMarineWeatherType,
  useDisplaySettingsDisplayShipIds,
  useDisplaySettingsOrderedShipIds,
  useDisplaySettingsDisplayMarineWeatherType,
} from 'app/displaySettingsSlice';
import { MarineWeatherSettingButton } from './MarineWeatherSettingButton';
import { MarineWeatherType } from 'models/marineWeather';

const StyledAppBar = styled((props: AppBarProps) => <AppBar position="fixed" {...props} />)({
  zIndex: theme.zIndex.drawer + 1,
  background: colors.appBar.background,
});

const StyledToolbar = styled((props: ToolbarProps) => (
  <Toolbar variant="dense" disableGutters {...props} />
))({});

const NormalMapButton = styled(Button)({
  color: theme.palette.primary.main,
  backgroundColor: theme.palette.background.default,
  '&:hover': {
    color: theme.palette.background.default,
    background: 'rgba(5, 158, 179, 0.55)',
  },
  minWidth: 160,
  width: 160,
  '&.MuiButton-outlinedPrimary': {
    border: '1px solid rgba(5, 158, 179, 1)',
  },
  '&.Mui-disabled': {
    color: theme.palette.text.disabled,
  },
});

const SelectedMapButton = styled(Button)({
  color: theme.palette.background.default,
  background: 'rgba(5, 158, 179, 1.0)',
  minWidth: 160,
  width: 160,
  '&.MuiButton-outlinedPrimary': {
    border: '1px solid rgba(5, 158, 179, 1)',
  },
  '&.Mui-disabled': {
    color: theme.palette.text.disabled,
  },
});

const LockIcon = styled(Lock)({
  width: 18,
  height: 18,
  marginBottom: '2px',
  marginLeft: '4px',
});

interface MainAppBarProps {
  title: string;
  mainDrawerOpen?: boolean;
  showSensorDataMenu: boolean;
  evelation: number;
  account?: Account;
  onBackClick: () => void;
}

export function MainAppBar(props: MainAppBarProps): JSX.Element {
  AppLogger.debug('CALL: MainAppBar');
  const { title, mainDrawerOpen, showSensorDataMenu, evelation, account, onBackClick } = props;
  const dispatch = useDispatch();
  const intl = useIntl();
  const navigate = useNavigate();
  const ships = useCommonShips();
  const savedOrderedShipIds = useDisplaySettingsOrderedShipIds();
  const savedDisplayShipIds = useDisplaySettingsDisplayShipIds();
  const savedMarineWeatherType = useDisplaySettingsDisplayMarineWeatherType();
  const mapMode = useAppBarMapMode();
  const reorderShips = useAppBarReorderShips(); // 表示順設定済み船舶リスト
  const displayShipIds = useAppBarDisplayShipIds(); // 表示する船舶IDリスト
  const marineWeatherType = useMarineWeatherType();

  /**
   * 船舶表示設定ダイアログの終了
   */
  const handleShipReorderClose = (
    result: DialogResult,
    reorderShips: Ship[] | undefined,
    checkedShipIds: string[] | undefined
  ) => {
    if (result === 'ok') {
      if (reorderShips && checkedShipIds && account != null) {
        dispatch(
          setDisplaySettingsShipIds({
            accountId: account.accountId,
            orderedShipIds: reorderShips.map((x) => x.shipId),
            displayShipIds: checkedShipIds,
          })
        );
      }
    }
  };

  /**
   * 気象海象情報表示設定ダイアログの終了
   */
  const handleMarineWeatherClose = (
    result: DialogResult,
    marineWeatherType?: MarineWeatherType
  ) => {
    if (result === 'ok' && marineWeatherType != null && account != null) {
      dispatch(
        setDisplaySettingsMarineWeatherType({
          accountId: account.accountId,
          marineWeatherType: marineWeatherType,
        })
      );
    }
  };

  /**
   * アカウント情報の表示要求時に呼ばれる。
   * @param event イベント
   */
  const handleAccountInformation = () => {
    dispatch(setAccountInformationOpen(true));
  };

  /**
   * サインアウト要求時に呼ばれる。
   */
  const handleSignOut = () => {
    allClearAndSignOut(dispatch, account?.homePath, navigate);
  };

  // 船舶表示設定で設定する船を設定
  useEffect(() => {
    if (ships != null && account != null) {
      if (
        savedOrderedShipIds[account.accountId] != null &&
        savedDisplayShipIds[account.accountId] != null
      ) {
        const orderedShipIds = savedOrderedShipIds[account.accountId];
        const displayShipIds = savedDisplayShipIds[account.accountId];
        const shipIds = ships.map((x) => x.shipId);

        // 新しい船舶が追加されていた場合の対応
        const newShipIds = shipIds.filter((val) => !orderedShipIds.includes(val));
        let tmpOrderedShipIds = [...orderedShipIds];
        let tmpDisplayShipIds = [...displayShipIds];
        if (newShipIds.length > 0) {
          tmpOrderedShipIds = tmpOrderedShipIds.concat(newShipIds);
          tmpDisplayShipIds = tmpDisplayShipIds.concat(newShipIds);
        }

        const tmpOrderedShips: Ship[] = [];
        tmpOrderedShipIds.forEach((x) => {
          const ship = ships.find((y) => y.shipId === x);
          if (ship != null) {
            tmpOrderedShips.push(ship);
          }
        });

        const filterShips = tmpOrderedShips.filter((x) => tmpDisplayShipIds.includes(x.shipId));
        dispatch(setReorderShips(tmpOrderedShips));
        dispatch(setDisplayShipIds(tmpDisplayShipIds));
        dispatch(setConfirmedReorderShips(filterShips));
      } else {
        dispatch(setReorderShips(ships));
        dispatch(setDisplayShipIds(ships.map((x) => x.shipId)));
        dispatch(setConfirmedReorderShips(ships));
      }
    }
  }, [dispatch, ships, account, savedOrderedShipIds, savedDisplayShipIds]);

  useEffect(() => {
    if (account != null) {
      dispatch(
        setMarineWeatherType(
          savedMarineWeatherType != null && savedMarineWeatherType[account.accountId] != null
            ? savedMarineWeatherType[account.accountId]
            : 'none'
        )
      );
    }
  }, [dispatch, account, savedMarineWeatherType]);

  const buttons = [
    { title: intl.formatMessage({ id: msgId.mapModePreviousData }), locked: false },
    { title: intl.formatMessage({ id: msgId.mapModeCurrentLocation }), locked: false },
  ];

  return (
    <StyledAppBar
      elevation={evelation}
      sx={
        mainDrawerOpen === undefined
          ? { left: 0, width: '100%' }
          : mainDrawerOpen
          ? { left: dimens.drawer.width, width: `calc(100% - ${dimens.drawer.width}px)` }
          : { left: dimens.drawer.minWidth, width: `calc(100% - ${dimens.drawer.minWidth}px)` }
      }
    >
      <StyledToolbar>
        {onBackClick && <ToolbarBackButton onClick={() => onBackClick()} />}
        <Box sx={{ paddingLeft: theme.spacing(1), width: 200 }}>
          <Typography variant="h5" noWrap>
            {title}
          </Typography>
        </Box>
        <Box sx={{ margin: theme.spacing(2) }} />
        {showSensorDataMenu && (
          <React.Fragment>
            <ButtonGroup color="primary">
              {buttons.map((row, index) => {
                if (mapMode === index) {
                  return (
                    <SelectedMapButton
                      key={index}
                      disabled={index === 2}
                      onClick={() => {
                        dispatch(setMapMode(index));
                      }}
                    >
                      <Typography variant="h6" noWrap>
                        {row.title}
                      </Typography>
                      {row.locked && <LockIcon />}
                    </SelectedMapButton>
                  );
                } else {
                  return (
                    <NormalMapButton
                      key={index}
                      disabled={index === 2}
                      onClick={() => {
                        dispatch(setMapMode(index));
                      }}
                    >
                      <Typography variant="h6" noWrap>
                        {row.title}
                      </Typography>
                      {row.locked && <LockIcon />}
                    </NormalMapButton>
                  );
                }
              })}
            </ButtonGroup>
            {reorderShips && (
              <ShipReorderPopup
                sx={{ marginLeft: theme.spacing(4) }}
                ships={reorderShips}
                selectedShipIds={displayShipIds}
                onClose={handleShipReorderClose}
              />
            )}
            <MarineWeatherSettingButton
              sx={{ marginLeft: theme.spacing(2) }}
              selectedMarineWeatherType={marineWeatherType}
              onClose={handleMarineWeatherClose}
            />
          </React.Fragment>
        )}
        <Box sx={{ flexGrow: 1 }} />
        {account && (
          <Fragment>
            <Divider orientation="vertical" flexItem sx={{ margin: theme.spacing(1) }} />
            <AccountButton
              account={account}
              onClickSignOut={handleSignOut}
              onClickAccountInformation={handleAccountInformation}
            />
          </Fragment>
        )}
      </StyledToolbar>
    </StyledAppBar>
  );
}

MainAppBar.defaultProps = {
  evelation: 0,
  onBackClick: undefined,
};
