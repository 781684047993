import React from 'react';
import { Polyline } from '@react-google-maps/api';
import colors from 'resources/colors';
import { usePastDataShipWaveData } from '../pastDataSlice';
import { usePastDataMapReset } from './mapSlice';
import { ShipWaveData } from 'models/data';
import config from 'resources/config';

interface PathInfo {
  option: google.maps.PolylineOptions;
  path: google.maps.LatLngLiteral[];
}

interface AllShipWavePathProps {
  shipWaveData?: ShipWaveData;
  isThinned: boolean;
}

export function AllShipWavePath(props: AllShipWavePathProps): JSX.Element {
  const { shipWaveData, isThinned } = props;
  const reset = usePastDataMapReset();

  const pathInfoList: PathInfo[] = [];

  let vesLatC1 = null;
  let vesLonC1 = null;
  let vesSpdSOGC1 = null;
  if (
    !isThinned &&
    shipWaveData != null &&
    shipWaveData.sensors.vesLatC1 != null &&
    shipWaveData.sensors.vesLonC1 != null
  ) {
    vesLatC1 = shipWaveData.sensors.vesLatC1;
    vesLonC1 = shipWaveData.sensors.vesLonC1;
    vesSpdSOGC1 = shipWaveData.sensors.vesSpdSOGC1;
  } else if (
    !isThinned &&
    shipWaveData != null &&
    shipWaveData.sensors.vesLat != null &&
    shipWaveData.sensors.vesLon != null
  ) {
    vesLatC1 = shipWaveData.sensors.vesLat;
    vesLonC1 = shipWaveData.sensors.vesLon;
    vesSpdSOGC1 = shipWaveData.sensors.vesSog;
  }

  if (
    vesLatC1 != null &&
    vesLonC1 != null &&
    vesSpdSOGC1 != null
  ) {
    let array: google.maps.LatLngLiteral[] = [];
    let prevLat = NaN;
    let prevLon = NaN;
    let prevSpeed = NaN;
    for (let i = 0; i < vesLatC1.data.length; i++) {
      // 描画点が多いため、100個に1個だけ描画する
      // if (i % 100 !== 0) {
      //   continue;
      // }
      if (prevLat !== vesLatC1.data[i] || vesLonC1.data[i] !== prevLon) {
        if (isNaN(vesLatC1.data[i]) && isNaN(vesLonC1.data[i])) {
          // 一旦ここまでを追加してリセット
          if (array.length > 0) {
            pathInfoList.push({
              path: array,
              option: {
                strokeColor: colors.map.shipSpeed[prevSpeed],
                strokeOpacity: 0.5,
                strokeWeight: 5,
              },
            });
          }
          array = [];
          prevLat = NaN;
          prevLon = NaN;
          prevSpeed = NaN;
          continue;
        }
        if (!isNaN(vesLatC1.data[i]) && !isNaN(vesLonC1.data[i])) {
          const adbLat = Math.abs(vesLatC1.data[i]);
          const absLon = Math.abs(vesLonC1.data[i]);
          if (adbLat < 1 && absLon < 1) {
            continue;
          }
        }

        let speed = 10;
        if (vesSpdSOGC1 != null) {
          speed = Math.round(vesSpdSOGC1.data[i]);
          if (speed > 20) {
            speed = 20;
          } else if (speed < 0) {
            speed = 0;
          }
        }
        array.push({ lat: vesLatC1.data[i], lng: vesLonC1.data[i] });

        if (isNaN(prevSpeed)) {
          prevSpeed = speed;
        } else if (prevSpeed !== speed) {
          pathInfoList.push({
            path: array,
            option: {
              strokeColor: colors.map.shipSpeed[prevSpeed],
              strokeOpacity: 0.5,
              strokeWeight: 5,
            },
          });

          prevSpeed = speed;
          array = [];
          array.push({ lat: vesLatC1.data[i], lng: vesLonC1.data[i] });
        }

        prevLat = vesLatC1.data[i];
        prevLon = vesLonC1.data[i];
      }
    }

    if (array.length > 0) {
      pathInfoList.push({
        path: array,
        option: {
          strokeColor: colors.map.shipSpeed[prevSpeed],
          strokeOpacity: 0.2,
          strokeWeight: 3,
        },
      });
    }
  }

  if (!reset && pathInfoList.length > 0) {
    return (
      <React.Fragment>
        {pathInfoList.map((pathInfo, index) => (
          <Polyline key={index} path={pathInfo.path} options={pathInfo.option} />
        ))}
      </React.Fragment>
    );
  } else {
    return <React.Fragment />;
  }
}
