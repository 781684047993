import React, { useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';
import { useIntl } from 'react-intl';
import msgId from 'resources/intl';
import { theme } from 'resources/theme';
import dimens from 'resources/dimens';
import colors from 'resources/colors';
import {
  Box,
  Button,
  ClickAwayListener,
  Divider,
  FormControl,
  FormControlLabel,
  Grow,
  Link,
  Paper,
  Popper,
  Radio,
  RadioGroup,
  RadioProps,
  SxProps,
} from '@mui/material';
import { DialogResult } from 'components/AlertDialog';
import AppLogger from 'utils/AppLogger';
import { MarineWeatherType } from 'models/marineWeather';

const StyledPopper = styled(Popper)({
  zIndex: theme.zIndex.drawer + 101,
});

const StyledLink = styled(Link)({
  color: theme.palette.background.default,
  cursor: 'pointer',
  textDecoration: 'underline',
  whiteSpace: 'nowrap',
});

const Arrow = styled('span')({
  position: 'absolute',
  width: 0,
  height: 0,
  borderLeft: '1em solid transparent',
  borderRight: '1em solid transparent',
  borderBottom: '1em solid #FFF',
  marginTop: '-0.9em',
  '&:before': {
    borderWidth: '0 1em 1em 1em',
    borderColor: 'transparent transparent white transparent',
  },
});

const FooterButtonDiv = styled('div')({
  width: '100%',
  background: theme.palette.background.default,
  borderTop: `1px solid #E0E0E0`,
  borderRadius: '0px 0px 4px 4px',
});

const FooterButton = styled(Button)({
  width: '50%',
  textAlign: 'center',
  height: dimens.searchBar.height,
  borderRadius: 0,
});

const StyledRadio = styled((props: RadioProps) => (
  <Radio size="small" color="primary" {...props} />
))({
  marginLeft: theme.spacing(1),
});

interface MarineWeatherSettingButtonProps {
  sx?: SxProps;
  selectedMarineWeatherType: MarineWeatherType;
  onClose: (result: DialogResult, marineWeatherType?: MarineWeatherType) => void;
}

export function MarineWeatherSettingButton(props: MarineWeatherSettingButtonProps): JSX.Element {
  const { sx, selectedMarineWeatherType, onClose } = props;
  const intl = useIntl();
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLDivElement>(null);
  const [arrowRef, setArrowRef] = useState<HTMLSpanElement>();
  const paparRef = useRef<HTMLDivElement>(null);
  const prevOpen = useRef(open);
  const [selectedValue, setSelectedValue] = React.useState(selectedMarineWeatherType);

  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current?.focus();
    }
    prevOpen.current = open;
  }, [open]);

  const handleToggle = () => {
    const tmpOpen = prevOpen.current;
    setOpen((prevOpen) => !prevOpen);
    if (tmpOpen === true) {
      setSelectedValue(selectedMarineWeatherType);
      onClose('cancel', undefined);
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleClickAway = (event: any) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    const rects = paparRef.current?.getClientRects();
    if (rects && rects.length > 0) {
      if (
        rects[0].left > 0 &&
        rects[0].left <= event.clientX &&
        rects[0].left + rects[0].width >= event.clientX &&
        rects[0].top <= event.clientY &&
        rects[0].top + rects[0].height >= event.clientY
      ) {
        AppLogger.debug('in rect');
      } else {
        setOpen(false);
        setSelectedValue(selectedMarineWeatherType);
        onClose('cancel', undefined);
      }
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedValue((event.target as HTMLInputElement).value as MarineWeatherType);
  };

  const handleClose = (result: DialogResult) => {
    setOpen(false);
    if (result === 'ok') {
      setSelectedValue(selectedValue);
      onClose(result, selectedValue);
    } else {
      setSelectedValue(selectedMarineWeatherType);
      onClose(result, undefined);
    }
  };

  const handleArrowRef = (node: HTMLElement) => {
    setArrowRef(node);
  };

  return (
    <React.Fragment>
      <Box
        ref={anchorRef}
        sx={sx}
        aria-controls={open ? 'marine-weather-type-list-grow' : undefined}
        aria-haspopup="true"
      >
        <StyledLink onClick={handleToggle}>
          {intl.formatMessage({ id: msgId.marineWeatherDisplaySettings })}
        </StyledLink>
      </Box>
      <StyledPopper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        placement="auto"
        modifiers={[
          {
            name: 'offset',
            options: {
              offset: [0, 20],
            },
          },
          {
            name: 'arrow',
            enabled: true,
            options: {
              element: arrowRef,
            },
          },
        ]}
      >
        {({ TransitionProps }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: 'center top',
            }}
          >
            <Paper
              ref={paparRef}
              sx={{
                minWidth: 300,
                width: 300,
                padding: 0,
                background: colors.subDrawer.background,
              }}
            >
              <Arrow ref={handleArrowRef} />
              <ClickAwayListener onClickAway={handleClickAway}>
                <Box
                  id="marine-weather-type-list-grow"
                  sx={{ background: theme.palette.background.default }}
                >
                  <FormControl
                    component="fieldset"
                    sx={{
                      width: '100%',
                      paddingTop: theme.spacing(0.5),
                    }}
                  >
                    <RadioGroup
                      value={selectedValue}
                      onChange={handleChange}
                      name="marine-weather-types-group"
                    >
                      <FormControlLabel
                        value="none"
                        control={<StyledRadio />}
                        componentsProps={{
                          typography: {
                            variant: 'body2',
                          },
                        }}
                        label={intl.formatMessage({ id: msgId.marineWeatherTypeNone })}
                      />
                      <Divider />
                      <FormControlLabel
                        value="wind_dir_wind_speed"
                        control={<StyledRadio />}
                        componentsProps={{
                          typography: {
                            variant: 'body2',
                          },
                        }}
                        label={intl.formatMessage({ id: msgId.marineWeatherTypeWindDirWindSpeed })}
                      />
                      <Divider />
                      <FormControlLabel
                        value="sig_height"
                        control={<StyledRadio />}
                        componentsProps={{
                          typography: {
                            variant: 'body2',
                          },
                        }}
                        label={intl.formatMessage({ id: msgId.marineWeatherTypeSigHeight })}
                      />
                      <Divider />
                      <FormControlLabel
                        value="swell_height_swell_dir"
                        control={<StyledRadio />}
                        componentsProps={{
                          typography: {
                            variant: 'body2',
                          },
                        }}
                        label={intl.formatMessage({
                          id: msgId.marineWeatherTypeSwellHeightSwellDir,
                        })}
                      />
                      <Divider />
                      <FormControlLabel
                        value="swell_period_swell_dir"
                        control={<StyledRadio />}
                        componentsProps={{
                          typography: {
                            variant: 'body2',
                          },
                        }}
                        label={intl.formatMessage({
                          id: msgId.marineWeatherTypeSwellPeriodSwellDir,
                        })}
                      />
                    </RadioGroup>
                  </FormControl>
                  <FooterButtonDiv>
                    <FooterButton onClick={() => handleClose('cancel')} color="primary">
                      {intl.formatMessage({ id: msgId.cancel })}
                    </FooterButton>
                    <FooterButton onClick={() => handleClose('ok')} color="primary">
                      {intl.formatMessage({ id: msgId.ok })}
                    </FooterButton>
                  </FooterButtonDiv>
                </Box>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </StyledPopper>
    </React.Fragment>
  );
}
