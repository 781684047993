import React from 'react';
import { useIntl } from 'react-intl';
import { SensorValue } from './MimicContainer';
import { TempInfoComponent as TempInfo } from './TempInfoComponent';
import { styled } from '@mui/material';
import colors from 'resources/colors';
import constants from 'resources/constants';

const RootDiv = styled('div')({
  position: 'absolute',
  overflow: 'hidden',
  fontSize: 0,
  border: `1px solid ${colors.chart.mimic.border}`,
  padding: '2px',
});

const ContentsDiv = styled('div')({
  width: '100%',
  height: '100%',
  position: 'relative',
  background: 'black',
  paddingLeft: '2px',
});

interface RexLoInfoPanelProps {
  value: SensorValue | undefined;
  value2: SensorValue | undefined;
  top: number;
  left: number;
  title?: string;
  title2?: string;
  unit?: string;
  rateV?: number;
  rateH?: number;
}

export function RexLoInfoPanel(props: RexLoInfoPanelProps): JSX.Element {
  const intl = useIntl();
  const rateH = props.rateH ? props.rateH : 1;
  const rateV = props.rateV ? props.rateV : 1;
  const basePanelHeight = 54 * rateV;
  const basePanelWidth = 210 * rateH;
  const sensor = props.value?.sensor;
  const sensor2 = props.value2?.sensor;

  let title = props.title;
  if (sensor && !title) {
    let unit = sensor.displayUnit;
    unit = !(unit === undefined || unit === null || unit.length === 0) ? '[' + unit + ']' : '';
    title = intl.formatMessage({ id: constants.labelPrefix.mimic + sensor.sensorName }) + unit;
  }
  let title2 = props.title2;
  if (sensor2 && !title2) {
    let unit = sensor2.displayUnit;
    unit = !(unit === undefined || unit === null || unit.length === 0) ? '[' + unit + ']' : '';
    title2 = intl.formatMessage({ id: constants.labelPrefix.mimic + sensor2.sensorName }) + unit;
  }

  return (
    <RootDiv
      sx={{
        top: props.top * rateV,
        left: props.left * rateH,
        width: basePanelWidth,
        height: basePanelHeight,
      }}
    >
      <ContentsDiv>
        {props.value && (
          <TempInfo
            top={4}
            title={title}
            left={3}
            width={198}
            value={props.value.value}
            sensor={props.value.sensor}
            group={props.value.group}
          ></TempInfo>
        )}
        {props.value2 && (
          <TempInfo
            top={26}
            title={title2}
            left={3}
            width={198}
            value={props.value2.value}
            sensor={props.value2.sensor}
            group={props.value2.group}
          ></TempInfo>
        )}
      </ContentsDiv>
    </RootDiv>
  );
}
