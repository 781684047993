import React from 'react';
import { Link, SxProps, Typography } from '@mui/material';
import colors from 'resources/colors';

interface LinkTextProps {
  sx?: SxProps;
  linkSx?: SxProps;
  text: string;
  linkText: string;
  disabled?: boolean;
  underline: 'none' | 'hover' | 'always';
  onClickLink: (event: React.MouseEvent<HTMLAnchorElement>) => void;
}

export function LinkText(props: LinkTextProps): JSX.Element {
  const { sx, linkSx, text, linkText, disabled, underline, onClickLink } = props;

  return (
    <Typography sx={sx} variant="body2" align="left" component="div">
      {text}
      {disabled !== true ? (
        <Link
          onClick={onClickLink}
          sx={{ cursor: 'pointer', color: colors.accent, ...linkSx }}
          underline={underline}
        >
          {linkText}
        </Link>
      ) : (
        <span>{linkText}</span>
      )}
    </Typography>
  );
}

LinkText.defaultProps = {
  underline: 'hover',
};
