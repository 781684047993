import React, { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import {
  play,
  pause,
  setSpeedMagnification,
  setRange,
  setCurrentIndex,
  incrementIndex,
  decrementIndex,
  setSamplePerMilliSeconds,
  usePastDataTimeSliderPlaying,
  usePastDataTimeSliderSamplePerMilliSeconds,
  usePastDataTimeSliderSpeedMagnification,
  usePastDataTimeSliderRange,
  usePastDataTimeSliderCurrentIndex,
} from './timeSliderSlice';
import { CommonSensorData } from 'models/data';
import { useIntl } from 'react-intl';
import msgId from 'resources/intl';
import colors from 'resources/colors';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Slider,
  styled,
  SxProps,
  TextField,
  Typography,
} from '@mui/material';
import dimens from 'resources/dimens';
import { theme } from 'resources/theme';
import {
  ArrowDropDown,
  ArrowDropUp,
  Event,
  PauseCircleFilled,
  PlayCircleFilled,
} from '@mui/icons-material';
import { SpeedMagnificationPopup } from './SpeedMagnificationPopup';
import constants from 'resources/constants';
import { loadingLogo } from 'aws-amplify';

dayjs.extend(utc);

const RootDiv = styled('div')({
  height: dimens.timeSlider.height,
  background: theme.palette.primary.main,
  border: '2px solid #A4AFB7',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  padding: 4,
  overflow: 'hidden',
});

const StyledIconButton = styled(IconButton)({
  color: theme.palette.secondary.main,
  width: 32,
  height: 32,
});

const UpDownBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
});

const UpDownIconButton = styled(IconButton)({
  padding: 0,
  width: 16,
  height: 16,
});

const ArrowDropUpIcon = styled(ArrowDropUp)({
  width: 16,
  height: 16,
});

const ArrowDropDownIcon = styled(ArrowDropDown)({
  width: 16,
  height: 16,
});

// TimeSlider componentが消えるときにtimerIdが初期化されIntervalイベントが消えていないと思われるのでglobal領域にtimerIdを持つ
let sliderTimerId: NodeJS.Timer | undefined = undefined;

interface TimeSliderProps {
  sx?: SxProps;
  startDate: string;
  endDate: string;
  commonSensorData: CommonSensorData | undefined;
}

export function TimeSlider(props: TimeSliderProps): JSX.Element {
  const { sx, startDate, endDate, commonSensorData } = props;
  const intl = useIntl();
  const sd = dayjs(startDate).utc();
  const ed = dayjs(endDate).utc();
  const startTime = commonSensorData ? commonSensorData.logdate[0] : 0;
  const slider = React.useRef<HTMLSpanElement>(null);
  const dispatch = useDispatch();
  const calendarAnchorRef = React.useRef<HTMLButtonElement>(null);
  const anchorRef = React.useRef<HTMLButtonElement>(null);
  const [calendarOpen, setCalendarOpen] = React.useState(false);
  const [validDate, setValidDate] = React.useState(true);
  const [inputDate, setInputDate] = React.useState<dayjs.Dayjs | undefined>(undefined);
  const [open, setOpen] = React.useState(false);

  const playing = usePastDataTimeSliderPlaying();
  const samplePerMilliSeconds = usePastDataTimeSliderSamplePerMilliSeconds();
  const speedMagnification = usePastDataTimeSliderSpeedMagnification();
  const range = usePastDataTimeSliderRange();
  const currentIndex = usePastDataTimeSliderCurrentIndex();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleChange = (event: Event, value: number | number[], activeThumb: number) => {
    dispatch(setCurrentIndex(Number(value)));
  };

  const getFormatDate = (index: number) => {
    if (commonSensorData) {
      const d = new Date(index * commonSensorData.samplePerMilliSeconds + startTime);
      const year = d.getUTCFullYear();
      const month = d.getUTCMonth() + 1 < 10 ? '0' + (d.getUTCMonth() + 1) : d.getUTCMonth() + 1;
      const day = d.getUTCDate() < 10 ? '0' + d.getUTCDate() : d.getUTCDate();
      const hour = d.getUTCHours() < 10 ? '0' + d.getUTCHours() : d.getUTCHours();
      const min = d.getUTCMinutes() < 10 ? '0' + d.getUTCMinutes() : d.getUTCMinutes();
      const sec = d.getUTCSeconds() < 10 ? '0' + d.getUTCSeconds() : d.getUTCSeconds();
      const msec = Math.floor(d.getUTCMilliseconds() / commonSensorData.samplePerMilliSeconds)
        .toString()
        .slice(0, 1);

      return year + '/' + month + '/' + day + '\n' + hour + ':' + min + ':' + sec + '.' + msec;
    } else {
      return '';
    }
  };

  /**
   * カレンダーオープン
   */
  const handleCalendarOpen = () => {
    setValidDate(true);
    if (commonSensorData != null) {
      const d = dayjs(currentIndex * commonSensorData.samplePerMilliSeconds + startTime);
      setInputDate(d);
    }
    setCalendarOpen(true);
  };

  /**
   * カレンダークローズ
   */
  const handleCalendarClose = (
    reason: 'backdropClick' | 'escapeKeyDown' | 'userClose',
    result: boolean
  ) => {
    if (reason === 'backdropClick') {
      return false;
    }

    if (reason === 'escapeKeyDown') {
      return false;
    }

    setCalendarOpen(false);
    if (result && inputDate != null) {
      const d = inputDate.diff(sd);
      const index = d / samplePerMilliSeconds;
      dispatch(setCurrentIndex(index));
    }
  };

  /**
   * 日時変更
   */
  const handleCalendarDateChange = (input: string) => {
    let d = dayjs(input).utc();

    // 変換出来ない形はNG
    if (d != null && isNaN(d.unix())) {
      setValidDate(false);

      return;
    }
    const inputs = input.split(/\s+/);
    // 日付と時刻に、１つ以上のスペースで分割出来ない場合はNG
    if (inputs.length !== 2) {
      setValidDate(false);

      return;
    }

    const days = inputs[0].split(/\/|-/);
    // 年月日がハイフンまたはスラッシュで区切られていなければNG
    if (days.length !== 3) {
      setValidDate(false);

      return;
    }
    const year = ('000' + days[0]).slice(-4);
    const month = ('0' + days[1]).slice(-2);
    const day = ('0' + days[2]).slice(-2);

    const times = inputs[1].split(/:|\./);
    // 時分秒がコロンで区切られていなければNG。ミリ秒はあっても無くても良い。
    if (times.length !== 3 && times.length !== 4) {
      setValidDate(false);

      return;
    }

    const hour = ('0' + times[0]).slice(-2);
    const minite = ('0' + times[1]).slice(-2);
    const second = ('0' + times[2]).slice(-2);
    let millisecond;
    if (times.length === 4 && samplePerMilliSeconds < 1000) {
      // ミリ秒は3桁まで。
      if (times[3].length > 3) {
        setValidDate(false);

        return;
      }
      millisecond = (times[3] + '000').slice(0, 3);
    } else {
      millisecond = '000';
    }

    // 時 範囲
    if (Number(hour) < 0 || Number(hour) > 23) {
      setValidDate(false);

      return;
    }
    // 分 範囲
    if (Number(minite) < 0 || Number(minite) > 59) {
      setValidDate(false);

      return;
    }
    // 分 範囲
    if (Number(second) < 0 || Number(second) > 59) {
      setValidDate(false);

      return;
    }
    // ミリ秒は100mm単位
    if (Number(millisecond) % 100 !== 0) {
      setValidDate(false);

      return;
    }

    const datetime = year.concat(
      '-',
      month,
      '-',
      day,
      'T',
      hour,
      ':',
      minite,
      ':',
      second,
      '.',
      millisecond,
      '+00:00'
    );
    const format = constants.dateFormat.iso8601WithSSS;
    d = dayjs(datetime, format);

    // 整形した日付を変換した時、元と一致しなければ異常（例えば13月は01月に変換されるので、比較すると不一致)
    const datetime2 = d.utc().format(format);
    if (datetime2 !== datetime) {
      setValidDate(false);

      return;
    }

    if (d != null) {
      if (d < sd || d > ed) {
        setValidDate(false);
      } else {
        setValidDate(true);
        setInputDate(d);
      }
    } else {
      setValidDate(false);
    }
  };

  /**
   * ポップアップメニュークリック
   * @param event イベント
   * @param magnification 再生速度倍率
   */
  const handleMenuClick = (event: React.MouseEvent<EventTarget>, magnification: number) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    dispatch(setSpeedMagnification(magnification));
    setOpen(false);
  };

  // sliderTimerIdを消えない領域に持つように変更
  if (playing) {
    if (sliderTimerId === undefined) {
      sliderTimerId = setInterval(() => dispatch(incrementIndex()), samplePerMilliSeconds);
    }
  } else {
    if (sliderTimerId !== undefined) {
      clearInterval(sliderTimerId);
      sliderTimerId = undefined;
    }
  }

  useEffect(() => {
    if (commonSensorData) {
      dispatch(setSamplePerMilliSeconds(commonSensorData.samplePerMilliSeconds));
      dispatch(
        setRange({
          startIndex: 0,
          endIndex: commonSensorData.logdate.length - 1,
          startTime: commonSensorData.logdate[0],
          endTime: commonSensorData.logdate[commonSensorData.logdate.length - 1],
        })
      );
    }
  }, [dispatch, commonSensorData]);

  useEffect(() => {
    if (commonSensorData) {
      if (commonSensorData.samplePerMilliSeconds !== samplePerMilliSeconds) {
        dispatch(setSpeedMagnification(1));
      }
    }
  }, [dispatch, commonSensorData, samplePerMilliSeconds]);

  return (
    <RootDiv sx={sx}>
      {!playing && (
        <StyledIconButton onClick={() => dispatch(play())}>
          <PlayCircleFilled />
        </StyledIconButton>
      )}
      {playing && (
        <StyledIconButton onClick={() => dispatch(pause())}>
          <PauseCircleFilled />
        </StyledIconButton>
      )}
      <Typography
        variant="body2"
        component="div"
        sx={{
          lineHeight: 1.0,
          marginLeft: theme.spacing(0.5),
          marginRight: theme.spacing(0.5),
          color: colors.timeSlider.border,
        }}
      >
        {getFormatDate(currentIndex)
          .split('\n')
          .map((t, i) => {
            return <div key={i}>{t}</div>;
          })}
      </Typography>
      <UpDownBox>
        <UpDownIconButton color="secondary" onClick={() => dispatch(incrementIndex())}>
          <ArrowDropUpIcon viewBox="4 4 16 16" />
        </UpDownIconButton>
        <UpDownIconButton color="secondary" onClick={() => dispatch(decrementIndex())}>
          <ArrowDropDownIcon viewBox="4 4 16 16" />
        </UpDownIconButton>
      </UpDownBox>
      <IconButton
        size="small"
        ref={calendarAnchorRef}
        aria-controls={open ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        onFocus={(event) => event.stopPropagation()}
        onClick={() => handleCalendarOpen()}
        sx={{ padding: '6px' }}
      >
        <Event color="secondary" />
      </IconButton>
      <Dialog
        disableEscapeKeyDown
        transitionDuration={{ appear: 0, enter: 0, exit: 0 }}
        open={calendarOpen}
        onClose={(e, reason) => handleCalendarClose(reason, false)}
      >
        <DialogContent>
          <TextField
            // eslint-disable-next-line jsx-a11y/no-autofocus
            autoFocus
            variant="standard"
            margin="dense"
            id="datetime"
            type="datetime"
            fullWidth
            defaultValue={getFormatDate(currentIndex).replace('\n', ' ')}
            onChange={(event) => handleCalendarDateChange(event.target.value)}
            error={!validDate}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleCalendarClose('userClose', false)} color="primary">
            {intl.formatMessage({ id: msgId.cancel })}
          </Button>
          <Button
            onClick={() => handleCalendarClose('userClose', true)}
            color="primary"
            disabled={!validDate}
          >
            {intl.formatMessage({ id: msgId.ok })}
          </Button>
        </DialogActions>
      </Dialog>
      <Slider
        sx={{
          width: 100,
          minWidth: 100,
          margin: '0 16px',
        }}
        ref={slider}
        size="small"
        aria-label="Small"
        color="secondary"
        value={currentIndex}
        onChange={handleChange}
        min={range.startIndex}
        max={range.endIndex}
        step={1}
        aria-labelledby="continuous-slider"
      />
      <Typography
        variant="body2"
        component="div"
        sx={{
          lineHeight: 1.0,
          marginLeft: theme.spacing(0.5),
          marginRight: theme.spacing(0.5),
          color: colors.timeSlider.border,
        }}
      >
        {getFormatDate(range.endIndex)
          .split('\n')
          .map((t, i) => {
            return <div key={i}>{t}</div>;
          })}
      </Typography>
      <SpeedMagnificationPopup
        speedMagnification={speedMagnification}
        samplePerMilliSeconds={samplePerMilliSeconds}
        onClickMagnification={handleMenuClick}
      />
    </RootDiv>
  );
}
