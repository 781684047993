import React from 'react';
import { useIntl } from 'react-intl';
import msgId from 'resources/intl';
import { SearchBar } from 'components/SearchBar';
import { ShipItem } from 'components/shipDrawer/ShipItem';
import { Machine, Ship } from 'models/ships';
import { Grid, styled, Typography } from '@mui/material';
import { NormalButton } from 'components/NormalButton';
import { CheckCircle } from '@mui/icons-material';
import { theme } from 'resources/theme';
import { ContentsHeader } from 'components/ContentsHeader';
import { CustomScrollBars } from 'components/CustomScrollBars';

const RootGrid = styled(Grid)({
  width: 300,
  minWidth: 300,
});

const GridItem = styled(Grid)({
  width: '100%',
  '&:last-child': {
    height: '100%',
  },
});

const FooterDiv = styled('div')({
  width: '100%',
  padding: theme.spacing(1),
  textAlign: 'center',
});

interface SearchShipListProps {
  ships: Ship[] | undefined;
  checkedMachines: Machine[];
  filterShipName: string | undefined;
  titleMsgId?: string;
  onSearchInputChange: (input: string) => void;
  onCheckChange: (shipId: string, machineId: number, checked: boolean) => void;
  onOkClick?: () => void;
}

export function SearchShipList(props: SearchShipListProps): JSX.Element {
  const {
    ships,
    checkedMachines,
    filterShipName,
    onSearchInputChange,
    onCheckChange,
    onOkClick,
    titleMsgId,
  } = props;
  const intl = useIntl();
  const titleId = titleMsgId ? titleMsgId : msgId.shipSelection;

  return (
    <RootGrid container item direction="column" wrap="nowrap">
      <GridItem item>
        <ContentsHeader title={intl.formatMessage({ id: titleId })} />
        <SearchBar
          sx={{ padding: '4px 8px 4px 8px' }}
          placeholder={intl.formatMessage({ id: msgId.shipNameSearch })}
          onChange={(event) => onSearchInputChange(event.target.value)}
          defaultValue={filterShipName}
        />
      </GridItem>
      <GridItem item>
        <CustomScrollBars autoHide={false}>
          {ships != null &&
            ships.length > 0 &&
            ships.map((ship) => {
              return (
                <ShipItem
                  key={ship.shipId}
                  ship={ship}
                  expanded={true}
                  showCalendar={false}
                  checked={ship.checked}
                  onCheckChange={onCheckChange}
                />
              );
            })}
          {ships != null && ships.length === 0 && (
            <Typography variant="h5" sx={{ margin: theme.spacing(1) }}>
              {intl.formatMessage({ id: msgId.noResults })}
            </Typography>
          )}
          {onOkClick && (
            <FooterDiv>
              <NormalButton
                label={intl.formatMessage({ id: msgId.decision })}
                color="primary"
                startIcon={<CheckCircle />}
                disabled={checkedMachines.length === 0}
                onClick={() => onOkClick()}
              />
            </FooterDiv>
          )}
        </CustomScrollBars>
      </GridItem>
    </RootGrid>
  );
}
