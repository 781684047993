import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import { Machine, Ship } from 'models/ships';
import { AppThunk } from 'app/store';
import {
  AnalysisSearchConditions,
  AnalysisSection,
  PeriodUnit,
  FuelConsumptionDetectionConditionItem,
} from 'models/analysis';
import { ErrorResult, getErrorResult } from 'models/error';
import {
  ConfirmedSearchResultItem,
  SearchResult,
  SearchResultItem,
  SearchFuelResult,
} from 'models/search';
import {
  getAnalysisSectionsAsync,
  searchAnalysisDataAsync,
  searchAnalysisFuelOilConsumDetectionSearchAsync,
} from 'api/maricoApiAnalysis';
import { RootState } from 'app/rootReducer';
import { useSelector } from 'react-redux';
import constants from 'resources/constants';
import { doFilterShips } from 'utils/misc';

/**
 * SearchState インターフェース
 */
interface SearchState {
  /** 検索画面オープン状態 */
  open: boolean;
  /** 船舶確定状態 */
  shipConfirmed: boolean;
  /** 船舶リスト */
  ships: Ship[] | undefined;
  /** 選択された機械リスト */
  checkedMachines: Machine[];
  /** フィルター済み船舶リスト */
  filteredShips: Ship[] | undefined;
  /** フィルター船舶名 */
  filterShipName: string;
  /** 分析要素区分リスト */
  analysisSections: AnalysisSection[] | undefined;
  /** 分析要素区分リストエラー */
  analysisSectionsError: ErrorResult | undefined;
  /** 分析メニュー */
  searchConditions: AnalysisSearchConditions;
  /** 選択された分析メニュー */
  confirmedSearchConditions: AnalysisSearchConditions | undefined;
  /** 検索可能かどうか */
  canSearch: boolean;
  /** 検索中かどうか */
  searching: boolean;
  /** 検索結果リスト */
  searchResults: SearchResult[] | undefined;
  /** 燃料診断検索結果 */
  searchFuelResult: SearchFuelResult | undefined;
  /** 検索エラー */
  searchError: ErrorResult | undefined;
  /** 選択された検索結果 */
  selectedSearchResultItem: SearchResultItem | undefined;
  /** 確定検索結果 */
  confirmedSearchResultItem: ConfirmedSearchResultItem | undefined;
  /** 確定済み */
  searchFixed: boolean;
}

const initialDate = (addMonth: number, addDay: number, addSecond?: number) => {
  // 時分秒切り捨て
  const unixTime =
    Math.floor(dayjs().add(addDay, 'day').add(addMonth, 'month').unix() / (60 * 60 * 24)) *
    (60 * 60 * 24) +
    (addSecond ? addSecond : 0);
  const d = dayjs.unix(unixTime).format(constants.dateFormat.iso8601);

  return d;
};

/**
 * Search の初期状態
 */
const initialState: SearchState = {
  open: true,
  shipConfirmed: false,
  ships: undefined,
  checkedMachines: [],
  filteredShips: undefined,
  filterShipName: '',
  analysisSections: undefined,
  analysisSectionsError: undefined,
  searchConditions: {
    startDate: initialDate(0, -31),
    endDate: initialDate(0, -1, 60 * 60 * 24 - 60),
    periodUnit: 'monthly',
    analysisMenuIds: [],
    analysisMenuNames: [],
    conditions: [],
    invalidStartDate: false,
    invalidEndDate: false,
  },
  confirmedSearchConditions: undefined,
  canSearch: false,
  searching: false,
  searchResults: undefined,
  searchFuelResult: undefined,
  searchError: undefined,
  selectedSearchResultItem: undefined,
  confirmedSearchResultItem: undefined,
  searchFixed: false,
};

/**
 * Search の確定状態
 */
const fixedState: SearchState = {
  open: true,
  shipConfirmed: false,
  ships: undefined,
  checkedMachines: [],
  filteredShips: undefined,
  filterShipName: '',
  analysisSections: undefined,
  analysisSectionsError: undefined,
  searchConditions: {
    startDate: initialDate(0, -31),
    endDate: initialDate(0, -1, 60 * 60 * 24 - 60),
    periodUnit: 'monthly',
    analysisMenuIds: [],
    analysisMenuNames: [],
    conditions: [],
    invalidStartDate: false,
    invalidEndDate: false,
  },
  confirmedSearchConditions: undefined,
  canSearch: false,
  searching: false,
  searchResults: undefined,
  searchFuelResult: undefined,
  searchError: undefined,
  selectedSearchResultItem: undefined,
  confirmedSearchResultItem: undefined,
  searchFixed: false,
};

/**
 * 検索確定状態の保持
 * @param state Search状態
 */
const setFixedState = (state: SearchState) => {
  fixedState.shipConfirmed = state.shipConfirmed;
  fixedState.ships = state.ships?.map((ship) => {
    return {
      ...ship,
      machines: ship.machines.map((v) => {
        return {
          ...v,
          serialNumbers: v.serialNumbers.map((va) => va),
          sensorGroups: v.sensorGroups.map((va) => {
            return {
              ...va,
              sensors: va.sensors.map((val) => {
                return { ...val };
              }),
            };
          }),
        };
      }),
      propulsionSerialNumbers: ship.propulsionSerialNumbers.map((v) => v),
    };
  });
  fixedState.checkedMachines = state.checkedMachines?.map((v) => {
    return {
      ...v,
      serialNumbers: v.serialNumbers.map((va) => va),
      sensorGroups: v.sensorGroups.map((va) => {
        return {
          ...va,
          sensors: va.sensors.map((val) => {
            return { ...val };
          }),
        };
      }),
    };
  });
  fixedState.filteredShips = state.filteredShips?.map((ship) => {
    return {
      ...ship,
      machines: ship.machines.map((v) => {
        return {
          ...v,
          serialNumbers: v.serialNumbers.map((va) => va),
          sensorGroups: v.sensorGroups.map((va) => {
            return {
              ...va,
              sensors: va.sensors.map((val) => {
                return { ...val };
              }),
            };
          }),
        };
      }),
      propulsionSerialNumbers: ship.propulsionSerialNumbers.map((v) => v),
    };
  });
  fixedState.filterShipName = state.filterShipName;
  fixedState.analysisSections = state.analysisSections?.map((section) => {
    return {
      ...section,
      analysisMenus: section.analysisMenus.map((menu) => {
        return { ...menu };
      }),
    };
  });
  fixedState.searchConditions = {
    ...state.searchConditions,
    analysisMenuIds: state.searchConditions.analysisMenuIds.map((v) => {
      return v;
    }),
    analysisMenuNames: state.searchConditions.analysisMenuNames.map((v) => {
      return v;
    }),
    conditions: state.searchConditions.conditions.map((v) => {
      return { ...v };
    }),
  };
  if (state.confirmedSearchConditions != null) {
    fixedState.confirmedSearchConditions = {
      ...state.confirmedSearchConditions,
      analysisMenuIds: state.confirmedSearchConditions.analysisMenuIds.map((v) => {
        return v;
      }),
      analysisMenuNames: state.confirmedSearchConditions.analysisMenuNames.map((v) => {
        return v;
      }),
      conditions: state.confirmedSearchConditions.conditions.map((v) => {
        return { ...v };
      }),
    };
  } else {
    state.confirmedSearchConditions = undefined;
  }
  fixedState.canSearch = state.canSearch;
  fixedState.searchResults = state.searchResults?.map((v) => {
    return {
      ...v,
      results: v.results.map((va) => {
        return { ...va };
      }),
    };
  });
  fixedState.searchFuelResult = state.searchFuelResult
    ? ({
      ...state.searchFuelResult,
      summary: { ...state.searchFuelResult.summary },
      data: {
        date: state.searchFuelResult.data.date ? [...state.searchFuelResult.data.date] : [],
        FuelOilConsumP1: state.searchFuelResult.data.FuelOilConsumP1
          ? [...state.searchFuelResult.data.FuelOilConsumP1]
          : [],
        FuelOilConsumS1: state.searchFuelResult.data.FuelOilConsumS1
          ? [...state.searchFuelResult.data.FuelOilConsumS1]
          : [],
        MegSpdP1: state.searchFuelResult.data.MegSpdP1
          ? [...state.searchFuelResult.data.MegSpdP1]
          : [],
        MegSpdS1: state.searchFuelResult.data.MegSpdS1
          ? [...state.searchFuelResult.data.MegSpdS1]
          : [],
        Status: state.searchFuelResult.data.Status ? [...state.searchFuelResult.data.Status] : [],
        VesSpdSog: state.searchFuelResult.data.VesSpdSog
          ? [...state.searchFuelResult.data.VesSpdSog]
          : [],
        VesSog: state.searchFuelResult.data.VesSog
          ? [...state.searchFuelResult.data.VesSog]
          : [],
        EngSpdP: state.searchFuelResult.data.EngSpdP
          ? [...state.searchFuelResult.data.EngSpdP]
          : [],
        EngSpdS: state.searchFuelResult.data.EngSpdS
          ? [...state.searchFuelResult.data.EngSpdS]
          : [],
        MeFoFlowTotal: state.searchFuelResult.data.MeFoFlowTotal
          ? [...state.searchFuelResult.data.MeFoFlowTotal]
          : [],
      },
    } as SearchFuelResult)
    : undefined;
  fixedState.searchError = state.searchError;
  fixedState.selectedSearchResultItem = { ...state.selectedSearchResultItem } as SearchResultItem;
  fixedState.confirmedSearchResultItem = {
    ...state.confirmedSearchResultItem,
    machine: {
      ...state.confirmedSearchResultItem?.machine,
      serialNumbers: state.confirmedSearchResultItem?.machine.serialNumbers.map((v) => v),
      sensorGroups: state.confirmedSearchResultItem?.machine.sensorGroups.map((v) => {
        return {
          ...v,
          sensors: v.sensors.map((val) => {
            return { ...val };
          }),
        };
      }),
    },
  } as ConfirmedSearchResultItem;
};

/**
 * 検索確定状態の再設定
 * @param state Search状態
 */
const resetFixedState = (state: SearchState) => {
  if (fixedState.confirmedSearchResultItem !== undefined) {
    state.shipConfirmed = fixedState.shipConfirmed;
    state.ships = fixedState.ships;
    state.checkedMachines = fixedState.checkedMachines;
    state.filteredShips = fixedState.filteredShips;
    state.filterShipName = fixedState.filterShipName;
    state.analysisSections = fixedState.analysisSections;
    state.searchConditions = fixedState.searchConditions;
    state.confirmedSearchConditions = fixedState.confirmedSearchConditions;
    state.canSearch = fixedState.canSearch;
    state.searchResults = fixedState.searchResults;
    state.searchFuelResult = fixedState.searchFuelResult;
    state.searchError = fixedState.searchError;
    state.selectedSearchResultItem = fixedState.selectedSearchResultItem;
    state.confirmedSearchResultItem = fixedState.confirmedSearchResultItem;
    state.searchFixed = true;
  }
};

/**
 * 検索結果をクリアする。
 * @param state Search状態
 */
const clearSearchResult = (state: SearchState) => {
  state.searchResults = undefined;
  state.selectedSearchResultItem = undefined;
  state.searchFuelResult = undefined;
  // state.confirmedSearchConditions = undefined;
  // state.confirmedSearchResultItem = undefined;
};

/**
 * 検索条件の整合性を確認する。
 * @param state Search状態
 */
const validSearchConditions = (state: SearchState): boolean => {
  const now = dayjs();
  const start = dayjs(state.searchConditions.startDate);
  const end = dayjs(state.searchConditions.endDate);
  if (start != null && end != null) {
    let invalidStartDate = false;
    let invalidEndDate = false;
    if (start > end) {
      invalidStartDate = true;
      invalidEndDate = true;
    }
    if (start > now) {
      invalidStartDate = true;
    }
    if (
      state.searchConditions.periodUnit === 'other' &&
      state.searchConditions.analysisMenuIds.length === 1 &&
      state.searchConditions.analysisMenuIds[0] === 15
    ) {
      const s = dayjs(start).add(31, 'day').add(-1, 'minute');
      if (s < end) {
        invalidStartDate = true;
        invalidEndDate = true;
      }
    }
    state.searchConditions.invalidStartDate = invalidStartDate;
    state.searchConditions.invalidEndDate = invalidEndDate;
  }
  const invalidDate =
    state.searchConditions.invalidStartDate || state.searchConditions.invalidEndDate;
  const invalidMenu = state.searchConditions.analysisMenuIds.length === 0;
  const invalidOther =
    state.searchConditions.periodUnit === 'other' && state.checkedMachines.length !== 1;
  const invalidConditions =
    state.searchConditions.periodUnit === 'other' &&
    state.searchConditions.conditions.some((c) => c.invalidLowerLimit || c.invalidUpperLimit);

  return !invalidDate && !invalidMenu && !invalidOther && !invalidConditions;
};

export const search = createSlice({
  name: 'analysis/search',
  initialState,
  reducers: {
    /**
     * 検索画面の開閉状態を設定する。
     */
    setOpen: (state, { payload }: PayloadAction<boolean>) => {
      state.open = payload;
      resetFixedState(state);
    },
    /**
     * 船舶確定状態を設定する。
     */
    setShipConfirmed: (state, { payload }: PayloadAction<boolean>) => {
      state.shipConfirmed = payload;
      state.canSearch = validSearchConditions(state);
    },
    /**
     * 船舶リストを設定する。
     */
    setCommonShips: (state, { payload }: PayloadAction<Ship[]>) => {
      const commonShips = payload;
      const ships = commonShips.map((commonShip) => {
        return {
          shipId: commonShip.shipId,
          name: commonShip.name,
          propulsionSerialNumbers: commonShip.propulsionSerialNumbers,
          machines: commonShip.machines.map((commonMachine) => {
            return {
              machineId: commonMachine.machineId,
              name: commonMachine.name,
              serialNumbers: commonMachine.serialNumbers,
              dataFormatId: commonMachine.dataFormatId,
              sensorGroups: commonMachine.sensorGroups.filter((x) =>
                x.sensors.find(
                  (y) => y.isSearch && y.displayLowerLimit != null && y.displayUpperLimit != null
                )
              ),
              checked: false,
            };
          }),
          createdAt: commonShip.createdAt,
          updatedAt: commonShip.updatedAt,
          checked: false,
          propulsionSerialNumbersText: commonShip.propulsionSerialNumbers.join(', '),
          machineNamesText: commonShip.machines.map((x) => x.name).join(', '),
        };
      });

      ships.forEach((ship) => {
        ship.machines.forEach((machine) => {
          machine.checked = state.checkedMachines.some((x) => x.machineId === machine.machineId);
        });
        ship.checked = ship.machines.every((x) => x.checked);
      });

      state.ships = ships;
      state.filteredShips = doFilterShips(state.ships, state.filterShipName);
    },
    /**
     * 船舶のチェック状態を設定する。
     */
    setShipCheck: (
      state,
      { payload }: PayloadAction<{ shipId: string; machineId: number; checked: boolean }>
    ) => {
      const ships = state.ships;
      if (ships == null) {
        return;
      }

      if (payload.machineId === 0) {
        const ship = ships.find((x) => x.shipId === payload.shipId);
        if (ship != null) {
          ship.checked = payload.checked;
          ship.machines.map((y) => (y.checked = payload.checked));
        }
      } else {
        const ship = ships.find((x) => x.shipId === payload.shipId);
        if (ship != null) {
          const machine = ships
            .find((x) => x.shipId === payload.shipId)
            ?.machines.find((y) => y.machineId === payload.machineId);
          if (machine != null) {
            machine.checked = payload.checked;
            ship.checked = ship.machines.some((x) => x.checked);
          }
        }
      }

      const machines: Machine[] = [];
      ships.forEach((ship) => {
        ship.machines.forEach((machine) => {
          if (machine.checked) {
            machines.push(machine);
          }
        });
      });

      state.checkedMachines = machines;
      state.ships = ships;
      state.filteredShips = doFilterShips(state.ships, state.filterShipName);
      state.shipConfirmed = false;
      clearSearchResult(state);
    },
    /**
     * フィルター船舶名を設定する。
     */
    setFilterShipName: (state, { payload }: PayloadAction<string>) => {
      state.filterShipName = payload;
      if (state.ships != null) {
        state.filteredShips = doFilterShips(state.ships, state.filterShipName);
      }
    },
    /**
     * 検索期間開始日時を設定する。
     */
    setStartDate: (state, { payload }: PayloadAction<string>) => {
      const startDate = payload;
      const now = dayjs();
      const start = dayjs(startDate);
      const end = dayjs(state.searchConditions.endDate);
      let invalidStartDate = start == null;
      let invalidEndDate = end == null;
      if (start != null && end != null) {
        if (start > end) {
          invalidStartDate = true;
          invalidEndDate = true;
        }
        if (start > now) {
          invalidStartDate = true;
        }
        if (state.searchConditions.periodUnit === 'other') {
          const s = dayjs(start).add(31, 'day').add(-1, 'minute');
          if (s < end) {
            invalidStartDate = true;
            invalidEndDate = true;
          }
        }
      }

      state.searchConditions = {
        ...state.searchConditions,
        startDate: startDate,
        invalidStartDate: invalidStartDate,
        invalidEndDate: invalidEndDate,
      };
      state.canSearch = validSearchConditions(state);
      clearSearchResult(state);
    },
    /**
     * 検索期間終了日時を設定する。
     */
    setEndDate: (state, { payload }: PayloadAction<string>) => {
      const endDate = payload;
      const now = dayjs();
      const start = dayjs(state.searchConditions.startDate);
      const end = dayjs(endDate);
      let invalidStartDate = start == null;
      let invalidEndDate = end == null;
      if (start != null && end != null) {
        if (start > end) {
          invalidStartDate = true;
          invalidEndDate = true;
        }
        if (end > now) {
          invalidEndDate = true;
        }
        if (state.searchConditions.periodUnit === 'other') {
          const s = dayjs(start).add(31, 'day').add(-1, 'minute');
          if (s < end) {
            invalidStartDate = true;
            invalidEndDate = true;
          }
        }
      }

      state.searchConditions = {
        ...state.searchConditions,
        endDate: endDate,
        invalidStartDate: invalidStartDate,
        invalidEndDate: invalidEndDate,
      };
      state.canSearch = validSearchConditions(state);
      clearSearchResult(state);
    },
    /**
     * 分析要素区分リストを設定する。
     */
    setAnalysisSections: (state, { payload }: PayloadAction<AnalysisSection[]>) => {
      state.analysisSections = payload;
      const analysisMenuIds: number[] = [];
      const analysisMenuNames: string[] = [];
      // 月別・週別・日別・その他の順でソートする
      state.analysisSections.forEach((section) => {
        section.analysisMenus.sort((a, b) => {
          const av = a.monthly ? 4 : a.weekly ? 3 : a.daily ? 2 : a.other ? 1 : 0;
          const bv = b.monthly ? 4 : b.weekly ? 3 : b.daily ? 2 : b.other ? 1 : 0;
          if (av < bv) {
            return 1;
          } else if (av > bv) {
            return -1;
          } else {
            return 0;
          }
        });
      });

      state.analysisSections?.forEach((section) =>
        section.analysisMenus.forEach((menu) => {
          menu.checked = !menu.locked && menu[state.searchConditions.periodUnit];
          if (menu.checked) {
            analysisMenuIds.push(menu.analysisMenuId);
            analysisMenuNames.push(menu.analysisMenuName);
          }
        })
      );
      state.searchConditions.analysisMenuIds = analysisMenuIds;
      state.searchConditions.analysisMenuNames = analysisMenuNames;
      state.canSearch = validSearchConditions(state);
    },
    /**
     * 分析要素区分リストエラーを設定する。
     */
    setAnalysisSectionsError: (state, { payload }: PayloadAction<ErrorResult | undefined>) => {
      state.analysisSectionsError = payload;
    },
    /**
     * 期間単位を更新する。
     */
    setPreriodUnit: (state, { payload }: PayloadAction<PeriodUnit>) => {
      if (payload != state.searchConditions.periodUnit) {
        state.searchConditions.periodUnit = payload;

        const analysisMenuIds: number[] = [];
        const analysisMenuNames: string[] = [];
        state.analysisSections?.forEach((section) =>
          section.analysisMenus.forEach((menu) => {
            menu.checked = !menu.locked && menu[state.searchConditions.periodUnit];
            if (menu.checked) {
              analysisMenuIds.push(menu.analysisMenuId);
              analysisMenuNames.push(menu.analysisMenuName);
            }
          })
        );

        state.searchConditions.analysisMenuIds = analysisMenuIds;
        state.searchConditions.analysisMenuNames = analysisMenuNames;
        state.canSearch = validSearchConditions(state);
      }

      clearSearchResult(state);
    },
    /**
     * 分析要素区分リストのチェック状態を設定する。
     */
    setAnalysisSectionChecked: (
      state,
      {
        payload,
      }: PayloadAction<{ analysisSectionId: number; analysisMenuId: number; checked: boolean }>
    ) => {
      const menu = state.analysisSections
        ?.find((x) => x.analysisSectionId === payload.analysisSectionId)
        ?.analysisMenus.find((y) => y.analysisMenuId === payload.analysisMenuId);
      if (menu != null) {
        menu.checked = payload.checked;
        if (payload.checked) {
          const analysisMenuIds = [...state.searchConditions.analysisMenuIds];
          let index = -1;
          for (let i = 0; i < analysisMenuIds.length; i++) {
            if (analysisMenuIds[i] > payload.analysisMenuId) {
              index = i;
              break;
            }
          }
          if (index === -1) {
            state.searchConditions.analysisMenuIds.push(payload.analysisMenuId);
            state.searchConditions.analysisMenuNames.push(menu.analysisMenuName);
          } else {
            state.searchConditions.analysisMenuIds.splice(index, 0, payload.analysisMenuId);
            state.searchConditions.analysisMenuNames.splice(index, 0, menu.analysisMenuName);
          }
        } else {
          state.searchConditions.analysisMenuIds = state.searchConditions.analysisMenuIds.filter(
            (menuId) => menuId !== payload.analysisMenuId
          );
          state.searchConditions.analysisMenuNames =
            state.searchConditions.analysisMenuNames.filter(
              (menuName) => menuName !== menu.analysisMenuName
            );
        }
      }

      state.canSearch = validSearchConditions(state);
      clearSearchResult(state);
    },
    /**
     * 検索を開始する。
     */
    startSearch: (state) => {
      state.searching = true;
    },
    /**
     * 検索結果を設定する。
     */
    setSearchResult: (state, { payload }: PayloadAction<SearchResult[] | undefined>) => {
      if (payload) {
        state.searchResults = payload;
        state.searching = false;
      } else {
        clearSearchResult(state);
      }
    },
    /**
     * 検索結果を設定する。
     */
    setSearchFuelResult: (state, { payload }: PayloadAction<SearchFuelResult | undefined>) => {
      if (payload) {
        state.searchFuelResult = payload;
      } else {
        clearSearchResult(state);
      }
      state.searching = false;
    },

    /**
     * 燃料診断の条件を設定する。
     */
    setFuelConsumptionSearchConditions: (
      state,
      { payload }: PayloadAction<{ data: FuelConsumptionDetectionConditionItem[]; init: boolean }>
    ) => {
      if (!payload.init || state.searchConditions.conditions.length === 0) {
        state.searchConditions.conditions = payload.data;
      } else {
        const condi1 = state.searchConditions.conditions[0];
        const condi2 = state.searchConditions.conditions[1];
        state.searchConditions.conditions = [
          {
            ...state.searchConditions.conditions[0],
            id: payload.data[0].id,
            lowerLimit: payload.data[0].lowerLimit,
            upperLimit: payload.data[0].upperLimit,
            unit: payload.data[0].unit,
            invalidLowerLimit:
              condi1.lowerValue < payload.data[0].lowerLimit ||
              condi1.lowerValue > payload.data[0].upperLimit ||
              condi1.lowerValue > condi1.upperValue,
            invalidUpperLimit:
              condi1.upperValue < payload.data[0].lowerLimit ||
              condi1.upperValue > payload.data[0].upperLimit ||
              condi1.lowerValue > condi1.upperValue,
          },
          {
            ...state.searchConditions.conditions[1],
            id: payload.data[1].id,
            lowerLimit: payload.data[1].lowerLimit,
            upperLimit: payload.data[1].upperLimit,
            unit: payload.data[1].unit,
            invalidLowerLimit:
              condi2.lowerValue < payload.data[1].lowerLimit ||
              condi2.lowerValue > payload.data[1].upperLimit ||
              condi2.lowerValue > condi2.upperValue,
            invalidUpperLimit:
              condi2.upperValue < payload.data[1].lowerLimit ||
              condi2.upperValue > payload.data[1].upperLimit ||
              condi2.lowerValue > condi2.upperValue,
          },
        ];
      }
      state.searchFuelResult = undefined;
      state.canSearch = validSearchConditions(state);
    },
    /**
     * 検索エラーを設定する。
     */
    setSearchError: (state, { payload }: PayloadAction<ErrorResult | undefined>) => {
      state.searchError = payload;
      state.searching = false;
    },
    /**
     * 検索メニューを確定する。
     */
    setConfirmedSearchConditions: (
      state,
      { payload }: PayloadAction<AnalysisSearchConditions | undefined>
    ) => {
      if (payload != null) {
        state.confirmedSearchConditions = {
          ...payload,
          analysisMenuIds: payload.analysisMenuIds.map((v) => {
            return v;
          }),
          analysisMenuNames: payload.analysisMenuNames.map((v) => {
            return v;
          }),
          conditions: payload.conditions.map((v) => {
            return { ...v };
          }),
        };
      } else {
        state.confirmedSearchConditions = undefined;
      }
    },
    /**
     * 検索結果を設定する。
     */
    setSelectedSearchResultItem: (
      state,
      { payload }: PayloadAction<SearchResultItem | undefined>
    ) => {
      state.selectedSearchResultItem = payload;
    },
    /**
     * 検索結果を確定する。
     */
    setConfirmedResultItem: (state, { payload }: PayloadAction<SearchResultItem | undefined>) => {
      const searchResultItem = payload;
      if (searchResultItem != null && state.ships != null) {
        const machine = state.ships
          .map((x) => x.machines.find((y) => y.machineId === searchResultItem.machineId))
          .filter((x) => x != null)[0];
        if (machine != null) {
          state.confirmedSearchResultItem = {
            machine: machine,
            searchResultItem: searchResultItem,
          };
          setFixedState(state);
        }
      } else {
        state.confirmedSearchResultItem = undefined;
      }
    },
    /**
     * AnalyisSearchをクリアする。
     */
    clearAnalysisSearch: (state) => {
      state.open = initialState.open;
      state.shipConfirmed = initialState.shipConfirmed;
      state.ships = initialState.ships;
      state.checkedMachines = [];
      state.filteredShips = initialState.filteredShips;
      state.filterShipName = '';
      state.analysisSections = initialState.analysisSections;
      state.analysisSectionsError = initialState.analysisSectionsError;
      state.searchConditions = {
        startDate: initialDate(0, -31),
        endDate: initialDate(0, -1, 60 * 60 * 24 - 60),
        periodUnit: 'monthly',
        analysisMenuIds: [],
        analysisMenuNames: [],
        conditions: [],
        invalidStartDate: false,
        invalidEndDate: false,
      };
      state.confirmedSearchConditions = initialState.confirmedSearchConditions;
      state.canSearch = initialState.canSearch;
      state.searching = initialState.searching;
      state.searchResults = initialState.searchResults;
      state.searchFuelResult = initialState.searchFuelResult;
      state.searchError = initialState.searchError;
      state.selectedSearchResultItem = initialState.selectedSearchResultItem;
      state.confirmedSearchResultItem = initialState.confirmedSearchResultItem;
      state.searchFixed = initialState.searchFixed;
    },
  },
});

export const {
  setOpen,
  setShipConfirmed,
  setCommonShips,
  setShipCheck,
  setFilterShipName,
  setAnalysisSections,
  setAnalysisSectionsError,
  setStartDate,
  setEndDate,
  setPreriodUnit,
  setAnalysisSectionChecked,
  setSearchError,
  setConfirmedSearchConditions,
  setSelectedSearchResultItem,
  setConfirmedResultItem,
  clearAnalysisSearch,
  setFuelConsumptionSearchConditions,
} = search.actions;

/**
 * 分析区分リストを取得する。
 */
export const fetchAnalysisSections = (): AppThunk => async (dispatch) => {
  try {
    const analysisSections = await getAnalysisSectionsAsync();
    dispatch(search.actions.setAnalysisSections(analysisSections));
  } catch (error) {
    dispatch(search.actions.setAnalysisSectionsError(getErrorResult(error)));
  }
};

/**
 * 分析データを検索する。
 * @param machines 機械リスト
 * @param searchConditions 検索条件
 */
export const searchAnalysisData =
  (machines: Machine[], searchConditions: AnalysisSearchConditions): AppThunk =>
    async (dispatch) => {
      dispatch(search.actions.startSearch());
      try {
        const machineIds = machines.map((x) => x.machineId);
        const searchResults = await searchAnalysisDataAsync(machineIds, searchConditions);
        dispatch(search.actions.setSearchResult(searchResults));
      } catch (error) {
        dispatch(search.actions.setSearchError(getErrorResult(error)));
      }
    };

/**
 * 分析データを検索する。
 * @param machines 機械リスト
 * @param searchConditions 検索条件
 */
export const searchAnalysisFuelOilConsumDetectionSearch =
  (machines: Machine, searchConditions: AnalysisSearchConditions): AppThunk =>
    async (dispatch) => {
      dispatch(search.actions.startSearch());
      try {
        const machineId = machines.machineId;
        const searchResults = await searchAnalysisFuelOilConsumDetectionSearchAsync(
          machineId,
          searchConditions
        );

        dispatch(search.actions.setSearchFuelResult(searchResults));
      } catch (error) {
        dispatch(search.actions.setSearchError(getErrorResult(error)));
      }
    };

const analysisSearchState = (state: RootState) => state.analysisSearch;
const selectOpen = createSelector(analysisSearchState, (x) => x.open);
const selectShipConfirmed = createSelector(analysisSearchState, (x) => x.shipConfirmed);
const selectShips = createSelector(analysisSearchState, (x) => x.ships);
const selectCheckedMachines = createSelector(analysisSearchState, (x) => x.checkedMachines);
const selectFilteredShips = createSelector(analysisSearchState, (x) => x.filteredShips);
const selectFilterShipName = createSelector(analysisSearchState, (x) => x.filterShipName);
const selectAnalysisSections = createSelector(analysisSearchState, (x) => x.analysisSections);
const selectAnalysisSectionsError = createSelector(
  analysisSearchState,
  (x) => x.analysisSectionsError
);
const selectSearchConditions = createSelector(analysisSearchState, (x) => x.searchConditions);
const selectConfirmedSearchConditions = createSelector(
  analysisSearchState,
  (x) => x.confirmedSearchConditions
);
const selectCanSearch = createSelector(analysisSearchState, (x) => x.canSearch);
const selectSearching = createSelector(analysisSearchState, (x) => x.searching);
const selectSearchResults = createSelector(analysisSearchState, (x) => x.searchResults);
const selectSearchFuelResult = createSelector(analysisSearchState, (x) => x.searchFuelResult);
const selectSearchError = createSelector(analysisSearchState, (x) => x.searchError);
const selectSelectedSearchResultItem = createSelector(
  analysisSearchState,
  (x) => x.selectedSearchResultItem
);
const selectConfirmedSearchResultItem = createSelector(
  analysisSearchState,
  (x) => x.confirmedSearchResultItem
);
const selectSearchFixed = createSelector(analysisSearchState, (x) => x.searchFixed);

export const useAnalysisSearchOpen = () => useSelector(selectOpen);
export const useAnalysisSearchShipConfirmed = () => useSelector(selectShipConfirmed);
export const useAnalysisSearchShips = () => useSelector(selectShips);
export const useAnalysisSearchCheckedMachines = () => useSelector(selectCheckedMachines);
export const useAnalysisSearchFilteredShips = () => useSelector(selectFilteredShips);
export const useAnalysisSearchFilterShipName = () => useSelector(selectFilterShipName);
export const useAnalysisSearchAnalysisSections = () => useSelector(selectAnalysisSections);
export const useAnalysisSearchAnalysisSectionsError = () =>
  useSelector(selectAnalysisSectionsError);
export const useAnalysisSearchConditions = () => useSelector(selectSearchConditions);
export const useAnalysisConfirmedSearchConditions = () =>
  useSelector(selectConfirmedSearchConditions);
export const useAnalysisSearchCanSearch = () => useSelector(selectCanSearch);
export const useAnalysisSearchSearching = () => useSelector(selectSearching);
export const useAnalysisSearchResults = () => useSelector(selectSearchResults);
export const useAnalysisSearchFuelResult = () => useSelector(selectSearchFuelResult);
export const useAnalysisSearchError = () => useSelector(selectSearchError);
export const useAnalysisSearchSelectedSearchResultItem = () =>
  useSelector(selectSelectedSearchResultItem);
export const useAnalysisSearchConfirmedSearchResultItem = () =>
  useSelector(selectConfirmedSearchResultItem);
export const useAnalysisSearchFixed = () => useSelector(selectSearchFixed);

export default search.reducer;
