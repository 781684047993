import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'app/rootReducer';
import { useSelector } from 'react-redux';

interface AccountInformationState {
  open: boolean;
}

const initialState: AccountInformationState = {
  open: false,
};

const accountInformation = createSlice({
  name: 'accountInformation',
  initialState,
  reducers: {
    setOpen: (state, { payload }: PayloadAction<boolean>) => {
      state.open = payload;
    },
    clearAccountInformation: (state) => {
      state.open = initialState.open;
    },
  },
});

export const { setOpen, clearAccountInformation } = accountInformation.actions;

export const useAccountInformationOpen = () => {
  return useSelector<RootState, boolean>((state) => state.accountInformation.open);
};

export default accountInformation.reducer;
