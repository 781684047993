import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import msgId from 'resources/intl';
import {
  deleteAccount,
  registrationAccount,
  setAccountEditing,
  setEditAccountError,
  updateAccount,
  useAdminSettingsAccountGroups,
  useAdminSettingsEditAccount,
  useAdminSettingsEditMode,
  useAdminSettingssEditAccountError,
  useAdminSettingsEditAccountResult,
  setEditAccountResult,
  useAdminSettingsFetching,
} from '../adminSettingsSlice';
import { Autorities } from 'models/admin';
import {
  EditDialog,
  EditDialogContents,
  EditDialogFooterButtonGrid,
  EditDialogInputFieldGrid,
  EditDialogRootGrid,
  EditDialogTitleGrid,
} from 'components/EditDialog';
import { MenuItem, Typography } from '@mui/material';
import { SmallTextField } from 'components/SmallTextField';
import { PasswordTextField } from 'components/PasswordTextField';
import { NormalButton } from 'components/NormalButton';
import { theme } from 'resources/theme';
import { SelectTextField } from 'components/SelectTextField';
import { ErrorInfo, ErrorMessages, FieldLabels, getErrorMessage } from 'models/error';
import { AlertDialog } from 'components/AlertDialog';
import dimens from 'resources/dimens';
import { CustomScrollBars } from 'components/CustomScrollBars';
import colors from 'resources/colors';
import { FadeLoading } from 'components/FadeLoading';
import { useAccount } from 'features/login/accountSlice';
import { AuthorityUsers } from 'models/accounts';

interface AccountEditDialogProps {
  contentHeight: number;
}

export function AccountEditDialog(props: AccountEditDialogProps): JSX.Element {
  const { contentHeight } = props;
  const intl = useIntl();
  const dispatch = useDispatch();
  const account = useAccount();
  const editMode = useAdminSettingsEditMode();
  const editAccount = useAdminSettingsEditAccount();
  const accountGroups = useAdminSettingsAccountGroups();
  const updateResult = useAdminSettingsEditAccountResult();
  const editAccountError = useAdminSettingssEditAccountError();
  const inProgress = useAdminSettingsFetching();
  const [state, setState] = useState<{
    userName: string;
    authorityId: number;
    accountGroupId: number | undefined;
    mailAddress: string;
    password: string;
  }>({
    userName: '',
    authorityId: 4,
    accountGroupId: 0,
    mailAddress: '',
    password: '',
  });
  const [deleteDialog, setDeleteDialog] = useState<boolean>(false);

  let errorMsg: ErrorMessages = {};
  const labels: FieldLabels = {
    PARAMETER_LENGTH_ERROR: {
      fallback: intl.formatMessage({ id: msgId.errorMessageLengthError100 }),
      password: intl.formatMessage({ id: msgId.errorPasswordRequirement }),
    },
    PARAMETER_FORMAT_ERROR: {
      fallback: intl.formatMessage({ id: msgId.errorLoginIdInvalidCharacter }),
      mailAddress: intl.formatMessage({ id: msgId.errorMailAddressFormat }),
      passsword: intl.formatMessage({ id: msgId.errorPasswordFormat }),
    },
    PARAMETER_REQUIREMENTS_ERROR: {
      fallback: intl.formatMessage({ id: msgId.errorPasswordRequirement }),
    },
    PASSWORD_MISMATCH_ERROR: {
      fallback: intl.formatMessage({ id: msgId.errorCurrentPasswordMismatch }),
    },
    PARAMETER_INVALID_CHARACTER_ERROR: {
      fallback: intl.formatMessage({ id: msgId.errorPasswordFormat }),
    },
    PARAMETER_DUPLICATION_ERROR: {
      fallback: intl.formatMessage({ id: msgId.errorMessageParameterRequirements }),
    },
    fallback: { fallback: intl.formatMessage({ id: msgId.errorMessageOther }) },
  };
  let errors: ErrorInfo[] = [];
  if (editAccountError) {
    if (Array.isArray(editAccountError.response)) {
      errors = editAccountError.response;
    } else {
      errors = [editAccountError.response];
    }
  }
  errorMsg = getErrorMessage(errors, labels);
  if (!accountGroups?.some((x) => x.accountGroupId === state.accountGroupId)) {
    errorMsg['accountGroupId'] = intl.formatMessage({ id: msgId.messageInputRequired });
  }

  /**
   * バックボタン押下
   */
  const handleBackClick = () => {
    dispatch(setAccountEditing({ editing: false, account: undefined }));
    dispatch(setEditAccountError(undefined));
    setState({
      userName: '',
      authorityId: 4,
      accountGroupId: 0,
      mailAddress: '',
      password: '',
    });
  };

  /**
   * 削除ボタン押下時に呼ばれる。
   */
  const handleDeleteClick = () => {
    setDeleteDialog(true);
  };

  /**
   * 削除実行時に呼ばれる。
   */
  const handleDeleteClickOk = (result: string) => {
    if (editAccount && result === 'ok') {
      dispatch(deleteAccount(editAccount.accountId));
    }
    setDeleteDialog(false);
  };

  /**
   * 登録ボタン押下時に呼ばれる。
   */
  const handleRegistrationClick = () => {
    if (editAccount) {
      dispatch(
        updateAccount(editAccount.accountId, {
          authorityId: state.authorityId,
          accountGroupId: state.accountGroupId,
          mailAddress: state.mailAddress,
          password: state.password ? state.password : undefined,
        })
      );
    } else {
      if (state.accountGroupId !== undefined) {
        dispatch(
          registrationAccount(
            state.authorityId,
            state.accountGroupId,
            state.mailAddress,
            state.userName
          )
        );
      }
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setState({ ...state, [event.target.name]: event.target.value });
  };

  const handleSelectChange = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    if (event.target.name) {
      switch (event.target.name) {
        case 'authority-id':
          setState({ ...state, ['authorityId']: parseInt(event.target.value + '', 10) });
          break;
        case 'account-group-id':
          setState({ ...state, ['accountGroupId']: parseInt(event.target.value + '', 10) });
          break;
      }
    }
  };

  const handleResultClear = (isSuccess: boolean) => {
    if (isSuccess) {
      dispatch(setAccountEditing({ editing: false, account: undefined }));
      dispatch(setEditAccountError(undefined));
      setDeleteDialog(false);
      setState({
        userName: '',
        authorityId: 4,
        accountGroupId: 0,
        mailAddress: '',
        password: '',
      });
    }
    dispatch(setEditAccountResult(undefined));
  };

  useEffect(() => {
    setState({
      userName: editAccount ? editAccount.cognitoAccountId : '',
      accountGroupId: editAccount ? editAccount.accountGroupId : 0,
      authorityId: editAccount ? editAccount.authorityId : AuthorityUsers,
      mailAddress: editAccount ? editAccount.mailAddress : '',
      password: '',
    });
  }, [dispatch, editAccount]);

  useEffect(() => {
    if (updateResult && updateResult.isSuccess && updateResult.isDeleteRequest) {
      dispatch(setAccountEditing({ editing: false, account: undefined }));
      dispatch(setEditAccountError(undefined));
      setState({
        userName: '',
        authorityId: AuthorityUsers,
        accountGroupId: 0,
        mailAddress: '',
        password: '',
      });
      dispatch(setEditAccountResult(undefined));
    }
  }, [dispatch, updateResult]);

  return (
    <EditDialog
      id="accountEditDialog"
      open={editMode === 'account'}
      onBackClick={handleBackClick}
      title={intl.formatMessage({
        id: editAccount ? msgId.accountInformation : msgId.accountNewRegistration,
      })}
    >
      <EditDialogContents>
        <CustomScrollBars
          thumbColor={colors.scrollBar.thumb.light}
          height={contentHeight}
          width={dimens.dialogContents.width}
          autoHide={false}
        >
          <EditDialogRootGrid>
            <EditDialogTitleGrid>
              <Typography variant="h5">
                {intl.formatMessage({ id: msgId.guideMessageEditAccount })}
              </Typography>
            </EditDialogTitleGrid>
            <EditDialogInputFieldGrid>
              <SmallTextField
                id="userName"
                fullWidth={true}
                label={intl.formatMessage({ id: msgId.accountName })}
                required={true}
                autoComplete="email"
                value={state.userName}
                disabled={editAccount !== undefined}
                onChange={handleChange}
                error={errorMsg['cognitoAccountId'].length > 0 || !state.userName}
                helperText={
                  !state.userName
                    ? intl.formatMessage({ id: msgId.messageInputRequired })
                    : errorMsg['cognitoAccountId']
                }
              />
            </EditDialogInputFieldGrid>
            <EditDialogInputFieldGrid>
              <SelectTextField
                id="authority-id"
                fullWidth={true}
                label={intl.formatMessage({ id: msgId.authority })}
                required={true}
                value={state.authorityId}
                disabled={editAccount != null && editAccount.accountId === account?.accountId}
                onChange={handleSelectChange}
                error={errorMsg['authorityId'].length > 0}
                helperText={errorMsg['authorityId']}
              >
                {Autorities.map((authority) => {
                  return (
                    <MenuItem key={authority.id} value={authority.id}>
                      {intl.formatMessage({ id: authority.msgId })}
                    </MenuItem>
                  );
                })}
              </SelectTextField>
            </EditDialogInputFieldGrid>
            <EditDialogInputFieldGrid>
              <SelectTextField
                id="account-group-id"
                fullWidth={true}
                label={intl.formatMessage({ id: msgId.accountGroup })}
                required={true}
                value={state.accountGroupId}
                onChange={handleSelectChange}
                error={errorMsg['accountGroupId'].length > 0}
                helperText={errorMsg['accountGroupId']}
              >
                {accountGroups &&
                  accountGroups.map((accountGroup) => {
                    return (
                      <MenuItem
                        key={accountGroup.accountGroupId}
                        value={accountGroup.accountGroupId}
                      >
                        {accountGroup.accountGroupName}
                      </MenuItem>
                    );
                  })}
              </SelectTextField>
            </EditDialogInputFieldGrid>
            <EditDialogInputFieldGrid>
              <SmallTextField
                id="mailAddress"
                fullWidth={true}
                label={intl.formatMessage({ id: msgId.mailAddress })}
                required={true}
                autoComplete="off"
                value={state.mailAddress}
                onChange={handleChange}
                error={errorMsg['mailAddress'].length > 0 || !state.mailAddress}
                helperText={
                  !state.mailAddress
                    ? intl.formatMessage({ id: msgId.errorMessageLengthError100 })
                    : errorMsg['mailAddress']
                }
              />
            </EditDialogInputFieldGrid>
            {editAccount && (
              <EditDialogInputFieldGrid>
                <PasswordTextField
                  id="password"
                  fullWidth={true}
                  label={intl.formatMessage({ id: msgId.adminAccountPassword })}
                  required={false}
                  autoComplete="new-password"
                  value={state.password}
                  onChange={handleChange}
                  placeholder={intl.formatMessage({ id: msgId.passwordPlaceholder })}
                  error={errorMsg['password'].length > 0}
                  shrink={true}
                  helperText={errorMsg['password']}
                />
              </EditDialogInputFieldGrid>
            )}
            <EditDialogFooterButtonGrid>
              {editAccount && (
                <NormalButton
                  label={intl.formatMessage({ id: msgId.delete })}
                  color="error"
                  onClick={() => handleDeleteClick()}
                  sx={{ marginRight: theme.spacing(2), width: dimens.button.footerWidth }}
                />
              )}
              <NormalButton
                sx={{ width: dimens.button.footerWidth }}
                label={intl.formatMessage({ id: msgId.registration })}
                color="primary"
                onClick={() => handleRegistrationClick()}
                disabled={
                  state.accountGroupId === undefined ||
                  state.userName.length === 0 ||
                  state.mailAddress.length === 0 ||
                  !accountGroups?.some((x) => x.accountGroupId === state.accountGroupId)
                }
              />
            </EditDialogFooterButtonGrid>
          </EditDialogRootGrid>
          {updateResult != null && updateResult.showDialog && editMode === 'account' && (
            <AlertDialog
              title={intl.formatMessage({
                id: updateResult.isSuccess ? msgId.confirm : msgId.error,
              })}
              message={intl.formatMessage({ id: updateResult.msgId })}
              onClose={() => handleResultClear(updateResult.isSuccess)}
            />
          )}
          {deleteDialog && editMode === 'account' && (
            <AlertDialog
              title={intl.formatMessage({ id: msgId.delete })}
              message={intl.formatMessage({ id: msgId.confirmMessageDelete })}
              onClose={(result) => handleDeleteClickOk(result)}
              negativeButton={intl.formatMessage({ id: msgId.cancel })}
              positiveButton={intl.formatMessage({ id: msgId.ok })}
            />
          )}
        </CustomScrollBars>
      </EditDialogContents>
      <FadeLoading loading={inProgress} />
    </EditDialog>
  );
}
