import React from 'react';
import { useIntl } from 'react-intl';
import { SensorValue } from './MimicContainer';
import { RexLoInfoComponent } from './RexInfoComponent';
import { styled, SxProps } from '@mui/material';
import colors from 'resources/colors';
import constants from 'resources/constants';

const Rex01_L = 'MegFuelRackLvP1';
const Rex01_R = 'MegFuelRackLvS1';
const Rex02_L = 'MegHpP1';
const Rex02_R = 'MegHpS1';

const Rex01_402L = 'EngFuelRackLvP';
const Rex01_402R = 'EngFuelRackLvS';
const Rex02_402L = 'EngSftHpP';
const Rex02_402R = 'EngSftHpS';

const RootDiv = styled('div')({
  border: `1px solid ${colors.chart.mimic.border}`,
  position: 'absolute',
  fontSize: 0,
  padding: '3px',
});

const BackgroundDiv = styled('div')({
  width: '100%',
  height: '100%',
  position: 'relative',
  padding: '3px',
  background: 'black',
});

interface RexInfoPanelProps {
  sx?: SxProps;
  values: SensorValue[];
  top: number;
  left: number;
  rateV?: number;
  rateH?: number;
}

interface RexInfoItemProps {
  value: SensorValue;
  top: number;
  left: number;
  rateV?: number;
  rateH?: number;
}

function RexInfoItem(props: RexInfoItemProps) {
  const intl = useIntl();
  const { left, top, rateV, rateH } = props;
  const { group, sensor, value } = props.value;
  const sensorTitle = sensor.sensorName
    ? intl.formatMessage({ id: constants.labelPrefix.mimic + sensor.sensorName })
    : '';
  const sensorUnit = group.displayUnit;

  return (
    <RexLoInfoComponent
      group={group}
      left={left}
      sensor={sensor}
      top={top}
      value={value}
      rateH={rateH}
      rateV={rateV}
      title={sensorTitle}
      unit={sensorUnit}
    />
  );
}

export function RexInfoPanel(props: RexInfoPanelProps): JSX.Element {
  const { sx, values } = props;
  const rateV = props.rateV ? props.rateV : 1;
  const rateH = props.rateH ? props.rateH : 1;
  // const rowSize = 18 * rateV;
  const basePanelHeight = 159 * rateV;
  const basePanelWidth = 180 * rateH;

  const rootProps = {
    top: props.top * rateV,
    left: props.left * rateH,
    width: basePanelWidth,
    height: basePanelHeight,
    ...sx,
  };

  if (values.length === 0) {
    return <RootDiv sx={rootProps} />;
  }

  return (
    <RootDiv sx={rootProps}>
      <BackgroundDiv>
        {values.map((item) => {
          const { sensor, value } = item;
          const key = sensor.sensorName + '_' + value;
          switch (sensor.sensorName) {
            case Rex01_L:
            case Rex01_R:
            case Rex01_402L:
            case Rex01_402R:
              return (
                <RexInfoItem key={key} top={0} left={0} value={item} rateH={rateH} rateV={rateV} />
              );
            case Rex02_L:
            case Rex02_R:
            case Rex02_402L:
            case Rex02_402R:
              return (
                <RexInfoItem key={key} top={0} left={84} value={item} rateH={rateH} rateV={rateV} />
              );
            default:
              return <React.Fragment key={key} />;
          }
        })}
      </BackgroundDiv>
    </RootDiv>
  );
}
