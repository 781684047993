import React from 'react';
import { useIntl } from 'react-intl';
import colors from 'resources/colors';
import msgId from 'resources/intl';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { Grid, styled, Typography } from '@mui/material';
import { ContentsHeader } from 'components/ContentsHeader';
import { theme } from 'resources/theme';
import { NormalButton } from 'components/NormalButton';
import { CheckCircle } from '@mui/icons-material';
import { CustomScrollBars } from 'components/CustomScrollBars';

dayjs.extend(utc);

const RootGrid = styled(Grid)({
  minWidth: 280,
  width: '100%',
  '& > div': {
    width: '100%',
  },
  height: '100%',
});

const GridItem = styled(Grid)({
  width: '100%',
});

const GridContents = styled(Grid)({
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1),
  paddingTop: theme.spacing(0.5),
  paddingBottom: theme.spacing(0.5),
  minHeight: 32,
  background: colors.listItem.header,
});

const ResultDiv = styled('div')({
  padding: '10px 0 10px',
  background: 'white',
  height: '100%',
});

const FuelConsumptionResultDiv = styled('div')({
  margin: '0 10px 10px',
  width: `calc(100% - 20px)`,
  '& > div': {
    overflow: 'hidden',
  },
  maxWidth: 500,
});

const AggregateResultDiv = styled('div')({
  border: '1px solid skyblue',
  margin: '0 10px 0',
  width: `calc(100% - 20px)`,
  '& > div': {
    overflow: 'hidden',
  },
  maxWidth: 500,
});

const FooterDiv = styled('div')({
  width: '100%',
  padding: theme.spacing(1),
  textAlign: 'center',
  maxWidth: 500,
});

interface FuelConsumptionResults {
  caption: string;
  value: string;
}

interface AggregateResults {
  caption: string;
  value: string;
}

interface FuelConsumptionDetectionResultProps {
  fuelConsumptionResults: FuelConsumptionResults[] | undefined;
  aggregateResults: AggregateResults[] | undefined;
  nodata: boolean;
  onOutputClick: () => void;
}

export function FuelConsumptionDetectionResult(
  props: FuelConsumptionDetectionResultProps
): JSX.Element {
  const { fuelConsumptionResults, aggregateResults, nodata, onOutputClick } = props;
  const intl = useIntl();

  return (
    <RootGrid container item direction="column" wrap="nowrap">
      <ContentsHeader title={intl.formatMessage({ id: msgId.analysisDataResults })} />
      <CustomScrollBars autoHide={false} height={`100%`}>
        <GridItem item>
          {/* <ContentsHeader title={intl.formatMessage({ id: msgId.resultSelection })} /> */}
          {(fuelConsumptionResults || aggregateResults) && !nodata && (
            <ResultDiv>
              <FuelConsumptionResultDiv>
                {fuelConsumptionResults?.map((result, index) => {
                  return (
                    <GridContents
                      container
                      wrap="nowrap"
                      alignItems="center"
                      key={index}
                      sx={{
                        border: '1px solid white',
                        backgroundColor: colors.chart.mimic.background,
                      }}
                    >
                      <Grid item sx={{ minWidth: 180 }}>
                        <Typography variant="body2" noWrap sx={{ color: 'white' }}>
                          {result.caption}
                        </Typography>
                      </Grid>
                      <Grid item sx={{ width: '100%' }}>
                        <Typography
                          variant="body2"
                          noWrap
                          textAlign={'right'}
                          sx={{ color: colors.chart.mimic.value }}
                        >
                          {result.value}
                        </Typography>
                      </Grid>
                    </GridContents>
                  );
                })}
              </FuelConsumptionResultDiv>
              <AggregateResultDiv>
                {aggregateResults?.map((result, index) => {
                  return (
                    <GridContents
                      container
                      wrap="nowrap"
                      alignItems="center"
                      key={index}
                      sx={{ backgroundColor: 'white' }}
                    >
                      <Grid item sx={{ minWidth: 180 }}>
                        <Typography variant="body2" noWrap>
                          {result.caption}
                        </Typography>
                      </Grid>
                      <Grid item sx={{ width: '100%' }}>
                        <Typography variant="body2" noWrap textAlign={'right'}>
                          {result.value}
                        </Typography>
                      </Grid>
                    </GridContents>
                  );
                })}
              </AggregateResultDiv>
            </ResultDiv>
          )}
          {nodata && (
            <Typography
              variant="body2"
              noWrap
              sx={{ marginTop: theme.spacing(1), marginLeft: theme.spacing(1) }}
            >
              {intl.formatMessage({ id: msgId.noResults })}
            </Typography>
          )}
        </GridItem>
        <GridItem item>
          {(fuelConsumptionResults || aggregateResults || nodata) && (
            <FooterDiv>
              <NormalButton
                label={intl.formatMessage({ id: msgId.analysisResultsOutput })}
                color="primary"
                startIcon={<CheckCircle />}
                disabled={nodata}
                onClick={() => onOutputClick()}
              />
            </FooterDiv>
          )}
        </GridItem>
      </CustomScrollBars>
    </RootGrid>
  );
}
