import React from 'react';
import colors from 'resources/colors';
import { useIntl } from 'react-intl';
import msgId from 'resources/intl';
import { FileUplod } from 'components/FileUpload';
import { useDispatch } from 'react-redux';
import {
  uploadAnalysisMenuAuthority,
  uploadSensorAccessAuthorities,
  useAdminSettingsSensorAccessAuthoritiesError,
  useAdminSettingsAnalysisMenuAccessAuthoritiesError,
  setSensorAccessAuthoritiesError,
  setAnalysisMenuAccessAuthoritiesError,
  useAdminSettingsFileUploadResult as useAdminSettingsUpdateResult,
  setFileUploadResult as setUpdateResult,
} from '../adminSettingsSlice';
import { Box, styled, Typography } from '@mui/material';
import { MultiLineTypography } from 'components/MultiLineTypography';
import AppLogger from 'utils/AppLogger';
import { theme } from 'resources/theme';
import { ContentsHeader } from 'components/ContentsHeader';
import { AlertDialog } from 'components/AlertDialog';
import { CustomScrollBars } from 'components/CustomScrollBars';
import { useNavigate } from 'react-router-dom';
import { useAccount } from 'features/login/accountSlice';
import { allClearAndSignOut } from 'utils/common';

const MenuContents = styled('div')({
  paddingBottom: theme.spacing(1),
  minWidth: '200px',
});

const MenuHeader = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  padding: '4px 4px 4px 8px',
  border: '1px solid rgba(164, 175, 183, 1)',
  background: colors.listItem.header,
});

interface AccessAuthorityManagementProps {
  open: boolean;
}

export function AccessAuthorityManagement(props: AccessAuthorityManagementProps): JSX.Element {
  AppLogger.debug('CALL: AccessAuthorityManagement');
  const { open } = props;
  const intl = useIntl();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const account = useAccount();
  const updateResult = useAdminSettingsUpdateResult();
  const sensorAccessAuthoritiesError = useAdminSettingsSensorAccessAuthoritiesError();
  const analysisMenuAccessAuthoritiesError = useAdminSettingsAnalysisMenuAccessAuthoritiesError();

  const handleAttachFileSensorAccessAuthority = (file: File | undefined) => {
    // 拡張子チェック
    const error = file != null && !file.name.toLowerCase().endsWith('.csv');
    dispatch(setSensorAccessAuthoritiesError(error));
  };

  const handleUploadSensorAccessAuthority = (file: File) => {
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        const url = reader.result as string;
        const data = url.replace(/^.*,/, '');
        dispatch(uploadSensorAccessAuthorities(data));
      };
      reader.readAsDataURL(file);
    }
  };

  const handleUploadAnalysisMenuAuthority = (file: File) => {
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        const url = reader.result as string;
        const data = url.replace(/^.*,/, '');
        dispatch(uploadAnalysisMenuAuthority(data));
      };
      reader.readAsDataURL(file);
    }
  };

  const handleAttachFileAnalysisMenuAuthority = (file: File | undefined) => {
    // 拡張子チェック
    const error = file != null && !file.name.toLowerCase().endsWith('.csv');
    dispatch(setAnalysisMenuAccessAuthoritiesError(error));
  };

  const handleResultClear = () => {
    if (updateResult?.isSuccess) {
      allClearAndSignOut(dispatch, account?.homePath, navigate);
    } else {
      dispatch(setUpdateResult(undefined));
    }
  };

  return (
    <Box
      sx={{
        position: 'absolute',
        width: '100%',
        height: '100%',
        background: colors.subDrawer.background,
        visibility: open ? 'visible' : 'hidden',
      }}
    >
      <CustomScrollBars thumbColor={colors.scrollBar.thumb.light}>
        <ContentsHeader title={intl.formatMessage({ id: msgId.authorityManagement })} />
        <MenuContents>
          <MenuHeader>
            <Typography variant="body2" noWrap>
              {intl.formatMessage({ id: msgId.sensorAccessAuthoritySettings })}
            </Typography>
          </MenuHeader>
          <MultiLineTypography
            text={intl.formatMessage({ id: msgId.sensorAccessAuthoritySettingsDescription })}
            variant="body2"
            align="left"
            sx={{ padding: '4px 4px 4px 8px' }}
          />
          <FileUplod
            id="FileUplodSensorAccessAuthority"
            acceptFileType=".csv"
            onAttachFile={handleAttachFileSensorAccessAuthority}
            onUpload={handleUploadSensorAccessAuthority}
            errorMessage={
              sensorAccessAuthoritiesError
                ? intl.formatMessage({ id: msgId.fileIsNotSupported })
                : undefined
            }
            sx={{ marginRight: '8px' }}
          />
        </MenuContents>
        <MenuContents>
          <MenuHeader>
            <Typography variant="body2" noWrap>
              {intl.formatMessage({ id: msgId.analysisMenuAuthoritySettings })}
            </Typography>
          </MenuHeader>
          <MultiLineTypography
            text={intl.formatMessage({ id: msgId.analysisMenuAuthoritySettingsDescription })}
            variant="body2"
            align="left"
            sx={{ padding: '4px 4px 4px 8px' }}
          />
          <FileUplod
            id="FileUplodAnalysisMenuAuthority"
            acceptFileType=".csv"
            onAttachFile={handleAttachFileAnalysisMenuAuthority}
            onUpload={handleUploadAnalysisMenuAuthority}
            errorMessage={
              analysisMenuAccessAuthoritiesError
                ? intl.formatMessage({ id: msgId.fileIsNotSupported })
                : undefined
            }
            sx={{ marginRight: '8px' }}
          />
        </MenuContents>
      </CustomScrollBars>
      {updateResult != null && open && (
        <AlertDialog
          title={intl.formatMessage({ id: updateResult.isSuccess ? msgId.confirm : msgId.error })}
          message={intl.formatMessage({ id: updateResult.msgId })}
          onClose={() => handleResultClear()}
        />
      )}
    </Box>
  );
}
