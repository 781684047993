import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import {
  setOpen,
  setShipConfirmed,
  setShips,
  setShipCheck,
  setFilterShipName,
  setStartDate,
  setEndDate,
  setSearchConditionsEnabled,
  setScreeningMinutes,
  addCondition,
  removeCondition,
  updateCondition,
  setSelectedSearchResultItem,
  setSearchError,
  setConfirmedResultItem,
  setSearchResult,
  usePastDataSearchFilteredShips,
  usePastDataSearchShipConfirmed,
  searchSensorData,
  usePastDataSearchFilterShipName,
  usePastDataSearchCheckedMachines,
  usePastDataSearchSensors,
  usePastDataSearchConditions,
  usePastDataCanSearch,
  usePastDataSearching,
  usePastDataSearchResults,
  usePastDataSearchError,
  usePastDataSearchSelectedSearchResultItem,
  usePastDataSearchConfirmedSearchResultItem,
} from './searchSlice';
import colors from 'resources/colors';
import msgId from 'resources/intl';
import { useIntl } from 'react-intl';
import { NavigationBackBar } from 'components/NavigationBackBar';
import { CustomScrollBars } from 'components/CustomScrollBars';
import { SearchConditionItem } from './SearchConditionItem';
import { FadeLoading } from 'components/FadeLoading';
import { SearchShipList } from 'components/search/SearchShipList';
import { SearchResultList } from 'components/search/SearchResultList';
import AppLogger from 'utils/AppLogger';
import { useCommonShips } from 'app/commonSlice';
import { SearchResult, SearchResultItem } from 'models/search';
import { ApiErrorDialog } from 'components/ApiErrorDialog';
import {
  Box,
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  RadioProps,
  styled,
  TextField,
  Typography,
} from '@mui/material';
import { theme } from 'resources/theme';
import dimens from 'resources/dimens';
import { ContentsHeader } from 'components/ContentsHeader';
import { DatePicker } from 'components/DatePicker';
import constants from 'resources/constants';
import { AddCircle, CheckCircle } from '@mui/icons-material';
import { NormalButton } from 'components/NormalButton';
import {
  addChart,
  removeChart,
  setConfirmedCondition,
  ConfirmedTrendChartCondition,
  setCopyToTempContexts,
} from '../chart/trend/trendChartSlice';
import { saveRange } from '../timeSlider/timeSliderSlice';
import {
  setCommonSensorData,
  setShipWaveData,
  setTrendOnly,
  usePastDataCurrentThinnedConditions,
} from '../pastDataSlice';
import { resetMap } from '../map/mapSlice';

dayjs.extend(utc);

const RootDiv = styled('div')({
  width: '100%',
  height: '100%',
  zIndex: theme.zIndex.drawer + 10,
  background: colors.subDrawer.background,
});

const GridContents = styled(Grid)({
  padding: 0,
  height: '100%',
  '& > div': {
    overflow: 'hidden',
  },
});

const GridConditions = styled(Grid)({
  width: 600,
  minWidth: 600,
});

const GridItem = styled(Grid)({
  width: '100%',
  '&:last-child': {
    height: '100%',
  },
});

const DateRow = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  width: '100%',
  background: theme.palette.background.default,
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),
});

const SearchConditionRow = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  width: '100%',
  background: theme.palette.background.default,
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1),
  paddingTop: theme.spacing(0.5),
  paddingBottom: theme.spacing(0.5),
});

const FooterDiv = styled('div')({
  width: '100%',
  padding: theme.spacing(1),
  textAlign: 'center',
});

const StyledRadio = styled((props: RadioProps) => (
  <Radio size="small" color="primary" {...props} />
))({
  width: 24,
  height: 24,
  marginRight: theme.spacing(0.5),
});

interface SearchFragmentProp {
  machineId?: number;
  condition: ConfirmedTrendChartCondition | undefined;
}

export function SearchFragment(props: SearchFragmentProp): JSX.Element {
  AppLogger.debug('  CALL: sensorData/search/SearchFragment');
  const { machineId, condition } = props;
  const intl = useIntl();
  const dispatch = useDispatch();
  const ships = useCommonShips();
  const shipConfirmed = usePastDataSearchShipConfirmed();
  const filteredShips = usePastDataSearchFilteredShips();
  const filterShipName = usePastDataSearchFilterShipName();
  const checkedMachines = usePastDataSearchCheckedMachines();
  const sensors = usePastDataSearchSensors();
  const searchConditions = usePastDataSearchConditions();
  const canSearch = usePastDataCanSearch();
  const searching = usePastDataSearching();
  const searchResults = usePastDataSearchResults();
  const searchError = usePastDataSearchError();
  const selectedSearchResultItem = usePastDataSearchSelectedSearchResultItem();
  const selectedConfirmedSearchResultItem = usePastDataSearchConfirmedSearchResultItem();
  const currentThinnedConditions = usePastDataCurrentThinnedConditions();

  let filteredSearchResults: SearchResult[] = [];
  if (searchResults != null) {
    filteredSearchResults = searchResults.filter((x) => x.results.length > 0);
  }

  let isMixedMachinesExist = false;
  let is400401Exist = false;
  let is402Exist = false;
  checkedMachines.forEach((machine) => {
    if (machine.dataFormatId !== 402) {
      is400401Exist = true
    }
    if (machine.dataFormatId === 402) {
      is402Exist = true
    }
  });
  if (is400401Exist && is402Exist) {
    isMixedMachinesExist = true
  }

  /**
   * バックボタン押下
   */
  const handleBackClick = () => {
    dispatch(setOpen(false));
  };

  /**
   * 船舶リストのチェック状態変更
   * @param shipId 船舶ID
   * @param machineId 機械ID
   * @param checked チェック状態
   */
  const handleCheckChange = (shipId: string, machineId: number, checked: boolean) => {
    dispatch(setShipCheck({ shipId, machineId, checked }));
  };

  /**
   * 検索入力変更
   * @param input 入力値
   */
  const handleSeachInputChange = (input: string) => {
    dispatch(setFilterShipName(input));
  };

  /**
   * 船舶検索条件決定
   */
  const handleShipOkClick = () => {
    dispatch(setShipConfirmed(true));
  };

  /**
   * 開始日付の変更
   * @param value 日付
   */
  const handleStartDateChange = (value: string | undefined) => {
    if (value != null) {
      try {
        dispatch(setStartDate(value));
      } catch (e) {
        dispatch(setStartDate(dayjs().add(-1, 'day').format(constants.dateFormat.iso8601)));
      }
    }
  };

  /**
   * 終了日付の変更
   * @param value 日付
   */
  const handleEndDateChange = (value: string | undefined) => {
    if (value != null) {
      try {
        dispatch(setEndDate(value));
      } catch (e) {
        dispatch(setEndDate(dayjs().format(constants.dateFormat.iso8601)));
      }
    }
  };

  /**
   * 検索条件の有効無効変更
   * @param event イベント
   */
  const handleSearchConditionsEnabledChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setSearchConditionsEnabled((event.target as HTMLInputElement).value === 'true'));
  };

  /**
   * スクリーニング単位変更
   * @param event イベント
   */
  const handleScreeningMinutesChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    dispatch(setScreeningMinutes(Number(event.target.value)));
  };

  /**
   * 検索条件追加
   */
  const handleAddCondition = () => {
    if (sensors.length > 0) {
      const filterSensors = sensors.filter((sensor) => {
        let ret = true;
        searchConditions.conditions.forEach((condition) => {
          if (sensor.sensorName === condition.sensorName) {
            ret = false;
          }
        });

        return ret;
      });
      dispatch(
        addCondition({
          id: uuidv4(),
          sensorName: filterSensors[0].sensorName,
          lowerValue: filterSensors[0].displayLowerLimit,
          upperValue: filterSensors[0].displayUpperLimit,
          lowerLimit: filterSensors[0].displayLowerLimit,
          upperLimit: filterSensors[0].displayUpperLimit,
          order: 0,
          invalidLowerLimit: false,
          invalidUpperLimit: false,
        })
      );
    }
  };

  /**
   * 検索条件削除
   * @param id 検索条件ID
   */
  const handleRemoveCondition = (id: string) => {
    dispatch(removeCondition(id));
  };

  /**
   * 検索条件変更
   * @param id 検索条件ID
   */
  const handleChangeCondition = (
    id: string,
    sensorName: string,
    upperLimit: number,
    lowerLimit: number,
    upperValue: number,
    lowerValue: number
  ) => {
    dispatch(updateCondition({ id, sensorName, upperLimit, lowerLimit, upperValue, lowerValue }));
  };

  /**
   * 検索実行
   */
  const handleSearchClick = () => {
    dispatch(setSearchResult(undefined));
    dispatch(searchSensorData(checkedMachines, searchConditions));
  };

  /**
   * 検索エラー
   */
  const handleSearchError = () => {
    dispatch(setSearchError(undefined));
  };

  /**
   * 検索結果選択変更
   */
  const handleSearchResultItemChange = (searchResultItem: SearchResultItem) => {
    dispatch(setSelectedSearchResultItem(searchResultItem));
  };

  /**
   * 検索結果選択決定
   */
  const handleSelectResultClick = () => {
    dispatch(resetMap(true));
    dispatch(setCommonSensorData(undefined));
    dispatch(setShipWaveData(undefined));
    dispatch(setConfirmedResultItem(selectedSearchResultItem));
    dispatch(setOpen(false));
    if (selectedConfirmedSearchResultItem === undefined) {
      if (currentThinnedConditions != null && selectedSearchResultItem != null) {
        if (currentThinnedConditions.machineId != selectedSearchResultItem.machineId) {
          dispatch(setTrendOnly(false));
        }
      }
      dispatch(setCopyToTempContexts());
      dispatch(saveRange());
      if (!(machineId !== undefined && machineId === selectedSearchResultItem?.machineId)) {
        dispatch(
          addChart({ chartId: '0', title: intl.formatMessage({ id: msgId.trendDefaultTitle }) })
        );
      }
    } else if (
      selectedConfirmedSearchResultItem &&
      selectedConfirmedSearchResultItem.machine.machineId !== selectedSearchResultItem?.machineId
    ) {
      dispatch(setTrendOnly(false));
      dispatch(removeChart({ chartId: '0' }));
      dispatch(
        addChart({ chartId: '0', title: intl.formatMessage({ id: msgId.trendDefaultTitle }) })
      );
    }
    if (
      selectedSearchResultItem &&
      machineId !== undefined &&
      machineId === selectedSearchResultItem.machineId
    ) {
      if (condition) {
        dispatch(
          setConfirmedCondition({
            chartId: condition.chartId,
            machine: condition.machine,
            sensorNames: condition.sensorNames,
            applied: false,
            thinnedData: false,
            startDate: selectedSearchResultItem.startDate,
            endDate: selectedSearchResultItem.endDate,
          })
        );
      }
    }
  };

  useEffect(() => {
    if (ships != null) {
      dispatch(setShips(ships));
    }
  }, [dispatch, ships]);

  return (
    <RootDiv>
      <NavigationBackBar
        title={intl.formatMessage({ id: msgId.changeSearchConditions })}
        handleBackClick={handleBackClick}
        canBack={true}
      />
      <CustomScrollBars autoHide={false} height={`calc(100% - ${dimens.searchBar.height}px)`}>
        <GridContents container wrap="nowrap">
          {/* 船舶選択 */}
          <SearchShipList
            ships={filteredShips}
            checkedMachines={checkedMachines}
            filterShipName={filterShipName}
            onSearchInputChange={handleSeachInputChange}
            onCheckChange={handleCheckChange}
            onOkClick={handleShipOkClick}
          />
          <Divider orientation="vertical" flexItem />
          {/* 検索条件設定 */}
          <GridConditions container item direction="column" wrap="nowrap">
            <GridItem item>
              <ContentsHeader
                title={intl.formatMessage({ id: msgId.dataSearchConditionSetting })}
              />
              {shipConfirmed && (
                <React.Fragment>
                  <DateRow>
                    <Typography
                      variant="body2"
                      noWrap
                      sx={{ paddingLeft: theme.spacing(1), color: colors.accent }}
                    >
                      {intl.formatMessage({ id: msgId.periodSearch })}
                    </Typography>
                    <DatePicker
                      value={searchConditions.startDate}
                      type="datetime"
                      displayFormat={constants.dateFormat.YYYYMMDDHHmm}
                      onValueChanged={handleStartDateChange}
                      error={searchConditions.invalidStartDate}
                    />
                    <Typography variant="body2" sx={{ paddingTop: '2px' }}>
                      -
                    </Typography>
                    <DatePicker
                      value={searchConditions.endDate}
                      type="datetime"
                      displayFormat={constants.dateFormat.YYYYMMDDHHmm}
                      onValueChanged={handleEndDateChange}
                      error={searchConditions.invalidEndDate}
                    />
                  </DateRow>
                  <SearchConditionRow sx={{ background: colors.subDrawer.background }}>
                    <FormControl
                      component="fieldset"
                      sx={{
                        width: '100%',
                        paddingTop: theme.spacing(0.5),
                        paddingLeft: theme.spacing(1),
                        paddingRight: theme.spacing(1),
                      }}
                    >
                      <RadioGroup
                        name="searchConditionsEnabled"
                        value={searchConditions.enabled ? 'true' : 'false'}
                        onChange={handleSearchConditionsEnabledChange}
                      >
                        <FormControlLabel
                          value="false"
                          control={<StyledRadio />}
                          componentsProps={{
                            typography: {
                              variant: 'body2',
                            },
                          }}
                          label={intl.formatMessage({ id: msgId.searchConditionsDisabled })}
                        />
                        <FormControlLabel
                          value="true"
                          control={<StyledRadio />}
                          componentsProps={{
                            typography: {
                              variant: 'body2',
                            },
                          }}
                          label={intl.formatMessage({ id: msgId.searchConditionsEnabled })}
                        />
                      </RadioGroup>
                    </FormControl>
                  </SearchConditionRow>
                </React.Fragment>
              )}
            </GridItem>
            <GridItem item>
              {shipConfirmed && (
                <CustomScrollBars autoHide={false}>
                  {searchConditions.enabled && (
                    <React.Fragment>
                      <SearchConditionRow>
                        <FormControl
                          sx={{
                            minWidth: 160,
                            paddingTop: theme.spacing(0.5),
                            paddingBottom: theme.spacing(0.5),
                          }}
                        >
                          <TextField
                            id="screening-unit"
                            select
                            label={intl.formatMessage({ id: msgId.screeningUnits })}
                            value={searchConditions.screeningMinutes}
                            onChange={handleScreeningMinutesChange}
                            variant="standard"
                          >
                            <MenuItem value={1}>
                              1{intl.formatMessage({ id: msgId.minute })}
                            </MenuItem>
                            <MenuItem value={5}>
                              5{intl.formatMessage({ id: msgId.minutes })}
                            </MenuItem>
                            <MenuItem value={10}>
                              10{intl.formatMessage({ id: msgId.minutes })}
                            </MenuItem>
                            <MenuItem value={30}>
                              30{intl.formatMessage({ id: msgId.minutes })}
                            </MenuItem>
                            <MenuItem value={60}>
                              1{intl.formatMessage({ id: msgId.hour })}
                            </MenuItem>
                          </TextField>
                        </FormControl>
                        <Typography variant="body2" sx={{ paddingLeft: theme.spacing(1) }}>
                          {intl.formatMessage({ id: msgId.guideScreeningUnits })}
                        </Typography>
                      </SearchConditionRow>
                      <Divider />
                      {searchConditions.conditions.map((condition) => {
                        const filterSensors = sensors.filter((sensor) => {
                          let ret = true;
                          searchConditions.conditions.forEach((item) => {
                            if (
                              condition.sensorName !== item.sensorName &&
                              sensor.sensorName === item.sensorName
                            ) {
                              ret = false;
                            }
                          });

                          return ret;
                        });

                        return (
                          <React.Fragment key={condition.id}>
                            <SearchConditionItem
                              sensors={filterSensors}
                              condition={condition}
                              onClickDelete={handleRemoveCondition}
                              onChange={handleChangeCondition}
                            />
                            <Divider />
                          </React.Fragment>
                        );
                      })}
                      <Box
                        sx={{
                          padding: 0,
                          background: theme.palette.background.default,
                        }}
                      >
                        <Button
                          variant="text"
                          color="primary"
                          disabled={searchConditions.conditions.length >= 10 || isMixedMachinesExist}
                          startIcon={<AddCircle />}
                          onClick={handleAddCondition}
                          sx={{
                            width: '100%',
                            borderRadius: 0,
                          }}
                        >
                          <Typography variant="body1" noWrap>
                            {intl.formatMessage({ id: msgId.add })}
                          </Typography>
                        </Button>
                      </Box>
                    </React.Fragment>
                  )}
                  <Divider />
                  <FooterDiv>
                    <NormalButton
                      label={intl.formatMessage({ id: msgId.decision })}
                      color="primary"
                      disabled={!canSearch}
                      startIcon={<CheckCircle />}
                      onClick={() => handleSearchClick()}
                    />
                  </FooterDiv>
                </CustomScrollBars>
              )}
            </GridItem>
          </GridConditions>
          <Divider orientation="vertical" flexItem />
          {/* 検索結果 */}
          <SearchResultList
            searchResults={searchResults && filteredSearchResults}
            selectedItem={selectedSearchResultItem}
            dateFormat={'YYYY/MM/DD HH:mm:ss.SSS'}
            dateOnly={false}
            onChange={handleSearchResultItemChange}
            onOkClick={handleSelectResultClick}
          />
        </GridContents>
      </CustomScrollBars>
      <FadeLoading loading={searching} />
      {searchError != null && <ApiErrorDialog error={searchError} onClose={handleSearchError} />}
    </RootDiv>
  );
}
