import AppLogger from 'loglevel';
import config from 'resources/config';

if (process.env.NODE_ENV === 'development') {
  AppLogger.setLevel('trace');
} else {
  AppLogger.setLevel(config.valueInspectionLog ? 'info' : 'error');
}

export default AppLogger;
