import React, { useEffect, useRef } from 'react';
import { useIntl } from 'react-intl';
import colors from 'resources/colors';
import { DisplayInfo } from 'models/analysis';
import { ChartHeaderPanel } from 'components/chart/ChartHeaderPanel';
import { LegendGroup, ChartLegendList } from '../common/ChartLegendList';
import {
  RexPropellerInvolvementChart,
  PropellerGraphType,
  hoverInfoText,
  getYaxis,
} from './RexPropellerInvolvementChart';
import { ChartContainer, ChartDivider } from '../common/ChartContainer';
import { CustomScrollBars } from 'components/CustomScrollBars';
import msgId from 'resources/intl';
import { useDispatch } from 'react-redux';
import {
  setRexPropellerInvolvementExpanded,
  useAnalysisData,
  useAnalysisRexPropellerInvolvementExpanded,
} from 'features/contents/analysis/analysisSlice';
import Plotly, { PlotMouseEvent } from 'plotly.js';
import { styled } from '@mui/material';
import dimens from 'resources/dimens';
import { roundValueToString } from 'utils/misc';
import { useAnalysisSearchConfirmedSearchResultItem } from '../../search/searchSlice';
import config from 'resources/config';
import { dumpAnalysisRexPropellerForeignMatterEntrainment } from 'utils/dumpData';
import dayjs from 'dayjs';
import constants from 'resources/constants';

const RootDiv = styled('div')({
  height: '100%',
  width: '100%',
});

const ContainerDiv = styled('div')({
  height: '100%',
  width: '100%',
});

const ContentsDiv = styled('div')({
  width: '100%',
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'flex-start',
  background: colors.chart.legend.background,
  borderBottom: `1px solid ${colors.chart.divider}`,
  zIndex: 2,
  overflow: 'hidden',
});

const FooterContentsDiv = styled('div')({
  width: '100%',
  height: 50,
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'flex-start',
  background: colors.chart.legend.background,
});

interface RexPropellerInvolvementContainerProps {
  chartId: string;
  hidden: boolean;
}
interface HoverInfo {
  titleId: string;
  dataL: number[];
  dataR: number[];
  infoL: DisplayInfo;
  infoR: DisplayInfo;
}
interface GraphHoverData {
  [key: string]: HoverInfo[];
}

let graphTypes: PropellerGraphType[];

export function RexPropellerInvolvementContainer(
  props: RexPropellerInvolvementContainerProps
): JSX.Element {
  const { chartId, hidden } = props;
  const intl = useIntl();
  const dispatch = useDispatch();
  const confirmedSearchResultItem = useAnalysisSearchConfirmedSearchResultItem();
  const analysisData = useAnalysisData();
  const expanded = useAnalysisRexPropellerInvolvementExpanded();
  const contents = analysisData?.data.rexPropellerForeignMatterEntrainment;
  const mapWindow = useRef<Window | null>(null);

  if (analysisData?.dataFormatId !== 402) {
    graphTypes = [
      'analysis_propeller_change_score',
      'MimicMegFuelRackLvP1',
      'MimicMegTCSpdP1',
      'RexLtStrPressB',
      'RexRtStrPressB',
      'MimicRexCPPBladeAngOrderP1',
      'MimicRexCPPBladeAngFBP1',
      'MimicRexStrAngOrderP1',
      'MimicRexStrAngFBP1',
    ];
  } else {
    graphTypes = [
      'analysis_propeller_change_score',
      'MimicMegFuelRackLvP1',
      'MimicMegTCSpdP1',
      'RexLtStrPressB',
      'RexRtStrPressB',
      'MimicRexStrAngOrderP1',
      'MimicRexStrAngFBP1',
    ];
  }

  useEffect(() => {
    const windoUnload = (ev: BeforeUnloadEvent) => {
      ev.preventDefault();
      if (mapWindow.current) {
        mapWindow.current.close();
        mapWindow.current = null;
      }
    };

    window.addEventListener('beforeunload', windoUnload);

    return (): void => {
      window.removeEventListener('beforeunload', windoUnload);
    };
  }, []);

  useEffect(() => {
    const forceShutdownLogout = () => {
      document.removeEventListener('forceShutdown', forceShutdownLogout, true);
      if (mapWindow.current) {
        mapWindow.current.close();
        mapWindow.current = null;
      }
    };
    document.addEventListener('forceShutdown', forceShutdownLogout, true);

    return (): void => {
      window.removeEventListener('forceShutdown', forceShutdownLogout);
    };
  }, []);

  if (confirmedSearchResultItem == null || analysisData == null || contents == null || hidden) {
    if (mapWindow.current) {
      mapWindow.current.close();
      mapWindow.current = null;
    }

    return <div></div>;
  }

  if (config.valueInspectionLog) {
    dumpAnalysisRexPropellerForeignMatterEntrainment(analysisData);
  }

  /**
   * CSV出力
   */
  const handleCsvOutput = () => {
    // noop
  };

  /**
   * 開閉状態変更
   * @param expanded 開閉状態
   */
  const handleExpandChanged = (expanded: boolean) => {
    dispatch(setRexPropellerInvolvementExpanded(expanded));
  };

  /**
   * 凡例リスト
   */
  const legendGroups: LegendGroup[] = graphTypes.map((type, index) => {
    let mimicMegSpdP1: string;
    if (analysisData.dataFormatId !== 402) {
      mimicMegSpdP1 = msgId.MimicMegSpdP1;
    } else {
      mimicMegSpdP1 = msgId.MimicEngSpdP;
    }
    let selectedType: string = type;
    if (analysisData.dataFormatId === 402) {
      switch (type) {
        case 'MimicMegFuelRackLvP1':
          selectedType = 'MimicEngFuelRackLvP';
          break;
        case 'MimicMegTCSpdP1':
          selectedType = 'MimicEngTcSpdP';
          break;
        case 'RexLtStrPressB':
          selectedType = 'RexLtStrPress';
          break;
        case 'RexRtStrPressB':
          selectedType = 'RexRtStrPress';
          break;
        case 'MimicRexStrAngOrderP1':
          selectedType = 'MimicRexStrAngOrderP';
          break;
        case 'MimicRexStrAngFBP1':
          selectedType = 'MimicRexStrAngFbP';
          break;
      }
    }

    return index === 0
      ? {
          groupName: analysisData.shipName + ' - ' + analysisData.machineName,
          legends: [
            {
              /** 主機回転数A */
              title: intl.formatMessage({ id: mimicMegSpdP1 }),
              color: colors.chart.lines[0],
              iconType: 'line',
            },
            {
              /** 変化点スコア */
              title: intl.formatMessage({ id: selectedType }),
              color: colors.chart.lines[1],
              iconType: 'circle',
            },
          ],
        }
      : {
          groupName: '',
          legends: [
            {
              /** 変化点スコア */
              title: intl.formatMessage({ id: selectedType }),
              color: colors.chart.lines[0],
              iconType: 'line',
            },
          ],
        };
  });

  const analysisName =
    intl.formatMessage({
      id:
        'AnalysisSection' +
        analysisData.data.rexPropellerForeignMatterEntrainment?.analysisSectionName,
    }) +
    ' : ' +
    intl.formatMessage({
      id: 'AnalysisMenu' + analysisData.data.rexPropellerForeignMatterEntrainment?.analysisMenuName,
    });

  let graphIds: PropellerGraphType[];
  if (analysisData.dataFormatId !== 402) {
    graphIds = [
      'analysis_propeller_change_score',
      'MimicMegFuelRackLvP1',
      'MimicMegTCSpdP1',
      'RexLtStrPressB',
      'RexRtStrPressB',
      'MimicRexCPPBladeAngOrderP1',
      'MimicRexCPPBladeAngFBP1',
      'MimicRexStrAngOrderP1',
      'MimicRexStrAngFBP1',
    ];
  } else {
    graphIds = [
      'analysis_propeller_change_score',
      'MimicMegFuelRackLvP1',
      'MimicMegTCSpdP1',
      'RexLtStrPressB',
      'RexRtStrPressB',
      'MimicRexStrAngOrderP1',
      'MimicRexStrAngFBP1',
    ];
  }

  let graphHoverData: GraphHoverData;
  if (analysisData.dataFormatId !== 402) {
    graphHoverData = {
      analysis_propeller_change_score: [
        {
          titleId: msgId.MimicMegSpdS1,
          dataL: contents.sensorData.MegSpdP1,
          dataR: contents.sensorData.MegSpdS1,
          infoL: contents.displayInformations.MegSpd,
          infoR: contents.displayInformations.MegSpd,
        },
        {
          titleId: 'analysis_propeller_change_score',
          dataL: contents.sensorData.MahalanobisP1,
          dataR: contents.sensorData.MahalanobisS1,
          infoL: contents.displayInformations.Mahalanobis,
          infoR: contents.displayInformations.Mahalanobis,
        },
        {
          titleId: 'analysis_propeller_change_score',
          dataL: contents.sensorData.MahalanobisAlertP1,
          dataR: contents.sensorData.MahalanobisAlertS1,
          infoL: contents.displayInformations.Mahalanobis,
          infoR: contents.displayInformations.Mahalanobis,
        },
      ],
      MimicMegFuelRackLvP1: [
        {
          titleId: 'MimicMegFuelRackLvP1',
          dataL: contents.sensorData.MegFuelRackLvP1,
          dataR: contents.sensorData.MegFuelRackLvS1,
          infoL: contents.displayInformations.MegFuelRackLv,
          infoR: contents.displayInformations.MegFuelRackLv,
        },
      ],
      MimicMegTCSpdP1: [
        {
          titleId: 'MimicMegTCSpdP1',
          dataL: contents.sensorData.MegTCSpdP1,
          dataR: contents.sensorData.MegTCSpdS1,
          infoL: contents.displayInformations.MegTCSpd,
          infoR: contents.displayInformations.MegTCSpd,
        },
      ],
      RexLtStrPressB: [
        {
          titleId: 'RexLtStrPressB',
          dataL: contents.sensorData.RexLtStrPressP1RIO,
          dataR: contents.sensorData.RexLtStrPressS1RIO,
          infoL: contents.displayInformations.RexLtStrPressRIO,
          infoR: contents.displayInformations.RexLtStrPressRIO,
        },
      ],
      RexRtStrPressB: [
        {
          titleId: 'RexRtStrPressB',
          dataL: contents.sensorData.RexRtStrPressP1RIO,
          dataR: contents.sensorData.RexRtStrPressS1RIO,
          infoL: contents.displayInformations.RexRtStrPressRIO,
          infoR: contents.displayInformations.RexRtStrPressRIO,
        },
      ],
      MimicRexCPPBladeAngOrderP1: [
        {
          titleId: 'MimicRexCPPBladeAngOrderP1',
          dataL: contents.sensorData.RexCPPBladeAngOrderP1,
          dataR: contents.sensorData.RexCPPBladeAngOrderS1,
          infoL: contents.displayInformations.RexCPPBladeAngOrder,
          infoR: contents.displayInformations.RexCPPBladeAngOrder,
        },
      ],
      MimicRexCPPBladeAngFBP1: [
        {
          titleId: 'MimicRexCPPBladeAngFBP1',
          dataL: contents.sensorData.RexCPPBladeAngFBP1,
          dataR: contents.sensorData.RexCPPBladeAngFBS1,
          infoL: contents.displayInformations.RexCPPBladeAngFB,
          infoR: contents.displayInformations.RexCPPBladeAngFB,
        },
      ],
      MimicRexStrAngOrderP1: [
        {
          titleId: 'MimicRexStrAngOrderP1',
          dataL: contents.sensorData.RexStrAngOrderP1,
          dataR: contents.sensorData.RexStrAngOrderS1,
          infoL: contents.displayInformations.RexStrAngOrder,
          infoR: contents.displayInformations.RexStrAngOrder,
        },
      ],
      MimicRexStrAngFBP1: [
        {
          titleId: 'MimicRexStrAngFBP1',
          dataL: contents.sensorData.RexStrAngFBP1,
          dataR: contents.sensorData.RexStrAngFBS1,
          infoL: contents.displayInformations.RexStrAngFB,
          infoR: contents.displayInformations.RexStrAngFB,
        },
      ],
    };
  } else {
    graphHoverData = {
      analysis_propeller_change_score: [
        {
          titleId: msgId.MimicEngSpdS,
          dataL: contents.sensorData.EngSpdP,
          dataR: contents.sensorData.EngSpdS,
          infoL: contents.displayInformations.EngSpd,
          infoR: contents.displayInformations.EngSpd,
        },
        {
          titleId: 'analysis_propeller_change_score',
          dataL: contents.sensorData.MahalanobisP1,
          dataR: contents.sensorData.MahalanobisS1,
          infoL: contents.displayInformations.Mahalanobis,
          infoR: contents.displayInformations.Mahalanobis,
        },
        {
          titleId: 'analysis_propeller_change_score',
          dataL: contents.sensorData.MahalanobisAlertP1,
          dataR: contents.sensorData.MahalanobisAlertS1,
          infoL: contents.displayInformations.Mahalanobis,
          infoR: contents.displayInformations.Mahalanobis,
        },
      ],
      MimicMegFuelRackLvP1: [
        {
          titleId: 'MimicEngFuelRackLvP',
          dataL: contents.sensorData.EngFuelRackLvP,
          dataR: contents.sensorData.EngFuelRackLvS,
          infoL: contents.displayInformations.EngFuelRackLv,
          infoR: contents.displayInformations.EngFuelRackLv,
        },
      ],
      MimicMegTCSpdP1: [
        {
          titleId: 'MimicEngTcSpdP',
          dataL: contents.sensorData.EngTcSpdP,
          dataR: contents.sensorData.EngTcSpdS,
          infoL: contents.displayInformations.EngTcSpd,
          infoR: contents.displayInformations.EngTcSpd,
        },
      ],
      RexLtStrPressB: [
        {
          titleId: 'RexLtStrPress',
          dataL: contents.sensorData.RexLtStrPressP,
          dataR: contents.sensorData.RexLtStrPressS,
          infoL: contents.displayInformations.RexLtStrPress,
          infoR: contents.displayInformations.RexLtStrPress,
        },
      ],
      RexRtStrPressB: [
        {
          titleId: 'RexRtStrPress',
          dataL: contents.sensorData.RexRtStrPressP,
          dataR: contents.sensorData.RexRtStrPressS,
          infoL: contents.displayInformations.RexRtStrPress,
          infoR: contents.displayInformations.RexRtStrPress,
        },
      ],
      MimicRexStrAngOrderP1: [
        {
          titleId: 'MimicRexStrAngOrderP',
          dataL: contents.sensorData.RexStrAngOrderP,
          dataR: contents.sensorData.RexStrAngOrderS,
          infoL: contents.displayInformations.RexStrAngOrder,
          infoR: contents.displayInformations.RexStrAngOrder,
        },
      ],
      MimicRexStrAngFBP1: [
        {
          titleId: 'MimicRexStrAngFbP',
          dataL: contents.sensorData.RexStrAngFbP,
          dataR: contents.sensorData.RexStrAngFbS,
          infoL: contents.displayInformations.RexStrAngFb,
          infoR: contents.displayInformations.RexStrAngFb,
        },
      ],
    };
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let oldLayout: { [key: string]: any } = {};
  function onRelayout(elementId: string, event: Readonly<Plotly.PlotRelayoutEvent>) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const _event = event as { [key: string]: any };
    let relayouting = false;
    let yAxisAutorange = false;
    let y2axis: { [key: string]: number } = {};
    const keys = Object.keys(event);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let relayout: { [key: string]: any } = {};
    keys.forEach((key) => {
      switch (key) {
        case 'xaxis.range[0]':
          relayout = { ...relayout, 'xaxis.range[0]': event[key] };
          break;
        case 'xaxis.range[1]':
          relayout = { ...relayout, 'xaxis.range[1]': event[key] };
          break;
        case 'yaxis2.range[0]':
          y2axis = { ...y2axis, 'yaxis2.range[0]': _event[key] };
          break;
        case 'yaxis2.range[1]':
          y2axis = { ...y2axis, 'yaxis2.range[1]': _event[key] };
          break;
        case 'xaxis.range':
          relayout = { ...relayout, 'xaxis.range': event[key] };
          break;
        case 'xaxis.autorange':
          relayout = { ...relayout, 'xaxis.autorange': event[key] };
          break;
        case 'yaxis.autorange':
          if (event[key] === true) {
            yAxisAutorange = true;
          }
      }
    });
    relayouting = true;
    // relayoutするか判断する。
    if (yAxisAutorange) {
      // yaxis.autorangeはDouble clickの時なので無条件で実行
      relayouting = false;
    } else {
      Object.keys(relayout).forEach((key) => {
        // 初回(oldLayoutに値がない)、値の不一致時にrelayoutを実行する。（keyの情報が一致しない、値が異なる）
        if (oldLayout === {}) {
          relayouting = false;
        } else if (oldLayout[key] === undefined) {
          relayouting = false;
        } else if (
          key === 'xaxis.range' &&
          (oldLayout[key][0] !== relayout[key][0] || oldLayout[key][1] !== relayout[key][1])
        ) {
          relayouting = false;
        } else if (key !== 'xaxis.range' && oldLayout[key] !== relayout[key]) {
          relayouting = false;
        }
        if (relayouting === false) {
          return;
        }
      });
      // xaxis.rangeの時は配列なので単純な比較だと不一致になるのでそれぞれで比較
      if (
        relayouting &&
        Object.keys(event).includes('yaxis.range[0]') &&
        (oldLayout['yaxis.range[0]'] !== event['yaxis.range[0]'] ||
          oldLayout['yaxis.range[1]'] !== event['yaxis.range[1]'])
      ) {
        relayouting = false;
      }
      // yaxis2.rangeの時は配列なので単純な比較だと不一致になるのでそれぞれで比較
      if (
        relayouting &&
        Object.keys(event).includes('yaxis2.range[0]') &&
        (oldLayout['yaxis2.range[0]'] !== _event['yaxis2.range[0]'] ||
          oldLayout['yaxis2.range[1]'] !== _event['yaxis2.range[1]'])
      ) {
        relayouting = false;
      }
    }
    if (!relayouting) {
      oldLayout = Object.assign({}, relayout);
      oldLayout['yaxis.range[0]'] = event['yaxis.range[0]'];
      oldLayout['yaxis.range[1]'] = event['yaxis.range[1]'];
      oldLayout['yaxis2.range[0]'] = _event['yaxis2.range[0]'];
      oldLayout['yaxis2.range[1]'] = _event['yaxis2.range[1]'];
      const ids = graphIds.concat();
      ids.push('DateTimeTick');
      ids.forEach((graphId) => {
        const eventL = Object.assign({}, event) as Plotly.PlotRelayoutEvent;
        const eventR = Object.assign({}, event) as Plotly.PlotRelayoutEvent;
        if (graphId !== 'DateTimeTick') {
          graphHoverData[graphId].forEach((hoverData, index) => {
            if (yAxisAutorange) {
              if (index === 0) {
                eventL['yaxis.autorange'] = false;
                eventR['yaxis.autorange'] = false;
                eventL['yaxis.range'] = [hoverData.infoL.rangeMin, hoverData.infoL.rangeMax];
                eventR['yaxis.range'] = [hoverData.infoR.rangeMin, hoverData.infoR.rangeMax];
              } else if (index === 1) {
                eventL.yaxis2 = getYaxis(
                  index,
                  hoverData.infoL,
                  intl.formatMessage({ id: hoverData.titleId }),
                  graphId
                );
                eventR.yaxis2 = getYaxis(
                  index,
                  hoverData.infoR,
                  intl.formatMessage({ id: hoverData.titleId }),
                  graphId
                );
                if (Object.keys(y2axis).length > 0) {
                  eventL.yaxis2.range = [y2axis['yaxis2.range[0]'], y2axis['yaxis2.range[1]']];
                  eventR.yaxis2.range = [y2axis['yaxis2.range[0]'], y2axis['yaxis2.range[1]']];
                }
              }
            }
          });
        } else {
          // graphHoverDataに情報が無く、DateTimeTickの時のyaxis.autorangeにfalseを設定(ループ対策)
          if (yAxisAutorange) {
            eventL['yaxis.autorange'] = false;
            eventR['yaxis.autorange'] = false;
          }
        }
        const panel = 'RexPropellerInvolvement';
        const elementPlotL = 'PlotL' + panel + graphId;
        const elementPlotR = 'PlotR' + panel + graphId;
        if (elementId === elementPlotR) {
          Plotly.relayout(elementPlotL, eventL);
          if (yAxisAutorange) {
            Plotly.relayout(elementPlotR, eventR);
          }
        } else if (elementId === elementPlotL) {
          Plotly.relayout(elementPlotR, eventR);
          if (yAxisAutorange) {
            Plotly.relayout(elementPlotL, eventL);
          }
        } else {
          Plotly.relayout(elementPlotL, relayout);
          Plotly.relayout(elementPlotR, relayout);
        }
      });
    }
  }

  const setHoverInfo = (
    data: Readonly<PlotMouseEvent>,
    elementId: string,
    hoverText: string,
    index: number,
    width: number
  ) => {
    const div = document.getElementById(elementId);
    if (div) {
      const offsetY = data.event.offsetY > 160 ? 160 : data.event.offsetY;
      if (index === 0) {
        div.innerHTML = hoverText;
        if (100 < offsetY) {
          div.style.top = offsetY - 5 - (index + 1) * 40 + 'px';
        } else {
          div.style.top = offsetY + 5 + index * 40 + 'px';
        }
        if (width < data.event.offsetX + 180) {
          div.style.left = data.event.offsetX - 160 + 'px';
        } else {
          div.style.left = data.event.offsetX + 10 + 'px';
        }
        div.style.padding = '3px';
        div.style.pointerEvents = 'none';
      } else {
        let secondHoverText = hoverText;
        if (div.innerHTML.includes('<br>')) {
          secondHoverText = secondHoverText.substring(div.innerHTML.indexOf('<br>'));
          const secondIndex = 0;
          if (100 < offsetY) {
            div.style.top = offsetY - 5 - (secondIndex + 1) * 40 + 'px';
          } else {
            div.style.top = offsetY + 5 + secondIndex * 40 + 'px';
          }
          if (width < data.event.offsetX + 180) {
            div.style.left = data.event.offsetX - 160 + 'px';
          } else {
            div.style.left = data.event.offsetX + 10 + 'px';
          }
          div.style.padding = '3px';
          div.style.pointerEvents = 'none';
        }
        div.innerHTML = div.innerHTML + secondHoverText;
      }
    }
  };

  function onHover(data: Readonly<PlotMouseEvent>, graphType: PropellerGraphType) {
    const dragLayer = document.getElementsByClassName(
      'nsewdrag'
    ) as HTMLCollectionOf<HTMLDivElement>;
    if (
      dragLayer.length > 0 &&
      data.points &&
      (data.points[0].curveNumber === 1 || data.points[0].curveNumber === 2) &&
      graphType === 'analysis_propeller_change_score'
    ) {
      const pattern = /^\d*$/;
      Object.keys(dragLayer).forEach((index) => {
        if (pattern.test(index) === true && dragLayer[Number(index)]) {
          dragLayer[Number(index)].style.cursor = 'pointer';
        }
      });
    }
    const point = data.points[0];
    if (!contents) return;

    const dateString = dayjs(contents.sensorData.date[point.pointIndex])
      .utc()
      .format(constants.dateFormat.YYYYMMDDHHmmss);
    let hoverText: string;
    graphIds.forEach((graphId) => {
      const panel = 'RexPropellerInvolvement';
      const PlotL = 'PlotL' + panel + graphId;
      const PlotR = 'PlotR' + panel + graphId;
      const hoverIdL = PlotL + 'hover';
      const hoverIdR = PlotR + 'hover';
      const element = document.getElementById(PlotL);
      let width = 400;
      if (element) {
        width = element.clientWidth;
      }
      let offsetL = 0;
      let offsetR = 0;
      graphHoverData[graphId].forEach((hoverData, index) => {
        if (isNaN(hoverData.dataL[point.pointIndex]) === false) {
          hoverText = hoverInfoText(
            intl,
            dateString + '',
            intl.formatMessage({ id: hoverData.titleId }),
            roundValueToString(hoverData.dataL[point.pointIndex], hoverData.infoL.roundingPosition),
            hoverData.infoL.unit + ''
          );
          setHoverInfo(data, hoverIdL, hoverText, index - offsetL, width);
        } else {
          offsetL++;
        }
        if (isNaN(hoverData.dataR[point.pointIndex]) === false) {
          hoverText = hoverInfoText(
            intl,
            dateString + '',
            intl.formatMessage({ id: hoverData.titleId }),
            roundValueToString(hoverData.dataR[point.pointIndex], hoverData.infoR.roundingPosition),
            hoverData.infoR.unit + ''
          );
          setHoverInfo(data, hoverIdR, hoverText, index - offsetR, width);
        } else {
          offsetR++;
        }
      });
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  function onUnhover(event: Readonly<Plotly.PlotMouseEvent>) {
    const dragLayer = document.getElementsByClassName('nsewdrag')[0] as HTMLDivElement;
    if (dragLayer) {
      dragLayer.style.cursor = '';
    }
    graphIds.forEach((graphId) => {
      const panel = 'RexPropellerInvolvement';
      const PlotL = 'PlotL' + panel + graphId;
      const PlotR = 'PlotR' + panel + graphId;
      const hoverIdL = PlotL + 'hover';
      const hoverIdR = PlotR + 'hover';
      graphHoverData[graphId].forEach((hoverData, index) => {
        let e = document.getElementById(hoverIdL + (index === 0 ? '' : index));
        if (e) {
          e.innerHTML = '';
          e.style.padding = '0px';
        }
        e = document.getElementById(hoverIdR + (index === 0 ? '' : index));
        if (e) {
          e.innerHTML = '';
          e.style.padding = '0px';
        }
      });
    });
  }

  const onClick = (data: Readonly<PlotMouseEvent>, graphType: PropellerGraphType) => {
    if (
      data.points &&
      (data.points[0].curveNumber === 1 || data.points[0].curveNumber === 2) &&
      graphType === 'analysis_propeller_change_score'
    ) {
      const pointIndex = data.points[0].pointIndex;
      const d = new Date();
      d.setTime(contents.sensorData.date[pointIndex]);
      const dateString = d.toISOString().replace('T', ' ').split('.')[0];
      // 船の表示
      const sensorDataVesLatC1 = contents.sensorData.VesLatC1[pointIndex];
      const sensorDataVesLonC1 = contents.sensorData.VesLonC1[pointIndex];
      const sensorDataVesCourseTrueDirC1 = contents.sensorData.VesCourseTrueDirC1[pointIndex];
      if (mapWindow.current && !mapWindow.current.closed) {
        const message = JSON.stringify({
          shipName: analysisData.shipName,
          lat: String(sensorDataVesLatC1),
          lng: String(sensorDataVesLonC1),
          deg: String(sensorDataVesCourseTrueDirC1),
          date: dateString,
        });
        mapWindow.current.postMessage(message);
        // サブウィンドウにフォーカスを移動する
        // ブラウザ依存のため動作しない場合あり
        mapWindow.current.blur();
        window.focus();
        window.blur();
        mapWindow.current.focus();
      } else {
        const d = new Date();
        d.setTime(contents.sensorData.date[pointIndex]);
        // /map/:shipName/:lat/:lng/:deg/:date
        const urlString: string[] = [
          'map',
          encodeURI(analysisData.shipName),
          encodeURI(String(sensorDataVesLatC1)),
          encodeURI(String(sensorDataVesLonC1)),
          encodeURI(String(sensorDataVesCourseTrueDirC1)),
          encodeURI(dateString),
        ];
        const url = '/' + urlString.join('/');
        mapWindow.current = window.open(
          url,
          'map',
          'width=500,toolbar=no,menubar=no,scrollbars=yes'
        );
      }
    }
  };

  const onDoubleClick = (elementId: string) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const relayout: { [key: string]: any } = {};
    const utcOffset = new Date().getTimezoneOffset() * 60000;
    relayout['xaxis.range'] = [
      contents.sensorData.date[0] + utcOffset,
      contents.sensorData.date[contents.sensorData.date.length - 1] + utcOffset,
    ];
    relayout['yaxis.autorange'] = true;
    Plotly.relayout(elementId, relayout);
  };

  return (
    <RootDiv>
      <CustomScrollBars thumbColor={colors.scrollBar.thumb.dark} autoHide={false}>
        <ChartContainer
          chartId={chartId}
          shipName={analysisData.shipName}
          machineName={analysisData.machineName}
          analysisName={analysisName}
          confirmedSearchResultItem={confirmedSearchResultItem}
          title={analysisName}
          expanded={expanded}
        >
          <ContainerDiv>
            <ChartHeaderPanel
              chartId={chartId}
              sx={{ paddingRight: `${dimens.chart.legend.width}px` }}
              title={analysisName}
              titleEditable={false}
              csvOutputEnabled={false}
              csvOutputLocked={true}
            />
            {graphTypes.map((type, index) => {
              return (
                <ContentsDiv key={index}>
                  <RexPropellerInvolvementChart
                    graphType={type}
                    sx={{ width: `calc(100% - ${dimens.chart.legend.width}px - 1px)` }}
                    analysisData={analysisData}
                    onRelayout={onRelayout}
                    onHover={onHover}
                    onUnhover={onUnhover}
                    onClick={onClick}
                    onDoubleClick={onDoubleClick}
                  />
                  <ChartDivider orientation="vertical" flexItem />
                  <ChartLegendList legendGroups={[legendGroups[index]]} />
                </ContentsDiv>
              );
            })}
          </ContainerDiv>
          {expanded && (
            <FooterContentsDiv>
              <RexPropellerInvolvementChart
                graphType={'DateTimeTick'}
                sx={{ width: `calc(100% - ${dimens.chart.legend.width}px - 1px)` }}
                analysisData={analysisData}
                onRelayout={onRelayout}
                onHover={onHover}
                onUnhover={onUnhover}
                onDoubleClick={onDoubleClick}
              />
              <ChartDivider orientation="vertical" flexItem />
              <ChartLegendList legendGroups={[]} />
            </FooterContentsDiv>
          )}
        </ChartContainer>
      </CustomScrollBars>
    </RootDiv>
  );
}
