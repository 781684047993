import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import msgId from 'resources/intl';
import {
  setPasswordChangeOpen,
  changePassword,
  setPasswordChangeResult,
  useAccountPasswordChangeOpen,
  useAccountPasswordChangeResult,
  useAccountInProgress,
  useAccountCognitoUserInfo,
  accountChangePassword,
  useAccountPasswordChangeError,
  setPasswordChangeError,
} from './accountSlice';
import {
  EditDialog,
  EditDialogContents,
  EditDialogFooterButtonGrid,
  EditDialogInputFieldGrid,
  EditDialogRootGrid,
  EditDialogTitleGrid,
} from 'components/EditDialog';
import { useAccountInformationOpen } from 'features/main/accountInformation/accountInformationSlice';
import { Typography, SxProps } from '@mui/material';
import { PasswordTextField } from 'components/PasswordTextField';
import { NormalButton } from 'components/NormalButton';
import { FadeLoading } from 'components/FadeLoading';
import { AlertDialog } from 'components/AlertDialog';
import { MultiLineTypography } from 'components/MultiLineTypography';
import { theme } from 'resources/theme';
import { ErrorInfo, ErrorMessages, FieldLabels, getErrorMessage } from 'models/error';
import { CustomScrollBars } from 'components/CustomScrollBars';
import colors from 'resources/colors';
import dimens from 'resources/dimens';

const textFieldSxProps: SxProps = {
  paddingTop: theme.spacing(1),
};

export function AccountPasswordChangeDialog(): JSX.Element {
  const intl = useIntl();
  const dispatch = useDispatch();
  const initiate = !useAccountInformationOpen();
  const open = useAccountPasswordChangeOpen();
  const result = useAccountPasswordChangeResult();
  const passwordChangeError = useAccountPasswordChangeError();
  const inProgress = useAccountInProgress();
  const cognitoUserInfo = useAccountCognitoUserInfo();
  const [state, setState] = useState({
    nowPassword: '',
    newPassword: '',
    confirmNewPassword: '',
  });

  let errorMsg: ErrorMessages = {};
  let errors: ErrorInfo[] = [];
  const labels: FieldLabels = {
    PARAMETER_LENGTH_ERROR: {
      fallback: intl.formatMessage({ id: msgId.errorPasswordRequirement }),
    },
    PARAMETER_FORMAT_ERROR: { fallback: intl.formatMessage({ id: msgId.errorPasswordFormat }) },
    PASSWORD_MISMATCH_ERROR: {
      fallback: intl.formatMessage({ id: msgId.errorCurrentPasswordMismatch }),
    },
    PARAMETER_REQUIREMENTS_ERROR: {
      fallback: intl.formatMessage({ id: msgId.errorPasswordRequirement }),
    },
    PARAMETER_INVALID_CHARACTER_ERROR: {
      fallback: intl.formatMessage({ id: msgId.errorPasswordFormat }),
    },
    NOT_AUTHORIZED_ERROR: { fallback: intl.formatMessage({ id: msgId.errorLoginIdNotAuthorized }) },
    fallback: { fallback: intl.formatMessage({ id: msgId.errorMessageOther }) },
  };

  if (passwordChangeError) {
    if (Array.isArray(passwordChangeError.response)) {
      errors = passwordChangeError.response;
    } else {
      errors = [passwordChangeError.response];
    }
  }
  errorMsg = getErrorMessage(errors, labels);

  if (!state.nowPassword) {
    errorMsg['password'] = intl.formatMessage({ id: msgId.errorPasswordRequirement });
  }

  if (!state.newPassword) {
    errorMsg['newPassword'] = intl.formatMessage({ id: msgId.errorPasswordRequirement });
  } else if (state.newPassword !== state.confirmNewPassword) {
    errorMsg['newPassword'] = intl.formatMessage({ id: msgId.errorPasswordMismatch });
  }

  if (!state.confirmNewPassword) {
    errorMsg['confirmNewPassword'] = intl.formatMessage({ id: msgId.errorPasswordRequirement });
  }

  /**
   * バックボタン押下
   */
  const handleBackClick = () => {
    dispatch(setPasswordChangeOpen(false));
    dispatch(setPasswordChangeError(undefined));
    dispatch(setPasswordChangeResult(undefined));
  };

  /**
   * 送信ボタン押下時に呼ばれる。
   */
  const handleSendPassword = () => {
    if (cognitoUserInfo) {
      if (initiate) {
        dispatch(changePassword(cognitoUserInfo.userName, state.nowPassword, state.newPassword));
      } else {
        dispatch(accountChangePassword(state.nowPassword, state.newPassword));
      }
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setState({ ...state, [event.target.name]: event.target.value });
  };

  const handleResultClear = (success: boolean) => {
    dispatch(setPasswordChangeResult(undefined));
    if (success) {
      dispatch(setPasswordChangeOpen(false));
      dispatch(setPasswordChangeError(undefined));
    }
  };

  useEffect(() => {
    if (open) {
      setState({
        nowPassword: '',
        newPassword: '',
        confirmNewPassword: '',
      });
    }
  }, [open]);

  useEffect(() => {
    if (result != null && result.isSuccess) {
      if (result.step === 1) {
        // dispatch(setPasswordChangeResult(undefined));
      }
    }
  }, [dispatch, result]);

  return (
    <EditDialog
      id="accountPasswordResetDialog"
      open={open}
      onBackClick={handleBackClick}
      title={intl.formatMessage({
        id: msgId.accountChangePasswordTitle,
      })}
    >
      <EditDialogContents>
        <CustomScrollBars
          thumbColor={colors.scrollBar.thumb.light}
          width={dimens.dialogContents.width}
          autoHide={false}
        >
          <EditDialogRootGrid>
            {initiate && (
              <EditDialogTitleGrid sx={{ marginBottom: theme.spacing(2) }}>
                <Typography variant="h5">
                  {intl.formatMessage({ id: msgId.guideMessageInitialPassword1 })}
                </Typography>
              </EditDialogTitleGrid>
            )}
            <EditDialogTitleGrid>
              <MultiLineTypography
                variant="body1"
                align="center"
                text={intl.formatMessage({ id: msgId.guideMessageInitialPassword2 })}
              />
            </EditDialogTitleGrid>
            <EditDialogInputFieldGrid sx={textFieldSxProps}>
              <PasswordTextField
                id="nowPassword"
                fullWidth={true}
                label={intl.formatMessage({ id: msgId.accountChangePasswordNow })}
                autoComplete="password"
                value={state.nowPassword}
                onChange={handleChange}
                required={true}
                error={!!errorMsg['password']}
                helperText={errorMsg['password']}
              />
            </EditDialogInputFieldGrid>
            <EditDialogInputFieldGrid sx={textFieldSxProps}>
              <PasswordTextField
                id="newPassword"
                fullWidth={true}
                label={intl.formatMessage({ id: msgId.accountChangePasswordNew })}
                autoComplete="new-password"
                value={state.newPassword}
                onChange={handleChange}
                required={true}
                error={!!errorMsg['newPassword']}
                helperText={errorMsg['newPassword']}
              />
            </EditDialogInputFieldGrid>
            <EditDialogInputFieldGrid sx={textFieldSxProps}>
              <PasswordTextField
                id="confirmNewPassword"
                fullWidth={true}
                label={intl.formatMessage({ id: msgId.accountChangePasswordConfirm })}
                autoComplete="new-password"
                value={state.confirmNewPassword}
                onChange={handleChange}
                required={true}
                error={!!errorMsg['confirmNewPassword']}
                helperText={errorMsg['confirmNewPassword']}
              />
            </EditDialogInputFieldGrid>
            <EditDialogFooterButtonGrid>
              <NormalButton
                label={intl.formatMessage({ id: msgId.registration })}
                color="primary"
                onClick={() => handleSendPassword()}
                disabled={
                  !state.nowPassword ||
                  state.newPassword !== state.confirmNewPassword ||
                  !state.newPassword
                }
              />
            </EditDialogFooterButtonGrid>
          </EditDialogRootGrid>
        </CustomScrollBars>
      </EditDialogContents>
      <FadeLoading loading={inProgress} />
      {result != null && result.showDialog && result.msgId && (
        <AlertDialog
          title={intl.formatMessage({ id: result.isSuccess ? msgId.confirm : msgId.error })}
          message={intl.formatMessage({ id: result.msgId })}
          onClose={() => handleResultClear(result.isSuccess)}
        />
      )}
    </EditDialog>
  );
}
