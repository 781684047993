import React from 'react';
import { useIntl } from 'react-intl';
import { SensorGroup, Sensor, Machine } from 'models/ships';
import { SensorData, CommonSensorData } from 'models/data';
import colors from 'resources/colors';
import msgId from 'resources/intl';

import { ClutchPanel } from './ClutchPanel';
import { RexLoInfoPanel } from './RexLoInfoPanel';
import { SteeringInfoPanel } from './SteeringInfoPanel';
import { TcInfoPanel } from './TcInfoPanel';
import { MegInfoPanel } from './MegInfoPanel';
import { RexInfoPanel } from './RexInfoPanel';
import { RexPropellerInfoPanel } from './RexPropellerInfoPanel';
import { usePastDataTimeSliderCurrentIndex } from '../../timeSlider/timeSliderSlice';
import { useCommonShips } from 'app/commonSlice';
import { styled, SxProps } from '@mui/material';
import dimens from 'resources/dimens';
import config from 'resources/config';
import { dumpPastDataMimicSensorList } from 'utils/dumpData';

export interface SensorValue {
  value: number;
  sensor: Sensor;
  group: SensorGroup;
}

const circleSize = 5;

const RootDiv = styled('div')({
  background: colors.chart.header.background,
  width: dimens.shipState.chartContainer.width,
  height: dimens.shipState.mimicContainer.height,
  position: 'relative',
});

const MimicBaseDiv = styled('div')({
  background: colors.chart.header.background,
  width: dimens.shipState.chartContainer.width,
  height: dimens.shipState.mimicContainer.height,
  position: 'relative',
});

const cluchLeft: SxProps = {
  position: 'absolute',
  left: 217,
  top: 145,
};

const cluchRight: SxProps = {
  position: 'absolute',
  left: 217,
  top: 245,
};

const steeingInfoLeft: SxProps = {
  position: 'absolute',
  left: 129,
  top: 152,
};

const steeingInfoRight: SxProps = {
  position: 'absolute',
  left: 129,
  top: 436,
};

const tcInfoLeft: SxProps = {
  position: 'absolute',
};

const tcInfoRight: SxProps = {
  position: 'absolute',
};

const megInfoLeft: SxProps = {
  position: 'absolute',
};

const megInfoRight: SxProps = {
  position: 'absolute',
};

const rexInfoLeft: SxProps = {
  position: 'absolute',
};

const rexInfoRight: SxProps = {
  position: 'absolute',
};

const PortStartCircle1 = styled('div')({
  position: 'absolute',
  top: 98,
  left: 210,
  height: circleSize,
  width: circleSize,
  borderRadius: circleSize,
  border: `3px solid ${colors.chart.mimic.border}`,
});

const StarboardStartCircle1 = styled('div')({
  position: 'absolute',
  top: 401,
  left: 210,
  height: circleSize,
  width: circleSize,
  borderRadius: circleSize,
  border: `3px solid ${colors.chart.mimic.border}`,
});

const PortEndCircle1 = styled('div')({
  position: 'absolute',
  top: 150,
  left: 260,
  height: circleSize,
  width: circleSize,
  borderRadius: circleSize,
  border: `3px solid ${colors.chart.mimic.border}`,
});

const StarboardEndCircle1 = styled('div')({
  position: 'absolute',
  top: 347,
  left: 260,
  height: circleSize,
  width: circleSize,
  borderRadius: circleSize,
  border: `3px solid ${colors.chart.mimic.border}`,
});

const PortLine1 = styled('div')({
  position: 'absolute',
  top: 100,
  left: 215,
  width: 48,
  height: 55,
  borderTop: `1px solid ${colors.chart.mimic.border}`,
  borderRight: `1px solid ${colors.chart.mimic.border}`,
});

const StarboardLine1 = styled('div')({
  position: 'absolute',
  top: 349,
  left: 215,
  width: 48,
  height: 55,
  borderBottom: `1px solid ${colors.chart.mimic.border}`,
  borderRight: `1px solid ${colors.chart.mimic.border}`,
});

const PortStartCircle2 = styled('div')({
  position: 'absolute',
  top: 110,
  left: 498,
  height: circleSize,
  width: circleSize,
  borderRadius: circleSize,
  border: `3px solid ${colors.chart.mimic.border}`,
});

const StarboardStartCircle2 = styled('div')({
  position: 'absolute',
  top: 409,
  left: 498,
  height: circleSize,
  width: circleSize,
  borderRadius: circleSize,
  border: `3px solid ${colors.chart.mimic.border}`,
});

const PortEndCircle2 = styled('div')({
  position: 'absolute',
  top: 155,
  left: 445,
  height: circleSize,
  width: circleSize,
  borderRadius: circleSize,
  border: `3px solid ${colors.chart.mimic.border}`,
});

const PortEndCircle2u = styled('div')({
  position: 'absolute',
  top: 54,
  left: 445,
  height: circleSize,
  width: circleSize,
  borderRadius: circleSize,
  border: `3px solid ${colors.chart.mimic.border}`,
});

const StarboardEndCircle2 = styled('div')({
  position: 'absolute',
  top: 358,
  left: 445,
  height: circleSize,
  width: circleSize,
  borderRadius: circleSize,
  border: `3px solid ${colors.chart.mimic.border}`,
});

const StarboardEndCircle2u = styled('div')({
  position: 'absolute',
  top: 460,
  left: 445,
  height: circleSize,
  width: circleSize,
  borderRadius: circleSize,
  border: `3px solid ${colors.chart.mimic.border}`,
});

const PortLine2 = styled('div')({
  position: 'absolute',
  top: 112,
  left: 448,
  width: 51,
  height: 45,
  borderTop: `1px solid ${colors.chart.mimic.border}`,
  borderLeft: `1px solid ${colors.chart.mimic.border}`,
});

const PortLine2u = styled('div')({
  position: 'absolute',
  top: 54,
  left: 448,
  width: 51,
  height: 59,
  borderLeft: `1px solid ${colors.chart.mimic.border}`,
});

const StarboardLine2 = styled('div')({
  position: 'absolute',
  top: 360,
  left: 448,
  width: 52,
  height: 52,
  borderBottom: `1px solid ${colors.chart.mimic.border}`,
  borderLeft: `1px solid ${colors.chart.mimic.border}`,
});

const StarboardLine2u = styled('div')({
  position: 'absolute',
  top: 412,
  left: 448,
  width: 52,
  height: 51,
  borderLeft: `1px solid ${colors.chart.mimic.border}`,
});

const PortLabel = styled('div')({
  position: 'absolute',
  top: 145,
  left: 340,
  color: 'white',
  fontSize: 14,
  userSelect: 'none',
});

const StarboardLabel = styled('div')({
  position: 'absolute',
  top: 340,
  left: 340,
  color: 'white',
  fontSize: 14,
  userSelect: 'none',
});

interface MimicContainerProps {
  sx?: SxProps;
  width: number;
  commonSensorData: CommonSensorData;
  machine: Machine;
}

function getSensorGroup(
  machine: Machine,
  sensorName: string,
  index: number,
  sensorData: SensorData
): SensorValue {
  const retSG = machine.sensorGroups.filter((group) => {
    return (
      group.sensors.filter((sensor) => {
        return sensor.sensorName === sensorName;
      }).length > 0
    );
  })[0];
  const retS = retSG.sensors.filter((sensor) => {
    return sensor.sensorName === sensorName;
  })[0];

  return { group: retSG, sensor: retS, value: sensorData.data[index] };
}

export function MimicContainer(props: MimicContainerProps): JSX.Element {
  const { sx, width, commonSensorData, machine } = props;
  const intl = useIntl();
  const currentIndex = usePastDataTimeSliderCurrentIndex();
  const ships = useCommonShips();
  const megSensorsL: SensorValue[] = [];
  const megSensorsR: SensorValue[] = [];
  const tcSensorsL: SensorValue[] = [];
  const tcSensorsR: SensorValue[] = [];
  const rexSensorsL: SensorValue[] = [];
  const rexSensorsR: SensorValue[] = [];
  const rexPropellerL: SensorValue[] = [];
  const rexPropellerR: SensorValue[] = [];

  const group = ships && ships[0].machines[0].sensorGroups[5];
  const sensor = group?.sensors[0];
  if (!(sensor && group && commonSensorData && ships)) {
    return <React.Fragment />;
  }

  const {
    megSpdP1,
    megSpdS1,
    megTCSpdP1,
    megTCSpdS1,
    megTCbstpressP1,
    megTCbstpressS1,
    megFuelRackLvP1,
    megFuelRackLvS1,
    megHpP1,
    megHpS1,
    megLOpressP1,
    megLOpressS1,
    megLOTmpP1,
    megLOTmpS1,
    megNo1EXTGASTmpP1,
    megNo1EXTGASTmpS1,
    megNo2EXTGASTmpP1,
    megNo2EXTGASTmpS1,
    megNo3EXTGASTmpP1,
    megNo3EXTGASTmpS1,
    megNo4EXTGASTmpP1,
    megNo4EXTGASTmpS1,
    megNo5EXTGASTmpP1,
    megNo5EXTGASTmpS1,
    megNo6EXTGASTmpP1,
    megNo6EXTGASTmpS1,
    megNo7EXTGASTmpP1,
    megNo7EXTGASTmpS1,
    megNo8EXTGASTmpP1,
    megNo8EXTGASTmpS1,
    megNo9EXTGASTmpP1,
    megNo9EXTGASTmpS1,
    megInletAirTmpP1,
    megInletAirTmpS1,
    rexStrAngOrderP1,
    rexStrAngOrderS1,
    rexStrAngFBP1,
    rexStrAngFBS1,
    rexCPPBladeAngOrderP1,
    rexCPPBladeAngOrderS1,
    rexCPPBladeAngFBP1,
    rexCPPBladeAngFBS1,
    rexClutchengagedFBP1,
    rexClutchengagedFBS1,
    rexLOPressP1,
    rexLOPressS1,
    rexLOTmpP1,
    rexLOTmpS1,
    engSpdP,
    engFuelRackLvP,
    engSftHpP,
    engTcSpdP,
    engSpdS,
    engFuelRackLvS,
    engSftHpS,
    engTcSpdS,
    rexStrAngOrderP,
    rexStrAngOrderS,
    rexStrAngFbP,
    rexStrAngFbS,
    rexClutchEngFbP,
    rexClutchEngFbS,
    engChargedAirPressP,
    engChargedAirPressS,
    engLoPressP,
    engLoPressS,
    engLoInTempP,
    engLoInTempS,
    engNo1ExtGasTempP,
    engNo1ExtGasTempS,
    engNo2ExtGasTempP,
    engNo2ExtGasTempS,
    engNo3ExtGasTempP,
    engNo3ExtGasTempS,
    engNo4ExtGasTempP,
    engNo4ExtGasTempS,
    engNo5ExtGasTempP,
    engNo5ExtGasTempS,
    engNo6ExtGasTempP,
    engNo6ExtGasTempS,
    engTcInTemp145P,
    engTcInTemp145S,
    engTcInTemp236P,
    engTcInTemp236S,
    engTcOutTempP,
    engTcOutTempS,
    rexLOPressP,
    rexLoPressS,
    rexLoTempP,
    rexLoTempS,
    engChargedAirTempP,
    engChargedAirTempS,
  } = commonSensorData.sensors;

  let mimicBaseLeft: number;
  let RexClutchengagedFBP1Val: number | undefined = undefined;
  let RexClutchengagedFBS1Val: number | undefined = undefined;
  let MegLOpressP1: SensorValue | undefined = undefined;
  let MegLOTmpP1: SensorValue | undefined = undefined;
  let MegLOpressS1: SensorValue | undefined = undefined;
  let MegLOTmpS1: SensorValue | undefined = undefined;
  let rexStrAngOrderPG: SensorValue | undefined = undefined;
  let rexStrAngFBP: SensorValue | undefined = undefined;
  let rexStrAngOrderSG: SensorValue | undefined = undefined;
  let rexStrAngFBS: SensorValue | undefined = undefined;
  if (commonSensorData.dataFormatId !== 402) {
    /** 左舷 主機 回転数 */
    if (megSpdP1 && megSpdP1.sensor) {
      megSensorsL.push(getSensorGroup(machine, megSpdP1.sensor.sensorName, currentIndex, megSpdP1));
    }
    /** 右舷 主機 回転数 */
    if (megSpdS1 && megSpdS1.sensor) {
      megSensorsR.push(getSensorGroup(machine, megSpdS1.sensor.sensorName, currentIndex, megSpdS1));
    }
    /** 左舷 主機 潤滑油圧力 */
    if (megLOpressP1 && megLOpressP1.sensor) {
      MegLOpressP1 = getSensorGroup(
        machine,
        megLOpressP1.sensor.sensorName,
        currentIndex,
        megLOpressP1
      );
    }
    /** 右舷 主機 潤滑油圧力 */
    if (megLOpressS1 && megLOpressS1.sensor) {
      MegLOpressS1 = getSensorGroup(
        machine,
        megLOpressS1.sensor.sensorName,
        currentIndex,
        megLOpressS1
      );
    }
    /** 左舷 主機 過給機回転数 */
    if (megTCSpdP1 && megTCSpdP1.sensor) {
      megSensorsL.push(
        getSensorGroup(machine, megTCSpdP1.sensor.sensorName, currentIndex, megTCSpdP1)
      );
    }
    /** 右舷 主機 過給機回転数 */
    if (megTCSpdS1 && megTCSpdS1.sensor) {
      megSensorsR.push(
        getSensorGroup(machine, megTCSpdS1.sensor.sensorName, currentIndex, megTCSpdS1)
      );
    }
    /** 左舷 主機 過給機ブースト圧 */
    if (megTCbstpressP1 && megTCbstpressP1.sensor) {
      megSensorsL.push(
        getSensorGroup(machine, megTCbstpressP1.sensor.sensorName, currentIndex, megTCbstpressP1)
      );
    }
    /** 右舷 主機 過給機ブースト圧 */
    if (megTCbstpressS1 && megTCbstpressS1.sensor) {
      megSensorsR.push(
        getSensorGroup(machine, megTCbstpressS1.sensor.sensorName, currentIndex, megTCbstpressS1)
      );
    }

    /** 左舷 主機 燃料ポンプラックレベル */
    if (megFuelRackLvP1 && megFuelRackLvP1.sensor) {
      rexSensorsL.push(
        getSensorGroup(machine, megFuelRackLvP1.sensor.sensorName, currentIndex, megFuelRackLvP1)
      );
    }
    /** 右舷 主機 燃料ポンプラックレベル */
    if (megFuelRackLvS1 && megFuelRackLvS1.sensor) {
      rexSensorsR.push(
        getSensorGroup(machine, megFuelRackLvS1.sensor.sensorName, currentIndex, megFuelRackLvS1)
      );
    }
    /** 左舷 主機 出力馬力 */
    if (megHpP1 && megHpP1.sensor) {
      rexSensorsL.push(getSensorGroup(machine, megHpP1.sensor.sensorName, currentIndex, megHpP1));
    }
    /** 右舷 主機 出力馬力 */
    if (megHpS1 && megHpS1.sensor) {
      rexSensorsR.push(getSensorGroup(machine, megHpS1.sensor.sensorName, currentIndex, megHpS1));
    }

    /** 左舷 主機 排気温度1 */
    if (megNo1EXTGASTmpP1 && megNo1EXTGASTmpP1.sensor) {
      tcSensorsL.push(
        getSensorGroup(machine, megNo1EXTGASTmpP1.sensor.sensorName, currentIndex, megNo1EXTGASTmpP1)
      );
    }
    /** 右舷 主機 排気温度1 */
    if (megNo1EXTGASTmpS1 && megNo1EXTGASTmpS1.sensor) {
      tcSensorsR.push(
        getSensorGroup(machine, megNo1EXTGASTmpS1.sensor.sensorName, currentIndex, megNo1EXTGASTmpS1)
      );
    }
    /** 左舷 主機 排気温度2 */
    if (megNo2EXTGASTmpP1 && megNo2EXTGASTmpP1.sensor) {
      tcSensorsL.push(
        getSensorGroup(machine, megNo2EXTGASTmpP1.sensor.sensorName, currentIndex, megNo2EXTGASTmpP1)
      );
    }
    /** 右舷 主機 排気温度2 */
    if (megNo2EXTGASTmpS1 && megNo2EXTGASTmpS1.sensor) {
      tcSensorsR.push(
        getSensorGroup(machine, megNo2EXTGASTmpS1.sensor.sensorName, currentIndex, megNo2EXTGASTmpS1)
      );
    }
    /** 左舷 主機 排気温度3 */
    if (megNo3EXTGASTmpP1 && megNo3EXTGASTmpP1.sensor) {
      tcSensorsL.push(
        getSensorGroup(machine, megNo3EXTGASTmpP1.sensor.sensorName, currentIndex, megNo3EXTGASTmpP1)
      );
    }
    /** 右舷 主機 排気温度3 */
    if (megNo3EXTGASTmpS1 && megNo3EXTGASTmpS1.sensor) {
      tcSensorsR.push(
        getSensorGroup(machine, megNo3EXTGASTmpS1.sensor.sensorName, currentIndex, megNo3EXTGASTmpS1)
      );
    }
    /** 左舷 主機 排気温度4 */
    if (megNo4EXTGASTmpP1 && megNo4EXTGASTmpP1.sensor) {
      tcSensorsL.push(
        getSensorGroup(machine, megNo4EXTGASTmpP1.sensor.sensorName, currentIndex, megNo4EXTGASTmpP1)
      );
    }
    /** 右舷 主機 排気温度4 */
    if (megNo4EXTGASTmpS1 && megNo4EXTGASTmpS1.sensor) {
      tcSensorsR.push(
        getSensorGroup(machine, megNo4EXTGASTmpS1.sensor.sensorName, currentIndex, megNo4EXTGASTmpS1)
      );
    }
    /** 左舷 主機 排気温度5 */
    if (megNo5EXTGASTmpP1 && megNo5EXTGASTmpP1.sensor) {
      tcSensorsL.push(
        getSensorGroup(machine, megNo5EXTGASTmpP1.sensor.sensorName, currentIndex, megNo5EXTGASTmpP1)
      );
    }
    /** 右舷 主機 排気温度5 */
    if (megNo5EXTGASTmpS1 && megNo5EXTGASTmpS1.sensor) {
      tcSensorsR.push(
        getSensorGroup(machine, megNo5EXTGASTmpS1.sensor.sensorName, currentIndex, megNo5EXTGASTmpS1)
      );
    }
    /** 左舷 主機 排気温度6 */
    if (megNo6EXTGASTmpP1 && megNo6EXTGASTmpP1.sensor) {
      tcSensorsL.push(
        getSensorGroup(machine, megNo6EXTGASTmpP1.sensor.sensorName, currentIndex, megNo6EXTGASTmpP1)
      );
    }
    /** 右舷 主機 排気温度6 */
    if (megNo6EXTGASTmpS1 && megNo6EXTGASTmpS1.sensor) {
      tcSensorsR.push(
        getSensorGroup(machine, megNo6EXTGASTmpS1.sensor.sensorName, currentIndex, megNo6EXTGASTmpS1)
      );
    }
    /** 左舷 主機 排気温度7 */
    if (megNo7EXTGASTmpP1 && megNo7EXTGASTmpP1.sensor) {
      tcSensorsL.push(
        getSensorGroup(machine, megNo7EXTGASTmpP1.sensor.sensorName, currentIndex, megNo7EXTGASTmpP1)
      );
    }
    /** 右舷 主機 排気温度7 */
    if (megNo7EXTGASTmpS1 && megNo7EXTGASTmpS1.sensor) {
      tcSensorsR.push(
        getSensorGroup(machine, megNo7EXTGASTmpS1.sensor.sensorName, currentIndex, megNo7EXTGASTmpS1)
      );
    }
    /** 左舷 主機 排気温度8 */
    if (megNo8EXTGASTmpP1 && megNo8EXTGASTmpP1.sensor) {
      tcSensorsL.push(
        getSensorGroup(machine, megNo8EXTGASTmpP1.sensor.sensorName, currentIndex, megNo8EXTGASTmpP1)
      );
    }
    /** 右舷 主機 排気温度8 */
    if (megNo8EXTGASTmpS1 && megNo8EXTGASTmpS1.sensor) {
      tcSensorsR.push(
        getSensorGroup(machine, megNo8EXTGASTmpS1.sensor.sensorName, currentIndex, megNo8EXTGASTmpS1)
      );
    }
    /** 左舷 主機 排気温度9 */
    if (megNo9EXTGASTmpP1 && megNo9EXTGASTmpP1.sensor) {
      tcSensorsL.push(
        getSensorGroup(machine, megNo9EXTGASTmpP1.sensor.sensorName, currentIndex, megNo9EXTGASTmpP1)
      );
    }
    /** 右舷 主機 排気温度9 */
    if (megNo9EXTGASTmpS1 && megNo9EXTGASTmpS1.sensor) {
      tcSensorsR.push(
        getSensorGroup(machine, megNo9EXTGASTmpS1.sensor.sensorName, currentIndex, megNo9EXTGASTmpS1)
      );
    }
    /** 左舷 主機 吸気温度 */
    if (megInletAirTmpP1 && megInletAirTmpP1.sensor) {
      tcSensorsL.push(
        getSensorGroup(machine, megInletAirTmpP1.sensor.sensorName, currentIndex, megInletAirTmpP1)
      );
    }
    /** 右舷 主機 吸気温度 */
    if (megInletAirTmpS1 && megInletAirTmpS1.sensor) {
      tcSensorsR.push(
        getSensorGroup(machine, megInletAirTmpS1.sensor.sensorName, currentIndex, megInletAirTmpS1)
      );
    }

    /** 左舷 レックスペラ クラッチ */
    if (rexClutchengagedFBP1 && rexClutchengagedFBP1.sensor) {
      RexClutchengagedFBP1Val = getSensorGroup(
        machine,
        rexClutchengagedFBP1.sensor.sensorName,
        currentIndex,
        rexClutchengagedFBP1
      ).value;
    }
    /** 右舷 レックスペラ クラッチ */
    if (rexClutchengagedFBS1 && rexClutchengagedFBS1.sensor) {
      RexClutchengagedFBS1Val = getSensorGroup(
        machine,
        rexClutchengagedFBS1.sensor.sensorName,
        currentIndex,
        rexClutchengagedFBS1
      ).value;
    }

    /** 左舷 レックスペラ 潤滑油温度 */
    if (rexLOTmpP1 && rexLOTmpP1.sensor) {
      rexPropellerL.push(
        getSensorGroup(machine, rexLOTmpP1.sensor.sensorName, currentIndex, rexLOTmpP1)
      );
    }
    /** 右舷 レックスペラ 潤滑油温度 */
    if (rexLOTmpS1 && rexLOTmpS1.sensor) {
      rexPropellerR.push(
        getSensorGroup(machine, rexLOTmpS1.sensor.sensorName, currentIndex, rexLOTmpS1)
      );
    }
    /** 左舷 レックスペラ 潤滑油圧力C1 */
    if (rexLOPressP1 && rexLOPressP1.sensor) {
      rexPropellerL.push(
        getSensorGroup(machine, rexLOPressP1.sensor.sensorName, currentIndex, rexLOPressP1)
      );
    }
    /** 右舷 レックスペラ 潤滑油圧力C1 */
    if (rexLOPressS1 && rexLOPressS1.sensor) {
      rexPropellerR.push(
        getSensorGroup(machine, rexLOPressS1.sensor.sensorName, currentIndex, rexLOPressS1)
      );
    }
    /** 左舷 レックスペラ CPP翼角ハンドルオーダ */
    if (rexCPPBladeAngOrderP1 && rexCPPBladeAngOrderP1.sensor) {
      rexPropellerL.push(
        getSensorGroup(
          machine,
          rexCPPBladeAngOrderP1.sensor.sensorName,
          currentIndex,
          rexCPPBladeAngOrderP1
        )
      );
    }
    /** 右舷 レックスペラ CPP翼角ハンドルオーダ */
    if (rexCPPBladeAngOrderS1 && rexCPPBladeAngOrderS1.sensor) {
      rexPropellerR.push(
        getSensorGroup(
          machine,
          rexCPPBladeAngOrderS1.sensor.sensorName,
          currentIndex,
          rexCPPBladeAngOrderS1
        )
      );
    }
    /** 左舷 レックスペラ CPP翼角フィードバック */
    if (rexCPPBladeAngFBP1 && rexCPPBladeAngFBP1.sensor) {
      rexPropellerL.push(
        getSensorGroup(
          machine,
          rexCPPBladeAngFBP1.sensor.sensorName,
          currentIndex,
          rexCPPBladeAngFBP1
        )
      );
    }
    /** 右舷 レックスペラ CPP翼角フィードバック */
    if (rexCPPBladeAngFBS1 && rexCPPBladeAngFBS1.sensor) {
      rexPropellerR.push(
        getSensorGroup(
          machine,
          rexCPPBladeAngFBS1.sensor.sensorName,
          currentIndex,
          rexCPPBladeAngFBS1
        )
      );
    }

    /** 左舷 レックスペラ 旋回角ハンドルオーダ */
    if (rexStrAngOrderP1 && rexStrAngOrderP1.sensor) {
      rexStrAngOrderPG = getSensorGroup(
        machine,
        rexStrAngOrderP1.sensor.sensorName,
        currentIndex,
        rexStrAngOrderP1
      );
      rexPropellerL.push(rexStrAngOrderPG);
    }
    /** 右舷 レックスペラ 旋回角ハンドルオーダ */
    if (rexStrAngOrderS1 && rexStrAngOrderS1.sensor) {
      rexStrAngOrderSG = getSensorGroup(
        machine,
        rexStrAngOrderS1.sensor.sensorName,
        currentIndex,
        rexStrAngOrderS1
      );
      rexPropellerR.push(rexStrAngOrderSG);
    }
    /** 左舷 レックスペラ 旋回角フィードバック */
    if (rexStrAngFBP1 && rexStrAngFBP1.sensor) {
      rexStrAngFBP = getSensorGroup(
        machine,
        rexStrAngFBP1.sensor.sensorName,
        currentIndex,
        rexStrAngFBP1
      );
      rexPropellerL.push(rexStrAngFBP);
    }
    /** 右舷 レックスペラ 旋回角フィードバック */
    if (rexStrAngFBS1 && rexStrAngFBS1.sensor) {
      rexStrAngFBS = getSensorGroup(
        machine,
        rexStrAngFBS1.sensor.sensorName,
        currentIndex,
        rexStrAngFBS1
      );
      rexPropellerR.push(rexStrAngFBS);
    }

    /** 左舷 主機 潤滑油温度 */
    if (megLOTmpP1 && megLOTmpP1.sensor) {
      MegLOTmpP1 = getSensorGroup(machine, megLOTmpP1.sensor.sensorName, currentIndex, megLOTmpP1);
    }
    /** 右舷 主機 潤滑油温度 */
    if (megLOTmpS1 && megLOTmpS1.sensor) {
      MegLOTmpS1 = getSensorGroup(machine, megLOTmpS1.sensor.sensorName, currentIndex, megLOTmpS1);
    }

    mimicBaseLeft = width - dimens.shipState.chartContainer.width;
    if (mimicBaseLeft < 0) {
      mimicBaseLeft = 0;
    }

    if (config.valueInspectionLog) {
      dumpPastDataMimicSensorList(commonSensorData, machine);
    }

  } else {
    /** 左舷 主機 回転数 */
    if (engSpdP && engSpdP.sensor) {
      megSensorsL.push(getSensorGroup(machine, engSpdP.sensor.sensorName, currentIndex, engSpdP));
    }
    /** 右舷 主機 回転数 */
    if (engSpdS && engSpdS.sensor) {
      megSensorsR.push(getSensorGroup(machine, engSpdS.sensor.sensorName, currentIndex, engSpdS));
    }
    /** 左舷 主機 LO圧力 */
    if (engLoPressP && engLoPressP.sensor) {
      MegLOpressP1 = getSensorGroup(
        machine,
        engLoPressP.sensor.sensorName,
        currentIndex,
        engLoPressP
      );
    }
    /** 右舷 主機 LO圧力 */
    if (engLoPressS && engLoPressS.sensor) {
      MegLOpressS1 = getSensorGroup(
        machine,
        engLoPressS.sensor.sensorName,
        currentIndex,
        engLoPressS
      );
    }
    /** 左舷 主機 T/C回転数 */
    if (engTcSpdP && engTcSpdP.sensor) {
      megSensorsL.push(
        getSensorGroup(machine, engTcSpdP.sensor.sensorName, currentIndex, engTcSpdP)
      );
    }
    /** 右舷 主機 T/C回転数 */
    if (engTcSpdS && engTcSpdS.sensor) {
      megSensorsR.push(
        getSensorGroup(machine, engTcSpdS.sensor.sensorName, currentIndex, engTcSpdS)
      );
    }
    /** 左舷 主機 T/C過給圧 */
    if (engChargedAirPressP && engChargedAirPressP.sensor) {
      megSensorsL.push(
        getSensorGroup(machine, engChargedAirPressP.sensor.sensorName, currentIndex, engChargedAirPressP)
      );
    }
    /** 右舷 主機 T/C過給圧 */
    if (engChargedAirPressS && engChargedAirPressS.sensor) {
      megSensorsR.push(
        getSensorGroup(machine, engChargedAirPressS.sensor.sensorName, currentIndex, engChargedAirPressS)
      );
    }

    /** 左舷 主機 燃料ポンプラック */
    if (engFuelRackLvP && engFuelRackLvP.sensor) {
      rexSensorsL.push(
        getSensorGroup(machine, engFuelRackLvP.sensor.sensorName, currentIndex, engFuelRackLvP)
      );
    }
    /** 右舷 主機 燃料ポンプラック */
    if (engFuelRackLvS && engFuelRackLvS.sensor) {
      rexSensorsR.push(
        getSensorGroup(machine, engFuelRackLvS.sensor.sensorName, currentIndex, engFuelRackLvS)
      );
    }
    /** 左舷 主機 出力馬力 */
    if (engSftHpP && engSftHpP.sensor) {
      rexSensorsL.push(getSensorGroup(machine, engSftHpP.sensor.sensorName, currentIndex, engSftHpP));
    }
    /** 右舷 主機 出力馬力 */
    if (engSftHpS && engSftHpS.sensor) {
      rexSensorsR.push(getSensorGroup(machine, engSftHpS.sensor.sensorName, currentIndex, engSftHpS));
    }

    /** 左舷 主機 No.1 */
    if (engNo1ExtGasTempP && engNo1ExtGasTempP.sensor) {
      tcSensorsL.push(
        getSensorGroup(machine, engNo1ExtGasTempP.sensor.sensorName, currentIndex, engNo1ExtGasTempP)
      );
    }
    /** 右舷 主機 No.1 */
    if (engNo1ExtGasTempS && engNo1ExtGasTempS.sensor) {
      tcSensorsR.push(
        getSensorGroup(machine, engNo1ExtGasTempS.sensor.sensorName, currentIndex, engNo1ExtGasTempS)
      );
    }
    /** 左舷 主機 No.2 */
    if (engNo2ExtGasTempP && engNo2ExtGasTempP.sensor) {
      tcSensorsL.push(
        getSensorGroup(machine, engNo2ExtGasTempP.sensor.sensorName, currentIndex, engNo2ExtGasTempP)
      );
    }
    /** 右舷 主機 No.2 */
    if (engNo2ExtGasTempS && engNo2ExtGasTempS.sensor) {
      tcSensorsR.push(
        getSensorGroup(machine, engNo2ExtGasTempS.sensor.sensorName, currentIndex, engNo2ExtGasTempS)
      );
    }
    /** 左舷 主機 No.3 */
    if (engNo3ExtGasTempP && engNo3ExtGasTempP.sensor) {
      tcSensorsL.push(
        getSensorGroup(machine, engNo3ExtGasTempP.sensor.sensorName, currentIndex, engNo3ExtGasTempP)
      );
    }
    /** 右舷 主機 No.3 */
    if (engNo3ExtGasTempS && engNo3ExtGasTempS.sensor) {
      tcSensorsR.push(
        getSensorGroup(machine, engNo3ExtGasTempS.sensor.sensorName, currentIndex, engNo3ExtGasTempS)
      );
    }
    /** 左舷 主機 No.4 */
    if (engNo4ExtGasTempP && engNo4ExtGasTempP.sensor) {
      tcSensorsL.push(
        getSensorGroup(machine, engNo4ExtGasTempP.sensor.sensorName, currentIndex, engNo4ExtGasTempP)
      );
    }
    /** 右舷 主機 No.4 */
    if (engNo4ExtGasTempS && engNo4ExtGasTempS.sensor) {
      tcSensorsR.push(
        getSensorGroup(machine, engNo4ExtGasTempS.sensor.sensorName, currentIndex, engNo4ExtGasTempS)
      );
    }
    /** 左舷 主機 No.5 */
    if (engNo5ExtGasTempP && engNo5ExtGasTempP.sensor) {
      tcSensorsL.push(
        getSensorGroup(machine, engNo5ExtGasTempP.sensor.sensorName, currentIndex, engNo5ExtGasTempP)
      );
    }
    /** 右舷 主機 No.5 */
    if (engNo5ExtGasTempS && engNo5ExtGasTempS.sensor) {
      tcSensorsR.push(
        getSensorGroup(machine, engNo5ExtGasTempS.sensor.sensorName, currentIndex, engNo5ExtGasTempS)
      );
    }
    /** 左舷 主機 No.6 */
    if (engNo6ExtGasTempP && engNo6ExtGasTempP.sensor) {
      tcSensorsL.push(
        getSensorGroup(machine, engNo6ExtGasTempP.sensor.sensorName, currentIndex, engNo6ExtGasTempP)
      );
    }
    /** 右舷 主機 No.6 */
    if (engNo6ExtGasTempS && engNo6ExtGasTempS.sensor) {
      tcSensorsR.push(
        getSensorGroup(machine, engNo6ExtGasTempS.sensor.sensorName, currentIndex, engNo6ExtGasTempS)
      );
    }
    /** 左舷 主機 T/C入口1 */
    if (engTcInTemp145P && engTcInTemp145P.sensor) {
      tcSensorsL.push(
        getSensorGroup(machine, engTcInTemp145P.sensor.sensorName, currentIndex, engTcInTemp145P)
      );
    }
    /** 右舷 主機 T/C入口1 */
    if (engTcInTemp145S && engTcInTemp145S.sensor) {
      tcSensorsR.push(
        getSensorGroup(machine, engTcInTemp145S.sensor.sensorName, currentIndex, engTcInTemp145S)
      );
    }
    /** 左舷 主機 T/C入口2 */
    if (engTcInTemp236P && engTcInTemp236P.sensor) {
      tcSensorsL.push(
        getSensorGroup(machine, engTcInTemp236P.sensor.sensorName, currentIndex, engTcInTemp236P)
      );
    }
    /** 右舷 主機 T/C入口2 */
    if (engTcInTemp236S && engTcInTemp236S.sensor) {
      tcSensorsR.push(
        getSensorGroup(machine, engTcInTemp236S.sensor.sensorName, currentIndex, engTcInTemp236S)
      );
    }
    /** 左舷 主機 T/C出口 */
    if (engTcOutTempP && engTcOutTempP.sensor) {
      tcSensorsL.push(
        getSensorGroup(machine, engTcOutTempP.sensor.sensorName, currentIndex, engTcOutTempP)
      );
    }
    /** 右舷 主機 T/C出口 */
    if (engTcOutTempS && engTcOutTempS.sensor) {
      tcSensorsR.push(
        getSensorGroup(machine, engTcOutTempS.sensor.sensorName, currentIndex, engTcOutTempS)
      );
    }
    /** 左舷 主機 給気 */
    if (engChargedAirTempP && engChargedAirTempP.sensor) {
      tcSensorsL.push(
        getSensorGroup(machine, engChargedAirTempP.sensor.sensorName, currentIndex, engChargedAirTempP)
      );
    }
    /** 右舷 主機 給気 */
    if (engChargedAirTempS && engChargedAirTempS.sensor) {
      tcSensorsR.push(
        getSensorGroup(machine, engChargedAirTempS.sensor.sensorName, currentIndex, engChargedAirTempS)
      );
    }

    /** 左舷 クラッチ嵌完了(F/B) */
    if (rexClutchEngFbP && rexClutchEngFbP.sensor) {
      RexClutchengagedFBP1Val = getSensorGroup(
        machine,
        rexClutchEngFbP.sensor.sensorName,
        currentIndex,
        rexClutchEngFbP
      ).value;
    }
    /** 右舷 クラッチ嵌完了(F/B) */
    if (rexClutchEngFbS && rexClutchEngFbS.sensor) {
      RexClutchengagedFBS1Val = getSensorGroup(
        machine,
        rexClutchEngFbS.sensor.sensorName,
        currentIndex,
        rexClutchEngFbS
      ).value;
    }

    /** 左舷 LO温度 */
    if (rexLoTempP && rexLoTempP.sensor) {
      rexPropellerL.push(
        getSensorGroup(machine, rexLoTempP.sensor.sensorName, currentIndex, rexLoTempP)
      );
    }
    /** 右舷 LO温度 */
    if (rexLoTempS && rexLoTempS.sensor) {
      rexPropellerR.push(
        getSensorGroup(machine, rexLoTempS.sensor.sensorName, currentIndex, rexLoTempS)
      );
    }
    /** 左舷 LO圧力 */
    if (rexLOPressP && rexLOPressP.sensor) {
      rexPropellerL.push(
        getSensorGroup(machine, rexLOPressP.sensor.sensorName, currentIndex, rexLOPressP)
      );
    }
    /** 右舷 LO圧力 */
    if (rexLoPressS && rexLoPressS.sensor) {
      rexPropellerR.push(
        getSensorGroup(machine, rexLoPressS.sensor.sensorName, currentIndex, rexLoPressS)
      );
    }

    /** 左舷 旋回角指令C1 */
    if (rexStrAngOrderP && rexStrAngOrderP.sensor) {
      rexStrAngOrderPG = getSensorGroup(
        machine,
        rexStrAngOrderP.sensor.sensorName,
        currentIndex,
        rexStrAngOrderP
      );
      rexPropellerL.push(rexStrAngOrderPG);
    }
    /** 右舷 旋回角指令C1 */
    if (rexStrAngOrderS && rexStrAngOrderS.sensor) {
      rexStrAngOrderSG = getSensorGroup(
        machine,
        rexStrAngOrderS.sensor.sensorName,
        currentIndex,
        rexStrAngOrderS
      );
      rexPropellerR.push(rexStrAngOrderSG);
    }
    /** 左舷 旋回角F/B C1 */
    if (rexStrAngFbP && rexStrAngFbP.sensor) {
      rexStrAngFBP = getSensorGroup(
        machine,
        rexStrAngFbP.sensor.sensorName,
        currentIndex,
        rexStrAngFbP
      );
      rexPropellerL.push(rexStrAngFBP);
    }
    /** 右舷 旋回角F/B C1 */
    if (rexStrAngFbS && rexStrAngFbS.sensor) {
      rexStrAngFBS = getSensorGroup(
        machine,
        rexStrAngFbS.sensor.sensorName,
        currentIndex,
        rexStrAngFbS
      );
      rexPropellerR.push(rexStrAngFBS);
    }

    /** 左舷 主機 LO温度 */
    if (engLoInTempP && engLoInTempP.sensor) {
      MegLOTmpP1 = getSensorGroup(machine, engLoInTempP.sensor.sensorName, currentIndex, engLoInTempP);
    }
    /** 右舷 主機 LO温度 */
    if (engLoInTempS && engLoInTempS.sensor) {
      MegLOTmpS1 = getSensorGroup(machine, engLoInTempS.sensor.sensorName, currentIndex, engLoInTempS);
    }

    mimicBaseLeft = width - dimens.shipState.chartContainer.width;
    if (mimicBaseLeft < 0) {
      mimicBaseLeft = 0;
    }

    if (config.valueInspectionLog) {
      dumpPastDataMimicSensorList(commonSensorData, machine);
    }
  }

  return (
    <RootDiv sx={sx}>
      <MimicBaseDiv sx={{ marginLeft: `${mimicBaseLeft / 2}px` }}>
        {RexClutchengagedFBP1Val !== undefined ? (
          <ClutchPanel sx={cluchLeft} value={RexClutchengagedFBP1Val} visible={true} />
        ) : (
          <ClutchPanel sx={cluchLeft} value={0} visible={false} />
        )}
        {RexClutchengagedFBS1Val !== undefined ? (
          <ClutchPanel sx={cluchRight} value={RexClutchengagedFBS1Val} visible={true} />
        ) : (
          <ClutchPanel sx={cluchRight} value={0} visible={false} />
        )}
        <RexPropellerInfoPanel top={3} left={3} value={rexPropellerL} />
        <RexPropellerInfoPanel top={287} left={3} value={rexPropellerR} />
        {(MegLOpressP1 || MegLOTmpP1) && (
          <RexLoInfoPanel top={3} left={289} value={MegLOTmpP1} value2={MegLOpressP1} />
        )}
        {(MegLOpressS1 || MegLOTmpS1) && (
          <RexLoInfoPanel top={463} left={289} value={MegLOTmpS1} value2={MegLOpressS1} />
        )}
        {rexStrAngOrderPG && rexStrAngFBP && (
          <SteeringInfoPanel
            sx={steeingInfoLeft}
            fb={rexStrAngFBP.value}
            ol={rexStrAngOrderPG.value}
          />
        )}
        {rexStrAngOrderSG && rexStrAngFBS && (
          <SteeringInfoPanel
            sx={steeingInfoRight}
            fb={rexStrAngFBS.value}
            ol={rexStrAngOrderSG.value}
          />
        )}
        <TcInfoPanel sx={tcInfoLeft} top={3} left={500} values={tcSensorsL} />
        <TcInfoPanel sx={tcInfoRight} top={261} left={500} values={tcSensorsR} />
        <MegInfoPanel sx={megInfoLeft} top={3} left={666} values={megSensorsL} dataFormatId={commonSensorData.dataFormatId} />
        <MegInfoPanel sx={megInfoRight} top={261} left={666} values={megSensorsR} dataFormatId={commonSensorData.dataFormatId} />
        <RexInfoPanel sx={rexInfoLeft} top={100} left={666} values={rexSensorsL} />
        <RexInfoPanel sx={rexInfoRight} top={358} left={666} values={rexSensorsR} />

        <PortStartCircle1 />
        <StarboardStartCircle1 />
        <PortEndCircle1 />
        <StarboardEndCircle1 />
        <PortLine1 />
        <StarboardLine1 />

        <PortStartCircle2 />
        <StarboardStartCircle2 />
        <PortEndCircle2 />
        {(MegLOpressP1 || MegLOTmpP1) && <PortEndCircle2u />}
        <StarboardEndCircle2 />
        {(MegLOpressS1 || MegLOTmpS1) && <StarboardEndCircle2u />}
        <PortLine2 />
        {(MegLOpressP1 || MegLOTmpP1) && <PortLine2u />}
        <StarboardLine2 />
        {(MegLOpressS1 || MegLOTmpS1) && <StarboardLine2u />}

        <PortLabel>{intl.formatMessage({ id: msgId.trendLabelPort })}</PortLabel>
        <StarboardLabel>{intl.formatMessage({ id: msgId.trendLabelStbd })}</StarboardLabel>
      </MimicBaseDiv>
    </RootDiv>
  );
}
