import React from 'react';
import colors from 'resources/colors';
import dimens from 'resources/dimens';
import { StatisticalValueType } from 'models/analysis';
import msgId from 'resources/intl';
import { useIntl } from 'react-intl';
import { Box, List, ListItem, MenuItem, styled, SxProps, Typography } from '@mui/material';
import { theme } from 'resources/theme';
import { CheckBox, CheckBoxOutlineBlank } from '@mui/icons-material';
import { ChartSelectField } from '../common/ChartSelectField';

const RootDiv = styled('div')({
  backgroundColor: colors.chart.header.background,
  color: colors.chart.text,
  width: dimens.chart.legend.width,
});

const ShipHeaderDiv = styled('div')({
  width: '100%',
  padding: '4px 8px 4px 8px',
  background: colors.chart.header.background,
});

const HeaderDiv = styled('div')({
  width: dimens.chart.legend.width,
  padding: theme.spacing(0.5),
  backgroundColor: colors.chart.legend.background,
  '&.MuiOutlinedInput-inputMarginDense': {
    paddingTop: '1px',
    paddingBottom: '0px',
  },
});

const StyledList = styled(List)({
  padding: 0,
  width: dimens.chart.legend.width,
  backgroundColor: colors.chart.legend.background,
  color: colors.chart.text,
});

const StyledListItem = styled(ListItem)({
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1),
  '&.Mui-selected': {
    backgroundColor: colors.chart.legend.listItem.selected,
    '&:hover': {
      backgroundColor: colors.chart.legend.listItem.hover,
    },
  },
  borderBottom: `1px solid ${colors.chart.legend.border}`,
});

const iconProps: SxProps = {
  width: 16,
  height: 16,
  marginTop: theme.spacing(0.5),
  marginBottom: theme.spacing(0.5),
  marginLeft: '-2px',
  marginRight: theme.spacing(0.5),
};

export type IconType = 'circle' | 'square' | 'checkbox';

export interface LegendIndex {
  groupIndex: number;
  itemIndex: number;
  checked: boolean;
}

export interface Legend {
  date: string;
  megHpP1: number;
  megHpS1: number;
}

export interface LegendGroup {
  groupName: string;
  legends: Legend[];
}

/**
 * 値に応じた色を取得する。
 * @param value 値の色
 */
export function getValueColor(value: number): string {
  if (isNaN(value)) {
    return colors.chart.megHpLevels[5];
  } else if (value >= 100) {
    return colors.chart.megHpLevels[0];
  } else if (value >= 80) {
    return colors.chart.megHpLevels[1];
  } else if (value >= 60) {
    return colors.chart.megHpLevels[2];
  } else if (value >= 40) {
    return colors.chart.megHpLevels[3];
  } else if (value >= 20) {
    return colors.chart.megHpLevels[4];
  } else {
    return colors.chart.megHpLevels[5];
  }
}

interface MegTempLegendListProps {
  legendGroups: LegendGroup[];
  selectedLegends: boolean[][];
  statisticalValueType: StatisticalValueType;
  onChangeStatisticalValueType: (valueType: StatisticalValueType) => void;
  onCheckChange: (index: LegendIndex) => void;
}

export function MegTempLegendList(props: MegTempLegendListProps): JSX.Element {
  const {
    legendGroups,
    selectedLegends,
    statisticalValueType,
    onChangeStatisticalValueType,
    onCheckChange,
  } = props;
  const intl = useIntl();

  /**
   * センサー名変更
   * @param event イベント
   */
  const handleChangeValueType = (event: React.ChangeEvent<{ value: unknown }>) => {
    const newValueType = event.target.value as StatisticalValueType;
    if (newValueType != null) {
      onChangeStatisticalValueType(newValueType);
    }
  };

  return (
    <RootDiv>
      <HeaderDiv>
        <ChartSelectField
          id="statistical-value-type"
          value={statisticalValueType}
          onChange={handleChangeValueType}
        >
          <MenuItem value="mode">{intl.formatMessage({ id: msgId.statisticalValueMode })}</MenuItem>
          <MenuItem value="median">
            {intl.formatMessage({ id: msgId.statisticalValueMedian })}
          </MenuItem>
        </ChartSelectField>
      </HeaderDiv>
      {legendGroups.map((legendGroup, i) => {
        return (
          <React.Fragment key={'list-' + i}>
            <ShipHeaderDiv>
              <Typography variant="body2" noWrap sx={{ width: '100%' }}>
                {legendGroup.groupName}
              </Typography>
            </ShipHeaderDiv>
            <StyledList>
              {legendGroup.legends.map((legend, j) => {
                const labelId = `label-${j}`;
                const megHpP1Color = getValueColor(legend.megHpP1);
                const megHpS1Color = getValueColor(legend.megHpS1);

                return (
                  <StyledListItem
                    key={labelId}
                    role={undefined}
                    dense
                    onClick={() =>
                      onCheckChange({
                        groupIndex: i,
                        itemIndex: j,
                        checked: !selectedLegends[i][j],
                      })
                    }
                  >
                    {selectedLegends[i][j] && <CheckBox sx={iconProps} color="secondary" />}
                    {!selectedLegends[i][j] && (
                      <CheckBoxOutlineBlank sx={iconProps} color="secondary" />
                    )}

                    <Typography variant="body2" noWrap sx={{ color: colors.chart.header.color }}>
                      {legend.date}
                    </Typography>
                    <Box sx={{ flexGrow: 1 }} />
                    <Typography variant="body2" noWrap sx={{ marginLeft: theme.spacing(1) }}>
                      {intl.formatMessage({ id: msgId.trendLabelPort })}
                    </Typography>
                    <Typography
                      sx={{ width: 24 }}
                      variant="body2"
                      noWrap
                      align={'right'}
                      style={{ color: megHpP1Color }}
                    >
                      {isNaN(legend.megHpP1) ? '---' : String(legend.megHpP1)}
                    </Typography>
                    <Typography variant="body2" noWrap>
                      %
                    </Typography>
                    <Typography variant="body2" noWrap sx={{ marginLeft: theme.spacing(1) }}>
                      {intl.formatMessage({ id: msgId.trendLabelStbd })}
                    </Typography>
                    <Typography
                      sx={{ width: 24 }}
                      variant="body2"
                      noWrap
                      align={'right'}
                      style={{ color: megHpS1Color }}
                    >
                      {isNaN(legend.megHpS1) ? '---' : String(legend.megHpS1)}
                    </Typography>
                    <Typography variant="body2" noWrap>
                      %
                    </Typography>
                  </StyledListItem>
                );
              })}
            </StyledList>
          </React.Fragment>
        );
      })}
    </RootDiv>
  );
}
