import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import colors from 'resources/colors';
import dimens from 'resources/dimens';
import { AnalysisData } from 'models/analysis';
import { ChartHeaderPanel } from 'components/chart/ChartHeaderPanel';
import { LegendGroup, ChartLegendList } from '../common/ChartLegendList';
import { MegLoadBandChart } from './MegLoadBandChart';
import { ChartContainer, ChartContentsDiv, ChartDivider } from '../common/ChartContainer';
import msgId from 'resources/intl';
import { useDispatch } from 'react-redux';
import {
  setMegLoadBandExpanded,
  useAnalysisMegLoadBandExpanded,
} from 'features/contents/analysis/analysisSlice';
import { ConfirmedSearchResultItem } from 'models/search';

interface MegLoadBandContainerProps {
  chartId: string;
  confirmedSearchResultItem: ConfirmedSearchResultItem;
  analysisData: AnalysisData;
}

export function MegLoadBandContainer(props: MegLoadBandContainerProps): JSX.Element {
  const { chartId, confirmedSearchResultItem, analysisData } = props;
  const intl = useIntl();
  const dispatch = useDispatch();
  const expanded = useAnalysisMegLoadBandExpanded();

  /**
   * CSV出力
   */
  const handleCsvOutput = () => {
    // noop
  };

  /**
   * 開閉状態変更
   * @param expanded 開閉状態
   */
  const handleExpandChanged = (expanded: boolean) => {
    dispatch(setMegLoadBandExpanded(expanded));
  };

  /**
   * 凡例リスト
   */
  const legendGroups: LegendGroup[] = [
    {
      groupName: analysisData.shipName + ' - ' + analysisData.machineName,
      legends: [
        {
          title: intl.formatMessage({ id: msgId.AnalysisMegHpLevel6 }),
          color: colors.chart.megHpLevels[0],
          iconType: 'square',
        },
        {
          title: intl.formatMessage({ id: msgId.AnalysisMegHpLevel5 }),
          color: colors.chart.megHpLevels[1],
          iconType: 'square',
        },
        {
          title: intl.formatMessage({ id: msgId.AnalysisMegHpLevel4 }),
          color: colors.chart.megHpLevels[2],
          iconType: 'square',
        },
        {
          title: intl.formatMessage({ id: msgId.AnalysisMegHpLevel3 }),
          color: colors.chart.megHpLevels[3],
          iconType: 'square',
        },
        {
          title: intl.formatMessage({ id: msgId.AnalysisMegHpLevel2 }),
          color: colors.chart.megHpLevels[4],
          iconType: 'square',
        },
        {
          title: intl.formatMessage({ id: msgId.AnalysisMegHpLevel1 }),
          color: colors.chart.megHpLevels[5],
          iconType: 'square',
        },
      ],
    },
  ];

  const analysisName =
    intl.formatMessage({
      id: 'AnalysisSection' + analysisData.data.megLoadBand?.analysisSectionName,
    }) +
    ' : ' +
    intl.formatMessage({ id: 'AnalysisMenu' + analysisData.data.megLoadBand?.analysisMenuName });

  const chart = useMemo(
    () => (
      <MegLoadBandChart
        sx={{ width: `calc(100% - ${dimens.chart.legend.width}px - 1px)` }}
        analysisData={analysisData}
      />
    ),
    [analysisData]
  );

  return (
    <ChartContainer
      chartId={chartId}
      shipName={analysisData.shipName}
      machineName={analysisData.machineName}
      analysisName={analysisName}
      confirmedSearchResultItem={confirmedSearchResultItem}
      title={analysisName}
      expanded={expanded}
      onExpandChanged={handleExpandChanged}
    >
      <ChartHeaderPanel
        chartId={chartId}
        sx={{ paddingRight: `${dimens.chart.legend.width}px` }}
        title={analysisName}
        titleEditable={false}
        csvOutputEnabled={false}
        csvOutputLocked={true}
      />
      <ChartContentsDiv>
        {chart}
        <ChartDivider orientation="vertical" flexItem />
        <ChartLegendList legendGroups={legendGroups} />
      </ChartContentsDiv>
    </ChartContainer>
  );
}
