import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import msgId from 'resources/intl';
import { setOpen, useAccountInformationOpen } from './accountInformationSlice';
import {
  EditDialog,
  EditDialogContents,
  EditDialogInputFieldGrid,
  EditDialogRootGrid,
  EditDialogTitleGrid,
  EditDialogMiniButton,
} from 'components/EditDialog';
import { styled, Grid, Typography } from '@mui/material';
import { SmallTextField } from 'components/SmallTextField';
import {
  useAccountCognitoUserInfo,
  useAccount,
  setMailAddressChangeOpen,
  setPasswordChangeOpen,
} from 'features/login/accountSlice';
import { theme } from 'resources/theme';
import { CustomScrollBars } from 'components/CustomScrollBars';
import dimens from 'resources/dimens';
import colors from 'resources/colors';

const EditButtonGrid = styled(Grid)({
  paddingTop: theme.spacing(2),
  paddingLeft: theme.spacing(1),
  width: '48px',
});

const RowLeftGrid = styled(Grid)({
  width: `${350 + 48}px`,
  justifyContent: 'start',
  display: 'flex',
  alignItems: 'flex-start',
  marginTop: theme.spacing(1),
});

const AccountEditButton = styled(EditDialogMiniButton)({
  paddingTop: theme.spacing(0.5),
});

const AccountEditInputField = styled(EditDialogInputFieldGrid)({
  paddingTop: theme.spacing(1),
});

export function AccountInformationDialog(): JSX.Element {
  const intl = useIntl();
  const dispatch = useDispatch();
  const open = useAccountInformationOpen();
  const cognitoUserInfo = useAccountCognitoUserInfo();
  const userAccount = useAccount();
  const [state, setState] = useState({
    accountName: '',
    mailAddress: '',
  });

  /**
   * バックボタン押下
   */
  const handleBackClick = () => {
    dispatch(setOpen(false));
  };

  /**
   * メールアドレスの変更ボタン押下時に呼ばれる。
   */
  const handleMailChange = (): void => {
    if (cognitoUserInfo) {
      dispatch(setMailAddressChangeOpen(true));
    }
  };

  /**
   * パスワードの変更ボタン押下時に呼ばれる。
   */
  const handlePasswordChange = (): void => {
    dispatch(setPasswordChangeOpen(true));
  };

  useEffect(() => {
    if (userAccount) {
      setState({
        accountName: userAccount.cognitoAccountId,
        mailAddress: userAccount.mailAddress,
      });
    }
  }, [userAccount]);

  return (
    <EditDialog
      id="accountInformationDialog"
      open={open}
      onBackClick={handleBackClick}
      title={intl.formatMessage({
        id: msgId.accountInformation,
      })}
    >
      <EditDialogContents>
        <CustomScrollBars
          thumbColor={colors.scrollBar.thumb.light}
          width={dimens.dialogContents.width}
          autoHide={false}
        >
          <EditDialogRootGrid>
            <EditDialogTitleGrid>
              <Typography variant="h5">
                {intl.formatMessage({ id: msgId.guideMessageAccountInformation })}
              </Typography>
            </EditDialogTitleGrid>
            <RowLeftGrid>
              <AccountEditInputField>
                <SmallTextField
                  id="account-edit-id"
                  fullWidth={true}
                  label={intl.formatMessage({ id: msgId.accountName })}
                  autoComplete="email"
                  value={state.accountName}
                  variant="standard"
                  InputProps={{ readOnly: true }}
                />
              </AccountEditInputField>
            </RowLeftGrid>
            <RowLeftGrid>
              <AccountEditInputField>
                <SmallTextField
                  id="account-edit-mail-address"
                  fullWidth={true}
                  label={intl.formatMessage({ id: msgId.mailAddress })}
                  autoComplete="email"
                  value={state.mailAddress}
                  variant="standard"
                  InputProps={{ readOnly: true }}
                />
              </AccountEditInputField>
              <EditButtonGrid item>
                <AccountEditButton color="primary" onClick={handleMailChange}>
                  {intl.formatMessage({ id: msgId.labelEdit })}
                </AccountEditButton>
              </EditButtonGrid>
            </RowLeftGrid>
            <RowLeftGrid>
              <AccountEditInputField>
                <SmallTextField
                  id="account-edit-password"
                  fullWidth={true}
                  label={intl.formatMessage({ id: msgId.password })}
                  autoComplete="new-password"
                  value={intl.formatMessage({ id: msgId.passwordPlaceholder })}
                  variant="standard"
                  InputProps={{ readOnly: true }}
                />
              </AccountEditInputField>
              <EditButtonGrid item>
                <AccountEditButton color="primary" onClick={handlePasswordChange}>
                  {intl.formatMessage({ id: msgId.labelEdit })}
                </AccountEditButton>
              </EditButtonGrid>
            </RowLeftGrid>
          </EditDialogRootGrid>
        </CustomScrollBars>
      </EditDialogContents>
    </EditDialog>
  );
}
