/**
 * サイズ関係の定義
 */
const dimens = {
  default: {
    rowHeight: 32,
    margin: 8,
  },
  button: {
    width: 180,
    minWidth: 120,
    headerWidth: 140,
    footerWidth: 150,
    height: 30,
    clearHeight: 26,
    borderRadius: '15px',
  },
  login: {
    width: 460,
    minHeight: 580,
  },
  drawer: {
    width: 240,
    minWidth: 52,
  },
  subDrawer: {
    width: 300,
    minWidth: 40,
  },
  searchBar: {
    height: 40,
  },
  toolbar: {
    height: 48,
  },
  splitter: {
    width: 7,
  },
  dataGrid: {
    margin: '0px 4px 4px 4px',
    offsetHeight: 45,
    minHeight: 106,
    column: {
      minWidth: 60,
    },
  },
  treeList: {
    margin: '0px 4px 4px 4px',
    offsetHeight: 44,
  },
  contentsHeader: {
    height: 40,
  },
  chart: {
    legend: {
      width: 250,
    },
    button: {
      height: 20,
      minWidth: 80,
      borderRadius: 10,
    },
    trend: {
      height: 400,
    },
    analysis: {
      width: 900,
    },
  },
  shipState: {
    chartContainer: {
      width: 850,
    },
    mimicContainer: {
      height: 520,
    },
    realtimeAisScrollbar: {
      margin: 82,
    },
    pastDataScrollbar: {
      margin: 82,
    },
  },
  timeSlider: {
    height: 40,
    displayMinHeight: 373,
  },
  diagnosis: {
    graphMinWidth: 700,
    graphMinHeight: 300,
    legendMinWidth: 267,
  },
  marineWeather: {
    legend: {
      bottom: 63,
      right: 8,
      displayMinWidth: 110,
    },
    popup: {
      bottom: 63,
      right: 68,
      displayMinWidth: 300,
      width: 180,
    },
  },
  dialogContents: {
    width: 700,
    labelMaxWidth: 310,
  },
};

export default dimens;
