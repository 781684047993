import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { styled } from '@mui/material';
import config from 'resources/config';
import { GoogleMap, useLoadScript } from '@react-google-maps/api';
import { ShipMarker, ShipMarkerProps } from './ShipMarker';
import constants from 'resources/constants';

const RootDiv = styled('div')({
  width: '100%',
});

const options: google.maps.MapOptions = {
  disableDefaultUI: true,
  scaleControl: true,
  scaleControlOptions: {
    style: 0, // google.maps.ScaleControlStyle.DEFAULT,
  },
  fullscreenControl: true,
  fullscreenControlOptions: {
    position: 5, // google.maps.ControlPosition.LEFT_TOP,
  },
  zoomControl: true,
  zoomControlOptions: {
    position: 4, // google.maps.ControlPosition.LEFT_CENTER,
  },
};

const mapContainerStyle = {
  height: '100%',
  width: '100%',
};

interface DefaultProps {
  defaultCenter: { lat: number; lng: number };
  defaultZoom: number;
}
let addEventListenerFlg = false;

export function MapContainer(props: DefaultProps): JSX.Element {
  const { defaultCenter, defaultZoom } = props;
  const params = useParams();
  const { shipName, lat, lng, deg, date } = params as {
    shipName: string;
    lat: string;
    lng: string;
    deg: string;
    date: string;
  };
  const dispatch = useDispatch();
  const [contentSize, setContentSize] = useState<{ width: number; height: number }>({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const [param, setParam] = useState<ShipMarkerProps>({
    shipName: shipName,
    lat: lat,
    lng: lng,
    deg: deg,
    date: date,
  });

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: config.googleMapKey,
  });
  const [googleMap, setGoogleMap] = useState<google.maps.Map | undefined>(undefined);

  const onMapLoad = useCallback((map) => {
    setGoogleMap(map);
  }, []);

  useEffect(() => {
    googleMap?.setCenter({ lat: Number(lat), lng: Number(lng) });
    // dispatch(setCenter(undefined));
  }, [dispatch, googleMap, lat, lng]);

  useEffect(() => {
    window.addEventListener('resize', (e) => {
      if (e && e.target) {
        const width = (e.target as Window).innerWidth;
        const height = (e.target as Window).innerHeight;
        setContentSize({ width, height });
      }
    });
  }, [dispatch]);

  if (loadError) {
    return <div>Map cannot be loaded right now, sorry.</div>;
  }

  if (!isLoaded) {
    return <React.Fragment />;
  }

  function receiveMessage(event: MessageEvent) {
    const paramObj = JSON.parse(event.data);
    setParam(paramObj);
  }
  if (!addEventListenerFlg) {
    addEventListenerFlg = true;
    window.addEventListener('message', receiveMessage, false);
  }

  return (
    <RootDiv sx={{ height: contentSize.height }}>
      <GoogleMap
        id="map2"
        options={options}
        mapContainerStyle={mapContainerStyle}
        zoom={defaultZoom}
        center={defaultCenter}
        onLoad={onMapLoad}
      >
        <ShipMarker
          shipName={param.shipName}
          lat={param.lat}
          lng={param.lng}
          deg={param.deg}
          date={param.date}
        />
      </GoogleMap>
    </RootDiv>
  );
}

MapContainer.defaultProps = {
  defaultCenter: constants.map.defaultLatLng,
  defaultZoom: constants.map.defaultZoom,
} as DefaultProps;
