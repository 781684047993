import React from 'react';
import { IconButton } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';

interface VisibilityIconButtonProps {
  visibility: boolean;
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

export function VisibilityIconButton(props: VisibilityIconButtonProps): JSX.Element {
  const { visibility, onClick } = props;

  return (
    <IconButton
      aria-label="toggle password visibility"
      onClick={onClick}
      onMouseDown={(event) => event.preventDefault()}
      edge="end"
      tabIndex={-1}
    >
      {visibility ? <VisibilityOff /> : <Visibility />}
    </IconButton>
  );
}
