import React, { ReactNode } from 'react';
import { Box } from '@mui/material';
import { styled, SxProps } from '@mui/material/styles';
import constants from 'resources/constants';

const StyledBox = styled(Box)({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  display: 'flex',
  overflow: 'hidden',
});

interface RootContainerProps {
  sx?: SxProps;
  children: ReactNode;
  hidden?: boolean;
}

export const RootContainer = React.forwardRef(function RootContainer(
  props: RootContainerProps,
  ref: React.Ref<HTMLDivElement>
) {
  const { sx, children, hidden } = props;

  return (
    <StyledBox
      ref={ref}
      sx={{
        visibility: hidden === false ? 'visible' : 'hidden',
        zIndex: hidden === false ? constants.zIndex.active : constants.zIndex.hidden,
        ...sx,
      }}
    >
      {children}
    </StyledBox>
  );
});
