import React, { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import msgId from 'resources/intl';
import { InfoWindow, Marker } from '@react-google-maps/api';
import { useDispatch } from 'react-redux';
import ShipPin from 'resources/assets/ShipPin.svg';
import { SensorData, CommonSensorData, SensorThinnedHeaderData } from 'models/data';
import { setCurrentConditionMachineId } from '../pastDataSlice';
import { usePastDataTimeSliderCurrentIndex } from '../timeSlider/timeSliderSlice';
import { roundValueToString } from 'utils/misc';
import {
  removeChart,
  addChart,
} from 'features/contents/shipState/pastData/chart/trend/trendChartSlice';
// import dayjs from 'dayjs';
// import constants from 'resources/constants';

interface VesInfo {
  machineId?: number;
  shipName?: string;
  vesLatC1: number;
  vesLonC1: number;
  vesSpdSOGC1: number | undefined;
  vesCourseTrueDirC1: number | undefined;
  vesLatC1Text: string;
  vesLonC1Text: string;
  vesSpdSOGC1Text: string | undefined;
  vesCourseTrueDirC1Text: string | undefined;
  vesWindDirRelC1Text: string | undefined;
  vesWindSpdRelC1Text: string | undefined;
  logdate: number | undefined;
}

interface VesSensorData {
  machineId?: number;
  shipName?: string;
  vesLatC1: SensorData;
  vesLonC1: SensorData;
  vesSpdSOGC1: SensorData | undefined;
  vesCourseTrueDirC1: SensorData | undefined;
  vesWindDirRelC1: SensorData | undefined;
  vesWindSpdRelC1: SensorData | undefined;
  logdate: number[] | undefined;
}

interface ShipMarkerProps {
  machineIdtoShipName: { [key: string]: string };
  commonSensorData: CommonSensorData | undefined;
  sensorThinnedHeaderData: SensorThinnedHeaderData | undefined;
  isThinned: boolean;
}

export function ShipMarker(props: ShipMarkerProps): JSX.Element {
  const { machineIdtoShipName, commonSensorData, sensorThinnedHeaderData, isThinned } = props;
  const intl = useIntl();
  const dispatch = useDispatch();
  const _currentIndex = usePastDataTimeSliderCurrentIndex();
  const [open, setOpen] = useState<boolean>(false);
  const [image, setImage] = useState(new Image());
  const dblClickTimeoutId = useRef<NodeJS.Timeout | undefined>(undefined);
  let currentIndex = _currentIndex;

  let vesSensorData: VesSensorData | undefined = undefined;
  if (isThinned) {
    if (
      sensorThinnedHeaderData != null &&
      sensorThinnedHeaderData.dataFormatId !== 402 &&
      sensorThinnedHeaderData.sensors.vesLatC1 != null &&
      sensorThinnedHeaderData.sensors.vesLonC1 != null
    ) {
      if (commonSensorData != null) {
        vesSensorData = {
          machineId: sensorThinnedHeaderData.machineId,
          shipName: machineIdtoShipName[String(sensorThinnedHeaderData.machineId)],
          vesLatC1: sensorThinnedHeaderData.sensors.vesLatC1,
          vesLonC1: sensorThinnedHeaderData.sensors.vesLonC1,
          vesSpdSOGC1: sensorThinnedHeaderData.sensors.vesSpdSOGC1,
          vesCourseTrueDirC1: sensorThinnedHeaderData.sensors.vesCourseTrueDirC1,
          vesWindDirRelC1: sensorThinnedHeaderData.sensors.vesWindDirRelC1,
          vesWindSpdRelC1: sensorThinnedHeaderData.sensors.vesWindSpdRelC1,
          logdate: sensorThinnedHeaderData.logdate,
        };
      } else {
        vesSensorData = {
          machineId: sensorThinnedHeaderData.machineId,
          shipName: machineIdtoShipName[String(sensorThinnedHeaderData.machineId)],
          vesLatC1: {
            data: [
              sensorThinnedHeaderData.sensors.vesLatC1.data[
              sensorThinnedHeaderData.sensors.vesLatC1.data.length - 1
              ],
            ],
            diagnosisStatus: sensorThinnedHeaderData.sensors.vesLatC1.diagnosisStatus,
            max: sensorThinnedHeaderData.sensors.vesLatC1.max,
            min: sensorThinnedHeaderData.sensors.vesLatC1.min,
            sensor: sensorThinnedHeaderData.sensors.vesLatC1.sensor,
            sensorGroup: sensorThinnedHeaderData.sensors.vesLatC1.sensorGroup,
          },
          vesLonC1: {
            data: [
              sensorThinnedHeaderData.sensors.vesLonC1.data[
              sensorThinnedHeaderData.sensors.vesLonC1.data.length - 1
              ],
            ],
            diagnosisStatus: sensorThinnedHeaderData.sensors.vesLonC1.diagnosisStatus,
            max: sensorThinnedHeaderData.sensors.vesLonC1.max,
            min: sensorThinnedHeaderData.sensors.vesLonC1.min,
            sensor: sensorThinnedHeaderData.sensors.vesLonC1.sensor,
            sensorGroup: sensorThinnedHeaderData.sensors.vesLonC1.sensorGroup,
          },
          vesSpdSOGC1:
            sensorThinnedHeaderData.sensors.vesSpdSOGC1 != null
              ? {
                data: [
                  sensorThinnedHeaderData.sensors.vesSpdSOGC1.data[
                  sensorThinnedHeaderData.sensors.vesSpdSOGC1.data.length - 1
                  ],
                ],
                diagnosisStatus: sensorThinnedHeaderData.sensors.vesSpdSOGC1.diagnosisStatus,
                max: sensorThinnedHeaderData.sensors.vesSpdSOGC1.max,
                min: sensorThinnedHeaderData.sensors.vesSpdSOGC1.min,
                sensor: sensorThinnedHeaderData.sensors.vesSpdSOGC1.sensor,
                sensorGroup: sensorThinnedHeaderData.sensors.vesSpdSOGC1.sensorGroup,
              }
              : undefined,
          vesCourseTrueDirC1: sensorThinnedHeaderData.sensors.vesCourseTrueDirC1,
          vesWindDirRelC1: sensorThinnedHeaderData.sensors.vesWindDirRelC1,
          vesWindSpdRelC1: sensorThinnedHeaderData.sensors.vesWindSpdRelC1,
          logdate: [sensorThinnedHeaderData.lastLogDate],
        };

        // 間引きデータの場合は各データの長さは常に1
        currentIndex = 0;
      }
    } else if (
      sensorThinnedHeaderData != null &&
      sensorThinnedHeaderData.dataFormatId === 402 &&
      sensorThinnedHeaderData.sensors.vesLat != null &&
      sensorThinnedHeaderData.sensors.vesLon != null
    ) {
      if (commonSensorData != null) {
        vesSensorData = {
          machineId: sensorThinnedHeaderData.machineId,
          shipName: machineIdtoShipName[String(sensorThinnedHeaderData.machineId)],
          vesLatC1: sensorThinnedHeaderData.sensors.vesLat,
          vesLonC1: sensorThinnedHeaderData.sensors.vesLon,
          vesSpdSOGC1: sensorThinnedHeaderData.sensors.vesSog,
          vesCourseTrueDirC1: sensorThinnedHeaderData.sensors.vesHeading,
          vesWindDirRelC1: sensorThinnedHeaderData.sensors.vesWindDirMagnitudMagDeg,
          vesWindSpdRelC1: sensorThinnedHeaderData.sensors.vesWindSpd,
          logdate: sensorThinnedHeaderData.logdate,
        };
      } else {
        vesSensorData = {
          machineId: sensorThinnedHeaderData.machineId,
          shipName: machineIdtoShipName[String(sensorThinnedHeaderData.machineId)],
          vesLatC1: {
            data: [
              sensorThinnedHeaderData.sensors.vesLat.data[
              sensorThinnedHeaderData.sensors.vesLat.data.length - 1
              ],
            ],
            diagnosisStatus: sensorThinnedHeaderData.sensors.vesLat.diagnosisStatus,
            max: sensorThinnedHeaderData.sensors.vesLat.max,
            min: sensorThinnedHeaderData.sensors.vesLat.min,
            sensor: sensorThinnedHeaderData.sensors.vesLat.sensor,
            sensorGroup: sensorThinnedHeaderData.sensors.vesLat.sensorGroup,
          },
          vesLonC1: {
            data: [
              sensorThinnedHeaderData.sensors.vesLon.data[
              sensorThinnedHeaderData.sensors.vesLon.data.length - 1
              ],
            ],
            diagnosisStatus: sensorThinnedHeaderData.sensors.vesLon.diagnosisStatus,
            max: sensorThinnedHeaderData.sensors.vesLon.max,
            min: sensorThinnedHeaderData.sensors.vesLon.min,
            sensor: sensorThinnedHeaderData.sensors.vesLon.sensor,
            sensorGroup: sensorThinnedHeaderData.sensors.vesLon.sensorGroup,
          },
          vesSpdSOGC1:
            sensorThinnedHeaderData.sensors.vesSog != null
              ? {
                data: [
                  sensorThinnedHeaderData.sensors.vesSog.data[
                  sensorThinnedHeaderData.sensors.vesSog.data.length - 1
                  ],
                ],
                diagnosisStatus: sensorThinnedHeaderData.sensors.vesSog.diagnosisStatus,
                max: sensorThinnedHeaderData.sensors.vesSog.max,
                min: sensorThinnedHeaderData.sensors.vesSog.min,
                sensor: sensorThinnedHeaderData.sensors.vesSog.sensor,
                sensorGroup: sensorThinnedHeaderData.sensors.vesSog.sensorGroup,
              }
              : undefined,
          vesCourseTrueDirC1: sensorThinnedHeaderData.sensors.vesHeading,
          vesWindDirRelC1: sensorThinnedHeaderData.sensors.vesWindDirMagnitudMagDeg,
          vesWindSpdRelC1: sensorThinnedHeaderData.sensors.vesWindSpd,
          logdate: [sensorThinnedHeaderData.lastLogDate],
        };

        // 間引きデータの場合は各データの長さは常に1
        currentIndex = 0;
      }
    }
  } else {
    if (
      commonSensorData != null &&
      commonSensorData.dataFormatId !== 402 &&
      commonSensorData.sensors.vesLatC1 != null &&
      commonSensorData.sensors.vesLonC1 != null
    ) {
      vesSensorData = {
        shipName: machineIdtoShipName[String(commonSensorData.machineId)],
        vesLatC1: commonSensorData.sensors.vesLatC1,
        vesLonC1: commonSensorData.sensors.vesLonC1,
        vesSpdSOGC1: commonSensorData.sensors.vesSpdSOGC1,
        vesCourseTrueDirC1: commonSensorData.sensors.vesCourseTrueDirC1,
        vesWindDirRelC1: commonSensorData.sensors.vesWindDirRelC1,
        vesWindSpdRelC1: commonSensorData.sensors.vesWindSpdRelC1,
        logdate: commonSensorData.logdate,
      };
    } else if (
      commonSensorData != null &&
      commonSensorData.dataFormatId === 402 &&
      commonSensorData.sensors.vesLat != null &&
      commonSensorData.sensors.vesLon != null
    ) {
      vesSensorData = {
        shipName: machineIdtoShipName[String(commonSensorData.machineId)],
        vesLatC1: commonSensorData.sensors.vesLat,
        vesLonC1: commonSensorData.sensors.vesLon,
        vesSpdSOGC1: commonSensorData.sensors.vesSog,
        vesCourseTrueDirC1: commonSensorData.sensors.vesHeading,
        vesWindDirRelC1: commonSensorData.sensors.vesWindDirMagnitudMagDeg,
        vesWindSpdRelC1: commonSensorData.sensors.vesWindSpd,
        logdate: commonSensorData.logdate,
      };
    }
  }

  let vesInfo: VesInfo | undefined = undefined;
  if (vesSensorData) {
    const vesLatC1 = vesSensorData.vesLatC1;
    const vesLonC1 = vesSensorData.vesLonC1;
    const vesSpdSOGC1 = vesSensorData.vesSpdSOGC1;
    const vesCourseTrueDirC1 = vesSensorData.vesCourseTrueDirC1;
    const vesWindDirRelC1 = vesSensorData.vesWindDirRelC1;
    const vesWindSpdRelC1 = vesSensorData.vesWindSpdRelC1;

    // 少なくとも座標は必要
    // 座標が(0, 0)の場合は表示しない
    if (
      vesLatC1 != null &&
      vesLonC1 != null &&
      vesLatC1.data[currentIndex] != null &&
      vesLonC1.data[currentIndex] != null &&
      vesLatC1.data[currentIndex] !== 0 &&
      vesLonC1.data[currentIndex] !== 0 &&
      Math.abs(vesLatC1.data[currentIndex]) >= 1 &&
      Math.abs(vesLonC1.data[currentIndex]) >= 1
    ) {
      vesInfo = {
        machineId: vesSensorData.machineId,
        shipName: vesSensorData.shipName,
        vesLatC1: vesLatC1.data[currentIndex],
        vesLonC1: vesLonC1.data[currentIndex],
        vesSpdSOGC1: vesSpdSOGC1 && vesSpdSOGC1.data[currentIndex],
        vesCourseTrueDirC1: vesCourseTrueDirC1 && vesCourseTrueDirC1.data[currentIndex],
        vesLatC1Text: roundValueToString(
          vesLatC1.data[currentIndex],
          vesLatC1.sensor.displayRoundingPosition
        ),
        vesLonC1Text: roundValueToString(
          vesLonC1.data[currentIndex],
          vesLonC1.sensor.displayRoundingPosition
        ),
        vesSpdSOGC1Text: undefined,
        vesCourseTrueDirC1Text: undefined,
        vesWindDirRelC1Text: undefined,
        vesWindSpdRelC1Text: undefined,
        logdate: vesSensorData.logdate != null ? vesSensorData.logdate[currentIndex] : undefined,
      };

      if (vesSpdSOGC1 != null && vesSpdSOGC1.data.length > currentIndex) {
        vesInfo.vesSpdSOGC1Text = roundValueToString(
          vesSpdSOGC1.data[currentIndex],
          vesSpdSOGC1.sensor.displayRoundingPosition
        );
        if (vesSpdSOGC1.sensor.displayUnit != null) {
          vesInfo.vesSpdSOGC1Text += vesSpdSOGC1.sensor.displayUnit;
        }
      }
      if (vesCourseTrueDirC1 != null && vesCourseTrueDirC1.data.length > currentIndex) {
        vesInfo.vesCourseTrueDirC1Text = roundValueToString(
          vesCourseTrueDirC1.data[currentIndex],
          vesCourseTrueDirC1.sensor.displayRoundingPosition
        );
        if (vesCourseTrueDirC1.sensor.displayUnit != null) {
          vesInfo.vesCourseTrueDirC1Text += vesCourseTrueDirC1.sensor.displayUnit;
        }
      }
      if (vesWindDirRelC1 != null && vesWindDirRelC1.data.length > currentIndex) {
        vesInfo.vesWindDirRelC1Text = roundValueToString(
          vesWindDirRelC1.data[currentIndex],
          vesWindDirRelC1.sensor.displayRoundingPosition
        );
        if (vesWindDirRelC1.sensor.displayUnit != null) {
          vesInfo.vesWindDirRelC1Text += vesWindDirRelC1.sensor.displayUnit;
        }
      }
      if (vesWindSpdRelC1 != null && vesWindSpdRelC1.data.length > currentIndex) {
        vesInfo.vesWindSpdRelC1Text = roundValueToString(
          vesWindSpdRelC1.data[currentIndex],
          vesWindSpdRelC1.sensor.displayRoundingPosition
        );
        if (vesWindSpdRelC1.sensor.displayUnit != null) {
          vesInfo.vesWindSpdRelC1Text += vesWindSpdRelC1.sensor.displayUnit;
        }
      }
    }
  }

  const rotateIcon = (deg: number) => {
    const canvas = document.createElement('canvas');
    canvas.width = 42;
    canvas.height = 42;
    const context = canvas.getContext('2d');
    const centerX = 21;
    const centerY = 21;
    const angle = (deg * Math.PI) / 180;

    if (context != null) {
      context.clearRect(0, 0, canvas.width, canvas.height);
      context.save();
      context.translate(centerX, centerY);
      context.rotate(angle);
      context.translate(-centerX, -centerY);
      context.drawImage(image, 0, 0);
      context.restore();
    }

    return canvas.toDataURL('image/png');
  };

  useEffect(() => {
    const image = new Image();
    image.src = ShipPin;
    image.onload = () => {
      setImage(image);
    };
  }, []);

  function getMinSec(num: string) {
    let min, sec;
    if (num.split('.').length === 1) {
      min = 0;
      sec = '00';
    } else {
      min = parseInt(num.split('.')[1].substring(0, 2), 10);
      sec = num.split('.')[1].substring(2, 4);
    }

    return { min: min, sec: sec };
  }

  function getLatText(lat: number): string {
    let val = lat;
    let latText;
    if (lat < 0) {
      val *= -1;
      latText = intl.formatMessage({ id: msgId.shipInfoLatSouth });
    } else {
      latText = intl.formatMessage({ id: msgId.shipInfoLatNorth });
    }
    const degrees = parseInt(val + '', 10);
    const minutes = ((val - degrees) * 6) / 10 + '000';
    const { min, sec } = getMinSec(minutes);
    latText +=
      degrees +
      intl.formatMessage({ id: msgId.shipInfoUnitDegrees }) +
      min +
      '.' +
      sec +
      intl.formatMessage({ id: msgId.shipInfoUnitMinutes });

    return latText;
  }
  function getLonText(lon: number): string {
    let val = lon;
    let lonText;
    if (lon < 0) {
      val *= -1;
      lonText = intl.formatMessage({ id: msgId.shipInfoLonWest });
    } else {
      lonText = intl.formatMessage({ id: msgId.shipInfoLonEast });
    }
    const degrees = parseInt(val + '', 10);
    const minutes = ((val - degrees) * 6) / 10 + '000';
    const { min, sec } = getMinSec(minutes);
    lonText +=
      degrees +
      intl.formatMessage({ id: msgId.shipInfoUnitDegrees }) +
      min +
      '.' +
      sec +
      intl.formatMessage({ id: msgId.shipInfoUnitMinutes });

    return lonText;
  }

  const handleSetOpen = (open: boolean): void => {
    if (open) {
      if (dblClickTimeoutId.current) {
        clearInterval(dblClickTimeoutId.current);
        dblClickTimeoutId.current = undefined;
      }
      const timeoutId = setInterval(() => {
        setOpen(true);
        if (dblClickTimeoutId.current) {
          clearInterval(dblClickTimeoutId.current);
          dblClickTimeoutId.current = undefined;
        }
      }, 400);

      dblClickTimeoutId.current = timeoutId;
    } else {
      setOpen(false);
    }
  };

  const handleDblClick = (machineId: number | undefined): void => {
    if (dblClickTimeoutId.current) {
      clearInterval(dblClickTimeoutId.current);
      dblClickTimeoutId.current = undefined;
    }

    if (machineId !== undefined && isThinned) {
      dispatch(setCurrentConditionMachineId(machineId));
      dispatch(removeChart({ chartId: '0' }));
      dispatch(
        addChart({ chartId: '0', title: intl.formatMessage({ id: msgId.trendDefaultTitle }) })
      );
    }
  };

  return (
    <React.Fragment>
      {vesInfo && (
        <Marker
          position={{ lat: vesInfo.vesLatC1, lng: vesInfo.vesLonC1 }}
          icon={{
            url: rotateIcon(vesInfo.vesCourseTrueDirC1 != null ? vesInfo.vesCourseTrueDirC1 : 0),
            origin: new window.google.maps.Point(0, 0),
            anchor: new window.google.maps.Point(21, 21),
            scaledSize: new window.google.maps.Size(42, 42),
          }}
          label={{
            text: vesInfo.shipName ? vesInfo.shipName : '',
            className: 'marico-map-marker-label',
          }}
          onClick={() => handleSetOpen(!open)}
          onDblClick={() => handleDblClick(vesInfo?.machineId)}
        >
          {open && (
            <InfoWindow
              position={{ lat: vesInfo.vesLatC1, lng: vesInfo.vesLonC1 }}
              onCloseClick={() => handleSetOpen(false)}
            >
              <div>
                <div>{getLatText(vesInfo.vesLatC1) + ' ' + getLonText(vesInfo.vesLonC1)}</div>
                {vesInfo.vesSpdSOGC1Text != null && vesInfo.vesCourseTrueDirC1Text != null && (
                  <div>
                    {intl.formatMessage({ id: msgId.shipInfoSpeed }) +
                      ' ' +
                      vesInfo.vesSpdSOGC1Text +
                      ' ' +
                      intl.formatMessage({ id: msgId.shipInfoDirection }) +
                      ' ' +
                      vesInfo.vesCourseTrueDirC1Text}
                  </div>
                )}
                {vesInfo.vesSpdSOGC1Text != null && vesInfo.vesCourseTrueDirC1Text == null && (
                  <div>
                    {intl.formatMessage({ id: msgId.shipInfoSpeed }) +
                      ' ' +
                      vesInfo.vesSpdSOGC1Text}
                  </div>
                )}
                {vesInfo.vesSpdSOGC1Text == null && vesInfo.vesCourseTrueDirC1Text != null && (
                  <div>
                    {intl.formatMessage({ id: msgId.shipInfoDirection }) +
                      ' ' +
                      vesInfo.vesCourseTrueDirC1Text}
                  </div>
                )}
                {vesInfo.vesWindDirRelC1Text != null && vesInfo.vesWindSpdRelC1Text != null && (
                  <div>
                    {intl.formatMessage({ id: msgId.shipInfoWindDirection }) +
                      ' ' +
                      vesInfo.vesWindDirRelC1Text +
                      ' ' +
                      intl.formatMessage({ id: msgId.shipInfoWindSpeed }) +
                      ' ' +
                      vesInfo.vesWindSpdRelC1Text}
                  </div>
                )}
                {vesInfo.vesWindDirRelC1Text != null && vesInfo.vesWindSpdRelC1Text == null && (
                  <div>
                    {intl.formatMessage({ id: msgId.shipInfoWindDirection }) +
                      ' ' +
                      vesInfo.vesWindDirRelC1Text}
                  </div>
                )}
                {vesInfo.vesWindDirRelC1Text == null && vesInfo.vesWindSpdRelC1Text != null && (
                  <div>
                    {intl.formatMessage({ id: msgId.shipInfoWindSpeed }) +
                      ' ' +
                      vesInfo.vesWindSpdRelC1Text}
                  </div>
                )}
                {/* {vesInfo.logdate != null && (
                  <div>
                    {intl.formatMessage({ id: msgId.updatedAt }) +
                      ' ' +
                      dayjs(vesInfo.logdate).utc().format(constants.dateFormat.YYYYMMDDHHmmss)}
                  </div>
                )} */}
              </div>
            </InfoWindow>
          )}
        </Marker>
      )}
    </React.Fragment>
  );
}
